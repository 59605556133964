<template>
  <input-box-buttons
    name="suncorp-divisions"
    :buttons="[
      { name: 'Banking and Wealth', value: 'banking_and_wealth' },
      { name: 'Customer and Digital', value: 'customer_digital' },
      { name: 'People Experience', value: 'people_experience' },
      { name: 'Finance and Advice', value: 'finance_and_advice' },
      { name: 'Insurance', value: 'insurance' },
      { name: 'Program Excellence', value: 'program_excellence' },
      { name: 'Risk', value: 'risk' },
      { name: 'Technology, Data and Labs', value: 'technology_data_and_labs' }
    ]"
    @click="
      (value) => {
        $emit('click', value)
      }
    "
    :selected="selected"
    :multi-line="true" />
</template>

<script>
import InputBoxButtons from '../../BasicInputs/InputBoxButtons'
export default {
  name: 'DivisionAnswer',
  components: { InputBoxButtons },
  props: {
    selected: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped></style>
