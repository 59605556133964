<template>
  <base-view class="manage-bg-size">
    <div class="min-h-65vh" :class="{ 'bg-blue-5': this.$route.params.content_type === 'aboutUs' }">
      <render-extra-content
        class="bg-white shadow-lg rounded my-6 p-4 article fix-width-m-auto"
        :get-content-for="getContentType()" />
    </div>
  </base-view>
</template>

<script>
import BaseView from '@/views/BaseView.vue'
import RenderExtraContent from '@/components/BrandComponents/ExtraContent/RenderExtraContent.vue'
import AboutUs from '@/views/Otivo/Brand/About/AboutUs.vue'
import SecurityPage from '@/views/Otivo/Brand/About/Security.vue'

export default {
  name: 'ExtraContent',
  components: { RenderExtraContent, BaseView },

  methods: {
    getContentType() {
      const tags = {
        privacy: 'PRIVACY',
        termsAndConditions: 'TERMS_AND_CONDITIONS'
      }
      if (this.$route.params.content_type && tags[this.$route.params.content_type]) {
        return tags[this.$route.params.content_type]
      }
    }
  }
}
</script>

<style scoped>
:deep(p) {
  @apply py-3 !important;
  word-break: break-word;
}

:deep(h2) {
  @apply text-black !important;
}
</style>
