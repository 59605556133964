<template>
  <g>
    <circle cx="3.5" cy="3.5" r="3.5" fill="#0064FF" />
    <circle cx="12.5" cy="3.5" r="3.5" :fill="active ? '#FF0000' : '#0064FF'" />
    <circle cx="3.5" cy="12.5" r="3.5" fill="#0064FF" />
    <circle cx="12.5" cy="12.5" r="3.5" fill="#0064FF" />
  </g>
</template>

<script>
export default {
  name: 'IconDashboard',
  props: {
    active: { type: Boolean, default: true }
  }
}
</script>

<style scoped></style>
