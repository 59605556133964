<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.10175 3.465C7.83653 3.465 7.58218 3.35964 7.39464 3.17211C7.2071 2.98457 7.10175 2.73022 7.10175 2.465V1C7.10175 0.734783 7.2071 0.48043 7.39464 0.292893C7.58218 0.105357 7.83653 0 8.10175 0C8.36696 0 8.62132 0.105357 8.80885 0.292893C8.99639 0.48043 9.10175 0.734783 9.10175 1V2.465C9.10175 2.73022 8.99639 2.98457 8.80885 3.17211C8.62132 3.35964 8.36696 3.465 8.10175 3.465Z"
      :fill="fill1" />
    <path
      d="M7.99924 16C7.73402 16 7.47967 15.8946 7.29213 15.7071C7.10459 15.5196 6.99924 15.2652 6.99924 15V13.65C6.99924 13.3848 7.10459 13.1304 7.29213 12.9429C7.47967 12.7554 7.73402 12.65 7.99924 12.65C8.26445 12.65 8.51881 12.7554 8.70634 12.9429C8.89388 13.1304 8.99924 13.3848 8.99924 13.65V15C8.99924 15.2652 8.89388 15.5196 8.70634 15.7071C8.51881 15.8946 8.26445 16 7.99924 16Z"
      :fill="fill1" />
    <path
      d="M8.24924 14.1675C6.22924 14.1675 4.18674 13.3575 3.21924 12.145C3.05348 11.9378 2.97682 11.6732 3.00612 11.4095C3.03543 11.1458 3.16829 10.9045 3.37549 10.7387C3.58269 10.573 3.84725 10.4963 4.11098 10.5256C4.3747 10.5549 4.61598 10.6878 4.78174 10.895C5.74424 12.105 8.83674 12.67 10.4417 11.5925C10.7292 11.4025 11.3892 10.96 10.7042 9.62748C10.0992 9.07998 8.87924 8.82998 7.79424 8.60498C7.12497 8.48877 6.46555 8.32162 5.82174 8.10498C5.30831 7.97504 4.84175 7.70338 4.4753 7.32102C4.10884 6.93865 3.85726 6.46097 3.74924 5.94248C3.61464 5.33996 3.64789 4.71206 3.84537 4.12713C4.04285 3.54219 4.39698 3.02263 4.86924 2.62498C5.79174 1.89748 7.36924 1.55498 8.88424 1.74498C10.2067 1.90998 11.2892 2.45998 11.9292 3.29248C12.0192 3.39519 12.0871 3.51522 12.1289 3.64518C12.1707 3.77514 12.1854 3.91228 12.1722 4.04815C12.159 4.18402 12.1181 4.31575 12.052 4.43521C11.986 4.55467 11.8961 4.65935 11.7881 4.74278C11.68 4.82621 11.556 4.88664 11.4237 4.92033C11.2915 4.95403 11.1537 4.96028 11.0189 4.93871C10.8841 4.91713 10.7551 4.86818 10.64 4.79488C10.5248 4.72158 10.4259 4.62547 10.3492 4.51248C10.0392 4.11248 9.41424 3.82748 8.63674 3.72998C7.54924 3.59248 6.52424 3.86498 6.11174 4.18998C5.93223 4.35176 5.7988 4.55821 5.72502 4.78832C5.65124 5.01844 5.63976 5.26399 5.69174 5.49998C5.72008 5.66491 5.79791 5.81733 5.9149 5.93699C6.03188 6.05666 6.18249 6.13792 6.34674 6.16998C6.39115 6.17952 6.43465 6.1929 6.47674 6.20998C7.03844 6.39761 7.61338 6.54301 8.19674 6.64498C9.62174 6.93998 11.2492 7.27248 12.2492 8.33748C12.3067 8.39853 12.3563 8.46657 12.3967 8.53998C13.4467 10.44 13.1467 12.2025 11.5617 13.255C10.5717 13.8791 9.41923 14.1966 8.24924 14.1675Z"
      :fill="fill2" />
  </svg>
</template>

<script>
export default {
  name: 'CashflowIcon',
  props: {
    fill1: {
      type: String,
      default: '#FF0000'
    },
    fill2: {
      type: String,
      default: '#0064FF'
    }
  }
}
</script>

<style scoped></style>
