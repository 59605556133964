<template>
  <div class="flex flex-col w-full rounded-[20px] bg-white otivo-shadow border border-blue-4.5 p-5 md:p-10">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'BasicCard'
}
</script>
