<template>
  <svg viewBox="0 0 39 52" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 18.5299C0 31.3091 17.8791 49.5625 19.1264 52C20.3738 49.5625 38.2529 31.3091 38.2529 18.5299C38.2529 8.29644 29.6893 0 19.1264 0C8.56354 0 0 8.29644 0 18.5299Z"
      fill="#8A55CC" />
    <path
      d="M28.0765 15.9366C27.9513 15.5577 27.6078 15.2886 27.2014 15.2528L21.681 14.7624L19.4981 9.76407C19.3371 9.39776 18.9706 9.16064 18.5633 9.16064C18.156 9.16064 17.7894 9.39776 17.6285 9.76493L15.4456 14.7624L9.9243 15.2528C9.51862 15.2895 9.17599 15.5577 9.05005 15.9366C8.92412 16.3154 9.04042 16.731 9.3473 16.993L13.5201 20.573L12.2896 25.8754C12.1996 26.2653 12.3543 26.6683 12.6849 26.9021C12.8627 27.0277 13.0706 27.0917 13.2803 27.0917C13.4611 27.0917 13.6405 27.044 13.8014 26.9498L18.5633 24.1656L23.3234 26.9498C23.6717 27.1548 24.1108 27.1361 24.4408 26.9021C24.7716 26.6676 24.9261 26.2644 24.8361 25.8754L23.6056 20.573L27.7784 16.9937C28.0853 16.731 28.2025 16.3162 28.0765 15.9366V15.9366Z"
      fill="white" />
  </svg>
</template>

<script>
export default {
  name: 'StarMmpMarker'
}
</script>

<style scoped>
svg path:first-child {
  fill: inherit !important;
}
</style>
