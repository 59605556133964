<template>
  <svg width="42" height="43" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_11425_181116)">
      <path
        d="M21.2666 9.67521C20.5704 9.67521 19.9027 9.39865 19.4104 8.90637C18.9182 8.41409 18.6416 7.74641 18.6416 7.05021V3.20459C18.6416 2.5084 18.9182 1.84072 19.4104 1.34843C19.9027 0.856152 20.5704 0.57959 21.2666 0.57959C21.9628 0.57959 22.6305 0.856152 23.1228 1.34843C23.615 1.84072 23.8916 2.5084 23.8916 3.20459V7.05021C23.8916 7.74641 23.615 8.41409 23.1228 8.90637C22.6305 9.39865 21.9628 9.67521 21.2666 9.67521Z"
        :class="`fill-${fill2}`" />
      <path
        d="M20.998 42.5796C20.3019 42.5796 19.6342 42.303 19.1419 41.8107C18.6496 41.3185 18.373 40.6508 18.373 39.9546V36.4108C18.373 35.7146 18.6496 35.047 19.1419 34.5547C19.6342 34.0624 20.3019 33.7858 20.998 33.7858C21.6942 33.7858 22.3619 34.0624 22.8542 34.5547C23.3465 35.047 23.623 35.7146 23.623 36.4108V39.9546C23.623 40.6508 23.3465 41.3185 22.8542 41.8107C22.3619 42.303 21.6942 42.5796 20.998 42.5796Z"
        :class="`fill-${fill2}`" />
      <path
        d="M21.6543 37.7693C16.3518 37.7693 10.9902 35.643 8.45051 32.4602C8.01539 31.9163 7.81415 31.2218 7.89107 30.5296C7.96799 29.8373 8.31676 29.2039 8.86066 28.7688C9.40457 28.3337 10.099 28.1325 10.7913 28.2094C11.4836 28.2863 12.117 28.6351 12.5521 29.179C15.0786 32.3552 23.1964 33.8383 27.4096 31.0099C28.1643 30.5112 29.8968 29.3496 28.0986 25.8518C26.5105 24.4146 23.308 23.7584 20.4599 23.1677C18.7031 22.8627 16.9721 22.4239 15.2821 21.8552C13.9343 21.5141 12.7096 20.801 11.7477 19.7973C10.7857 18.7936 10.1253 17.5397 9.84176 16.1787C9.48844 14.597 9.57572 12.9488 10.0941 11.4134C10.6125 9.8779 11.5421 8.51405 12.7818 7.47023C15.2033 5.56054 19.3443 4.66148 23.3211 5.16023C26.7927 5.59335 29.6343 7.0371 31.3143 9.22241C31.5503 9.49201 31.7287 9.80709 31.8384 10.1482C31.9481 10.4894 31.9868 10.8494 31.9521 11.206C31.9173 11.5627 31.81 11.9085 31.6366 12.2221C31.4631 12.5357 31.2273 12.8104 30.9437 13.0294C30.6601 13.2485 30.3346 13.4071 29.9873 13.4955C29.6401 13.584 29.2784 13.6004 28.9245 13.5438C28.5707 13.4871 28.2322 13.3586 27.9299 13.1662C27.6276 12.9738 27.3679 12.7215 27.1668 12.4249C26.353 11.3749 24.7124 10.6268 22.6714 10.3709C19.8168 10.0099 17.1261 10.7252 16.0433 11.5784C15.5721 12.003 15.2218 12.545 15.0282 13.149C14.8345 13.753 14.8044 14.3976 14.9408 15.0171C15.0152 15.45 15.2195 15.8501 15.5266 16.1643C15.8337 16.4784 16.229 16.6917 16.6602 16.7759C16.7768 16.8009 16.891 16.836 17.0014 16.8809C18.4759 17.3734 19.9851 17.7551 21.5164 18.0227C25.2571 18.7971 29.5293 19.6699 32.1543 22.4655C32.3051 22.6258 32.4352 22.8044 32.5414 22.9971C35.2977 27.9846 34.5102 32.6112 30.3496 35.374C27.7507 37.0123 24.7255 37.8456 21.6543 37.7693Z"
        :class="`fill-${fill1}`" />
    </g>
    <defs>
      <clipPath id="clip0_11425_181116">
        <rect width="42" height="42" fill="white" transform="translate(0 0.57959)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1?: string
  fill2?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red'
})
</script>

<style scoped></style>
