<template>
  <CFSView type="member" class="bg-blue-6">
    <div
      class="flex flex-col items-center justify-center min-h-100% drop-shadow-md w-full justify-self-center">
      <form
        @submit.prevent="setPassword"
        class="max-w-[375px] w-100% text-left p-[40px] bg-white rounded-lg border-[2px] border-blue-5 otivo-drop-shadow">
        <h3 class="text-blue-1 text-22px">Please set your new password</h3>
        <InputText
          label="Confirmation code"
          name="sms_code"
          :value="password"
          @input="(val) => (password = val)"
          place-holder="SMS code"
          data-test="smsCodeInput"
          :autofocus="true"
          class="w-100% m-auto mt-[26px] cfs-guidance-client-input"
          :allow-digits="true" />
        <div class="flex gap-1 mt-[10px]">
          <div>Didn't receive it?</div>
          <div tabindex="1" class="text-otivo-blue cursor-pointer" @click="resendSMS()">Resend</div>
        </div>
        <InputText
          type="password"
          label="Set your password"
          name="sms_code"
          :value="newPassword"
          @input="(val) => (newPassword = val)"
          place-holder=""
          data-test="newPassword"
          :autofocus="true"
          :allow-digits="true"
          class="w-100% m-auto mt-[26px] cfs-guidance-client-input" />
        <div class="flex flex-col bg-white rounded-b border-t-0 border-1px border-grey-2 px-8 py-2">
          <span :class="validations.length ? 'text-otivo-blue' : 'text-red-1'"
            >Must be at least 8 characters</span
          >
          <span :class="validations.number ? 'text-otivo-blue' : 'text-red-1'"
            >Must contain a number</span
          >
          <span :class="validations.special ? 'text-otivo-blue' : 'text-red-1'"
            >Must contain a special character</span
          >
        </div>
        <InputText
          type="password"
          label="Confirm password"
          name="sms_code"
          :error-message="confirmationError"
          :value="confirmPassword"
          @input="(val) => (confirmPassword = val)"
          place-holder=""
          data-test="confirmPassword"
          :autofocus="true"
          :allow-digits="true"
          class="w-100% m-auto cfs-guidance-client-input" />
        <InputError class="pt-[10px] text-center" :error="smsError" v-if="smsError !== ''" />
        <InputError
          class="pt-[10px] text-center"
          :error="confirmationError"
          v-if="confirmationError !== ''" />
        <OtivoButton
          type="submit"
          size="large"
          colour="blue"
          class="rounded-circle mt-[20px] w-100% md:w-fit"
          data-test="confirmBtn"
          :loading="loading"
          :disabled="!canSubmit">
          Confirm
        </OtivoButton>
      </form>
    </div>
  </CFSView>
</template>
<script lang="ts" setup>
import InputText from '@/components/BasicInputs/InputText.vue'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import OtivoButton from '@/components/OtivoButton.vue'
import InputError from '@/components/BasicInputs/InputError.vue'
import CFSView from '@/views/Clients/CFS/CFSView.vue'
import { useAuth0 } from '@/lib/AuthenticatorPlugin'
import { useDebounceFn } from '@vueuse/core'
import { useGuidanceClientStore } from '@/store/pinia/GuidanceClientStore'
import { useToast } from '@/composables/useToast.ts'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import api from '@/services/Api.ts'
import { useRouter } from 'vue-router'

const auth0 = useAuth0()
const clientStore = useGuidanceClientStore()
const sessionStore = useSessionStore()
const router = useRouter()
const { successToast } = useToast()

const guidanceClientData = computed(() => clientStore.getGuidanceClientData)
const password = ref('')
const newPassword = ref('')
const confirmPassword = ref('')
const loading = ref(false)
const canSubmit = computed(() => password.value)
const resent = ref(false)
const smsError = ref('')

// value: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/,
const validations = reactive({
  length: false,
  number: false,
  special: false,
})
const confirmationError = ref('')
const passwordsMatch = computed(() => {
  return newPassword.value === confirmPassword.value
})

watch(newPassword, (newVal) => {
  validations.length = newVal.length >= 8
  validations.number = /\d/.test(newVal)
  validations.special = /[~`!@#$%^&*+=:;"'<,>.?_-]/.test(newVal)
})

const setPassword = () => {
  if (!passwordsMatch.value) {
    confirmationError.value = 'Passwords do not match.'
    return
  }
  if (!validations.length || !validations.number || !validations.special) {
    confirmationError.value = 'Password does not meet requirements.'
    return
  }
  loading.value = true

  const payload = {
    email: guidanceClientData.value.email,
    member_id: guidanceClientData.value.memberId,
    password: newPassword.value,
    temporary_password: password.value,
    whitelabel_domain: sessionStore.getWhitelabelData.domain,
  }
  window.localStorage.setItem('overrideWelcomeModal', 'Y')
  api()
    .Post('/modularised_advice/set-password', payload)
    ?.then(() => {
      successToast('Password set successfully')
      auth0.login()
      loading.value = false
    })
    .catch((err) => {
      console.error('error setting password', err)
      loading.value = false
    })
}

// const OTPLogin = () => {
//   loading.value = true
//   const payload: LoginObject = {
//     email: guidanceClientData.value.email,
//     password: password.value.replace(/\s/g, ''),
//     realm: 'cfs-users', //todo: add realm login to whitelable config
//     redirectUri: getSiteUri() + 'client/dashboard',
//   }
//
//   loginWithEmail(payload).catch(() => {
//     smsError.value = 'Incorrect SMS code.'
//     loading.value = false
//   })
// }

const resendSMS = useDebounceFn(() => {
  const payload = {
    email: guidanceClientData.value.email,
    member_id: guidanceClientData.value.memberId,
  }

  clientStore
    .resendCFSClientSMS(payload)
    .then(() => {
      successToast('SMS Resent')
      resent.value = true
    })
    .catch((err) => {
      console.error('error resending SMS', err)
    })
}, 3500)

onMounted(() => {
  if (!guidanceClientData.value?.email && !guidanceClientData.value?.memberId) {
    router.replace({ name: 'clientSignIn' })
  }
})
</script>

<style scoped></style>
