<template>
  <svg
    :class="{ 'white-color': white }"
    class="savingsIcon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 96"
    stroke="none">
    <path d="M0 0h96v96H0z" />
    <path
      class="icon"
      fill="#3EA76D"
      d="M46.712 25.322l-9.666-1.654a13 13 0 0 1-7.765-4.456L19.2 7.2l18.597 3.626a9 9 0 0 1 5.118 2.984l5.871 6.867 6.839-6.184a6 6 0 0 1 3.04-1.468l15.981-2.656-9.175 10.43a8 8 0 0 1-4.52 2.576l-10.215 1.933v14.987c-.672.006-1.343.012-2.011.012-.67 0-1.34-.006-2.013-.012V25.322zM64.696 86.4H32.062l-6.925-26.678H71.62L64.696 86.4zM20.034 43.882h56.69v11.672h-56.69V43.882z" />
  </svg>
</template>

<script>
export default {
  name: 'SavingsIcon',
  props: {
    white: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.icon {
  @apply fill-otivo-blue;
}

.white-color .icon {
  @apply fill-white;
}
</style>
