import getApiInstance from '@/services/Api'
const api = getApiInstance()

export default {
  getAllNextSteps() {
    return api.Get('/next_steps')
  },
  getDebtNextSteps() {
    return api.Get('/next_steps/debt')
  },
  getSavingsNextSteps() {
    return api.Get('/next_steps/savings')
  },
  getRetirementNextSteps() {
    return api.Get('/next_steps/retirement')
  }
}
