<template>
  <high-charts class="relative" :options="getOptions" />
</template>

<script>
import Highcharts from 'highcharts'
import { Chart } from 'highcharts-vue'
import exportingInit from 'highcharts/modules/exporting'
import highchartsMoreInit from 'highcharts/highcharts-more'
import solidGaugeInit from 'highcharts/modules/solid-gauge'
import { getRootColor } from '@/lib/helpers'

exportingInit(Highcharts)
highchartsMoreInit(Highcharts)
solidGaugeInit(Highcharts)

export default {
  name: 'PlanRatingGraphs',
  components: {
    HighCharts: Chart,
  },
  props: {
    resultResponse: {
      type: Object,
      required: true,
    },
    averageScores: {
      type: Array,
      required: true,
    },
  },
  computed: {
    getOptions() {
      return {
        chart: {
          type: 'bar',
          styleMode: true,
        },
        credits: {
          enabled: false,
        },

        exporting: {
          enabled: false,
        },
        title: {
          text: null,
        },
        subtitle: {
          text: null,
        },
        xAxis: {
          lineWidth: 0,
          minorGridLineWidth: 0,
          lineColor: 'transparent',
          minorTickLength: 0,
          tickLength: 0,
          categories: [
            'Debt',
            'Savings',
            'Invest + Retire',
            'Insurance',
            'Financial peace of mind',
          ],
          labels: {
            align: 'left',
            x: 0,
            y: -28,
            style: {
              fontWeight: 'bold',
              fontSize: '14px',
              width: '200px',
              color: '#333',
            },
          },
          title: {
            text: null,
          },
        },
        yAxis: {
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          labels: {
            enabled: false,
          },
          title: {
            text: null,
          },
          min: 0,
        },
        tooltip: {
          valueSuffix: '%',
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              format: '{y} %',
              style: {
                fontWeight: 'bold',
                fontSize: '14px',
                color: '#333',
                fontFamily: 'Raleway',
              },
            },
          },
          series: {
            pointWidth: 14,
            pointPadding: 0,
            borderRadius: 10,
          },
        },

        legend: {
          enabled: false,
        },

        series: [
          {
            name: 'Your Score',
            data: [
              this.resultResponse.debtScore,
              this.resultResponse.savingsScore,
              this.resultResponse.retireScore,
              this.resultResponse.insuranceScore,
              this.resultResponse.financialStressScore,
            ],
            color: getRootColor('otivo-blue'),
          },
          {
            name: 'Average Score',
            data: this.averageScores,
            color: getRootColor('blue-3'),
          },
        ],
      }
    },
  },
}
</script>

<style scoped></style>
