<template>
  <div class="flex flex-wrap">
    <div
      v-for="(option, index) in options"
      :key="index"
      class="h-12 flex m-2 w-38 justify-around border opacity-50 cursor-pointer rounded lg:hover:border-otivo-blue"
      :class="{ selected: selected === option.value }"
      @click="$emit('click', option.value)">
      <div class="self-center">
        {{ option.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AgeAnswers',
  props: {
    selected: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      options: [
        { value: '24', name: '16 - 24' },
        { value: '34', name: '25 - 34' },
        { value: '44', name: '35 - 44' },
        { value: '54', name: '45 - 54' },
        { value: '64', name: '55 - 64' },
        { value: '74', name: '65 - 74' },
        { value: '75', name: '75 above' }
      ]
    }
  }
}
</script>

<style scoped></style>
