<template>
  <svg width="42" height="43" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.1272 15.8909C18.8776 15.2499 17.5359 14.8071 16.1503 14.5784C17.5802 14.0275 18.7722 12.9936 19.5198 11.656C20.2673 10.3183 20.5231 8.76127 20.243 7.25476C19.9628 5.74825 19.1643 4.38727 17.9859 3.40779C16.8075 2.42831 15.3234 1.89209 13.7911 1.89209C12.2588 1.89209 10.7747 2.42831 9.5963 3.40779C8.41788 4.38727 7.61937 5.74825 7.33921 7.25476C7.05905 8.76127 7.31491 10.3183 8.06242 11.656C8.80994 12.9936 10.002 14.0275 11.4319 14.5784C8.23762 15.131 5.34041 16.7919 3.24957 19.2693C1.15874 21.7466 0.00812112 24.8817 0 28.1234C0 28.8195 0.276562 29.4872 0.768845 29.9795C1.26113 30.4718 1.92881 30.7484 2.625 30.7484H24.9375C25.6337 30.7484 26.3014 30.4718 26.7937 29.9795C27.2859 29.4872 27.5625 28.8195 27.5625 28.1234C27.5675 25.5979 26.8761 23.12 25.5644 20.9619C24.2527 18.8038 22.3713 17.0492 20.1272 15.8909ZM13.7812 7.12335C14.0408 7.12335 14.2946 7.20033 14.5104 7.34455C14.7263 7.48877 14.8945 7.69375 14.9938 7.93358C15.0932 8.17341 15.1192 8.43731 15.0685 8.69191C15.0179 8.94651 14.8929 9.18038 14.7093 9.36393C14.5258 9.54749 14.2919 9.67249 14.0373 9.72313C13.7827 9.77378 13.5188 9.74779 13.279 9.64845C13.0392 9.54911 12.8342 9.38088 12.6899 9.16504C12.5457 8.9492 12.4688 8.69544 12.4688 8.43585C12.4688 8.08776 12.607 7.75392 12.8532 7.50778C13.0993 7.26164 13.4332 7.12335 13.7812 7.12335ZM5.66344 25.4984C6.21843 23.7821 7.3031 22.286 8.76163 21.2248C10.2202 20.1636 11.9775 19.592 13.7812 19.5921C15.1515 19.5854 16.5028 19.912 17.7188 20.5437C19.7123 21.5795 21.2168 23.3599 21.9056 25.4984H5.66344Z"
      :class="`fill-${fill2}`" />
    <path
      d="M30.5681 25.7149C31.9997 25.166 33.194 24.1331 33.9436 22.7956C34.6931 21.4581 34.9507 19.9003 34.6716 18.3927C34.3925 16.8851 33.5943 15.5228 32.4156 14.5422C31.2368 13.5617 29.752 13.0248 28.2188 13.0248C26.6855 13.0248 25.2007 13.5617 24.0219 14.5422C22.8432 15.5228 22.045 16.8851 21.7659 18.3927C21.4868 19.9003 21.7444 21.4581 22.4939 22.7956C23.2435 24.1331 24.4378 25.166 25.8694 25.7149C22.6718 26.2681 19.772 27.9319 17.6807 30.4133C15.5895 32.8947 14.441 36.0345 14.4375 39.2796C14.4375 39.9758 14.7141 40.6435 15.2063 41.1357C15.6986 41.628 16.3663 41.9046 17.0625 41.9046H39.375C40.0712 41.9046 40.7389 41.628 41.2312 41.1357C41.7234 40.6435 42 39.9758 42 39.2796C41.9965 36.0345 40.848 32.8947 38.7568 30.4133C36.6655 27.9319 33.7657 26.2681 30.5681 25.7149ZM28.2188 18.2796C28.4783 18.2796 28.7321 18.3566 28.9479 18.5008C29.1638 18.645 29.332 18.85 29.4313 19.0898C29.5307 19.3296 29.5567 19.5935 29.506 19.8481C29.4554 20.1027 29.3304 20.3366 29.1468 20.5202C28.9633 20.7037 28.7294 20.8287 28.4748 20.8794C28.2202 20.93 27.9563 20.904 27.7165 20.8047C27.4767 20.7053 27.2717 20.5371 27.1274 20.3213C26.9832 20.1054 26.9062 19.8517 26.9062 19.5921C26.9062 19.244 27.0445 18.9101 27.2907 18.664C27.5368 18.4179 27.8707 18.2796 28.2188 18.2796ZM20.1009 36.6546C20.6557 34.9381 21.7403 33.4416 23.1988 32.3802C24.6574 31.3187 26.4148 30.7469 28.2188 30.7469C30.0227 30.7469 31.7801 31.3187 33.2387 32.3802C34.6972 33.4416 35.7818 34.9381 36.3366 36.6546H20.1009Z"
      :class="`fill-${fill1}`" />
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1?: string
  fill2?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red'
})
</script>

<style scoped></style>
