import getApiInstance from '@/services/Api'

const api = getApiInstance()

const getSearchPayloadObject = (search) => {
  const payload = {
    params: {},
  }
  if (search && search !== '') {
    payload.params = {
      search: search,
    }
  }
  return payload
}

export default {
  getSuperFundNames(search = '') {
    return api.Get(`/chant-west/super-products?search=${search}`)
  },

  getSuperFundOptions({ id, search = '' }: { id: number; search: string }) {
    const payload = {
      params: {
        id: id,
        search: search,
      },
    }

    return api.Get('/chant-west/investments', payload)
  },

  getOccupations(search) {
    const payload = getSearchPayloadObject(search)
    return api.Get('/chant-west/occupations', payload)
  },

  getAllPostcodes(search) {
    const payload = getSearchPayloadObject(search)
    return api.Get('/chant-west/postcodes', payload)
  },

  getPostCodeById(id) {
    return api.Get(`/chant-west/postcode/${id}`)
  },

  postOptimizeAdviceSuperData(payload) {
    return api.Post('/chant-west/account-profile', payload)
  },

  postOptimizeAdviceInsuranceData(payload) {
    return api.Post('/chant-west/account-profile', payload)
  },

  getComparisonSupers() {
    return api.Get('/chant-west/better-rated-supers')
  },

  getBetterIntraFundOptions(superId) {
    let params = {}
    if (superId) {
      params = {
        id: superId,
      }
    }
    return api.Get('/chant-west/better-intrafund', { params: params })
  },

  getOptimizeAdviceInsurance() {
    return api.Get('/chant-west/insurance')
  },

  getOptimizeAdviceTotalNumberOfInvestmentOptions(id) {
    return api.Get(`/chant-west/super-product/investments-count/${id}`)
  },

  getSuperInsuranceTypes(id) {
    return api.Get('/chant-west/insurance-type', { params: { productId: id } })
  },

  updateSuperInvestmentOption(payload) {
    return api.Post('/chant-west/update-investment-option', payload)
  },

  getChantWestContactInfo(chantWestProductId: number) {
    return api.Get(`/chant-west/contact-info/${chantWestProductId}`)
  },

  getHistoricalEvents() {
    return api.Get('/historical-events')
  },

  getChantWestProfiles() {
    return api.Get('/chant-west/profile')
  },

  getChantWestInsurancesByOwner() {
    return api.Get('/chant-west/insurance/')
  },

  getDefaultInsurances() {
    return api.Get('/chant-west/default-insurance')
  },

  getOptimiseHistory() {
    return api.Get('/optimize')
  },
}
