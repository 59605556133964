<template>
  <loader class-list="lds-spinner self-center m-auto max-h-48 max-w-48 min-h-10 min-w-10 " />
</template>
<script lang="ts" setup>
import { onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import Api from '@/services/Api'
import Loader from '@/components/Loader/Loader.vue'
import getApiInstance from '@/services/Api'
const api = getApiInstance()

const router = useRouter()
const route = useRoute()
const jobId: string | null = route.query?.jobId
const userId: string | null = route.query?.state
const urlFromUserId = () => {
  api
    .Get('/basiq/redirect/user/' + userId + '/' + jobId)
    .then((res) => {
      if (res.data.url) {
        window.location.href = res.data.url
      } else {
        router.push('/dashboard')
      }
    })
    .catch(() => {
      router.push('/dashboard')
    })
}

const urlFromJobId = () => {
  api
    .Get('/basiq/redirect/job/' + jobId)
    .then((res) => {
      if (res.data.url) {
        window.location.href = res.data.url
      } else {
        router.push('/dashboard')
      }
    })
    .catch(() => {
      router.push('/dashboard')
    })
}

onMounted(() => {
  if (userId) {
    // should always be provided
    urlFromUserId()
  } else if (jobId) {
    // fallback
    urlFromJobId()
  } else {
    router.push('/dashboard')
  }
})
</script>
