<template>
  <div class="relative w-auto h-full flow-root">
    <video
      v-if="!checkIfSafari()"
      muted
      autoplay
      loop
      class="absolute top-[22%] left-[20%] w-[65%] h-auto"
      :src="BetterOffBreathAnimation" />
    <img
      v-else
      :src="BetterOffBreathStatic"
      alt="BetterOffBreathStatic"
      class="absolute top-[25%] left-[20%] w-[65%] h-auto" />
    <div class="absolute bottom-[20%] left-[29%]">
      <OtivoButton
        colour="blue"
        size="medium"
        typography="button-2"
        class="transition-all pointer-events-none"
        :custom-style-overrides="
          handHoverActive ? 'background: #0037a1; border-color: #0037a1' : ''
        "
        >Be better off</OtivoButton
      >
    </div>
    <img :src="BetterOffPhone" alt="BetterOffPhone" class="w-full h-auto" />
  </div>
</template>

<script lang="ts" setup>
import BetterOffBreathAnimation from '@/assets/img/BetterOffBreathAnimation.webm'
import BetterOffBreathStatic from '@/components/SVGS/BetterOffBreathStatic.svg?url'
import BetterOffPhone from '@/components/SVGS/BetterOffPhone.svg?url'
import OtivoButton from '@/components/OtivoButton.vue'

defineProps<{ handHoverActive: boolean }>()

const checkIfSafari = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  return userAgent.includes('safari') && !userAgent.includes('chrome')
}
</script>
