<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
      :class="`fill-${fill1}`"
      :fill="fill1" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.7071 5.29289C13.0976 5.68342 13.0976 6.31658 12.7071 6.70711L7.1 12.3142L4.29289 9.50711C3.90237 9.11658 3.90237 8.48342 4.29289 8.09289C4.68342 7.70237 5.31658 7.70237 5.70711 8.09289L7.1 9.48579L11.2929 5.29289C11.6834 4.90237 12.3166 4.90237 12.7071 5.29289Z"
      fill="white" />
  </svg>
</template>

<script lang="ts" setup>
type Props = {
  fill1?: string
}

withDefaults(defineProps<Props>(), {
  fill1: '#01CC9B',
})
</script>

<style scoped></style>
