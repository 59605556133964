import { Chart, ChartConfiguration } from 'chart.js/auto'
import htmlLegendsPlugin from '@/composables/charts/plugins/htmlLegendsPlugin.ts'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { useDebounceFn } from '@vueuse/core'

const barConfig = {
  type: 'bar',
  options: {
    scales: {
      y: {
        border: { display: false },
        ticks: {
          maxTicksLimit: 4,
        },
        grid: {
          color: '#A0E6FA',
        },
      },
      x: {
        border: { display: false },
        grid: {
          display: false,
          zeroLineColor: 'transparent',
        },
      },
    },
    plugins: {
      htmlLegend: {
        containerID: 'legend',
      },
      legend: {
        display: false,
      },
    },
  },
}

//todo: refactor to pass plugins
export const drawGraph = useDebounceFn(
  (data, canvas: HTMLCanvasElement, plugins = [htmlLegendsPlugin(), ChartDataLabels]) => {
    const config = {
      ...barConfig,
      data: data,
      plugins: plugins,
    }

    return new Chart(canvas, config)
  },
  500,
)
