<template>
  <div class="flex gap-2.5 items-center p-2.5">
    <div
      class="bg-red-light border rounded-sm border-red-1 flex flex-row items-center text-red-1 px-[5px] py-[6px] gap-1">
      <TooltipIcon class="transform rotate-180" fill1="red-1" />
      <span class="button-4 text-[10px]">ACCOUNT DEACTIVATED</span>
    </div>
    <p>on</p>
    <span class="text-grey-2 paragraph-2 font-bold">{{ date }}</span>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps } from 'vue'
import TooltipIcon from '@/components/icons/TooltipIcon.vue'
import { format } from 'date-fns'

const props = defineProps<{
  expiresAt: number
}>()

const date = computed(() => {
  return format(new Date(props.expiresAt), 'd MMM yyyy HH:mm:ss')
})
</script>

<style scoped></style>
