import { toast, ToastContainerOptions } from 'vue3-toastify'

export const useToast = () => {
  const defaultToast = (message: string, options: ToastContainerOptions = {}) => {
    toast(message, {
      type: 'default',
      ...options
    })
  }

  const errorToast = (message: string, options: ToastContainerOptions = {}) => {
    toast(message, {
      type: 'error',
      autoClose: 8000,
      ...options
    })
  }

  const infoToast = (message: string, options: ToastContainerOptions = {}) => {
    toast(message, {
      type: 'info',
      ...options
    })
  }

  const successToast = (message: string, options: ToastContainerOptions = {}) => {
    toast(message, {
      type: 'success',
      autoClose: 3000,
      ...options
    })
  }

  const warningToast = (message: string, options: ToastContainerOptions = {}) => {
    toast(message, {
      type: 'warning',
      ...options
    })
  }

  return {
    defaultToast,
    errorToast,
    infoToast,
    successToast,
    warningToast
  }
}
