<template>
  <svg
    :class="{ 'white-color': white }"
    class="insuranceIcon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 96">
    <path
      fill="#F48358"
      d="M82 17.688L50.787 5v40.208H82zM19.135 65.627c2.719 4.578 6.489 9.184 11.206 13.69C36.52 85.22 42.636 89.357 45.213 91V50.792H14c.45 4.866 2.172 9.845 5.135 14.835zM76.865 65.627c-2.719 4.578-6.489 9.184-11.206 13.69C59.48 85.22 53.364 89.357 50.787 91V50.792H82c-.45 4.866-2.172 9.845-5.135 14.835zM14 45.208h31.213V5L14 17.688z" />
  </svg>
</template>

<script>
export default {
  name: 'InsuranceIcon',
  props: {
    white: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
g {
  @apply fill-otivo-blue;
}

.white-color g {
  @apply fill-white;
}
</style>
