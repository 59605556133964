<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.244385" width="24" height="24" rx="12" :class="`fill-${fill1}`" />
    <path
      d="M15 17.0144H9C7.2 17.0144 6 16.1541 6 14.1468V10.1321C6 8.1247 7.2 7.2644 9 7.2644H15C16.8 7.2644 18 8.1247 18 10.1321V14.1468C18 16.1541 16.8 17.0144 15 17.0144Z"
      stroke="white"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M15 10.5644L13.122 12.0692C12.504 12.5628 11.49 12.5628 10.872 12.0692L9 10.5644"
      stroke="white"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'grey-3'
})
</script>
