<template>
  <div>
    <svg
      class="offset-curve -mt-[1px]"
      :class="[
        `stroke-${fill} fill-${fill}`,
        [isUserOnMobile() ? [rotate ? 'translate-y-[0px]' : 'translate-y-[0.6px]'] : '']
      ]"
      width="100%"
      viewBox="0 0 1440 81"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg">
      <path :class="{ rotate: rotate }" d="M0 80.6055C492 -99.3945 1440 80.6055 1440 80.6055H0Z" />
    </svg>
  </div>
</template>

<script lang="ts" setup>
import { isUserOnMobile } from '@/utilities'

interface Props {
  fill?: string
  rotate?: boolean
}

withDefaults(defineProps<Props>(), {
  fill: 'otivo-blue',
  rotate: false
})
</script>

<style lang="scss" scoped>
.rotate {
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}
</style>
