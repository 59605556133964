<template>
  <div v-if="isItMedibankstaff">
    <fitness-test-question-label :question-number="questionNumber" :question="label" />

    <input-box-buttons
      name="medibank-divisions"
      class="px-5 md:px-0"
      :buttons="getDivisions"
      min-width="52"
      @click="
        (value) => {
          $emit('click', value)
        }
      "
      :selected="selected" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FitnessTestQuestionLabel from '../FitnessTest/QuestionsLabel/FitnessTestQuestionLabel.vue'

import InputBoxButtons from '../BasicInputs/InputBoxButtons.vue'

export default {
  name: 'MediBankDivision',
  components: { InputBoxButtons, FitnessTestQuestionLabel },
  props: {
    questionNumber: {
      type: String,
      default: '',
    },
    selected: {
      type: String,
      default: '',
      required: true,
    },
    label: {
      type: String,
      default: 'Your company',
    },
  },
  computed: {
    ...mapGetters(['isItMedibankstaff']),
    getDivisions() {
      return [
        { name: 'Customer and Portfolio AHM', value: 'ahm' },
        { name: 'Customer and Portfolio CustomerConnect', value: 'customer_connect' },
        { name: 'Customer and Portfolio Retail', value: 'retails' },
        { name: 'Customer and Portfolio All other', value: 'customer_and_portfolio_all_other' },
        { name: 'Enterprise Services', value: 'enterprise_services' },
        {
          name: 'Healthcare and Strategy Health Services (inc Telehealth)',
          value: 'health_services',
        },
        { name: 'Healthcare and Strategy – All other', value: 'health_care_all_other' },
        { name: 'HealthStrong', value: 'health_strong' },
        { name: 'Home Support Services', value: 'home_support_services' },
        { name: 'Care Complete', value: 'care_complete' },
        {
          name: 'P&C / MDs Office / Board/ Ext Affairs/Legal, Gov and Reg Affairs',
          value: 'gov_and_reg',
        },
        { name: 'Technology and Operations (Tech and Ops)', value: 'tech_and_ops' },
      ]
    },
  },
}
</script>

<style scoped></style>
