import getApiInstance from '@/services/Api'
import { AxiosResponse } from 'axios'

const api = getApiInstance()
type SuperContributionResponse = {
  max_monthly_contribution: number
  min_monthly_contribution: number
  min_concessional_lump_sum: number
  min_non_concessional_lump_sum: number
  system: {
    sab_at_retirement: number
    years_super_lasts: number
  }
  recommendation: {
    sab_at_retirement: number
    years_super_lasts: number
  }
} & SuperRetirementOptions

type ContributionProjections = {
  super_contributions: {
    recommendation_projection: ContributionProjection
    system_projection?: ContributionProjection
    lump_sum_recommendation_projection?: ContributionProjection
  }
  cashflow_impact: number
  cashflow_impact_text?: string
}

type ContributionProjection = {
  id: number
  account_id: number
  age_retirement_income_falls: number
  number_of_years: number
  contribution: number
  sab_at_retirement: number
  desired_income: number
  partner?: PartnerContributionProjection
  created_at: string
  updated_at: string
}

type PartnerContributionProjection = {
  partner_age_retirement_income_falls: number
  partner_number_of_years: number
  partner_sab_at_retirement: number
  partner_contribution: number
}

export type SuperContributionPayload = {
  desired_retirement_income: number
  desired_retirement_age: number
  monthly_contribution: number
  projection_only?: boolean
  auth0id?: string
}

export type SuperRetirementOptions = {
  recommended_before_tax_super_contributions?: number
  additional_before_tax_super_contributions: number
  desired_retirement_income: number
  desired_retirement_age: number
  cashflow_impact?: number
  recommendation_text?: string
  recommendation_why?: string
  esg_sab?: number
  esg_years?: number
  recommended_sab?: number
  recommended_years?: number
  original_recommendation_amount?: number
  original_recommendation_cashflow_impact?: number
}

const actions = [
  'system',
  'recommendation',
  'system_projection',
  'recommendation_projection',
] as const
export type RetirementInvestmentAction = (typeof actions)[keyof typeof actions]

export default {
  getAllSuperPlans() {
    return api.Get('/super')
  },

  getASuperPlans(id) {
    return api.Get(`/super/${id}`)
  },

  postRetirementPlan(payload) {
    return api.Post('/retire', payload)
  },

  postSuperPlans(supers) {
    return api.Post('/super', { data: supers })
  },

  postSuperPlan(payload) {
    const data = []
    data.push(payload)
    return api.Post('/super', { data: data })
  },

  removeSuperPlan(id) {
    return api.Delete(`/super/${id}`)
  },

  postRetireInvestAssumptions(payload) {
    return api.Post('/assumptions', payload)
  },

  getRetireInvestAssumptions() {
    return api.Get('/assumptions')
  },

  getAssetsAndIncomeChartData() {
    return api.Get('/projections')
  },

  getIncomeProjectionData(
    action?: RetirementInvestmentAction,
    sacrifice?: number,
    age?: number,
    income?: number,
    lumpSumContribution?: number,
  ) {
    return api.Get('/income-projections', {
      params: { action, sacrifice, age, income, lumpSumContribution },
    })
  },

  getAssetsProjectionData(
    action?: RetirementInvestmentAction,
    sacrifice?: number,
    age?: number,
    income?: number,
    lumpSumContribution?: number,
  ) {
    return api.Get('/assets-projections', {
      params: { action, sacrifice, age, income, lumpSumContribution },
    })
  },

  getPreservationAges() {
    return api.Get('/preservationAges')
  },

  async getUserPreservationAge() {
    try {
      const res = await api.Get('/modularised_advice/client-preservation-age')
      if (!res) {
        throw new Error('No response from client preservation age')
      }
      return res.data.preservation_age || 0
    } catch (e) {
      console.error('error fetching client preservation age', e)
    }
  },

  getPensionAges() {
    return api.Get('/pensionAges')
  },

  getSuperContactDetails(idsArray: Array<number | string>) {
    return api.Post('/super/contactDetails', { superIds: idsArray })
  },

  getSuperAverages(ageBracket) {
    return api.Post('/super/averageOptimisation', ageBracket)
  },

  getSuperGuarantee() {
    return api.Get('/super_values')
  },

  getSuperContributions(
    auth0id?: string,
    cont_type?: 'salary' | 'concessional' | 'non_concessional',
  ): Promise<AxiosResponse<SuperContributionResponse>> {
    return api.Get('/cfs/super/super-contributions', {
      params: {
        cfsClientAuthId: auth0id,
        cont_type: cont_type,
      },
    })
  },

  resetSuperContributionAdvice(
    action: 'salary_sacrifice' | 'concessional' | 'non_concessional',
    cfsClientAuthId?: string,
  ): Promise<AxiosResponse<SuperRetirementOptions>> {
    return api.Get('/cfs/super/reset-super-contributions', {
      params: {
        cfsClientAuthId: cfsClientAuthId,
        action: action,
      },
    })
  },

  setSuperContributions(
    payload: SuperRetirementOptions,
  ): Promise<AxiosResponse<SuperRetirementOptions>> {
    return api.Post('/cfs/super/super-contributions', payload)
  },

  fetchSuperProjections(
    payload: SuperContributionPayload,
  ): Promise<AxiosResponse<ContributionProjections>> {
    return api.Post('cfs/super/contribution-projection', payload)
  },

  fetchSuperLumpSumProjections(payload: {
    lumpSumContribution: number
    contributionType: 'concessional' | 'non_concessional'
    cfsClientAuthId?: string
  }): Promise<AxiosResponse<ContributionProjections>> {
    return api.Post('cfs/super/lump-sum-projection', payload)
  },
}
