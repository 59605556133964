<template>
  <div class="max-w-[1100px] mx-auto py-[40px]">
    <h1 class="text-otivo-blue">Colours</h1>

    <h4 class="mt-[40px]">We have two main sets of colour palettes — primary and supporting.</h4>

    <ColourWheel class="mt-[40px] max-w-100%" />
    <ColoursSpread class="mt-[40px] max-w-100%" />

    <h3 class="mt-[40px] text-otivo-blue">Primary colours</h3>
    <p class="mt-[20px] intro-1">
      We have two main primary colours - Otivo blue and Otivo red. Along with forming our logo,
      these are our hero colours that introduce the world to Otivo. As such they’re only used for
      positive news.
    </p>

    <div class="mt-[40px] grid md:grid-cols-3 gap-[40px]">
      <div>
        <h5 class="bold text-otivo-blue">Otivo blue</h5>
        <p class="mt-[20px] paragraph-1">
          Otivo blue is our dominant colour. We use it across our interactive elements such as
          buttons, links, inputs, etc. We use dark blue to highlight text, while the lighter hues
          are used in our backgrounds.
        </p>
      </div>

      <div class="flex flex-wrap gap-[16px] nick md:col-span-2">
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-otivo-blue text-white">Otivo blue</p>
          <p class="swatch-text paragraph-1">#0064FF</p>
        </div>
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-blue-6 text-blue-1">Blue 6</p>
          <p class="swatch-text paragraph-1">#F4FAFF</p>
        </div>
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-blue-5 text-blue-1">Blue 5</p>
          <p class="swatch-text paragraph-1">#D7F5FF</p>
        </div>
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-blue-4 text-blue-1">Blue 4</p>
          <p class="swatch-text paragraph-1">#A0E6FA</p>
        </div>
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-blue-3 text-white">Blue 3</p>
          <p class="swatch-text paragraph-1">#70D6FF</p>
        </div>
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-blue-2 text-white">Blue 2</p>
          <p class="swatch-text paragraph-1">#62A3FF</p>
        </div>
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-blue-1 text-white">Blue 1</p>
          <p class="swatch-text paragraph-1">#0037A1</p>
        </div>
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-midnight text-white">Midnight</p>
          <p class="swatch-text paragraph-1">#000032</p>
        </div>
      </div>
    </div>

    <div class="mt-[40px] grid md:grid-cols-3 gap-[40px]">
      <div>
        <h5 class="bold text-otivo-red">Otivo red</h5>
        <p class="mt-[20px] paragraph-1">
          Otivo red is our secondary colour. Because of its intensity, we use it sparingly to call
          out all our good stuff like when we're providing advice.
        </p>
      </div>
      <div class="swatch otivo-drop-shadow">
        <p class="swatch-colour button-1 bg-otivo-red text-white">Otivo red</p>
        <p class="swatch-text paragraph-1">#FF0000</p>
      </div>
    </div>

    <div class="mt-[40px] w-100% h-[20px] bg-blue-6"></div>

    <h3 class="mt-[40px] text-grey-2">Supporting colours</h3>
    <p class="mt-[20px] intro-1">
      Because our primary palette is so INTENSE, we need a batch of colours that play a more
      supportive role —hence our supporting palette. We use these mostly in secondary elements like
      background panels, graphs and secondary UI elements.
    </p>

    <div class="mt-[40px] grid md:grid-cols-3 gap-[40px]">
      <div>
        <h5 class="bold text-grey-2">Grey</h5>
        <p class="mt-[20px] paragraph-1">
          Grey is used widely in our everyday UI elements, from text, form fields, backgrounds, and
          dividers.
        </p>
      </div>
      <div class="flex flex-wrap gap-[16px] nick md:col-span-2">
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-grey-5 text-grey-2">Grey 5</p>
          <p class="swatch-text paragraph-1 text-grey-2">#FAFAFA</p>
        </div>
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-grey-4 text-grey-2">Grey 4</p>
          <p class="swatch-text paragraph-1 text-grey-2">#EEEBEB</p>
        </div>
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-grey-field text-grey-2">Grey field</p>
          <p class="swatch-text paragraph-1 text-grey-2">#DDDDDD</p>
        </div>
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-grey-3 text-white">Grey 3</p>
          <p class="swatch-text paragraph-1 text-grey-2">#A0A0A0</p>
        </div>
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-grey-2 text-white">Grey 2</p>
          <p class="swatch-text paragraph-1 text-grey-2">#696969</p>
        </div>
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-grey-1 text-white">Grey 1</p>
          <p class="swatch-text paragraph-1 text-grey-2">#333333</p>
        </div>
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-grey-logo text-white">Grey logo</p>
          <p class="swatch-text paragraph-1 text-grey-2">#222222</p>
        </div>
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-black text-white">Black</p>
          <p class="swatch-text paragraph-1 text-grey-2">#000000</p>
        </div>
      </div>
    </div>

    <div class="mt-[40px] grid md:grid-cols-3 gap-[40px]">
      <div>
        <h5 class="bold text-green-2">Success</h5>
        <p class="mt-[20px] paragraph-1">
          While our two primary colours are the main ways we express positivity, we also use green
          in secondary instances to represent success. You'll notice it in icons, buttons, and
          graphs to signify achievements and positive results.
        </p>
      </div>

      <div class="flex flex-wrap gap-[16px] nick md:col-span-2">
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-green-5 text-green-2">Green 5</p>
          <p class="swatch-text paragraph-1 text-grey-2">#DCFFEC</p>
        </div>
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-green-4 text-white">Green 4</p>
          <p class="swatch-text paragraph-1 text-grey-2">#03F0C5</p>
        </div>
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-green-3 text-white">Green 3</p>
          <p class="swatch-text paragraph-1 text-grey-2">#01CC9B</p>
        </div>
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-green-2 text-white">Green 2</p>
          <p class="swatch-text paragraph-1 text-grey-2">#00966E</p>
        </div>
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-green-1 text-white">Green 1</p>
          <p class="swatch-text paragraph-1 text-grey-2">#1C545C</p>
        </div>
      </div>
    </div>

    <div class="mt-[40px] grid md:grid-cols-3 gap-[40px]">
      <div>
        <h5 class="bold text-gold-1">Feature</h5>
        <p class="mt-[20px] paragraph-1">
          These colours act as a highlight within secondary charts and graphs.
        </p>
      </div>

      <div class="flex flex-wrap gap-[16px] nick md:col-span-2">
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-gold-4 text-red-dark">Gold 4</p>
          <p class="swatch-text paragraph-1 text-grey-2">#FEFFFA</p>
        </div>
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-gold-3 text-red-dark">Gold 3</p>
          <p class="swatch-text paragraph-1 text-grey-2">#FFDC00</p>
        </div>
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-gold-2 text-white">Gold 2</p>
          <p class="swatch-text paragraph-1 text-grey-2">#FDB900</p>
        </div>
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-gold-1 text-white">Gold 1</p>
          <p class="swatch-text paragraph-1 text-grey-2">#FD8900</p>
        </div>
      </div>
    </div>

    <div class="mt-[40px] grid md:grid-cols-3 gap-[40px]">
      <div>
        <h5 class="mt-[40px] bold text-red-dark">Error</h5>
        <p class="mt-[20px] paragraph-1">
          These colours are used to denote there is an error that requires immediate attention.
        </p>
      </div>

      <div class="flex flex-wrap gap-[16px] nick md:col-span-2">
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-red-light text-red-dark">Red light</p>
          <p class="swatch-text paragraph-1 text-grey-2">#E9CAC5</p>
        </div>
        <div class="swatch otivo-drop-shadow">
          <p class="swatch-colour button-1 bg-red-dark text-white">Red dark</p>
          <p class="swatch-text paragraph-1 text-grey-2">#982C17</p>
        </div>
      </div>
    </div>

    <div class="mt-[40px] w-100% h-[20px] bg-blue-6"></div>

    <h3 class="mt-[40px] text-grey-1">Gradients</h3>
    <p class="mt-[20px] intro-1">
      We sometimes use gradients to highlight UI elements and add depth and visual interest.
    </p>

    <div class="mt-[40px] grid md:grid-cols-3 gap-[40px]">
      <div>
        <h5 class="bold text-grey-1">Gradients</h5>
        <p class="mt-[20px] paragraph-1">
          A gradient is a smooth blend of colours, gradually shifting from one to another. Gradients
          can be linear or radial, offering versatility in design.
        </p>
      </div>

      <div class="flex flex-wrap gap-[20px] md:col-span-2">
        <div class="gradient-swatch otivo-drop-shadow">
          <div class="otivo-gradient h-[128px]"></div>
          <div class="p-[10px]">
            <p class="button-1">Gradient</p>
            <p class="paragraph-1">#D7F5FF</p>
          </div>
        </div>
        <div class="gradient-swatch otivo-drop-shadow">
          <div class="graph-gradient h-[128px]"></div>
          <div class="p-[10px]">
            <p class="button-1">Graph gradient</p>
            <p class="paragraph-1">#A8E0FF</p>
          </div>
        </div>
        <div class="gradient-swatch otivo-drop-shadow">
          <div class="draw-gradient h-[128px]"></div>
          <div class="p-[10px]">
            <p class="button-1">Draw gradient</p>
            <p class="paragraph-1">#0037A1</p>
          </div>
        </div>
        <div class="gradient-swatch otivo-drop-shadow">
          <div class="tab-gradient h-[128px]"></div>
          <div class="p-[10px]">
            <p class="button-1">Tab gradient</p>
            <p class="paragraph-1">#FAFAFA</p>
          </div>
        </div>
        <div class="gradient-swatch otivo-drop-shadow">
          <div class="card-gradient h-[128px]"></div>
          <div class="p-[10px]">
            <p class="button-1">Card gradient</p>
            <p class="paragraph-1">#C9ECFF</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ColourWheel from '@/components/SVGS/ColourWheel.vue'
import ColoursSpread from '@/components/SVGS/ColoursSpread.vue'
</script>

<style scoped>
.swatch {
  @apply rounded-[10px] w-[99px] h-fit overflow-hidden;
}
.gradient-swatch {
  @apply rounded-[10px] w-[280px] h-fit overflow-hidden;
}

.swatch-colour {
  @apply py-[30px] px-[10px] text-center;
}

.swatch-text {
  @apply text-center p-[10px];
}

.otivo-gradient {
  background: var(--otivo-gradient);
}

.graph-gradient {
  background: var(--graph-gradient);
}

.draw-gradient {
  background: var(--draw-gradient);
}
.tab-gradient {
  background: var(--tab-gradient);
}
.card-gradient {
  background: var(--card-gradient);
}
</style>
