import _ from 'lodash'

const getAllCurrentComplianceFlags = () => {
  return {
    OPTIMIZE_INSURANCE_SUPER_COMPLIANCE: false,
    GUIDANCE_CLIENT_CONSENTED: false
  }
}

const state = () => ({
  flags: getAllCurrentComplianceFlags()
})

const getters = {
  getAllComplianceFlags(state) {
    return state.flags
  },

  getComplianceFlag(state) {
    return (flag) => {
      return state.flags[flag]
    }
  }
}

const mutations = {
  SET_COMPLIANCE_FLAGS(state, { flag, value }) {
    state.flags[flag] = value
  },

  SET_ALL_COMPLIANCE_FLAGS(state, payload) {
    state.flags = _.cloneDeep({
      ...getAllCurrentComplianceFlags(),
      ...payload
    })
  }
}

const actions = {
  setAllComplianceFlags({ commit }, flags) {
    const payload = {}
    flags.map((el) => {
      payload[el.compliance_identifier] = el.complied
      return el
    })
    commit('SET_ALL_COMPLIANCE_FLAGS', payload)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
