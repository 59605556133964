import NextStepsService from '@/services/NextStepsService'
import _ from 'lodash'

const state = () => ({
  nextSteps: {
    debt: { loading: true },
    savings: { loading: true },
    retirement: { loading: true }
  }
})

const getters = {
  allNextSteps(state) {
    return state.nextSteps
  },
  debtNextSteps(state) {
    return state.nextSteps.debt
  },
  savingsNextSteps(state) {
    return state.nextSteps.savings
  },
  retirementNextSteps(state) {
    return state.nextSteps.retirement
  }
}

const mutations = {
  SET_ALL_NEXT_STEPS(state, nextSteps) {
    ;['debt', 'savings', 'retirement'].map((type) => {
      if (nextSteps[type] === undefined) {
        nextSteps[type] = _.cloneDeep(state.nextSteps[type])
      }
      nextSteps[type].loading = false
    })

    // will trigger all getters
    state.nextSteps = nextSteps
  },
  SET_DEBT_NEXT_STEPS(state, nextSteps) {
    ;['debt'].map((type) => {
      if (nextSteps[type] === undefined) {
        nextSteps[type] = _.cloneDeep(state.nextSteps[type])
      }
      nextSteps[type].loading = false
    })

    // will trigger all getters
    state.nextSteps.debt = nextSteps.debt
  },
  SET_SAVINGS_NEXT_STEPS(state, nextSteps) {
    ;['savings'].map((type) => {
      if (nextSteps[type] === undefined) {
        nextSteps[type] = _.cloneDeep(state.nextSteps[type])
      }
      nextSteps[type].loading = false
    })

    // will trigger all getters
    state.nextSteps.savings = nextSteps.savings
  },
  SET_RETIREMENT_NEXT_STEPS(state, nextSteps) {
    ;['retirement'].map((type) => {
      if (nextSteps[type] === undefined) {
        nextSteps[type] = _.cloneDeep(state.nextSteps[type])
      }
      nextSteps[type].loading = false
    })

    // will trigger all getters
    state.nextSteps.retirement = nextSteps.retirement
  },
  SET_LOADING_FOR_ALL_NEXT_STEPS(state, value) {
    ;['debt', 'savings', 'retirement'].map((type) => {
      state.nextSteps[type].loading = value
    })
  },
  SET_LOADING_FOR_DEBT_NEXT_STEPS(state, value) {
    ;['debt'].map((type) => {
      state.nextSteps[type].loading = value
    })
  },
  SET_LOADING_FOR_SAVINGS_NEXT_STEPS(state, value) {
    ;['savings'].map((type) => {
      state.nextSteps[type].loading = value
    })
  },
  SET_LOADING_FOR_RETIREMENT_NEXT_STEPS(state, value) {
    ;['retirement'].map((type) => {
      state.nextSteps[type].loading = value
    })
  }
}

const actions = {
  fetchAllNextSteps({ dispatch, commit }) {
    commit('SET_LOADING_FOR_ALL_NEXT_STEPS', true)
    return NextStepsService.getAllNextSteps()
      .then((res) => {
        dispatch('setAllNextSteps', res.data.data)
      })
      .catch((err) => {
        commit('SET_LOADING_FOR_ALL_NEXT_STEPS', false)
        throw err
      })
  },

  fetchDebtNextSteps({ dispatch, commit }) {
    commit('SET_LOADING_FOR_DEBT_NEXT_STEPS', true)
    return NextStepsService.getDebtNextSteps()
      .then((res) => {
        dispatch('setDebtNextSteps', res.data.data)
      })
      .catch((err) => {
        commit('SET_LOADING_FOR_DEBT_NEXT_STEPS', false)
        throw err
      })
  },
  fetchSavingsNextSteps({ dispatch, commit }) {
    commit('SET_LOADING_FOR_SAVINGS_NEXT_STEPS', true)
    return NextStepsService.getSavingsNextSteps()
      .then((res) => {
        dispatch('setSavingsNextSteps', res.data.data)
      })
      .catch((err) => {
        commit('SET_LOADING_FOR_SAVINGS_NEXT_STEPS', false)
        throw err
      })
  },
  fetchRetirementNextSteps({ dispatch, commit }) {
    commit('SET_LOADING_FOR_RETIREMENT_NEXT_STEPS', true)
    return NextStepsService.getRetirementNextSteps()
      .then((res) => {
        dispatch('setRetirementNextSteps', res.data.data)
      })
      .catch((err) => {
        commit('SET_LOADING_FOR_RETIREMENT_NEXT_STEPS', false)
        throw err
      })
  },

  setAllNextSteps({ commit, dispatch }, nextSteps) {
    commit('SET_ALL_NEXT_STEPS', nextSteps)
  },
  setDebtNextSteps({ commit, dispatch }, nextSteps) {
    commit('SET_DEBT_NEXT_STEPS', nextSteps)
  },
  setSavingsNextSteps({ commit, dispatch }, nextSteps) {
    commit('SET_SAVINGS_NEXT_STEPS', nextSteps)
  },
  setRetirementNextSteps({ commit, dispatch }, nextSteps) {
    commit('SET_RETIREMENT_NEXT_STEPS', nextSteps)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
