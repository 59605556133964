<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0.00380056C7.48357 0.00370681 5.05962 0.95811 3.21178 2.67659C1.36393 4.39507 0.228061 6.75127 0.0307916 9.27507C-0.166478 11.7989 0.58936 14.3047 2.1475 16.2927C3.70564 18.2806 5.95152 19.6044 8.43703 20V12.9651H5.89886V10.0637H8.43703V7.84675C8.43703 5.3256 9.93007 3.93383 12.2169 3.93383C12.9664 3.94249 13.7142 4.00603 14.4546 4.12396V6.59568H13.1978C11.9561 6.59568 11.5686 7.37143 11.5686 8.16808V10.0542H14.3317L13.8876 12.9613H11.563V19.9962C14.0485 19.6006 16.2944 18.2768 17.8525 16.2888C19.4106 14.3009 20.1665 11.7951 19.9692 9.27127C19.7719 6.74747 18.6361 4.39127 16.7882 2.67279C14.9404 0.954309 12.5164 -9.37426e-05 10 6.90599e-09V0.00380056Z"
      :class="`fill-${fill1}`" />
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'grey-3'
})
</script>
