export const getRootUrl = (userRole: string): string => {
  switch (userRole) {
    case 'superadmin':
      return '/admin-portal/home'
    case 'callcentre':
      return '/admin-portal/search-users'
    case 'backoffice':
      return '/admin-portal/reports'
    case 'admin':
    case 'standard':
    case 'member':
      return '/dashboard'
    default:
      return '/'
  }
}
