import _ from 'lodash'
import moment from 'moment'

import VueScrollTo from 'vue-scrollto'

const scrollToBottom = (elementId) => {
  // run it after a sec as let the store update components
  setTimeout(function () {
    let container = document.getElementById(elementId)
    container = container === null ? document.getElementsByClassName(elementId)[0] : container
    if (!container) return
    const top = container.scrollHeight
    container.scroll({ top: top, behavior: 'smooth' })
  }, 1)
}

/**
 * @param {string} elementId
 */
const scrollIntoView = (elementId) => {
  // run it after a sec as let the store update components
  setTimeout(function () {
    let container = document.getElementById(elementId)
    container = container === null ? document.getElementsByClassName(elementId)[0] : container
    if (container) {
      VueScrollTo.scrollTo(`#${elementId}`, 500, {})
      // container.scrollIntoView({behavior: behaviour, block: 'start', inline: 'start'})
    }
  }, 1)
}

const getFlooredAge = (dob) => {
  return Math.floor(calculateAge(new Date(dob)))
}
const calculateAge = (birthday) => {
  const age = moment().diff(
    moment([birthday.getFullYear(), birthday.getMonth(), birthday.getDate()]),
    'years',
    true
  )
  return _.isNaN(age) ? 0 : age
}

const formatCurrency = (number) => {
  if (isNaN(number) || number === null || number === '$') {
    return null
  }

  let val = getStringToNumber(number)
  const sign = val < 0 ? '-$' : '$'
  val = val < 0 ? val * -1 : val
  return sign + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const getStringToNumber = (number) => {
  number = clearNumberString(number)
  return Math.ceil(Number(number))
}

// return a number or text
const clearNumberString = (number) => {
  if (typeof number !== 'string') return number
  return number.replace(/[^0-9-.]+/g, '')
}

const formatPercentage = (number) => {
  return number && isNaN(number) ? '0%' : `${number}%`
}

const formatMonths = (actualMonths) => {
  let { years, months, days } = formatMonthsWithDays(actualMonths)
  // if minimum days of a month 0 days and increase month
  if (days > 0) {
    days = 0
    ++months
  }

  // if months are above 11 then increase year by one
  if (months > 11) {
    months = 0
    ++years
  }

  return { years, months }
}

const abbrNum = (number, decPlaces = 2) => {
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces)

  // Enumerate number abbreviations
  const abbrev = ['k', 'm', 'b', 't']

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    const size = Math.pow(10, (i + 1) * 3)

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * decPlaces) / size) / decPlaces

      // Handle special case where we round up to the next abbreviation
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1
        i++
      }

      // Add the letter for the abbreviation
      number += abbrev[i]

      // We are done... stop
      break
    }
  }

  return number
}

const formatMonthsWithDays = (months) => {
  if (isFinite(months)) {
    // default global values
    const actualMonthsInYear = 12
    const noOfDaysInAMonth = 30.4166666
    const actualYears = months / actualMonthsInYear

    let years = Math.floor(actualYears)

    const remainingYear = actualYears - years
    const remainingYearInMonths = remainingYear * actualMonthsInYear

    let Months = Math.floor(remainingYearInMonths)
    const remainingMonths = remainingYearInMonths - Months

    let days = Math.floor(remainingMonths * noOfDaysInAMonth)

    // Incrementing after the 27th day, 0 days and increase month
    if (days > 27) {
      days = 0
      ++Months
    }

    // if months are above 11 then increase year by one
    if (Months > 11) {
      Months = 0
      ++years
    }
    return { years: years, months: Months, days: days }
  }
  return { years: 0, months: 0, days: 0 }
}

const getStringFormattedMonths = (numberOfMonths) => {
  let formattedMonths = ''
  const months = numberOfMonths % 12
  const temp = numberOfMonths - months
  const years = temp / 12

  if (years > 0) {
    formattedMonths = `${years}y `
  }

  if (months >= 11.5) {
    formattedMonths = `${years + 1}y `
  } else if (months > 0) {
    formattedMonths += `${months.toFixed(0)}m `
  }

  if (years === 0 && months === 0) {
    formattedMonths = '-'
  }

  return formattedMonths
}

const getStringFormattedMonthsWithDays = (numberOfMonths) => {
  let formattedMonths = ''
  const { years, months, days } = formatMonthsWithDays(numberOfMonths)

  if (years > 0) {
    formattedMonths = `${years}y `
  }

  if (months > 0) {
    formattedMonths += `${months}m `
  }

  if (days > 0) {
    formattedMonths += `${days}d `
  }

  if (years === 0 && months === 0) {
    formattedMonths = '-'
  }
  return formattedMonths
}

export const removeSpaces = (value) => {
  return value.replace(/\s/g, '')
}

const capitalize = (value) => {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

const isInViewport = (elementId) => {
  let container = document.getElementById(elementId)
  container = container === null ? document.getElementsByClassName(elementId)[0] : container
  if (container) {
    const distance = container.getBoundingClientRect()
    return (
      distance.top >= 0 &&
      distance.left >= 0 &&
      distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      distance.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }
  return false
}

const getMonthNameByIndex = (monthIndex) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
  return monthNames[monthIndex]
}

const getFullMonthNameByIndex = (monthIndex) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  return monthNames[monthIndex]
}

const getNiceDate = (date) => {
  const formattedDate = date.getDate() + ' ' + getFullMonthNameByIndex(date.getMonth())
  return formattedDate
}

const isItMobile = () => {
  return !!(
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  )
}

const monthsToFortnights = (months) => {
  return Math.ceil((months / 12) * 26)
}

const monthsToWeeks = (months) => {
  return Math.ceil((months / 12) * 52)
}

const weeksToMonths = (weeks) => {
  return Math.ceil((weeks / 52) * 12)
}

const fortNightsToMonths = (fortNights) => {
  return Math.ceil((fortNights / 26) * 12)
}

const stringToHash = (string) => {
  let hash = 0

  if (string.length === 0) return hash

  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash
  }

  return hash
}

const getBrowserName = () => {
  return navigator.userAgent.toLowerCase()
}

const calculatePercentage = (numerator, denominator, toFixed = 2) => {
  return Number(((numerator / denominator) * 100).toFixed(toFixed))
}

const calculatePercentageAndFormat = (numerator, denominator, toFixed = 2) => {
  return formatPercentage(calculatePercentage(numerator, denominator, toFixed))
}

const replaceUnderScores = (text) => {
  return text.replace(/_/g, ' ')
}

const capitalizeAllCharactersAfterSpace = (text) => {
  return text
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

const convertCamelCase = (text) => {
  // insert a space before all caps
  return text.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase()
  })
}

const stringifyErrorObject = (errors) => {
  // errors is object
  let error = ''
  _.some(errors, (el, index) => {
    if (el !== '') {
      error += `${capitalize(index.split('_').join(' '))}, `
    }
  })

  if (error !== '') {
    error = 'Please check - ' + error
    // remove extra , and space
    error = error.slice(0, -2)
  }

  return error
}

const getRandomColor = () => {
  const colors = []

  colors.push(getRootColor('grey-1'))
  colors.push(getRootColor('grey-2'))
  colors.push(getRootColor('grey-3'))
  colors.push(getRootColor('grey-4'))
  colors.push(getRootColor('blue-1'))
  colors.push(getRootColor('blue-2'))
  colors.push(getRootColor('blue-3'))
  colors.push(getRootColor('blue-4'))
  colors.push(getRootColor('blue-5'))
  colors.push(getRootColor('pink-1'))
  colors.push(getRootColor('pink-2'))
  colors.push(getRootColor('pink-3'))
  colors.push(getRootColor('pink-4'))
  colors.push(getRootColor('gold-1'))
  colors.push(getRootColor('gold-2'))
  colors.push(getRootColor('gold-3'))
  colors.push(getRootColor('gold-4'))
  colors.push(getRootColor('green-1'))
  colors.push(getRootColor('green-2'))
  colors.push(getRootColor('green-3'))
  colors.push(getRootColor('green-4'))
  colors.push(getRootColor('green-5'))
  colors.push(getRootColor('purple-1'))
  colors.push(getRootColor('purple-2'))
  colors.push(getRootColor('purple-3'))
  colors.push(getRootColor('purple-4'))
  colors.push(getRootColor('purple-5'))
  colors.push(getRootColor('red-dark'))
  colors.push(getRootColor('red-light'))

  return colors[Math.floor(Math.random() * colors.length)]
}

const getRootColor = (variable) => {
  const style = getComputedStyle(document.body)
  return style.getPropertyValue(`--${variable}`)
}

const setRootColor = (variable, value) => {
  const root = document.documentElement
  root.style.setProperty(`--${variable}`, value)
}

const formatNumber = (number) => {
  if (number === null || number === undefined || isNaN(number)) return number
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
const avgAustLifeExpectancy = () => 84

export {
  avgAustLifeExpectancy,
  scrollToBottom,
  scrollIntoView,
  calculateAge,
  getFlooredAge,
  formatCurrency,
  formatPercentage,
  capitalize,
  formatMonths,
  formatMonthsWithDays,
  getStringFormattedMonths,
  isInViewport,
  abbrNum,
  getMonthNameByIndex,
  getFullMonthNameByIndex,
  isItMobile,
  clearNumberString,
  getStringToNumber,
  monthsToFortnights,
  monthsToWeeks,
  weeksToMonths,
  fortNightsToMonths,
  getStringFormattedMonthsWithDays,
  getNiceDate,
  stringToHash,
  getBrowserName,
  calculatePercentage,
  calculatePercentageAndFormat,
  replaceUnderScores,
  capitalizeAllCharactersAfterSpace,
  convertCamelCase,
  stringifyErrorObject,
  getRandomColor,
  getRootColor,
  setRootColor,
  formatNumber
}
