import ContentService from '@/services/ContentService'

const state = () => ({
  coupon: {
    code: '',
    discount: 0,
    message: '',
    type: '',
    valid: false
  }
})

const getters = {
  getCoupon(state) {
    return state.coupon
  }
}

const mutations = {
  VALIDATE_COUPON(state, coupon) {
    state.coupon.code = coupon.code
    state.coupon.discount = coupon.discount
    state.coupon.message = coupon.message
    state.coupon.type = coupon.type
    state.coupon.valid = coupon.valid
  }
}

const actions = {
  validateCoupon({ dispatch }, coupon) {
    return ContentService.validateCoupon(coupon)
      .then((response) => {
        dispatch('setCoupon', response.data)
      })
      .catch(() =>
        dispatch('setCoupon', {
          code: '',
          discount: 0,
          message: '',
          type: '',
          valid: false
        })
      )
  },

  setCoupon({ commit }, coupon) {
    commit('VALIDATE_COUPON', coupon)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
