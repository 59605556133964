<template>
  <div
    id="portal-reporting"
    class="py-[80px] px-[50px] bg-blue-6 w-100% h-100% flex justify-center">
    <div class="flex w-100% gap-[40px] max-w-[1920px]">
      <div class="w-100% flex flex-col flex-grow">
        <div class="flex flex-row justify-between">
          <h4 class="text-blue-1">{{ title }}</h4>
        </div>
        <div class="w-100%">
          <div id="verification-section">
            <AccountVerificationMetrics :key="updates" />

            <AccountVerificationTable @updated="refreshStats" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import AccountVerificationMetrics from '@/components/CallcentrePortal/Verification/AccountVerificationMetrics.vue'
import AccountVerificationTable from '@/components/CallcentrePortal/Verification/AccountVerificationTable.vue'

const route = useRoute()
const title = computed(() => route.meta.title)
const updates = ref(0)
const refreshStats = () => updates.value++
</script>

<style scoped lang="scss"></style>
