<template>
  <div ref="menu">
    <div
      class="dropdown-heading text-white flex flex-row justify-center align-center gap-4"
      @click.prevent="open = !open">
      <div class="text-[16px] font-semibold">
        {{ name }}
      </div>
      <Chevron class="self-center" :class="[{ rotate: open }]" />
    </div>
    <div v-show="open">
      <slot name="dropdown-items" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import Chevron from '@/components/SVGS/Chevron.vue'
interface Props {
  name: string
}
defineProps<Props>()
defineEmits(['change'])

const open = ref(false)
const menu = ref()

onClickOutside(menu, (event) => {
  if (open.value) open.value = false
})
</script>

<style lang="scss" scoped>
.rotate {
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}
</style>
