<template>
  <div>
    <div class="headline-3 text-grey-1">
      {{ pageContent.heading }}
    </div>
    <p v-if="selectedCurrentOption" class="intro-1 text-grey-1 mt-[20px]">
      {{ pageContent.subHeading2 }}
    </p>
    <p v-else class="intro-1 text-grey-1 mt-[20px]">
      {{ pageContent.subHeading }}
    </p>

    <OtivoButton
      @click="onClickTakeBack"
      colour="blue"
      size="large"
      class="mt-[40px]"
      data-test="backToOtivoBtn">
      {{ pageContent.buttonText }}
    </OtivoButton>
  </div>
</template>

<script setup lang="ts">
import OtivoButton from '@/components/OtivoButton.vue'
import { ComponentContentKeysType } from '@/types/GlobalTypes'

type Props = {
  pageContent: ComponentContentKeysType
  selectedCurrentOption: boolean
  onClickTakeBack: () => void
}

defineProps<Props>()
</script>

<style scoped></style>
