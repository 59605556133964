import { cloneObject } from '@/utilities.ts'
import InvestmentOption from '@/types/api/super/InvestmentOption.ts'

export default (): InvestmentOption => {
  return cloneObject({
    chant_west_investment_name: '',
    chant_west_investment_id: null,
    percent_allocation: 100,
    is_term_deposit: false,
  })
}
