import ContentService from '@/services/ContentService'

const state = () => ({
  userHistory: [],
  userHistoryLoading: false
})

const mutations = {
  SET_USER_HISTORY(state, valueArray) {
    state.userHistory = valueArray
  },

  SET_USER_HISTORY_LOADING(state, value) {
    state.userHistoryLoading = value
  }
}

const actions = {
  fetchUserHistory({ commit }) {
    commit('SET_USER_HISTORY_LOADING', true)
    return ContentService.getUserHistory()
      .then((res) => {
        commit('SET_USER_HISTORY', res.data.data.data)
        commit('SET_USER_HISTORY_LOADING', false)
      })
      .catch((err) => {
        commit('SET_USER_HISTORY_LOADING', false)
        throw err
      })
  }
}

export default {
  state,
  mutations,
  actions
}
