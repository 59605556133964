<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7945_206803)">
      <path
        d="M19.4998 18.75H4.49979C3.82104 18.75 3.22479 18.2925 3.05229 17.6362L0.0522898 6.38625C-0.10146 5.81625 0.0972898 5.20875 0.55479 4.8375C1.01229 4.46625 1.64604 4.395 2.17479 4.6575L6.93354 7.035L10.7135 0.7275C10.9835 0.2775 11.471 0 11.9998 0C12.5285 0 13.016 0.2775 13.286 0.7275L17.0698 7.035L21.8285 4.6575C22.3573 4.395 22.991 4.4625 23.4485 4.8375C23.906 5.20875 24.101 5.81625 23.951 6.38625L20.951 17.6362C20.7748 18.2925 20.1823 18.75 19.4998 18.75ZM5.65104 15.75H18.3485L20.1935 8.83125L17.171 10.3425C16.4698 10.695 15.6185 10.4437 15.2135 9.7725L11.9998 4.4175L8.78604 9.7725C8.38104 10.4437 7.52979 10.6912 6.82854 10.3425L3.80604 8.83125L5.65104 15.75Z"
        :class="`fill-${fill1}`" />
      <path
        d="M19.5 24H4.5C3.67125 24 3 23.3288 3 22.5C3 21.6712 3.67125 21 4.5 21H19.5C20.3288 21 21 21.6712 21 22.5C21 23.3288 20.3288 24 19.5 24Z"
        :class="`fill-${fill2}`" />
    </g>
    <defs>
      <clipPath id="clip0_7945_206803">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1?: string
  fill2?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red'
})
</script>
