<template>
  <div class="relative">
    <div v-if="content" id="getting-started" class="text-white overflow-hidden max-w-screen pb-10">
      <h3 class="md:text-center pt-20 px-[20px]">
        {{ content.heading }}
      </h3>
      <div
        class="flex flex-col md:flex-row text-left gap-6 md:max-w-[1100px] w-full py-6 md:py-20 mx-auto relative z-20">
        <div class="px-[20px] z-30 relative">
          <div class="space-y-10 intro-2">
            <div v-if="content.subHeading" v-html="content.subHeading" />
            <div v-if="content.tagline">
              {{ content.tagline }}
              <span
                class="underline cursor-pointer"
                v-if="content.taglineLink"
                @click="openHowWeWorkThisOutModal">
                {{ content.taglineLink }}
              </span>
            </div>
          </div>
          <div class="flex flex-col basis-1/3 flex-wrap gap-y-2 mt-[30px] z-30">
            <div class="w-full h-1px bg-blue-1 line-gradient" />
            <div
              v-for="(bullet, idx) in content.iterableItems"
              :key="idx"
              class="button-1 self-center md:self-start w-full">
              <BulletPoint :text="bullet.body" class="text-left pt-[4px]" fill="blue-2" />
              <div class="w-full h-1px bg-blue-1 line-gradient mt-[12px]" />
            </div>
          </div>
          <div v-if="content.footer" class="mt-[30px] paragraph-1">
            {{ content.footer }}
          </div>
          <div>
            <OtivoButton
              class="mt-[30px] mb-[10px] md:mb-0"
              colour="white"
              @click="$emit('button-clicked')">
              {{ content.buttonText }}
            </OtivoButton>
          </div>
        </div>
        <BetterOffPhone
          alt="BetterOffPhone"
          class="z-0 hidden xl:block w-full"
          :hand-hover-active="handHoverActive"
          @mouseover="handHover()"
          @mouseleave="handHover(false)" />
        <img
          :src="PointerHand"
          @mouseover="handHover()"
          @mouseleave="handHover(false)"
          alt="PointerHand"
          style="transform: scaleX(-1)"
          class="hidden xl:block absolute transition-all duration-300"
          :class="[
            handHoverActive ? '-bottom-[200px] -right-[380px]' : '-bottom-[260px] -right-[450px]',
          ]"
          id="pointer-hand" />
      </div>
    </div>
    <!--  overlapping bottom curve  -->
    <div class="absolute bottom-0 inset-x-0 z-50 bg-transparent object-contain w-screen">
      <svg
        class="z-50 w-full h-auto"
        viewBox="0 0 1500 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 26.1777V0.177734C160.957 8.62546 435.852 26.1683 759.738 26.1777H760.278C1077.65 26.169 1327.55 11.2245 1500 0.177734V26.1777H760.278C760.185 26.1777 760.093 26.1777 760 26.1777C759.913 26.1777 759.826 26.1777 759.738 26.1777H0Z"
          fill="white" />
        <path
          d="M760.278 26.1777H759.738C759.826 26.1777 759.913 26.1777 760 26.1777C760.093 26.1777 760.185 26.1777 760.278 26.1777Z"
          fill="white" />
      </svg>
    </div>
  </div>
</template>

<script lang="ts" setup>
import BulletPoint from '@/components/BulletPoint.vue'
import PointerHand from '@/components/SVGS/PointerHand.svg?url'
import OtivoButton from '@/components/OtivoButton.vue'
import BetterOffPhone from '@/components/BrandComponents/Otivo/Personal/BetterOffPhone.vue'
import { useDebounceFn } from '@vueuse/core'
import { nextTick, ref } from 'vue'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import HowWeWorkItOutModal from '@/components/BrandComponents/Otivo/HowWeWorkItOutModal.vue'

type ContentType = {
  heading: string
  subHeading?: string
  tagline?: string
  taglineLink?: string
  footer?: string
  buttonText: string
  iterableItems: Array<{ body: string }>
}

defineEmits(['button-clicked'])

interface Props {
  content: ContentType
}

defineProps<Props>()

const handHoverActive = ref(false)

const modalStore = useModalStore()

const openHowWeWorkThisOutModal = () => {
  modalStore.openModal(HowWeWorkItOutModal, {})
}

const handHover = useDebounceFn((value = true) => {
  nextTick(async () => {
    handHoverActive.value = value
  })
}, 10)
</script>

<style scoped>
.line-gradient {
  background: linear-gradient(90deg, #0037a1, #0037a1, #0064ff);
}
</style>
