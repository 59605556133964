import OptimizeAdviceService from '@/services/OptimizeAdviceService'
import { PostcodeType } from '@/types/GlobalTypes'

export const getPostcodeData = async (postcode: string) => {
  const response = await OptimizeAdviceService.getAllPostcodes(postcode).catch((err) =>
    console.error(err)
  )
  if (response) {
    const postcodes: PostcodeType[] = response.data.map((postcode) => {
      return {
        value: postcode.id,
        name: `${postcode.name} (${postcode.postcode})`,
        code: postcode.postcode,
        suburb: postcode.name,
        state: postcode.state ? { ...postcode.state } : {}
      }
    })
    return postcodes
  }
}

export const existingPostcodeData = async (id: string) => {
  return await getPostcodeData(id).then((arr) => {
    return arr.find((el) => el.value === id)
  })
}
