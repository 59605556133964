import { useAccountFlagsStore } from '@/store/pinia/AccountFlagsStore.ts'
import { useSuperStore } from '@/store/pinia/SuperStore.ts'
import { useModuleStatusesStore } from '@/store/pinia/ModuleStatusesStore.ts'

export default async () => {
  const accountFlagStore = useAccountFlagsStore()
  const superStore = useSuperStore()
  const moduleStatusStore = useModuleStatusesStore()
  console.info('[User] setting up...')
  try {
    const promises = []
    promises.push(accountFlagStore.fetchAccountFlags())
    promises.push(moduleStatusStore.fetchModuleStatuses())
    promises.push(superStore.fetchAllSupers())
    await Promise.all(promises)
    console.info('[User] setup complete')
    return
  } catch (e) {
    console.error(e)
  }
}
