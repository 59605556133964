<template>
  <modal v-if="adviserContactModal" @close="closeModal()">
    <template #header>
      <h2 class="text-center">Contact form</h2>
      <div class="intro-1 text-center py-8">
        If you’ve got a question or want to give us some feedback, we’d love to hear from you.
      </div>
    </template>

    <template #body>
      <contact-us-form type="adviser" />
    </template>
  </modal>
</template>

<script>
import ContactUsForm from '@/components/BrandComponents/ContactUs/ContactUsForm.vue'
import Modal from '@/components/Modals/Modal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AdviserContactModal',
  components: { ContactUsForm, Modal },
  computed: {
    ...mapGetters(['adviserContactModal']),
  },
  methods: {
    closeModal() {
      this.$store.dispatch('toggleAdviserContactModal')
    },
  },
}
</script>

<style scoped></style>
