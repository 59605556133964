<template>
  <svg width="32" height="33" viewBox="0 0 32 33" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.4473 26.9014L17.3848 25.9639C17.4785 25.8701 17.5254 25.7451 17.5254 25.5889C17.5254 25.4326 17.4785 25.292 17.3848 25.167L10.1191 17.9014H26.1035C26.2598 17.9014 26.4004 17.8389 26.5254 17.7139C26.6191 17.6201 26.666 17.4951 26.666 17.3389V16.0264C26.666 15.8701 26.6191 15.7295 26.5254 15.6045C26.4004 15.5107 26.2598 15.4639 26.1035 15.4639L10.1191 15.4639L17.3848 8.19824C17.4785 8.07324 17.5254 7.93262 17.5254 7.77637C17.5254 7.62012 17.4785 7.49512 17.3848 7.40137L16.4473 6.46387C16.3223 6.37012 16.1816 6.32324 16.0254 6.32324C15.8691 6.32324 15.7441 6.37012 15.6504 6.46387L5.85351 16.3076C5.72851 16.4014 5.66601 16.5264 5.66601 16.6826C5.66601 16.8389 5.72851 16.9639 5.85351 17.0576L15.6504 26.9014C15.7441 26.9951 15.8691 27.042 16.0254 27.042C16.1816 27.042 16.3223 26.9951 16.4473 26.9014Z"
      fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'ArrowLeftIcon'
}
</script>
