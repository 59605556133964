import getApiInstance from '@/services/Api'

const api = getApiInstance()

export default {
  validatePurchaseOrder(payload) {
    return api.Post('/purchase/validate', payload)
  },
  purchaseNoPaymentRequired(payload) {
    return api.Post('/purchase', payload)
  },
  purchasePaymentRequired(payload) {
    return api.Post('/purchase', payload)
  },
  sendOnboardEmail() {
    return api.Post('/purchase/onboardEmail')
  },
  cancelSubscription() {
    return api.Post('/purchase/cancel')
  },
}
