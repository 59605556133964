<template>
  <Transition name="fade">
    <div
      v-if="show && !isAuthenticated"
      class="fixed z-100 rounded-lg p-5 max-w-[472px] w-full flex flex-col overflow-hidden items-center bg-blue-1 md:right-10 bottom-0 md:bottom-10 m-auto">
      <div class="w-100% h-100% absolute top-0 right-0 gradient rounded-lg" />
      <RegularCurve
        fill="blue-1"
        class="z-0 absolute w-[1500px] h-50% bottom-[-50px] right-[-450px]" />
      <div id="content" class="z-10 text-white flex flex-col pt-10">
        <button
          data-test="closeNewFeaturesPopup"
          aria-label="Close"
          class="absolute cursor-pointer top-[12px] md:top-[16px] right-[12px] md:right-[16px]"
          type="button"
          id="closeModalBtn"
          @click.prevent="close">
          <CloseXIcon fill1="white" />
        </button>
        <div class="flex z-10 flex-col px-5">
          <h3 class="text-white">New features to help you be better off</h3>
          <p class="paragraph-1 mt-5">
            The team at Otivo has been hard at work creating a brand-new version that’s even easier
            and safer to use, helping you stay on top of your money. And don’t worry, all your
            previous info is just as you left it.
          </p>
          <div class="py-10 flex gap-2">
            <BaseCheckbox name="dont-show-again" @update:checked="dontShowAgain" />
            <p class="paragraph-2">Don't show this again</p>
          </div>
        </div>
        <div class="flex flex-col md:flex-row gap-5 pb-5 justify-center">
          <OtivoButton size="large" colour="white" @click="showNewFeaturesModal">
            See more info
          </OtivoButton>
          <OtivoButton v-if="!isAuthenticated" size="large" colour="red" @click="login()">
            Log into Otivo
          </OtivoButton>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import RegularCurve from '@/components/Shapes/RegularCurve.vue'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import { onBeforeMount, ref } from 'vue'
import OtivoButton from '@/components/OtivoButton.vue'
import { isAuthenticated, login } from '@/lib/AuthenticatorPlugin.ts'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import NewFeaturesModal from '@/components/Modals/NewFeaturesModal.vue'
import CloseXIcon from '@/components/icons/CloseXIcon.vue'
import { useMediaQuery } from '@vueuse/core'

const show = ref(false)
const close = () => {
  show.value = false
}
const key = 'dontShowNewFeatures'
onBeforeMount(() => {
  const dontShow = localStorage.getItem(key)
  if (dontShow !== 'true') {
    show.value = true
  }
})

const dontShowAgain = () => {
  localStorage.setItem(key, 'true')
  show.value = false
}

const showNewFeaturesModal = () => {
  show.value = false
  const modalStore = useModalStore()
  modalStore.openModal(
    NewFeaturesModal,
    undefined,
    'extra-large',
    false,
    'padding: 0',
    useMediaQuery('(min-width: 1024px)').value,
  )
}
</script>

<style scoped lang="scss">
.gradient {
  background: linear-gradient(299deg, rgba(168, 224, 255, 0.5) 1.51%, rgba(215, 245, 255, 0) 62%);
}
</style>
