<template>
  <div class="flex flex-col items-center justify-center max-w-[570px] w-100%">
    <form @submit.prevent="next" id="capture-super-balance">
      <div class="w-100% max-w-[375px]">
        <h6>Enter your current super balance</h6>
        <BaseInput
          type="currency"
          class="w-100% mt-[20px]"
          name="capture-super-balance-input"
          placeholder="10,000"
          :error-message="superBalanceErrorMessage"
          :value="balance"
          @update:value="(val: number) => (balance = val)" />
      </div>
      <OtivoButton type="submit" class="w-100% max-w-[375px] mt-[60px]"> Next </OtivoButton>
    </form>
  </div>
</template>

<script setup lang="ts">
import BaseInput from '@/components/Inputs/BaseInput.vue'
import { useAccountCreationStore } from '@/store/pinia/AccountCreationStore.ts'
import { ref } from 'vue'
import OtivoButton from '@/components/OtivoButton.vue'

const accountCreationStore = useAccountCreationStore()
const balance = ref(accountCreationStore.getSuperBalance ?? 0)
const superBalanceErrorMessage = ref('')

const emit = defineEmits<{
  (e: 'next', data: string): void
}>()

const next = () => {
  if (!balance.value) superBalanceErrorMessage.value = 'Please enter your super balance'
  else {
    superBalanceErrorMessage.value = ''
    accountCreationStore.setBalance(balance.value)
    emit('next', 'captured balance')
  }
}
</script>

<style scoped></style>
