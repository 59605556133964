<template>
  <div
    class="border border-grey-4 p-4 shadow-md m-auto py-4 md:p-8 bg-white rounded rounded-t-none border-t-0">
    <SuperWidgetAdviceResult
      v-for="(investmentOption, index) in superWidgetGraphData?.options"
      :key="index"
      :advice-locked="adviceLocked"
      :class="{
        'grayscale opacity-50':
          adviceLocked && activeSuper.advice_request?.requested_change_id !== investmentOption.id,
      }"
      :client-view="isUser()"
      :has-multiple-alternatives="hasMultipleAlternatives"
      :get-current-super-results="superWidgetGraphData?.options || []"
      :index="+index"
      :intra-super-lifestage="pageContent.intraSuperLifestage"
      :item="investmentOption"
      :show-recommendations="state.showRecommendations"
      @selection-updated="changeInvestmentOption(investmentOption)" />
    <!--  TODO: config based on whitelabel -->
    <p class="paragraph-3 mt-[20px]" v-if="dynamicDates && dynamicDates['CFS_CHANTWEST']">
      All returns and fees are supplied by Chant West and figures may be delayed. As of
      {{ dynamicDates['CFS_CHANTWEST'] }}, these returns and fees are current, and the most recent
      CFS Product Disclosure Statements can be accessed directly via this
      <a
        href="https://www.cfs.com.au/personal/tools-resources/tools-calculators/product-disclosure-statements.html"
        target="_blank"
        >link</a
      >.
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import SuperWidgetAdviceResult from '@/components/SuperWidget/SuperWidgetAdviceResult.vue'
import { useIntrafundAdviceStore } from '@/store/pinia/IntrafundAdviceStore.ts'
import { useSuperStore } from '@/store/pinia/SuperStore.ts'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import { InvestmentOption as SuperInvestmentOption } from '@/components/SuperWidget/SuperWidgetAdviceType.ts'
import InvestmentOption from '@/types/api/super/InvestmentOption.ts'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import ClientConfirmationChangeSuper from '@/components/CFS/CFSClientConfirmationSteps/ClientConfirmationChangeSuper.vue'
import { usePageContentStore } from '@/store/pinia/PageContentStore.ts'
import { state } from '@/components/CFS/IntrafundInvestment/superInvestmentsState.ts'
import { useCheckUser } from '@/composables/users/checkUser.ts'
import { superInvestmentRecommendationHandlers } from '@/components/CFS/IntrafundInvestment/Utils/superInvestmentRecommendationHandlers.ts'
import { useModuleStatusesStore } from '@/store/pinia/ModuleStatusesStore.ts'
import ClientConfirmationConfirmSuper from '@/components/CFS/CFSClientConfirmationSteps/ClientConfirmationConfirmSuper.vue'
import {
  ExclusionRules,
  useWhitelabelExclusionRules,
} from '@/composables/useWhitelabelExclusionRules.ts'
import { useUserStore } from '@/store/pinia/UserStore.ts'

const intrafundAdviceStore = useIntrafundAdviceStore()
const superStore = useSuperStore()
const pageContentStore = usePageContentStore()
const modalStore = useModalStore()
const moduleStatusesStore = useModuleStatusesStore()
const { hasExclusionRule } = useWhitelabelExclusionRules()

const { isUser } = useCheckUser()
const { hasMultipleAlternatives } = superInvestmentRecommendationHandlers(
  useUserStore(),
  superStore,
  isUser,
)

const superWidgetGraphData = computed(() => intrafundAdviceStore.getSuperWidgetGraphData)
const activeSuper = computed(() => superStore.getActiveSuperObject)

const dynamicDates = computed(() => useSessionStore().getWhitelabelData?.dynamic_dates)
const pageContent = computed(() => pageContentStore.pageContent)
const adviceLocked = computed(() =>
  ['processing', 'actioned'].includes(moduleStatusesStore.getStatuses.superInvestments),
)

const changeInvestmentOption = async (investmentOption: SuperInvestmentOption) => {
  try {
    const newAllocation: InvestmentOption[] = []
    let totalTermDepositPercentAllocation = 0

    if (hasExclusionRule(ExclusionRules.SOME_IN_CASH_OR_TERM_DEPOSITS)) {
      const allocation = activeSuper.value.investments
      const termDepositAllocations = allocation.filter((value) => value.is_term_deposit)
      if (termDepositAllocations.length > 0) {
        totalTermDepositPercentAllocation = termDepositAllocations.reduce(
          (total, td) => total + Number(td.percent_allocation),
          0,
        )
        newAllocation.push(...termDepositAllocations)
      }
    }

    newAllocation.push({
      chant_west_investment_name: investmentOption.investment.name,
      chant_west_investment_id: investmentOption.id,
      percent_allocation: 100 - totalTermDepositPercentAllocation,
      is_term_deposit: false,
      is_cash_option: false,
      has_cash_option: false,
      restricted_product: false,
    })
    intrafundAdviceStore.setRequestedAllocation(newAllocation)
    state.selectedCurrentOption = investmentOption.tag === 'current'
    // Skip the first step if they choose the same option
    if (state.selectedCurrentOption) {
      modalStore.openModal(ClientConfirmationConfirmSuper, {
        refuseCashChanges: false,
        pageContent: pageContent.value.confirmSuper,
        selectedCurrentOption: state.selectedCurrentOption,
        cancelAdvice: () => {
          state.activeModal = ''
          modalStore.closeModal()
        },
      })
    } else {
      modalStore.openModal(ClientConfirmationChangeSuper, {
        refuseCashChanges: false,
        pageContent: pageContent.value.changeSuper,
        onProceed: async () => {
          state.activeModal = 'confirmSuper'
        },
        cancelAdvice: () => {
          state.activeModal = ''
          modalStore.closeModal()
        },
      })
    }
  } catch (error) {
    console.error('Error changing investment option', error)
  }
}
</script>

<style scoped></style>
