<template>
  <div>
    <input
      class="p-4 rounded-[10px] appearance-none block w-full mh4 md:h4 border-[1px] border-grey-3"
      :id="name"
      :placeholder="placeHolder"
      @input="updateValue($event)"
      autocomplete="new-password" />
  </div>
</template>

<script setup lang="ts">
interface Props {
  name: string
  label?: string
  placeHolder?: string
  required?: boolean
  error?: string
}
withDefaults(defineProps<Props>(), {
  label: '',
  placeHolder: '',
  required: false,
  error: '',
})

const emit = defineEmits(['input'])

function updateValue(event) {
  let value = event.target.value
  if (!this.allowDigits) {
    value = value.replace(/[0-9]/g, '')
    event.target.value = value
  }
  emit('input', value)
}
</script>

<style scoped></style>
