<template>
  <section v-if="content">
    <img
      class="max-w-[90%] md:max-w-[370px] m-auto pt-5 transform transition-all duration-1000 hover:scale-105 px-[20px] md:px-0"
      :src="WomanIpad"
      alt="Woman with an Ipad" />
    <h3
      class="pt-[20px] text-grey-1"
      v-if="content.implement_advice_header"
      v-html="content.implement_advice_header" />
    <p
      class="intro-1 pt-[20px] text-grey-1"
      v-if="content.implement_advice_intro"
      v-html="content.implement_advice_intro" />
    <ClientConfirmationChangeSuperText
      :investments="activeSuper.investments"
      :requested-allocation="requestedAllocation"
      :selected-current-option="state.selectedCurrentOption" />
    <br />
    <HowToActionTheAdviceTable v-if="stepsContent.table_step_2 !== ''" :content="stepsContent" />
    <ClientConfirmationChangeSuperTerms
      v-if="content.pds_notice"
      :content="content.pds_notice"
      :super="activeSuper" />
    <div
      class="paragraph-1 mt-5"
      v-if="content.implement_advice_notice"
      v-html="content.implement_advice_notice" />

    <template v-if="hasTermDeposit">
      <div
        class="paragraph-1 mt-5"
        v-if="content.implement_advice_default_term_deposit_notice"
        v-html="content.implement_advice_default_term_deposit_notice" />
    </template>

    <div class="paragraph-1 mt-5" v-if="content.switch_notice" v-html="content.switch_notice" />
    <div v-if="displayCashAllocation" class="flex gap-4 items-center justify-center my-[20px]">
      <CustomCheckBox
        name="refuseCashChanges"
        :checked="refuseCashChanges"
        @click="changeCashCheckbox" />
      <div class="paragraph-1">Do you wish to retain your cash options?</div>
    </div>
    <div class="w-100% md:w-fit flex flex-col gap-[16px] mt-10">
      <OtivoButton size="large" @click="onProceed" colour="blue" data-test="proceedBtn">
        {{ content.button_1 }}
      </OtivoButton>
      <OtivoButton size="large" @click="cancelAdvice" colour="white" data-test="cancelBtn">
        {{ content.button_2 }}
      </OtivoButton>
    </div>
  </section>
  <BeatLoader color="var(--otivo-blue)" v-else />
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, ref } from 'vue'
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import CustomCheckBox from '@/components/BasicInputs/CustomCheckBox/SmallCheckbox.vue'
import ClientConfirmationChangeSuperText from '@/components/CFS/CFSClientConfirmationSteps/ClientConfirmationChangeSuperText.vue'
import { ComponentContentKeysType } from '@/types/GlobalTypes'
import ClientConfirmationChangeSuperTerms from '@/components/CFS/CFSClientConfirmationSteps/ClientConfirmationChangeSuperTerms.vue'
import { useIntrafundAdviceStore } from '@/store/pinia/IntrafundAdviceStore'
import { useSuperStore } from '@/store/pinia/SuperStore.ts'
import { state } from '@/components/CFS/IntrafundInvestment/superInvestmentsState.ts'
import moduleContentService from '@/services/ModuleContentService.ts'
import HowToActionTheAdviceTable from '@/components/HowToActionTheAdviceTable/HowToActionTheAdviceTable.vue'
import WomanIpad from '@/assets/img/WomanIpad.png'

type Props = {
  refuseCashChanges: boolean
  pageContent: ComponentContentKeysType
  onProceed: () => void
  cancelAdvice: () => void
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'refuseCashChanges', data: boolean): void
}>()

const content = ref()
const stepsContent = ref()

// @TODO: We will revisit when we want to give user option to retain your cash options.
// const cashAllocation = getClientSuperObject.value.allocation.find((el) => el.chantWestInvestmentIsCashOption)
// const displayCashAllocation = ref(cashAllocation ?? false)
const displayCashAllocation = ref(false)
const requestedAllocation = computed(() => intrafundAdviceStore.getRequestedAllocation)

const superStore = useSuperStore()
const intrafundAdviceStore = useIntrafundAdviceStore()

const hasTermDeposit = computed(() => intrafundAdviceStore.getHasCashAndTermDepositInvestments)

const activeSuper = computed(() => superStore.getActiveSuperObject)

onBeforeMount(async () => {
  const { getBlockTags } = moduleContentService()
  const res = await getBlockTags([
    'implement_advice_super_investment_block',
    'implement_advice_steps_block',
  ])
  content.value = { ...res.data.implement_advice_super_investment_block }
  stepsContent.value = { ...res.data.implement_advice_steps_block }
})

// changeCashCheckbox to retain your cash options
const changeCashCheckbox = () => {
  emit('refuseCashChanges', !props.refuseCashChanges)
  // @TODO: After the change of the checkbox, we need to update the requested allocation
}
</script>

<style scoped></style>
