<template>
  <div id="media-title-component">
    <div class="flex justify-center pb-[40px] md:pb-16">
      <div class="text-center">
        <div class="fix-width-m-auto text-grey-1">
          <h1 class="px-6" v-html="mediaTitleContent.heading" />
          <div class="intro-1 m-auto py-8 px-[20px] md:px-0">
            {{ mediaTitleContent.subHeading }}
          </div>
          <img :src="NewspaperLady" alt="Newspaper Lady" class="m-auto my-4 w-76" />
        </div>
      </div>
    </div>
    <OffsetCurve fill="otivo-blue" />
  </div>
</template>

<script lang="ts" setup>
import NewspaperLady from '@/components/SVGS/NewspaperLady.svg?url'
import OffsetCurve from '@/components/Shapes/OffsetCurve.vue'

interface Props {
  mediaTitleContent: {
    heading: string
    subHeading: string
    footer: string
  }
}

defineProps<Props>()
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  #media-logos svg {
    width: 1151px;
    height: auto;
  }
}
</style>
