<template>
  <div class="flex gap-3">
    <label
      v-if="label !== '' && label !== undefined"
      class="pt-4 md:set-w-48 paragraph-2"
      :for="name"
      :class="!shrinkLabel ? 'set-w-48' : 'set-w-28'">
      {{ this.uppercase(label) }}
    </label>
    <div class="w-full">
      <input
        class="focus:outline-none rounded flex w-full bg-white p-4 pr-6 border border-grey-2 focus:border-otivo-blue ring-2 ring-transparent focus:ring-blue-4"
        :class="inputClasses"
        data-test="email"
        :id="name"
        type="email"
        :value="email"
        :disabled="disabled"
        @input="updateUserEmail"
        placeholder="name@email.com" />
      <input-error v-if="error !== ''" :error="error" class="text-center" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { capitalize } from '@/lib/helpers'
import InputError from '@/components/BasicInputs/InputError.vue'
export default {
  name: 'UserEmail',
  components: { InputError },
  computed: {
    ...mapGetters(['checkHasChangeBeenMade', 'profile', 'totalMonthlyExpense']),
    ...mapState({
      email: (state) => state.profile.email,
      partnerInfo: (state) => state.partnerInfo,
    }),
  },

  props: {
    error: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: 'Email',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputClasses: {
      type: String,
      default: 'focus:outline-none mp1 md:p1 self-center',
    },
    shrinkLabel: {
      type: Boolean,
      default: false,
    },
    triggerUnsavedModal: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    updateUserEmail(event) {
      if (!this.checkHasChangeBeenMade && this.triggerUnsavedModal) {
        const profileDataString =
          JSON.stringify(this.profile) +
          JSON.stringify(this.totalMonthlyExpense) +
          JSON.stringify(this.partnerInfo)
        this.$store.dispatch('setPreviousDataObject', profileDataString)
      }
      this.$store.dispatch('setProfileEmail', event.target.value)
    },

    uppercase(value) {
      return capitalize(value)
    },
  },
}
</script>

<style scoped></style>
