<template>
  <modal v-if="showContactUsModal" @close="store.dispatch('renderContactUsModal', false)">
    <template #header>
      <h2 class="text-center">Contact form</h2>
      <div class="intro-1 text-center md:py-8">
        If you’ve got a question or want to give us some feedback, we’d love to hear from you.
      </div>
    </template>

    <template #body>
      <contact-us-form :type="businessPage" />
    </template>
  </modal>
</template>

<script setup lang="ts">
import ContactUsForm from '@/components/BrandComponents/ContactUs/ContactUsForm.vue'
import Modal from '@/components/Modals/Modal.vue'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const router = useRouter()
const showContactUsModal = computed(() => store.getters.showContactUsModal)
const businessPage = computed(() => {
  return router.currentRoute.value.meta.type ? router.currentRoute.value.meta.type : ''
})
</script>

<style scoped></style>
