<template>
  <p v-if="error !== ''" class="text-red-1 mt-1 md:mt-2">
    {{ error !== undefined ? error : '' }}
  </p>
</template>

<script>
export default {
  name: 'InputError',
  props: {
    error: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped></style>
