import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import { computed } from 'vue'
import { isAuthenticated } from '@/lib/AuthenticatorPlugin.ts'
import { GenericObject } from '@/types/GlobalTypes.ts'
import { useCheckUser } from '@/composables/users/checkUser.ts'

type WhitelabelLinksType = (
  displayLegal?: boolean,
) => GenericObject<
  GenericObject<{ name: string; link: string; id: string; target: 'blank' | 'self' }>
>

export const getLinksForWhitelabel: WhitelabelLinksType = (displayLegal = true) => {
  const sessionStore = useSessionStore()
  const whitelabelData = computed(() => sessionStore.getWhitelabelData)
  const links = {
    adviceFirms: {
      name: 'Advice practices',
      link: '/advice-practices',
      id: 'advice-practices-link',
      target: isAuthenticated.value ? 'blank' : 'self',
    },
    askOtivoSuper: {
      name: 'Ask Otivo super',
      link: '/ask-otivo-super',
      id: 'ask-otivo-super-link',
      target: isAuthenticated.value ? 'blank' : 'self',
    },
    aboutOtivo: {
      name: 'About Otivo',
      link: '/our-team',
      id: 'our-team-link',
      target: isAuthenticated.value ? 'blank' : 'self',
    },
    adviceOverview: {
      name: 'Advice overview',
      link: '/advice-overview',
      id: 'advice-overview-link',
      target: isAuthenticated.value ? 'blank' : 'self',
    },
    copyright: {
      name: 'Copyright',
      link: whitelabelData.value.copyright_url !== null ? whitelabelData.value.copyright_url : null,
      target: 'self',
    },
    contactUs: {
      name: 'Contact us',
      link: '/contact',
      id: 'contact-link',
      target: isAuthenticated.value ? 'blank' : 'self',
    },
    contactUsEmail: {
      name: 'Email us',
      link: 'mailto:support@otivo.com',
      id: 'email-us-link',
      target: 'mail',
    },
    employees: {
      name: 'Employee wellbeing',
      link: '/employee-wellbeing',
      id: 'employee-wellbeing-link',
      target: isAuthenticated.value ? 'blank' : 'self',
    },
    faq: {
      name: 'FAQ',
      link: '/faq',
      id: 'faq-link',
      target: isAuthenticated.value ? 'blank' : 'self',
    },
    faqCfs: {
      name: 'FAQ',
      link: '/cfs/faq',
      id: 'cfs-faq-link',
      target: 'self',
    },
    financialFitness: {
      name: 'Financial fitness test',
      link: '/financial-fitness',
      id: 'financial-fitness-link',
      target: isAuthenticated.value ? 'blank' : 'self',
    },
    financialServiceGuide: {
      name: 'Financial services guide',
      link: !whitelabelData.value.fsg_url?.includes('/fsg')
        ? whitelabelData.value.fsg_url
        : 'https://store.otivo.com/FSG/FSCGv1.pdf',
      target: 'blank',
    },
    helpCentre: {
      name: 'Help centre',
      link: '/help-centre',
      id: 'help-centre-link',
      target: isAuthenticated.value ? 'blank' : 'self',
    },
    howTheAdviceWorks: {
      name: 'How the advice works',
      link: '/dashboard/how-advice-works',
      id: 'dashboard-how-advice-works-link',
      target: isAuthenticated.value ? 'blank' : 'self',
    },
    howWeMakeMoney: {
      name: 'How Otivo makes money',
      link: '/how-otivo-makes-money',
      id: 'how-otivo-makes-money-link',
      target: isAuthenticated.value ? 'blank' : 'self',
    },
    personalPricing: {
      name: 'Personal pricing',
      link: '/personal-pricing',
      id: 'personal-pricing-link',
      target: isAuthenticated.value ? 'blank' : 'self',
    },
    ourStory: {
      name: 'Our story',
      link: '/our-team',
      id: 'our-team-link',
      target: isAuthenticated.value ? 'blank' : 'self',
    },
    media: {
      name: 'Media',
      link: '/media',
      id: 'media-link',
      target: isAuthenticated.value ? 'blank' : 'self',
    },
    learn: {
      name: 'Learn',
      link: '/learn',
      id: 'learn-link',
      target: isAuthenticated.value ? 'blank' : 'self',
    },
    otivoSuperReport: {
      name: 'Otivo Super Report',
      link: '/super-report',
      id: 'otivo-super-report-link',
      target: isAuthenticated.value ? 'blank' : 'self',
    },
    privacy: {
      name: 'Privacy policy',
      link: !whitelabelData.value.privacy_url.includes('/content/privacy')
        ? whitelabelData.value.privacy_url
        : '/content/privacy',
      target: 'self',
    },
    profile: {
      name: 'Profile',
      link: '/dashboard/details',
      id: 'profile-link',
    },
    termsAndConditions: {
      name: 'Terms and conditions',
      link: !whitelabelData.value.terms_and_conditions_url?.includes('/content/termsAndConditions')
        ? whitelabelData.value.terms_and_conditions_url
        : '/content/termsAndConditions',
      target: 'self',
    },
    security: {
      name: 'Security',
      link: '/security',
      id: 'security-link',
      target: isAuthenticated.value ? 'blank' : 'self',
    },
    dashSuper: {
      name: 'Super',
      link: '/dashboard/super-investments',
      id: 'super-link',
      target: isAuthenticated.value ? 'blank' : 'self',
    },
    superFunds: {
      name: 'Super funds',
      link: '/super-funds',
      id: 'super-funds-link',
      target: isAuthenticated.value ? 'blank' : 'self',
    },
    superReport: {
      name: 'Super report',
      link: '/super-report',
      id: 'super-report-link',
      target: isAuthenticated.value ? 'blank' : 'self',
    },
    wealthCalculator: {
      name: 'Wealth calculator',
      link: '/wealth-calculator',
      id: 'wealth-calculator-link',
      target: isAuthenticated.value ? 'blank' : 'self',
    },
  }

  const servicesPages = {
    Services: {
      employees: links.employees,
      adviceFirms: links.adviceFirms,
      superFunds: links.superFunds,
      personalPricing: links.personalPricing,
    },
  }
  const brandPages = {
    About: {
      ourStory: links.ourStory,
      // adviceOverview: links.adviceOverview,
      howWeMakeMoney: links.howWeMakeMoney,
      security: links.security,
      media: links.media,
      contactUs: links.contactUs,
    },
    Resources: {
      financialFitness: links.financialFitness,
      // wealthCalculator: links.wealthCalculator,
      // helpCentre: links.helpCentre,
      faq: links.faq,
      // contactUs: links.contactUs,
      superReport: links.superReport,
      // askOtivoSuper: links.askOtivoSuper, // removed based on Nathans request. comment is new
    },
  }
  const legalStuff = {
    Legal_stuff: {
      privacy: links.privacy,
      financialServiceGuide: links.financialServiceGuide,
      termsAndConditions: links.termsAndConditions,
      copyright: links.copyright,
    },
  }

  const callcentreLinks = () => {
    const { isUser } = useCheckUser()
    let linksObject = {
      Support: {
        contactUsEmail: links.contactUsEmail,
        faqCfs: links.faqCfs,
      },
    }

    if (isAuthenticated && isUser()) {
      const aboutObject = {
        About: {
          howTheAdviceWorks: links.howTheAdviceWorks,
        },
      }
      linksObject = {
        ...linksObject,
        ...aboutObject,
      }
    }

    return linksObject
  }

  const partnerLinks = (displayLegal: boolean) => {
    const baseLinks = {
      About: {
        ourStory: links.ourStory,
        security: links.security,
        media: links.media,
      },
      Resources: {
        financialFitness: links.financialFitness,
        faq: links.faq,
        // superReport: links.superReport, // currently missing removed for now
        contactUs: links.contactUs,
      },
    }
    const legalLinks = {
      Legal_stuff: {
        privacy: links.privacy,
        financialServiceGuide: links.financialServiceGuide,
        termsAndConditions: links.termsAndConditions,
      },
    }
    return displayLegal ? { ...baseLinks, ...legalLinks } : baseLinks
  }

  let baseLinks = {}
  // Only show servicePages on main site
  if (whitelabelData.value.identifier === 'OTIVO') {
    baseLinks = { ...servicesPages, ...brandPages }
  }
  if (whitelabelData.value.identifier === 'OTIVO' && isAuthenticated.value) {
    baseLinks = {
      Support: {
        contactUs: links.contactUs,
        faq: links.faq,
      },
    }
  }

  if (whitelabelData.value.identifier === 'COLONIAL_FIRST_STATE') {
    const cfsservices = isAuthenticated.value
      ? {
          Services: {
            profile: links.profile,
            dashSuper: links.dashSuper,
          },
        }
      : {
          Services: {
            security: links.security,
            media: links.media,
          },
        }

    const cfssupport = {
      Support: {
        emailus: links.contactUsEmail,
        faq: links.faqCfs,
      },
    }

    const cfsabout = {
      About: {
        howTheAdviceWorks: links.howTheAdviceWorks,
      },
    }
    const cfslinks = displayLegal
      ? { ...cfsservices, ...cfssupport, ...legalStuff }
      : { ...cfsservices, ...cfssupport }
    return isAuthenticated.value ? { ...cfslinks, ...cfsabout } : cfslinks
  }

  // If callcenter override the previous links
  if (whitelabelData.value.callcentre_portal) baseLinks = { ...callcentreLinks() }

  // partner footer links
  if (
    whitelabelData.value.identifier !== 'OTIVO' &&
    whitelabelData.value.identifier !== 'COLONIAL_FIRST_STATE'
  ) {
    return { ...partnerLinks(displayLegal) }
  }

  // Display legal links always unless in the header
  return displayLegal ? { ...baseLinks, ...legalStuff } : baseLinks
}
