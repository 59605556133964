<template>
  <BaseView>
    <div v-if="pageContent.currentPage === 'securityPage'" id="security" class="bg-white">
      <div class="bg-blue-5">
        <Breadcrumbs class="bg-blue-5" />
        <div class="text-center mt-[80px] px-6">
          <h1 class="fix-width-m-auto">
            {{ pageContent.securityHero.heading }}
          </h1>
          <p class="intro-1 md:w-[800px] m-auto mt-[20px]">
            {{ pageContent.securityHero.subHeading }}
          </p>
        </div>
        <img :src="SecurityTeam" alt="Security Team" class="m-auto w-[430px] mt-[20px] px-[20px]" />
        <div class="mt-[80px]">
          <OffsetCurve fill="white" />
          <div class="relative bg-white pt-12 md:pt-0 pb-12 max-w-screen overflow-hidden">
            <h3
              class="text-center px-[20px] mb-10"
              v-html="
                pageContent.securityPoints.heading ?? 'How we keep Otivo safe and secure'
              "></h3>
            <div
              class="grid md:w-[960px] m-auto md:grid-cols-2 lg:gap-20 px-5 relative items-start mt-[20px]">
              <div
                :key="'column' + columnIndex"
                v-for="(itemColumn, columnIndex) in items"
                class="flex-1">
                <div :key="index" v-for="(info, index) in itemColumn" class="pb-10 flex space-x-5">
                  <component
                    class="hidden md:block h-[32px] w-[32px] fill-otivo-blue shrink-0"
                    :is="icons[columnIndex][index]" />
                  <div class="max-w-[360px]">
                    <h6>
                      {{ info.heading }}
                    </h6>
                    <div class="paragraph-2 mt-[10px]" v-html="info.body" />
                  </div>
                </div>
              </div>
            </div>
            <p
              class="intro-2 text-grey-1 text-center mt-[60px]"
              v-html="pageContent.securityPoints.subHeading" />
          </div>
        </div>
      </div>
      <RegularCurve class="hidden md:block" fill="blue-5" />
      <RegularCurve class="h-6 w-full md:hidden bg-blue-5" fill="white" :rotate="true" />
      <ThreeTileDisplay
        class="bg-blue-5"
        text-colour="grey-1"
        :icon-boxes="pageContent.threeTileDisplay.iterableItems" />
      <RegularCurve class="h-6 w-full md:hidden bg-blue-5 fill-midnight" fill="midnight" />
    </div>
  </BaseView>
</template>

<script lang="ts" setup>
import BaseView from '@/views/BaseView.vue'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import CrownIcon from '@/components/icons/CrownIcon.vue'
import LockIcon from '@/components/icons/LockIcon.vue'
import MonthlyCashFlowIcon from '@/components/icons/MonthlyCashFlowIcon.vue'
import Secure from '@/components/icons/Secure.vue'
import SecurityTeam from '@/components/SVGS/SecurityTeam.svg?url'
import ThreeTileDisplay from '@/components/BrandComponents/Otivo/ThreeTileDisplay.vue'
import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import OffsetCurve from '@/components/Shapes/OffsetCurve.vue'
import BankIcon from '@/components/icons/BankIcon.vue'
import MonthlySpendDetailIcon from '@/components/icons/MonthlySpendDetailIcon.vue'
import OwnAndOweIcon from '@/components/icons/OwnAndOweIcon.vue'
import RegularCurve from '@/components/Shapes/RegularCurve.vue'
import { useHead } from '@unhead/vue'

const store = useStore()

onBeforeMount(() => store.dispatch('ContentStore/getContent', 'securityPage'))
const pageContent = computed(() => store.getters['ContentStore/pageContent'])
const leftItems = computed(() =>
  Object.values(pageContent.value.securityPoints.iterableItems).filter((item, index) => index < 4),
)

useHead({
  title: 'Otivo takes security seriously',
})

const rightItems = computed(() =>
  Object.values(pageContent.value.securityPoints.iterableItems).filter((item, index) => index >= 4),
)
const items = computed(() => [leftItems.value, rightItems.value])

const icons = [
  [MonthlyCashFlowIcon, MonthlySpendDetailIcon, LockIcon, Secure],
  [Secure, CrownIcon, OwnAndOweIcon, BankIcon],
]
</script>
