<template>
  <div
    id="portal-reporting"
    class="py-10 px-5 lg:px-10 bg-blue-6 w-100% h-100% flex justify-center">
    <div class="flex w-100% gap-[40px] max-w-[1920px]">
      <div class="w-100% flex flex-col flex-grow">
        <div class="flex flex-row justify-between items-center">
          <div class="inline-flex space-x-10 items-center">
            <h4 class="text-blue-1">{{ title }}</h4>
          </div>

          <PeriodDatePicker @update-range="setSearchRange" />
        </div>
        <div v-if="whitelabels.length > 1" class="inline-flex items-start py-10">
          <input-select-box :options="whitelabels" name="whitelabel_id" class="min-w-80"
                            :select-input="selected"
                            @change="handleWhitelabelChange" />
        </div>
        <div class="w-100%">
          <RouterView></RouterView>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import { useNavBarStore } from '@/store/pinia/NavBarStore.ts'
import { useCallcentreReportingStore } from '@/store/pinia/CallcentrePortalReportingStore.ts'
import PeriodDatePicker, { DateRange } from '@/components/PeriodDatePicker.vue'
import { useDebounceFn } from '@vueuse/core'
import InputSelectBox from '@/components/BasicInputs/InputSelectBox.vue'

const route = useRoute()
const navBarStore = useNavBarStore()
const reportingStore = useCallcentreReportingStore()
const selected = computed(() => reportingStore.whitelabelId)
const whitelabels = computed(() => reportingStore.whitelabelOptions.map((option) => {
  return { label: option.name, value: option.id }
}))

const title = computed(() => route.meta.title)

onMounted(() => {
  // start fetching data
  reportingStore.getReportingMetrics()
  reportingStore.getWhitelabelOptions()
  setRoutesOverride()
})

const setRoutesOverride = async () => {
  // await navBarStore.overrideNavRoutes('reporting', false)
  navBarStore.setRouterComponents(['reporting'])
}

const handleWhitelabelChange = (id: number) => {
  reportingStore.setWhitelabelId(id)
}

const setSearchRange = useDebounceFn((range: DateRange) => {
  reportingStore.setSearchRange(range)
}, 500)
</script>

<style scoped lang="scss"></style>
