import LoadingPage from '@/views/LoadingPage.vue'
import { accountCreationGuard, handleLoginCallback } from '@/router/routeGuards.ts'
import getApiInstance from '@/services/Api.ts'
import NetworkIssue from '@/views/Otivo/NetworkIssue.vue'
import LogoutView from '@/views/Otivo/Dashboard/Logout.vue'
import Maintenance from '@/views/Otivo/Maintenance.vue'
import NotFound from '@/views/Otivo/NotFound.vue'
import Fsg from '@/views/Otivo/Brand/LegalStuff/Fsg.vue'
import ContactUs from '@/views/Otivo/Brand/About/ContactUs.vue'
import { RouteRecordRaw } from 'vue-router'
import { ISessionStore } from '@/store/pinia/SessionStore.ts'
import CreateAccount from '@/components/CreateAccount/CreateAccount.vue'
import { createAccount, isAuthenticated, login } from '@/lib/AuthenticatorPlugin.ts'
import UserDetailsForm from '@/components/CreateAccount/Partials/UserDetailsForm.vue'
import MobileVerificationForm from '@/components/CreateAccount/Partials/MobileVerificationForm.vue'
import CaptureFundMemberId from '@/components/CreateAccount/Partials/CaptureFundMemberId.vue'
import TermsAndConditionsForm from '@/components/CreateAccount/Partials/TermsAndConditionsForm.vue'
import CaptureSuperBalance from '@/components/CreateAccount/Partials/CaptureSuperBalance.vue'
import CaptureSuperAccountNumber from '@/components/CreateAccount/Partials/CaptureSuperAccountNumber.vue'
import CaptureInvestmentOptions from '@/components/CreateAccount/Partials/CaptureInvestmentOptions.vue'
import SelectSuperFund from '@/components/CreateAccount/Partials/SelectSuperFund.vue'
import Paywall from '@/components/CreateAccount/Paywall/Paywall.vue'
import SecurityPage from '@/views/Otivo/Brand/About/Security.vue'
import Media from '@/views/Otivo/Brand/About/Media.vue'
import HowOtivoMakesMoney from '@/views/Otivo/Brand/About/HowOtivoMakesMoney.vue'
import PersonalPricing from '@/views/Otivo/Brand/Solutions/PersonalPricing.vue'
import OnboardingIntroductoryBoostSuperPage from '@/views/Otivo/Brand/SplashScreens/OnboardingIntroductoryBoostSuperPage.vue'
import OnboardingIntroductoryInsurancePage from '@/views/Otivo/Brand/SplashScreens/OnboardingIntroductoryInsurancePage.vue'
import OnboardingIntroductoryOptimizeSuperPage from '@/views/Otivo/Brand/SplashScreens/OnboardingIntroductoryOptimizeSuperPage.vue'
import AdvicePractices from '@/views/Otivo/Brand/Solutions/AdvicePractices.vue'
import SuperFunds from '@/views/Otivo/Brand/Solutions/SuperFunds.vue'
import OnboardingIntroductorySalarySacrificePage from '@/views/Otivo/Brand/SplashScreens/OnboardingIntroductorySalarySacrificePage.vue'
import ExtraContent from '@/views/Otivo/Brand/LegalStuff/ExtraContent.vue'
import ParseJWT from '@/composables/decryptWBGJWT.ts'
import { IAccountFlagsStore } from '@/store/pinia/AccountFlagsStore.ts'
import { IUserStore } from '@/store/pinia/UserStore.ts'
import dashboardRoutes from '@/router/features/dashboardRoutes.ts'
import UnsubscribeEmail from '@/components/Profile/EmailSubscription/UnsubscribeEmailPage.vue'
import AdminPortalLayout from '@/app/layouts/AdminPortalLayout.vue'
import SearchForm from '@/components/AdminPortal/SearchForm.vue'
import DeactivateAccount from '@/components/Profile/Deactivate/DeactivateAccount.vue'
import DeactivateSurvey from '@/components/Profile/Deactivate/DeactivateSurvey.vue'
import DeactivateAccountView from '@/components/Profile/Deactivate/DeactivateAccountView.vue'
import DeactivateFarewell from '@/components/Profile/Deactivate/DeactivateFarewell.vue'

export default (
  sessionStore: ISessionStore,
  userStore: IUserStore,
  accountFlagsStore: IAccountFlagsStore,
): Array<RouteRecordRaw> => {
  return [
    // {
    //   path: '/playground',
    //   name: 'playground',
    //   component: AccountStatus,
    // },
    {
      path: '/layout',
      name: 'layout',
      component: AdminPortalLayout,
      children: [
        {
          path: 'search',
          name: 'SearchForm',
          component: SearchForm,
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: LoadingPage,
      beforeEnter: () => login(),
    },
    {
      path: '/login/callback',
      name: 'callback',
      component: LoadingPage,
      beforeEnter: (to, from, next) => handleLoginCallback(to, from, next),
    },
    {
      path: '/logout',
      name: 'logout',
      component: LogoutView,
      beforeEnter: async () => {
        sessionStore.logout()
      },
    },
    {
      path: '/sign-up',
      component: LoadingPage,
      beforeEnter: async (to) => {
        const params = new URLSearchParams(window.location.search)
        // scrape WBG token if it exists
        if (params.has('token')) {
          await ParseJWT(params.get('token') as string, import.meta.env.VITE_WBGK).then((token) => {
            localStorage.setItem('wbg_details', JSON.stringify(token))
            history.pushState({}, '', to)
          })
        }
        createAccount()
      },
    },
    {
      path: '/create-account',
      name: 'create-account',
      component: CreateAccount,
      beforeEnter: (to, from, next) => accountCreationGuard(to, from, next, userStore),
      children: [
        {
          path: 'details',
          name: 'UserDetailsForm',
          component: UserDetailsForm,
        },
        {
          path: 'verify',
          name: 'MobileVerification',
          component: MobileVerificationForm,
        },
        {
          path: 'member-details',
          name: 'CaptureFundMemberId',
          component: CaptureFundMemberId,
        },
        {
          path: 'select-fund',
          name: 'SelectSuperFund',
          component: SelectSuperFund,
        },
        {
          path: 'account-number',
          name: 'CaptureSuperAccountNumber',
          component: CaptureSuperAccountNumber,
        },
        {
          path: 'super-balance',
          name: 'CaptureSuperBalance',
          component: CaptureSuperBalance,
        },
        {
          path: 'super-investment-options',
          name: 'CaptureInvestmentOptions',
          component: CaptureInvestmentOptions,
        },
        {
          path: 'subscribe',
          name: 'Paywall',
          component: Paywall,
          beforeEnter: (to, from, next) => {
            const whitelabel = sessionStore.getWhitelabelData
            if (whitelabel.subscription_required) next()
            else next({ name: 'clientDashboard' })
          },
        },
        {
          path: 'terms',
          name: 'TermsAndConditionsForm',
          component: TermsAndConditionsForm,
        },
      ],
    },
    {
      path: '/deactivate',
      name: 'Deactivate',
      redirect: '/deactivate/account',
      beforeEnter: (to, from, next) => {
        if (!isAuthenticated.value) login() // will redirect to login page && stop execution
        next()
      },
      component: DeactivateAccountView,
      children: [
        {
          path: 'account',
          name: 'DeactivateAccount',
          component: DeactivateAccount,
        },
        {
          path: 'survey',
          name: 'DeactivateSurvey',
          component: DeactivateSurvey,
        },
        {
          path: 'farewell',
          name: 'DeactivateFarewell',
          component: DeactivateFarewell,
        },
      ],
    },
    {
      path: '/impersonate',
      name: 'impersonation',
      component: LoadingPage,
      beforeEnter: (to, from, next) => {
        const api = getApiInstance()
        const token = to.query.token as string
        if (token) {
          api.setImpersonationHeader(token)
          sessionStorage.setItem('impersonationToken', token)
          sessionStore.setImpersonationState(true)
        }
        next({ name: 'dashboard' })
      },
    },
    {
      path: '/network-issue',
      name: 'network-issue',
      component: NetworkIssue,
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: Maintenance,
    },
    {
      path: '/fsg',
      name: 'fsg',
      component: Fsg,
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: NotFound,
    },
    {
      path: '/contactUs',
      name: 'contactUs',
      component: ContactUs,
    },
    {
      path: '/security',
      name: 'Security',
      component: SecurityPage,
    },
    {
      path: '/media',
      name: 'Media',
      component: Media,
    },
    {
      path: '/how-otivo-makes-money',
      name: 'How Otivo makes money',
      component: HowOtivoMakesMoney,
    },
    {
      path: '/personal-pricing',
      name: 'Personal pricing',
      meta: {
        type: 'personal',
      },
      component: PersonalPricing,
    },
    {
      path: '/advice-practices',
      name: 'Advice practices',
      meta: {
        type: 'business',
      },
      component: AdvicePractices,
    },
    {
      path: '/super-funds',
      name: 'Super funds',
      meta: {
        type: 'business',
      },
      component: SuperFunds,
    },
    {
      path: '/intro/boost-super',
      name: 'intro-boost-super',
      component: OnboardingIntroductoryBoostSuperPage,
    },
    {
      path: '/intro/insurance',
      name: 'intro-insurance',
      component: OnboardingIntroductoryInsurancePage,
    },
    {
      path: '/intro/optimize-super',
      name: 'intro-optimize-super',
      component: OnboardingIntroductoryOptimizeSuperPage,
    },
    {
      path: '/intro/salary-sacrifice',
      name: 'intro-salary-sacrifice',
      component: OnboardingIntroductorySalarySacrificePage,
    },
    {
      path: '/content/:content_type',
      name: 'content',
      component: ExtraContent,
    },
    {
      path: '/email/:subscriptionId/:userId',
      name: 'unsubscribe-email',
      component: UnsubscribeEmail,
    },

    ...dashboardRoutes(userStore, sessionStore, accountFlagsStore),
  ] as Array<RouteRecordRaw>
}
