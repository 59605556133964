export default function (numberString: string): boolean {
  const numberArray = numberString.split('').map(Number)
  // deliberately take off the last digit
  const checkDigit = numberArray.pop()
  let sum = 0
  let doubleNextValue = true

  for (let i = numberArray.length - 1; i >= 0; i--) {
    const weight = doubleNextValue ? 2 : 1
    doubleNextValue = !doubleNextValue
    numberArray[i] *= weight
    if (numberArray[i] > 9) numberArray[i] -= 9
    sum += numberArray[i]
  }

  const next10Interval = Math.ceil(sum / 10) * 10
  const checkSum = next10Interval - sum
  return checkSum === checkDigit
}
