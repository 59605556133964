<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 1.8075h-.75V1c0-.265216-.1054-.51957-.2929-.707107C10.7696.105357 10.5152 0 10.25 0c-.26522 0-.51957.105357-.70711.292893C9.35536.48043 9.25.734784 9.25 1v.8075h-2.5V1c0-.265216-.10536-.51957-.29289-.707107C6.26957.105357 6.01522 0 5.75 0s-.51957.105357-.70711.292893C4.85536.48043 4.75.734784 4.75 1v.8075H4c-1.06087 0-2.07828.42143-2.82843 1.17157C.421427 3.72922 0 4.74663 0 5.8075V12c0 1.0609.421427 2.0783 1.17157 2.8284C1.92172 15.5786 2.93913 16 4 16h8c1.0609 0 2.0783-.4214 2.8284-1.1716C15.5786 14.0783 16 13.0609 16 12V5.81c.0003-.5255-.1029-1.04591-.3038-1.5315-.2008-.48559-.4954-.92684-.8669-1.29854-.3715-.3717-.8125-.66656-1.298-.86774-.4855-.20117-1.0058-.30472-1.5313-.30472Zm-8 2h.75V5c0 .26522.10536.51957.29289.70711C5.23043 5.89464 5.48478 6 5.75 6s.51957-.10536.70711-.29289C6.64464 5.51957 6.75 5.26522 6.75 5V3.8075h2.5V5c0 .26522.10536.51957.29289.70711C9.73043 5.89464 9.98478 6 10.25 6c.2652 0 .5196-.10536.7071-.29289.1875-.18754.2929-.44189.2929-.70711V3.8075H12c.5304 0 1.0391.21071 1.4142.58579.3751.37507.5858.88378.5858 1.41421V6.5H2v-.69c-.00033-.26285.05116-.52319.15152-.76613.10036-.24294.24763-.46372.43338-.6497.18575-.18598.40634-.33352.64916-.43419.24281-.10067.50309-.15248.76594-.15248ZM12 14H4c-.53043 0-1.03914-.2107-1.41421-.5858C2.21071 13.0391 2 12.5304 2 12V8.5h12V12c0 .5304-.2107 1.0391-.5858 1.4142S12.5304 14 12 14Z"
      :class="`fill-${fill1}`" />
    <path
      d="M10.7201 9.30503c-.0912-.09463-.2002-.17036-.3207-.22284s-.2502-.08069-.3816-.08301c-.13142-.00232-.26201.02129-.3843.06948-.12229.04819-.23388.12002-.32838.21137l-1.9525 1.88747-.725-.5825c-.10122-.0906-.21984-.1597-.34864-.203-.1288-.0433-.26507-.0598-.40048-.0487-.13542.0111-.26715.0498-.38715.1135s-.22574.1513-.31079.2572c-.08505.106-.14761.2282-.18386.3591-.03625.131-.04544.2679-.027.4026.01844.1346.06412.264.13426.3804.07013.1164.16325.2172.27366.2964l1.4125 1.1375.0375.025.065.0425c.03226.0203.06564.0386.1.055l.065.03c.04003.0143.08095.026.1225.035.0179.0065.03629.0115.055.015.05941.0108.11962.0167.18.0175.06812 0 .13602-.0076.2025-.0225l.06-.0175c.04595-.012.09105-.027.135-.045.0241-.011.04749-.0235.07-.0375.03841-.0195.07519-.0421.11-.0675.02436-.0161.04773-.0336.07-.0525.015-.015.0325-.025.0475-.0375l2.58748-2.5c.0945-.0914.17-.2005.2223-.3211.0522-.1206.0802-.2503.0823-.3817.0021-.13142-.0217-.26197-.0701-.38417-.0484-.1222-.1205-.23366-.212-.328Z"
      :class="`fill-${fill2}`" />
  </svg>
</template>
<script setup lang="ts">
interface Props {
  fill1: string
  fill2: string
}
withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red',
})
</script>

<style scoped></style>
