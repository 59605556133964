export default [
  {
    order: 0,
    imgUrl: 'https://store.otivo.com/HomePageImages/super-funds.webp',
    highlight: 'Super Funds',
    heading: 'Super-sized member advice',
    body: 'Re-engage and retain existing members through Otivo’s super-engaging financial advice.',
    features: [
      'Improve member retirement outcomes',
      'Discover member insights via our custom reporting tools',
      'Customisable technology that securely syncs with your business',
    ],
    button: {
      link: '/super-funds',
      label: 'Read more',
    },
  },
  {
    order: 1,
    imgUrl: 'https://store.otivo.com/HomePageImages/employee-wellbeing.webp',
    highlight: 'Employee Wellbeing',
    heading: 'Wellbeing programmes',
    body: 'A proven way to reduce financial stress and improve the wellbeing of your employees.',
    features: [
      'Simple and secure installation',
      'Improve employee wellbeing and engagement',
      'Increase employee productivity',
    ],
    button: {
      link: '/employee-wellbeing',
      label: 'Discover more',
    },
  },
  {
    order: 2,
    imgUrl: 'https://store.otivo.com/HomePageImages/financial-adviser.webp',
    highlight: 'Financial Advisers',
    heading: 'Hybrid models for financial advisers',
    body: 'Service more clients, more efficiently and easily discover clients who need one-on-one advice.',
    features: [
      'Easily discover clients who need one-on-one advice',
      'Improve business efficiency and increase client retention',
      'Simple plug and play technology',
    ],
    button: {
      link: '/advice-practices',
      label: 'Learn more',
    },
  },
]
