<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1777_15608)">
      <path
        d="M10.0009 1.78707e-07C8.02281 -0.000373679 6.08904 0.585847 4.44414 1.68454C2.79925 2.78323 1.51711 4.34504 0.759871 6.17244C0.00263291 7.99984 -0.195689 10.0108 0.189986 11.9509C0.575661 13.891 1.52801 15.6732 2.92659 17.072C4.32517 18.4709 6.10717 19.4235 8.04721 19.8096C9.98726 20.1956 11.9982 19.9977 13.8258 19.2408C15.6533 18.4839 17.2153 17.202 18.3143 15.5574C19.4133 13.9127 19.9999 11.979 19.9999 10.0009C19.9999 7.34885 18.9465 4.80536 17.0714 2.92987C15.1963 1.05439 12.653 0.000501424 10.0009 1.78707e-07ZM14.388 14.8814C13.3219 15.8681 11.8646 16.4389 10.1294 16.4389C8.93766 16.4391 7.76932 16.108 6.75492 15.4825C5.74053 14.8569 4.92005 13.9617 4.38516 12.8967C3.89168 11.9166 3.65739 10.8265 3.7046 9.73014C3.7518 8.63382 4.07894 7.56785 4.65484 6.63379C5.23074 5.69973 6.03622 4.92869 6.99454 4.39413C7.95286 3.85956 9.03209 3.57929 10.1294 3.58C11.7261 3.55621 13.27 4.15166 14.4371 5.24147L12.6226 7.01256C11.9495 6.38308 11.0584 6.03949 10.137 6.05424C9.32322 6.06015 8.53182 6.32125 7.8743 6.80074C7.21678 7.28023 6.72628 7.95395 6.47192 8.72696C6.33692 9.13761 6.26677 9.56679 6.264 9.99905C6.2656 10.4314 6.3384 10.8606 6.47948 11.2693C6.73125 12.0405 7.21917 12.713 7.87419 13.1916C8.52922 13.6702 9.31817 13.9308 10.1294 13.9363C10.9202 13.9612 11.6991 13.7392 12.3579 13.3012C12.7071 13.0616 13.0043 12.7539 13.2316 12.3966C13.459 12.0393 13.6119 11.6398 13.6811 11.222H10.1294V8.83281H16.1667C16.2637 9.26377 16.3087 9.70481 16.3009 10.1465C16.3009 12.1009 15.6015 13.7473 14.388 14.8814Z"
        fill="#0064FF" />
    </g>
    <defs>
      <clipPath id="clip0_1777_15608">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'GmailIcon'
}
</script>

<style scoped></style>
