<template>
  <div>
    <div class="relative min-h-[49px]">
      <div
        id="selectMenuContainer"
        ref="selectMenuContainer"
        v-on-click-outside="closeOptions"
        :class="[
          { '!outline-0 !shadow-none !border-grey-4': disabled },
          errorMessage
            ? 'border-red-dark hover:otivo-outline-error focus-within:otivo-outline-error active:otivo-outline-error'
            : 'border-grey-3 hover:otivo-outline focus-within:otivo-outline active:otivo-outline',
            containerClass !== ''? containerClass: ''
        ]"
        class="absolute z-10 rounded-[5px]"
        @keydown="handleKeyPress">
        <button
          id="selectMenuTrigger"
          :class="[
            { 'border-b-0 rounded-b-none': optionsVisible },
            { 'cursor-not-allowed !bg-grey-4 !border-grey-4': disabled },
            selectedItem.value ? 'text-grey-1' : 'text-grey-2',
            errorMessage ? 'border-red-dark text-red-dark' : 'border-grey-3',
            inputClass !== ''? inputClass: ''
          ]"
          :disabled="disabled"
          class="flex items-center justify-between gap-[20px] py-[13px] px-[15px] border-[1px] rounded-[5px] bg-grey-5 text-button-3 outline-0"
          @click="toggleOptions">
          {{ selectedItem.label }}
          <DropdownArrow
            :class="optionsVisible ? '' : 'rotate-180'"
            :stroke="dropdownArrowColour" />
        </button>
        <div
          v-if="optionsVisible"
          class="flex flex-col gap-[5px] px-[15px] pb-[5px] bg-grey-5 border-[1px] border-grey-field border-t-0 rounded-bl-[5px] rounded-br-[5px]">
          <div
            v-for="(item, index) in options"
            :key="index"
            :class="[
              { 'text-otivo-blue': item.value === selectedItem.value },
              { 'bg-blue-5': hoveredIndex === index }
            ]"
            class="py-[8px] px-[10px] rounded-[5px] text-grey-1 cursor-pointer z-10"
            @click="setSelected(item)"
            @mouseenter="setHoveredIndex(index)">
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
    <p v-if="errorMessage" class="otivo-error-message">
      {{ errorMessage }}
    </p>
  </div>
</template>

<!-- HOW DO WE WANT TO HANDLE ERRORS AND THE HEIGHT? NEED TO
DEFINE THE HEIGHT AS THE CONTAINER IS DISPLAY: ABSOLUTE -->
<script lang="ts" setup>
import DropdownArrow from '@/components/icons/DropdownArrow.vue'
import { computed, onMounted, ref, watch } from 'vue'
import { vOnClickOutside } from '@vueuse/components'
import { InputItem } from '@/types/InputItem'

type Props = {
  options: Array<InputItem>
  placeholder?: string
  defaultValue?: InputItem
  errorMessage?: string
  disabled?: boolean
  inputClass?: string,
  containerClass?: string
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: '',
  errorMessage: '',
  defaultValue: undefined,
  disabled: false,
  inputClass: 'w-max'
})
const emits = defineEmits<{
  (e: 'selected', val: InputItem): void
}>()

onMounted(() => {
  if (props.placeholder) {
    selectedItem.value.label = props.placeholder
    return
  }
  if (props.defaultValue) {
    selectedItem.value = props.defaultValue
    return
  }

  selectedItem.value = props.options[0]
})

const optionsVisible = ref(false)
const toggleOptions = () => {
  optionsVisible.value = !optionsVisible.value
}

const closeOptions = () => {
  optionsVisible.value = false

  if (!selectedItem.value.value) hoveredIndex.value = null
}

const selectedItem = ref<InputItem>({ label: '', value: '' })
const setSelected = (item: InputItem) => {
  selectedItem.value = item
  toggleOptions()
}

const hoveredIndex = ref<number | null>(null)
const setHoveredIndex = (key: number) => {
  hoveredIndex.value = key
}
const userHasInteractedWithOptions = computed(() => hoveredIndex.value !== null)

const handleKeyPress = (val: KeyboardEvent) => {
  if (val.code === 'Escape') {
    closeOptions()
  }

  if (val.code === 'ArrowUp') {
    val.preventDefault()
    moveFocusUp()
  }

  if (val.code === 'ArrowDown') {
    val.preventDefault()
    moveFocusDown()
  }

  if (val.code === 'Tab') {
    closeOptions()
  }

  if (val.code === 'Enter' && userHasInteractedWithOptions.value) {
    if (hoveredIndex.value) {
      selectedItem.value = props.options[hoveredIndex.value]
    }
  }

  if (val.code === 'Space' && userHasInteractedWithOptions.value) {
    if (hoveredIndex.value) {
      selectedItem.value = props.options[hoveredIndex.value]
    }
  }
}

const moveFocusUp = () => {
  if (hoveredIndex.value === 0 || hoveredIndex.value === null) {
    return
  }

  hoveredIndex.value = hoveredIndex.value - 1
}

const moveFocusDown = () => {
  if (hoveredIndex.value === props.options.length - 1) {
    return
  }

  if (hoveredIndex.value === null) {
    hoveredIndex.value = 0
    return
  }

  hoveredIndex.value = hoveredIndex.value + 1
}

watch(selectedItem, () => {
  emits('selected', selectedItem.value)
})

const dropdownArrowColour = computed(() => {
  if (props.errorMessage) return '#982c17'
  if (optionsVisible.value) return '#0064FF'
  return '#888888'
})
</script>
