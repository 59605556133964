import { defineStore } from 'pinia'
import { fetchAllFAQs } from '@/services/ArticlesService'
import { computed, reactive } from 'vue'

export const useFAQStore = defineStore('faqs-store', () => {
  const state = reactive({
    faqs: [{}]
  })

  const faqs = computed(() => state.faqs)

  const fetchFAQs = async (type: string) => {
    const results = await fetchAllFAQs(type)
    state.faqs = results.data.data
  }

  return {
    faqs,
    fetchFAQs
  }
})
