<template>
  <loader v-if="loading" />
  <div v-else class="leading-normal" v-html="assumptions" />
</template>

<script>
import Loader from '../Loader/Loader.vue'

import ContentService from '../../services/ContentService'

export default {
  name: 'GraphAssumptionsBody',
  components: { Loader },
  props: {
    plan: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      assumptions: null,
    }
  },

  created() {
    if (this.assumptions === null) {
      ContentService.getGraphAssumptions(this.plan)
        .then((res) => {
          this.loading = false
          this.assumptions = res.data.data.assumptions
            ? res.data.data.assumptions
            : 'No assumptions'
        })
        .catch((err) => {
          this.loading = false
          this.assumptions = 'Unable to fetch the assumptions.'
          throw err
        })
    } else {
      this.loading = false
    }
  },
}
</script>

<style scoped></style>
