import _ from 'lodash'

const adjustFitnessPlanScore = (value, type) => {
  const debtScore = 0.3
  const savingsScore = 0.3
  const retireScore = 0.064
  const insuranceScore = 0.15
  const financialStressScore = 0.336
  // total of all above scores
  const maxTotalScore = 1.15

  const maxActualIndividualScore = 5
  const maxDebtScore = maxActualIndividualScore * debtScore
  const maxSavingsScore = maxActualIndividualScore * savingsScore
  const maxRetireScore = maxActualIndividualScore * retireScore
  const maxInsuranceScore = maxActualIndividualScore * insuranceScore
  const maxFinancialStressScore = maxActualIndividualScore * financialStressScore

  let denominator = 0

  switch (type) {
    case 'debtScore':
      denominator = maxDebtScore
      break
    case 'savingsScore':
      denominator = maxSavingsScore
      break
    case 'retireScore':
      denominator = maxRetireScore
      break
    case 'insuranceScore':
      denominator = maxInsuranceScore
      break
    case 'financialStressScore':
      denominator = maxFinancialStressScore
      break
    case 'score':
      denominator = maxTotalScore
      break
    default:
      denominator = maxTotalScore
      break
  }

  let score = Math.ceil((value / denominator) * 100)
  if (score > 100) {
    score = 100
  } else if (score < 1) {
    score = 1
  }
  return score
}

const modifyFitnessTestResultObject = (resultObject) => {
  const payload = {
    debtScore: adjustFitnessPlanScore(resultObject.debtScore, 'debtScore'),
    savingsScore: adjustFitnessPlanScore(resultObject.savingsScore, 'savingsScore'),
    retireScore: adjustFitnessPlanScore(resultObject.retireScore, 'retireScore'),
    insuranceScore: adjustFitnessPlanScore(resultObject.insuranceScore, 'insuranceScore'),
    financialStressScore: adjustFitnessPlanScore(
      resultObject.financialStressScore,
      'financialStressScore'
    )
  }

  const totalScore = _.reduce(
    payload,
    (acc, el) => {
      acc += el
      return acc
    },
    0
  )

  payload.scorePercent = Math.ceil(totalScore / 5)

  return payload
}

export { adjustFitnessPlanScore, modifyFitnessTestResultObject }
