import { defineAsyncComponent, markRaw } from 'vue'

type Option = {
  [key: string]: {
    text: string
    icon: unknown
    style: {
      solid: {
        style: string
        fill1: string
        fill2: string
      }
      outline: {
        style: string
        fill1: string
        fill2: string
      }
    }
  }
}
const getModuleStatusList: Option = {
  processing: {
    text: 'Processing',
    icon: markRaw(defineAsyncComponent(() => import('@/components/icons/RefreshIcon.vue'))),
    style: {
      solid: {
        style: 'text-otivo-blue border-[1px] border-otivo-blue rounded-[2px] heading-4',
        fill1: '#0064ff',
        fill2: '',
      },
      outline: {
        style: 'text-otivo-blue menu-2',
        fill1: '#0064ff',
        fill2: '',
      },
    },
  },
  ready: {
    text: 'Advice Ready',
    icon: markRaw(defineAsyncComponent(() => import('@/components/SVGS/StatusLightning.svg'))),
    style: {
      solid: {
        style: 'bg-otivo-red text-white border-[1px] border-otivo-red rounded-[2px] heading-4',
        fill1: 'otivo-red',
        fill2: 'white',
      },
      outline: {
        style: 'text-otivo-red menu-2',
        fill1: '',
        fill2: 'otivo-red',
      },
    },
  },
  infoNeeded: {
    text: 'Info Needed',
    icon: markRaw(defineAsyncComponent(() => import('@/components/Inputs/BaseInfoCircle.vue'))),
    style: {
      solid: {
        style: 'bg-gold-1 text-white border-[1px] border-gold-1 rounded-[2px] heading-4',
        fill1: 'white',
        fill2: 'gold-1',
      },
      outline: {
        style: 'text-gold-1 menu-2',
        fill1: 'gold-1',
        fill2: 'white',
      },
    },
  },
  actioned: {
    text: 'Actioned',
    icon: markRaw(defineAsyncComponent(() => import('@/components/icons/CheckIcon.vue'))),
    style: {
      solid: {
        style: 'bg-white text-blue-3 border-[1px] border-blue-3 rounded-[2px] heading-4',
        fill1: 'blue-3',
        fill2: '',
      },
      outline: {
        style: 'text-blue-3 menu-2',
        fill1: 'blue-3',
        fill2: '',
      },
    },
  },
  comingSoon: {
    text: 'Coming Soon',
    icon: '',
    style: {
      solid: {
        style: 'bg-white text-otivo-blue border-[1px] border-grey-2 rounded-[2px] heading-4',
        fill1: '',
        fill2: '',
      },
      outline: {
        style: 'text-otivo-blue menu-2',
        fill1: '',
        fill2: '',
      },
    },
  },
}

export default getModuleStatusList
