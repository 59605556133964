<template>
  <div>
    <fitness-test-question-label :question-number="questionNumber" question="Your income bracket" />

    <income-bracket-answer @click="setSunCorpIncomeBracket" :selected="sunCorpIncomeBracket" />
  </div>
</template>

<script>
import FitnessTestQuestionLabel from '../QuestionsLabel/FitnessTestQuestionLabel.vue'

import { mapState } from 'vuex'
import IncomeBracketAnswer from './IncomeBracketAnswer'
export default {
  name: 'SunCorpIncomeBracket',
  components: { IncomeBracketAnswer, FitnessTestQuestionLabel },
  computed: {
    ...mapState({
      sunCorpIncomeBracket: (state) => state.fitnessTest.questions.sunCorpIncomeBracket,
    }),
  },

  props: {
    questionNumber: {
      type: String,
      default: '',
    },
  },

  methods: {
    setSunCorpIncomeBracket(value) {
      this.$store.dispatch('setFitnessTestSunCorpIncomeBracket', value)
    },
  },
}
</script>

<style scoped></style>
