import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { ref } from 'vue'
import * as Sentry from '@sentry/core'

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: '',
  'Otivo-Impersonation': '',
  'Otivo-Client-Key': '',
  'sentry-trace': '',
  baggage: '',
}

type Response<T> = Promise<AxiosResponse<T>>
const instance = ref<AxiosInstance>()
const authenticated = ref(false)

let abortController: AbortController | null = null

const getAPIUrl = () => {
  if (import.meta.env.MODE === 'development') {
    if (import.meta.env.VITE_TEST_BASE_API_URL) {
      return import.meta.env.VITE_TEST_BASE_API_URL
    }

    if (location.hostname !== '127.0.0.1') {
      return `https://api-${location.hostname}/api`
    }

    return 'https://laravel.devel/api'
  } else return `https://api-${location.hostname}/api`
}

const createAbortController = () => {
  abortController = new AbortController()
  return abortController
}

const abortPendingRequest = async () => {
  if (abortController) {
    abortController.abort()
    createAbortController() // Create a new AbortController for future requests
  }
  return true
}

const api = (() => {
  const createAxiosInstance = (): AxiosInstance => {
    // Create `baggage` header
    const instance = axios.create({
      baseURL: getAPIUrl(),
      headers: headers,
      timeout: 60_000,
      timeoutErrorMessage: 'Request timed out',
    })

    instance.interceptors.response.use((response) => {
      return response
    })

    instance.interceptors.request.use((requestConfig) => {
      if (abortController) {
        requestConfig.signal = abortController.signal
      }
      return requestConfig
    })

    return instance
  }
  const setAuthenticated = async (status: boolean, token: string) => {
    authenticated.value = status
    if (status) {
      try {
        if (!token) {
          localStorage.getItem('at')
        }
      } catch (err) {
        console.error(err)
      }

      headers.Authorization = `Bearer ${token}`
      if (!instance.value) {
        instance.value = createAxiosInstance()
      }
      // update instance headers
      instance.value.defaults.headers['Authorization'] = `Bearer ${token}`
    }
  }

  const setSentryTraceHeader = () => {
    const activeSpan = Sentry.getActiveSpan()

    const rootSpan = activeSpan ? Sentry.getRootSpan(activeSpan) : undefined

    // Create `sentry-trace` header
    const trace = rootSpan ? Sentry.spanToTraceHeader(rootSpan) : undefined
    const sentryBaggageHeader = rootSpan ? Sentry.spanToBaggageHeader(rootSpan) : undefined

    try {
      if (!instance.value) return
      instance.value.defaults.headers['sentry-trace'] = trace
      instance.value.defaults.headers['baggage'] = sentryBaggageHeader
    } catch (e) {
      console.error(e)
    }
  }

  const setClientHeader = (id: string) => {
    if (id && instance.value) {
      instance.value.defaults.headers['Otivo-Client-Key'] = btoa(id)
      return
    }
    console.warn('no id')
  }

  const setImpersonationHeader = (token?: string) => {
    const tk = token ? token : sessionStorage.getItem('impersonationToken')
    if (tk && instance.value) {
      instance.value.defaults.headers['Otivo-Impersonation'] = tk
      // todo: move this to main?
      return true
    }
  }

  const handleError = (error: any) => {
    if (error.code === 'ERR_CANCELED') console.warn(error)
    else if (error.response) {
      //TODO: change errors that incorrectly throw 401 to 403 then re-enable this
      // if (error.response.status === 401) logout()
      // else throw error
      throw error
    } else {
      console.error('An unexpected error occurred:', error)
      throw error
    }
  }

  const Get = <T>(url: string, config?: AxiosRequestConfig<object>): Response<T> => {
    if (!instance.value) throw new Error('No Axios instance')
    try {
      return instance.value.get<T>(url, {
        ...config,
        signal: abortController?.signal || undefined,
      })
    } catch (e) {
      handleError(e)
      throw e
    }
  }

  const Post = <T>(
    url: string,
    body: object = {},
    config?: AxiosRequestConfig<object>,
  ): Response<T> => {
    if (!instance.value) throw new Error('No Axios instance')
    try {
      return instance.value.post(url, body, {
        ...config,
        signal: abortController?.signal || undefined,
      })
    } catch (e) {
      handleError(e)
      throw e
    }
  }

  const Put = <T>(url: string, body?: object, config?: AxiosRequestConfig<object>): Response<T> => {
    if (!instance.value) throw new Error('No Axios instance')
    return instance.value.put(url, body, config)
  }

  const Patch = <T>(
    url: string,
    body?: object,
    config?: AxiosRequestConfig<object>,
  ): Response<T> => {
    if (!instance.value) throw new Error('No Axios instance')
    return instance.value.patch(url, body, config)
  }

  const Delete = <T>(url: string, config?: object): Response<T> => {
    if (!instance.value) throw new Error('No Axios instance')
    return instance.value.delete(url, config)
  }

  const getRequestType = (type: 'post' | 'get' | 'put' | 'patch' | 'delete') => {
    switch (type) {
      case 'post':
        return Post
      case 'get':
        return Get
      case 'put':
        return Put
      case 'patch':
        return Patch
      case 'delete':
        return Delete
    }
  }

  createAbortController()
  if (!instance.value) instance.value = createAxiosInstance()

  return {
    Get,
    Post,
    Put,
    Patch,
    Delete,
    getRequestType,
    setAuthenticated,
    abortPendingRequest,
    setSentryTraceHeader,
    setClientHeader,
    setImpersonationHeader,
    authenticated,
    instance: instance.value,
  }
})()

const getApiInstance = () => api
export default getApiInstance
