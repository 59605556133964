import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import { computed } from 'vue'

export const isCFSWhitelabel = () => {
  const sessionStore = useSessionStore()
  const whitelabelData = computed(() => sessionStore.getWhitelabelData)
  return (
    whitelabelData.value?.callcentre_portal &&
    (whitelabelData.value.identifier?.toUpperCase().includes('COLONIAL_FIRST_STATE') ||
      whitelabelData.value.frontend_url?.toLowerCase().includes('cfs'))
  )
}
