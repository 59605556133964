<template>
  <div class="flex flex-col">
    <h3>A heads up about this change</h3>

    <BeatLoader
      v-if="loading"
      class="mt-[80px] text-center min-h-[160px]"
      color="var(--otivo-blue)"
      size="32px" />

    <template v-else>
      <p v-if="hasInSuperInsurances" class="paragraph-1 mt-5">
        Changing your super fund will reset any investment and insurance advice we've provided.
        You’ll need to select new investment options and update the insurance provided by your new
        fund.
      </p>
      <p v-else class="paragraph-1 mt-5">
        Changing your super fund will reset any investment advice we've provided. You’ll need to
        select new investment options provided by your new fund.
      </p>

      <p class="paragraph-1 mt-5">
        You should note that we only consider the investment options inside super, and don't assess
        the suitability of the super fund you're in or want to change.
      </p>

      <p class="paragraph-1 mt-5">Please confirm if you want to proceed.</p>

      <div class="flex flex-col md:flex-row justify-end mt-10 gap-5 md:gap-4">
        <OtivoButton @click="cancel" colour="white">Cancel</OtivoButton>
        <OtivoButton @click="updateSuperFund" colour="blue">Proceed</OtivoButton>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import superService from '@/services/SuperService.ts'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import { useSuperStore } from '@/store/pinia/SuperStore.ts'

const modalStore = useModalStore()
type Props = {
  onCancel: () => void
  onProceed: () => void
}
const props = defineProps<Props>()

const superStore = useSuperStore()
const activeSuper = computed(() => superStore.getActiveSuperObject)

const hasInSuperInsurances = ref(false)
const loading = ref(false)

onMounted(() => {
  loading.value = true
  superService
    .hasInSuperInsurances(activeSuper.value?.id)
    .then((response) => {
      hasInSuperInsurances.value = response.data?.has_in_super_insurances
      loading.value = false
    })
    .catch(() => {
      loading.value = false
    })
})

const cancel = () => {
  props?.onCancel()

  modalStore.closeModal()
}

const updateSuperFund = async () => {
  props?.onProceed()

  modalStore.closeModal()
}
</script>
