<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    :stroke="fill"
    :stroke-width="strokeWidth"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.5013 18.7498C22.1892 18.7512 21.8842 18.6435 21.6272 18.4413L11.9972 10.8299L2.3635 18.4413C2.03877 18.6968 1.64023 18.7994 1.25095 18.7275C0.86166 18.6557 0.511671 18.415 0.273935 18.0555C0.161319 17.8792 0.0808817 17.6788 0.0374019 17.4663C-0.00607801 17.2537 -0.0117128 17.0334 0.020833 16.8183C0.0533787 16.6032 0.12344 16.3978 0.226854 16.2142C0.330269 16.0307 0.464925 15.8728 0.622821 15.7499L11.9972 6.75L23.3717 15.7499C23.5312 15.8728 23.6673 16.0313 23.7717 16.2159C23.8761 16.4006 23.9468 16.6074 23.9794 16.824C24.012 17.0407 24.0059 17.2626 23.9614 17.4765C23.9169 17.6903 23.835 17.8917 23.7205 18.0684C23.5785 18.2834 23.3941 18.4572 23.1822 18.5756C22.9704 18.694 22.7371 18.7537 22.5013 18.7498Z"
      :fill="fill"
      :class="`fill-${fill}`" />
  </svg>
</template>

<script>
export default {
  name: 'ArrowWide',
  props: {
    fill: {
      type: String,
      default: '#888888',
    },
    strokeWidth: {
      type: Number,
      default: 1,
    },
  },
}
</script>

<style scoped></style>
