<template>
  <div>
    <SideAccordionNavTemplate
      id="clientNav"
      :is-collapsable="true"
      :side-nav-accordions="sideNavAccordions"
      :styles="{
        heading: 'mh6 md:text-button1 text-blue-1',
        content: 'p1 md:p2 hover:text-blue-1',
        base: '',
        contentIconFill: 'blue-2',
      }"
      chevron-colour="#0037A1"
      @click="emit('click')" />
    <AncillaryLinks v-if="showAncillaryLinks" @click="emit('click')" />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import SideAccordionNavTemplate from '@/components/NavBar/TopNavBar/SideAccordionNavigation/SideAccordionNavTemplate.vue'
import { useLDFlag, useLDReady } from 'launchdarkly-vue-client-sdk'
import { useNavBarStore } from '@/store/pinia/NavBarStore.ts'
import AncillaryLinks from '@/components/CFS/CFSClientConfirmationSteps/AncillaryLinks.vue'

// Activating LaunchDarkly flags - must be done in component
const ldReady = useLDReady()
const enableModule2 = ldReady ? useLDFlag('enable-cfs-module-2', false) : ref(false)
const enableModuleNonConcessionalContributions = ldReady
  ? useLDFlag('enable-cfs-module-2.3', false)
  : ref(false)
const enableInsuranceModule = ldReady ? useLDFlag('enable-insurance-module', false) : ref(false)
const navRouteStore = useNavBarStore()
const showAncillaryLinks = computed(() => navRouteStore.showAncillaryLinks)
const emit = defineEmits(['click'])

const ldFlags = computed(() => {
  return {
    disabledModule2: !enableModule2.value,
    disabledModuleNonConcessionalContributions: !enableModuleNonConcessionalContributions.value,
    disabledInsurance: !enableInsuranceModule.value,
  }
})
watch(
  ldFlags,
  () => {
    navRouteStore.setLDFlags(ldFlags.value)
    // navRouteStore.fetchNavRoutes()
  },
  { immediate: true, deep: true },
)

const sideNavAccordions = computed(() => navRouteStore.getNavRoutes)
</script>

<style scoped></style>
