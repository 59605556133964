<template>
  <div class="h-full w-full">
    <content-breadcrumb
      v-if="getHeading !== 'Terms and Conditions'"
      class="flex m-6 mb-2 breadcrumbColour" />
    <div v-if="loading" class="p-6">
      <h1>{{ getHeading }}</h1>
      <loader />
    </div>
    <div v-else v-html="content" class="p-6" />
  </div>
</template>

<script>
import MiscellaneousService from '@/services/MiscellaneousService'
import Loader from '@/components/Loader/Loader.vue'
import ContentBreadcrumb from '@/components/NavBar/Breadcrumb/ContentBreadcrumb.vue'

export default {
  name: 'RenderExtraContent',
  components: { Loader, ContentBreadcrumb },
  data() {
    return {
      content: '',
      loading: true
    }
  },

  props: {
    getContentFor: {
      type: String,
      default: 'ABOUT_US'
    }
  },

  watch: {
    $route: {
      handler: function (to, from) {
        this.loading = true
        MiscellaneousService.getContent(this.getContentType(to.params.contentType))
          .then((res) => {
            this.content = res.data.data
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
            throw err
          })
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    MiscellaneousService.getContent(this.getContentFor)
      .then((res) => {
        this.content = res.data.data
        this.loading = false
      })
      .catch((err) => {
        this.loading = false
        throw err
      })
  },

  computed: {
    getHeading() {
      const tags = {
        FAQS: 'FAQS',
        ABOUT_US: 'Our Team',
        PRIVACY: 'Privacy Policy',
        SECURITY: 'Security',
        TERMS_AND_CONDITIONS: 'Terms and Conditions',
        FSG: 'Financial Services Guide'
      }
      return tags[this.getContentFor]
    }
  },
  methods: {
    getContentType(params) {
      const tags = {
        faqs: 'FAQS',
        aboutUs: 'ABOUT_US',
        privacy: 'PRIVACY',
        security: 'SECURITY',
        termsAndConditions: 'TERMS_AND_CONDITIONS'
      }
      if (this.$route.params.content_type && tags[this.$route.params.content_type]) {
        return tags[this.$route.params.content_type]
      }
    }
  }
}
</script>

<style scoped>
:deep(h3) {
  @apply pb-3 !important;
}

:deep(.images-div) {
  @apply flex flex-row justify-between;
}

h1 {
  @apply text-h1;
}

.breadcrumbColour :deep(a) {
  @apply text-black !important;
}
</style>
