<template>
  <div :class="style.container">
    <div
      :tabindex="0"
      id="test-id"
      :class="style.buttonWrapper"
      v-for="(button, index) in buttons"
      :key="index"
      :data-test="`${button.value}Btn`"
      @click="selectButton(button)"
      @keyup.enter="selectButton(button)"
      @keyup.space="selectButton(button)">
      <div :class="[style.button, buttonStatus(button.value)]">
        <div :class="style.buttonText">
          {{ this.capitalize(button.name) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { capitalize as cap } from '@/lib/helpers'

export default {
  name: 'InputToggleButton',
  props: {
    selected: {
      type: String,
      default: '',
      required: true
    },
    buttons: {
      type: Array,
      default: () => {
        return [
          { name: 'Button 1', value: 'button_1' },
          { name: 'Button 2', value: 'button_2' }
        ]
      }
    },
    rounded: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: ''
    },
    style: {
      type: Object,
      required: false,
      default: () => {
        return {
          container: 'flex overflow-auto flex-no-wrap pb-4 gap-2 p-2',
          buttonWrapper: 'flex-1 min-w-32 min-h-10 cursor-pointer',
          button: 'h-full w-full flex text-center border rounded ring py-3',
          buttonText: 'flex-1 self-center mb3 md:b2',
          active: 'border-otivo-blue ring-blue-4',
          inactive: 'border-grey-3 ring-transparent'
        }
      }
    }
  },
  emits: ['click'],

  methods: {
    selectButton(button) {
      this.$emit('click', button.value)
    },

    buttonStatus(button) {
      return button === this.selected.toLowerCase() ? this.style.active : this.style.inactive
    },

    capitalize(value) {
      return cap(value)
    }
  }
}
</script>
