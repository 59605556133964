import getApiInstance from '@/services/Api'

const api = getApiInstance()

export default {
  // get privacy terms of conditions and other things
  getContent(contentType) {
    return api.Get(`/content/${contentType}`)
  },

  submitContactUsForm(payload) {
    return api.Post('/enquiry', payload)
  },

  submitOtivoSuperReportForm(payload) {
    return api.Post('/download-super-report', payload)
  },

  submitSunCorpContactUsForm(payload) {
    return api.Post('/enquiry', {
      sunCorpEnquiry: true,
      ...payload
    })
  },

  registerUserLogin() {
    return api.Post('/register_login')
  },

  saveWhiteLabelData(payload) {
    return api.Post('/saveWhiteLabelData', payload)
  },

  getFrontendCrucialStoreData() {
    return api.Get('/getFrontendCrucialStoreData')
  },

  getFrontendStartingStoreData() {
    return api.Get('/getFrontendStartingStoreData')
  },

  getSystemStatusAndWhitelabelData() {
    return api.Get('/system/status')
  },

  postInterestedUser(payload) {
    return api.Post('/subscribe_user', payload)
  },

  getNewsArticles() {
    return api.Get('/news_articles')
  }
}
