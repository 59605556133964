<template>
  <div class="flex gap-1" tabindex="0">
    <div
      class="flex-1 w-full cursor-pointer"
      v-for="(button, index) in buttons"
      :key="index"
      @click="$emit('selected', button.value)"
      @keyup.enter="$emit('selected', button.value)"
      :tabindex="0">
      <div
        class="h-full w-full flex border border-b-0 rounded rounded-b-none pl-4 pr-2 py-8 gap-3"
        :class="button.value === selected ? `${classes.active}` : `${classes.inactive}`">
        <component
          v-if="button.component"
          :is="button.component"
          class="h-6 w-6 hidden md:block"
          :data-test="`${button.value}`"
          :fill1="
            String(button.value).toLowerCase() === String(this.selected).toLowerCase()
              ? '#FFF'
              : '#0064FF'
          "
          :fill2="
            String(button.value).toLowerCase() === String(this.selected).toLowerCase()
              ? '#FFF'
              : '#FF0000'
          " />
        <div class="self-center mb2 md:b1">
          {{ String(button.name).toUpperCase() }}
        </div>
        <div v-if="errorList.includes(button.value)">
          <needs-work />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { capitalize as cap } from '@/lib/helpers'
import NeedsWork from '@/components/icons/NeedsWork.vue'

export default {
  name: 'TabButtons',
  components: { NeedsWork },
  props: {
    selected: {
      type: [String, Number],
      default: '',
      required: true
    },
    classes: {
      type: Object,
      default: () => {
        return {
          active: 'border-grey-2 bg-otivo-blue text-white',
          inactive: 'border-grey-5 bg-blue-5 text-grey-2 hover:bg-blue-4'
        }
      }
    },
    buttons: {
      type: Array,
      default: () => {
        return [
          { name: 'Button 1', value: 'button_1' },
          { name: 'Button 2', value: 'button_2' }
        ]
      }
    },
    errorList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  emits: ['click', 'selected'],
  methods: {
    selectButton(button) {
      this.$emit('click', button.value)
    },
    capitalize(value) {
      return cap(value)
    }
  }
}
</script>
