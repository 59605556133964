<template>
  <Dropdown
    type="BOX"
    :items="mapSupers"
    @selected="(item: InputItem<superObject>) => changeSuperAccount(item.value)"
    :existing-item="{ label: activeSuper.fund_name, value: activeSuper }" />
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import SuperObject from '@/types/api/super/SuperObject.ts'
import superObject, { createNewSuperObject } from '@/types/api/super/SuperObject.ts'
import { useSuperStore } from '@/store/pinia/SuperStore.ts'
import Dropdown from '@/components/BasicInputs/Dropdown.vue'
import { InputItem } from '@/types/InputItem.ts'
import { useCheckUser } from '@/composables/users/checkUser.ts'
import { superInvestmentRecommendationHandlers } from '@/components/CFS/IntrafundInvestment/Utils/superInvestmentRecommendationHandlers.ts'
import { useUserStore } from '@/store/pinia/UserStore.ts'

const superStore = useSuperStore()
const supersArray = computed(() => superStore.getSupersArray)
const activeSuper = computed(() => superStore.getActiveSuperObject)
const { isUser, isAdmin } = useCheckUser()
const { fetchSuperInvestmentAdvice } = superInvestmentRecommendationHandlers(
  useUserStore(),
  superStore,
  isUser,
)

const mapSupers = computed(() => {
  const currentSuperObjects = supersArray.value
    .filter((superAccount) => superAccount.chant_west_product_id)
    .map((superAccount: SuperObject) => {
      return {
        label: `${superAccount.fund_name} ${superAccount.account_number ?? ''} `,
        value: superAccount,
      }
    })
  if (isAdmin())
    currentSuperObjects.push({ label: 'Add another super', value: createNewSuperObject() })

  return currentSuperObjects
})

const changeSuperAccount = async (object: superObject) => {
  if (object.fund_name === 'New super product') {
    superStore.addNewSuperObject(object)
  } else {
    superStore.setActiveSuperObject(object as SuperObject)
    fetchSuperInvestmentAdvice()
  }
}
</script>

<style scoped></style>
