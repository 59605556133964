<template>
  <div class="max-w-[1100px] mx-auto py-[40px]">
    <h1 class="text-otivo-blue">Typography</h1>

    <div class="mt-[40px]">
      <h3>Font family: Raleway</h3>
      <h1 class="mt-[20px]">Headline 1</h1>
      <h2>Headline 2</h2>
      <h3>Headline 3</h3>
      <h4>Headline 4</h4>
      <h5>Headline 5</h5>
      <h6>Headline 6</h6>
      <p class="headline-7">Headline 7</p>
      <p class="intro-1">Introduction 1</p>
      <p class="intro-2">Introduction 2</p>
      <p class="paragraph-1">Paragraph 1</p>
      <p class="paragraph-2">Paragraph 2</p>
      <p class="paragraph-3">Paragraph 3</p>
      <p class="paragraph-4">Paragraph 4</p>
      <p class="button-1">Button 1</p>
      <p class="button-2">Button 2</p>
      <p class="button-3">Button 3</p>
      <p class="button-4">Button 4</p>
      <p class="menu-1">Menu 1</p>
    </div>
  </div>
</template>

<script lang="ts" setup></script>
