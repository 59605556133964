<template>
  <div>
    <fitness-test-question-label
      :question-number="questionNumber"
      :question="`I can comfortably pay my bills${
        livingSituation !== 'own' ? `, ${livingSituation}` : ''
      } and day-to-day expenses`" />

    <number-rating :selected="canPayBills" @click="setCanPayBills" />
  </div>
</template>

<script>
import NumberRating from '../NumberRating/NumberRating.vue'

import { mapState } from 'vuex'
import FitnessTestQuestionLabel from '../QuestionsLabel/FitnessTestQuestionLabel.vue'

export default {
  name: 'CanPayBills',
  components: { FitnessTestQuestionLabel, NumberRating },
  computed: {
    ...mapState({
      livingSituation: (state) => state.fitnessTest.questions.livingSituation,
      canPayBills: (state) => state.fitnessTest.questions.canPayBills,
    }),
  },

  props: {
    questionNumber: {
      type: String,
      default: '',
    },
  },

  methods: {
    setCanPayBills(value) {
      this.$store.dispatch('setFitnessTestCanPayBills', value)
    },
  },
}
</script>

<style scoped></style>
