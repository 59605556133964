import { cloneObject } from '@/utilities'
import { MAX_GROSS_ANNUAL_SALARY_LIMIT, MAX_SUPER_FUND_LIMIT } from '@/lib/RetirementPlanHelper'
import { formatCurrency } from '@/lib/helpers'
interface ErrorTemplate {
  age: string
  income: string
  current_super_balance: string
  hasHome: string
  contribution_rate: string
  desired_retirement_income: string
  desired_retirement_age: string
  super_access_age: string
  property_income: string
  dividends_income: string
  cash_interest: string
  before_tax_contribution: string
  after_tax_contribution: string
  one_off_contribution: string
  one_off_age: string
  part_time_starting_age: string
  part_time_days: string
  part_time_duration: string
  career_breaks: object
}
const state = () => ({
  userErrors: <ErrorTemplate>{
    age: '',
    income: '',
    current_super_balance: '',
    hasHome: '',
    contribution_rate: '',
    desired_retirement_income: '',
    desired_retirement_age: '',
    super_access_age: '',
    property_income: '',
    dividends_income: '',
    cash_interest: '',
    before_tax_contribution: '',
    after_tax_contribution: '',
    one_off_contribution: '',
    one_off_age: '',
    part_time_starting_age: '',
    part_time_days: '',
    part_time_duration: '',
    career_breaks: {}
  },
  partnerErrors: <ErrorTemplate>{
    age: '',
    income: '',
    current_super_balance: '',
    hasHome: '',
    contribution_rate: '',
    desired_retirement_income: '',
    desired_retirement_age: '',
    super_access_age: '',
    property_income: '',
    dividends_income: '',
    cash_interest: '',
    before_tax_contribution: '',
    after_tax_contribution: '',
    one_off_contribution: '',
    one_off_age: '',
    part_time_starting_age: '',
    part_time_days: '',
    part_time_duration: '',
    career_breaks: {}
  },
  assetErrors: {},
  errorTabLocations: []
})

const getters = {
  userErrors(state) {
    return state.userErrors
  },
  partnerErrors(state) {
    return state.partnerErrors
  },
  userBreakErrors(state) {
    return state.userErrors.career_breaks
  },
  partnerBreakErrors(state) {
    return state.partnerErrors.career_breaks
  },
  assetErrors(state) {
    return state.assetErrors
  },
  errorLocations(state) {
    return state.errorTabLocations
  }
}

const mutations = {
  RESET_ERRORS(state) {
    const resetTemplate: ErrorTemplate = {
      age: '',
      income: '',
      current_super_balance: '',
      hasHome: '',
      contribution_rate: '',
      desired_retirement_income: '',
      desired_retirement_age: '',
      super_access_age: '',
      property_income: '',
      dividends_income: '',
      cash_interest: '',
      before_tax_contribution: '',
      after_tax_contribution: '',
      one_off_contribution: '',
      one_off_age: '',
      part_time_starting_age: '',
      part_time_days: '',
      part_time_duration: '',
      career_breaks: {}
    }
    state.userErrors = cloneObject(resetTemplate)
    state.partnerErrors = cloneObject(resetTemplate)
    state.assetErrors = {}
    state.errorTabLocations = []
  },
  SET_ERROR_LOCATIONS(state, value) {
    state.errorTabLocations = value
  },

  UPDATE_USER_ERROR(state, payload) {
    state.userErrors[payload.field] = payload.value
  },
  UPDATE_PARTNER_ERROR(state, payload) {
    state.partnerErrors[payload.field] = payload.value
  },

  UPDATE_CAREER_BREAK_ERROR(state, payload) {
    const careerBreaks = state[payload.owner].career_breaks
    if (careerBreaks[payload.index]) {
      careerBreaks[payload.index][payload.field] = payload.value
    } else {
      careerBreaks[payload.index] = {}
      careerBreaks[payload.index][payload.field] = payload.value
    }
  },

  REMOVE_CAREER_BREAK_ERROR(state, payload) {
    const careerBreaks =
      state[payload.owner === 'userData' ? 'userErrors' : 'partnerErrors'].career_breaks
    careerBreaks[payload.index] = {}
  },

  UPDATE_ASSET_ERROR(state, payload) {
    const assetError = state.assetErrors
    if (assetError[payload.index]) {
      assetError[payload.index][payload.field] = payload.value
    } else {
      assetError[payload.index] = {}
      assetError[payload.index][payload.field] = payload.value
    }
  },

  REMOVE_INCOME_ASSET_ERROR(state, index) {
    state.assetErrors[index] = {}
  }
}

const actions = {
  async validateRetirementCalculatorFields({ commit, dispatch, rootGetters }) {
    const userData = rootGetters['RetirementCalculator/userData']
    const partnerData = rootGetters['RetirementCalculator/partnerData']
    const hasPartner = rootGetters['RetirementCalculator/enablePartnerForm']
    dispatch('RetirementCalculator/setGraphLoading', true, { root: true })
    let valid = true
    commit('RESET_ERRORS')
    let errorLocations = [] // Error locations are to display where the errors are located in the tabs
    if (userData.age === null || userData.age === 0) {
      commit('UPDATE_USER_ERROR', { value: 'Please enter a valid age', field: 'age' })
      if (!errorLocations.includes(0)) errorLocations.push(0)
      valid = false
    }
    if (userData.age > userData.desired_retirement_age) {
      commit('UPDATE_USER_ERROR', {
        value: 'Your age cannot be more than your retirement age',
        field: 'age'
      })
      if (!errorLocations.includes(0)) errorLocations.push(0)
      valid = false
    }
    if (hasPartner && partnerData.age > partnerData.desired_retirement_age) {
      commit('UPDATE_PARTNER_ERROR', {
        value: 'Your partners age cannot be more than your retirement age',
        field: 'age'
      })
      if (!errorLocations.includes(0)) errorLocations.push(0)
      valid = false
    }
    if (hasPartner && (partnerData.age === null || partnerData.age === 0)) {
      commit('UPDATE_PARTNER_ERROR', { value: 'Please enter a valid age', field: 'age' })
      if (!errorLocations.includes(0)) errorLocations.push(0)
      valid = false
    }
    if (userData.income === null || userData.income === 0) {
      commit('UPDATE_USER_ERROR', { value: 'Please enter a valid income', field: 'income' })
      if (!errorLocations.includes(0)) errorLocations.push(0)
      valid = false
    }
    if (userData.income > MAX_GROSS_ANNUAL_SALARY_LIMIT) {
      commit('UPDATE_USER_ERROR', {
        value: `Maximum ${formatCurrency(MAX_GROSS_ANNUAL_SALARY_LIMIT)}`,
        field: 'income'
      })
      if (!errorLocations.includes(0)) errorLocations.push(0)
      valid = false
    }
    if (userData.hasHome === null) {
      commit('UPDATE_USER_ERROR', { value: 'Please provide an answer', field: 'hasHome' })
      if (!errorLocations.includes(0)) errorLocations.push(0)
      valid = false
    }
    if (hasPartner && (partnerData.income === null || partnerData.income === 0)) {
      commit('UPDATE_PARTNER_ERROR', { value: 'Please enter a valid income', field: 'income' })
      if (!errorLocations.includes(0)) errorLocations.push(0)
      valid = false
    }
    if (hasPartner && partnerData.income > MAX_GROSS_ANNUAL_SALARY_LIMIT) {
      commit('UPDATE_PARTNER_ERROR', {
        value: `Maximum ${formatCurrency(MAX_GROSS_ANNUAL_SALARY_LIMIT)}`,
        field: 'income'
      })
      if (!errorLocations.includes(0)) errorLocations.push(0)
      valid = false
    }
    if (userData.current_super_balance === null || userData.current_super_balance === 0) {
      commit('UPDATE_USER_ERROR', {
        value: 'Please enter a valid current super balance',
        field: 'current_super_balance'
      })
      if (!errorLocations.includes(0)) errorLocations.push(0)
      valid = false
    }
    if (userData.current_super_balance > MAX_SUPER_FUND_LIMIT) {
      commit('UPDATE_USER_ERROR', {
        value: `Maximum ${formatCurrency(MAX_SUPER_FUND_LIMIT)}`,
        field: 'current_super_balance'
      })
      if (!errorLocations.includes(0)) errorLocations.push(0)
      valid = false
    }
    if (
      hasPartner &&
      (partnerData.current_super_balance === null || partnerData.current_super_balance === 0)
    ) {
      commit('UPDATE_PARTNER_ERROR', {
        value: 'Please enter a valid current super balance',
        field: 'current_super_balance'
      })
      if (!errorLocations.includes(0)) errorLocations.push(0)
      valid = false
    }
    if (hasPartner && partnerData.current_super_balance > MAX_SUPER_FUND_LIMIT) {
      commit('UPDATE_PARTNER_ERROR', {
        value: `Maximum ${formatCurrency(MAX_SUPER_FUND_LIMIT)}`,
        field: 'current_super_balance'
      })
      if (!errorLocations.includes(0)) errorLocations.push(0)
      valid = false
    }
    if (userData.desired_retirement_income === null || userData.desired_retirement_income === 0) {
      commit('UPDATE_USER_ERROR', {
        value: 'Please enter a valid income',
        field: 'desired_retirement_income'
      })
      valid = false
      if (!errorLocations.includes(0)) errorLocations.push(0)
    }
    if (userData.desired_retirement_age === null || userData.desired_retirement_age === 0) {
      commit('UPDATE_USER_ERROR', {
        value: 'Please enter a valid age',
        field: 'desired_retirement_age'
      })
      valid = false
      if (!errorLocations.includes(0)) errorLocations.push(0)
    }
    if (
      hasPartner &&
      (partnerData.desired_retirement_age === null || partnerData.desired_retirement_age === 0)
    ) {
      commit('UPDATE_PARTNER_ERROR', {
        value: 'Please enter a valid age',
        field: 'desired_retirement_age'
      })
      valid = false
      if (!errorLocations.includes(0)) errorLocations.push(0)
    }

    if (userData.one_off_age > 0 && userData.one_off_age < userData.age) {
      if (userData.age >= 75) {
        dispatch(
          'RetirementCalculator/updateUserDataKeyPair',
          {
            value: null,
            field: 'one_off_contribution'
          },
          { root: true }
        )
        dispatch(
          'RetirementCalculator/updateUserDataKeyPair',
          {
            value: null,
            field: 'one_off_age'
          },
          { root: true }
        )
      } else {
        commit('UPDATE_USER_ERROR', {
          value: 'Please enter an age older than your current age',
          field: 'one_off_age'
        })
        valid = false
        if (!errorLocations.includes(2)) errorLocations.push(2)
      }
    }
    if (hasPartner && partnerData.one_off_age > 0 && partnerData.one_off_age < partnerData.age) {
      if (partnerData.age >= 75) {
        dispatch(
          'RetirementCalculator/updatePartnerDataKeyPair',
          {
            value: null,
            field: 'one_off_contribution'
          },
          { root: true }
        )
        dispatch(
          'RetirementCalculator/updatePartnerDataKeyPair',
          {
            value: null,
            field: 'one_off_age'
          },
          { root: true }
        )
      } else {
        commit('UPDATE_PARTNER_ERROR', {
          value: 'Please enter an age older than your current age',
          field: 'one_off_age'
        })
        valid = false
        if (!errorLocations.includes(2)) errorLocations.push(2)
      }
    }

    /**
     * Commented out because apparently we can retire at 18 when we're 92
     * Leaving here in case it becomes useful in near future 21/12/22
     */
    // if (userData.desired_retirement_age < userData.age) {
    //   commit('UPDATE_USER_ERROR', { value: 'Retirement age must not be less than current age', field: 'desired_retirement_age' })
    //   valid = false
    // }
    // if (rootGetters.'RetirementCalculator/enablePartnerForm' && partnerData.desired_retirement_age < partnerData.age) {]
    //   commit('UPDATE_PARTNER_ERROR', { value: 'Retirement age must not be less than current age', field: 'desired_retirement_age' })
    //   valid = false
    // }

    if (userData.before_tax_contribution > 0 && userData.desired_retirement_age <= userData.age) {
      commit('UPDATE_USER_ERROR', {
        value: 'Contributions are accepted if not retired',
        field: 'before_tax_contribution'
      })
      valid = false
      if (!errorLocations.includes(2)) errorLocations.push(2)
    }

    if (
      hasPartner &&
      partnerData.before_tax_contribution > 0 &&
      partnerData.desired_retirement_age <= partnerData.age
    ) {
      commit('UPDATE_PARTNER_ERROR', {
        value: 'Contributions are accepted if not retired',
        field: 'desired_retirement_age'
      })
      valid = false
      if (!errorLocations.includes(2)) errorLocations.push(2)
    }

    if (userData.after_tax_contribution > 0 && userData.age >= 75) {
      dispatch(
        'RetirementCalculator/updateUserDataKeyPair',
        {
          value: null,
          field: 'after_tax_contribution'
        },
        { root: true }
      )
    }
    if (partnerData.after_tax_contribution > 0 && partnerData.age >= 75) {
      dispatch(
        'RetirementCalculator/updatePartnerDataKeyPair',
        {
          value: null,
          field: 'after_tax_contribution'
        },
        { root: true }
      )
    }

    if (userData.one_off_contribution > 0 && !userData.one_off_age) {
      // If either but not both conditions are met. Very sad js doesn't have xor
      if (userData.age >= 75) {
        dispatch(
          'RetirementCalculator/updateUserDataKeyPair',
          {
            value: null,
            field: 'one_off_contribution'
          },
          { root: true }
        )
        dispatch(
          'RetirementCalculator/updateUserDataKeyPair',
          {
            value: null,
            field: 'one_off_age'
          },
          { root: true }
        )
      } else {
        commit('UPDATE_USER_ERROR', {
          value: 'Please enter an age and an amount',
          field: 'one_off_age'
        })
        valid = false
        if (!errorLocations.includes(2)) errorLocations.push(2)
      }
    }
    if (partnerData.one_off_contribution > 0 && !partnerData.one_off_age) {
      // If either but not both conditions are met. Very sad js doesn't have xor
      if (partnerData.age >= 75) {
        dispatch(
          'RetirementCalculator/updatePartnerDataKeyPair',
          {
            value: null,
            field: 'one_off_contribution'
          },
          { root: true }
        )
        dispatch(
          'RetirementCalculator/updatePartnerDataKeyPair',
          {
            value: null,
            field: 'one_off_age'
          },
          { root: true }
        )
      } else {
        commit('UPDATE_PARTNER_ERROR', {
          value: 'Please enter an age and an amount',
          field: 'one_off_age'
        })
        valid = false
        if (!errorLocations.includes(2)) errorLocations.push(2)
      }
    }

    const userPartTime = userData.part_time
    const userAgeValueAbsent =
      userPartTime.part_time_starting_age === null || userPartTime.part_time_starting_age === 0
    const userDurationValueAbsent =
      userPartTime.part_time_duration === null || userPartTime.part_time_duration === 0
    const userPartTimeBothNull = userAgeValueAbsent && userDurationValueAbsent
    if (
      userPartTime.part_time_starting_age > 0 &&
      userPartTime.part_time_starting_age < userData.age
    ) {
      commit('UPDATE_USER_ERROR', {
        value: `Min: ${userData.age}`,
        field: 'part_time_starting_age'
      })
      valid = false
      if (!errorLocations.includes(3)) errorLocations.push(3)
    }
    if (!userPartTimeBothNull) {
      if (userAgeValueAbsent) {
        commit('UPDATE_USER_ERROR', {
          value: 'Please enter a starting age',
          field: 'part_time_starting_age'
        })
        valid = false
        if (!errorLocations.includes(3)) errorLocations.push(3)
      }
      if (userDurationValueAbsent) {
        commit('UPDATE_USER_ERROR', {
          value: 'Please enter the duration',
          field: 'part_time_duration'
        })
        valid = false
        if (!errorLocations.includes(3)) errorLocations.push(3)
      }
      if (
        (userPartTime.part_time_starting_age > 0 || userPartTime.part_time_duration > 0) &&
        userPartTime.part_time_days === null
      ) {
        commit('UPDATE_USER_ERROR', {
          value: "Enter days you'll work in a week",
          field: 'part_time_days'
        })
        valid = false
        if (!errorLocations.includes(3)) errorLocations.push(3)
      }
    }

    const partPartTime = partnerData.part_time
    const partnerAgeValueAbsent =
      partPartTime.part_time_starting_age === null || partPartTime.part_time_starting_age === 0
    const partnerDurationAbsent =
      partPartTime.part_time_duration === null || partPartTime.part_time_duration === 0
    const partPartTimeBothNull = partnerAgeValueAbsent && partnerDurationAbsent
    if (
      partPartTime.part_time_starting_age > 0 &&
      partPartTime.part_time_starting_age < partnerData.age
    ) {
      commit('UPDATE_PARTNER_ERROR', {
        value: `Min: ${partnerData.age}`,
        field: 'part_time_starting_age'
      })
      valid = false
      if (!errorLocations.includes(3)) errorLocations.push(3)
    }
    if (!partPartTimeBothNull) {
      if (partnerAgeValueAbsent) {
        commit('UPDATE_PARTNER_ERROR', {
          value: 'Please enter a starting age',
          field: 'part_time_starting_age'
        })
        valid = false
        if (!errorLocations.includes(3)) errorLocations.push(3)
      }
      if (partnerDurationAbsent) {
        commit('UPDATE_PARTNER_ERROR', {
          value: 'Please enter the duration',
          field: 'part_time_duration'
        })
        valid = false
        if (!errorLocations.includes(3)) errorLocations.push(3)
      }
      if (
        (partPartTime.part_time_starting_age > 0 || partPartTime.part_time_duration > 0) &&
        partPartTime.part_time_days === null
      ) {
        commit('UPDATE_PARTNER_ERROR', {
          value: "Enter days you'll work in a week",
          field: 'part_time_days'
        })
        valid = false
        if (!errorLocations.includes(3)) errorLocations.push(3)
      }
    }

    rootGetters['RetirementCalculator/incomeAssetArray'].forEach((inAss, index) => {
      // Assets don't need income associated
      const bothAreNull =
        inAss.type === null && (inAss.current_value === null || inAss.current_value <= 0)
      if (!bothAreNull && inAss.type === null) {
        commit('UPDATE_ASSET_ERROR', { value: 'Please enter the type', field: 'type', index })
        valid = false
        if (!errorLocations.includes(1)) errorLocations.push(1)
      }
      if (!bothAreNull && (inAss.current_value === null || inAss.current_value <= 0)) {
        commit('UPDATE_ASSET_ERROR', {
          value: 'Please enter the current value',
          field: 'current_value',
          index
        })
        valid = false
        if (!errorLocations.includes(1)) errorLocations.push(1)
      }
    })

    const careerBreakOutput = await dispatch('validateCareerBreaks', {
      careerBreaks: userData.career_breaks,
      errorLocations: errorLocations,
      owner: 'userErrors',
      valid
    })
    errorLocations = careerBreakOutput.errorLocations
    valid = careerBreakOutput.valid

    if (hasPartner) {
      const careerBreakOutput = await dispatch('validateCareerBreaks', {
        careerBreaks: partnerData.career_breaks,
        errorLocations: errorLocations,
        owner: 'partnerErrors',
        valid
      })
      errorLocations = careerBreakOutput.errorLocations
      valid = careerBreakOutput.valid
    }

    if (!valid) commit('SET_ERROR_LOCATIONS', errorLocations)
    return valid
  },

  validateCareerBreaks({ commit }, payload) {
    const output = { errorLocations: payload.errorLocations, valid: payload.valid }
    payload.careerBreaks.forEach((cBreak, index) => {
      const bothNull = cBreak.age === null && cBreak.duration === null
      if (!bothNull && cBreak.age === null) {
        commit('UPDATE_CAREER_BREAK_ERROR', {
          value: 'Please enter the age',
          field: 'age',
          index,
          owner: payload.owner
        })
        output.valid = false
        if (!payload.errorLocations.includes(3)) output.errorLocations.push(3)
      }
      if (!bothNull && cBreak.duration === null) {
        commit('UPDATE_CAREER_BREAK_ERROR', {
          value: 'Please enter the duration',
          field: 'duration',
          index,
          owner: payload.owner
        })
        output.valid = false
        if (!payload.errorLocations.includes(3)) output.errorLocations.push(3)
      }
    })
    return output
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
