<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8 4.8H7.2V1.2C7.2 0.88174 7.07357 0.576516 6.84853 0.351472C6.62348 0.126428 6.31826 0 6 0C5.68174 0 5.37652 0.126428 5.15147 0.351472C4.92643 0.576516 4.8 0.88174 4.8 1.2V4.8H1.2C0.88174 4.8 0.576516 4.92643 0.351472 5.15147C0.126428 5.37652 0 5.68174 0 6C0 6.31826 0.126428 6.62348 0.351472 6.84853C0.576516 7.07357 0.88174 7.2 1.2 7.2H4.8V10.8C4.8 11.1183 4.92643 11.4235 5.15147 11.6485C5.37652 11.8736 5.68174 12 6 12C6.31826 12 6.62348 11.8736 6.84853 11.6485C7.07357 11.4235 7.2 11.1183 7.2 10.8V7.2H10.8C11.1183 7.2 11.4235 7.07357 11.6485 6.84853C11.8736 6.62348 12 6.31826 12 6C12 5.68174 11.8736 5.37652 11.6485 5.15147C11.4235 4.92643 11.1183 4.8 10.8 4.8Z"
      :fill="fill"
      :class="`fill-${fill}`" />
  </svg>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    fill?: string
  }>(),
  {
    fill: '#888888',
  },
)
</script>
