import convertToNumber from '@/composables/convertToNumber'

export default (value: string | number): string => {
  const valueAsNumber: number = convertToNumber(value)
  if (isNaN(valueAsNumber)) return ''

  return valueAsNumber.toLocaleString('en-AU', {
    style: 'percent',
    maximumFractionDigits: 4
  })
}
