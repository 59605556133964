<template>
  <!--  todo :: icons -->
  <div class="flex flex-wrap">
    <div
      class="flex h-20 w-24 justify-around m-2 border opacity-50 cursor-pointer rounded lg:hover:border-otivo-blue"
      :class="{ selected: selected === 5 }"
      @click="$emit('click', 5)">
      <div class="mx-3 self-center text-center">1 year or more</div>
    </div>
    <div
      class="flex h-20 w-24 justify-around m-2 border opacity-50 cursor-pointer rounded lg:hover:border-otivo-blue"
      :class="{ selected: selected === 4 }"
      @click="$emit('click', 4)">
      <div class="mx-3 self-center text-center">At least 6 months</div>
    </div>
    <div
      class="flex h-20 w-24 justify-around m-2 border opacity-50 cursor-pointer rounded lg:hover:border-otivo-blue"
      :class="{ selected: selected === 3 }"
      @click="$emit('click', 3)">
      <div class="mx-3 self-center text-center">At least 3 months</div>
    </div>
    <div
      class="flex h-20 w-24 justify-around m-2 border opacity-50 cursor-pointer rounded lg:hover:border-otivo-blue"
      :class="{ selected: selected === 2 }"
      @click="$emit('click', 2)">
      <div class="mx-3 self-center text-center">Less than 3 months</div>
    </div>
    <div
      class="flex h-20 w-24 justify-around m-2 border opacity-50 cursor-pointer rounded lg:hover:border-otivo-blue"
      :class="{ selected: selected === 1 }"
      @click="$emit('click', 1)">
      <div class="mx-3 self-center text-center">No time at all</div>
    </div>

    <input-box-buttons
      @click="setCanPayBills"
      :buttons="[
        {
          name: '1',
          value: '1',
        },
        {
          name: '2',
          value: '2',
        },
        {
          name: '3',
          value: '3',
        },
        {
          name: '4',
          value: '4',
        },
        {
          name: '5',
          value: '5',
        },
      ]"
      :selected="canPayBills"
      name="living-situation"
      label="" />
    <div class="flex justify-between paragraph-2">
      <div>Strongly disagree</div>
      <div>Strongly agree</div>
    </div>
  </div>
</template>

<script>
import InputBoxButtons from '@/components/BasicInputs/InputBoxButtons.vue'
export default {
  name: 'CoverExpensesAnswer',
  components: { InputBoxButtons },
  props: {
    selected: {
      type: [String, Number],
      required: true,
    },
  },
}
</script>

<style scoped></style>
