<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00368 2.37891C7.74858 2.37891 7.49599 2.4292 7.26034 2.52691C7.02469 2.62462 6.81061 2.76784 6.63035 2.94836C6.45008 3.12888 6.30717 3.34318 6.20977 3.57898C6.11238 3.81479 6.06242 4.06749 6.06275 4.32262H7.51845C7.5173 4.25889 7.52871 4.19556 7.55203 4.13624C7.57536 4.07692 7.61013 4.02277 7.65438 3.9769C7.69862 3.93102 7.75147 3.89431 7.8099 3.86885C7.86833 3.8434 7.93121 3.82971 7.99493 3.82856C8.05865 3.82741 8.12197 3.83883 8.18129 3.86215C8.2406 3.88548 8.29473 3.92026 8.3406 3.96451C8.38648 4.00876 8.42318 4.06162 8.44863 4.12006C8.47408 4.1785 8.48777 4.24138 8.48892 4.30511C8.4539 4.50774 7.98867 5.07309 7.53346 5.46584L7.28333 5.68347V6.8492H8.73904V6.34889C9.23928 5.8886 9.95212 5.0806 9.95212 4.32512C9.95146 3.80872 9.74589 3.3137 9.38055 2.94879C9.01522 2.58387 8.52001 2.37891 8.00368 2.37891Z"
      :class="`fill-${fill2}`" />
    <path
      d="M8.00376 8.66545C8.4458 8.66545 8.80414 8.30706 8.80414 7.86495C8.80414 7.42285 8.4458 7.06445 8.00376 7.06445C7.56171 7.06445 7.20337 7.42285 7.20337 7.86495C7.20337 8.30706 7.56171 8.66545 8.00376 8.66545Z"
      :class="`fill-${fill2}`" />
    <path
      d="M15.9476 14.5791L14.2568 9.74859V1.05316C14.2561 0.774046 14.145 0.506555 13.9476 0.309193C13.7503 0.11183 13.4828 0.000660623 13.2038 0H2.80124C2.5226 0.00131999 2.25579 0.112781 2.059 0.310072C1.8622 0.507363 1.75139 0.774478 1.75073 1.05316V9.7586L0.0599174 14.5791C0.00380313 14.7395 -0.0132317 14.911 0.0102275 15.0793C0.0336867 15.2476 0.0969629 15.4079 0.1948 15.5469C0.292637 15.6858 0.422211 15.7994 0.572759 15.8782C0.723307 15.957 0.890483 15.9988 1.0604 16H14.9396C15.1095 15.9988 15.2767 15.957 15.4272 15.8782C15.5778 15.7994 15.7074 15.6858 15.8052 15.5469C15.903 15.4079 15.9663 15.2476 15.9898 15.0793C16.0132 14.911 15.9962 14.7395 15.9401 14.5791H15.9476ZM12.1533 2.09631V8.90306H3.85425V2.09631H12.1533ZM2.57113 13.8537L3.55911 11.0294H12.4484L13.4489 13.8537H2.57113Z"
      :class="`fill-${fill1}`" />
  </svg>
</template>
<script setup lang="ts">
interface Props {
  fill1: string
  fill2: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red',
})
</script>

<style scoped></style>
