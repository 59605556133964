<template>
  <div class="max-w-[1100px] mx-auto py-[40px] px-[20px] md:px-[40px]">
    <div class="flex flex-wrap gap-[20px] w-fit">
      <BaseTabButton
        v-for="(option, index) in tabs"
        :key="index"
        :is-active="option.value === activeTab.value"
        :option="option"
        @change="
          (val) => {
            activeTab = val
            router.push({ name: val.value })
          }
        " />
    </div>
    <router-view></router-view>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import BaseTabButton from '@/components/Inputs/BaseTabButton.vue'
import { ref } from 'vue'

const router = useRouter()

const activeTab = ref({
  label: '',
  value: ''
})

const tabs = [
  {
    label: 'Assets',
    value: 'sampleAssets'
  },
  {
    label: 'Colours',
    value: 'sampleColours'
  },
  {
    label: 'Components',
    value: 'sampleComponents'
  },
  {
    label: 'Typography',
    value: 'sampleTypography'
  }
]
</script>

<style scoped></style>
