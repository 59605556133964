<template>
  <div class="relative min-h-[100vh] flex flex-col">
    <CallcentrePortalNavBar />
    <div class="flex-grow flex justify-center relative bg-blue-6">
      <div class="md:flex w-100%">
        <SideMenuNavigation v-if="showMenu" class="min-w-[200px] max-w-[200px] pl-5 py-5 lg:pl-10 lg:py-10" />
        <div class="w-100% flex justify-center">
          <RouterView></RouterView>
        </div>
      </div>
    </div>
    <div
      :class="userRole === 'member' ? 'bg-midnight' : 'bg-grey-1'"
      class="text-mf1 py-[40px] md:text-f1 flex flex-col justify-center items-center space-y-[80px]">
      <CFSFooterNav />

      <div :class="userRole === 'member' ? 'text-blue-2' : 'text-white'" class="text-center">
        {{ providerDetails.provider }} ABN {{ providerDetails.abn }} AFSL
        {{
          providerDetails.afsl === '485665'
            ? ' + Australian Credit Licence ' + providerDetails.afsl
            : providerDetails.afsl
        }}
        <div>
          <!--            Email: <a href="mailto:otivohelp@otivo.com">otivohelp@otivo.com</a>-->
          Suite 2.01, 50 York St, Sydney, NSW 2000
        </div>
      </div>
    </div>
    <TimeoutModal />
  </div>
</template>

<script setup lang="ts">
import CallcentrePortalNavBar from '@/views/Clients/CFS/CallcentrePortalNavBar.vue'
import TimeoutModal from '@/components/AppModals/TimeoutModal.vue'
import CFSFooterNav from '@/views/Clients/CFS/CFSFooterNav.vue'
import { WhitelabelDataType } from '@/types/WhiteLabelTypes.ts'
import SideMenuNavigation from '@/components/NavBar/SideMenuNavigation.vue'
import { userRole } from '@/lib/AuthenticatorPlugin.ts'
import { computed, watch } from 'vue'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import { useUserStore } from '@/store/pinia/UserStore.ts'
import { useRouter } from 'vue-router'
import { useNavBarStore } from '@/store/pinia/NavBarStore.ts'

const router = useRouter()
const navBarStore = useNavBarStore()
const sessionStore = useSessionStore()
const providerDetails: WhitelabelDataType = sessionStore.getWhitelabelData
const userStore = useUserStore()
const user = computed(() => userStore.getUser)

const showMenu = computed(() => {
  if (router.currentRoute.value.fullPath.includes('/reporting')) {
    return true
  }
  return (
    user?.value?.auth0id && !router.currentRoute.value.fullPath.includes('/admin-portal/')
    // Specifically excluding this route to handle back button and loading state
  )
})

watch(
  () => router.currentRoute.value.fullPath,
  (newPath) => {
    if (newPath.includes('reporting')) {
      navBarStore.setRouterComponents(['reporting'])
    } else {
      navBarStore.setRouterComponents([])
    }
  },
  { immediate: true }
)
</script>

<style scoped></style>
