<template>
  <div class="rounded transition border-inherit border-b-1px border-grey-4">
    <div
      class="flex flex-grow justify-between cursor-pointer my-[20px] h5 font-bold"
      :class="titleClasses"
      @click="open = !open">
      <slot name="heading" />
      <ArrowWide
        class="self-center mr-4 transition duration-300 ease-in-out transform"
        :class="open ? 'rotate-0' : 'rotate-180'" />
    </div>
    <div
      class="transition duration-100 ease-in-out origin-top"
      :class="open ? 'scale-y-100' : 'scale-y-0 opacity-0 h-1px'">
      <slot name="body" />

      <div class="flex">
        <div class="flex-1 max-w-70% text-left">
          <slot name="body-footer-left" />
        </div>

        <div class="md:flex-1 text-right">
          <slot name="body-footer-right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import ArrowWide from '@/components/icons/ArrowWide.vue'

const props = defineProps({
  defaultOpen: Boolean,
  titleClasses: {
    type: String,
    default: '',
  },
})

const open = ref(props.defaultOpen)
</script>

<style scoped></style>
