<template>
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 8.24438C16 12.6644 12.42 16.2444 8 16.2444C3.58 16.2444 0 12.6644 0 8.24438C0 3.82438 3.58 0.244385 8 0.244385C12.42 0.244385 16 3.82438 16 8.24438Z"
      :class="`fill-${fill1}`" />
    <path
      d="M7.01023 10.1344C6.85023 10.1344 6.77023 10.0544 6.77023 9.89444V9.51444C6.77023 9.04444 6.89023 8.67444 7.12023 8.39444C7.36023 8.10444 7.71023 7.80444 8.16023 7.48444C8.52023 7.23444 8.79024 7.00444 8.97023 6.81444C9.15023 6.62444 9.24023 6.38444 9.24023 6.10444C9.24023 5.80444 9.13023 5.56444 8.90023 5.39444C8.68023 5.20444 8.38024 5.11444 8.02024 5.11444C7.63024 5.11444 7.31023 5.21444 7.07023 5.40444C6.84023 5.60444 6.72023 5.85444 6.72023 6.16444V6.31444C6.72023 6.46444 6.64023 6.53444 6.48023 6.53444L4.98023 6.47444C4.91023 6.47444 4.86023 6.46444 4.81023 6.43444C4.76023 6.39444 4.74023 6.35444 4.74023 6.30444V6.15444C4.74023 5.59444 4.88023 5.10444 5.16023 4.67444C5.44023 4.23444 5.83023 3.90444 6.32023 3.66444C6.82023 3.42444 7.40023 3.30444 8.06024 3.30444C8.72024 3.30444 9.25023 3.42444 9.73023 3.65444C10.2202 3.87444 10.5902 4.19444 10.8502 4.60444C11.1102 5.01444 11.2402 5.49444 11.2402 6.03444C11.2402 6.44444 11.1702 6.80444 11.0202 7.09444C10.8802 7.38444 10.7102 7.62444 10.5002 7.80444C10.2902 7.99444 10.0302 8.19444 9.72023 8.42444C9.38023 8.65444 9.14023 8.85444 8.98023 9.02444C8.82023 9.19444 8.74023 9.39444 8.74023 9.64444V9.89444C8.74023 10.0544 8.66023 10.1344 8.50023 10.1344H7.02023H7.01023ZM7.84023 13.2744C7.51023 13.2744 7.23023 13.1644 7.00023 12.9544C6.77023 12.7344 6.65023 12.4644 6.65023 12.1544C6.65023 11.8444 6.77023 11.5844 7.00023 11.3744C7.24023 11.1544 7.53023 11.0344 7.85023 11.0344C8.17023 11.0344 8.44023 11.1444 8.68023 11.3744C8.92023 11.5844 9.04023 11.8544 9.04023 12.1544C9.04023 12.4544 8.92023 12.7244 8.68023 12.9544C8.45023 13.1644 8.17023 13.2744 7.84023 13.2744Z"
      :class="`fill-${fill2}`" />
  </svg>
</template>
<script setup lang="ts">
interface Props {
  fill1: string
  fill2: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'white',
})
</script>

<style scoped></style>
