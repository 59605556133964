<template>
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Function" clip-path="url(#clip0_394_2708)">
      <path
        id="Vector"
        d="M6 0.144531C4.81331 0.144531 3.65328 0.496425 2.66658 1.15571C1.67989 1.815 0.910851 2.75207 0.456725 3.84843C0.00259969 4.94479 -0.11622 6.15119 0.115291 7.31507C0.346802 8.47896 0.918247 9.54805 1.75736 10.3872C2.59648 11.2263 3.66557 11.7977 4.82946 12.0292C5.99335 12.2608 7.19974 12.1419 8.2961 11.6878C9.39246 11.2337 10.3295 10.4646 10.9888 9.47795C11.6481 8.49125 12 7.33122 12 6.14453C12 4.55323 11.3679 3.02711 10.2426 1.90189C9.11742 0.776672 7.5913 0.144531 6 0.144531Z"
        :class="`fill-${fill1}`" />
      <path
        id="Vector_2"
        d="M9 5.39453H6.75V3.14453C6.75 2.94562 6.67098 2.75485 6.53033 2.6142C6.38968 2.47355 6.19891 2.39453 6 2.39453C5.80109 2.39453 5.61032 2.47355 5.46967 2.6142C5.32902 2.75485 5.25 2.94562 5.25 3.14453V5.39453H3C2.80109 5.39453 2.61032 5.47355 2.46967 5.6142C2.32902 5.75485 2.25 5.94562 2.25 6.14453C2.25 6.34344 2.32902 6.53421 2.46967 6.67486C2.61032 6.81551 2.80109 6.89453 3 6.89453H5.25V9.14453C5.25 9.34344 5.32902 9.53421 5.46967 9.67486C5.61032 9.81551 5.80109 9.89453 6 9.89453C6.19891 9.89453 6.38968 9.81551 6.53033 9.67486C6.67098 9.53421 6.75 9.34344 6.75 9.14453V6.89453H9C9.19891 6.89453 9.38968 6.81551 9.53033 6.67486C9.67098 6.53421 9.75 6.34344 9.75 6.14453C9.75 5.94562 9.67098 5.75485 9.53033 5.6142C9.38968 5.47355 9.19891 5.39453 9 5.39453Z"
        :class="`fill-${fill2}`" />
    </g>
    <defs>
      <clipPath id="clip0_394_2708">
        <rect width="12" height="12" fill="white" transform="translate(0 0.144531)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts">
interface Props {
  fill1?: string
  fill2?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'white',
  fill2: 'otivo-blue'
})
</script>

<style scoped></style>
