<template>
  <div id="flex-financial-fitness" :class="backgroundColour">
    <div
      class="lg:flex mx-8 lg:w-[840px] lg:m-auto justify-center items-center text-center lg:text-left gap-10">
      <div>
        <img :src="FitnessLady" alt="FitnessLady" class="min-w-[340px] lg:px-0 m-auto lg:m-0" />
      </div>
      <div class="mt-4 lg:mt-0">
        <h3 class="text-[22px] font-[700] leading-[120%]">
          {{ title }}
        </h3>
        <h5 class="text-[22px] font-[300] leading-[120%] lg:mt-5">
          {{ text }}
        </h5>
        <OtivoButton
          @click="openFitnessTest"
          colour="red"
          class="mt-5 min-w-full sm:min-w-72 mx-auto lg:mx-0">
          Take our FREE fitness test
        </OtivoButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import FitnessLady from '@/components/SVGS/FitnessLady.svg?url'
import { useRouter } from 'vue-router'
import OtivoButton from '@/components/OtivoButton.vue'

const router = useRouter()

interface Props {
  backgroundColour?: string
  title?: string
  text?: string
}
const openFitnessTest = () => {
  router.push({ name: 'Financial fitness' })
}

withDefaults(defineProps<Props>(), {
  backgroundColour: 'blue-5',
  title: 'Looking to flex your finances?',
  text: 'Take our FREE financial fitness test to see how you shape up.',
})
</script>
