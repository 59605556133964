<template>
  <div class="flex flex-col items-center justify-center w-100% max-w-[570px]">
    <div id="capture-investment-options" class="space-y-5 flex flex-col">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-3 items-center max-w-[570px]">
        <h6 class="md:col-span-2">Choose your current investment options</h6>
        <h6 class="hidden md:flex">Allocation</h6>
      </div>
      <div
        :key="idx"
        v-for="(item, idx) in allocation"
        class="grid grid-cols-1 md:grid-cols-3 gap-3 items-center">
        <div class="flex flex-col md:grid md:col-span-2">
          <Dropdown
            :items="investmentOptions"
            placeholder="Select your investment option"
            type="investmentOptions"
            @selected="(investment) => selectInvestmentOption(investment, idx)"
            :existing-item="{
              label: item.chant_west_investment_name ?? '',
              value: item.chant_west_investment_id,
            }" />
        </div>
        <div class="flex flex-col md:flex-row justify-center items-center gap-3">
          <h6 class="flex md:hidden">Allocation</h6>
          <PercentageInput
            :value="item.percent_allocation"
            @update:value="(val: number) => (item.percent_allocation = val)" />
          <div
            class="flex justify-center items-center text-red-1 rounded cursor-pointer gap-3"
            @click="allocation.splice(Number(idx), 1)">
            <SubtractionIcon class="text-3xl" />
            <span class="font-bold">Remove</span>
          </div>
        </div>
      </div>
      <div
        class="mt-[20px] flex justify-center text-otivo-blue hover:text-blue-1 gap-3 rounded items-center cursor-pointer"
        @click="addNewAllocation">
        <AdditionIcon data-test="addOption" class="text-3xl" /><span class="font-bold"
          >Add more</span
        >
      </div>
    </div>
    <div class="otivo-error-message">{{ investmentOptionErrorMessage }}</div>
    <OtivoButton class="w-100% max-w-[375px] mt-[60px]" @click="next" :loading="loading">
      Next
    </OtivoButton>
  </div>
</template>

<script setup lang="ts">
import BaseInput from '@/components/Inputs/BaseInput.vue'
import Dropdown from '@/components/BasicInputs/Dropdown.vue'
import { computed, onBeforeMount, reactive, ref } from 'vue'
import OtivoButton from '@/components/OtivoButton.vue'
import { SuperFundAllocationNameType } from '@/types/CallcentrePortalTypes.ts'
import { useAccountCreationStore } from '@/store/pinia/AccountCreationStore.ts'
import getSuperFundInvestmentOptions from '@/composables/superHelpers/getSuperFundInvestmentOptions.ts'
import Allocation from '@/types/api/super/Allocation.ts'
import { useSuperStore } from '@/store/pinia/SuperStore.ts'
import setupUser from '@/composables/users/setupUser.ts'
import SubtractionIcon from '@/components/icons/SubtractionIcon.vue'
import AdditionIcon from '@/components/icons/AdditionIcon.vue'
import PercentageInput from '@/components/BasicInputs/PercentageInput.vue'

onBeforeMount(() => {
  fetchInvestmentOptions()
})

const emit = defineEmits<{
  (e: 'next', data: string): void
}>()

const loading = ref(false)
const accountCreationStore = useAccountCreationStore()
const superStore = useSuperStore()
const superProduct = accountCreationStore.getSuperProduct
const investmentOptions = ref<SuperFundAllocationNameType[]>([])
const fetchInvestmentOptions = async () => {
  try {
    investmentOptions.value = await getSuperFundInvestmentOptions(
      superProduct?.chant_west_product_id ?? null,
    )
  } catch (error) {
    console.error(error)
  }
}

const allocation = reactive<Allocation>(accountCreationStore.getAllocation)
const investmentOptionErrorMessage = ref('')
const totalAllocationPercentage = computed(() => {
  return allocation.reduce((acc, curr) => acc + curr.percent_allocation, 0) * 100
})

const addNewAllocation = () => {
  allocation.push({
    chant_west_investment_id: 0,
    chant_west_investment_name: '',
    percent_allocation: 0,
    is_term_deposit: false,
  })
}

const selectInvestmentOption = (
  valueObj: { label: string; value: string | number; isTermDeposit: boolean },
  index: number,
) => {
  allocation[index].chant_west_investment_id = valueObj.value as number
  allocation[index].chant_west_investment_name = valueObj.label
  allocation[index].is_term_deposit = valueObj.isTermDeposit
}

const next = async () => {
  if (allocation[0].chant_west_investment_name === '')
    investmentOptionErrorMessage.value = 'Please select an investment option'
  else if (allocation[0].percent_allocation === 0)
    investmentOptionErrorMessage.value = 'Please enter an allocation'
  else if (totalAllocationPercentage.value !== 100)
    investmentOptionErrorMessage.value = 'Total allocation must be 100%'
  else {
    investmentOptionErrorMessage.value = ''
    accountCreationStore.setAllocation(allocation)
    loading.value = true
    await setupUser()
    const activeSuperObject = computed(() => superStore.getActiveSuperObject)
    const success = await accountCreationStore.setupUserAndSuper(activeSuperObject.value.id)
    loading.value = false
    if (success) emit('next', 'super details captured')
  }
}
</script>

<style scoped></style>
