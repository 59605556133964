<template>
  <!--  todo :: icons -->
  <div class="flex">
    <div
      class="flex h-20 w-24 justify-around m-2 border opacity-50 cursor-pointer rounded lg:hover:border-otivo-blue"
      :class="{ selected: selected === 'yes' }"
      @click="$emit('click', 'yes')">
      <div class="mx-3 self-center text-center">Yes</div>
    </div>
    <div
      class="flex h-20 w-24 justify-around m-2 border opacity-50 cursor-pointer rounded lg:hover:border-otivo-blue"
      :class="{ selected: selected === 'no' }"
      @click="$emit('click', 'no')">
      <div class="mx-3 self-center text-center">No</div>
    </div>
    <div
      class="flex h-20 w-24 justify-around m-2 border opacity-50 cursor-pointer rounded lg:hover:border-otivo-blue"
      :class="{ selected: selected === 'self_insured' }"
      @click="$emit('click', 'self_insured')">
      <div class="mx-3 self-center text-center">Self Insured</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HealthInsuranceAnswer',
  props: {
    selected: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped></style>
