<template>
  <path
    d="M72.5 72.2H7.5C5.51088 72.2 3.60322 71.4098 2.1967 70.0032C0.790175 68.5967 0 66.6891 0 64.7V14.7C0 12.7108 0.790175 10.8032 2.1967 9.39665C3.60322 7.99013 5.51088 7.19995 7.5 7.19995H72.5C74.4891 7.19995 76.3968 7.99013 77.8033 9.39665C79.2098 10.8032 80 12.7108 80 14.7V64.7C80 66.6891 79.2098 68.5967 77.8033 70.0032C76.3968 71.4098 74.4891 72.2 72.5 72.2ZM10 62.2H70V17.2H10V62.2Z"
    :fill="fill" />
  <path d="M70 27.2H10V37.1999H70V27.2Z" :fill="fill" />
  <path d="M32.5 44.7H20V54.6999H32.5V44.7Z" :fill="fill" />
</template>

<script>
export default {
  name: 'CreditCardTile',
  props: {
    fill: { type: String, default: '#D4E7FE' },
    fill1: { type: String, default: '#D4E7FE' },
    fill2: { type: String, default: '#D4E7FE' }
  }
}
</script>

<style scoped></style>
