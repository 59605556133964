import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import CfsService from '@/services/CfsService.ts'
import getChantwestProductFromAccountNumber from '@/composables/superHelpers/getChantwestProductFromAccountNumber.ts'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import { userAuth0Id } from '@/lib/AuthenticatorPlugin.ts'
import { useSuperStore } from '@/store/pinia/SuperStore.ts'
import Allocation from '@/types/api/super/Allocation.ts'
import { AxiosError } from 'axios'

type UserSuperProduct = {
  chant_west_product_id: number
  chant_west_product_code: string
  fund_name: string
  account_number: string
}

export const useAccountCreationStore = defineStore('AccountCreationStore', () => {
  const uid = ref<string | undefined>() // member number | USI | whatever other kind of external user identifier
  const userAccountNumber = ref<string | undefined>()
  const superAccountNumber = ref<string | undefined>()
  const balance = ref(0)
  const allocation = ref<Allocation>([
    {
      chant_west_investment_id: 0,
      chant_west_investment_name: '',
      percent_allocation: 100,
      is_term_deposit: false,
    },
  ])
  const whitelabel = useSessionStore().getWhitelabelData
  const superProduct = ref<UserSuperProduct>()

  const setTermsAndConditionsAgreed = async (accountId: number) => {
    const now = new Date().getTime()
    //todo: change endpoint when available in hydra
    await CfsService.setConsentFlag({
      consented: true,
      expires_at: now + 31536000000, // + 1 year
      account_id: accountId,
    })
  }

  const renewTermsAndConditionsAgreed = async (
    accountId: number,
    consentFeeExpirationDate: string,
    accountDeactivated: boolean,
  ) => {
    const currentTimestamp = Date.now()
    const expirationTimestamp = parseInt(consentFeeExpirationDate)

    let newExpirationTimestamp: number

    if (expirationTimestamp < currentTimestamp || accountDeactivated) {
      // If already expired, set new expiration to now + 1 year
      newExpirationTimestamp = currentTimestamp + 365 * 24 * 60 * 60 * 1000
    } else {
      // If not expired, add 1 year to the current expiration date
      newExpirationTimestamp = expirationTimestamp + 365 * 24 * 60 * 60 * 1000
    }

    await CfsService.renewConsentFlag({
      consented: true,
      expires_at: newExpirationTimestamp.toString(),
      account_id: accountId,
    })
  }

  const setUserMetadataMemberId = async (memberId: string, auth0id: string) => {
    try {
      await CfsService.updateCFSClientMemberId({ member_id: uid.value }, auth0id)
    } catch (error) {
      console.log(error)
      if (error instanceof AxiosError) {
        throw new Error(error.response.data?.error)
      }
    }
  }

  const setUserAccountNumber = (accountNumber: string) => (userAccountNumber.value = accountNumber)
  const setUid = (id: string) => (uid.value = id)
  const setAccountNumber = (accountNumber: string) => {
    superAccountNumber.value = accountNumber
    superProduct.value = getChantwestProductFromAccountNumber(
      accountNumber,
      whitelabel.superfund_config.super_products,
    ) as UserSuperProduct
  }
  const setBalance = (amount: number) => (balance.value = amount)
  const setAllocation = (arr: Allocation) => (allocation.value = arr)

  const setupUserAndSuper = async (superObjectId: string) => {
    try {
      await useSuperStore().updateSuperObject(superObjectId, {
        auth0id: userAuth0Id.value,
        account_number: superAccountNumber.value as string,
        balance: balance.value,
        allocation: allocation.value,
        chant_west_product_id: superProduct.value?.chant_west_product_id,
        data_source: 'user',
      })
      return true
    } catch (e) {
      console.error('Error setting up user and super', e)
      return false
    }
  }

  return {
    setUserAccountNumber,
    userAccountNumber: computed(() => userAccountNumber.value),
    superAccountNumber: computed(() => superAccountNumber.value),
    setUid,
    uid: computed(() => uid.value),
    getSuperProduct: computed(() => superProduct.value),
    getSuperBalance: computed(() => balance.value),
    setAllocation,
    getAllocation: computed(() => allocation.value),
    setAccountNumber,
    setUserMetadataMemberId,
    setBalance,
    setTermsAndConditionsAgreed,
    renewTermsAndConditionsAgreed,
    setupUserAndSuper,
  }
})
