import { computed } from 'vue'
import InvestmentOption from '@/types/api/super/InvestmentOption.ts'
import { state } from '@/components/CFS/IntrafundInvestment/superInvestmentsState.ts'
import { useIntrafundAdviceStore } from '@/store/pinia/IntrafundAdviceStore.ts'
import { useSuperStore } from '@/store/pinia/SuperStore.ts'
import {
  ExclusionRules,
  useWhitelabelExclusionRules,
} from '@/composables/useWhitelabelExclusionRules.ts'

export const exclusionsHandlers = () => {
  const intrafundAdviceStore = useIntrafundAdviceStore()
  const superStore = useSuperStore()
  const { hasExclusionRule } = useWhitelabelExclusionRules()

  const applyExceptionRules = async () => {
    console.log('DEBUG: applyExceptionRules')
    if (allInCashOption.value && hasExclusionRule(ExclusionRules.ALL_IN_CASH_OR_TERM_DEPOSITS)) {
      state.isEligibleForRecommendations = false
      console.log('All in cash option')
      return
    }

    if (
      allInTermDepositOption.value &&
      hasExclusionRule(ExclusionRules.ALL_IN_CASH_OR_TERM_DEPOSITS)
    ) {
      state.isEligibleForRecommendations = false
      console.log('All in term deposit option')
      return
    }

    if (hasLifeStageFund.value && hasExclusionRule(ExclusionRules.INVESTED_IN_LIFE_STAGE_OPTION)) {
      state.isEligibleForRecommendations = false
      console.log('Has life stage fund')
      return
    }

    if (hasExclusionRule(ExclusionRules.SOME_IN_CASH_OR_TERM_DEPOSITS)) {
      await intrafundAdviceStore.fetchTermDepositConsent()
      if (needTermDepositConsent.value) {
        state.isEligibleForRecommendations = false
        console.log('Need term deposit consent')
        return
      }

      const termDepositConsentStatus = intrafundAdviceStore.termDepositConsentStatus

      if (termDepositConsentStatus.waiting && hasSomeInTermDepositOption.value) {
        state.isEligibleForRecommendations = false
        console.log('has some td and consent not given for term deposit option')
        return
      }

      if (termDepositConsentStatus.notGiven && hasSomeInTermDepositOption.value) {
        state.isEligibleForRecommendations = false
        console.log('Term deposit consent not given')
        return
      }
    }

    state.isEligibleForRecommendations = true
  }

  const allInCashOption = computed(() => {
    const cashInvestments =
      superStore.getActiveSuperObject.investments?.filter((val) => val.is_cash_option) || []
    const totalAllocation = cashInvestments.reduce(
      (total, investment) => total + Number(investment.percent_allocation),
      0,
    )

    return totalAllocation === 100
  })

  /**
   * Business Logic: Term Deposit
   */

  const allInTermDepositOption = computed(() => {
    const cashInvestments =
      superStore.getActiveSuperObject.investments?.filter((val) => val.is_term_deposit) || []
    const totalAllocation = cashInvestments.reduce(
      (total, investment) => total + Number(investment.percent_allocation),
      0,
    )

    return totalAllocation === 100
  })

  const hasSomeInTermDepositOption = computed(() => {
    const tdInvestments = superStore.getActiveSuperObject.investments?.filter(
      (val) => val.is_term_deposit,
    )
    const totalAllocation = tdInvestments?.reduce(
      (total, investment) => total + Number(investment.percent_allocation),
      0,
    )

    return totalAllocation > 0
  })

  const determineTermDepositConsent = computed(() => {
    if (!intrafundAdviceStore.termDepositConsent) return false

    const { has_term_deposit, consented, super_id, answered } =
      intrafundAdviceStore.termDepositConsent

    if (superStore.getActiveSuperObject.id === super_id) {
      if (!has_term_deposit) return false

      return has_term_deposit && !consented && !answered
    }

    return false
  })

  const needTermDepositConsent = computed(() => {
    return determineTermDepositConsent.value && !allInCashOption.value
  })

  /**
   * Business Logic: Life Stage Fund
   */

  const findLifeStageFund = (investments: InvestmentOption[]) => {
    if (!investments.length) {
      return undefined
    }

    return investments?.find(
      (investment) => investment.chant_west_investment_name?.toLowerCase().includes('lifestage'),
    )
  }

  const hasLifeStageFund = computed(() => {
    if (!superStore.getActiveSuperObject?.investments?.length) {
      return false
    }

    return findLifeStageFund(superStore.getActiveSuperObject.investments) !== undefined
  })

  const lifeStageSuperAllocation = computed(() => {
    if (!superStore.getActiveSuperObject?.investments.length) {
      return false
    }

    return findLifeStageFund(superStore.getActiveSuperObject.investments) ?? false
  })

  return {
    allInCashOption,
    allInTermDepositOption,
    hasSomeInTermDepositOption,
    determineTermDepositConsent,
    needTermDepositConsent,
    hasLifeStageFund,
    lifeStageSuperAllocation,
    hasExclusionRule,
    applyExceptionRules,
  }
}
