<template>
  <div id="awards" class="">
    <div class="max-w-[600px] m-auto 2xl:max-w-full">
      <div
        class="grid grid-cols-3 2xl:flex justify-center items-start 2xl:items-center flex-wrap gap-6 2xl:gap-20">
        <div :key="index" v-for="(award, index) in awards" class="flex flex-col items-center">
          <component
            :is="award.image.imageSVG"
            height="50"
            width="50"
            viewBox="0 0 50 50"
            :line-fill="color.svgLineFill || award.image.lineFill"
            :background-fill="color.svgImageBackgroundFill || award.image.backgroundFill" />
          <p
            class="w-[100px] md:w-full text-[12px] mt-3 text-center"
            :class="`text-${color.textColour || award.textColour}`">
            {{ award.title }}<br /><span class="font-bold">{{ award.boldTitle }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import FinderB2BInnovator2022 from '@/components/icons/Awards/FinderB2BInnovator2022.vue'
import IFATransformationOfTheYear2022 from '@/components/icons/Awards/IFATransformationOfTheYear2022.vue'
import IFAInnovatorOfTheYear2022 from '@/components/icons/Awards/IFAInnovatorOfTheYear2022.vue'
import FinniesWealthManagement2023 from '@/components/icons/Awards/FinniesWealthManagement2023.vue'
import FinniesOrganisationOfTheYear2024 from '@/components/icons/Awards/FinniesOrganisationOfTheYear2024.vue'
import IFADigitalAdviceStrategy2023 from '@/components/icons/Awards/IFADigitalAdviceStrategy2023.vue'

type Award = {
  image: {
    imageSVG: unknown
    lineFill?: string
    backgroundFill?: string
  }
  title: string
  boldTitle: string
  textColour?: string
}

type DefaultColor = {
  svgImageBackgroundFill?: string
  svgLineFill?: string
  textColour?: string
}

interface Props {
  awards: Array<Award>
  color?: DefaultColor
}

withDefaults(defineProps<Props>(), {
  awards: () => [
    {
      image: {
        imageSVG: FinniesOrganisationOfTheYear2024,
        lineFill: 'grey-1',
        backgroundFill: 'blue-5',
      },
      title: 'Finnies Organisation Of The Year 2024',
      boldTitle: 'Finalist 2024',
      textColour: 'grey-1',
    },
    {
      image: {
        imageSVG: IFADigitalAdviceStrategy2023,
        lineFill: 'grey-1',
        backgroundFill: 'blue-5',
      },
      title: 'Digital Advice Strategy Of The Year',
      boldTitle: 'Finalist 2023',
      textColour: 'grey-1',
    },
    {
      image: {
        imageSVG: FinniesWealthManagement2023,
        lineFill: 'grey-1',
        backgroundFill: 'blue-5',
      },
      title: 'Excellence in Wealth Management',
      boldTitle: 'Finalist 2023',
      textColour: 'grey-1',
    },
    {
      image: {
        imageSVG: IFAInnovatorOfTheYear2022,
        lineFill: 'grey-1',
        backgroundFill: 'blue-5',
      },
      title: 'Innovator of The Year',
      boldTitle: 'Finalist 2022',
      textColour: 'grey-1',
    },
    {
      image: {
        imageSVG: FinderB2BInnovator2022,
        lineFill: 'grey-1',
        backgroundFill: 'blue-5',
      },
      title: 'B2B Innovator Of The Year',
      boldTitle: 'Finalist 2022',
      textColour: 'grey-1',
    },
    {
      image: {
        imageSVG: IFATransformationOfTheYear2022,
        lineFill: 'grey-1',
        backgroundFill: 'blue-5',
      },
      title: 'Transformation Of The Year',
      boldTitle: 'Finalist 2022',
      textColour: 'grey-1',
    },
  ],
  color: () => ({
    svgImageBackgroundFill: '',
    svgLineFill: '',
    textColour: '',
  }),
})
</script>
