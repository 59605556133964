<template>
  <base-view class="manage-bg-size">
    <div class="min-h-70vh p-4 fix-width-m-auto">
      <div class="bg-white rounded p-6">
        <div class="font-medium text-22pt">Financial services guide</div>

        <div class="md:flex py-8 text-left">
          <div class="flex-1 m-2 shadow-md rounded p-4">
            <div class="text-18pt font-medium">Financial services guide</div>

            <div class="py-4 text-14px">Read before deciding to use our financial services</div>

            <div class="mt-2">
              <a
                class="font-medium w-48 block text-center link-button bg-otivo-blue text-white p-4 rounded"
                href="https://store.otivo.com/FSG/FSCGv1.pdf"
                target="_blank">
                Download
              </a>
            </div>
          </div>

          <div class="flex-1 m-2 shadow-md rounded p-4">
            <div class="text-18pt font-medium">Credit Guide</div>

            <div class="py-4 text-14px">Read before deciding to use our credit services</div>

            <div class="mt-2 w-full">
              <a
                class="font-medium w-48 block text-center link-button bg-otivo-blue text-white p-4 rounded"
                href="https://store.otivo.com/FSG/CG_Mozo_MMP_Otivo_v3.pdf"
                target="_blank">
                Download
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-view>
</template>

<script>
import BaseView from '@/views/BaseView.vue'
export default {
  name: 'Fsg',
  components: { BaseView },
  created() {
    return window.location.replace('https://store.otivo.com/FSG/FSCGv1.pdf')
  }
}
</script>

<style scoped></style>
