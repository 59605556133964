<template>
  <svg
    preserveAspectRatio="none"
    viewBox="0 0 1440 20"
    xmlns="http://www.w3.org/2000/svg"
    :class="[
      `stroke-${fill} fill-${fill}`,
      [
        isUserOnMobile()
          ? [
              [rotate ? 'mb-[-1px]' : 'mt-[-1px]'],
              [rotate ? 'translate-y-[-0.5px]' : 'translate-y-[0.5px]'],
            ]
          : '',
      ],
    ]">
    <!--    [rotate ? 'mb-[-1px]' : 'mt-[-1px]'], [rotate ? 'translate-y-[-0.5px]' : 'translate-y-[0.5px]']-->
    <path
      :class="[{ rotate: rotate }, `fill-${fill}`]"
      d="M0 20H1440C1440 20 1043.76 0 689.4 0C335.04 0 0 20 0 20Z" />
  </svg>
</template>

<script setup lang="ts">
import { isUserOnMobile } from '@/utilities'

interface Props {
  fill?: string
  rotate?: boolean
}

withDefaults(defineProps<Props>(), {
  fill: 'white',
  rotate: false,
})
</script>

<style lang="scss" scoped>
.rotate {
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}
</style>
