<template>
  <input-text
    :label="label"
    place-holder="First name"
    :input-classes="inputClasses"
    :value="firstName"
    :error="error"
    name="user-name"
    id="user-name"
    @input="updateUserName"
    @blur="$emit('blur')"
    :disabled="disabled"
    :stack-label="false" />
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { capitalize } from '@/lib/helpers'
import InputText from '@/components/BasicInputs/InputText.vue'
export default {
  name: 'UserFirstName',
  components: { InputText },
  emits: ['blur'],
  computed: {
    ...mapGetters(['checkHasChangeBeenMade', 'profile', 'totalMonthlyExpense']),
    ...mapState({
      firstName: (state) => state.profile.firstName,
      partnerInfo: (state) => state.partnerInfo,
    }),
  },
  props: {
    error: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'First name',
    },
    inputClasses: {
      type: String,
      default: 'w-full focus:outline-none mp1 md:p1 self-center text-left',
    },
    shrinkLabel: {
      type: Boolean,
      default: false,
    },
    triggerUnsavedModal: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    updateUserName(value) {
      if (!this.checkHasChangeBeenMade && this.triggerUnsavedModal) {
        const profileDataString =
          JSON.stringify(this.profile) +
          JSON.stringify(this.totalMonthlyExpense) +
          JSON.stringify(this.partnerInfo)
        this.$store.dispatch('setPreviousDataObject', profileDataString)
      }
      this.$store.dispatch('setProfileFirstName', value)
    },
    uppercase(value) {
      return capitalize(value)
    },
  },
}
</script>

<style scoped></style>
