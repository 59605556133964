  <template>
  <div class="flex flex-row justify-center">
    <MenuDropDown
      v-for="(links, idx) in routerLinks"
      :key="idx"
      ref="solutionsDropdown"
      :data-test="idx"
      :name="idx.toString().replace('_', ' ')"
      class="relative cursor-pointer w-full">
      <template #dropdown-items>
        <div class="dropdown top-[30px]">
          <ul class="flex flex-col gap-y-[20px]">
            <li v-for="(link, index) in links" :key="index" class="flex">
              <RouterLink
                :data-test="link.id"
                :to="link.link"
                class="text-grey-1 font-bold hover:text-otivo-blue">
                {{ link.name }}
              </RouterLink>
            </li>
          </ul>
        </div>
      </template>
    </MenuDropDown>
  </div>
</template>

<script lang="ts" setup>
import MenuDropDown from '@/components/BasicInputs/DropDown/MenuDropDown.vue'
import { getLinksForWhitelabel } from '@/composables/getLinksForWhitelabel.ts'

type RouterLink = {
  name: string
  link: string
  id: string
}

interface RouterLinks {
  [key: string]: Array<RouterLink>
}

const routerLinks: RouterLinks = getLinksForWhitelabel(false)
</script>

<style lang="scss" scoped>
.router-link.router-link-active {
  @apply fill-otivo-blue;
  @apply text-white;
}

::deep(.plan-icon) {
  @apply h-10 w-10;
}

.dropdown {
  @apply flex absolute bg-white rounded text-black p-[20px] shadow w-[205px];
}
</style>
