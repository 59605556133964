<template>
  <div
    :id="`${type}-autocomplete`"
    class="relative"
    :class="overrideWidth"
    v-on-click-outside="doom">
    <input
      :id="`${type}-input`"
      autocomplete="off"
      type="text"
      v-model="inputString"
      :data-test="`${type}-input`"
      :placeholder="placeholder"
      @blur="reset"
      @keydown.tab.shift="doom"
      :class="[{ 'rounded-b-none': showResults }, { 'border-red-1 border': error }]"
      class="p-4 w-100% paragraph-2 rounded-[5px] border border-grey-field enabled:active:otivo-outline shadow-inner enabled:hover:otivo-outline"
      :disabled="disabled" />

    <div
      v-if="showResults || dropdownModeEnabled"
      :data-test="`${type}-resultsContainer`"
      class="absolute bg-white w-100% border border-t-0 border-grey-field border-r-[1px] rounded rounded-t-none drop-shadow overflow-hidden z-10">
      <div v-if="items.length && !loading" class="max-h-[150px] overflow-auto">
        <div
          v-for="(item, idx) in items"
          :id="`item-${idx}`"
          :key="idx"
          :data-test="`${type}-result-${sanitise(item.name)}`"
          tabindex="0"
          @click="selectItem(item)"
          @keydown.enter="selectItem(item)"
          class="cursor-pointer p-4 text-14px font-medium hover:bg-otivo-blue hover:text-white focus:bg-otivo-blue focus:text-white">
          {{ item.name }}
        </div>
      </div>

      <div v-else class="flex justify-center pt-4 pb-4">
        <SyncLoader v-if="loading" color="#0064FF" />
        <div class="font-medium" v-else>No results</div>
      </div>
    </div>

    <InputError v-if="error" :error="error" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import SyncLoader from 'vue-spinner/src/SyncLoader.vue'
import { sanitise } from '@/composables/sanitise'
import { vOnClickOutside } from '@vueuse/components'
import { useDebounceFn } from '@vueuse/core'
import InputError from '@/components/BasicInputs/InputError.vue'
import { ArrayObjectType } from '@/types/ArrayObject.ts'

/**
 * Autocomplete:
 * error handling inside this component is visual only, validation must be handled by the parent
 */
type AutocompleteProps<T> = {
  id: string
  type: 'postcode' | 'occupation' | 'superfund' | 'investmentOptions' | string
  placeholder: string
  getItems: (val: string, id?: number, items?: AutoCompleteItemObjectType[]) => Promise<T[]>
  itemsIdentifyingId?: number | null
  existingValue?: AutoCompleteItemObjectType | null
  error?: string | null
  overrideWidth?: string
  disabled?: boolean
  forceDropdownMode?: boolean
}

export type AutoCompleteItemObjectType = {
  name: string
  value: string | number
}

const loading = ref(false)
const dropdownModeEnabled = ref(false)
// get Items
const items = ref<AutoCompleteItemObjectType[]>([])
const inputString = ref('')

const showResults = computed(() => {
  return (
    (inputString.value?.length > 2 && inputString.value !== selectedItem.value?.name) ||
    loading.value
  )
})

onMounted(() => {
  if (props.existingValue) {
    inputString.value = props.existingValue.name
  }
})
watch(inputString, (val) => {
  if (val !== selectedItem.value?.name) {
    if (val.length > 2) {
      loading.value = true
      getItemsAsync()
    }
  }
})

const getItemsAsync = useDebounceFn(() => {
  props.getItems?.(inputString.value, props.itemsIdentifyingId || undefined).then((res) => {
    items.value = res
    loading.value = false
  })
}, 690)

// selectItem
const selectedItem = ref<ArrayObjectType>()
const selectItem = (item: AutoCompleteItemObjectType) => {
  items.value = []
  selectedItem.value = item
  inputString.value = item.name
  dropdownModeEnabled.value = false
  emits('selected', item)
}

const doom = () => {
  /** DOOM! needs to be here and it's fun **/
  dropdownModeEnabled.value = false
}

const reset = () => {
  if (inputString.value === '') inputString.value = props.existingValue?.name || ''
}

const undoSelect = () => {
  inputString.value = props.existingValue?.name || ''
  selectedItem.value = props.existingValue as ArrayObjectType
}

const props = withDefaults(defineProps<AutocompleteProps<AutoCompleteItemObjectType>>(), {
  overrideWidth: 'w-100%',
  itemsIdentifyingId: null,
  existingValue: null,
  error: null,
  disabled: false,
  forceDropdownMode: false,
})

const emits = defineEmits({
  selected: (val: AutoCompleteItemObjectType) => val,
})

defineExpose({
  undoSelect,
})

watch(
  props,
  (changed) => {
    if (changed.existingValue) {
      inputString.value = changed.existingValue.name
      selectedItem.value = changed.existingValue as ArrayObjectType
    }
  },
  {
    immediate: true,
  },
)
</script>

<style scoped>
input:disabled {
  @apply border-grey-light rounded bg-grey-light ring-grey-light text-grey-2 shadow-none;
}
</style>
