<template>
  <div class="flex">
    <div
      v-for="(option, index) in options"
      class="flex h-24 w-36 justify-around m-2 border opacity-50 cursor-pointer rounded lg:hover:border-otivo-blue"
      :key="index"
      :class="{ selected: option.value === selected }"
      @click="$emit('click', option.value)">
      <div class="mx-3 self-center text-center">
        {{ option.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FinancialPlanAnswer',
  data() {
    return {
      options: [
        { name: 'A comprehensive plan', value: 'a_comprehensive_plan' },
        { name: 'A rough plan', value: 'a_rough_plan' },
        { name: 'Not plan at all', value: 'not_plan_at_all' }
      ]
    }
  },
  props: {
    selected: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped></style>
