<template>
  <div class="m-auto">
    <h4>Unsubscribe email</h4>
    <div class="paragraph-1 py-[40px]">Tick the box if you wish to unsubscribe</div>
    <div class="flex flex-wrap" v-for="(subscription, index) in subscriptions" :key="index">
      <BaseCheckbox :name="subscription.name" v-model:checked="subscription.unsubscribe" />
      <p class="ml-[10px]">{{ subscription.name }}</p>
    </div>

    <OtivoButton
      class="w-full mt-[40px]"
      @click="unsubscribe()"
      :loading="loading"
      :disabled="subscriptions.filter((el) => el.unsubscribe).length === 0">
      Unsubscribe
    </OtivoButton>
  </div>
</template>

<script setup lang="ts">
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import { computed, onMounted, ref } from 'vue'
import { useToast } from '@/composables/useToast.ts'
import api from '@/services/Api.ts'
import OtivoButton from '@/components/OtivoButton.vue'
import { useRoute } from 'vue-router'

const { successToast, errorToast } = useToast()

const route = useRoute()

const subscriptionId = computed(() => route.params.subscriptionId)
const userId = computed(() => route.params.userId)

const subscriptions = ref([])

const loading = ref(false)

onMounted(() => {
  api()
    .Get('/email/subscriptions')
    .then((res) => {
      setSubscriptions(res.data)
    })
})

const setSubscriptions = (value) => {
  subscriptions.value = value
    .map((item) => {
      item.unsubscribe = item.id === Number(subscriptionId.value)
      return item
    })
    .filter((el) => el.name.toLowerCase() !== 'advice') // advice emails should always come through
}

const unsubscribe = () => {
  loading.value = true
  api()
    .Post('/email/unsubscribe', {
      userId: userId.value,
      subscriptions: subscriptions.value.map((el) => {
        el.subscribed = !el.unsubscribe
        return el
      }),
    })
    .then(() => {
      successToast('You are now unsubscribed')
      loading.value = false
    })
    .catch((err) => {
      errorToast('Error occurred, please try again')
      loading.value = false
      throw err
    })
}
</script>

<style scoped></style>
