<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
    <g clip-path="url(#clip0_764_169761)">
      <path
        d="M16 8.19043C16 12.6087 12.4183 16.1904 8 16.1904C3.58172 16.1904 0 12.6087 0 8.19043C0 3.77215 3.58172 0.19043 8 0.19043C12.4183 0.19043 16 3.77215 16 8.19043Z"
        :class="`fill-${fill1}`" />
      <path
        d="M12 6.98719H8.84232L10.0952 2.19043L4 9.58414H7.15768L5.90476 14.3809L12 6.98719Z"
        :class="`fill-${fill2}`" />
    </g>
  </svg>
</template>

<script lang="ts" setup>
type Props = {
  fill1?: string
  fill2?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-red',
  fill2: 'white'
})
</script>

<style scoped></style>
