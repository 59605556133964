import UserService from '@/services/UserService.ts'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import getModuleStatusList from '@/composables/getModuleStatusList.ts'

export type ModuleStatus =
  | 'infoNeeded'
  | 'ready'
  | 'actioned'
  | 'processing'
  | 'comingSoon'
  | 'onTrack'

export type InsuranceModuleStatus = Record<'lifeCover' | 'incomeCover' | 'tpdCover', ModuleStatus>

export type ModuleStatusesType = {
  superInvestments: ModuleStatus
  salarySacrificeContributions: ModuleStatus
  lumpSumConcessionalContributions: ModuleStatus
  lumpSumNonConcessionalContributions: ModuleStatus
  personalInsurance: InsuranceModuleStatus
}

export const useModuleStatusesStore = defineStore('ModuleStatuses', () => {
  const possibleModuleStates: ModuleStatus[] = ['infoNeeded', 'ready', 'actioned', 'processing']
  const statuses = ref<ModuleStatusesType>({
    superInvestments: 'infoNeeded',
    salarySacrificeContributions: 'infoNeeded',
    lumpSumConcessionalContributions: 'infoNeeded',
    lumpSumNonConcessionalContributions: 'infoNeeded',
    personalInsurance: {
      lifeCover: 'infoNeeded',
      incomeCover: 'infoNeeded',
      tpdCover: 'infoNeeded',
    },
  })

  const fetchModuleStatuses = async () => {
    try {
      const response = await UserService.getModuleStatuses()
      statuses.value = response.data
    } catch (e) {
      console.error('Error fetching module statuses', e)
    }
  }

  const isValidInsuranceCoverType = (type: string): boolean => {
    return ['life', 'tpd', 'income'].includes(type)
  }

  // Determine if advice for a specific cover type has been actioned
  const isAdviceActioned = (type: string): boolean => {
    if (!isValidInsuranceCoverType(type)) return false
    const fullKey = `${type}Cover` as keyof InsuranceModuleStatus
    return statuses.value.personalInsurance[fullKey] === 'actioned'
  }

  // Get the highest priority state for given modules
  const getHighestPriorityState = (modules: Array<string> | string): ModuleStatus => {
    if (typeof modules === 'string') {
      const module = statuses.value[modules as keyof ModuleStatusesType]
      if (typeof module !== 'object' || module === null) {
        return 'infoNeeded' // default status
      }
      const moduleStatuses = Object.values(module) as ModuleStatus[]
      for (const state of possibleModuleStates) {
        if (moduleStatuses.includes(state)) {
          return state
        }
      }
    } else {
      const targetedModules: Array<ModuleStatus> = modules.map(
        (module) => statuses.value[module as keyof ModuleStatusesType] as ModuleStatus,
      )
      // Find the highest priority status from the list
      for (const state of possibleModuleStates) {
        if (targetedModules.includes(state)) {
          return state
        }
      }
    }
    return 'infoNeeded'
  }

  return {
    getStatuses: computed(() => statuses.value),
    options: getModuleStatusList,
    fetchModuleStatuses,
    isAdviceActioned,
    getHighestPriorityState,
  }
})
