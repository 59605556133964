<template>
  <BaseView>
    <div
      id="personal-pricing"
      v-if="pageContent.currentPage === 'personalPricingPage'"
      class="bg-blue-5">
      <Breadcrumbs />
      <PersonalPricingTiles class="relative z-0 bg-blue-5" />
      <PersonalBetterOff
        v-if="false"
        :personal-better-off-content="pageContent.personalBetterOff" />
      <RegularCurve class="min-h-6 w-full" fill="blue-1" />
      <HowItWorksModule
        class="py-16 px-5 md:px-0 relative z-50 bg-blue-1 text-white"
        :body-text-colour="'white'"
        :lines="'blue-2'"
        :how-it-works="pageContent.personalPricingHowItWorks" />
      <Awards
        class="pt-10 pb-[80px] bg-grey-1 md:bg-blue-1"
        :color="{
          textColour: 'white',
          svgLineFill: 'white',
          svgImageBackgroundFill: 'blue-1',
        }" />
      <ThreeTileDisplay
        class="!bg-blue-1 md:!bg-grey-1"
        text-colour="white"
        :icon-boxes="pageContent.threeTileDisplay.iterableItems" />
      <RegularCurve class="h-6 w-full md:hidden bg-blue-1 fill-midnight" fill="midnight" />
    </div>
  </BaseView>
</template>

<script setup lang="ts">
import Awards from '@/components/Footer/Awards.vue'
import BaseView from '@/views/BaseView.vue'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import HowItWorksModule from '@/components/HowItWorksModule/HowItWorksModule.vue'
import PersonalBetterOff from '@/components/BrandComponents/Otivo/Personal/PersonalBetterOff.vue'
import PersonalPricingTiles from '@/components/BrandComponents/Pricing/PersonalPricingTiles.vue'

import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import ThreeTileDisplay from '@/components/BrandComponents/Otivo/ThreeTileDisplay.vue'
import RegularCurve from '@/components/Shapes/RegularCurve.vue'
import { useHead } from '@unhead/vue'

const store = useStore()

onBeforeMount(() => store.dispatch('ContentStore/getContent', 'personalPricingPage'))

useHead({
  title: "Otivo's affordable digital advice for everyone",
})

const pageContent = computed(() => store.getters['ContentStore/pageContent'])
</script>
