import { Chart } from 'chart.js/auto'

export default function <T>(id: string, updateData: T) {
  const graph = Object.values(Chart.instances)
    .filter((c) => c.canvas.id == id)
    .pop() as Chart

  graph.data.labels = updateData.labels
  graph.data.datasets[0].data = updateData.data
  graph.update()
}
