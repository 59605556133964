<template>
  <div class="flex flex-row h-full md:h-[140px] w-full md:max-w-[1280px] mx-auto">
    <div class="flex-[1] bg-white flex justify-center items-center">
      <p class="mx-auto menu-1 text-blue-1 uppercase">Trusted by</p>
    </div>
    <InfiniteSliderHorizontal :items :speed="30" list-class="space-x-10 pr-10" />
  </div>
</template>

<script setup lang="ts">
import ColonialFirstState from '@/components/BrandComponents/Otivo/TrustedBy/ColonialFirstState.svg'
import Suncorp from '@/components/BrandComponents/Otivo/TrustedBy/Suncorp.svg'
import RioTinto from '@/components/BrandComponents/Otivo/TrustedBy/RioTinto.svg'
import Latitude from '@/components/BrandComponents/Otivo/TrustedBy/Latitude.svg'
import EY from '@/components/BrandComponents/Otivo/TrustedBy/EY.svg'
import Medibank from '@/components/BrandComponents/Otivo/TrustedBy/Medibank.svg'
import PacificBlue from '@/components/BrandComponents/Otivo/TrustedBy/PacificBlue.svg'
import StanfordBrown from '@/components/BrandComponents/Otivo/TrustedBy/Stanfordbrown.svg'
import Fortnum from '@/components/BrandComponents/Otivo/TrustedBy/Fortnum.svg'
import InfiniteSliderHorizontal from '@/components/InfiniteSliderHorizontal.vue'

const items = [
  ColonialFirstState,
  Suncorp,
  RioTinto,
  Latitude,
  EY,
  Medibank,
  PacificBlue,
  StanfordBrown,
  Fortnum,
]
</script>

<style scoped></style>
