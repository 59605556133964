<template>
  <div
    class="px-4 py-10 mx-2 rounded shadow hover:shadow-lg relative pointer-events-none bg-white text-grey-1 w-[262px] h-[440px] lg:h-[390px]"
    :class="styles">
    <img
      :src="icons[index]"
      :alt="'How it works' + index"
      class="m-auto rounded-circle min-h-[160px]" />
    <div class="flex gap-[10px] mt-[20px] min-w-[128px]">
      <div
        class="h5 bg-otivo-red flex h-[32px] min-w-[32px] items-center justify-center rounded-circle text-white">
        {{ index + 1 }}
      </div>
      <div class="text-left">
        <h6 class="font-bold">
          {{ data.heading }}
        </h6>
        <div class="paragraph-2 text-grey-2 pt-[10px]">
          {{ data.body }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Step } from '@/components/HowItWorksModule/types'
import HowItWorksBubbleThree from '@/components/BrandComponents/Otivo/HowItWorks/HowItWorksBubbleThree.svg?url'
import HowItWorksBubbleTwo from '@/components/BrandComponents/Otivo/HowItWorks/HowItWorksBubbleTwo.svg?url'
import HowItWorksBubbleOne from '@/components/BrandComponents/Otivo/HowItWorks/HowItWorksBubbleOne.svg?url'
import HowItWorksBubbleFour from '@/components/BrandComponents/Otivo/HowItWorks/HowItWorksBubbleFour.svg?url'

interface Props {
  data: Step
  index: number
  styles?: string
}

withDefaults(defineProps<Props>(), {
  styles: '',
})

const icons: Array<unknown> = [
  HowItWorksBubbleThree,
  HowItWorksBubbleTwo,
  HowItWorksBubbleOne,
  HowItWorksBubbleFour,
]
</script>
