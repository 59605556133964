<template>
  <tbody>
    <tr
      v-for="(data, index) in tableData"
      :key="index"
      class="border border-grey-5 mb1 md:b1 even:bg-white odd:bg-grey-5">
      <th class="border-[1px] border-grey-4 p-4">
        {{ data.first_name }}
      </th>
      <th class="border-[1px] border-grey-4 p-4">
        {{ data.last_name }}
      </th>
      <th class="border-[1px] border-grey-4 p-4 overflow-ellipsis">
        {{ data.email }}
      </th>
      <th class="border-[1px] border-grey-4 p-4">
        {{ data.contact_centre ? 'Yes' : 'No' }}
      </th>
      <th class="border-[1px] border-grey-4 p-4">
        {{ data.super_user ? 'Yes' : 'No' }}
      </th>
      <th class="border-[1px] border-grey-4 p-4">
        {{ data.back_office ? 'Yes' : 'No' }}
      </th>
      <th class="border-[1px] border-grey-4 p-4">
        {{ data.blocked ? 'Yes' : 'No' }}
      </th>
      <th class="border-1px border-grey-4 flex p-4 gap-x-4">
        <OtivoTextButton
          :disabled="data.super_user as boolean"
          @click="emit('edit', index)"
          colour="white">
          <span
            :class="{
              'cursor-not-allowed text-grey': data.super_user,
              'text-otivo-blue': !data.super_user,
            }">
            Edit
          </span>
        </OtivoTextButton>

        <OtivoTextButton v-if="data.blocked" :disabled="data.super_user as boolean" colour="red">
          <Popper arrow :disabled="data.super_user as boolean">
            <span
              :class="{
                'cursor-not-allowed text-grey': data.super_user,
                'text-grey-1': !data.super_user,
              }">
              Unblock
            </span>
            <template #content="{ close }">
              <div class="flex flex-col space-y-5">
                <p class="text-grey-1">Are you sure you want to unblock this user?</p>
                <div class="flex justify-end gap-x-4">
                  <OtivoButton size="small" @click="close" colour="white">Cancel</OtivoButton>
                  <OtivoButton
                    size="small"
                    @click="
                      () => {
                        emit('restore', index)
                        close()
                      }
                    "
                    colour="red">
                    Confirm
                  </OtivoButton>
                </div>
              </div>
            </template>
          </Popper>
        </OtivoTextButton>

        <OtivoTextButton v-else :disabled="data.super_user as boolean" colour="red">
          <Popper arrow :disabled="data.super_user as boolean">
            <span
              :class="{
                'cursor-not-allowed text-grey': data.super_user,
                'text-red-1': !data.super_user,
              }">
              Block
            </span>
            <template #content="{ close }">
              <div class="flex flex-col space-y-5">
                <p class="text-grey-1">Are you sure you want to block this user?</p>
                <div class="flex justify-end gap-x-4">
                  <OtivoButton size="small" @click="close" colour="white">Cancel</OtivoButton>
                  <OtivoButton
                    size="small"
                    @click="
                      () => {
                        emit('delete', index)
                        close()
                      }
                    "
                    colour="red">
                    Confirm
                  </OtivoButton>
                </div>
              </div>
            </template>
          </Popper>
        </OtivoTextButton>
      </th>
    </tr>
  </tbody>
</template>

<script setup lang="ts">
import Popper from 'vue3-popper'
import OtivoTextButton from '@/components/OtivoTextButton.vue'
import OtivoButton from '@/components/OtivoButton.vue'

defineProps<{
  tableData: Array<Record<string, unknown>>
}>()

const emit = defineEmits<{
  (e: 'edit', index: number): void
  (e: 'delete', index: number): void
  (e: 'restore', index: number): void
}>()
</script>

<style>
:root {
  --popper-theme-background-color: #fff;
  --popper-theme-background-color-hover: #fff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 20px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>
