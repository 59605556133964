import { useDebounceFn } from '@vueuse/core'
import { Chart } from 'chart.js/auto'
import htmlLegendsPlugin from '@/composables/charts/plugins/htmlLegendsPlugin.ts'

const doughnutConfig = {
  type: 'doughnut',
  options: {
    plugins: {
      htmlLegend: {
        containerID: 'doughnut-legend'
      },
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    }
  },
  plugins: [htmlLegendsPlugin('column', '8px')]
}

export const drawDoughnut = useDebounceFn((data, canvas: HTMLCanvasElement, labelPlugin?) => {
  const config = {
    ...doughnutConfig,
    data: data
  }
  if (labelPlugin) config.plugins.push(labelPlugin)
  return new Chart(canvas, config)
}, 500)
