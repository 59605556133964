<template>
  <div class="w-full max-w-[560px] mx-auto">
    <BasicCard :class="'space-y-5'">
      <h4 class="h5-bold text-blue-1 font-semibold">Search for client</h4>
      <form @submit.prevent="search" class="min-w-[375px]">
        <div class="w-100% px-[40px]">
          <div class="flex flex-col col-span-1 gap-2 mt-5">
            <label for="email" class="w-fit paragraph-1 text-grey-2">
              <span class="flex flex-row"> Email </span>
            </label>
            <BaseInput
              type="text"
              v-model:value="searchValue.email"
              name="email"
              placeholder="user@mail.com"
              class="w-full" />
          </div>
          <div class="flex flex-col col-span-1 gap-2 mt-5">
            <label for="first-name" class="w-fit paragraph-1 text-grey-2">
              <span class="flex flex-row"> First name </span>
            </label>
            <BaseInput
              type="text"
              v-model:value="searchValue.first_name"
              name="first-name"
              placeholder="First name"
              class="w-full" />
          </div>

          <div class="flex flex-col col-span-1 gap-2 mt-5">
            <label for="first-name" class="w-fit paragraph-1 text-grey-2">
              <span class="flex flex-row"> Last name </span>
            </label>
            <BaseInput
              type="text"
              v-model:value="searchValue.last_name"
              name="last-name"
              placeholder="Last name"
              class="w-full" />
          </div>
        </div>
        <OtivoButton
          type="submit"
          colour="blue"
          size="large"
          class="mt-[40px] m-auto"
          data-test="submitBtn"
          :loading="loading">
          Search
        </OtivoButton>
      </form>
      <Transition mode="in-out" name="fade">
        <UserList
          @clear-search="memberSearchResult = []"
          v-if="memberSearchResult.length > 0"
          :member-search-result="memberSearchResult" />
      </Transition>
    </BasicCard>
  </div>
</template>

<script setup lang="ts">
import UserSearchForm from '@/components/AdminPortal/UserSearchForm.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import BasicCard from '@/app/components/BasicCard.vue'
import { onBeforeMount, ref } from 'vue'
import GuidanceAccountSearchForm from '@/components/CFS/Guidance/GuidanceAccountSearchForm.vue'
import api from '@/services/Api.ts'
import { ClientSearchResult, SearchParams } from '@/types/CallcentrePortalTypes.ts'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import { searchForClient } from '@/composables/users/UserSearch.ts'
import { useRouter } from 'vue-router'
import { useToast } from '@/composables/useToast.ts'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import UserList from '@/components/AdminPortal/UserList.vue'
import Pagination from '@/components/Pagination.vue'

const router = useRouter()
const { errorToast } = useToast()

const loading = ref(false)
const memberSearchResult = ref<ClientSearchResult[]>([])

onBeforeMount(async () => {
  const gotClient = await checkForClientData()
})

const searchValue = ref<SearchParams>({
  first_name: '',
  last_name: '',
  email: '',
})

const emits = defineEmits({
  search: (value: SearchParams) => value,
})

const checkForClientData = async (): Promise<boolean> => {
  const clientDataRaw = window.localStorage.getItem('activeManagedUser')
  let clientData: { auth0Id: string } | null = null
  if (clientDataRaw) {
    try {
      clientData = JSON.parse(clientDataRaw)
      if (clientData?.auth0Id) api().setClientHeader(clientData.auth0Id)
    } catch (e) {
      // no client data
      console.error(e)
    }
  }
  return !!(clientData && clientData.auth0Id)
}

const search = async () => {
  loading.value = true
  if (
    searchValue.value.first_name === '' &&
    searchValue.value.last_name === '' &&
    searchValue.value.email === ''
  ) {
    errorToast('Please enter search parameters')
    loading.value = false
  } else {
    try {
      searchValue.value.whitelabel_id = useSessionStore().getWhitelabelData.id
      memberSearchResult.value = await searchForClient(searchValue.value)
    } catch (e) {
      if (e.status === 404) errorToast('No results found')
      console.error(e)
    } finally {
      loading.value = false
    }
  }
}

defineExpose({
  emits,
})
</script>

<style scoped></style>
