import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import {
  login as auth0Login,
  logout as auth0Logout,
  signUp as auth0Signup,
  userAuth0Id,
} from '@/lib/AuthenticatorPlugin'
import { WhitelabelDataType } from '@/types/WhiteLabelTypes'
import { ISignupObject } from '@/lib/Authenticator'
import { store } from '@/store'

export interface ISessionStore {
  getLoadingState: boolean
  getWhitelabelData: WhitelabelDataType
  getImpersonationState: boolean
  isBackendAlive: boolean
  timeoutModalActive: boolean
  logout: (returnTo?: string) => void
  login: (connectionOverride?: string) => void
  signUp: (signUpObject: ISignupObject) => Promise<void>
  toggleTimeoutModal: (val: boolean) => void
  setImpersonationState: (val: boolean) => void
  setWhitelabelData: (val: { status: boolean; whitelabel: WhitelabelDataType }) => void
}

export const useSessionStore = defineStore('SessionStore', () => {
  const loading = ref(true)
  const impersonating = ref(false)
  const whitelabelData = ref<WhitelabelDataType>({} as WhitelabelDataType)
  const showTimeoutModal = ref(false)

  const getLoadingState = computed(() => loading.value)
  const getWhitelabelData = computed(() => whitelabelData.value)
  const timeoutModalActive = computed(() => showTimeoutModal.value)
  const getImpersonationState = computed(() => impersonating.value)

  const login = (connectionOverride?: string) => {
    auth0Login(connectionOverride)
  }
  const logout = (returnTo?: string) => {
    const hasSeenWelcomeModalKey = 'hasSeenWelcomeModal_' + userAuth0Id.value
    const dontShowNewFeaturesKey = 'dontShowNewFeatures'
    const hasSeenWelcomeModal = localStorage.getItem(hasSeenWelcomeModalKey)
    const dontShowNewFeatures = localStorage.getItem(dontShowNewFeaturesKey)
    window.localStorage.clear()
    window.sessionStorage.clear()
    if (hasSeenWelcomeModal) {
      localStorage.setItem(hasSeenWelcomeModalKey, 'Y')
    }
    if (dontShowNewFeatures) {
      localStorage.setItem(dontShowNewFeaturesKey, 'true')
    }
    const url = whitelabelData.value.frontend_config?.logout_redirect_url
      ? whitelabelData.value.frontend_config?.logout_redirect_url +
        'https://' +
        window.location.host
      : returnTo
    auth0Logout(url)
  }

  const signUp = async (signUpObject: ISignupObject) => {
    return auth0Signup(signUpObject)
  }

  const toggleTimeoutModal = (val: boolean) => {
    showTimeoutModal.value = val
  }

  const setWhitelabelData = (val: { whitelabel: WhitelabelDataType }) => {
    whitelabelData.value = val.whitelabel
    store.dispatch('setWhitelabelValues', val.whitelabel)
  }

  const setImpersonationState = (val: boolean) => (impersonating.value = val)

  return {
    getLoadingState,
    getWhitelabelData,
    getImpersonationState,
    timeoutModalActive,
    logout,
    login,
    signUp,
    toggleTimeoutModal,
    setImpersonationState,
    setWhitelabelData,
  }
})
