<template>
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.51 1.99958L1.815 4.09958C1.365 4.44958 1 5.19458 1 5.75958V9.46458C1 10.6246 1.945 11.5746 3.105 11.5746H8.895C10.055 11.5746 11 10.6246 11 9.46958V5.82958C11 5.22458 10.595 4.44958 10.1 4.10458L7.01 1.93958C6.31 1.44958 5.185 1.47458 4.51 1.99958Z"
      :class="`stroke-${stroke}`"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M6 9.57458V8.07458"
      :class="`stroke-${stroke}`"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script setup lang="ts">
interface Props {
  stroke?: string
}

withDefaults(defineProps<Props>(), {
  stroke: 'grey-3'
})
</script>
