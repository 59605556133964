import convertToNumber from '@/composables/convertToNumber'

export default (value: string | number): string => {
  const valueAsNumber: number = convertToNumber(value)
  if (isNaN(valueAsNumber)) return ''

  return valueAsNumber.toLocaleString('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })
}
