<template>
  <svg width="10" height="13" viewBox="0 0 10 13" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.94312 4.55425L9.66687 3.79675C9.95187 3.49863 9.94438 3.02425 9.64812 2.7355C9.35375 2.44863 8.88312 2.45613 8.59812 2.75425L7.89688 3.48738C7.0625 2.863 6.0575 2.5255 5 2.5255C2.31125 2.5255 0.125 4.73425 0.125 7.45113C0.125 10.168 2.31125 12.3749 5 12.3749C7.68875 12.3749 9.875 10.1661 9.875 7.45113C9.875 6.3955 9.54688 5.3905 8.94312 4.55425ZM5 10.8749C3.13063 10.8749 1.61 9.33925 1.61 7.45113C1.61 5.563 3.13063 4.02738 5 4.02738C5.90562 4.02738 6.75687 4.38363 7.39625 5.0305C8.0375 5.67738 8.39 6.538 8.39 7.453C8.39 9.34113 6.86938 10.8768 5 10.8768V10.8749Z"
      :fill="uniColor ? 'currentColor' : '#0064FF'" />
    <path
      d="M5.14875 4.91016C4.73813 4.91016 4.40625 5.24578 4.40625 5.66016V7.44891C4.40625 7.86328 4.73813 8.19891 5.14875 8.19891C5.55938 8.19891 5.89125 7.86328 5.89125 7.44891V5.66016C5.89125 5.24578 5.55938 4.91016 5.14875 4.91016Z"
      :fill="uniColor ? 'currentColor' : '#FF0000'" />
    <path
      d="M5.95945 0.375H4.04133C3.6307 0.375 3.29883 0.710625 3.29883 1.125C3.29883 1.53938 3.6307 1.875 4.04133 1.875H5.95945C6.37008 1.875 6.70195 1.53938 6.70195 1.125C6.70195 0.710625 6.37008 0.375 5.95945 0.375Z"
      :fill="uniColor ? 'currentColor' : '#FF0000'" />
  </svg>
</template>

<script lang="ts" setup>
type Props = {
  uniColor?: boolean
}

withDefaults(defineProps<Props>(), {
  uniColor: false
})
</script>
