<template>
  <svg width="42" height="43" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_11281_178922)">
      <path
        d="M38.0631 22.2358C37.3669 22.2358 36.6993 22.5124 36.207 23.0047C35.7148 23.497 35.4382 24.1646 35.4382 24.8608V37.3296H28.876V30.1108C28.876 29.4146 28.5994 28.747 28.1071 28.2547C27.6149 27.7624 26.9472 27.4858 26.251 27.4858C25.5549 27.4858 24.8872 27.7624 24.395 28.2547C23.9027 28.747 23.6261 29.4146 23.6261 30.1108V37.3296H17.7201V26.1733C17.7201 25.4771 17.4436 24.8095 16.9513 24.3172C16.459 23.8249 15.7914 23.5483 15.0952 23.5483C14.399 23.5483 13.7314 23.8249 13.2391 24.3172C12.7468 24.8095 12.4703 25.4771 12.4703 26.1733V37.3296H6.56426V33.3921C6.56426 32.6959 6.28771 32.0282 5.79545 31.5359C5.30318 31.0437 4.63553 30.7671 3.93936 30.7671C3.24319 30.7671 2.57554 31.0437 2.08327 31.5359C1.59101 32.0282 1.31445 32.6959 1.31445 33.3921V39.9546C1.31445 40.6508 1.59101 41.3185 2.08327 41.8107C2.57554 42.303 3.24319 42.5796 3.93936 42.5796H38.0631C38.7593 42.5796 39.4269 42.303 39.9192 41.8107C40.4115 41.3185 40.688 40.6508 40.688 39.9546V24.8608C40.688 24.1646 40.4115 23.497 39.9192 23.0047C39.4269 22.5124 38.7593 22.2358 38.0631 22.2358Z"
        :class="`fill-${fill1}`" />
      <path
        d="M39.3751 0.57959H28.8755C28.1793 0.57959 27.5117 0.856152 27.0194 1.34844C26.5271 1.84072 26.2506 2.5084 26.2506 3.20459C26.2506 3.90078 26.5271 4.56846 27.0194 5.06074C27.5117 5.55303 28.1793 5.82959 28.8755 5.82959H32.9113L23.2516 15.4896L17.2012 9.4324C16.9587 9.18493 16.6685 8.98927 16.3481 8.85729C16.0278 8.72531 15.684 8.65975 15.3375 8.66459C14.9907 8.66517 14.6474 8.73449 14.3275 8.86853C14.0076 9.00257 13.7175 9.19868 13.4738 9.44553L0.756185 22.354C0.267127 22.85 -0.00485698 23.52 6.56759e-05 24.2166C0.00498833 24.9132 0.286415 25.5793 0.782435 26.0683C1.27845 26.5574 1.94844 26.8294 2.64499 26.8245C3.34155 26.8196 4.00762 26.5381 4.49667 26.0421L15.3572 15.0171L21.4011 21.0546C21.8931 21.5455 22.5598 21.8212 23.2549 21.8212C23.95 21.8212 24.6167 21.5455 25.1087 21.0546L36.7502 9.41271V13.7046C36.7502 14.4008 37.0267 15.0685 37.519 15.5607C38.0113 16.053 38.6789 16.3296 39.3751 16.3296C40.0713 16.3296 40.7389 16.053 41.2312 15.5607C41.7234 15.0685 42 14.4008 42 13.7046V3.20459C42 2.5084 41.7234 1.84072 41.2312 1.34844C40.7389 0.856152 40.0713 0.57959 39.3751 0.57959V0.57959Z"
        :class="`fill-${fill2}`" />
    </g>
    <defs>
      <clipPath id="clip0_11281_178922">
        <rect width="42" height="42" fill="white" transform="translate(0 0.57959)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1?: string
  fill2?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red'
})
</script>

<style scoped></style>
