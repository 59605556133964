import ContentService from '@/services/ContentService'
import ProfileService from '@/services/ProfileService'
import MiscellaneousService from '@/services/MiscellaneousService'
import _ from 'lodash'
import { createSavingObjectAfterSaving } from '@/lib/SavingsPlanHelper'

// TODO: Review/Refactor whatever the hell this is

const state = () => ({
  states: [],
  openDashboardInfoModal: false,
  incompletePlansModal: {
    render: false,
    planType: 'debt'
  },
  labelContent: {
    general: {},
    ret_calc: {}
  }
})

const getters = {
  totalActualRetirementAndInvestments(state, rootGetters) {
    return (
      rootGetters.totalActualInvestments.length + rootGetters.supers.filter((el) => el.id).length
    )
  },

  totalActualPlans(state, rootGetters) {
    return (
      rootGetters.totalActualLiabilities +
      rootGetters.totalActualSavings +
      rootGetters.totalActualRetirementAndInvestments +
      rootGetters.totalActualInsurances
    )
  },

  getStateById: (state) => (id) => {
    return state.states.filter((state) => state.id === id)[0]
  },

  getOwnersList(state, rootGetters) {
    const owners = [{ name: rootGetters.firstName, value: 'single' }]
    if (rootGetters.checkIfUserBasicInfoFormFilled) {
      owners.push({ name: rootGetters.partnerFirstName, value: 'partner' })
    }
    owners.push({ name: 'Shared', value: 'shared' })
    return owners
  },

  getHeightNavBarHeightForWhiteLabels(state, getters, rootState, rootGetters) {
    if (getters.isItMeem || getters.isItBenestar || getters.isItEy || getters.isItAustrac) {
      return 40
    } else if (getters.isItWww && !rootGetters.authenticated) {
      return 32
    }
    return 22
  },

  areThereAnyUncompletedPlans(state, rootGetters) {
    return (planType) => {
      if (rootGetters.checkIfUserBasicInfoFormFilled) {
        if (planType === 'debt') {
          return rootGetters.getUncompletedLiabilities.length > 0
        } else if (planType === 'savings') {
          return rootGetters.getUncompletedSavings.length > 0
        } else if (planType === 'insurance') {
          return rootGetters.getUncompletedInsurances.length > 0
        } else if (planType === 'retirement') {
          return (
            rootGetters.getUncompletedRetirement.user ||
            rootGetters.getUncompletedRetirement.partner ||
            rootGetters.getUncompletedInvestments.length > 0 ||
            rootGetters.getUncompletedSupers.length > 0
          )
        }
      }

      return false
    }
  },

  getListOfUncompletedPlanTypes(state, rootGetters) {
    return rootGetters.getPlansOrder.filter((el) => rootGetters.areThereAnyUncompletedPlans(el))
  },
  getRetCalcLabelContent(state) {
    return state.labelContent.ret_calc
  }
}

const mutations = {
  SET_STATES(state, states) {
    state.states = states
  },

  OPEN_DASHBOARD_INFO_MODAL(state, value) {
    state.openDashboardInfoModal = value
  },

  SET_INCOMPLETE_PLANS_MODAL_DATA(state, payload) {
    state.incompletePlansModal = {
      ...state.incompletePlansModal,
      payload
    }
  },
  SET_LABEL_CONTENT(state, payload) {
    state.labelContent = payload
  },
  UPDATE_LABEL_CONTENT(state, field) {
    state.labelContent[field.category][field.id_tag] = field
  }
}

const actions = {
  fetchStates({ dispatch, rootGetters }) {
    return ProfileService.getStates().then((res) => {
      dispatch('setStates', res.data.data).then(() => {
        if (
          rootGetters.profile.supplementary_info.living_state &&
          rootGetters.profile.supplementary_info.living_state !== ''
        ) {
          dispatch(
            'setProfileLivingState',
            rootGetters.getStateById(rootGetters.profile.supplementary_info.living_state).short_code
          )
        }
      })
    })
  },

  setStates({ commit, dispatch, rootGetters }, states) {
    return commit('SET_STATES', states)
  },

  setStartingStoreDataLoaders({ commit }, value) {
    commit('RetirementAges/SET_AGE_PENSIONS_LOADING', value)
    commit('RetirementAges/SET_PRESERVATION_AGES_LOADING', value)
    commit('SET_ALL_CHANT_WEST_PRODUCTS_LOADING', value)
  },

  getOtherStoreData({ dispatch, commit, rootGetters }) {
    dispatch('setStartingStoreDataLoaders', true)
    return MiscellaneousService.getFrontendStartingStoreData()
      .then((res) => {
        const data = res.data
        // states
        dispatch('setStates', data.states).then(() => {
          if (
            rootGetters.profile.supplementary_info.living_state &&
            rootGetters.profile.supplementary_info.living_state !== ''
          ) {
            dispatch(
              'setProfileLivingState',
              rootGetters.getStateById(rootGetters.profile.supplementary_info.living_state)
                .short_code
            )
          }
        })

        // agePensions
        commit('RetirementAges/SET_AGE_PENSIONS', data.agePensions)

        // preservationAges
        commit('RetirementAges/SET_PRESERVATION_AGES', data.preservationAges)

        // chantWestProducts
        dispatch('setChantWestProducts', data.chantWestProducts)

        // BasiqInstitutions
        dispatch('fetchAllInstitutions', data.basiqInstitutions)

        dispatch('setStartingStoreDataLoaders', false)
      })
      .catch((err) => {
        dispatch('setStartingStoreDataLoaders', false)
        throw err
      })
  },

  setCrucialDataLoaders({ commit, dispatch }, value) {
    commit('SET_OPTIMIZE_ADVICE_INSURANCE_QUESTIONS_LOADING', value)
    commit('SET_MONTHLY_CASH_FLOW_LOADING', value)
    commit('SET_USER_HISTORY_LOADING', value)
    commit('SET_LOADING_FOR_ALL_NEXT_STEPS', value)
    commit('SET_LOADING_FOR_ALL_RECOMMENDATIONS', value)
    commit('SET_LOADING_FOR_ALL_RECOMMENDATIONS', value)
    dispatch('setFitnessScoreAndNetWealthLoading', value)
  },

  getFrontendCrucialStoreData({ dispatch, commit, rootGetters }) {
    // set loaders
    // dispatch('setCrucialDataLoaders', true)

    return MiscellaneousService.getFrontendCrucialStoreData()
      .then((res) => {
        const data = res.data
        // 'accountFlags' => $accountFlags,
        if (data.accountFlags) commit('SET_ALL_ACCOUNT_FLAGS', data.accountFlags)

        // 'chantWestProfiles' => $chantWestProfiles,
        if (data.chantWestProfiles)
          commit('SET_OPTIMIZE_ADVICE_CHANT_WEST_PROFILES', data.chantWestProfiles)

        // 'cashFlow' => $cashFlow,
        if (data.cashFlow) dispatch('setMonthlyCashFlow', data.cashFlow)

        // 'debtPayDownData' => $debtPayDownData,
        if (data.debtPayDownData) commit('SET_PAY_DOWN_LIABILITY_DATA', data.debtPayDownData)

        // 'financialScoreDataAndNetWealth' => $financialScoreDataAndNetWealth,
        if (data.financialScoreDataAndNetWealth)
          dispatch('setUserFinancialScoreData', data.financialScoreDataAndNetWealth.fitness_score)
        if (data.financialScoreDataAndNetWealth)
          dispatch('setUserNetWorth', data.financialScoreDataAndNetWealth.net_position)

        // 'history' => $history['data'],
        if (data.history) commit('SET_USER_HISTORY', data.history)

        // 'nextSteps' => $nextSteps,
        if (data.nextSteps) dispatch('setAllNextSteps', data.nextSteps)

        // 'notifications' => $notifications,
        if (data.notifications) dispatch('setNotifications', data.notifications)

        // 'recommendations' => $recommendations,
        if (data.recommendations)
          dispatch('setRecommendationsPlanStagesAndPlanOrdering', data.recommendations)

        // 'retirementConsent' => $getRetirementConsent,
        if (data.retirementConsent) dispatch('setRetirementConsentFor', data.retirementConsent)

        // 'rainyDayPlan' => $rainyDayPlan['data'],
        if (data.rainyDayPlan) {
          const savingsPlan = data.rainyDayPlan
          const index = _.findIndex(rootGetters.savings, { id: savingsPlan.id })
          commit('SET_SAVINGS_PLAN', {
            index: index,
            savingsPlan: createSavingObjectAfterSaving(savingsPlan)
          })
          dispatch('calculateUnallocatedCash')
        }

        // dispatch('setCrucialDataLoaders', false)
        return res
      })
      .catch((err) => {
        // dispatch('setCrucialDataLoaders', false)
        throw err
      })
  },

  refreshCrucialStoreData({ dispatch }) {
    // todo :: refactor into one call
    return Promise.all([
      dispatch('fetchMonthlyCashFlow'), // always cashflow should be first
      dispatch('fetchProducts'),
      dispatch('fetchAllRecommendations'),
      dispatch('fetchAllNextSteps'),
      dispatch('fetchFinancialScoreDataAndNetWealth'),
      dispatch('fetchDebtPayDownData'),
      dispatch('getLatestDataOfRainyDayPlan'),
      dispatch('fetchNotifications'),
      dispatch('fetchUserHistory'),
      dispatch('getFrontendCrucialStoreData'),
      dispatch('getOptimiseHistory'),
      dispatch('setDashboardState')
    ])
  },

  fetchLabelContent({ commit }, payload = []) {
    ContentService.fetchLabelContent(payload).then((res) => {
      commit('SET_LABEL_CONTENT', res.data)
    })
  },

  getLabelContent({ commit }, payload) {
    ContentService.getLabelContent(payload).then((res) => {
      res.data.forEach((field) => {
        commit('UPDATE_LABEL_CONTENT', field)
      })
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
