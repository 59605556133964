<template>
  <div class="flex flex-col items-center justify-center min-h-100% bg-blue-6">
    <div
      class="w-full md:min-w-[520px] text-left py-[40px] bg-white rounded-lg border-[2px] border-blue-5 otivo-drop-shadow mt-[40px]">
      <h5 class="text-blue-1 text-22px px-[40px] font-bold w-100% text-left">
        {{ searchHeading }}
      </h5>
      <Component
        ref="searchForm"
        :is="searchFormComponent"
        v-if="!searchedForClient"
        @search="(searchCriteria: ClientSearchParams) => search(searchCriteria)" />
      <div class="w-100% md:min-w-[560px] m-auto rounded" v-if="searchedForClient">
        <div
          class="bg-white border-[1px] mt-[40px] border-l-0 border-r-0 border-grey-4 flex justify-between items-center mb-[2px] px-[30px] h-[50px]">
          <div class="flex gap-2">
            <MagnifyingGlass />
            <p v-if="memberSearchResult.length > 0">
              {{ memberSearchResult[0].member_id }}
            </p>
            <p v-else class="text-grey-2">No members found</p>
          </div>
          <div
            class="menu-1 cursor-pointer"
            @click="
              () => {
                searchedForClient = false
                memberSearchResult = []
              }
            ">
            CLEAR
          </div>
        </div>
        <div v-if="memberSearchResult.length > 0" class="bg-white rounded-b text-left pb-[30px]">
          <div class="text-cfs-blue menu-1 h-[50px] px-[30px] flex items-center">
            {{ memberSearchResult.length }} accounts found
          </div>
          <div v-for="(result, idx) in memberSearchResult" :key="idx">
            <button
              @click="selectUser(result)"
              class="text-left w-full h-full p-[30px] border-b-[1px] border-t-[1px] border-grey-4">
              <div class="button-1 text-otivo-blue underline">
                {{ result.first_name }} {{ result.last_name }}
              </div>
              <div class="paragraph-2 text-[12px] text-grey-1 my-[10px] flex items-center">
                <div class="w-[70px] text-grey-2 paragraph-2 mr-[20px]">Account</div>
                {{ result.member_id }}
              </div>
              <div class="paragraph-2 text-[12px] my-[10px] flex items-center">
                <div class="w-[70px] text-grey-2 paragraph-2 mr-[20px]">Email</div>
                {{ result.email }}
              </div>
              <div class="paragraph-2 text-[12px] my-[10px] flex items-center">
                <div class="w-[70px] text-grey-2 paragraph-2 mr-[20px]">Dob</div>
                {{ result.dob }}
              </div>
              <div class="paragraph-2 text-[12px] my-[10px] flex items-center">
                <div class="w-[70px] text-grey-2 paragraph-2 mr-[20px]">Status</div>
                Last modified on {{ result.updated_at }}
              </div>
            </button>
          </div>
        </div>
        <div class="m-auto bg-white rounded-b text-left" v-else>
          <div class="text-cfs-blue menu-1 h-[50px] px-[30px] flex items-center">NO USER FOUND</div>
          <!-- Only for CFS-->
          <OtivoButton
            v-if="isCallcentrePortal"
            size="large"
            class="m-auto"
            colour="blue"
            data-test="createUserBtn"
            @click="createUser">
            Create new user
          </OtivoButton>
        </div>
      </div>
    </div>
    <OtivoButton
      v-if="!searchedForClient"
      @click="handleFormSubmit"
      type="submit"
      colour="blue"
      size="large"
      class="my-[40px] w-[200px]"
      data-test="searchBtn"
      :loading="loading">
      Search
    </OtivoButton>
  </div>
</template>

<script lang="ts" setup>
//TODO: refactor - Clean CFS
import { computed, onBeforeMount, ref } from 'vue'
import { useRouter } from 'vue-router'
import OtivoButton from '@/components/OtivoButton.vue'
import GuidanceAccountSearchForm from '@/components/CFS/Guidance/GuidanceAccountSearchForm.vue'
import UserSearchForm from '@/components/AdminPortal/UserSearchForm.vue'
import { ClientSearchParams, ClientSearchResult } from '@/types/CallcentrePortalTypes.ts'
import MagnifyingGlass from '@/components/SVGS/MagnifyingGlass.vue'
import getApiInstance from '@/services/Api.ts'
import { searchForClient } from '@/composables/users/UserSearch.ts'
import { useToast } from '@/composables/useToast.ts'
import { useCallCentrePortalStore } from '@/store/pinia/adminPortal/CallCentrePortalStore.ts'
import { useUserStore } from '@/store/pinia/UserStore.ts'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'

const callCentrePortalStore = useCallCentrePortalStore()

const { errorToast } = useToast()
const api = getApiInstance()
const router = useRouter()
const loading = ref(false)
const memberSearchResult = ref<ClientSearchResult[]>([])
const searchedForClient = ref(false)
const storedSearchValue = ref<ClientSearchParams | null>(null)

const searchForm = ref<InstanceType<typeof GuidanceAccountSearchForm> | typeof UserSearchForm>(null)
const searchFormComponent = ref<typeof GuidanceAccountSearchForm | typeof UserSearchForm>(
  GuidanceAccountSearchForm,
)
const isCallcentrePortal = computed(() => useSessionStore().getWhitelabelData.callcentre_portal)

onBeforeMount(async () => {
  if (isCallcentrePortal.value) searchFormComponent.value = GuidanceAccountSearchForm
  else searchFormComponent.value = UserSearchForm

  const gotClient = await checkForClientData()
  if (gotClient) await router.push({ name: 'clientDetails' })
  // else clientStore.resetSearchMemberResults()
})

const checkForClientData = async (): Promise<boolean> => {
  const clientDataRaw = window.localStorage.getItem('activeManagedUser')
  let clientData: { auth0Id: string } | null = null
  if (clientDataRaw) {
    try {
      clientData = JSON.parse(clientDataRaw)
      if (clientData?.auth0Id) api.setClientHeader(clientData.auth0Id)
    } catch {
      // no client data
    }
  }
  return !!(clientData && clientData.auth0Id)
}

const handleFormSubmit = () => {
  search(searchForm.value?.searchValue)
}

const search = async (searchValue: ClientSearchParams) => {
  loading.value = true
  if (
    searchValue.first_name === '' &&
    searchValue.last_name === '' &&
    searchValue.email === '' &&
    searchValue.member_id === ''
  ) {
    errorToast('Please enter search parameters')
    loading.value = false
  } else {
    try {
      searchValue.whitelabel_id = useSessionStore().getWhitelabelData.id
      memberSearchResult.value = await searchForClient(searchValue)
      storedSearchValue.value = searchForm.value?.searchValue
      searchedForClient.value = true
      loading.value = false
    } catch (e) {
      loading.value = false
      console.error(e)
    }
  }
}

const createUser = () => {
  useUserStore().setUser(storedSearchValue.value)
  router.push({ name: 'manageUser' })
}

const searchHeading = computed(() => {
  const user = isCallcentrePortal.value ? 'member' : 'client'
  if (searchedForClient.value) {
    return 'Search results'
  } else {
    return `Search for ${user}`
  }
})
const selectUser = async (userData: ClientSearchResult) => {
  await callCentrePortalStore.getManagedUserData(userData.auth0id)
  await router.push({ name: 'manageUser' })
}
</script>

<style scoped></style>
