<template>
  <svg viewBox="0 0 40 39" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 26.0153C16.6716 26.0156 16.3463 25.9532 16.0429 25.8317C15.7394 25.7103 15.4636 25.5321 15.2312 25.3075L9.60623 19.8625C9.13713 19.4084 8.8736 18.7925 8.8736 18.1503C8.8736 17.5081 9.13713 16.8923 9.60623 16.4382C10.0753 15.9841 10.7116 15.729 11.375 15.729C12.0384 15.729 12.6746 15.9841 13.1437 16.4382L16.95 20.1226L25.8062 11.0476C26.2621 10.5807 26.8908 10.3082 27.5542 10.29C28.2177 10.2719 28.8613 10.5096 29.3437 10.9508C29.8261 11.3921 30.1076 12.0007 30.1263 12.6429C30.1451 13.2851 29.8995 13.9082 29.4437 14.3751L18.8187 25.2651C18.5885 25.4989 18.3124 25.6857 18.0064 25.8145C17.7005 25.9434 17.3711 26.0116 17.0375 26.0153H17Z"
      :class="`fill-${fill2}`" />
    <path
      d="M20.125 38.72L7.16874 31.8714C5.60907 31.0529 4.30597 29.8429 3.39644 28.3684C2.4869 26.8939 2.00452 25.2095 2 23.4921V4.28945L19.5 0L36.9999 4.28945V23.8067C36.9919 25.4833 36.5293 27.1284 35.6584 28.5776C34.7876 30.0268 33.539 31.2294 32.0375 32.065L20.125 38.72ZM6.99999 8.00414V23.4921C7.00111 24.351 7.2422 25.1936 7.69772 25.9306C8.15324 26.6676 8.80622 27.2717 9.58749 27.6787L20.0687 33.2205L29.5 27.9389C30.2549 27.5234 30.8834 26.9227 31.3223 26.1973C31.7612 25.4719 31.9949 24.6474 32 23.8067V8.00414L19.5 4.94285L6.99999 8.00414Z"
      :class="`fill-${fill1}`" />
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1?: string
  fill2?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red'
})
</script>

<style scoped></style>
