<template>
  <button
    :disabled="disabled"
    @click="$emit('click')"
    :class="`${textClass} ${textColor} hover:${hoverColour} font-bold underline underline-offset-4 inline-flex`">
    <span v-if="text">{{ text }}</span>
    <slot />
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface Props {
  text?: string
  disabled?: boolean
  size?: 'large' | 'medium' | 'small' | 'extra-small'
  colour?: 'blue' | 'red' | 'white' | 'sky'
  typography?: 'button-1' | 'button-2' | 'button-3'
}

const props = withDefaults(defineProps<Props>(), {
  text: '',
  disabled: false,
  size: 'medium',
  colour: 'blue',
  typography: 'button-1'
})

const textClass = computed(
  () =>
    ({
      'extra-small': 'text-xs',
      small: 'text-sm',
      medium: 'text-base',
      large: 'text-lg'
    })[props.size]
)

const textColor = computed(
  () =>
    ({
      blue: 'text-otivo-blue',
      red: 'text-red-1',
      white: 'text-grey-1',
      sky: 'text-blue-3'
    })[props.colour]
)

const hoverColour = computed(
  () =>
    ({
      blue: 'text-blue-1',
      red: 'text-red-1',
      white: 'text-grey-1',
      sky: 'text-blue-3'
    })[props.colour]
)

defineEmits<{ (e: 'click'): void }>()
</script>
