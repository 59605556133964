<template>
  <base-view>
    <div class="bg-otivo-blue text-center text-white">
      <div class="min-h-30vh md:min-h-50vh 2xl:min-h-40vh">
        <h2 class="pt-20">Looks like you’re a little lost</h2>
        <p class="intro-1 py-12 px-6">
          If you need a hand finding something, please check out our
          <a
            href="https://help.otivo.com"
            class="text-white underline cursor-pointer hover:text-blue-1"
            style="text-underline-offset: 8px"
            >Help Centre</a
          >.
        </p>
      </div>
      <img :src="NotFoundVista" alt="Not Found" class="hidden md:block" />
      <img :src="NotFoundVistaMobile" alt="Not Found" class="md:hidden" />
    </div>
  </base-view>
</template>

<script lang="ts" setup>
import BaseView from '../BaseView.vue'
import NotFoundVista from '@/components/Otivo/NotFoundPage/NotFoundVista.svg?url'
import NotFoundVistaMobile from '@/components/Otivo/NotFoundPage/NotFoundVistaMobile.svg?url'
</script>

<style scoped></style>
