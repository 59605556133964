<template>
  <section class="flex flex-col items-center justify-center">
    <span
      v-if="label"
      class="hidden md:flex text-[8px] text-blue-1 text-center menu uppercase font-bold mb-1">
      {{ label }}
    </span>
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="14" fill="none">
      <path
        :fill="index === 1 ? '#0064FF' : '#A0E6FA'"
        d="M14.099 7.029a6.85 6.85 0 1 0-13.699 0 6.85 6.85 0 0 0 13.699 0Z" />
      <path
        fill="#fff"
        d="M9.653 8.495v1.329H5.188V8.495h1.554V5.159a1.16 1.16 0 0 1-.294.274 2.57 2.57 0 0 1-.473.26c-.178.083-.35.144-.52.2a1.548 1.548 0 0 1-.48.075v-1.37c.13 0 .295-.041.48-.124a3.44 3.44 0 0 0 .561-.3c.185-.124.343-.24.48-.35.137-.11.219-.206.246-.267h1.514v4.931h1.39l.007.007Z" />
      <path
        :fill="index === 2 ? '#0064FF' : '#A0E6FA'"
        d="M28.482 7.029a6.85 6.85 0 1 0-13.698 0 6.85 6.85 0 0 0 13.698 0Z" />
      <path
        fill="#fff"
        d="M18.914 9.9c0-.323.027-.624.075-.891s.144-.52.274-.747c.13-.226.308-.445.527-.65.22-.206.5-.398.843-.596.301-.165.562-.308.788-.425a7.93 7.93 0 0 0 .582-.336c.157-.102.28-.205.356-.315a.64.64 0 0 0 .116-.37.763.763 0 0 0-.123-.417.819.819 0 0 0-.342-.302 1.32 1.32 0 0 0-.562-.11c-.171 0-.329.021-.48.07-.15.04-.287.102-.417.184s-.247.171-.356.267c-.11.096-.213.199-.309.302l-.91-1.062c.095-.103.225-.212.397-.336.17-.123.376-.232.616-.342a4.7 4.7 0 0 1 .795-.26 4.01 4.01 0 0 1 .917-.103c.5 0 .918.082 1.274.24.356.157.617.376.809.657.184.281.28.603.28.973 0 .267-.047.5-.143.698-.096.2-.22.377-.37.528-.151.15-.308.287-.48.404-.171.116-.335.22-.493.315-.226.116-.431.226-.616.329-.185.103-.35.198-.48.301-.13.103-.253.206-.363.308a2.08 2.08 0 0 0-.28.357h3.342v1.328H18.9h.014Z" />
      <path
        :fill="index === 3 ? '#0064FF' : '#A0E6FA'"
        d="M42.866 7.029a6.85 6.85 0 1 0-13.699 0 6.85 6.85 0 0 0 13.699 0Z" />
      <path
        fill="#fff"
        d="M37.085 6.543c.315.055.582.172.808.357.226.185.404.417.534.698.13.281.192.596.192.946 0 .452-.116.856-.35 1.198-.232.343-.56.61-.986.795-.424.185-.924.28-1.513.28-.59 0-1.048-.089-1.473-.267a2.775 2.775 0 0 1-1.068-.78l.835-1.028c.192.226.418.411.678.541.26.137.59.206.987.206.445 0 .787-.096 1.02-.281.233-.185.35-.466.35-.836 0-.39-.137-.692-.405-.904-.267-.212-.678-.315-1.226-.315h-.308v-1.11h.35c.445 0 .787-.102 1.034-.308a.973.973 0 0 0 .37-.78.9.9 0 0 0-.13-.507.74.74 0 0 0-.384-.302 1.549 1.549 0 0 0-.582-.103c-.336 0-.637.076-.904.227-.268.15-.487.37-.651.65l-.918-1.027c.15-.22.363-.418.637-.59.267-.17.582-.3.925-.396.349-.09.705-.137 1.082-.137.5 0 .931.082 1.308.246.377.164.671.39.877.685.205.295.315.637.315 1.041 0 .288-.055.562-.171.815a1.746 1.746 0 0 1-.487.644 1.775 1.775 0 0 1-.733.35l-.013-.008Z" />
      <path
        :fill="index === 4 ? '#0064FF' : '#A0E6FA'"
        d="M57.25 7.029a6.85 6.85 0 1 0-13.7 0 6.85 6.85 0 0 0 13.7 0Z" />
      <path
        fill="#fff"
        d="M50.647 10.61V8.98h-3.2v-1.3l3.672-4.713h1v4.698h.966V8.98h-.966v1.63h-1.472Zm-1.733-2.945h1.897V5.18l-1.897 2.486Z" />
      <path
        :fill="index === 5 ? '#0064FF' : '#A0E6FA'"
        d="M71.633 7.029a6.85 6.85 0 1 0-13.699 0 6.85 6.85 0 0 0 13.699 0Z" />
      <path
        fill="#fff"
        d="M64.564 10.748a3.189 3.189 0 0 1-1.513-.363 2.463 2.463 0 0 1-1.028-.986l.842-.89c.206.294.46.52.76.69.302.172.624.254.96.254.26 0 .5-.054.698-.164.199-.11.363-.26.48-.459.116-.199.171-.425.171-.678 0-.253-.055-.48-.164-.671a1.175 1.175 0 0 0-.452-.446 1.337 1.337 0 0 0-.644-.157c-.24 0-.466.055-.672.164-.205.11-.376.26-.5.452h-1.294l.897-4.637h3.85v1.336H64.18l-.309 1.74a.931.931 0 0 1 .377-.226 1.82 1.82 0 0 1 .617-.096c.5 0 .938.11 1.328.315.39.212.692.507.911.883.22.377.329.809.329 1.302 0 .52-.123.972-.37 1.37-.246.397-.589.705-1.02.924-.432.226-.925.336-1.48.336v.007Z" />
    </svg>
    <div class="hidden md:flex justify-between w-22 text-blue-2 text-xs">
      <div>Low</div>
      <div>High</div>
    </div>
  </section>
</template>

<script setup lang="ts">
type Props = {
  index: number
  label: string
}
withDefaults(defineProps<Props>(), {
  index: 3,
  label: 'Balanced',
})
</script>

<style scoped></style>
