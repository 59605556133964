import '@/assets/css/animate.scss'
import '@/assets/css/borderAnimation.css'
import '@/assets/css/responsive.scss'
import '@/assets/css/tailwind.scss'
import 'nprogress/nprogress.css'
import 'vue-toast-notification/dist/theme-bootstrap.css'
import 'floating-vue/dist/style.css'
import FloatingVue from 'floating-vue'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/vue'
import { createApp } from 'vue'
import type { App } from 'vue'
import { store } from '@/store'
import globalConstants from '@/GlobalConstants/globalConstants'
import { isAuthenticated, provideObject } from '@/lib/AuthenticatorPlugin'
import { createPinia } from 'pinia'
import { useSessionStore } from '@/store/pinia/SessionStore'
import { createHead } from 'unhead'
import { setupInstance, getEntry } from '@/instances.ts'
import getBackendStatus from '@/composables/getBackendStatus.ts'
import { WhitelabelDataType } from '@/types/WhiteLabelTypes.ts'
import { LDPlugin, LDPluginOptions } from 'launchdarkly-vue-client-sdk'
import Vue3Toastify, { ToastContainerOptions } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import './assets/css/toasts.scss'
import { createLDContext } from '@/composables/createLDContext.ts'
import ParseJWT from '@/composables/decryptWBGJWT.ts'
import { checkUserSymbol, provideObject as checkUserObject } from '@/composables/users/checkUser.ts'
import api from '@/services/Api.ts'

const pinia = createPinia()
const auth0Injection = provideObject()
const head = createHead()
;(async () => {
  const backendStatus = await getBackendStatus()

  /**
   * Removing this for the time being to remove a flicker on the maintenance page
   */
  // Force Maintenance if the backend dies
  // let app: App
  // if (!backendStatus) {
  //   app = createApp(Maintenance)
  //   app.mount('#app')
  //   return
  // }

  const whitelabelName = backendStatus.whitelabel.name
  const adminPortalEnabled =
    backendStatus.whitelabel.callcentre_portal || backendStatus.whitelabel.admin_portal

  // Init frontend
  const app = createApp(getEntry(backendStatus.whitelabel.frontend_config.entry))
  // Create Auth0 instance
  const realm = backendStatus.whitelabel.superfund_config?.users_realm
  const continueRedirectPath = window.location.pathname ?? '/'

  // note cant remember why this was removed in hydra, adding it back for now
  // check if we're impersonating then set or continue
  // getApiInstance().setImpersonationHeader()

  app.use(pinia)

  app.use(head)

  const instance = setupInstance(backendStatus.whitelabel.frontend_config, adminPortalEnabled)
  const redirectUrl = await auth0Injection.auth0.createClient(
    instance.AUTH0_ID,
    realm,
    continueRedirectPath,
  )
  app.use(instance.ROUTER)
  app.use(store)

  // Inject Auth0 instance
  app.provide(auth0Injection.Auth0Symbol, auth0Injection.auth0)
  app.provide(checkUserSymbol, checkUserObject)

  // set global constants
  app.config.globalProperties.globalConstants = globalConstants()

  // save whitelabel data to session
  const sessionStore = useSessionStore()
  sessionStore.setWhitelabelData({
    whitelabel: backendStatus.whitelabel as WhitelabelDataType,
  })

  const params = new URLSearchParams(window.location.search)

  // scrape WBG token if it exists
  if (params.has('token')) {
    try {
      const token = await ParseJWT(params.get('token'), import.meta.env.VITE_WBGK)
      localStorage.setItem('wbg_details', JSON.stringify(token))
      history.pushState({}, '', continueRedirectPath)
    } catch (e) {
      history.pushState({}, '', continueRedirectPath)
      console.error(e)
    }
  }

  // Directives and Component Plugins

  app.use(FloatingVue, {
    themes: {
      tooltip: {
        triggers: ['hover', 'focus', 'click', 'touch'],
      },
    },
  })

  app.use(Vue3Toastify, {
    theme: 'colored',
    position: 'top-center',
    clearOnUrlChange: false,
  } as ToastContainerOptions)

  const sentryDns = instance.SENTRY_ID
  if (sentryDns) {
    Sentry.init({
      app,
      dsn: 'https://ab93577133194a0db206614ae4b9b40d@o293201.ingest.us.sentry.io/5931210',
      environment: import.meta.env.VITE_SENTRY_PROJECT,
      tracePropagationTargets: ['*.127.0.0.1:8080', '*.otivo.com', '*.otivo.dev', /^\//],
      trackComponents: true,
      tracesSampleRate: 1,
      integrations: [Sentry.browserTracingIntegration({ router: instance.ROUTER })],
    })
  }
  api().setSentryTraceHeader()

  const LDContext = await createLDContext(whitelabelName)
  const pluginOptions: LDPluginOptions = {
    clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID,
    context: LDContext,
  }
  app.use(LDPlugin, pluginOptions)
  app.mount('#app')
})()
