<template>
  <BaseView>
    <div
      class="bg-white shadow-lg rounded flex flex-col"
      v-if="pageContent.currentPage === 'mediaPage'">
      <Breadcrumbs class="bg-blue-5" />
      <MediaTitleComponent
        :media-title-content="pageContent.mediaHero"
        class="bg-blue-5 pt-[80px]" />
      <MediaTiles
        :media-tiles-content="pageContent.mediaTiles"
        class="bg-otivo-blue py-16 md:pt-0 px-[20px] md:px-0" />
      <MediaLinks v-if="false" class="pb-[60px] pt-[4px]" />
      <MediaArticles :media-articles-content="pageContent.mediaArticles" class="py-16 px-[20px]" />
      <div>
        <h6 class="heading-6 text-blue-2 text-center mt-[20px]">AS SEEN IN</h6>
        <MediaLogos
          fill="blue-2"
          id="media-logos"
          class="mx-auto justify-center flex mt-[20px] mb-[60px] px-[20px]" />
      </div>
      <ThreeTileDisplay
        class="bg-blue-5"
        :icon-boxes="pageContent.threeTileDisplay.iterableItems" />
    </div>
  </BaseView>
</template>

<script lang="ts" setup>
import BaseView from '@/views/BaseView.vue'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import MediaArticles from '@/components/BrandComponents/Otivo/Media/MediaArticles.vue'
import MediaLinks from '@/components/BrandComponents/Otivo/Media/MediaLinks.vue'
import MediaTiles from '@/components/BrandComponents/Otivo/Media/MediaTiles.vue'
import MediaTitleComponent from '@/components/BrandComponents/Otivo/Media/MediaTitleComponent.vue'
import ThreeTileDisplay from '@/components/BrandComponents/Otivo/ThreeTileDisplay.vue'
import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import MediaLogos from '@/components/SVGS/MediaLogos.vue'
import { useHead } from '@unhead/vue'

const store = useStore()

onBeforeMount(() => store.dispatch('ContentStore/getContent', 'mediaPage'))
useHead({
  title: 'Otivo is making news',
})
const pageContent = computed(() => store.getters['ContentStore/pageContent'])
</script>
