<template>
  <medi-bank-division
    :label="label"
    :question-number="questionNumber"
    :selected="mediBankDivision"
    @click="setFitnessTestMediBankDivision" />
</template>

<script>
import MediBankDivision from '../../Medibank/MediBankDivision'
import { mapState } from 'vuex'
export default {
  name: 'MedibankDivisionQuestion',
  components: { MediBankDivision },
  props: {
    questionNumber: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState({
      mediBankDivision: (state) => state.fitnessTest.questions.mediBankDivision
    })
  },
  methods: {
    setFitnessTestMediBankDivision(value) {
      this.$store.dispatch('setFitnessTestMediBankDivision', value)
    }
  }
}
</script>

<style scoped></style>
