<template>
  <div id="media-links" class="flex justify-center gap-8 bg-otivo-blue">
    <OtivoButton colour="white" @click="redirectAndScroll"> Media inquiries </OtivoButton>
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router'
import OtivoButton from '@/components/OtivoButton.vue'

const route = useRouter()
const redirectAndScroll = () => {
  route.push({ name: 'contactUs' })
  window.scrollTo(0, 0)
}
</script>

<style scoped></style>
