<template>
  <!--    negative margin on flag divs for design alignment on mobile-->
  <div
    v-if="flag === 'current' || flag === 'both'"
    id="current-investment-flag"
    class="flex flex-col items-center mt-[-21px]">
    <div class="rounded-[2px] py-[2px] px-[4px] text-white w-fit flex bg-blue-1">
      <p class="button-4 flex self-center">CURRENT INVESTMENT</p>
    </div>
    <div
      class="h-[13px] w-0 stroke-width-[2px] border-l border-r flex self-center border-blue-1 border-dashed" />
  </div>
  <div
    v-if="flag === 'recommended' || flag === 'both'"
    id="recommended-investment-flag"
    :class="flag === 'both' ? 'mt-[-7px]' : 'mt-[-21px]'"
    class="flex flex-col items-center">
    <div class="rounded-[2px] py-[2px] px-[4px] text-white w-fit flex bg-otivo-red">
      <p class="button-4 flex self-center">RECOMMENDED</p>
    </div>
    <div
      :class="{ 'h-[21px]': flag === 'both' }"
      class="h-[7px] w-0 stroke-width-[2px] border-l border-r flex self-center border-otivo-red border-dashed" />
  </div>
</template>

<script lang="ts" setup>
type Props = {
  flag: 'current' | 'recommended' | 'both' | null
}
defineProps<Props>()
</script>
