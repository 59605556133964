import { ref } from 'vue'
import { ISessionStore, useSessionStore } from '@/store/pinia/SessionStore.ts'

let instance: InactivityTimerType | null = null
let animationFrameId: number | null = null

type InactivityTimerType = {
  start: (durationInMinutes: number) => void
  stop: () => void
  timerRef: NodeJS.Timeout | null
}

const createInactivityTimer = (sessionStore?: ISessionStore): InactivityTimerType => {
  const durationInMinutes = ref(20) // default 20 minutes
  const timer = ref<NodeJS.Timeout | null>(null)
  const throttleDuration = 120000 // 2 minutes
  const resettable = ref(false)
  const resetTimer = () => {
    if (timer.value) {
      clearTimeout(timer.value)
      timer.value = null
      resettable.value = false
      cancelAnimationFrame(animationFrameId as number)
      animationFrameId = null
      throttledReset()
    }

    timer.value = setTimeout(
      () => {
        try {
          sessionStore?.toggleTimeoutModal(true)
        } catch (e) {
          console.error('Error toggling timeout modal:', e)
        }
      },
      durationInMinutes.value * 60 * 1000,
    )
  }

  const handleUserInput = () => {
    if (resettable.value) {
      resetTimer()
    }
  }

  const throttledReset = () => {
    let startTimestamp = 0
    const rafCallback = (timestamp: number) => {
      // requestAnimationFrame() passes the timestamp to the callback automagically
      if (!startTimestamp) {
        startTimestamp = timestamp
      }

      const elapsedTime = timestamp - startTimestamp
      if (elapsedTime > throttleDuration) {
        resettable.value = true
      } else {
        animationFrameId = requestAnimationFrame(rafCallback)
      }
    }

    if (animationFrameId === null) animationFrameId = requestAnimationFrame(rafCallback)
  }

  const attachListeners = () => {
    window.addEventListener('mousemove', handleUserInput)
    window.addEventListener('keydown', handleUserInput)
    window.addEventListener('touchstart', handleUserInput)

    throttledReset()
  }

  const detachListeners = () => {
    window.removeEventListener('mousemove', handleUserInput)
    window.removeEventListener('keydown', handleUserInput)
    window.removeEventListener('touchstart', handleUserInput)
    if (animationFrameId !== null) {
      cancelAnimationFrame(animationFrameId)
      animationFrameId = null
    }
  }

  const start = (minutes: number) => {
    durationInMinutes.value = minutes
    resetTimer()
    attachListeners()
  }

  const stop = () => {
    detachListeners()
    if (timer.value) {
      clearTimeout(timer.value)
      timer.value = null
    }
  }

  instance = {
    start,
    stop,
    timerRef: timer.value,
  }

  return instance
}

const getInstance = (sessionStore?: ISessionStore) => {
  if (!instance) {
    return createInactivityTimer(sessionStore)
  }
  return instance
}

const useInactivityTimer = (sessionStore?: ISessionStore) => getInstance(sessionStore)
export default useInactivityTimer
