<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8025 15.9969h-2.53l-.0225-.9698c-.0026-.0846-.0375-.1651-.0975-.2249-.0304-.0308-.0666-.0553-.1065-.072-.0399-.0168-.08273-.0254-.126-.0255H6.75c-.04327.0001-.0861.0087-.12601.0255-.03991.0167-.0761.0412-.10649.072-.05998.0598-.09485.1403-.0975.2249l-.035.9698h-2.53v-2.2495c-1.02314-.6987-1.79512-1.707-2.2025-2.8769H0V7.02877h1.6525c.408-1.16954 1.17985-2.17773 2.2025-2.87691V1.11499L5.5 2.5322c.24619.20922.44988.46378.6.74984.30714-.05524.61804-.08699.93-.09498h1.25l.1825.76984c.25979 1.10767.90572 2.0866 1.822 2.76128.9162.67469 2.0429 1.00101 3.178.92047.2278-.01434.4542-.04525.6775-.09249l1.125-.24994.085 1.19725c.0832 1.02187-.1084 2.04743-.555 2.97033-.4466.9229-1.1319 1.7096-1.985 2.2786l-.0075 2.2545ZM6.75 12.7051h3.165c.3045-.0007.6061.0588.8875.175v-.1875l.8875-.5949c.5593-.3744 1.0075-.8924 1.2975-1.4997.1447-.3035.2497-.62442.3125-.95477-1.4171.03678-2.8102-.37012-3.98456-1.16379C8.14104 7.68577 7.24409 6.545 6.75 5.21664c-.095 0-.19.0225-.285.04l-.93.16996-.5575.37992c-.66836.45513-1.1724 1.11331-1.4375 1.87712L3.1 8.95587l.4425 1.27223c.26558.7642.76945 1.4231 1.4375 1.8796l.875.5949v.1775c.28376-.1172.58801-.1767.895-.175Z"
      :class="`fill-${fill1}`" />
    <path
      d="M13 5.99876c-.5933 0-1.1734-.17591-1.6667-.50548-.4934-.32958-.8779-.79802-1.1049-1.34608-.2271-.54807-.28651-1.15114-.1708-1.73297.1158-.58182.4015-1.11626.8211-1.53573.4195-.419471.9541-.705135 1.536-.8208664.582-.1157318 1.1852-.05633401 1.7334.1706824.5481.227016 1.0167.611454 1.3463 1.104704.3297.49324.5056 1.07314.5056 1.66636 0 .79549-.3161 1.55839-.8787 2.12089-.5626.56249-1.3256.87849-2.1213.87849Zm0-3.99917c-.1978 0-.3911.05864-.5556.16849-.1644.10986-.2926.26601-.3683.4487-.0757.18269-.0955.38371-.0569.57765s.1338.37209.2737.51191c.1398.13983.318.23505.512.27363.194.03857.3951.01877.5778-.0569.1827-.07567.3389-.20382.4488-.36823.1099-.16442.1685-.35772.1685-.55546 0-.26516-.1054-.51946-.2929-.70696s-.4419-.29283-.7071-.29283Z"
      :class="`fill-${fill2}`" />
  </svg>
</template>
<script setup lang="ts">
interface Props {
  fill1: string
  fill2: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red',
})
</script>

<style scoped></style>
