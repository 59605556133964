import { IFinancialWellBeingCheckIn } from '@/types/globalComponents/IFinancialWellBeingCheckIn'
import getApiInstance from '@/services/Api'
const api = getApiInstance()

export default {
  getLastCheckIn() {
    return api.Get('/financial_well_being_check_in')
  },
  submitCheckIn(payload: IFinancialWellBeingCheckIn) {
    return api.Post('/financial_well_being_check_in', payload)
  }
}
