<template>
  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.145 12.5H1.855C0.8325 12.5 0 11.6675 0 10.645V1.855C0 0.8325 0.8325 0 1.855 0H14.145C15.1675 0 16 0.8325 16 1.855V10.645C16 11.6675 15.1675 12.5 14.145 12.5ZM2 10.5H14V2H2V10.5Z"
      fill="#0064FF" />
    <path
      d="M13.25 16H2.75C2.1975 16 1.75 15.5525 1.75 15C1.75 14.4475 2.1975 14 2.75 14H13.25C13.8025 14 14.25 14.4475 14.25 15C14.25 15.5525 13.8025 16 13.25 16Z"
      fill="#0064FF" />
    <path
      d="M6.11978 9.99969C6.09978 9.99969 6.08228 9.99969 6.06228 9.99969C5.82978 9.98719 5.60728 9.92469 5.41228 9.81469C5.19228 9.69469 5.00478 9.51219 4.87728 9.29219C4.74728 9.07219 4.68228 8.81969 4.68728 8.56469V3.91719C4.68228 3.67719 4.74978 3.42469 4.87728 3.20469C5.00728 2.98469 5.19228 2.80219 5.41728 2.67969C5.80728 2.45469 6.38728 2.44219 6.81728 2.69219L6.83978 2.70469L10.6798 5.05969C10.8723 5.18969 11.0323 5.36469 11.1423 5.56969C11.2523 5.77469 11.3123 6.00469 11.3148 6.23719C11.3173 6.48219 11.2573 6.72219 11.1423 6.93219C11.0273 7.14469 10.8598 7.32469 10.6548 7.45469L6.84978 9.78469C6.63228 9.92469 6.37478 10.0022 6.11728 10.0022L6.11978 9.99969ZM6.68478 8.56469V8.57969C6.68478 8.57969 6.68478 8.56969 6.68478 8.56469ZM6.68478 4.95469V7.53969L8.79478 6.24719L6.68478 4.95469ZM6.68478 3.90219V3.91969C6.68478 3.91969 6.68478 3.90719 6.68478 3.90219Z"
      fill="#FF0000" />
  </svg>
</template>

<script>
export default {
  name: 'BroadcastIcon'
}
</script>

<style scoped></style>
