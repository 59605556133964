<template>
  <div class="flex">
    <div
      class="ownIcon border rounded p-2 flex flex-col h-28 w-28 m-2 border opacity-50 cursor-pointer rounded lg:hover:border-otivo-blue"
      :class="{ selected: selected === 'own' }"
      @click="$emit('click', 'own')">
      <svg class="flex-1 m-auto w-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 32">
        <path
          fill="none"
          fill-rule="nonzero"
          stroke="#99999F"
          stroke-width="1.5"
          d="M31.525 27.349H7.475a.922.922 0 0 0-.925.913v1.825c0 .502.416.913.925.913h24.05c.509 0 .925-.41.925-.913v-1.825a.922.922 0 0 0-.925-.913zm3.7-19.046c-1.532 0-2.775 1.226-2.775 2.738 0 .405.093.782.254 1.13l-4.185 2.476c-.89.525-2.041.228-2.556-.662l-4.711-8.136a2.713 2.713 0 0 0 1.023-2.11C22.275 2.226 21.032 1 19.5 1c-1.532 0-2.775 1.227-2.775 2.738 0 .856.405 1.61 1.023 2.111l-4.711 8.136a1.862 1.862 0 0 1-2.556.662l-4.18-2.476c.156-.343.255-.725.255-1.13 0-1.512-1.243-2.738-2.775-2.738C2.249 8.303 1 9.529 1 11.04s1.243 2.739 2.775 2.739c.15 0 .3-.023.445-.046l4.18 11h22.2l4.18-11c.144.023.295.046.445.046 1.532 0 2.775-1.227 2.775-2.739 0-1.512-1.243-2.738-2.775-2.738z" />
      </svg>
      <div class="flex-1 flex">
        <div class="self-center text-center w-full">I own outright</div>
      </div>
    </div>

    <div
      class="rentingIcon border rounded p-2 flex flex-col h-28 w-28 m-2 border opacity-50 cursor-pointer rounded lg:hover:border-otivo-blue"
      :class="{ selected: selected === 'rent' }"
      @click="$emit('click', 'rent')">
      <svg class="flex-1 m-auto w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 30">
        <path
          fill="none"
          fill-rule="nonzero"
          stroke="#99999F"
          stroke-width="1.5"
          d="M1 26.375C1 27.825 2.2 29 3.679 29H23.32C24.801 29 26 27.824 26 26.375V11.5H1v14.875zm17.857-10.719c0-.36.301-.656.67-.656h2.232c.368 0 .67.295.67.656v2.188c0 .36-.302.656-.67.656h-2.232a.665.665 0 0 1-.67-.656v-2.188zm0 7c0-.36.301-.656.67-.656h2.232c.368 0 .67.295.67.656v2.188c0 .36-.302.656-.67.656h-2.232a.665.665 0 0 1-.67-.656v-2.188zm-7.143-7c0-.36.302-.656.67-.656h2.232c.368 0 .67.295.67.656v2.188c0 .36-.302.656-.67.656h-2.232a.665.665 0 0 1-.67-.656v-2.188zm0 7c0-.36.302-.656.67-.656h2.232c.368 0 .67.295.67.656v2.188c0 .36-.302.656-.67.656h-2.232a.665.665 0 0 1-.67-.656v-2.188zm-7.143 0c0-.36.302-.656.67-.656h2.232c.369 0 .67.295.67.656v2.188c0 .36-.301.656-.67.656H5.241a.665.665 0 0 1-.67-.656v-2.188zm18.75-18.475h-2.678V1.795c0-.437-.402-.795-.893-.795h-1.786c-.49 0-.893.358-.893.795v2.386H9.93V1.795c0-.437-.402-.795-.893-.795H7.25c-.491 0-.893.358-.893.795v2.386H3.68C2.199 4.18 1 5.249 1 6.566v2.386h25V6.566c0-1.317-1.2-2.385-2.679-2.385z" />
      </svg>
      <div class="flex-1 flex">
        <div class="self-center text-center w-full">I am renting</div>
      </div>
    </div>

    <div
      class="mortgageIcon border rounded p-2 flex flex-col h-28 w-28 m-2 border opacity-50 cursor-pointer rounded lg:hover:border-otivo-blue"
      :class="{ selected: selected === 'mortgage' }"
      @click="$emit('click', 'mortgage')">
      <svg class="flex-1 m-auto w-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 31">
        <path
          fill="#99999F"
          fill-rule="nonzero"
          d="M32.753 12.873L16.906.143a.65.65 0 0 0-.814 0L.246 12.873a.658.658 0 0 0-.077.955l3.163 3.505a.639.639 0 0 0 .883.07l.991-.805v13.776c0 .363.31.626.67.626h21.247c.36 0 .687-.263.687-.626V16.598l.983.806a.625.625 0 0 0 .4.144.64.64 0 0 0 .478-.215l3.16-3.504a.66.66 0 0 0-.078-.956zM19.976 29.686H13.04v-8.295h6.936v8.295zm6.529 0h-5.223v-8.945a.663.663 0 0 0-.654-.664H12.37a.651.651 0 0 0-.636.664v8.945H6.511v-14.14l9.996-8.04 9.997 8.04v14.14zm2.608-13.694l-12.205-9.84a.65.65 0 0 0-.816 0l-12.206 9.84-2.282-2.529L16.499 1.498l14.896 11.965-2.282 2.53z" />
      </svg>
      <div class="flex-1 flex">
        <div class="self-center text-center w-full">I have a mortgage</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LivingSituationAnswer',
  props: {
    selected: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.selected svg * {
  @apply fill-otivo-blue stroke-otivo-blue;
}
</style>
