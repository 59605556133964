<template>
  <div>
    <loader />
    <test-button />
  </div>
</template>

<script>
import Loader from '@/components/Loader/Loader.vue'
import getApiInstance from '@/services/Api'

export default {
  name: 'RedirectToCompany',
  components: { Loader },
  created() {
    if (this.$route.params.company) {
      let url = location.host.split('.')
      url[0] = this.$route.params.company
      url = url.join('.')
      const api = getApiInstance()
      api.Get(`/company/${url}`).then((res) => {
        if (res.data.present) {
          window.location.replace(`https://${url}`)
        } else {
          this.$router.push({ name: 'home' })
        }
      })
    } else {
      this.$router.push({ name: 'home' })
    }
  },
}
</script>

<style scoped></style>
