<template>
  <div class="pb-4 font-bold">
    <label>
      <span v-if="questionNumber !== ''">{{ questionNumber }}.</span> {{ question }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'FitnessTestQuestionLabel',
  props: {
    questionNumber: {
      type: String,
      default: ''
    },
    question: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped></style>
