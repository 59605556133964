<template>
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.11006 7.78003H3.06006V10.59H5.11006V7.78003Z"
      :class="`fill-${fill2}`" />
    <path
      d="M8.45991 3.11011H6.40991V10.2701H8.45991V3.11011Z"
      :class="`fill-${fill2}`" />
    <path d="M11.8 5.37012H9.75V10.6701H11.8V5.37012Z" :class="`fill-${fill2}`" />
    <path
      d="M12.8 2.05005V9.75006H2.05V2.05005H12.78H12.8ZM12.78 0.0500488H2.07001C1.52101 0.0500488 0.994493 0.268111 0.606293 0.656311C0.218092 1.04451 0 1.57106 0 2.12006V9.82007C0.00264382 10.3673 0.221903 10.8912 0.609818 11.2773C0.997732 11.6633 1.52274 11.8801 2.07001 11.8801H12.8C13.3455 11.8774 13.868 11.6596 14.2537 11.2738C14.6395 10.888 14.8574 10.3656 14.86 9.82007V2.12006C14.86 1.57279 14.6433 1.04777 14.2573 0.659851C13.8712 0.271937 13.3473 0.0526926 12.8 0.0500488H12.78Z"
      :class="`fill-${fill1}`" />
    <path
      d="M6.3801 11.1401H4.3501V14.8801H6.3801V11.1401Z"
      :class="`fill-${fill1}`" />
    <path
      d="M10.51 11.1401H8.47998V15.2401H10.51V11.1401Z"
      :class="`fill-${fill1}`" />
    <path
      d="M12.0401 13.9501H2.82007V16.0001H12.0401V13.9501Z"
      :class="`fill-${fill1}`" />
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1?: string
  fill2?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red',
})
</script>

<style scoped></style>
