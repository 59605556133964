<template>
  <svg width="42" height="43" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.2842 17.5924C41.2842 17.5924 41.4155 17.3824 41.468 17.2512C41.5205 17.1199 41.468 17.2512 41.468 17.2512C41.5322 17.0978 41.5848 16.9399 41.6255 16.7787C41.6131 16.7358 41.6131 16.6903 41.6255 16.6475C41.6255 16.6475 41.6255 16.3849 41.6255 16.2537C41.6511 16.0884 41.6511 15.9202 41.6255 15.7549V15.7549C41.6255 15.7549 41.6255 15.4923 41.4942 15.3611V15.2036L35.903 4.7036C35.651 4.23206 35.2709 3.84138 34.8064 3.57663C34.3419 3.31188 33.8121 3.18384 33.278 3.20733H8.07799C7.57041 3.21298 7.07364 3.35493 6.6396 3.61812C6.20555 3.88132 5.85015 4.25613 5.61048 4.7036L0.0192188 15.2036V15.3611V15.7549C-0.00640627 15.9202 -0.00640627 16.0884 0.0192188 16.2537V16.2537C0.00623483 16.3846 0.00623483 16.5165 0.0192188 16.6475C0.0316155 16.6903 0.0316155 16.7358 0.0192188 16.7787C0.047951 16.9431 0.101028 17.1024 0.176712 17.2512C0.176712 17.2512 0.176732 17.4874 0.360482 17.5924L18.578 40.0099L18.893 40.3249L19.3655 40.6136L19.8117 40.745H19.943H20.4942H21.0717L21.518 40.6136L21.9905 40.3249L22.3055 40.0099L40.523 17.5924H41.2842ZM11.018 18.9049L12.9342 24.4699L8.39297 18.9049H11.018ZM16.9505 18.9049H24.4842L20.7042 29.8774L16.9505 18.9049ZM30.4167 18.9049H33.0417L28.448 24.4699L30.4167 18.9049ZM34.2492 13.2874H30.3117L28.448 8.40494H31.7292L34.2492 13.2874ZM22.3317 8.40494L24.2742 13.2874H17.1605L19.103 8.40494H22.3317ZM9.78422 8.40494H13.0917L11.123 13.2874H7.18546L9.78422 8.40494Z"
      fill="#0064FF"
      :class="`fill-${fill1}`" />
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue'
})
</script>

<style scoped></style>
