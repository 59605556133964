<template>
  <div class="bg-midnight w-full relative z-40" v-if="navBar.showNavBar">
    <div class="h-navBarHeight m-auto z-50 flex flex-col">
      <nav class="z-50 h-full" id="nav">
        <!-- Start top bar -->
        <TopNavBar class="h-full text-white" />
      </nav>
      <!-- Main side bar menu -->
    </div>
    <div class="absolute w-full md:w-auto inset-x-0 left-auto min-h-auto h-auto">
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeInRight"
        leave-active-class="animated fadeOutRight">
        <NavSideBar />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import NavSideBar from '@/components/NavBar/NavSideBar.vue'
import TopNavBar from '@/components/NavBar/TopNavBar/TopNavBar.vue'
export default {
  name: 'NavBar',
  computed: {
    ...mapGetters(['authenticated']),
    ...mapState({
      navBar: (state) => state.navBar,
    }),
  },
  components: {
    TopNavBar,
    NavSideBar,
  },
  methods: {
    ...mapActions(['logout', 'toggleSideBar']),
    getClassList() {
      return {
        enabled: this.authenticated,
        hidden: !this.authenticated,
      }
    },
  },

  mounted: function () {
    // vue does not control body tag so need to add event listener for the modal
    document.body.addEventListener('keyup', (e) => {
      if (e.key === 'Escape' && this.navBar.opened) {
        this.toggleSideBar()
      }
    })
  },
}
</script>

<style scoped></style>
