<template>
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 16.2444C14.745 16.2444 14.49 16.1469 14.2925 15.9519L11.23 12.8894C10.84 12.4994 10.8375 11.8644 11.23 11.4719C11.62 11.0794 12.2525 11.0794 12.645 11.4719L15.7075 14.5344C16.0975 14.9244 16.1 15.5594 15.7075 15.9519C15.5125 16.1494 15.255 16.2469 15 16.2469V16.2444Z"
      :class="`fill-${fill2}`" />
    <path
      d="M6.9575 14.157C5.175 14.157 3.395 13.4795 2.0375 12.122C0.725 10.807 0 9.05949 0 7.19949C0 5.33949 0.725 3.59199 2.04 2.27949C4.7525 -0.433008 9.165 -0.433008 11.8775 2.27949C13.1925 3.59449 13.915 5.34199 13.915 7.20199C13.915 9.06199 13.19 10.8095 11.8775 12.1245C10.5225 13.4795 8.74 14.1595 6.9575 14.1595V14.157ZM6.9575 2.24949C5.6875 2.24949 4.4175 2.73199 3.45 3.69949C2.515 4.63449 2 5.87949 2 7.20199C2 8.52449 2.515 9.76949 3.45 10.7045C5.3825 12.637 8.53 12.637 10.4625 10.7045C11.3975 9.76949 11.9125 8.52449 11.9125 7.20199C11.9125 5.87949 11.3975 4.63449 10.4625 3.69949C9.495 2.73199 8.225 2.24949 6.955 2.24949H6.9575Z"
      :class="`fill-${fill1}`" />
  </svg>
</template>
<script setup lang="ts">
interface Props {
  fill1: string
  fill2: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red',
})
</script>

<style scoped></style>
