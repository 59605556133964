import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import {
  CFSProduct,
  SuperAllocation,
  ClientSuperObject,
  SuperFundAllocationNameType,
} from '@/types/CallcentrePortalTypes.ts'
import { GuidanceClientSuperObjectTemplate } from '@/lib/IntrafundGuidanceHelper'
import { FieldValuePairType } from '@/types/GlobalTypes'
import CfsService from '@/services/CfsService'
import { useIntrafundAdviceStore } from '@/store/pinia/IntrafundAdviceStore'
import { mod10Checksum } from '@/utilities'
import OptimizeAdviceService from '@/services/OptimizeAdviceService'
import SuperAllocationTemplate from '@/composables/superHelpers/superAllocationTemplate.ts'

export const useGuidanceSuperStore = defineStore('GuidanceSuperStore', () => {
  const intrafundAdviceStore = useIntrafundAdviceStore()
  const superIndex = ref(0)
  const supersArray = ref<Array<ClientSuperObject>>([] as Array<ClientSuperObject>)

  /**
   * TODO: convert from cfs to get current whitelabel client super products
   */
  const cfsProducts = ref<Array<CFSProduct>>([
    {
      cfsCode: '011',
      chantWestProductId: 780,
      fundName: 'CFS FirstChoice Wholesale Personal Super',
    },
    { cfsCode: '051', chantWestProductId: 76, fundName: 'CFS FirstChoice Wholesale Pension' },
    {
      cfsCode: '065',
      chantWestProductId: 777,
      fundName: 'CFS FirstChoice Employer Super',
    },
  ])
  const clientSuperObject = ref<ClientSuperObject>(GuidanceClientSuperObjectTemplate())
  const allocationItems = ref<Array<SuperFundAllocationNameType>>([])

  const getClientSuperObject = computed(() => clientSuperObject.value)
  const getAllocationItems = computed(() => allocationItems.value)
  const getSuperIndex = computed(() => superIndex.value)
  const getSupersArray = computed(() => supersArray.value)

  const setSupersArray = (payload: Array<ClientSuperObject>) => {
    supersArray.value = payload
  }
  const updateSupersArrayByIndex = (payload: ClientSuperObject) => {
    supersArray.value[superIndex.value] = payload
  }
  const setSuperIndex = (value: number) => {
    superIndex.value = value
  }
  const setClientSuperObject = async (payload: ClientSuperObject) => {
    // Set full object
    clientSuperObject.value = payload
  }
  const getClientSuper = (superId: string) => {
    // Get the super object from the API
    if (superId !== null) {
      return CfsService.getModularisedSuper(superId)
        .then((res) => {
          const payload = res.data.data
          if (payload.allocation === null || payload.allocation?.length <= 0) {
            payload.allocation = [SuperAllocationTemplate()]
          }
          payload.fundName = payload.fundName.length > 0 ? payload.fundName : '-'
          payload.noInvestmentOptions = 0
          // Account number is initially set on the account details page so, we don't want to overwrite it
          if (payload.accountNumber === null && clientSuperObject.value.accountNumber !== null) {
            payload.accountNumber = clientSuperObject.value.accountNumber
          }
          return setClientSuperObject(payload)
        })
        .finally(() => {
          intrafundAdviceStore.setSuperWidgetFieldsLoading(false)
        })
    }
  }
  const addSuperFundAllocation = (payload: SuperAllocation) => {
    clientSuperObject.value.allocation.push(payload)
  }
  const updateCFSSuperField = (payload: FieldValuePairType<unknown>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    clientSuperObject.value[payload.field] = payload.value
  }
  const selectSuperFundOption = (payload: { value: number | null; label: string }) => {
    updateCFSSuperField({ value: payload.label, field: 'fundName' })
    // Reset the investment options as they are probably not linked to the new product
    updateCFSSuperField({
      value: [SuperAllocationTemplate()],
      field: 'allocation',
    })
    updateCFSSuperField({ value: payload.value, field: 'chantWestProductId' })
    getSuperFundAllocationItems(payload.value)
  }

  //CONVERTED TO COMPOSABLE
  const getChantWestProductFromAccountNumber = async (accountNumber: string) => {
    const product = cfsProducts.value.find((product: CFSProduct) => {
      return product.cfsCode === accountNumber.substring(0, 3)
    })

    // THIS BIT NOT CONVERTED TO COMPOSABLE
    if (product && mod10Checksum('001' + accountNumber)) {
      // If we get a result, set the super fund option
      selectSuperFundOption({
        value: product.chant_west_product_id,
        label: product.fund_name,
      })
    } else {
      selectSuperFundOption({
        value: null,
        label: '',
      })
      setAllocationItems([])
    } // If we don't get a result, clear the super fund options
    return product
  }
  const setAllocationItems = (payload: Array<SuperFundAllocationNameType>) => {
    return (allocationItems.value = payload)
  }
  const getSuperFundAllocationItems = (productId: number | null) => {
    if (productId === null) return setAllocationItems([])
    OptimizeAdviceService.getSuperFundOptions({ id: productId, search: '' }).then((res) => {
      const values: Array<{ id: number; name: string; isTermDeposit: boolean }> = Object.values(
        res.data,
      )
      const sortedValues: SuperFundAllocationNameType[] = values
        .map((item): SuperFundAllocationNameType => {
          return {
            value: item.id,
            label: item.name,
            isTermDeposit: item.isTermDeposit,
          }
        })
        .sort((a: { value: number; label: string }, b: { value: number; label: string }) => {
          return a.label.localeCompare(b.label)
        })

      // MAKE PRETTY
      return setAllocationItems(sortedValues)
    })
  }

  const addEmptySuperFund = () => {
    const newTemplate = GuidanceClientSuperObjectTemplate()
    newTemplate.fundName = 'New account'
    supersArray.value.push(newTemplate)
  }

  const removeSuperFundAllocation = (index: number) => {
    clientSuperObject.value.allocation.splice(index, 1)
  }
  const removeCurrentSuperFund = () => {
    supersArray.value.splice(superIndex.value, 1)
  }

  const deleteCFSSuperAccount = (superId: number) => {
    CfsService.deleteModularisedSuper(superId)
      .then(() => {
        removeCurrentSuperFund()
        setSuperIndex(0)
        getClientSuper(supersArray.value[superIndex.value].id)
      })
      .catch((err) => {
        intrafundAdviceStore.setSuperWidgetFieldsLoading(false)
        console.warn(err)
      })
  }
  const resetClientSuper = () => {
    clientSuperObject.value = GuidanceClientSuperObjectTemplate()
    intrafundAdviceStore.setSuperWidgetFieldsLoading(false)
  }

  const setSuperFund = (payload: { index: number; data: ClientSuperObject }) => {
    // Set full object
    supersArray.value[payload.index] = payload.data
  }

  const setSuperFundAllocationName = (payload: SuperFundAllocationNameType, index: number) => {
    clientSuperObject.value.allocation[index].chantWestInvestmentName = payload.label
    clientSuperObject.value.allocation[index].chantWestInvestmentId = payload.value
    clientSuperObject.value.allocation[index].isTermDeposit = payload.isTermDeposit
  }

  const setSuperFundAllocationPercentage = (payload: { superIndex: number; value: number }) => {
    clientSuperObject.value.allocation[payload.superIndex].percentAllocation = payload.value
  }

  const updateSuperByIndex = (index: number) => {
    setSuperIndex(index)
    getClientSuper(supersArray.value[index].id)
  }

  return {
    getAllocationItems,
    getClientSuperObject,
    getSuperIndex,
    getSupersArray,
    addEmptySuperFund,
    addSuperFundAllocation,
    deleteCFSSuperAccount,
    getChantWestProductFromAccountNumber,
    getClientSuper,
    getSuperFundAllocationItems,
    removeSuperFundAllocation,
    resetClientSuper,
    setClientSuperObject,
    setSuperFund,
    setSuperFundAllocationName,
    setSuperFundAllocationPercentage,
    setSuperIndex,
    setSupersArray,
    updateCFSSuperField,
    updateSuperByIndex,
    updateSupersArrayByIndex,
  }
})
