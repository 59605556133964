<template>
  <section class="bg-blue-5 max-w-[1200px] mx-auto pb-12">
    <div class="headline-3 text-center py-14 mx-4">
      Otivo is open for
      <span class="relative whitespace-nowrap"
        >business
        <img
          :src="FocusArrow"
          alt="FocusArrow"
          class="absolute -bottom-[1.95em] right-[0.75em] lg:-bottom-[1.25em] lg:-right-[1.75em] h-[1.75em] w-[1.75em] object-contain" />
      </span>
    </div>

    <!-- container -->
    <div class="relative min-h-[520px] lg:min-h-[550px] h-auto w-full my-10 flow-root">
      <!-- card -->
      <div
        class="absolute h-full bg-white rounded-lg shadow-lg border-blue-5 border top-0"
        v-for="card in cards"
        :key="card.order"
        :style="{
          zIndex: getZIndex(card),
          left: `calc(15px + ${card.order * 2.5}rem)`,
          width: cardWidth,
        }"
        :class="getOrderClasses(card)">
        <!-- content -->
        <div
          class="content relative h-full w-full p-6 lg:p-10"
          :class="{ 'cursor-pointer': activeCard.order !== card.order }"
          @click="activeCard = card">
          <div
            class="flex"
            :class="{
              'w-full flex-col flex-1 ': activeCard.order === card.order,
              relative: activeCard.order !== card.order,
            }">
            <p class="headline-6 normal-case text-otivo-blue highlight">
              {{ card.highlight }}
            </p>
            <div
              class="headline-3 hide-inactive text-blue-1 mb-5"
              :class="{ 'ml-4': activeCard.order !== card.order }">
              {{ card.heading }}
            </div>

            <div class="hide-inactive flex flex-col space-y-2">
              <p class="flex paragraph-1 mb-5">{{ card.body }}</p>
              <div
                v-for="(feature, index) in card.features"
                :key="`card-${card.order}-feature-${index}`"
                class="flex flex-col">
                <p class="paragraph-2 inline-flex items-start">
                  <span class="mr-[10px] mt-1">
                    <BasicTick class="fill-otivo-blue" />
                  </span>
                  {{ feature }}
                </p>
              </div>
            </div>

            <a :href="card.button.link" class="mt-auto mb-4 hide-inactive">
              <div
                class="rounded-[60px] flex flex-col bg-white text-otivo-blue cursor-pointer border border-grey-1 w-75% md:justify-start md:w-fit text-center mx-auto md:mx-0 py-[16px] px-[40px] min-h-[40px] hover:bg-grey-4">
                <div class="button-1">{{ card.button.label }}</div>
              </div>
            </a>
          </div>

          <div class="hidden hide-inactive lg:flex items-center lg:ml-5 lg:pl-5 lg:col-span-2">
            <img
              style="box-shadow: -30px 0 20px -8px rgba(0, 0, 0, 0.1)"
              class="object-contains rounded"
              :src="card.imgUrl"
              alt="otivo-platform-image" />
          </div>
        </div>
        <!-- end of content -->
      </div>
      <!-- end of card -->
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed, ComputedRef, ref } from 'vue'
import FocusArrow from '@/components/SVGS/FocusArrowDownRight.svg?url'
import BasicTick from '@/components/icons/BasicTick.vue'

type Card = {
  order: number
  imgUrl: string
  highlight: string
  heading: string
  body: string
  features: Array<string>
  button: {
    label: string
    link: string
  }
}
type Props = {
  data: Array<Card>
}

const props = defineProps<Props>()
const cards = ref(props.data)
const activeCard = ref(cards.value[0])
const totalCards: ComputedRef<number> = computed(() => cards.value?.length ?? 0)
const cardWidth: ComputedRef<string> = computed(() => `calc(100% - ${totalCards.value * 2.5}rem)`)

const getZIndex = (card: Card) => {
  const highest = totalCards.value + 1
  const distanceFromActive = Math.abs(activeCard.value.order - card.order)
  const level = activeCard.value.order === card.order ? highest : highest - distanceFromActive
  return level * 10
}

const getOrderClasses = (card: Card) => {
  return {
    active: activeCard.value.order === card.order,
    inactive: activeCard.value.order !== card.order,
    before: activeCard.value.order > card.order,
    after: activeCard.value.order < card.order,
  }
}
</script>

<style lang="scss" scoped>
.active .content {
  @apply flex flex-col lg:grid lg:grid-cols-3 lg:pl-10;
}

.highlight {
  @apply lg:p-2 mb-6 lg:mb-10;
}

.inactive {
  .highlight {
    @apply absolute whitespace-nowrap -top-8 lg:-mx-5;
  }

  .hide-inactive {
    display: none;
  }

  &.before .highlight {
    @apply origin-bottom-left rotate-90 -left-[17px];
  }

  &.after .highlight {
    @apply origin-bottom-right -rotate-90 -right-[17px];
  }
}
</style>
