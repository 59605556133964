<template>
  <svg xmlns="http://www.w3.org/2000/svg" :height="height" :width="width" viewBox="0 0 20 20">
    <path
      :class="`fill-${lineFill}`"
      d="M18.75 20c-.319 0-.637-.122-.884-.366l-3.829-3.828a1.253 1.253 0 0 1 0-1.772 1.247 1.247 0 0 1 1.77 0l3.827 3.828a1.253 1.253 0 0 1-.884 2.14V20Z" />
    <path
      :class="`fill-${lineFill}`"
      d="M8.697 17.39a8.666 8.666 0 0 1-6.15-2.544A8.655 8.655 0 0 1 0 8.693c0-2.325.906-4.51 2.55-6.15 3.39-3.39 8.906-3.39 12.297 0a8.642 8.642 0 0 1 2.547 6.153c0 2.325-.906 4.51-2.547 6.153a8.674 8.674 0 0 1-6.15 2.544v-.003Zm0-14.885a6.18 6.18 0 0 0-4.384 1.813A6.153 6.153 0 0 0 2.5 8.696c0 1.653.644 3.21 1.813 4.378a6.207 6.207 0 0 0 8.765 0 6.153 6.153 0 0 0 1.813-4.378c0-1.653-.644-3.21-1.813-4.378a6.18 6.18 0 0 0-4.384-1.813h.003Z" />
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  backgroundFill?: string
  lineFill?: string
  width?: string
  height?: string
}

withDefaults(defineProps<Props>(), {
  backgroundFill: 'none',
  lineFill: 'grey-1',
  width: '20',
  height: '20',
})
</script>

<style scoped></style>
