<template>
  <canvas :id="canvasId"></canvas>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, onUpdated, ref } from 'vue'
import { Chart } from 'chart.js/auto'
import { ModuleOption } from '@/components/CallcentrePortal/CallcentrePortalEngagement.vue'
import { drawDoughnut } from '@/composables/charts/base/doughnutChart.ts'

const props = defineProps<{
  canvasId: string
  activeMembers: number
  adviceIssued: number
  activeTab: ModuleOption
}>()

const doughnutChart = ref()
const percentage = computed(() => {
  let percent = (props.adviceIssued / props.activeMembers) * 100
  return !Number.isNaN(percent) ? Number(percent.toFixed(0)) : 0
})

const createDoughnutLabel = () => {
  return {
    id: 'doughnutLabel',
    beforeDatasetDraw: (chart: Chart) => {
      const { ctx, data } = chart
      const width = ctx.canvas.width
      const height = ctx.canvas.height
      const xCoord = chart.getDatasetMeta(0).data[0].x
      const yCoord = chart.getDatasetMeta(0).data[0].y
      ctx.save()
      ctx.clearRect(xCoord - width / 2, yCoord - height / 2, width, height)
      ctx.font = 'bold 40px Fraunces'
      ctx.fillStyle = '#333'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      props.activeMembers > 0
        ? ctx.fillText(`${percentage.value}%`, xCoord, yCoord)
        : ctx.fillText(`No Data`, xCoord, yCoord)
      ctx.font = 'bold 12px Raleway'
      ctx.fillText('MEMBER ENGAGEMENT', xCoord, yCoord + 30)
      ctx.fillText(`${props.activeTab.label}`, xCoord, yCoord + 60)
    },
  }
}

const doughnutConfig = computed(() => {
  return {
    labels: ['Advice Issued', 'Active Members'],
    datasets: [
      {
        data: [props.adviceIssued, props.activeMembers - props.adviceIssued],
        backgroundColor: [props.activeTab.colour, '#DDD'],
        hoverBackgroundColor: [props.activeTab.colour, '#DDD'],
        showTooltips: false,
        cutout: '80%',
        borderAlign: 'inner',
        borderRadius: 100,
      },
    ],
  }
})

const create = async () => {
  const doughnutLabel = createDoughnutLabel()
  const doughnut = document.getElementById(props.canvasId) as HTMLCanvasElement
  doughnutChart.value = await drawDoughnut(doughnutConfig.value, doughnut, doughnutLabel)
}

const update = async () => {
  if (!doughnutChart.value) await create()

  const doughnut = Object.values(Chart.instances)
    .filter((c) => c.canvas.id == 'engagement-doughnut')
    .pop() as Chart

  const updatedLabel = createDoughnutLabel()

  try {
    doughnut.data.datasets[0].backgroundColor = [props.activeTab.colour, '#DDD']
    doughnut.data.datasets[0].hoverBackgroundColor = [props.activeTab.colour, '#DDD']
    doughnut.data.datasets[0].data = [props.adviceIssued, props.activeMembers - props.adviceIssued]

    const labelPlugin = doughnut.config.plugins?.find((plugin) => plugin.id === 'doughnutLabel')
    if (labelPlugin) {
      labelPlugin.beforeDatasetDraw = updatedLabel.beforeDatasetDraw
    }

    doughnut.update()
  } catch (e) {
    console.error("couldn't find chart")
  }
}

onMounted(() => {
  create()
})
onUpdated(() => {
  update()
})
onUnmounted(() => {
  doughnutChart.value?.destroy()
})
</script>

<style scoped></style>
