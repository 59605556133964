<template>
  <div
    class="text-grey-1 flex justify-between h-[90px] menu items-center gap-4 text-left border-b-[1px] border-b-blue-4.5"
    v-if="clientLoggedIn">
    <div class="flex gap-[40px]">
      <div class="flex gap-[5px] items-center" v-if="isCallCentrePortal">
        <MortgageColouredIcon class="w-[12px] h-[12px]" />
        <span class="text-grey-1 menu-1">MEMBER</span>
        <span class="text-grey-2 paragraph-2">
          {{ managedClientData.member_id }}
        </span>
      </div>
      <div class="flex gap-[5px] items-center">
        <OtivoIcon class="w-[14px] h-[14px]" />
        <span class="text-grey-1 menu-1">CREATED</span>
        <span class="text-grey-2 paragraph-2">
          {{ format(managedClientData.created_at, 'd MMM yyyy') }}
        </span>
      </div>
      <div class="flex gap-[5px] items-center">
        <CircleCheck class="w-[14px] h-[14px]" :background="'#A0A0A0'" tick="#FFF" ring="FFF" />
        <span class="text-grey-1 menu-1">UPDATED</span>
        <span class="text-grey-2 paragraph-2">
          {{ format(managedClientData.updated_at, 'd MMM yyyy HH:mm:ss') }}
        </span>
      </div>
      <div class="flex gap-[5px] items-center" v-if="expiresAt">
        <ArrowSide class="w-[12px] h-[12px]" :fill="'#982c17'" />
        <span class="text-grey-1 menu-1">{{ expiredText }}</span>
        <span class="text-grey-2 paragraph-2">
          {{ format(new Date(expiresAt), 'd MMM yyyy HH:mm:ss') }}
        </span>
      </div>
      <AccountDeactivated
        :expires-at="managedClientData.deactivated_at"
        v-if="managedClientData.account_deactivated" />
    </div>
    <div
      class="flex gap-[5px] items-center text-otivo-blue hover:text-blue-1 cursor-pointer"
      @click="goToProfile()">
      <profile-icon class="w-[12px] h-[12px]" />
      <div class="mt-1 menu-1">
        {{ managedClientData.first_name }} {{ managedClientData.last_name }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ProfileIcon from '@/components/icons/ProfileIcon.vue'
import MortgageColouredIcon from '@/components/icons/MortgageColouredIcon.vue'
import OtivoIcon from '@/components/icons/OtivoIcon.vue'
import CircleCheck from '@/components/icons/CircleCheck.vue'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/store/pinia/UserStore.ts'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import ArrowSide from '@/components/icons/ArrowSide.vue'
import { format } from 'date-fns'
import AccountDeactivated from '@/components/Profile/Deactivate/AccountDeactivatedBadge.vue'

const router = useRouter()
const userStore = useUserStore()
const sessionStore = useSessionStore()
const whitelabel = computed(() => sessionStore.getWhitelabelData)
const isCallCentrePortal = computed(() => whitelabel.value.callcentre_portal)
const managedClientData = computed(() => userStore.getUser)
const clientLoggedIn = computed(() => managedClientData.value.auth0id)

const ifExpired = computed(() => {
  const now = new Date()

  const expiresAtString =
    managedClientData.value.subscriptions[0]?.cancels_at ??
    parseInt(managedClientData.value.fee_consent_expires_at)

  return new Date(expiresAtString) < now
})

const expiredText = computed(() => {
  return ifExpired.value ? 'EXPIRED' : 'EXPIRES'
})

const expiresAt = computed(() => {
  return (
    managedClientData.value.subscriptions[0]?.cancels_at ??
    Number.parseInt(managedClientData.value.fee_consent_expires_at)
  )
})

const goToProfile = () => {
  isCallCentrePortal.value
    ? router.push({ name: 'guidanceMemberSummary' })
    : router.push({ name: 'AccountSummary' })
}
</script>
