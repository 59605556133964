<template>
  <div :class="multiLine ? '' : 'flex'">
    <slot name="input-label" />
    <div class="flex flex-col w-full">
      <slot name="input-box" />
      <div class="px-4">
        <slot name="input-errors" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasicInput',
  props: {
    multiLine: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped></style>
