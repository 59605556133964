<template>
  <div class="w-100% m-auto rounded">
    <div
      class="bg-white border-[1px] mt-[40px] border-l-0 border-r-0 border-grey-4 flex justify-between items-center mb-[2px] px-[30px] h-[50px]">
      <div class="flex gap-2">
        <MagnifyingGlass />
        <p v-if="memberSearchResult.length > 0">
          {{ memberSearchResult[0].member_id }}
        </p>
        <p v-else class="text-grey-2">No members found</p>
      </div>
      <div class="menu-1 cursor-pointer" @click="$emit('clear-search')">CLEAR</div>
    </div>
    <div v-if="memberSearchResult.length > 0" class="bg-white rounded-b text-left pb-[30px]">
      <div class="text-cfs-blue menu-1 h-[50px] px-[30px] flex items-center">
        {{ memberSearchResult.length }} accounts found
      </div>
      <div v-for="(result, idx) in memberSearchResult" :key="idx">
        <button
          @click="selectUser(result)"
          class="text-left w-full h-full p-[30px] border-b-[1px] border-t-[1px] border-grey-4">
          <div class="button-1 text-otivo-blue underline">
            {{ result.first_name }} {{ result.last_name }}
          </div>
          <div class="paragraph-2 text-[12px] text-grey-1 my-[10px] flex items-center">
            <div class="w-[70px] text-grey-2 paragraph-2 mr-[20px]">Account</div>
            {{ result.member_id }}
          </div>
          <div class="paragraph-2 text-[12px] my-[10px] flex items-center">
            <div class="w-[70px] text-grey-2 paragraph-2 mr-[20px]">Email</div>
            {{ result.email }}
          </div>
          <div class="paragraph-2 text-[12px] my-[10px] flex items-center">
            <div class="w-[70px] text-grey-2 paragraph-2 mr-[20px]">Dob</div>
            {{ result.dob }}
          </div>
          <div class="paragraph-2 text-[12px] my-[10px] flex items-center">
            <div class="w-[70px] text-grey-2 paragraph-2 mr-[20px]">Status</div>
            Last modified on {{ result.updated_at }}
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import MagnifyingGlass from '@/components/SVGS/MagnifyingGlass.vue'
import { ClientSearchResult } from '@/types/CallcentrePortalTypes.ts'
import { useCallCentrePortalStore } from '@/store/pinia/adminPortal/CallCentrePortalStore.ts'
import { useRouter } from 'vue-router'

type Props = {
  memberSearchResult: ClientSearchResult[]
}
defineProps<Props>()
defineEmits(['clear-search'])

const router = useRouter()
const selectUser = async (userData: ClientSearchResult) => {
  await useCallCentrePortalStore().getManagedUserData(userData.auth0id)
  await router.push({ name: 'manageUser' })
}
</script>

<style scoped></style>
