import getApiInstance from '@/services/Api'
const api = getApiInstance()
const getParams = (id, bands, company = '') => {
  let params = {}
  if (id && id !== 'select') {
    params = {
      params: {
        reporting_whitelabel_id: id
      }
    }
    if (bands) {
      params.params.salary_band = bands
    }
  }
  if (bands) {
    params = { params: { salary_band: bands } }
    if (id && id !== 'select') {
      params.params.reporting_whitelabel_id = id
    }
  }
  if (company) {
    params.params.company = company
  }
  return params
}

export default {
  getOverview(id, bands, company) {
    return api.Get('/adminReport/overview', getParams(id, bands, company))
  },

  getOptimiseAverages(id, bands, company) {
    return api.Get('/adminReport/optimiseAverages', getParams(id, bands, company))
  },

  getCashFlow(id, bands, company) {
    return api.Get('/adminReport/cashFlow', getParams(id, bands, company))
  },

  getDailyLogins(id, bands, company) {
    return api.Get('/adminReport/dailyLogins', getParams(id, bands, company))
  },

  getDebt(id, bands, company) {
    return api.Get('/adminReport/debt', getParams(id, bands, company))
  },

  getSavings(id, bands, company) {
    return api.Get('/adminReport/savings', getParams(id, bands, company))
  },

  getInsurance(id, bands, company) {
    return api.Get('/adminReport/insurance', getParams(id, bands, company))
  },

  getRetireAndInvest(id, bands, company) {
    return api.Get('/adminReport/retirement', getParams(id, bands, company))
  },

  getFitnessTestReport(id, bands, company) {
    return api.Get('/adminReport/fitnessTest', getParams(id, bands, company))
  },

  getLinkedAccountsReport(id, bands, company) {
    return api.Get('/adminReport/linkedAccounts', getParams(id, bands, company))
  },

  getEmailReports(id, bands, company) {
    return api.Get('/adminReport/emailReports', getParams(id, bands, company))
  },

  getAllWhiteLabels() {
    return api.Get('/whitelabels')
  },

  getAgeReport(id, bands, company) {
    return api.Get('/adminReport/age', getParams(id, bands, company))
  },

  getUserStats(id, bands, company) {
    return api.Get('/adminReport/userStats', getParams(id, bands, company))
  },

  getWhitelabelCompanies(id, bands) {
    return api.Get('/adminReport/companies', getParams(id, bands))
  }
}
