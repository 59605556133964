const state = () => ({
  userServiceSubscriptions: [],
  productId: null
})

const getters = {
  getUserServiceSubscriptions(state) {
    return state.userServiceSubscriptions
  },
  getUserProductId(state) {
    return state.productId
  }
}

const mutations = {
  SET_USER_SERVICE_SUBSCRIPTIONS(state, payload) {
    state.userServiceSubscriptions = payload
  },
  SET_USER_PRODUCT_ID(state, payload) {
    state.productId = payload
  }
}

const actions = {
  setUserServiceSubscriptions({ commit }, payload) {
    commit('SET_USER_SERVICE_SUBSCRIPTIONS', payload)
  },
  setUserProductId({ commit }, payload) {
    commit('SET_USER_PRODUCT_ID', payload)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
