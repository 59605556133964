import('chart.js/auto')
import { Chart } from 'chart.js/auto'
import { useDebounceFn } from '@vueuse/core'
import htmlLegendsPlugin from '@/composables/charts/plugins/htmlLegendsPlugin.ts'

const lineConfig = {
  type: 'line',
  options: {
    scales: {
      y: {
        border: { display: false },
        ticks: {
          maxTicksLimit: 5
        },
        grid: {
          color: '#A0E6FA'
        }
      },
      x: {
        border: { display: false },
        grid: {
          display: false,
          zeroLineColor: 'transparent'
        }
      }
    },
    plugins: {
      htmlLegend: {
        containerID: 'legend'
      },
      legend: {
        display: false
      }
    }
  }
}

export const drawGraph = useDebounceFn(
  (data, canvas: HTMLCanvasElement, plugins = [htmlLegendsPlugin()]) => {
    const config = {
      ...lineConfig,
      data: data,
      plugins: plugins
    }

    return new Chart(canvas, config)
  },
  500
)
