<template>
  <form @submit.prevent="emits('search', searchValue)" class="min-w-[375px]">
    <div class="w-100% px-[40px]">
      <InputText
        :stack-label="true"
        label="CFS Member ID"
        name="member_id_search"
        :value="searchValue.member_id"
        @input="updateSearchField('member_id', $event)"
        place-holder="CFS Member ID"
        class="w-100% m-auto mt-[6px] cfs-guidance-client-input"
        :allow-digits="true"
        :autofocus="true" />
      <InputText
        :stack-label="true"
        label="Email"
        name="member_id_search"
        :value="searchValue.email"
        @input="updateSearchField('email', $event)"
        place-holder="member@email.com"
        class="w-100% m-auto mt-[6px] cfs-guidance-client-input"
        :allow-digits="true" />
    </div>
    <div>
      <div
        @click="additionalSearchFields = !additionalSearchFields"
        class="border-t-[1px] mt-[40px] border-blue-5 cursor-pointer px-[40px] py-[20px] text-left text-cfs-blue button-1 hover:bg-blue-4.5 flex justify-between">
        <div>Show more search fields</div>
        <ArrowWide :class="{ 'transform rotate-180': additionalSearchFields }" />
      </div>
      <div class="px-[40px]" v-if="additionalSearchFields">
        <InputText
          :stack-label="true"
          label="First name"
          name="first_name_search"
          :value="searchValue.first_name"
          @input="updateSearchField('first_name', $event)"
          place-holder="First name"
          class="w-100% m-auto cfs-guidance-client-input"
          :allow-digits="true" />

        <InputText
          :stack-label="true"
          label="Last name"
          name="last_name_search"
          :value="searchValue.last_name"
          @input="updateSearchField('last_name', $event)"
          place-holder="Last name"
          class="w-100% m-auto mt-[6px] cfs-guidance-client-input"
          :allow-digits="true" />
      </div>
    </div>
    <OtivoButton
      type="submit"
      colour="blue"
      size="large"
      class="mt-[40px] m-auto hidden"
      data-test="submitBtn"
      :loading="loading">
      Search
    </OtivoButton>
  </form>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import ArrowWide from '@/components/icons/ArrowWide.vue'
import InputText from '@/components/BasicInputs/InputText.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import { ClientSearchParams } from '@/types/CallcentrePortalTypes.ts'
import { useCallCentrePortalStore } from '@/store/pinia/adminPortal/CallCentrePortalStore.ts'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'

const searchValue = ref<ClientSearchParams>({
  first_name: '',
  last_name: '',
  email: '',
  member_id: '',
})

defineExpose({
  searchValue,
})

const emits = defineEmits({
  search: (value: ClientSearchParams) => value,
})

const callCentrePortalStore = useCallCentrePortalStore()

const additionalSearchFields = ref<boolean>(false)
const loading = ref<boolean>(false)

const updateSearchField = (field: keyof ClientSearchParams, value: string) => {
  searchValue.value[field] = value
  callCentrePortalStore.updateManagedUserKey(field, value)
}
</script>

<style scoped></style>
