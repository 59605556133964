import { formatCurrency, formatPercentage } from '@/lib/helpers'
import globalConstants from '@/GlobalConstants/globalConstants'

const gc = globalConstants()

const assetsChartTooltip = (
  el: {
    year: number
    startingValue: number
    debtBalance: number
    annualGrowth: number
    annualGrowthPercentage: number
    earningsOnSuperBalance: number
    contributions: number
    earningsOnContributions: number
    drawDown: number
    pensionIncome: number
    pensionSource: number
    taxOnEarningsOnSuperBalance: number
    taxOnContributions: number
    grossAnnualSalary: number
    contributionRate: number
    closingValue: number
  },
  title: string,
  userDob: string,
  partnerDob: string,
  firstName: string,
  partnerFirstName: string,
  showPartnerElement: boolean,
  userRetirementAge: number,
  partnerRetirementAge: string,
  renderUser: boolean,
  renderPartner: boolean
) => {
  const isSuper = title.toLowerCase() === 'super'
  const isPartnerSuper = title.toLowerCase() === 'partner super'
  const isNetAssets = title.toLowerCase() === 'net assets'
  const isAlternative = title.toLowerCase() === 'alternative'
  const isProperty = title.toLowerCase() === 'property'
  const userAge = el.year - new Date(userDob).getFullYear()
  let tooltip = ''
  const partnerAge = ''

  tooltip += `<strong>${el.year}</strong> <br/>`

  if (isProperty) {
    tooltip += '<strong>Investment property</strong> <br/>'
  } else if (isAlternative) {
    tooltip += '<strong>Other</strong> <br/>'
  } else if (!isNetAssets) {
    tooltip += `<strong>${title}</strong> <br/>`
  }

  if (renderUser) {
    tooltip += `<strong>${firstName}</strong> ${userAge} `
  }

  if (renderPartner && showPartnerElement) {
    const partnerAge = el.year - new Date(partnerDob).getFullYear()
    tooltip += `<strong>${partnerFirstName}</strong> ${partnerAge}`
  }

  if (isNetAssets) {
    tooltip += '<br/>'
    let netAssets = el.startingValue
    if (el.debtBalance) {
      netAssets -= el.debtBalance
    }
    tooltip += `<strong>Net assets</strong> ${formatCurrency(netAssets)}`
  }

  if (!isNetAssets) {
    const value = el.startingValue
    tooltip += `<br /> <strong>Balance</strong>: ${formatCurrency(value)}`
  }

  // if (!isSuper && !isPartnerSuper) {
  //   tooltip += `<br /> <strong>Growth</strong>: ${formatCurrency(el.annualGrowth)} (${formatPercentage(((el.annualGrowth / el.startingValue) * 100).toFixed(2))})`
  // }

  if (el.debtBalance !== undefined && el.debtBalance > 0) {
    tooltip += `<br /> <strong>Debt balance</strong>: ${formatCurrency(el.debtBalance)}`
  }

  if (
    import.meta.env.MODE !== 'production' &&
    import.meta.env.MODE !== 'staging' &&
    (isSuper || isPartnerSuper)
  ) {
    tooltip += `<br /> <strong>Growth</strong>: ${formatCurrency(
      el.annualGrowth
    )} (${formatPercentage(el.annualGrowthPercentage.toFixed(2))})`
    tooltip += `<br /> <strong>Earning on super balance</strong>: ${formatCurrency(
      el.earningsOnSuperBalance
    )}`
    tooltip += `<br /> <strong>Contributions</strong>: ${formatCurrency(el.contributions)}`
    tooltip += `<br /> <strong>Growth on contributions</strong>: ${formatCurrency(
      el.earningsOnContributions
    )}`
    tooltip += '<br /> <strong>Less</strong>:'

    if (isSuper && userAge >= userRetirementAge) {
      tooltip += `<br /> <strong>Drawings</strong>: ${formatCurrency(el.drawDown)}`
      tooltip += `<br /> <strong>Pension Amount</strong>: ${formatCurrency(el.pensionIncome)}`
      tooltip += `<br /> <strong>Pension reason</strong>: ${el.pensionSource}`
    }

    if (isPartnerSuper && renderPartner && partnerAge >= partnerRetirementAge) {
      tooltip += `<br /> <strong>Partner Drawings</strong>: ${formatCurrency(el.drawDown)}`
      tooltip += `<br /> <strong>Partner Pension Amount</strong>: ${formatCurrency(
        el.pensionIncome
      )}`
      tooltip += `<br /> <strong>Partner Pension reason</strong>: ${el.pensionSource}`
    }
    tooltip += `<br /> <strong>Tax on super balance</strong>: ${formatCurrency(
      el.taxOnEarningsOnSuperBalance
    )}`
    tooltip += `<br /> <strong>Tax on contributions</strong>: ${formatCurrency(
      el.taxOnContributions
    )}`

    tooltip += `<br /> <strong>Gross annual salary</strong>: ${formatCurrency(
      el.grossAnnualSalary
    )}`
    tooltip += `<br /> <strong>Contribution rate</strong>: ${formatPercentage(el.contributionRate)}`

    tooltip += `<br /> <strong>Running starting value</strong>: ${formatCurrency(el.startingValue)}`
    tooltip += `<br /> <strong>Running closing value</strong>: ${formatCurrency(el.closingValue)}`
  }

  return tooltip
}

const incomeChartTooltip = (
  el: { year: number },
  chartData: {
    salary: number
    partnerSalary: number
    pensionSF: number
    partnerPensionSF: number
    superSF: number
    partnerSuperSF: number
    shares: number
    property: number
    cashAndFixed: number
    alternative: number
  },
  type = 'default',
  desiredRetirementIncome: number,
  showPartnerData: boolean,
  totalValue: number,
  currentUserAge: number,
  currentPartnerAge: number,
  userRetirementAge: number,
  partnerRetirementAge: number
) => {
  let tooltip =
    '<div id="html-tooltip-heading" class="mp2 md:p2 text-grey-1 bg-grey-1 p-4 rounded-t">'
  tooltip += `<span class="text-grey-3">Year</span> <span class="b1 text-white" >${el.year}</span></div>`

  tooltip +=
    '<div id="html-tooltip-info" class="mp2 md:p2 text-grey-1 bg-white rounded-b" style="padding: 8px">'
  tooltip += '<table class="p-4" style="width: 200px;">'
  if (showPartnerData) {
    tooltip += '<tr><td style="width: auto" /><td style="width: auto">You</td>'
    tooltip += '<td style="width: auto">Partner</td>'
    tooltip += '</tr><tr>'
  }
  tooltip += `<td>Age</td><td>${currentUserAge}</td>`
  if (showPartnerData) {
    tooltip += `<td>${currentPartnerAge}</td>`
  }
  tooltip += '</tr>'
  if (chartData.salary > 0 || (chartData.partnerSalary && chartData.partnerSalary > 0)) {
    tooltip += '<tr><td>Salary</td>'
    tooltip += `<td style="color: ${type === 'salary' ? '#164A9A' : '#333'};">${
      chartData.salary > 0 ? formatCurrency(chartData.salary) : '$0'
    }</td>`
    if (showPartnerData) {
      tooltip += `<td style="color: ${type === 'partnerSalary' ? '#164A9A' : '#333'};">${
        chartData.partnerSalary > 0 ? formatCurrency(chartData.partnerSalary) : '$0'
      }</td>`
    }
    tooltip += '</tr>'
  }
  if (chartData.pensionSF > 0 || (chartData.partnerPensionSF && chartData.partnerPensionSF > 0)) {
    tooltip += '<tr><td>Age Pension</td>'
    tooltip += `<td style="color: ${type === 'pension' ? '#164A9A' : '#333'};">${
      chartData.pensionSF > 0 ? significantFigureFormatCurrency(chartData.pensionSF) : '$0'
    }</td>`
    if (showPartnerData) {
      tooltip += `<td style="color: ${type === 'partnerPension' ? '#164A9A' : '#333'};">${
        chartData.partnerPensionSF > 0
          ? significantFigureFormatCurrency(chartData.partnerPensionSF)
          : '$0'
      }</td>`
    }
    tooltip += '</tr>'
  }
  if (chartData.superSF > 0 || (chartData.partnerSuperSF && chartData.partnerSuperSF > 0)) {
    tooltip += '<tr><td>Super</td>'
    tooltip += `<td style="color: ${type === 'super' ? '#164A9A' : '#333'};">${
      chartData.superSF > 0 ? significantFigureFormatCurrency(chartData.superSF) : '$0'
    }</td>`
    if (showPartnerData) {
      tooltip += `<td style="color: ${type === 'partnerSuper' ? '#164A9A' : '#333'};">${
        chartData.partnerSuperSF > 0
          ? significantFigureFormatCurrency(chartData.partnerSuperSF)
          : '$0'
      }</td>`
    }
    tooltip += '</tr>'
  }
  if (chartData.shares > 0) {
    tooltip += '<tr><td>Shares</td>'
    tooltip += `<td style="color: ${type === 'shares' ? '#164A9A' : '#333'};">${
      chartData.shares > 0 ? formatCurrency(chartData.shares) : '$0'
    }</td>`
    tooltip += '</tr>'
  }
  if (chartData.property > 0) {
    tooltip += '<tr><td>Investment property</td>'
    tooltip += `<td style="color: ${type === 'property' ? '#164A9A' : '#333'};">${
      chartData.property > 0 ? formatCurrency(chartData.property) : '$0'
    }</td>`
    tooltip += '</tr>'
  }
  if (chartData.cashAndFixed > 0) {
    tooltip += '<tr><td>Cash</td>'
    tooltip += `<td style="color: ${type === 'cashAndFixed' ? '#164A9A' : '#333'};">${
      chartData.cashAndFixed > 0 ? formatCurrency(chartData.cashAndFixed) : '$0'
    }</td>`
    tooltip += '</tr>'
  }
  if (chartData.alternative > 0) {
    tooltip += '<tr><td>Other</td>'
    tooltip += `<td style="color: ${type === 'alternative' ? '#164A9A' : '#333'};">${
      chartData.alternative > 0 ? formatCurrency(chartData.alternative) : '$0'
    }</td>`
    tooltip += '</tr>'
  }
  if (
    desiredRetirementIncome > totalValue &&
    (currentUserAge >= userRetirementAge ||
      (currentPartnerAge >= partnerRetirementAge && showPartnerData)) &&
    desiredRetirementIncome >= totalValue + (showPartnerData ? 200 : 100)
  ) {
    tooltip += '<tr style="color: #982C17"><td>Income gap</td>'
    tooltip += `<td>${formatCurrency(desiredRetirementIncome - totalValue)}</td>`
    tooltip += '</tr>'
  }

  tooltip += '</table></div>'

  return tooltip
}

const significantFigureRound = (superVal: number) => {
  return Math.round(superVal / 100) * 100
}
const significantFigureFormatCurrency = (superVal: number) => {
  return formatCurrency(significantFigureRound(superVal))
}

type DefaultRateOfReturnValuesType = {
  inflation: number
  wageGrowth: number
  insurancePremium: number
  annualAdminFees: number
  assumptionValues: Array<{
    type: string
    name: string
    adminFee: number
    value: string
    rateOfReturn: number
    discountRate: number
    taxOnEarnings?: number
  }>
}
const defaultRateOfReturnValues = (): DefaultRateOfReturnValuesType => {
  return {
    inflation: 2.5,
    wageGrowth: 4,
    insurancePremium: 214,
    annualAdminFees: 74,
    assumptionValues: [
      {
        type: 'superFunds',
        name: 'Starting assumption',
        adminFee: 0.85,
        value: 'default',
        rateOfReturn: 7.5,
        discountRate: 2.5,
        taxOnEarnings: 7.0
      },
      {
        type: 'superFundsDrawDown',
        name: 'Starting assumption',
        adminFee: 0.85,
        value: 'default',
        rateOfReturn: 6.5,
        discountRate: 2.5,
        taxOnEarnings: 0
      },
      {
        type: gc.PROPERTY_INVESTMENT_PLAN,
        name: 'Default',
        adminFee: 0,
        value: 'default',
        rateOfReturn: 6.9,
        discountRate: 2.5
      },
      {
        type: gc.SHARES_INVESTMENT_PLAN,
        name: 'Default',
        adminFee: 0,
        value: 'default',
        rateOfReturn: 5.1,
        discountRate: 2.5
      },
      {
        type: 'cashAndFixed',
        name: 'Default',
        adminFee: 0,
        value: 'default',
        rateOfReturn: 0.0,
        discountRate: 2.5
      },
      {
        type: gc.ALTERNATIVE_INVESTMENT_PLAN,
        name: 'Default',
        adminFee: 0,
        value: 'default',
        rateOfReturn: 4.0,
        discountRate: 2.5
      }
    ]
  }
}

const defaultRetCalcData = {
  age: null,
  income: null,
  income_time_period: 'yearly',
  annual_net_income: null,
  hasHome: null,
  current_super_balance: null,
  contribution_rate: null,
  desired_retirement_income: null,
  desired_retirement_age: 67,
  super_access_age: null,
  before_tax_contribution: null,
  after_tax_contribution: null,
  one_off_contribution: null,
  before_tax_frequency: 'yearly',
  after_tax_frequency: 'yearly',
  one_off_age: null,
  part_time: {
    part_time_starting_age: null,
    part_time_duration: null,
    part_time_days: null
  },
  career_breaks: [
    {
      age: null,
      duration: null
    }
  ]
}

export {
  assetsChartTooltip,
  incomeChartTooltip,
  significantFigureRound,
  defaultRateOfReturnValues,
  defaultRetCalcData
}
