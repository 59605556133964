<template>
  <div class="h-[100vh]">
    <div class="bg-midnight w-full min-h-[120px] flex items-center pl-6 xl:px-20">
      <img
        v-if="!whitelabel.joint_logo"
        src="@/assets/img/otivo-logo-white-text.png"
        class="flex max-w-[130px]"
        alt="otivo-logo" />
      <logo v-else />
    </div>
    <div class="flex-grow">
      <Transition name="fade">
        <slot />
      </Transition>
    </div>
    <CustomFooter class="w-full pt-4 bg-midnight text-blue-4" />
  </div>
</template>

<script lang="ts" setup>
import CustomFooter from '@/components/Footer/CustomFooter.vue'
import Logo from '@/components/Logo/Logo.vue'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import { computed } from 'vue'

const sessionStore = useSessionStore()
const whitelabel = computed(() => sessionStore.getWhitelabelData)
</script>
