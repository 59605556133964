<template>
  <div
    class="p-[20px] md:p-[40px] bg-white flex flex-row content-center items-center md:items-start ml-0"
    id="breadcrumbs">
    <div class="flex" v-for="(crumb, idx) in breadcrumbs" :key="idx">
      <a
        :id="`breadcrumb-${idx}`"
        class="mx-[10px] pb-[2px] flex"
        :class="
          crumb.clickable
            ? 'text-grey-2 border-b border-grey-2 hover:text-grey-1 capitalize'
            : 'font-bold text-grey-1 hover:text-otivo-blue'
        "
        :href="crumb.path">
        <home-icon
          v-if="idx === 0"
          width="12px"
          height="12px"
          class="flex self-center mr-[5px]"
          stroke="grey-1" />
        {{ crumb.route }}
      </a>
      <span v-if="idx !== breadcrumbs.length - 1"> / </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import HomeIcon from '@/components/icons/HomeIcon.vue'

type Props = {
  currentPageName?: string
}
const route = useRoute()
const paths = route.matched[0].path.split('/')
const props = withDefaults(defineProps<Props>(), {
  currentPageName: '',
})

const breadcrumbs = computed(() =>
  paths.map((crumb, index) => {
    const isLastIndex = index === paths.length - 1
    const path = !isLastIndex ? paths.slice(0, index + 1).join('/') : '#'
    let name = isLastIndex && route.matched[0].name ? route.matched[0].name : crumb
    name = isLastIndex && props.currentPageName ? props.currentPageName : name

    return {
      route: name || 'home',
      path: path || '/',
      clickable: !isLastIndex,
    }
  }),
)
</script>
