<template>
  <div v-if="isItSuncorpstaff">
    <OtivoButton colour="white" @click="triggerContactUsModal()">
      Contact staff financial services
    </OtivoButton>
  </div>
</template>

<script>
import OtivoButton from '@/components/OtivoButton.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SunCorpContactStaffFinancialServices',
  components: { OtivoButton },
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    triggerContactUsModal() {
      this.$store.commit('SUNCORP_CONTACT_US_MODAL', true)
      this.toggleShowModal()
      // this.$emit('click')
    }
  },

  computed: {
    ...mapGetters(['isItSuncorpstaff'])
  }
}
</script>

<style scoped></style>
