<template>
  <div
    class="rounded text-red-1 w-full focus:outline-none bg-white text-left p-4 pr-2 border-2px border-red-1 ring-2 ring-red-light overflow-auto"
    :class="{ 'h-auto': open }">
    <div class="flex cursor-pointer" @click="$emit('click')">
      <span class="w-full mp2 md:p2" data-test="privacyDropDown">
        Please read our privacy collection statement
      </span>
      <dropdown-arrow
        class="self-center mr-4"
        stroke="#888"
        :class="open ? 'transform rotate-0' : 'transform rotate-180'" />
    </div>
    <div v-if="open" class="py-3 text-grey-1">
      <span>
        Otivo Pty Ltd ABN 47 602 457 732 (Otivo, we, us, or our) is committed to protecting your
        privacy. We collect your personal information from you (or your partner) when you (or your
        partner) register on, and use, our platform to create financial plans.
        <br />
        <br />
      </span>
      <span>
        We use the personal information we collect for the purpose of providing advice on your
        financial situation. We do not trade, rent or sell your information or disclose it to any
        third parties <span v-if="isCFSWhitelabel()">(other than CFS)</span>.<br /><br />
      </span>
      <span>
        Our Privacy Policy contains more information about how to access and correct the information
        we hold about you and how to make a privacy related complaint. You can read it by visiting
        our website
        <!-- Hard coded for now -->
        <a href="/content/privacy" target="_blank"> Privacy Policy. </a>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
/**
 * @TODO: put switch for other than cfs bound to whitelabel
 */
import DropdownArrow from '@/components/icons/DropdownArrow.vue'
import { isCFSWhitelabel } from '@/composables/isCFSWhitelabel.ts'

type Prop = {
  open: boolean
}

withDefaults(defineProps<Prop>(), {
  open: false,
})

defineEmits<{ (e: 'click'): void }>()
</script>

<style scoped></style>
