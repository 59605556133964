<template>
  <div>
    <fitness-test-question-label
      :question-number="questionNumber"
      question="I'm on track to meet my financial goals" />

    <number-rating :selected="onTrackFinancialGoal" @click="setOnTrackFinancialGoal" />
  </div>
</template>

<script>
import NumberRating from '../NumberRating/NumberRating.vue'

import { mapState } from 'vuex'
import FitnessTestQuestionLabel from '../QuestionsLabel/FitnessTestQuestionLabel.vue'

export default {
  name: 'OnTrackFinancialGoal',
  components: { FitnessTestQuestionLabel, NumberRating },
  computed: {
    ...mapState({
      onTrackFinancialGoal: (state) => state.fitnessTest.questions.onTrackFinancialGoal,
    }),
  },

  props: {
    questionNumber: {
      type: String,
      default: '',
    },
  },

  methods: {
    setOnTrackFinancialGoal(value) {
      this.$store.dispatch('setFitnessTestOnTrackFinancialGoal', value)
    },
  },
}
</script>

<style scoped></style>
