<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26 14C26 20.6274 20.6274 26 14 26C7.37258 26 2 20.6274 2 14C2 7.37258 7.37258 2 14 2C20.6274 2 26 7.37258 26 14Z"
      :fill="background"
      :stroke="ring"
      stroke-width="3" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.0607 9.93934C21.6464 10.5251 21.6464 11.4749 21.0607 12.0607L12.65 20.4713L8.43934 16.2607C7.85355 15.6749 7.85355 14.7251 8.43934 14.1393C9.02513 13.5536 9.97487 13.5536 10.5607 14.1393L12.65 16.2287L18.9393 9.93934C19.5251 9.35355 20.4749 9.35355 21.0607 9.93934Z"
      :fill="tick" />
  </svg>
</template>

<script>
export default {
  name: 'CircleCheck',
  props: {
    background: {
      type: String,
      default: '#FFFFFF'
    },
    ring: {
      type: String,
      default: '#0064FF'
    },
    tick: {
      type: String,
      default: '#FF0000'
    }
  }
}
</script>

<style scoped></style>
