<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 4.66667H2C1.46957 4.66667 0.96086 4.52619 0.585787 4.27614C0.210714 4.02609 0 3.68696 0 3.33333C0 2.97971 0.210714 2.64057 0.585787 2.39052C0.96086 2.14047 1.46957 2 2 2H10C10.5304 2 11.0391 2.14047 11.4142 2.39052C11.7893 2.64057 12 2.97971 12 3.33333C12 3.68696 11.7893 4.02609 11.4142 4.27614C11.0391 4.52619 10.5304 4.66667 10 4.66667Z"
      :class="`fill-${fill1}`" />
    <path
      d="M10 10H2C1.46957 10 0.96086 9.85957 0.585787 9.60952C0.210714 9.35947 0 9.02033 0 8.66671C0 8.31309 0.210714 7.97395 0.585787 7.7239C0.96086 7.47385 1.46957 7.33337 2 7.33337H10C10.5304 7.33337 11.0391 7.47385 11.4142 7.7239C11.7893 7.97395 12 8.31309 12 8.66671C12 9.02033 11.7893 9.35947 11.4142 9.60952C11.0391 9.85957 10.5304 10 10 10Z"
      :class="`fill-${fill1}`" />
  </svg>
</template>

<script setup lang="ts">
type Props = {
  fill1?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'grey-2'
})
</script>

<style scoped></style>
