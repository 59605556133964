import { getWhiteLabelFromHost, getAllWhiteLabels } from './WhiteLabelHelpers'
import _ from 'lodash'
import { capitalize } from '@/lib/helpers'

const currentWhiteLabel = getWhiteLabelFromHost()

const getWhiteLabel = () => {
  return currentWhiteLabel
}

// is it getters e.g. ey => isItEy, otivo => isItWww, surmount => isItSurmount, vigourmedia => isItVigourmedia
let allGetters = _.transform(
  getAllWhiteLabels(),
  (result, value, key) => {
    result[`isIt${capitalize(key)}`] = () => key === currentWhiteLabel
  },
  {}
)

allGetters = {
  ...allGetters,
  getWhiteLabel
}
export default allGetters
