<template>
  <high-charts class="max-w-88" :options="getOptions()" />
</template>

<script>
import Highcharts from 'highcharts'
import { Chart } from 'highcharts-vue'
import exportingInit from 'highcharts/modules/exporting'
import highchartsMoreInit from 'highcharts/highcharts-more'
import solidGaugeInit from 'highcharts/modules/solid-gauge'
import { getRootColor } from '@/lib/helpers'

Highcharts.Legend.prototype.colorizeItem = function (item, visible) {
  item.legendGroup[visible ? 'removeClass' : 'addClass']('highcharts-legend-item-hidden')
  if (!this.chart.styledMode) {
    // eslint-disable-next-line one-var
    let legend = this,
      options = legend.options,
      legendItem = item.legendItem,
      legendLine = item.legendLine,
      legendSymbol = item.legendSymbol,
      hiddenColor = legend.itemHiddenStyle.color,
      textColor = visible ? options.itemStyle.color : hiddenColor,
      symbolColor = visible ? item.color || hiddenColor : hiddenColor,
      markerOptions = item.options && item.options.marker,
      symbolAttr = {
        fill: symbolColor,
      }
    if (legendItem) {
      legendItem.css({
        fill: textColor,
        color: textColor, // #1553, oldIE
      })
    }
    if (legendLine) {
      legendLine.attr({
        stroke: symbolColor,
      })
    }
    if (legendSymbol) {
      // Apply marker options
      if (markerOptions) {
        symbolAttr = item.pointAttribs()
        if (!visible) {
          // #6769
          symbolAttr.stroke = symbolAttr.fill = hiddenColor
        }
      }
      legendSymbol.attr(symbolAttr)
    }
  }
  Highcharts.fireEvent(this, 'afterColorizeItem', {
    item: item,
    visible: visible,
  })
}

exportingInit(Highcharts)
highchartsMoreInit(Highcharts)
solidGaugeInit(Highcharts)
export default {
  name: 'TotalScoreGraph',

  components: {
    HighCharts: Chart,
  },

  props: {
    userScore: {
      type: Number,
      default: 50,
    },
    averageScore: {
      type: Number,
      default: 50,
    },
  },

  methods: {
    getOptions() {
      return {
        chart: {
          type: 'solidgauge',
          styleMode: true,
          events: {
            load() {
              this.tooltip.refresh(this.series[1].points[0])
            },
            redraw() {
              this.tooltip.refresh(this.series[1].points[0])
            },
          },
        },

        credits: {
          enabled: false,
        },

        exporting: {
          enabled: false,
        },

        title: {
          text: `You scored ${this.userScore}%`,
          style: {
            fontSize: '32px',
            fontFamily: 'Raleway',
            fontWeight: '700',
          },
        },
        responsive: {
          rules: [
            {
              condition: {
                // maxWidth: 100
              },
              chartOptions: {
                legend: {
                  enabled: true,
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                  symbolHeight: 20,
                  symbolWidth: 20,
                  itemStyle: {
                    fontSize: '14px',
                    fontWeight: 'lighter',
                  },
                },
              },
            },
          ],
        },

        tooltip: {
          borderWidth: 0,
          backgroundColor: 'none',
          shadow: false,
          useHTML: true,
          style: {
            textAlign: 'center',
          },
          // pointFormat: '<div style="background-color: white;">{series.name}<br><span style="font-size:2em; color: {point.color}; font-weight: bold; background-color: white;">{point.y}%</span></div>',
          formatter: function () {
            if (this.point.series.name === 'Your score')
              return (
                '<span style="font-size:1.4em;font-weight:bold;">' +
                this.point.series.name +
                '</span><br/><span style="font-size:30px;color:' +
                getRootColor('otivo-red') +
                ';font-weight:bold;text-align:center;">' +
                this.point.y +
                '%</span>'
              )
            return (
              '<span style="font-size:1.4em;font-weight:bold;">' +
              this.point.series.name +
              '</span><br/><span style="font-size:30px;color:' +
              getRootColor('otivo-red') +
              ';font-weight:bold;">' +
              this.point.y +
              '%</span>'
            )
          },
          positioner: function (labelWidth) {
            return {
              x: (this.chart.chartWidth - labelWidth) / 2,
              y: this.chart.plotHeight / 2 + 25,
            }
          },
        },

        pane: {
          startAngle: 0,
          endAngle: 360,
          background: [
            {
              // Track for Move
              outerRadius: '112%',
              innerRadius: '88%',
              backgroundColor: getRootColor('grey-5'),
              borderWidth: 0,
            },
            {
              // Track for Exercise
              outerRadius: '87%',
              innerRadius: '63%',
              backgroundColor: getRootColor('grey-5'),
              borderWidth: 0,
            },
          ],
        },

        yAxis: {
          min: 0,
          max: 100,
          lineWidth: 0,
          tickPositions: [],
        },

        plotOptions: {
          solidgauge: {
            // dataLabels: {
            //   enabled: false
            // },

            dataLabels: {
              enabled: false,
              y: -40,
              borderWidth: 0,
              backgroundColor: 'none',
              useHTML: true,
              shadow: false,
              style: {
                fontSize: '40px',
                fontFamily: 'Fraunces',
              },
              formatter: function () {
                return (
                  '<div style="width:100%;text-align:center;"><span style="font-size:1.2em;font-weight:bold;">' +
                  this.point.series.name +
                  '</span><br/><span style="font-size:40px;color:' +
                  getRootColor('otivo-red') +
                  ';font-weight:bold;">' +
                  this.point.y +
                  '%</span>'
                )
              },
            },
            linecap: 'round',
            stickyTracking: true,
            rounded: true,
          },
        },

        series: [
          {
            name: 'Your score',
            marker: {
              fillColor: getRootColor('otivo-blue'),
            },
            data: [
              {
                color: getRootColor('otivo-blue'),
                radius: '112%',
                innerRadius: '88%',
                y: this.userScore,
              },
            ],
            showInLegend: true,
          },
          {
            name: 'Average score',
            marker: {
              fillColor: getRootColor('blue-3'),
            },
            data: [
              {
                color: getRootColor('blue-3'),
                radius: '87%',
                innerRadius: '63%',
                y: this.averageScore,
              },
            ],
            showInLegend: true,
          },
        ],
      }
    },
  },
}
</script>

<style scoped></style>
