<template>
  <basic-input class="gap-3" :class="{ 'flex-col': stackLabel }">
    <template #input-label>
      <div
        v-if="label !== ''"
        class="flex paragraph-2 gap-2 pt-4"
        :class="{ 'set-w-28 md:set-w-48': !stackLabel }">
        <label :for="name" v-html="label" />
        <info-circle :fill="tooltip.colour" :css-class="tooltip.class" :message="title" />
      </div>
    </template>
    <template #input-box>
      <input
        :id="name"
        :class="applyClasses"
        :disabled="disabled"
        :placeholder="placeHolder"
        :value="value"
        autocomplete="off"
        class="appearance-none paragraph-2 block w-full p-4 border rounded ring-2"
        inputmode="numeric"
        type="number"
        v-bind="$attrs"
        v-on="listeners"
        @wheel.prevent />
    </template>
    <template #input-errors>
      <div>
        <input-error v-if="error" :error="error" />

        <input-info v-if="getInfo !== ''" :info="getInfo" />
      </div>
    </template>
  </basic-input>
</template>

<script>
import InputError from './InputError'
import InfoCircle from '../InfoCircle/InfoCircle.vue'

import InputInfo from '@/components/BasicInputs/InputInfo.vue'
import BasicInput from '@/components/BasicInputs/BasicInput.vue'
export default {
  name: 'InputNumber',
  components: { BasicInput, InputInfo, InfoCircle, InputError },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    info: {
      type: String,
      default: '',
    },
    placeHolder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    stackLabel: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: null,
    },
    inputClasses: {
      type: String,
      default: 'paragraph-2',
    },
    tooltip: {
      type: Object,
      default: () => {
        return {
          colour: undefined,
          class: undefined,
        }
      },
    },
  },
  emits: ['input', 'blur'],
  data() {
    return { localInfo: '' }
  },
  mounted() {
    if (this.defaultValue > 0 && this.value === null) this.$emit('input', this.defaultValue)
  },
  computed: {
    listeners() {
      return {
        input: this.handleInput,
        blur: this.handleBlur,
      }
    },
    getInfo() {
      return this.info || this.localInfo
    },
    applyClasses() {
      if ((this.value === null && this.required) || this.error) {
        return this.inputClasses + ' border-red-1 ring-red-light'
      } else {
        return (
          this.inputClasses +
          ' border-grey-2 ring-transparent focus:border-otivo-blue focus:ring-blue-4'
        )
      }
    },
  },
  methods: {
    // getValue () {
    //   if (!isNaN(this.$attrs.value)) {
    //     return this.$attrs.value !== null ? Number(this.$attrs.value).toFixed(0) : (this.defaultValue ? this.defaultValue : '')
    //   }
    // },

    handleInput(event) {
      this.localInfo = ''
      event.target.value = event.target.value.replace('-', '')
      let value = Number(event.target.value)
      value = !isNaN(value) ? value : 0
      this.$emit('input', value)
    },
    handleBlur(event) {
      this.localInfo = ''
      event.target.value = event.target.value.replace('-', '')
      let value = Number(event.target.value)
      value = !isNaN(value) ? value : 0
      const min = Number(this.$attrs.min)
      const max = Number(this.$attrs.max)
      if (value < min || value > max) {
        // this.localInfo = `Min: ${min}, Max: ${max}`
        if (min > 0) this.localInfo += `Min: ${min}`
        if (min > 0 && max > 0) this.localInfo += ', '
        if (max > 0) this.localInfo += `Max: ${max}`
        if (value < min) {
          value = min
          event.target.value = min
        } else {
          value = max
          event.target.value = max
        }
        this.$emit('input', Number(event.target.value))
      }
      this.$emit('blur', event)
    },
  },
}
</script>

<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input:disabled {
  @apply border-grey-light rounded bg-grey-light ring-0 text-grey-2 border-0;
}

/* To prevent the default outline */
input:focus {
  outline: none !important;
}
</style>
