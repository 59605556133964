<template>
  <span class="inline-flex gap-[20px] items-center">
    <label id="toggle-label" :for="name">
      <span id="textFalse">{{ textFalse }}</span>
      <input
        :id="name"
        :checked="checked"
        class="paragraph-1"
        type="checkbox"
        @input="handleChange" />
      <span id="toggler" />
      <span id="textTrue">{{ textTrue }}</span>
    </label>
  </span>
</template>

<script lang="ts" setup>
type Props = {
  name: string
  checked: boolean
  textTrue?: string
  textFalse?: string
}

defineProps<Props>()

const emit = defineEmits<{ (e: 'update:checked', value: boolean): void }>()

const handleChange = (event: Event) => {
  emit('update:checked', (event.target as HTMLInputElement).checked)
}
</script>

<style scoped>
input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
}

#toggle-label {
  background-color: #fff;
  position: relative;
  width: 175px;
  height: 45px;
  margin: 0;
  border-radius: 10px;
  cursor: pointer;
}

#textFalse {
  position: absolute;
  top: 12px;
  left: 20px;
  z-index: 1;
}

#textTrue {
  position: absolute;
  top: 12px;
  right: 26px;
  z-index: 1;
}

#toggler {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--grey-4);
  border-radius: 10px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

input:focus + #toggler,
input:hover + #toggler {
  outline: 2px solid var(--otivo-blue);
  outline-offset: -1px;
  box-shadow: var(--otivo-focus-shadow);
}

#toggler:before {
  content: '';
  position: absolute;
  height: 35px;
  width: 74px;
  left: 2px;
  bottom: 5px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid var(--grey-4);
  box-shadow: var(--otivo-2px-shadow);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + #toggler:before {
  -webkit-transform: translateX(96px);
  -ms-transform: translateX(96px);
  transform: translateX(96px);
}
</style>
