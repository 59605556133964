import {
  GuidanceClientErrorsType,
  SuperAllocation,
  ClientSuperObject,
  ClientUser,
  SuperWidgetErrorType
} from '@/types/CallcentrePortalTypes.js'
import { FieldValuePairType } from '@/types/GlobalTypes'
import { validateEmailAddress, validatePhoneNumber } from '@/lib/ValidationHelper'
import { mod10Checksum } from '@/utilities'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { clientErrorTemplate, superWidgetErrorsTemplate } from '@/lib/IntrafundGuidanceHelper'

export const useGuidanceClientErrorsStore = defineStore('guidanceClientError', () => {
  const clientErrors = ref<GuidanceClientErrorsType>(clientErrorTemplate())
  const superWidgetErrors = ref<SuperWidgetErrorType>(superWidgetErrorsTemplate())

  const getClientErrors = computed(() => clientErrors.value)
  const getSuperWidgetErrors = computed(() => superWidgetErrors.value)
  const setClientErrors = (payload: GuidanceClientErrorsType) => {
    clientErrors.value = payload
  }

  const setClientErrorField = (payload: FieldValuePairType<any>) => {
    clientErrors.value[payload.field] = payload.value
  }
  const setSuperWidgetErrorField = (payload: FieldValuePairType<any>) => {
    superWidgetErrors.value[payload.field] = payload.value
  }
  const resetSuperWidgetErrors = () => (superWidgetErrors.value = superWidgetErrorsTemplate())

  const validateSuperWidget = (clientSuperObject: ClientSuperObject) => {
    resetSuperWidgetErrors()
    const accNum = clientSuperObject.accountNumber
    let valid = true
    if (
      accNum === null ||
      !mod10Checksum('001' + accNum) ||
      accNum === '' ||
      accNum.length !== 12
    ) {
      setSuperWidgetErrorField({
        field: 'accountNumber',
        value: 'Please provide a valid account number'
      })
      valid = false
    }
    if (clientSuperObject.balance === null || clientSuperObject.balance === 0) {
      setSuperWidgetErrorField({
        field: 'balance',
        value: 'Please provide your super balance'
      })
      valid = false
    }
    if (
      isNaN(clientSuperObject.chantWestProductId) ||
      clientSuperObject.chantWestProductId === null
    ) {
      setSuperWidgetErrorField({
        field: 'superFund',
        value: 'Please select your super'
      })
      valid = false
    }
    if (
      clientSuperObject.allocation.find((investment: SuperAllocation) => {
        return (
          investment.chantWestInvestmentId === null ||
          investment.chantWestInvestmentId === undefined
        )
      })
    ) {
      setSuperWidgetErrorField({
        field: 'superInvestmentOptions',
        value: 'Please assign all of your investment options'
      })
      valid = false
    }
    if (
      clientSuperObject.allocation.reduce(
        (acc: number, el: SuperAllocation) => acc + el.percentAllocation,
        0
      ) !== 100
    ) {
      setSuperWidgetErrorField({
        field: 'superInvestmentOptions',
        value: 'Make sure your allocation percentage adds up to 100%'
      })
      valid = false
    }
    return valid
  }

  const validateClientAccount = (
    guidanceClient: ClientUser,
    clientSuperObject: ClientSuperObject
  ) => {
    setClientErrors(clientErrorTemplate())
    setSuperWidgetErrorField({ field: 'accountNumber', value: '' })

    let valid = true
    const errorPayload = clientErrorTemplate()

    // form validation
    if (guidanceClient.memberId === '' || guidanceClient.memberId === null) {
      errorPayload.memberId = 'Please enter the member id'
      valid = false
    }

    // form and CFS validation
    if (
      guidanceClient.memberId === '' ||
      !mod10Checksum(String(guidanceClient.memberId)) ||
      guidanceClient.memberId?.length !== 8
    ) {
      errorPayload.memberId = 'Please enter a valid member id'
      valid = false
    }
    //form validation
    if (clientSuperObject.accountNumber === '' || clientSuperObject.accountNumber === null) {
      setSuperWidgetErrorField({ field: 'accountNumber', value: 'Please enter the account number' })
      valid = false
    }

    // form and CFS validation
    if (
      !mod10Checksum('001' + clientSuperObject.accountNumber) ||
      clientSuperObject.accountNumber === '' ||
      clientSuperObject.accountNumber.length !== 12
    ) {
      setSuperWidgetErrorField({
        field: 'accountNumber',
        value: 'Please enter a valid account number'
      })
      valid = false
    }

    // form validation
    if (guidanceClient.firstName === '') {
      errorPayload.firstName = 'Please enter a first name'
      valid = false
    }
    // form validation
    if (guidanceClient.lastName === '') {
      errorPayload.lastName = 'Please enter a last name'
      valid = false
    }
    // form validation
    if (!validateEmailAddress(guidanceClient.email)) {
      errorPayload.email = 'Please provide a valid email'
      valid = false
    }
    // form validation
    if (!validatePhoneNumber(guidanceClient.mobile)) {
      errorPayload.mobile = 'Please provide a valid phone number'
      valid = false
    }
    // form validation
    if (guidanceClient.dob === '') {
      errorPayload.dob = 'Please provide the date of birth'
      valid = false
    }
    setClientErrors(errorPayload)
    return valid
  }

  return {
    getClientErrors,
    getSuperWidgetErrors,
    setClientErrors,
    setClientErrorField,
    setSuperWidgetErrorField,
    resetSuperWidgetErrors,
    validateSuperWidget,
    validateClientAccount
  }
})
