<template>
  <div class="bg-otivo-blue w-full py-20 text-white">
    <div class="h3 md:text-center px-[20px]">
      Otivo’s plug-and-play platform makes
      <span :class="{ 'svg-underline-wide': !isUserOnMobile() }"
        >getting started <span :class="{ 'svg-underline': isUserOnMobile() }">easy</span>
      </span>
    </div>
    <div class="flex justify-center mt-10">
      <div class="w-[500px] sm:-mr-6 mt-5 md:max-h-[700px] overflow-hidden">
        <div class="line-gradient h-1px mb-1px md:ml-5" />
        <div v-for="(page, index) in pages" :key="index" class="accordion-container">
          <div
            class="flex justify-between cursor-pointer py-[15px] pr-4"
            :class="[
              pageNumber === index ? 'bg-blue-1 pl-6 md:rounded-l-circle -mt-1' : 'ml-6',
              { 'container-open': pageNumber === index },
            ]"
            @click="pageNumber = index">
            <div class="button-1">
              {{ page.heading }}
            </div>
            <span class="flex-end self-center">
              <DropdownArrow
                class="self-center mr-4 w-3 duration-300"
                stroke="white"
                :class="
                  pageNumber === index
                    ? 'transition transform rotate-0 '
                    : 'transition transform rotate-180'
                " />
            </span>
          </div>
          <section class="text-container pr-10 ml-6">
            <div class="h5 font-bold mt-5" v-html="page.subHeading" />
            <div class="paragraph-1 mt-4 mb-5" v-html="page.body" />
            <img
              class="sm:hidden w-[340px] h-[300px] m-auto"
              :src="page.src"
              alt="accordion-slideshow-mobile-image" />
          </section>
          <div class="line-gradient h-1px md:ml-5" />
        </div>
      </div>
      <img
        class="hidden lg:block w-[700px] h-[740px] transition-all"
        :src="pages[pageNumber].src"
        alt="accordion-slideshow-image" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import DropdownArrow from '@/components/icons/DropdownArrow.vue'
import { isUserOnMobile } from '@/utilities.ts'

const pageNumber = ref(0)

const pages = [
  {
    heading: 'Super-sized financial advice',
    subHeading: 'How Otivo works for super funds',
    body: "The level of integration and customisation is up to you. This means we can build a hybrid solution where members access Otivo on their devices with phone-based support from your call centre. Or we can simply provide members with a stand-alone web solution. Each service can be funded via member admin fees or their account, while the advice can be under Otivo's license or a super funds AFSL.",
    src: 'https://store.otivo.com/HomePageImages/SuperFundPageScreens/SuperInvestments.png',
  },
  {
    heading: 'Discovering advice',
    subHeading: 'How members discover advice',
    body: "While visiting their super fund's website, a member will notice a new service called 'Get financial advice.' Or if they call a super fund directly, they can be guided through the setup process over the phone. In this option, both the super fund and the member can see the same screens simultaneously.",
    src: 'https://store.otivo.com/HomePageImages/SuperFundPageScreens/Dashboard.png',
  },
  {
    heading: 'Getting started',
    subHeading: 'Members pick their path',
    body: 'Members can choose Intra-fund advice which covers things like investment optimisation, contributions (including salary sacrifice), insurance, and retirement projections. Best of all, members can enjoy unlimited 24/7 access to Otivo.',
    src: 'https://store.otivo.com/HomePageImages/SuperFundPageScreens/SuperFundTemplate.png',
  },
  {
    heading: 'Personalised advice',
    subHeading: 'The advice',
    body: "After answering some questions about their current situation and future goals, Otivo's advice algorithm gets to work. For contributions, Otivo considers the member's age and savings to see if it's a good idea. It can also help them figure out carry-forward contributions, factoring in their cash flow, current tax rate, and past contributions.",
    src: 'https://store.otivo.com/HomePageImages/SuperFundPageScreens/SalarySacrifice.png',
  },
  {
    heading: 'Implementing the advice',
    subHeading: 'The advice',
    body: 'The member can decide to make the change or stay put. To give them a hand, we’ll provide helpful reminders or provide more details to refine their plan. Depending on how much integration a super fund chooses, the advice can be implemented by you directly, or via instructions from Otivo. For more complicated items like contributions, we’ll guide them through every step with detailed instructions. Otivo also offers personal insurance needs analysis.',
    src: 'https://store.otivo.com/HomePageImages/SuperFundPageScreens/HowToImplement.png',
  },
  // {
  //   heading: 'Annuities and downsizing',
  //   subHeading: 'Retirement planning',
  //   body: "Otivo can also provide members with guidance when they're nearing retirement by opening their eyes to other income streams like annuities or fixed-term pensions. Our advice includes detailed personalised information with handy do-it-yourself calculators to help them understand how it all works. If a member is considering downsizing their home, we can also help them work out extra contributions.",
  //   src: 'https://store.otivo.com/HomePageImages/SuperFundPageScreens/Retirement.png',
  // },
  {
    heading: 'Insights and reporting',
    subHeading: 'Target members individually',
    body: "Super funds also have access to a dedicated admin portal. This powerful tool means the days of cohort communication are over. Super funds can now use Otivo's insights to create personalised messages and services for individual members. Detailed reporting tools can also be used to build member engagement, discover new business opportunities and — most importantly — help members be better off.",
    src: 'https://store.otivo.com/HomePageImages/SuperFundPageScreens/RetirementEmail.png',
  },
  {
    heading: 'Legal and regulatory compliance',
    subHeading: 'Licensed financial advice',
    body: "One of the unique things about Otivo is that we're fully licensed to provide personal financial advice. This means we take full legal responsibility, with every piece of advice written and audited by relevant providers. It also means we face the same compliance obligations as traditional advisers, like holding records for seven years. Members can easily access their advice records too via their Statement of Advice (SoA).",
    src: 'https://store.otivo.com/HomePageImages/SuperFundPageScreens/DownloadSOA.png',
  },
]
</script>

<style scoped>
.accordion-container section {
  margin-top: -1px;
  overflow: hidden;
  height: 0;
  position: relative;
  z-index: 10;
  transition:
    height 0.3s ease-in-out,
    box-shadow 0.6s linear;
}
.accordion-container .container-open ~ section {
  transition:
    height 0.5s ease-in-out,
    box-shadow 0.1s linear;
}
.accordion-container .container-open ~ section.text-container {
  height: 620px;
  @media screen and (min-width: 640px) {
    height: 280px;
  }
}
.line-gradient {
  background: linear-gradient(90deg, #0037a1, #0037a1, #0064ff);
}
</style>
