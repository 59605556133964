<template>
  <path
    d="M72.014 90H57.7828L57.6562 84.5438C57.6413 84.0675 57.4452 83.6148 57.1078 83.2782C56.9369 83.1049 56.7333 82.9672 56.5088 82.8731C56.2843 82.7789 56.0434 82.7302 55.8 82.7297H37.9687C37.7253 82.7302 37.4844 82.7789 37.2599 82.8731C37.0355 82.9672 36.8319 83.1049 36.6609 83.2782C36.3235 83.6148 36.1274 84.0675 36.1125 84.5438L35.9156 90H21.6844V77.3438C15.9292 73.413 11.5868 67.7398 9.29531 61.1579H0V39.5438H9.29531C11.5903 32.9638 15.932 27.2915 21.6844 23.3579V6.27191L30.9375 14.2453C32.3223 15.4225 33.4681 16.8547 34.3125 18.4641C36.0401 18.1533 37.7889 17.9747 39.5437 17.9297H46.575L47.6016 22.261C49.0629 28.4929 52.6962 34.0005 57.8502 37.7964C63.0041 41.5923 69.3417 43.4282 75.7265 42.975C77.0078 42.8944 78.2815 42.7205 79.5375 42.4547L85.8656 41.0485L86.3437 47.7844C86.8119 53.5336 85.7341 59.3034 83.2219 64.4959C80.7098 69.6884 76.8547 74.1145 72.0562 77.3157L72.014 90ZM37.9687 71.4797H55.7719C57.4845 71.476 59.181 71.8105 60.764 72.4641V71.4094L65.7562 68.0625C68.9022 65.956 71.4232 63.0416 73.0547 59.625C73.8685 57.9172 74.4593 56.1118 74.8125 54.2532C66.8416 54.4601 59.0053 52.1708 52.3993 47.7055C45.7934 43.2401 40.748 36.822 37.9687 29.3485C37.4344 29.3485 36.9 29.475 36.3656 29.5735L31.1344 30.5297L27.9984 32.6672C24.2389 35.2278 21.4037 38.9308 19.9125 43.2282L17.4375 50.386L19.9266 57.5438C21.4205 61.8434 24.2547 65.5502 28.0125 68.1188L32.9344 71.4657V72.4641C34.5305 71.8051 36.2419 71.4704 37.9687 71.4797Z"
    :fill="fill" />
  <path
    d="M73.125 33.75C69.7874 33.75 66.5248 32.7603 63.7498 30.9061C60.9747 29.0518 58.8118 26.4163 57.5345 23.3328C56.2573 20.2493 55.9231 16.8563 56.5743 13.5829C57.2254 10.3094 58.8326 7.30259 61.1926 4.94258C63.5526 2.58257 66.5594 0.975382 69.8329 0.324256C73.1063 -0.32687 76.4993 0.0073117 79.5828 1.28454C82.6663 2.56177 85.3018 4.72468 87.1561 7.49976C89.0103 10.2748 90 13.5374 90 16.875C90 21.3505 88.2221 25.6428 85.0574 28.8074C81.8928 31.9721 77.6005 33.75 73.125 33.75ZM73.125 11.25C72.0125 11.25 70.9249 11.5799 69.9999 12.198C69.0749 12.8161 68.3539 13.6946 67.9282 14.7224C67.5024 15.7502 67.391 16.8812 67.6081 17.9724C67.8251 19.0635 68.3609 20.0658 69.1475 20.8525C69.9342 21.6392 70.9365 22.1749 72.0276 22.3919C73.1188 22.609 74.2498 22.4976 75.2776 22.0718C76.3054 21.6461 77.1839 20.9251 77.802 20.0001C78.4201 19.0751 78.75 17.9875 78.75 16.875C78.75 15.3832 78.1574 13.9524 77.1025 12.8975C76.0476 11.8426 74.6168 11.25 73.125 11.25Z"
    :fill="fill" />
</template>

<script>
export default {
  name: 'SavingsTile',
  props: {
    fill: { type: String, default: '#D4E7FE' }
  }
}
</script>

<style scoped></style>
