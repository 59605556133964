<template>
  <svg width="12" height="13" viewBox="0 0 12 13" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_14666_217871)">
      <path
        d="M3.00009 11.6257C2.99941 11.4696 3.05324 11.3171 3.15437 11.1886L6.96003 6.37362L3.15437 1.55675C3.02658 1.39439 2.9753 1.19512 3.01123 1.00047C3.04716 0.80583 3.16752 0.630835 3.34723 0.511967C3.43539 0.455659 3.53559 0.41544 3.64186 0.3937C3.74814 0.371961 3.85832 0.369143 3.96586 0.385416C4.0734 0.401689 4.17611 0.43672 4.26788 0.488427C4.35965 0.540134 4.43861 0.607462 4.50007 0.68641L9 6.37362L4.50006 12.0608C4.4386 12.1406 4.35933 12.2086 4.26702 12.2608C4.17472 12.3131 4.07129 12.3484 3.96298 12.3647C3.85466 12.381 3.74369 12.3779 3.63676 12.3557C3.52984 12.3334 3.42916 12.2925 3.3408 12.2353C3.23328 12.1642 3.1464 12.072 3.0872 11.9661C3.02801 11.8602 2.99816 11.7436 3.00009 11.6257Z"
        fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_14666_217871">
        <rect width="12" height="12" fill="white" transform="matrix(0 -1 1 0 0 12.375)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ChevronRightIcon'
}
</script>
