<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="currentColor">
    <g clip-path="url(#clip0_2983_1999)">
      <path
        d="M1.24934 3.00009C1.40538 2.99941 1.55788 3.05324 1.68638 3.15437L6.50138 6.96003L11.3183 3.15437C11.4806 3.02658 11.6799 2.9753 11.8745 3.01123C12.0692 3.04716 12.2442 3.16752 12.363 3.34723C12.4193 3.43539 12.4596 3.53559 12.4813 3.64186C12.503 3.74814 12.5059 3.85832 12.4896 3.96586C12.4733 4.0734 12.4383 4.17611 12.3866 4.26788C12.3349 4.35965 12.2675 4.43861 12.1886 4.50007L6.50138 9L0.81417 4.50006C0.734414 4.4386 0.666375 4.35933 0.614151 4.26702C0.561929 4.17472 0.526604 4.07129 0.510305 3.96298C0.494006 3.85466 0.497069 3.74369 0.519312 3.63676C0.541555 3.52984 0.582516 3.42916 0.639728 3.3408C0.710758 3.23328 0.80297 3.1464 0.908886 3.0872C1.0148 3.02801 1.13144 2.99816 1.24934 3.00009Z"
        fill="#A0A0A0" />
    </g>
    <defs>
      <clipPath id="clip0_2983_1999">
        <rect width="12" height="12" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts" setup></script>
