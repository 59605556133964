<template>
  <div class="flex flex-row items-center">
    <component
      v-if="icon"
      class="self-center min-h-[12px] min-w-[13px]"
      :class="fill ? `fill-${fill}` : undefined"
      :is="icon" />
    <CircleCheck
      v-else
      class="self-center min-w-[25px] max-w-[25px] min-h-[25px]"
      :ring="getRootColor('blue-4')" />
    <p class="button-1 ml-[10px]">
      {{ text }}
    </p>
  </div>
</template>

<script setup lang="ts">
import CircleCheck from '@/components/icons/CircleCheck.vue'
import { getRootColor } from '@/lib/helpers'

interface Props {
  icon?: unknown
  text: string
  fill?: string
}

defineProps<Props>()
</script>
