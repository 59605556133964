<template>
  <div>
    <ul class="list-disc list-inside">
      <li>
        <span>When switching, you should read and keep a copy of the current </span>
        <a target="_blank" :href="getPdsLink()"
          ><strong>Product Disclosure Statement (PDS)</strong></a
        >
      </li>
      <li>
        A buy/sell spread cost applies when switching between investment options (refer to the
        current PDS for further details).
      </li>
      <li v-if="hasTermDeposit && !isOtivoWhitelabel">
        We recommend you consult Otivo for advice once your term deposit reaches its maturity, and
        on an annual basis thereafter. This approach will ensure your investment strategy remains
        optimised.
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import SuperObject from '@/types/api/super/SuperObject.ts'
import getPdsLink from '@/composables/superHelpers/getPdsLink.ts'
import { useWhitelabelIdentifier } from '@/composables/isOtivoWhiteLabel.ts'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'

type Props = {
  super: SuperObject
}
const props = defineProps<Props>()

const whitelabelIdentifier = useWhitelabelIdentifier(useSessionStore().getWhitelabelData)

const hasTermDeposit = computed(
  () => (props.super.investments.filter((value) => value.is_term_deposit) || []).length,
)
const isOtivoWhitelabel = computed(() => whitelabelIdentifier.isOtivoWhitelabel())
</script>
