import { cloneObject } from '@/utilities'
import {
  GuidanceClientErrorsType,
  ClientSuperObject,
  ClientUser,
  SuperWidgetErrorType,
} from '@/types/CallcentrePortalTypes.ts'
import SuperAllocation from '@/types/api/super/SuperAllocation.ts'
import superAllocationTemplate from '@/composables/superHelpers/superAllocationTemplate.ts'

// NOTE: NO LONGER USED

export const GuidanceClientSuperObjectTemplate = (): ClientSuperObject => {
  return cloneObject({
    id: '',
    accountNumber: null,
    balance: null,
    fundName: '-',
    chantWestProductId: '',
    intrafundAdviceStage: 1,
    noInvestmentOptions: 0,
    requestedChangeId: null,
    lastActioned: null,
    hasTermDepositAllocation: false,
    allocation: [superAllocationTemplate()],
  })
}

export const clientErrorTemplate = (): GuidanceClientErrorsType => {
  return cloneObject({
    memberId: '',
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    dob: '',
  })
}

export const superWidgetErrorsTemplate = (): SuperWidgetErrorType => {
  return cloneObject({
    accountNumber: '',
    balance: '',
    superFund: '',
    superInvestmentOptions: '',
  })
}

export const managedClientTemplate = (): ClientUser => {
  return cloneObject({
    firstName: '',
    middleName: '',
    lastName: '',
    hasPartner: false,
    email: '',
    dob: '',
    mobile: '',
    memberId: null,
    // auth0Id: '',
    accountId: null,
    // createdAt: '',
    // lastModified: '',
    // preservationAge: null
  })
}
