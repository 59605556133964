<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7945_206772)">
      <path
        d="M13.5 3.57375V1.5C13.5 1.10218 13.342 0.720645 13.0607 0.43934C12.7794 0.158036 12.3978 0 12 0C11.6022 0 11.2206 0.158036 10.9393 0.43934C10.658 0.720645 10.5 1.10218 10.5 1.5V3.57375C9.21343 3.90594 8.07352 4.65581 7.2591 5.70574C6.44468 6.75566 6.00182 8.04623 6 9.375C6 9.77282 6.15804 10.1544 6.43934 10.4357C6.72064 10.717 7.10218 10.875 7.5 10.875C7.89782 10.875 8.27936 10.717 8.56066 10.4357C8.84196 10.1544 9 9.77282 9 9.375C9 8.57935 9.31607 7.81629 9.87868 7.25368C10.4413 6.69107 11.2044 6.375 12 6.375C12.7956 6.375 13.5587 6.69107 14.1213 7.25368C14.6839 7.81629 15 8.57935 15 9.375C15 9.77282 15.158 10.1544 15.4393 10.4357C15.7206 10.717 16.1022 10.875 16.5 10.875C16.8978 10.875 17.2794 10.717 17.5607 10.4357C17.842 10.1544 18 9.77282 18 9.375C17.9982 8.04623 17.5553 6.75566 16.7409 5.70574C15.9265 4.65581 14.7866 3.90594 13.5 3.57375Z"
        fill="#FF0000" />
      <path
        d="M22.5 21.0188V12.75C22.8978 12.75 23.2794 12.592 23.5607 12.3107C23.842 12.0294 24 11.6478 24 11.25C24 10.8522 23.842 10.4706 23.5607 10.1893C23.2794 9.90804 22.8978 9.75 22.5 9.75H1.5C1.10218 9.75 0.720645 9.90804 0.43934 10.1893C0.158036 10.4706 0 10.8522 0 11.25C0 11.6478 0.158036 12.0294 0.43934 12.3107C0.720645 12.592 1.10218 12.75 1.5 12.75V21C1.10218 21.0244 0.730324 21.2058 0.466249 21.5043C0.202175 21.8028 0.0675085 22.1941 0.0918753 22.5919C0.116242 22.9897 0.297646 23.3616 0.59618 23.6256C0.894714 23.8897 1.28593 24.0244 1.68375 24H22.3088C22.7066 24.0244 23.0978 23.8897 23.3963 23.6256C23.6949 23.3616 23.8763 22.9897 23.9006 22.5919C23.925 22.1941 23.7903 21.8028 23.5263 21.5043C23.2622 21.2058 22.8903 21.0244 22.4925 21L22.5 21.0188ZM19.5 21.0188H16.5V12.75H19.5V21.0188ZM13.5 21H10.5V12.75H13.5V21ZM7.5 12.75V21H4.5V12.75H7.5Z"
        fill="#0064FF" />
    </g>
    <defs>
      <clipPath id="clip0_7945_206772">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'BankAltIcon'
}
</script>

<style scoped></style>
