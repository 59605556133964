<template>
  <div id="three-tile-display">
    <RegularCurve
      v-if="topCurveFill"
      :class="[`stroke-${topCurveFill}`]"
      :fill="topCurveFill"
      :rotate="rotateTopCurve"
      class="w-full" />
    <div
      class="flex flex-col lg:flex-row items-center lg:items-baseline justify-center gap-20 m-auto py-14">
      <div
        :key="index"
        v-for="(iconBox, index) in iconBoxes"
        class="flex md:flex-col text-left md:text-center w-[315px] md:w-300px gap-[20px]"
        @mouseenter="imgsAnimated[index].hover = true"
        @mouseleave="imgsAnimated[index].hover = false">
        <div class="text-center rounded-circle m-auto p-1 max-w-20" :class="'bg-' + circleColour">
          <img
            :alt="iconBox.alt"
            :src="
              imgsAnimated[index].hover
                ? imgsAnimated[index].animatedSrc
                : imgsAnimated[index].staticSrc
            " />
        </div>
        <div>
          <h5 class="h5 bold md:mt-5" :class="`text-${textColour}`">
            {{ iconBox.heading }}
          </h5>
          <p class="paragraph-1 md:paragraph-1 mt-3 md:mt-5" :class="`text-${textColour}`">
            {{ iconBox.body }}
          </p>
        </div>
      </div>
    </div>
    <RegularCurve
      v-if="bottomCurveFill"
      :class="[`stroke-${bottomCurveFill}`]"
      :fill="bottomCurveFill" />
  </div>
</template>

<script lang="ts" setup>
import RegularCurve from '@/components/Shapes/RegularCurve.vue'
import LightningAnimated from '@/assets/img/lightning_animated.gif'
import ShieldAnimated from '@/assets/img/shield_animated.gif'
import ScaleAnimated from '@/assets/img/scale_animated.gif'
import Lightning from '@/assets/img/lightning.png'
import Scale from '@/assets/img/scale.png'
import Shield from '@/assets/img/shield.png'
import { reactive } from 'vue'
import { IterableItemDefaultType } from '@/types/globalComponents/IterableItemDefaultType'

interface Props {
  iconBoxes: Array<IterableItemDefaultType>
  fill1?: string
  fill2?: string
  topCurveFill?: string
  rotateTopCurve?: boolean
  bottomCurveFill?: string
  rotateBottomCurve?: boolean
  textColour?: string
  circleColour?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red',
  topCurveFill: undefined,
  rotateTopCurve: true,
  bottomCurveFill: undefined,
  rotateBottomCurve: true,
  textColour: 'grey-1',
  iconBoxes: undefined,
  circleColour: 'white',
})

const imgsAnimated: Array<unknown> = reactive([
  { hover: false, animatedSrc: LightningAnimated, staticSrc: Lightning, alt: 'LightningAnimated' },
  { hover: false, animatedSrc: ScaleAnimated, staticSrc: Scale, alt: 'ScaleAnimated' },
  { hover: false, animatedSrc: ShieldAnimated, staticSrc: Shield, alt: 'ShieldAnimated' },
])
</script>
