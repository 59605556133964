import getApiInstance from '@/services/Api'

const api = getApiInstance()
export default {
  //TODO: review and refactor this entire collection

  getGraphAssumptions(planType) {
    return api.Get(`/graphAssumptions/${planType}`)
  },

  validateCoupon(payload) {
    return api.Get(`/coupon/validate/${payload}`)
  },

  getWhitelabelValues() {
    return api.Get('/getWhitelabelValues')
  },

  calculateFitnessTestScore(payload) {
    return api.Post('/financial_fitness_test', payload)
  },

  getEmailByHash(payload) {
    return api.Post('/getEmailByHash', payload)
  },

  getUserHistory() {
    return api.Get('/history')
  },

  getProducts() {
    return api.Get('/products')
  },

  getProductFeatures(product) {
    return api.Post('/products/features', { product: product })
  },

  getAccountFlags() {
    return api.Get('/account/flags')
  },

  getAccountFlagObject() {
    return api.Get('/account/flags/object')
  },

  postAccountFlags(payload) {
    return api.Post('/account/flags', payload)
  },

  fetchLabelContent() {
    return api.Get('/label_content')
  },

  getLabelContent(payload) {
    return api.Post('/label_content', payload)
  },

  getPageContent(pageRef: string) {
    return api.Get(`/page_content/${pageRef}`)
  },
}
