<template>
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_19553_215320)">
      <circle cx="2" cy="10.6875" r="2" fill="#696969" />
      <circle cx="10" cy="10.6875" r="2" fill="#696969" />
      <circle cx="6" cy="2.6875" r="2" fill="#696969" />
    </g>
    <defs>
      <clipPath id="clip0_19553_215320">
        <rect width="12" height="12" fill="white" transform="translate(0 0.6875)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script setup lang="ts"></script>
