<template>
  <div
    id="risk-profile-tile"
    class="rounded-[20px] md:otivo-drop-shadow md:border border-blue-4 p-[20px] md:p-[40px] pt-[41px] md:pt-[61px] mt-[40px] flex flex-col">
    <div class="flex flex-col md:flex-row items-end gap-[26px]">
      <div class="flex flex-col w-100%" data-test="highGrowthDiv">
        <RiskProfileFlag
          :flag="
            currentInvestment === 'high growth' && recommendedInvestment === 'high growth'
              ? 'both'
              : currentInvestment === 'high growth'
              ? 'current'
              : recommendedInvestment === 'high growth'
              ? 'recommended'
              : null
          " />
        <div class="high-growth flex flex-col w-100%">
          <div class="flex flex-row md:flex-col h-[30px] md:h-[100px] w-100%">
            <div
              class="orange-box flex w-85% md:w-100% bg-gold-1 border-r md:border-r-0 md:border-b border-white h-100% md:h-85% justify-center">
              <p class="text-white hidden md:flex items-center menu-2">GROWTH</p>
            </div>
            <div
              class="green-box flex w-15% md:w-100% bg-green-4 border-l md:border-l-0 md:border-t border-white h-100% md:h-15% justify-center">
              <p class="text-black hidden md:flex items-center menu-2">INCOME</p>
            </div>
          </div>
          <p
            class="paragraph-2 inline-block md:flex md:flex-col md:mt-[10px] md:text-center text-grey-2">
            <span class="button-2 md:!text-16px md:mb-[10px] text-black">High growth</span> Up to
            age 43
          </p>
        </div>
      </div>
      <div class="flex flex-col w-100%" data-test="growthDiv">
        <RiskProfileFlag
          :flag="
            currentInvestment === 'growth' && recommendedInvestment === 'growth'
              ? 'both'
              : currentInvestment === 'growth'
              ? 'current'
              : recommendedInvestment === 'growth'
              ? 'recommended'
              : null
          " />
        <div class="high-growth flex flex-col w-100%">
          <div class="flex flex-row md:flex-col h-[30px] md:h-[100px] w-100%">
            <div
              class="orange-box flex w-66% md:w-100% bg-gold-1 border-r md:border-r-0 md:border-b border-white h-100% md:h-66% justify-center">
              <p class="text-white hidden md:flex items-center menu-2">GROWTH</p>
            </div>
            <div
              class="green-box flex w-37% md:w-100% bg-green-4 border-l md:border-l-0 md:border-t border-white h-100% md:h-37% justify-center">
              <p class="text-black hidden md:flex items-center menu-2">INCOME</p>
            </div>
          </div>
          <p
            class="paragraph-2 inline-block md:flex md:flex-col md:mt-[10px] md:text-center text-grey-2">
            <span class="button-2 md:!text-16px md:mb-[10px] text-black">Growth</span> 44 to 52
          </p>
        </div>
      </div>
      <div class="flex flex-col w-100%" data-test="balancedDiv">
        <RiskProfileFlag
          :flag="
            currentInvestment === 'balanced' && recommendedInvestment === 'balanced'
              ? 'both'
              : currentInvestment === 'balanced'
              ? 'current'
              : recommendedInvestment === 'balanced'
              ? 'recommended'
              : null
          " />
        <div class="high-growth flex flex-col w-100%">
          <div class="flex flex-row md:flex-col h-[30px] md:h-[100px] w-100%">
            <div
              class="orange-box flex w-50% md:w-100% bg-gold-1 border-r md:border-r-0 md:border-b border-white h-100% md:h-50% justify-center">
              <p class="text-white hidden md:flex items-center menu-2">GROWTH</p>
            </div>
            <div
              class="green-box flex w-50% md:w-100% bg-green-4 border-l md:border-l-0 md:border-t border-white h-100% md:h-50% justify-center">
              <p class="text-black hidden md:flex items-center menu-2">INCOME</p>
            </div>
          </div>
          <p
            class="paragraph-2 inline-block md:flex md:flex-col md:mt-[10px] md:text-center text-grey-2">
            <span class="button-2 md:!text-16px md:mb-[10px] text-black">Balanced</span> 53 to 62
          </p>
        </div>
      </div>
      <div class="flex flex-col w-100%" data-test="conservativeDiv">
        <RiskProfileFlag
          :flag="
            currentInvestment === 'conservative' && recommendedInvestment === 'conservative'
              ? 'both'
              : currentInvestment === 'conservative'
              ? 'current'
              : recommendedInvestment === 'conservative'
              ? 'recommended'
              : null
          " />
        <div class="high-growth flex flex-col w-100%">
          <div class="flex flex-row md:flex-col h-[30px] md:h-[100px] w-100%">
            <div
              class="orange-box flex w-37% md:w-100% bg-gold-1 border-r md:border-r-0 md:border-b border-white h-100% md:h-37% justify-center">
              <p class="text-white hidden md:flex items-center menu-2">GROWTH</p>
            </div>
            <div
              class="green-box flex w-66% md:w-100% bg-green-4 border-l md:border-l-0 md:border-t border-white h-100% md:h-66% justify-center">
              <p class="text-black hidden md:flex items-center menu-2">INCOME</p>
            </div>
          </div>
          <p
            class="paragraph-2 inline-block md:flex md:flex-col md:mt-[10px] md:text-center text-grey-2">
            <span class="button-2 md:!text-16px md:mb-[10px] text-black">Conservative</span> 63 and
            over
          </p>
        </div>
      </div>
      <div class="flex flex-col w-100%" data-test="veryConservativeDiv">
        <RiskProfileFlag
          :flag="
            currentInvestment === 'very conservative' &&
            recommendedInvestment === 'very conservative'
              ? 'both'
              : currentInvestment === 'very conservative'
              ? 'current'
              : recommendedInvestment === 'very conservative'
              ? 'recommended'
              : null
          " />
        <div class="high-growth flex flex-col w-100%">
          <div class="flex flex-row md:flex-col h-[30px] md:h-[100px] w-100%">
            <div
              class="orange-box flex w-15% md:w-100% bg-gold-1 border-r md:border-r-0 md:border-b border-white h-100% md:h-15% justify-center">
              <p class="text-white hidden md:flex items-center menu-2">GROWTH</p>
            </div>
            <div
              class="green-box flex w-85% md:w-100% bg-green-4 border-l md:border-l-0 md:border-t border-white h-100% md:h-85% justify-center">
              <p class="text-black hidden md:flex items-center menu-2">INCOME</p>
            </div>
          </div>
          <p
            class="paragraph-2 inline-block md:flex md:flex-col md:mt-[10px] md:text-center text-grey-2">
            <span class="button-2 md:!text-16px md:mb-[10px] px-[20px] text-black"
              >Very conservative</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="flex flex-row mt-[40px] gap-[40px] self-center">
      <div class="flex flex-row items-center">
        <div class="bg-gold-1 h-[18px] w-[18px] mr-[10px]" />
        <p class="button-3 !font-bold flex">Growth Assets</p>
      </div>
      <div class="flex flex-row">
        <div class="flex flex-row items-center">
          <div class="bg-green-4 h-[18px] w-[18px] mr-[10px]" />
          <p class="button-3 !font-bold flex">Income Assets</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import RiskProfileFlag from '@/components/SuperWidget/RiskProfileFlag.vue'
import { InvestmentOption } from '@/components/SuperWidget/SuperWidgetAdviceType.ts'

type Props = {
  investmentOptionsArray: InvestmentOption[]
}
const props = defineProps<Props>()
const currentInvestment = computed(() => {
  if (props.investmentOptionsArray[0]) {
    return props.investmentOptionsArray[0].risk_profile?.toLowerCase()
  }
  return null
})
const recommendedInvestment = computed(() => {
  const recommended = props.investmentOptionsArray.find(
    (option) => option.tag === 'recommended' || option.tag === 'mysuper',
  )
  if (recommended) {
    return recommended.risk_profile?.toLowerCase()
  }
  return currentInvestment.value
})
</script>

<style scoped></style>
