<template>
  <div
    v-if="isItSunsuperemployees"
    v-tooltip="{ content: 'Speak to a member of our Sunsuper Advice Team' }">
    <OtivoButton colour="white" class="w-full" @click="addGoogleTag()"> Get Advice </OtivoButton>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OtivoButton from '@/components/OtivoButton.vue'

export default {
  name: 'SunSuperGetAdviceButton',
  components: { OtivoButton },
  methods: {
    addGoogleTag() {
      location.href = 'mailto:advice@sunsuper.com.au'
      this.$gtag.event('ContactAdvisor', { method: 'staffbenefits' })
    }
  },
  computed: {
    ...mapGetters(['isItSunsuperemployees'])
  }
}
</script>

<style scoped></style>
