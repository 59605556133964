<template>
  <svg height="18" viewBox="0 0 23 18" width="23" xmlns="http://www.w3.org/2000/svg">
    <path
      :class="`fill-${fill1}`"
      :fill="fill1"
      fill-rule="nonzero"
      d="M7.812 17.614L.337 10.139a1.15 1.15 0 0 1 0-1.626l1.626-1.627a1.15 1.15 0 0 1 1.626 0l5.036 5.036L19.41 1.136a1.15 1.15 0 0 1 1.627 0l1.626 1.627c.45.449.45 1.177 0 1.626L9.438 17.614a1.15 1.15 0 0 1-1.626 0z" />
  </svg>
</template>

<script lang="ts" setup>
type Props = {
  fill1?: string
}

withDefaults(defineProps<Props>(), {
  fill1: '#8A55CC',
})
</script>
