import OptimizeAdviceService from '@/services/OptimizeAdviceService'
type ListObjectType = {
  value: string
  name: string
}
export const getSuperFundNames = async (name = ''): Promise<ListObjectType[]> => {
  const response = await OptimizeAdviceService.getSuperFundNames(name)
  if (!response) return []
  const superFunds: ListObjectType[] = []
  Object.keys(response.data).forEach((key) => {
    superFunds.push({
      value: response.data[key].id,
      name: response.data[key].longName,
    })
  })
  return superFunds
}

export const generateOtivoAccountNumber = () => {
  const base = Math.floor(Math.random() * 1000000000).toString()
  return '07150' + base
}
