<template>
  <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Shield">
      <path
        :class="`fill-${fill2}`"
        id="Vector"
        d="M9.62501 16.7969C9.41976 16.797 9.21649 16.7568 9.02681 16.6783C8.83713 16.5999 8.66476 16.4849 8.51954 16.3398L5.00392 12.8242C4.71073 12.531 4.54602 12.1334 4.54602 11.7187C4.54602 11.3041 4.71073 10.9065 5.00392 10.6133C5.29711 10.3201 5.69476 10.1554 6.10939 10.1554C6.52402 10.1554 6.92167 10.3201 7.21486 10.6133L9.59376 12.9922L15.1289 7.13281C15.4138 6.83133 15.8068 6.65538 16.2214 6.64366C16.6361 6.63194 17.0384 6.78541 17.3399 7.07031C17.6413 7.35521 17.8173 7.7482 17.829 8.16284C17.8407 8.57747 17.6873 8.97977 17.4024 9.28125L10.7617 16.3125C10.6179 16.4634 10.4453 16.584 10.2541 16.6672C10.0629 16.7504 9.85695 16.7945 9.64845 16.7969H9.62501Z" />
      <path
        :class="`fill-${fill1}`"
        id="Vector_2"
        d="M11.5781 25L3.48047 20.5781C2.50567 20.0497 1.69124 19.2684 1.12278 18.3164C0.554316 17.3644 0.252827 16.2768 0.25 15.168V2.76953L11.1875 0L22.125 2.76953V15.3711C22.12 16.4536 21.8308 17.5158 21.2866 18.4515C20.7423 19.3872 19.9619 20.1636 19.0234 20.7031L11.5781 25ZM3.375 5.16797V15.168C3.3757 15.7225 3.52638 16.2665 3.81108 16.7424C4.09578 17.2183 4.50389 17.6083 4.99219 17.8711L11.543 21.4492L17.4375 18.0391C17.9093 17.7708 18.3022 17.3829 18.5765 16.9146C18.8508 16.4462 18.9968 15.9139 19 15.3711V5.16797L11.1875 3.19141L3.375 5.16797Z" />
    </g>
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1?: string
  fill2?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red',
})
</script>
