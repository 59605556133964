<template>
  <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5 7H5.0175V3.5C5.0175 3.10218 5.17554 2.72064 5.45684 2.43934C5.73814 2.15804 6.11968 2 6.5175 2H8.6375C9.03532 2 9.41686 2.15804 9.69816 2.43934C9.97946 2.72064 10.1375 3.10218 10.1375 3.5C10.1375 3.76522 10.2429 4.01957 10.4304 4.20711C10.6179 4.39464 10.8723 4.5 11.1375 4.5C11.4027 4.5 11.6571 4.39464 11.8446 4.20711C12.0321 4.01957 12.1375 3.76522 12.1375 3.5C12.1375 2.57174 11.7688 1.6815 11.1124 1.02513C10.456 0.368749 9.56576 0 8.6375 0H6.5175C5.58924 0 4.699 0.368749 4.04263 1.02513C3.38625 1.6815 3.0175 2.57174 3.0175 3.5V7H2C1.73478 7 1.48043 7.10536 1.29289 7.29289C1.10536 7.48043 1 7.73478 1 8V15C1 15.2652 1.10536 15.5196 1.29289 15.7071C1.48043 15.8946 1.73478 16 2 16H13.5C13.7652 16 14.0196 15.8946 14.2071 15.7071C14.3946 15.5196 14.5 15.2652 14.5 15V8C14.5 7.73478 14.3946 7.48043 14.2071 7.29289C14.0196 7.10536 13.7652 7 13.5 7ZM12.5 14H3V9H12.5V14Z"
      :class="`fill-${fill1}`" />
    <path
      d="M5.01758 3.5V7H3.01758V3.5C3.01758 2.57174 3.38633 1.6815 4.0427 1.02513C4.69908 0.368749 5.58932 0 6.51758 0H8.63758C9.56584 0 10.4561 0.368749 11.1125 1.02513C11.7688 1.6815 12.1376 2.57174 12.1376 3.5C12.1376 3.76522 12.0322 4.01957 11.8447 4.20711C11.6571 4.39464 11.4028 4.5 11.1376 4.5C10.8724 4.5 10.618 4.39464 10.4305 4.20711C10.2429 4.01957 10.1376 3.76522 10.1376 3.5C10.1376 3.10218 9.97954 2.72064 9.69824 2.43934C9.41693 2.15804 9.0354 2 8.63758 2H6.51758C6.11975 2 5.73822 2.15804 5.45692 2.43934C5.17561 2.72064 5.01758 3.10218 5.01758 3.5Z"
      :class="`fill-${fill2}`" />
  </svg>
</template>

<script setup lang="ts">
type Props = {
  fill1: string
  fill2: string
}
withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red',
})
</script>

<style scoped></style>
