// This file contains the functions to get the maximum and minimum date for the date of birth field in the forms

// Function to get the maximum date for the date of birth field in the forms (16 years ago from the current date)
function getMaxDateForDobDate() {
  const maxDobDate = new Date()
  maxDobDate.setFullYear(maxDobDate.getFullYear() - 16)
  return maxDobDate
}

// Function returns it in the format of a string
const getMaxDateForDobString = () => {
  const maxDateForDobDate = getMaxDateForDobDate()
  return maxDateForDobDate.toISOString().split('T')[0]
}

// Function to get the minimum date for the date of birth field in the forms (94 years ago from the current date)
const getMinDateForDobDate = () => {
  const minDobDate = new Date()
  minDobDate.setFullYear(minDobDate.getFullYear() - 94)
  return minDobDate
}

// Function returns it in the format of a string
const getMinDateForDobString = () => {
  const minDateForDobDate = getMinDateForDobDate()
  return minDateForDobDate.toISOString().split('T')[0]
}

const dobIsInRange = (value: string) => {
  const newDate = new Date(value)
  const maxDate = getMaxDateForDobDate()
  const minDate = getMinDateForDobDate()
  return newDate <= maxDate && newDate >= minDate
}

export {
  dobIsInRange,
  getMaxDateForDobDate,
  getMaxDateForDobString,
  getMinDateForDobDate,
  getMinDateForDobString,
}
