<template>
  <div>
    <fitness-test-question-label
      :question-number="questionNumber"
      question="I have enough knowledge to make sound financial decisions for my future" />

    <number-rating :selected="enoughFinancialKnowledge" @click="setEnoughFinancialKnowledge" />
  </div>
</template>

<script>
import NumberRating from '../NumberRating/NumberRating.vue'

import { mapState } from 'vuex'
import FitnessTestQuestionLabel from '../QuestionsLabel/FitnessTestQuestionLabel.vue'

export default {
  name: 'EnoughFinancialKnowledge',
  components: { FitnessTestQuestionLabel, NumberRating },
  computed: {
    ...mapState({
      enoughFinancialKnowledge: (state) => state.fitnessTest.questions.enoughFinancialKnowledge,
    }),
  },

  props: {
    questionNumber: {
      type: String,
      default: '',
    },
  },

  methods: {
    setEnoughFinancialKnowledge(value) {
      this.$store.dispatch('setFitnessTestEnoughFinancialKnowledge', value)
    },
  },
}
</script>

<style scoped></style>
