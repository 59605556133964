<template>
  <path
    d="M32 50C28.4399 50 24.9598 48.9443 21.9997 46.9665C19.0397 44.9886 16.7326 42.1774 15.3702 38.8883C14.0078 35.5992 13.6513 31.98 14.3459 28.4884C15.0404 24.9967 16.7547 21.7894 19.2721 19.2721C21.7894 16.7547 24.9967 15.0404 28.4884 14.3459C31.98 13.6513 35.5992 14.0078 38.8883 15.3702C42.1774 16.7326 44.9886 19.0397 46.9665 21.9997C48.9443 24.9598 50 28.4399 50 32C50 36.7739 48.1036 41.3523 44.7279 44.7279C41.3523 48.1036 36.7739 50 32 50ZM32 22C30.0222 22 28.0888 22.5865 26.4443 23.6853C24.7998 24.7841 23.5181 26.3459 22.7612 28.1732C22.0043 30.0004 21.8063 32.0111 22.1922 33.9509C22.578 35.8907 23.5304 37.6726 24.9289 39.0711C26.3275 40.4696 28.1093 41.422 30.0491 41.8079C31.9889 42.1937 33.9996 41.9957 35.8268 41.2388C37.6541 40.4819 39.2159 39.2002 40.3147 37.5557C41.4135 35.9112 42 33.9778 42 32C42 29.3478 40.9464 26.8043 39.0711 24.9289C37.1957 23.0536 34.6522 22 32 22Z"
    :fill="fill" />
  <path
    d="M32 10C30.9391 10 29.9217 9.57857 29.1716 8.82843C28.4214 8.07828 28 7.06087 28 6V4C28 2.93913 28.4214 1.92172 29.1716 1.17157C29.9217 0.421428 30.9391 0 32 0C33.0609 0 34.0783 0.421428 34.8284 1.17157C35.5786 1.92172 36 2.93913 36 4V6C36 7.06087 35.5786 8.07828 34.8284 8.82843C34.0783 9.57857 33.0609 10 32 10Z"
    :fill="fill" />
  <path
    d="M32 64C30.9391 64 29.9217 63.5786 29.1716 62.8284C28.4214 62.0783 28 61.0609 28 60V58C28 56.9391 28.4214 55.9217 29.1716 55.1716C29.9217 54.4214 30.9391 54 32 54C33.0609 54 34.0783 54.4214 34.8284 55.1716C35.5786 55.9217 36 56.9391 36 58V60C36 61.0609 35.5786 62.0783 34.8284 62.8284C34.0783 63.5786 33.0609 64 32 64Z"
    :fill="fill" />
  <path
    d="M6 36H4C2.93913 36 1.92172 35.5786 1.17157 34.8284C0.421428 34.0783 0 33.0609 0 32C0 30.9391 0.421428 29.9217 1.17157 29.1716C1.92172 28.4214 2.93913 28 4 28H6C7.06087 28 8.07828 28.4214 8.82843 29.1716C9.57857 29.9217 10 30.9391 10 32C10 33.0609 9.57857 34.0783 8.82843 34.8284C8.07828 35.5786 7.06087 36 6 36Z"
    :fill="fill" />
  <path
    d="M60 36H58C56.9391 36 55.9217 35.5786 55.1716 34.8284C54.4214 34.0783 54 33.0609 54 32C54 30.9391 54.4214 29.9217 55.1716 29.1716C55.9217 28.4214 56.9391 28 58 28H60C61.0609 28 62.0783 28.4214 62.8284 29.1716C63.5786 29.9217 64 30.9391 64 32C64 33.0609 63.5786 34.0783 62.8284 34.8284C62.0783 35.5786 61.0609 36 60 36Z"
    :fill="fill" />
  <path
    d="M13.6202 17.62C13.094 17.6191 12.5732 17.5144 12.0876 17.3119C11.602 17.1094 11.1611 16.8131 10.7902 16.44L9.37018 15C8.7394 14.2325 8.4168 13.2577 8.4652 12.2654C8.5136 11.2731 8.92954 10.3343 9.63203 9.63184C10.3345 8.92935 11.2733 8.51342 12.2656 8.46502C13.2579 8.41662 14.2327 8.73921 15.0002 9.37L16.4102 10.79C16.9696 11.3488 17.3509 12.0609 17.5058 12.8363C17.6607 13.6117 17.5824 14.4157 17.2806 15.1465C16.9788 15.8774 16.4672 16.5025 15.8103 16.9427C15.1535 17.383 14.3809 17.6186 13.5902 17.62H13.6202Z"
    :fill="fill" />
  <path
    d="M51.8001 55.8C50.7493 55.7929 49.7435 55.3726 49.0001 54.63L47.5901 53.21C47.1885 52.8461 46.8651 52.4045 46.6394 51.9119C46.4137 51.4193 46.2904 50.8859 46.2771 50.3442C46.2638 49.8025 46.3606 49.2638 46.5618 48.7607C46.763 48.2575 47.0643 47.8005 47.4475 47.4174C47.8306 47.0342 48.2876 46.7329 48.7907 46.5317C49.2939 46.3305 49.8326 46.2337 50.3743 46.247C50.916 46.2603 51.4494 46.3836 51.942 46.6093C52.4346 46.835 52.8762 47.1584 53.2401 47.56L54.6301 49C55.1799 49.5609 55.5525 50.2713 55.7012 51.0426C55.85 51.8138 55.7683 52.6118 55.4665 53.337C55.1647 54.0622 54.6561 54.6824 54.0041 55.1204C53.3521 55.5584 52.5855 55.7948 51.8001 55.8Z"
    :fill="fill" />
  <path
    d="M50.3799 17.62C49.5891 17.6186 48.8166 17.383 48.1597 16.9427C47.5029 16.5025 46.9912 15.8774 46.6895 15.1465C46.3877 14.4157 46.3093 13.6117 46.4642 12.8363C46.6192 12.0609 47.0004 11.3488 47.5599 10.79L48.9999 9.37C49.7674 8.73921 50.7422 8.41662 51.7345 8.46502C52.7268 8.51342 53.6655 8.92935 54.368 9.63184C55.0705 10.3343 55.4865 11.2731 55.5349 12.2654C55.5833 13.2577 55.2607 14.2325 54.6299 15L53.2099 16.41C52.8414 16.7886 52.4016 17.0904 51.9159 17.2981C51.4302 17.5058 50.9081 17.6152 50.3799 17.62Z"
    :fill="fill" />
  <path
    d="M12.2001 55.8C11.4146 55.7948 10.6481 55.5585 9.99606 55.1205C9.34405 54.6825 8.83542 54.0622 8.53361 53.337C8.23181 52.6118 8.1502 51.8139 8.29895 51.0426C8.4477 50.2713 8.82023 49.5609 9.37008 49L10.7901 47.59C11.1539 47.1885 11.5955 46.865 12.0882 46.6393C12.5808 46.4136 13.1141 46.2904 13.6558 46.277C14.1975 46.2637 14.7363 46.3606 15.2394 46.5617C15.7425 46.7629 16.1995 47.0642 16.5827 47.4474C16.9659 47.8305 17.2672 48.2876 17.4684 48.7907C17.6695 49.2938 17.7664 49.8326 17.7531 50.3743C17.7397 50.916 17.6165 51.4493 17.3908 51.9419C17.1651 52.4346 16.8416 52.8762 16.4401 53.24L15.0001 54.63C14.2567 55.3727 13.2509 55.7929 12.2001 55.8Z"
    :fill="fill" />
</template>

<script>
export default {
  name: 'RetirementTile',
  props: {
    fill: { type: String, default: '#D4E7FE' },
    fill1: { type: String, default: '#D4E7FE' },
    fill2: { type: String, default: '#D4E7FE' }
  }
}
</script>

<style scoped></style>
