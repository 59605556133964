import getApiInstance from '@/services/Api'
import { getMinSuperContributionRate } from '@/lib/RetirementPlanHelper'

const api = getApiInstance()

export default {
  getProfile() {
    return api.Get('/profile')
  },

  putProfile(profile) {
    return api.Put('/profile', profile)
  },

  getStates() {
    return api.Get('/state')
  },

  getMonthlySalaryFromGrossAnnualSalary({ grossAnnualSalary, superRate, additionalIncome }) {
    if (!superRate) superRate = getMinSuperContributionRate()
    return api.Post('/monthly_income', {
      gas: grossAnnualSalary,
      sa_rate: superRate,
      additional_income: additionalIncome
    })
  },

  putPartnerInfo(payload) {
    return api.Put('/partner', payload)
  },

  removePartnerData(payload) {
    return api.Post('/partner/remove', payload)
  },

  getCashFlow() {
    return api.Get('/cashflow')
  },

  getFinancialSummary() {
    return api.Get('/modularised_advice/financial-summary')
  },

  updateFinancialSummary(
    type: 'salaryAndSuper' | 'otherIncome' | 'assets' | 'debt' | 'expenses',
    payload: Record<string, unknown>
  ) {
    return api.Put('/modularised_advice/financial-summary', { type, payload })
  },

  putDependents(dependents) {
    return api.Put('/dependents', { data: { dependents: dependents } })
  },

  removeDependant(id) {
    return api.Delete(`/dependents/${id}`)
  },

  updateProfileIncomeAndExpenses(payload) {
    return api.Post('/profile/income_and_expense', payload)
  },

  saveOwnAndOwe(payload) {
    return api.Put('/saveOwnAndOwe', payload)
  },

  getFitnessScoreAndNetWealth() {
    return api.Get('/user/fitnessScoreAndNetWealth')
  },

  async setAccountProfilePostcodeId(postcodeId) {
    const resp = await api.Put('/profile/postcode', postcodeId)
    return resp
  },

  getDependents() {
    return api.Get('/dependents')
  },

  addDependent(payload) {
    return api.Put('/save-dependents', payload)
  },

  removeDependent(id: string | number) {
    return api.Delete(`/dependents/${id}`)
  }
}
