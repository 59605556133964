<template>
  <div id="naming-otivo">
    <div class="md:text-center max-w-900px m-auto">
      <h6 class="text-white text-2xl font-bold pb-6">
        We often get asked where the name ‘Otivo’ came from
      </h6>
      <p class="paragraph-1 text-white">
        We fell in love with it while throwing around ideas for a new name. Inspired by Latin and
        our own imagination, we wanted a name that symbolised us optimising peoples' lives. So
        'optimised lives' simply became 'Otivo'. Plus, it’s fun to say too. Give it a go — we dare
        you not to smile while you say it.
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup></script>
