<template>
  <div class="flex flex-col items-center justify-center md:max-w-[375px] mx-auto px-6">
    <h5 class="bold">Your account has been deactivated.</h5>
    <p class="mt-6 text-blue-1">
      Thank you for your feedback and for choosing us to help you be better off.
      <br />
      <br />
      As a heads up, we’re legally required to keep your data securely stored for seven years.
      Please rest assured, your information will remain confidential and protected.
      <br />
      <br />
      If you ever want to come back to Otivo, you’re always welcome!
    </p>
    <OtivoButton class="mt-[40px] w-full" @click="close">Close</OtivoButton>
  </div>
</template>

<script setup lang="ts">
import OtivoButton from '@/components/OtivoButton.vue'
import { useCheckUser } from '@/composables/users/checkUser.ts'
import { useCallCentrePortalStore } from '@/store/pinia/adminPortal/CallCentrePortalStore.ts'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/store/pinia/UserStore.ts'

const { isAdmin } = useCheckUser()
const router = useRouter()
const user = useUserStore().getUser

const close = async () => {
  if (isAdmin()) {
    await useCallCentrePortalStore().getManagedUserData(user.auth0id)
  } else {
    await useUserStore().fetchUser()
  }
  await router.push({ name: 'clientDetails' })
}
</script>

<style scoped></style>
