<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 16C0 16 0 14.56 0 13.9475C0 12.79 1.3175 12.2625 4.8575 12.0025C4.9887 11.9926 5.12056 12.0087 5.24552 12.0499C5.37048 12.0911 5.48609 12.1565 5.58572 12.2424C5.68535 12.3283 5.76705 12.4331 5.82613 12.5507C5.88521 12.6682 5.9205 12.7963 5.93 12.9275C5.9625 13.3625 10.04 13.3625 10.07 12.9275C10.0792 12.7961 10.1143 12.6677 10.1732 12.5499C10.2321 12.432 10.3138 12.327 10.4134 12.2408C10.5131 12.1546 10.6288 12.089 10.7539 12.0476C10.8791 12.0063 11.0111 11.9901 11.1425 12C14.6825 12.2625 16 12.79 16 13.9475C16 14.56 16 16 8 16Z"
      :class="`fill-${fill2}`" />
    <path
      d="M13.9375 5.9575H13.5V5C13.5 4.73478 13.3946 4.48043 13.2071 4.29289C13.0196 4.10536 12.7652 4 12.5 4H3.5C3.23478 4 2.98043 4.10536 2.79289 4.29289C2.60536 4.48043 2.5 4.73478 2.5 5V9.815C2.5 9.86 2.5625 14.25 8.0325 14.25C9.7975 14.25 11.1875 13.75 12.1625 12.75C12.7491 12.144 13.1611 11.3908 13.355 10.57H13.9375C14.4467 10.5687 14.9347 10.3656 15.2946 10.0053C15.6544 9.64498 15.8568 9.15674 15.8575 8.6475V7.88C15.8568 7.37076 15.6544 6.88252 15.2946 6.5222C14.9347 6.16187 14.4467 5.95882 13.9375 5.9575ZM11.5 9.5225C11.5 9.9775 11.3275 12.25 8.0325 12.25C4.7375 12.25 4.5 10.2125 4.5 9.815V6H11.5V9.5225ZM13.8575 8.575H13.5V7.9575H13.8575V8.575Z"
      :class="`fill-${fill1}`" />
    <path
      d="M4.9325 3.5C4.66728 3.5 4.41292 3.39464 4.22539 3.20711C4.03785 3.01957 3.9325 2.76522 3.9325 2.5V1C3.9325 0.734784 4.03785 0.48043 4.22539 0.292893C4.41292 0.105357 4.66728 0 4.9325 0C5.19771 0 5.45207 0.105357 5.6396 0.292893C5.82714 0.48043 5.9325 0.734784 5.9325 1V2.5C5.9325 2.76522 5.82714 3.01957 5.6396 3.20711C5.45207 3.39464 5.19771 3.5 4.9325 3.5Z"
      :class="`fill-${fill2}`" />
    <path
      d="M7.905 3.5C7.63978 3.5 7.38543 3.39464 7.19789 3.20711C7.01036 3.01957 6.905 2.76522 6.905 2.5V1C6.905 0.734784 7.01036 0.48043 7.19789 0.292893C7.38543 0.105357 7.63978 0 7.905 0C8.17022 0 8.42457 0.105357 8.61211 0.292893C8.79964 0.48043 8.905 0.734784 8.905 1V2.5C8.905 2.76522 8.79964 3.01957 8.61211 3.20711C8.42457 3.39464 8.17022 3.5 7.905 3.5Z"
      :class="`fill-${fill2}`" />
    <path
      d="M11.0675 3.5C10.8023 3.5 10.5479 3.39464 10.3604 3.20711C10.1729 3.01957 10.0675 2.76522 10.0675 2.5V1C10.0675 0.734784 10.1729 0.48043 10.3604 0.292893C10.5479 0.105357 10.8023 0 11.0675 0C11.3327 0 11.5871 0.105357 11.7746 0.292893C11.9621 0.48043 12.0675 0.734784 12.0675 1V2.5C12.0675 2.63132 12.0416 2.76136 11.9914 2.88268C11.9411 3.00401 11.8675 3.11425 11.7746 3.20711C11.6818 3.29997 11.5715 3.37362 11.4502 3.42388C11.3289 3.47413 11.1988 3.5 11.0675 3.5Z"
      :class="`fill-${fill2}`" />
  </svg>
</template>

<script lang="ts" setup>
type Props = {
  fill1?: string
  fill2?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red',
})
</script>

<style scoped></style>
