<template>
  <div
    id="logo"
    class="flex flex-row h-full items-center md:gap-10"
    :class="[{ 'cursor-pointer': !guidancePortal }]"
    @click.stop="pushToHome">
    <div class="flex items-center h-full object-contain">
      <img
        src="@/assets/img/otivo-logo-white-text.png"
        class="max-w-[120px] h-auto"
        alt="otivo-logo" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { userRole } from '@/lib/AuthenticatorPlugin.ts'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import { useRouter } from 'vue-router'

const router = useRouter()
const sessionStore = useSessionStore()
const whitelabel = computed(() => sessionStore.getWhitelabelData)
const guidancePortal = computed(() => whitelabel.value.callcentre_portal)

const isCallCentre = computed(() => whitelabel.value.callcentre_portal)

const pushToHome = () => {
  if (router.currentRoute.value.path !== '/' && router.currentRoute.value.path !== '/dashboard')
    router.push('/')
}
</script>

<style scoped></style>
