<template>
  <base-view>
    <div class="flex fix-width-m-auto h-65vh">
      <div class="self-center text-center m-auto">
        <h1 class="py-5">Uh-Oh!</h1>
        <h3>
          It looks you're experiencing some network issues, please click the back button and try
          again.
        </h3>
      </div>
    </div>
  </base-view>
</template>

<script>
import BaseView from '@/views/BaseView.vue'
export default {
  name: 'NetworkIssue',
  components: { BaseView }
}
</script>

<style scoped></style>
