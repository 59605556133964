import { GenericObject } from '@/types/GlobalTypes.ts'

/**
 * Reorder Array indexes
 * @param array
 * @param from
 * @param to
 */
export function reorderArray<T>(array: Array<T>, from: number, to: number): Array<T> {
  const newArr = [...array]
  const item = newArr[from]
  newArr.splice(from, 1)
  newArr.splice(to, 0, item)
  return newArr
}

/**
 * Clone deep copy of object
 * @param object
 */
export function cloneObject(object: object) {
  return JSON.parse(JSON.stringify(object))
}

/**
 * hash string
 * @param length
 */
export function strNg(length: number) {
  let text = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}

export function isUserOnMobile(): boolean {
  const navigator = window.navigator
  if ('maxTouchPoints' in navigator) {
    return navigator.maxTouchPoints > 0
  } else if ('msMaxTouchPoints' in navigator) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return navigator.msMaxTouchPoints > 0
  } else {
    const mQ = matchMedia?.('(pointer:coarse)')
    if (mQ?.media === '(pointer:coarse)') {
      return !!mQ.matches
    } else if ('orientation' in window) {
      return true // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const UA = navigator.userAgent
      return (
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
      )
    }
  }
}

export function mod10Checksum(numberString: string): boolean {
  const numberArray = numberString.split('').map(Number)
  // deliberately take off the last digit
  const checkDigit = numberArray.pop()
  let sum = 0
  let doubleNextValue = true

  for (let i = numberArray.length - 1; i >= 0; i--) {
    const weight = doubleNextValue ? 2 : 1
    doubleNextValue = !doubleNextValue
    numberArray[i] *= weight
    if (numberArray[i] > 9) numberArray[i] -= 9
    sum += numberArray[i]
  }

  const next10Interval = Math.ceil(sum / 10) * 10
  const checkSum = next10Interval - sum
  return checkSum === checkDigit
}

export function convertToSnakeCase(str: string): string {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}
export function convertToKebabCase(str: string): string {
  return str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)
}

export function convertPayloadToSnakeCase(payload: GenericObject): GenericObject {
  const returnPayload = {}
  Object.keys(payload).forEach((key) => {
    returnPayload[convertToSnakeCase(key)] = payload[key]
  })
  return returnPayload
}

export function convertArrayPayloadToSnakeCase(
  payloadArray: Array<GenericObject>,
): Array<GenericObject> {
  return payloadArray.map((allocation) => {
    return convertPayloadToSnakeCase(allocation)
  })
}

export function toKebabCase(str: string) {
  if (str == null) {
    return ''
  }
  return str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join('-')
}
