<template>
  <div class="space-y-5"
       :class="profile.classes">
    <div class="rounded-md">
      <div class="w-full h-full aspect-square relative inset-0 rounded">
        <!--  profile image -->
        <div class="w-full h-full object-contain inset-0 rounded">
          <img class="w-full h-full" :src="profile.image" alt="Illustrated profile of {{profile.name}}">
        </div>
        <!--  profile blurb  -->
        <div
          class="opacity-0 hover:opacity-100 absolute inset-0 rounded-md overflow-hidden grid items-center px-5 py-2 transition duration-500 ease-in-out border-1 rounded"
          :class="hoverClasses ?? 'border-otivo-blue bg-blue-1 text-white'">
          <p class="paragraph-2">{{ profile.blurb }}</p>
        </div>
      </div>
    </div>
    <div class="text-center space-y-1">
      <p class="button-2">{{ profile.name }}</p>
      <p class="paragraph-2">{{ profile.position }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">

defineProps<{
  profile: {
    image: string
    name: string
    position: string
    blurb: string
    classes?: string
  },
  hoverClasses?: string
}>()
</script>

<style scoped>

</style>
