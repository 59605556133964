<template>
  <EmptyView>
    <div class="flex bg-white rounded p-6 min-h-80vh">
      <UnsubscribeFitnessTestEmail v-if="fitnessTest" class="p-4 m-auto" />
      <UnsubscribeAccountEmail v-else class="p-4" />
    </div>
  </EmptyView>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import UnsubscribeAccountEmail from '@/components/Profile/EmailSubscription/UnsubscribeAccountEmail.vue'
import EmptyView from '@/views/EmptyView.vue'
import UnsubscribeFitnessTestEmail from '@/components/Profile/EmailSubscription/UnsubscribeFitnessTestEmail.vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const fitnessTest = computed(() => route.params.subscriptionId === 'fitnessTest')
</script>

<style scoped></style>
