<template>
  <div id="contact-address">
    <div class="max-w-[490px] m-auto">
      <div class="grid grid-cols-3 items-center">
        <p class="set-w-48 paragraph-2 bold text-left">Socials</p>
        <div class="col-span-2 flex gap-8">
          <a :key="index" :href="social.url" target="_blank" v-for="(social, index) in socialIcons">
            <component
              :is="social.icon.svg"
              :fill1="social.icon.fill1"
              class="w-8 h-8 cursor-pointer" />
          </a>
        </div>
      </div>

      <!--      <div class="grid grid-cols-3 items-center pt-10 text-left">-->
      <!--        <p class="set-w-48 paragraph-2">-->
      <!--          Email-->
      <!--        </p>-->
      <!--        <div class="col-span-2">-->
      <!--          <CustomButton-->
      <!--            colour="white"-->
      <!--            class="px-14 py-3"-->
      <!--          >-->
      <!--            Email us-->
      <!--          </CustomButton>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="grid grid-cols-3 items-center pt-10 text-left">
        <p class="set-w-48 paragraph-2 bold">Head office</p>
        <div class="col-span-2">
          <p>Suite 2.01, 50 York St, Sydney, NSW 2000</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import FBIcon from '@/components/icons/Socials/FBIcon.vue'
import LinkedInIcon from '@/components/icons/Socials/LinkedInIcon.vue'
import TwitterIcon from '@/components/icons/Socials/TwitterIcon.vue'
import { Icon } from '@/types/globalComponents/Icon'

type SocialIcon = {
  icon: Icon
  url: string
}

interface Props {
  socialIcons?: Array<SocialIcon>
}

withDefaults(defineProps<Props>(), {
  socialIcons: () => [
    {
      icon: {
        svg: TwitterIcon,
        fill1: 'otivo-blue'
      },
      url: 'https://twitter.com/OtivoFinancial'
    },
    {
      icon: {
        svg: FBIcon,
        fill1: 'otivo-blue'
      },
      url: 'https://www.facebook.com/OtivoFinancial'
    },
    {
      icon: {
        svg: LinkedInIcon,
        fill1: 'otivo-blue'
      },
      url: 'https://www.linkedin.com/company/otivo/'
    }
  ]
})
</script>
