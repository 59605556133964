<template>
  <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      :class="`fill-${fill}`"
      d="M12.2247 4.69805L8.27972 0.702044C7.88924 0.302444 7.25845 0.302444 6.86796 0.702044C6.47747 1.09165 6.46746 1.72103 6.86796 2.11064L9.1408 4.40834H1.50125C0.950563 4.40834 0.5 4.85789 0.5 5.40734C0.5 5.95679 0.950563 6.40634 1.50125 6.40634H9.12078L6.84794 8.70404C6.45745 9.09365 6.46746 9.73301 6.84794 10.1126C7.03817 10.3024 7.2985 10.4023 7.54881 10.4023C7.79912 10.4023 8.06946 10.3024 8.2597 10.1026L12.2146 6.10664C12.5951 5.71703 12.5951 5.08766 12.2146 4.70804L12.2247 4.69805Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  name: 'ArrowSide',
  props: {
    fill: {
      type: String,
      default: '#888888',
    },
  },
}
</script>

<style scoped></style>
