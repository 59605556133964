<template>
  <button
    :class="[`${colour}`, `${size}`, { 'disabled ': disabled }]"
    :disabled="disabled || loading"
    :style="customStyleOverrides"
    class="flex flex-row border justify-center rounded-[60px] relative ot-button"
    tabindex="0"
    @click="$emit('click')">
    <transition name="fade">
      <PulseLoader
        v-if="loading"
        :color="colour === 'white' ? `var(--otivo-blue)` : '#fff'"
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        size="5px" />
    </transition>
    <div :class="[{ 'h-0 invisible': loading }, typography]">
      <slot :class="typography"> Default button text </slot>
    </div>
  </button>
</template>

<script lang="ts" setup>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

interface Props {
  disabled?: boolean
  loading?: boolean
  customStyleOverrides?: string
  size?: 'large' | 'medium' | 'small' | 'mobile'
  colour?: 'blue' | 'red' | 'white' | 'sky' | 'dark-red' | 'black' | 'transparent'
  typography?: 'button-1' | 'button-2' | 'button-3'
}

withDefaults(defineProps<Props>(), {
  disabled: false,
  loading: false,
  customStyleOverrides: '',
  size: 'medium',
  colour: 'blue',
  typography: 'button-1',
})

defineEmits<{
  (e: 'click'): void
}>()
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.ot-button:focus-visible {
  outline: var(--blue-1);
  outline-style: solid;
  outline-offset: 0px;
}

// SIZE
.large {
  min-height: 50px;
  padding: 16px 50px;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
}

.medium {
  min-height: 40px;
  padding: 13px 40px;
  font-weight: 700;
  font-size: 12px;
  line-height: 120%;
}

.small {
  min-height: 34px;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
}

.mobile {
  min-height: 60px;
  padding: 20px 20px;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
}

// TYPE
.blue {
  background-color: var(--otivo-blue);
  border-color: var(--otivo-blue);
  color: white;

  &:hover {
    background-color: var(--blue-1);
    border-color: var(--blue-1);
  }
}

.red {
  background-color: var(--otivo-red);
  border-color: var(--otivo-red);
  color: white;

  &:hover {
    background-color: var(--red-1);
    border-color: var(--red-1);
  }
}

.dark-red {
  background-color: var(--red-1);
  border-color: var(--red-1);
  color: white;
  &:hover {
    background-color: var(--red-light);
    border-color: var(--red-light);
    color: var(--red-1);
  }
}

.white {
  background-color: white;
  border-color: var(--grey-1);
  color: var(--otivo-blue);

  &:hover {
    background-color: var(--grey-4);
  }
}

.sky {
  background-color: white;
  border-color: var(--blue-3);
  color: var(--blue-3);

  &:hover {
    background-color: var(--grey-4);
  }
}

.black {
  background-color: black;
  border-color: black;
  color: white;

  &:hover {
    background-color: var(--grey-2);
    border-color: var(--grey-2);
  }
}

.transparent {
  background-color: transparent;
  border-color: white;
  color: white;
  border-width: 1px;
  box-shadow: 0 0 1px 1px white inset;
}

.disabled {
  background-color: var(--grey-4);
  border: 1px solid var(--grey-3);
  color: var(--grey-3);
  pointer-events: none;
  cursor: not-allowed;
}

button:disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
</style>
