import { useSessionStore } from '@/store/pinia/SessionStore.ts'

export const ExclusionRules = {
  ALL_IN_CASH_OR_TERM_DEPOSITS: 'all-in-cash-or-term-deposits',
  SOME_IN_CASH_OR_TERM_DEPOSITS: 'some-in-cash-or-term-deposits',
  INVESTED_IN_LIFE_STAGE_OPTION: 'invested-in-lifestage-option',
  MINIMUM_SUPER_BALANCE: 'minimum-super-balance',
} as const
export type ExclusionRule = (typeof ExclusionRules)[keyof typeof ExclusionRules]

export const useWhitelabelExclusionRules = () => {
  const hasExclusionRule = (rule: ExclusionRule) => {
    const exclusionRules =
      useSessionStore().getWhitelabelData.superfund_config?.exclusion_rules ?? []
    return exclusionRules.find((r) => r.slug === rule) !== undefined
  }
  return {
    hasExclusionRule,
  }
}
