import { computed, ref } from 'vue'
import CallcentrePortalService from '@/services/CallcentrePortalService.ts'
import { defineStore } from 'pinia'
import { useToast } from '@/composables/useToast.ts'

export const useTemporaryPortalStore = defineStore('TemporaryPortalStore', () => {
  /**
   * TODO: refactor
   * MOVE THESE TO PORTAL STORE WHEN HYDRA IS MERGED
   */
  const { errorToast } = useToast()
  const portalStaff = ref([])
  const portalSortObject = ref({
    value: true,
    type: '',
  })
  const getAllPortalStaff = async () => {
    try {
      const res = await CallcentrePortalService.getAllPortalStaff(portalSortObject.value)
      portalStaff.value = res.data.data
    } catch (err) {
      console.warn(err)
    }
  }

  const createStaffMember = async (payload) => {
    try {
      await CallcentrePortalService.createPortalStaffMember(payload)
      await getAllPortalStaff()
    } catch (err) {
      errorToast('Error creating staff member')
      console.warn(err)
    }
  }

  const editStaffMember = async (payload) => {
    try {
      await CallcentrePortalService.updatePortalStaffMember(payload)
      await getAllPortalStaff()
      return true
    } catch (err) {
      console.warn(err)
      return false
    }
  }

  const deleteStaffMember = async (id: string) => {
    try {
      await CallcentrePortalService.deleteStaffMember(id)
      await getAllPortalStaff()
    } catch (err) {
      console.warn(err)
    }
  }

  const restoreStaffMember = async (id: string) => {
    try {
      await CallcentrePortalService.restoreStaffMember(id)
      await getAllPortalStaff()
    } catch (err) {
      console.warn(err)
    }
  }

  const setPortalSortObject = (obj: { value: boolean; type: string }) => {
    portalSortObject.value = obj
  }

  /**
   * END
   */
  return {
    setPortalSortObject,
    portalStaff: computed(() => portalStaff.value),
    portalSortObject: computed(() => portalSortObject.value),
    getAllPortalStaff,
    createStaffMember,
    editStaffMember,
    deleteStaffMember,
    restoreStaffMember,
  }
})
