import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { SuperFundAllocationNameType } from '@/types/CallcentrePortalTypes.ts'
import SuperService from '@/services/SuperService.ts'
import getChantwestProductFromAccountNumber from '@/composables/superHelpers/getChantwestProductFromAccountNumber.ts'
import SuperObject, { SuperPayload } from '@/types/api/super/SuperObject.ts'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import getSuperFundInvestmentOptions from '@/composables/superHelpers/getSuperFundInvestmentOptions.ts'

export interface ISuperStore {
  supersArray: Array<SuperObject>
  activeSuperObject: SuperObject
  superProductInvestmentOptions: Array<SuperFundAllocationNameType>
  getSupersArray: Array<SuperObject>
  getActiveSuperObject: SuperObject
  getSuperProductInvestmentOptions: Array<SuperFundAllocationNameType>
  fetchAllSupers: () => void
  addNewSuperObject: (object: SuperObject) => void
  setupSuper: (accountNumber: string, superId: string) => void
  setActiveSuperObject: (newSuperObject: SuperObject) => void
  updateSuperObject: (id: string, superPayload: SuperPayload) => void
  removeSuperObject: (id: string) => void
  reset: () => void
}

export const useSuperStore = defineStore('SuperStore', () => {
  const supersArray = ref<Array<SuperObject>>([] as Array<SuperObject>)
  const activeSuperObject = ref<SuperObject>({} as SuperObject)
  // InvestmentOptions
  const superProductInvestmentOptions = ref<SuperFundAllocationNameType[]>()

  const getSupersArray = computed(() => supersArray.value)
  const getActiveSuperObject = computed(() => activeSuperObject.value)
  const getSuperProductInvestmentOptions = computed(() => superProductInvestmentOptions.value)

  const fetchAllSupers = async () => {
    try {
      const response = await SuperService.getAllSupers()
      supersArray.value = response.data.data
      /**
       * if we already have an active super object, set it to the first object in the array.
       */
      if (activeSuperObject.value.id && supersArray.value.length) {
        const activeSuper = supersArray.value.find(
          (superObject) => superObject.id === activeSuperObject.value.id,
        )
        activeSuperObject.value = activeSuper ? activeSuper : supersArray.value[0]
        return
      }

      // Set active super object to the first object in the array (fresh)
      activeSuperObject.value =
        supersArray.value.length > 0 ? supersArray.value[0] : ({} as SuperObject)

      // Get investment options
      if (activeSuperObject.value.chant_west_product_id)
        getInvestmentOptionsForActiveSuper().catch((e) => {
          throw new Error(e)
        })
    } catch (e) {
      console.error(e)
    }
  }

  const getInvestmentOptionsForActiveSuper = async () => {
    superProductInvestmentOptions.value = await getSuperFundInvestmentOptions(
      activeSuperObject.value.chant_west_product_id,
    )
  }

  const setInvestmentOptionsForSuper = async (chantWestProductId: number) => {
    superProductInvestmentOptions.value = await getSuperFundInvestmentOptions(chantWestProductId)
  }

  const addNewSuperObject = (object: SuperObject) => {
    supersArray.value.push(object)
    setActiveSuperObject(supersArray.value[supersArray.value.length - 1])
  }

  const setupSuper = async (accountNumber: string, superId: string) => {
    try {
      const chantwestProduct = getChantwestProductFromAccountNumber(
        accountNumber,
        useSessionStore().getWhitelabelData.superfund_config.super_products,
      )
      // creates/updates super record in the DB
      await SuperService.updateSuper(superId, chantwestProduct)
      // update the active super object and supers array
      await fetchAllSupers()
      await getInvestmentOptionsForActiveSuper()
      // replace object in array
      supersArray.value.map((superObject) =>
        supersArray.value.find((obj) => obj.id === superObject.id || obj),
      )
      return
    } catch (e) {
      console.error('Error setting up super', e)
    }
  }

  const setActiveSuperObject = (newSuperObject: SuperObject) => {
    activeSuperObject.value = newSuperObject
  }

  const updateSuperObject = async (id = activeSuperObject.value.id, superPayload: SuperPayload) => {
    try {
      const updatedSuper = await SuperService.updateSuper(id, superPayload)
      activeSuperObject.value = updatedSuper.data.data
      const indexToUpdate = supersArray.value.findIndex((superObject) => superObject.id === id)
      supersArray.value[indexToUpdate] = updatedSuper.data.data
      // save the updated super object in the array
      getInvestmentOptionsForActiveSuper().catch((e) => {
        throw new Error(e)
      })
    } catch (e) {
      throw new Error('Error updating super')
    }
  }

  const removeSuperObject = (id: string) => {
    try {
      supersArray.value = supersArray.value.filter((superObject) => superObject.id !== id)
      if (activeSuperObject.value.id === id) {
        activeSuperObject.value = supersArray.value[0]
      }
    } catch (e) {
      throw new Error('Error removing super')
    }
  }

  const reset = (): void => {
    supersArray.value = []
    activeSuperObject.value = {} as SuperObject
    superProductInvestmentOptions.value = []
  }

  return {
    fetchAllSupers,
    addNewSuperObject,
    setupSuper,
    setActiveSuperObject,
    setInvestmentOptionsForSuper,
    updateSuperObject,
    removeSuperObject,
    getSupersArray,
    getActiveSuperObject,
    getSuperProductInvestmentOptions,
    reset,
  }
})
