<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1642_15988)">
      <path
        d="M3.285 0H2.3075V3.9125C2.32019 4.16353 2.42884 4.40008 2.611 4.57326C2.79316 4.74645 3.0349 4.84302 3.28625 4.84302C3.53759 4.84302 3.77933 4.74645 3.96149 4.57326C4.14365 4.40008 4.2523 4.16353 4.265 3.9125V2C5.46809 2.10972 6.64389 2.42247 7.7425 2.925L6.7725 4.6025C6.64282 4.82734 6.60763 5.09443 6.67466 5.34518C6.74168 5.59593 6.90544 5.80985 7.13 5.94C7.2824 6.02638 7.45482 6.07121 7.63 6.07C7.80285 6.06904 7.97237 6.02225 8.12125 5.93442C8.27013 5.84658 8.39306 5.72084 8.4775 5.57L9.45 3.885C10.5992 4.68846 11.5815 5.7073 12.3425 6.885L10.6225 7.885C10.5064 7.94602 10.4037 8.0298 10.3207 8.13133C10.2376 8.23286 10.1758 8.35007 10.139 8.47597C10.1022 8.60188 10.0911 8.73391 10.1064 8.86419C10.1217 8.99448 10.163 9.12035 10.228 9.23432C10.2929 9.34829 10.3802 9.44802 10.4845 9.52756C10.5888 9.60711 10.708 9.66485 10.8351 9.69733C10.9622 9.72982 11.0946 9.7364 11.2242 9.71667C11.3539 9.69694 11.4783 9.65131 11.59 9.5825L13.2375 8.6325C13.6422 9.6239 13.8951 10.6707 13.9875 11.7375H11.9875C11.7365 11.7502 11.4999 11.8588 11.3267 12.041C11.1536 12.2232 11.057 12.4649 11.057 12.7163C11.057 12.9676 11.1536 13.2093 11.3267 13.3915C11.4999 13.5737 11.7365 13.6823 11.9875 13.695H15.9875V12.7075C15.9816 9.33995 14.6415 6.11196 12.2608 3.73027C9.88 1.34858 6.65254 0.00726951 3.285 0Z"
        fill="#0064FF" />
      <path
        d="M6.415 8.45751L4.0675 10.8075C3.48301 10.4897 2.80381 10.3925 2.15364 10.5336C1.50348 10.6746 0.925626 11.0446 0.525375 11.576C0.125123 12.1074 -0.0708863 12.7649 -0.0269409 13.4288C0.0170044 14.0926 0.29798 14.7186 0.764791 15.1926C1.2316 15.6666 1.85318 15.9572 2.51626 16.0113C3.17935 16.0654 3.83981 15.8795 4.37731 15.4874C4.9148 15.0954 5.29356 14.5233 5.44457 13.8753C5.59558 13.2274 5.50878 12.5468 5.2 11.9575L7.56 9.59751C7.63485 9.52232 7.69417 9.43314 7.73455 9.33503C7.77494 9.23693 7.7956 9.13183 7.79537 9.02574C7.79514 8.91965 7.77401 8.81464 7.7332 8.71671C7.69238 8.61879 7.63268 8.52986 7.5575 8.45501C7.48232 8.38015 7.39313 8.32084 7.29503 8.28046C7.19692 8.24007 7.09182 8.2194 6.98573 8.21964C6.87964 8.21987 6.77464 8.241 6.67671 8.28181C6.57878 8.32262 6.48985 8.38232 6.415 8.45751ZM2.76 14.39C2.53206 14.3895 2.30938 14.3214 2.12013 14.1944C1.93088 14.0673 1.78355 13.887 1.69678 13.6762C1.61 13.4655 1.58768 13.2337 1.63264 13.0102C1.67759 12.7867 1.7878 12.5816 1.94933 12.4208C2.11086 12.26 2.31645 12.1507 2.54011 12.1067C2.76377 12.0627 2.99545 12.086 3.20585 12.1737C3.41625 12.2614 3.59593 12.4095 3.72216 12.5993C3.84838 12.7891 3.91549 13.0121 3.915 13.24C3.915 13.3914 3.88509 13.5414 3.82699 13.6812C3.76888 13.8211 3.68372 13.9481 3.57641 14.055C3.46909 14.1618 3.34172 14.2464 3.20162 14.3039C3.06152 14.3614 2.91144 14.3907 2.76 14.39Z"
        fill="#FF0000" />
    </g>
    <defs>
      <clipPath id="clip0_1642_15988">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'MonthlyCashFlowIcon'
}
</script>

<style scoped></style>
