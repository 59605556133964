<template>
  <BaseView>
    <div class="bg-blue-5 pb-[50px]">
      <Breadcrumbs />
      <h1 class="text-center pt-[70px]">Frequently asked questions</h1>
      <div class="intro-1 text-grey-2 m-auto text-center mt-[20px] mb-[30px] px-[20px] md:px-0">
        Here are some of the things we’re often asked.
      </div>
      <div class="m-auto md:w-[800px] bg-white rounded mt-[30px] shadow w-100% justify-center">
        <div v-for="(faqContent, index) in store.faqs" :key="faqContent.id">
          <accordion
            :id="faqContent.title + '_accordion'"
            :class="{ 'border-b-2px border-blue-5': index !== store.faqs.length - 1 }"
            :title="faqContent.heading"
            :content="faqContent.body"
            :styles="{
              title: 'text-[18px] font-bold',
              content: 'px-[20px] md:px-[40px] pt-[10px] pb-[20px] max-w-screen list-disc',
              base: 'px-[20px] md:px-[40px] py-[20px] border-none max-w-screen',
            }" />
        </div>
      </div>
    </div>
  </BaseView>
</template>

<script lang="ts" setup>
import Accordion from '@/components/Accordion.vue'
import BaseView from '@/views/BaseView.vue'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import { onBeforeMount } from 'vue'
import { useFAQStore } from '@/store/pinia/FAQs'
import { useHead } from '@unhead/vue'

const store = useFAQStore()

onBeforeMount(() => {
  store.fetchFAQs('faq')
})
useHead({
  title: 'Otivo. Be better off.',
})
</script>

<style scoped>
:deep(ul) {
  list-style: disc;
  margin-left: 20px;
}
</style>
