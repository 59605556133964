'use strict'
import { DashboardInterface } from '@/types/DashboardInterface'
import getApiInstance from '@/services/Api'
import type { AxiosResponse } from 'axios'

const api = getApiInstance()

const requests = {
  get: async (): Promise<DashboardInterface> => {
    const r: AxiosResponse<DashboardInterface> = await api.Get('/dashboard', {
      transformResponse: [
        (r) => {
          let resp
          try {
            resp = JSON.parse(r)
          } catch (error) {
            throw Error(
              `[requestClient] Error parsing response JSON data - ${JSON.stringify(error)}`
            )
          }
          return resp
        }
      ]
    })
    const { data } = r
    return data
  }
}

export const DashboardService = {
  getDashboard: (): Promise<DashboardInterface> => requests.get()
}
