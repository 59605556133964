<template>
  <form autocomplete="off" @submit.prevent="submitContactUsForm()" id="contact-us-form">
    <div class="text-14px py-2">
      Get in touch with a Suncorp Staff Financial Services Specialist
    </div>

    <div class="md:flex py-2 gap-4">
      <user-first-name class="flex-1 mb-4 md:mb-0" :error="errors.firstName" />
      <user-last-name class="flex-1" :error="errors.lastName" />
    </div>

    <user-email class="py-2" :disabled="authenticated" :error="errors.email" />

    <div class="md:flex gap-3 py-2">
      <input-label
        for="suncorp-discussion"
        label="What would you like to discuss?"
        class="set-w-48 paragraph-2" />
      <div class="grid grid-cols-3 gap-3">
        <input-check-box
          v-for="(question, index) in discussions"
          :name="`suncorp-${index}`"
          :label="question.name"
          :key="index"
          :checked="question.value"
          @click="
            (value) => {
              changeDiscussionValue(index, value)
            }
          " />
      </div>
    </div>

    <div class="md:flex pt-4">
      <label :for="name" class="set-w-48 pt-4 paragraph-2"> Message </label>
      <input-text-area
        class="py-4 w-full"
        rows="8"
        place-holder="Message"
        name="contact-us-message"
        v-model="message"
        :error="errors.message" />
    </div>

    <div class="flex justify-center py-2">
      <OtivoButton :loading="loading" @click="submitContactUsForm()"> Send </OtivoButton>
    </div>
  </form>
</template>

<script>
import InputCheckBox from '@/components/BasicInputs/InputCheckBox.vue'
import InputLabel from '@/components/BasicInputs/InputLabel.vue'
import InputTextArea from '@/components/BasicInputs/InputTextArea.vue'
import MiscellaneousService from '@/services/MiscellaneousService'
import UserEmail from '@/components/Profile/BasicInfo/Email/UserEmail.vue'
import UserFirstName from '@/components/Profile/BasicInfo/UserBasicInfo/UserFirstName.vue'
import UserLastName from '@/components/Profile/BasicInfo/UserBasicInfo/UserLastName.vue'
import { mapState, mapGetters } from 'vuex'
import { validateEmailAddress } from '@/lib/ValidationHelper'
import OtivoButton from '@/components/OtivoButton.vue'
import { useToast } from '@/composables/useToast.ts'

const { successToast, errorToast } = useToast()

export default {
  name: 'SunCorpContactUsForm',

  components: {
    OtivoButton,
    InputLabel,
    InputCheckBox,
    UserEmail,
    UserLastName,
    UserFirstName,
    InputTextArea,
  },
  emits: ['close'],

  data() {
    return {
      message: '',

      discussions: [
        { name: 'Home lending', value: false },
        { name: 'Credit cards', value: false },
        { name: 'Superannuation', value: false },
        { name: 'Personal loans', value: false },
        { name: 'Transaction accounts', value: false },
        { name: 'Term Deposits', value: false },
        { name: 'Personal Insurance', value: false },
        { name: 'Life Insurance', value: false },
        { name: 'Business Insurance', value: false },
      ],

      loading: false,

      errors: {
        firstName: '',
        email: '',
        message: '',
      },
    }
  },

  computed: {
    ...mapState({
      firstName: (state) => state.profile.firstName,
      lastName: (state) => state.profile.lastName,
      email: (state) => state.profile.email,
    }),
    ...mapGetters(['authenticated']),
  },

  methods: {
    changeDiscussionValue(index, value) {
      this.discussions = this.discussions.map((el, i) => {
        if (i === index) el.value = value
        return el
      })
    },

    submitContactUsForm() {
      // validation
      // except discussions
      let validated = true

      if (this.firstName.length === 0) {
        validated = false
        this.errors.firstName = 'First name is required'
      }

      if (this.lastName.length === 0) {
        validated = false
        this.errors.lastName = 'Last name is required'
      }

      if (!validateEmailAddress(this.email)) {
        validated = false
        this.errors.email = 'A valid email is required'
      }

      if (this.message.length === 0) {
        validated = false
        this.errors.message = 'Please provide a message'
      }

      if (validated) {
        this.loading = true
        MiscellaneousService.submitSunCorpContactUsForm({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          discussions: this.discussions
            .filter((el) => el.value)
            .map((el) => el.name.toLowerCase().replace(' ', '_'))
            .join(','),
          message: this.message,
        })
          .then(() => {
            this.loading = false
            successToast('We got your message, we will be in touch.')
            this.message = ''
            this.$store.dispatch('renderContactUsModal', false)
            this.$emit('close')
          })
          .catch((err) => {
            this.loading = false
            errorToast('Error occurred, please try again.')
            this.$store.dispatch('renderContactUsModal', false)
            throw err
          })
      }
    },
  },
}
</script>

<style scoped></style>
