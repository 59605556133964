<template>
  <svg width="42" height="43" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_11281_178926)">
      <path
        d="M23.625 6.83365V3.20459C23.625 2.5084 23.3484 1.84072 22.8562 1.34844C22.3639 0.856152 21.6962 0.57959 21 0.57959C20.3038 0.57959 19.6361 0.856152 19.1438 1.34844C18.6516 1.84072 18.375 2.5084 18.375 3.20459V6.83365C16.1235 7.41499 14.1287 8.72726 12.7034 10.5646C11.2782 12.402 10.5032 14.6605 10.5 16.9858C10.5 17.682 10.7766 18.3497 11.2688 18.842C11.7611 19.3343 12.4288 19.6108 13.125 19.6108C13.8212 19.6108 14.4889 19.3343 14.9812 18.842C15.4734 18.3497 15.75 17.682 15.75 16.9858C15.75 15.5935 16.3031 14.2581 17.2877 13.2735C18.2723 12.289 19.6076 11.7358 21 11.7358C22.3924 11.7358 23.7277 12.289 24.7123 13.2735C25.6969 14.2581 26.25 15.5935 26.25 16.9858C26.25 17.682 26.5266 18.3497 27.0188 18.842C27.5111 19.3343 28.1788 19.6108 28.875 19.6108C29.5712 19.6108 30.2389 19.3343 30.7312 18.842C31.2234 18.3497 31.5 17.682 31.5 16.9858C31.4968 14.6605 30.7218 12.402 29.2966 10.5646C27.8713 8.72726 25.8765 7.41499 23.625 6.83365Z"
        :class="`fill-${fill2}`" />
      <path
        d="M39.375 37.3624V22.8921C40.0712 22.8921 40.7389 22.6155 41.2312 22.1232C41.7234 21.631 42 20.9633 42 20.2671C42 19.5709 41.7234 18.9032 41.2312 18.4109C40.7389 17.9187 40.0712 17.6421 39.375 17.6421H2.625C1.92881 17.6421 1.26113 17.9187 0.768845 18.4109C0.276562 18.9032 0 19.5709 0 20.2671C0 20.9633 0.276562 21.631 0.768845 22.1232C1.26113 22.6155 1.92881 22.8921 2.625 22.8921V37.3296C1.92881 37.3722 1.27807 37.6897 0.815937 38.2121C0.353806 38.7346 0.11814 39.4192 0.160782 40.1154C0.203424 40.8116 0.52088 41.4623 1.04331 41.9244C1.56575 42.3866 2.25037 42.6222 2.94656 42.5796H39.0403C39.7365 42.6222 40.4211 42.3866 40.9436 41.9244C41.466 41.4623 41.7835 40.8116 41.8261 40.1154C41.8687 39.4192 41.6331 38.7346 41.1709 38.2121C40.7088 37.6897 40.0581 37.3722 39.3619 37.3296L39.375 37.3624ZM34.125 37.3624H28.875V22.8921H34.125V37.3624ZM23.625 37.3296H18.375V22.8921H23.625V37.3296ZM13.125 22.8921V37.3296H7.875V22.8921H13.125Z"
        :class="`fill-${fill1}`" />
    </g>
    <defs>
      <clipPath id="clip0_11281_178926">
        <rect width="42" height="42" fill="white" transform="translate(0 0.57959)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1?: string
  fill2?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red'
})
</script>

<style scoped></style>
