<template>
  <div class="flex w-100% justify-center">
    <dl
      class="rounded-xl w-[160px] bg-none border-[1px] h-[12px] box-border border-blue-1 self-center">
      <dd
        class="bg-blue-1 rounded-xl h-100% transition-all duration-500"
        :style="{ width: completed + 'px' }"></dd>
    </dl>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

type Props = {
  completePercentage: number
}
const props = withDefaults(defineProps<Props>(), {
  completePercentage: 0,
})

const barWidth = 160
const completed = computed(() => {
  return (props.completePercentage / 100) * barWidth
})
</script>

<style scoped></style>
