<template>
  <div>
    <BeatLoader class="h-full w-full" v-if="loading" />
    <div v-else class="paragraph-1">You are unsubscribed.</div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import api from '@/services/Api.ts'
import { useToast } from '@/composables/useToast.ts'

const loading = ref(false)

const route = useRoute()

const userId = computed(() => route.params.userId)

const { successToast, errorToast } = useToast()

onMounted(() => {
  loading.value = true
  api()
    .Post('/email/fitnessTest/unsubscribe', {
      userId: userId.value,
    })
    .then(() => {
      successToast('You are unsubscribed')
      loading.value = false
    })
    .catch((err) => {
      loading.value = false
      errorToast('Something went wrong')
      throw err
    })
})
</script>

<style scoped></style>
