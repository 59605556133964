<template>
  <g>
    <path
      d="M15.0915 7.24178C14.5756 7.4759 14.0215 7.61469 13.4561 7.65145C12.1878 7.73856 10.9369 7.31831 9.97842 6.48314C9.01996 5.64798 8.4325 4.46629 8.34524 3.19801H7.05017C6.73429 3.20521 6.41948 3.23725 6.10862 3.29382C5.89324 2.88174 5.56972 2.53609 5.17277 2.29396C4.77582 2.05183 4.32043 1.92237 3.85547 1.91946V4.15609C2.83052 4.85326 2.05774 5.8624 1.65187 7.03365H0.330374C0.242753 7.03365 0.158726 7.06845 0.096769 7.13041C0.0348119 7.19237 0 7.27641 0 7.36403V10.5587C0 10.6464 0.0348119 10.7304 0.096769 10.7923C0.158726 10.8543 0.242753 10.8891 0.330374 10.8891H1.65187C2.05774 12.0604 2.83052 13.0695 3.85547 13.7667V16.0033H6.41256V15.0419C6.41256 14.9543 6.44737 14.8703 6.50933 14.8083C6.57128 14.7463 6.65531 14.7115 6.74293 14.7115H9.91452C10.0021 14.7115 10.0862 14.7463 10.1481 14.8083C10.2101 14.8703 10.2449 14.9543 10.2449 15.0419V16.0033H12.802V13.7667C13.655 13.1968 14.3402 12.4093 14.7869 11.4858C15.2335 10.5623 15.4254 9.53623 15.3426 8.51372C15.3108 8.08112 15.2265 7.65399 15.0915 7.24178Z"
      fill="#0064FF" />
    <circle cx="13" cy="3" r="3" :fill="active ? '#FF0000' : '#0064FF'" />
  </g>
</template>

<script>
export default {
  name: 'IconSaving',
  props: {
    active: { type: Boolean, default: true }
  }
}
</script>

<style scoped></style>
