<template>
  <div class="pt-20 pb-20 m-auto w-90% md:max-w-[860px]">
    <h5 class="bold text-otivo-blue text-center">Otivo</h5>
    <div class="headline-1 fix-width-m-auto text-center">Our story</div>
    <p class="intro-1 text-2xl mt-10 md:text-center">
      Our story began back in 2016 when <strong>Paul Feeney</strong> — Otivo’s founder and CEO — had
      a dream of<br />
      giving <span class="svg-underline-wide">every Australian</span> access to quality financial
      advice.
    </p>
    <p class="paragraph-1 text-xl pt-12 md:text-center">
      To make this dream a reality, Paul recognised a new approach was needed. One that fit within
      the regulatory framework yet was still affordable for the majority of Australians. And most
      importantly, one that puts people first. So just like Spotify and Netflix revolutionised how
      we consume entertainment, Otivo began its journey to reimagine how Aussies receive financial
      advice.
    </p>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
