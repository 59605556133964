<template>
  <div>
    <fitness-test-question-label :question-number="questionNumber" question="Your age group" />

    <input-box-buttons
      @click="setUserAgeGroup"
      :buttons="[
        { value: '24', name: '16 - 24' },
        { value: '34', name: '25 - 34' },
        { value: '44', name: '35 - 44' },
        { value: '54', name: '45 - 54' },
        { value: '64', name: '55 - 64' },
        { value: '74', name: '65 - 74' },
        { value: '75', name: '75 above' },
      ]"
      :selected="ageGroup"
      name="living-situation"
      label=""
      :multi-line="true" />
  </div>
</template>

<script>
import AgeAnswers from './AgeAnswers'
import { mapState } from 'vuex'
import FitnessTestQuestionLabel from '../QuestionsLabel/FitnessTestQuestionLabel.vue'

import InputBoxButtons from '@/components/BasicInputs/InputBoxButtons.vue'
export default {
  name: 'AgeGroup',
  components: { InputBoxButtons, FitnessTestQuestionLabel, AgeAnswers },
  computed: {
    ...mapState({
      ageGroup: (state) => state.fitnessTest.questions.ageGroup,
    }),
  },

  props: {
    questionNumber: {
      type: String,
      default: '',
    },
  },

  methods: {
    setUserAgeGroup(value) {
      this.$store.dispatch('setFitnessTestAgeGroup', value)
    },
  },
}
</script>

<style scoped></style>
