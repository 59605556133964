<template>
  <BaseView>
    <div class="flex flex-col bg-blue-6 h-full w-full items-center relative py-[60px]">
      <h3 class="lg:h1 text-center w-full max-w-5xl px-6" v-html="content.heading" />
      <div class="intro-1 mt-[20px] px-6">{{ content.subHeading }}</div>
      <section class="flex flex-col w-full px-6 lg:w-100">
        <div class="flex flex-col justify-center">
          <Dropdown
            :items="selectMenuDataDefault"
            :existing-item="null"
            placeholder="16-24"
            @selected="handleRangeSelected"
            class="mt-[20px]"
            type="text" />
          <div class="flex flex-col mt-[20px]">
            <div class="flex justify-between">
              <p class="menu-1 text-blue-1">Contribution</p>
              <p class="menu-1 text-blue-1">{{ formatAsCurrency(contributionAmount) }}</p>
            </div>
            <BaseRangeInput
              v-model:value="contributionAmount"
              :show-tooltip="false"
              :max="maxContribution"
              :min="minContribution"
              :step="step"
              ball-background-colour="blue-6">
              <template #minLabel>
                <p class="button-1 text-blue-1">{{ formatAsCurrency(minContribution) }}</p>
              </template>
              <template #maxLabel>
                <div class="text-right">
                  <p class="button-1 text-blue-1">{{ formatAsCurrency(maxContribution) }}</p>
                  <p class="menu-2 text-blue-1">max</p>
                </div>
              </template>
            </BaseRangeInput>
          </div>
        </div>
      </section>
      <div class="flex max-w-[90rem] w-full items-center justify-center relative h-full">
        <div
          class="flex w-full justify-between lg:items-center lg:max-w-3xl mt-[20px] lg:min-h-[500px]">
          <!-- Blue circle -->
          <div class="flex flex-col lg:justify-center ml-6 lg:ml-10">
            <div
              class="bg-blue-1 rounded-[999px] grow-shrink-circle flex items-center justify-center z-10 lg:mt-[80px]"
              :style="{
                width: '160px',
                height: '160px',
                marginRight: -overlapDistance + 'px',
              }">
              <div class="text-white text-center w-[140px]">
                <span class="menu-1">WITHOUT ADVICE<br /></span>
                <span class="button-3 lg:button-2">your super might stay at<br /></span>
                <span class="button-2 lg:headline-6">{{ formatAsCurrency(regularValue) }}</span>
              </div>
            </div>
            <div
              class="grow-shrink-circle"
              :style="{
                marginRight: -overlapDistance + 'px',
              }">
              <img
                class="grow-shrink-circle hidden md:block"
                :src="CircleBase"
                alt="Circle Base"
                :style="{
                  marginTop: '60px',
                  width: '160px',
                }" />
            </div>
          </div>
          <!-- Red circle with text -->
          <div class="flex flex-col justify-center gap-20 mt-24 lg:mt-0">
            <div
              :style="{ width: redCircleSize + 'px', height: redCircleSize + 'px' }"
              class="bg-otivo-red rounded-[999px] grow-shrink-circle flex flex-col items-center justify-center relative z-50 mr-6 lg:mr-0">
              <OtivoIcon fill-1="white" fill-2="white" class="w-[32px] h-[32px]" />
              <div class="text-white text-center button-2 w-[180px] mt-2">
                <span class="menu-1">WITH ADVICE<br /></span>
                <span class="button-1 lg:button-2">your super can grow to<br /></span>
                <span class="headline-3 lg:headline-6">{{ formatAsCurrency(otivoValue) }}</span>
              </div>
            </div>
            <img :src="CircleBase" alt="Circle Base" class="grow-shrink-circle hidden md:block" />
          </div>
        </div>
        <img
          :src="SuperBoostHunter"
          alt="Super Boost Hunter"
          class="bottom-24 right-0 w-[175px] hidden lg:absolute" />
      </div>
      <OtivoButton
        class="mt-[20px] md:mt-0"
        size="large"
        @click="createAccountWithModuleStream('salarySacrificeContributions')">
        Help super-size my super
      </OtivoButton>
      <div class="mt-10 fix-width-m-auto p-2 text-xs leading-4">
        Assumptions: The projections are in today’s dollars. We assume the contribution is made from
        a lump sum with no claimed tax deduction. We also assume super grows at 4% per year, after
        inflation, tax, and fees. Insurance premiums, if applicable, are excluded. This calculator
        doesn’t factor in current super balances, retirement income, contribution eligibility, or
        insurance within super. We’ve used average super balances by age as a starting point, while
        the projections are based on today’s super and tax laws, which may change. The results
        presented are not guaranteed and may differ from those of a defined benefit fund. The
        information provided is general in nature and does not take into account your specific
        financial situation or needs.
      </div>
    </div>
  </BaseView>
</template>

<script setup lang="ts">
import Dropdown from '@/components/BasicInputs/Dropdown.vue'
import BaseRangeInput from '@/components/Inputs/BaseRangeInput.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import SuperBoostHunter from '@/assets/img/super-boost-hunter.png'
import CircleBase from '@/assets/img/circle-base.png'
import BaseView from '@/views/BaseView.vue'
import { computed, ref } from 'vue'
import createAccountWithModuleStream from '@/composables/createAccountWithModuleStream.ts'
import OtivoIcon from '@/components/icons/OtivoIcon.vue'
import { isUserOnMobile } from '@/utilities.ts'
import formatAsCurrency from '@/composables/formatAsCurrency.ts'

const blueMinSize = isUserOnMobile() ? 160 : 160
const blueMaxSize = isUserOnMobile() ? 160 : 300
const redMinSize = isUserOnMobile() ? 260 : 300
const redMaxSize = isUserOnMobile() ? 260 : 380

const blueCircleSize = computed(() =>
  mapContributionToSize(contributionAmount.value, blueMinSize, blueMaxSize),
)
const redCircleSize = computed(() =>
  mapContributionToSize(contributionAmount.value, redMinSize, redMaxSize),
)

const maxContribution = ref(360000)
const minContribution = ref(10000)
const contributionAmount = ref(10000)
const step = ref(10000)

const returnRate = ref(0.04)
const salary = 90000
const employerContribution = 0.115

const content = {
  heading: `How can I <span class='text-otivo-red'>add to super</span> from my savings?`,
  subHeading:
    'Tell us your age and how much you want to contribute, and we’ll show you how your super could grow!',
}

const selectMenuDataDefault = ref([
  { label: '16-24', value: 0 },
  { label: '25-34', value: 1 },
  { label: '35-44', value: 2 },
  { label: '45-54', value: 3 },
  { label: '55-64', value: 4 },
  { label: '65-74', value: 5 },
])

const valuesBasedOnAge = ref([
  { startingSuperBalance: 8000, yearsTillRetirement: 45 },
  { startingSuperBalance: 45000, yearsTillRetirement: 35.5 },
  { startingSuperBalance: 90000, yearsTillRetirement: 25.5 },
  { startingSuperBalance: 170000, yearsTillRetirement: 15.5 },
  { startingSuperBalance: 290000, yearsTillRetirement: 5.5 },
  { startingSuperBalance: 220000, yearsTillRetirement: 1 },
])

const selectedIndex = ref(0)
const selectedPeriod = computed(() => valuesBasedOnAge.value[selectedIndex.value])

// Function to map contribution to circle size
const mapContributionToSize = (contribution: number, minSize: number, maxSize: number) => {
  const range = maxContribution.value - minContribution.value
  const scale = (maxSize - minSize) / range
  return minSize + scale * (contribution - minContribution.value)
}

const overlapDistance = computed(() => {
  return redCircleSize.value - blueCircleSize.value
})

const handleRangeSelected = (selected: { label: string; value: number }) => {
  selectedIndex.value = selected?.value
}

const calculateEndBalance = (principal: number, rate: number, period: number): number => {
  return principal * Math.pow(1 + rate, period)
}

const calculateCompulsoryContributions = (rate: number, periods: number): number => {
  const salaryContribution = salary - salary / (1 + employerContribution)
  return (salaryContribution * (Math.pow(1 + rate, periods) - 1)) / rate
}

const calculateBalanceWithExtraContributions = (
  principal: number,
  rate: number,
  periods: number,
): number => {
  const endBalance = calculateEndBalance(principal, rate, periods)
  const compulsoryContributions = calculateCompulsoryContributions(rate, periods)
  return endBalance + compulsoryContributions
}

const calculateBalanceWithoutExtraContributions = (
  principal: number,
  rate: number,
  periods: number,
): number => {
  const endBalance = calculateEndBalance(principal, rate, periods)
  const compulsoryContributions = calculateCompulsoryContributions(rate, periods)
  return endBalance + compulsoryContributions
}

const otivoValue = computed(() =>
  calculateBalanceWithExtraContributions(
    selectedPeriod.value.startingSuperBalance + contributionAmount.value,
    returnRate.value,
    selectedPeriod.value.yearsTillRetirement,
  ),
)
const regularValue = computed(() =>
  calculateBalanceWithoutExtraContributions(
    selectedPeriod.value.startingSuperBalance,
    returnRate.value,
    selectedPeriod.value.yearsTillRetirement,
  ),
)
</script>

<style>
.grow-shrink-circle {
  transition:
    width 0.5s ease,
    height 0.5s ease; /* Add transition for smooth growth */
}
</style>
