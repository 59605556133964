<template>
  <div class="w-full md:hidden bg-white h-24 flex items-center p-4 pl-22px">
    <img
      v-if="isItWellbeinggateway"
      class="w-74"
      :src="`${this.s3Bucket}/images/wellbeinggateway_colour.png`" />
    <img
      v-else-if="isItSurmount"
      class="w-48"
      :src="`${this.s3Bucket}/Logo/grey/surmount_grey.png`" />
  </div>
</template>

<script>
import { getAssetsS3BucketLink } from '@/lib/WhiteLabelHelpers'
import { mapGetters } from 'vuex'

export default {
  name: 'WhitelabelBanner',
  computed: {
    ...mapGetters(['isItWellbeinggateway', 'isItSurmount'])
  },
  data() {
    return {
      s3Bucket: getAssetsS3BucketLink()
    }
  }
}
</script>

<style scoped></style>
