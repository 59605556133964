import { reactive } from 'vue'
import { ObjectEntries, ObjectSchema, safeParse } from 'valibot'

type ErrorType<T> = { [key in keyof T]: string }

export const useValibotForm = <T extends object>(
  state: T,
  objectSchema: ObjectSchema<ObjectEntries>,
) => {
  const form = reactive<T>({ ...state })
  const errorState = Object.keys(state).reduce(
    (o, key) => ({ ...o, [key]: '' }),
    {},
  ) as ErrorType<T>
  const errors = reactive<{ [key in keyof T]: string }>({ ...errorState })

  const validate = () => {
    resetErrors()
    const result = safeParse(objectSchema, form)
    if (result.success) {
      return result.output as T
    }

    const errorMap = new Map<[keyof T], string>()
    for (const issuesKey in result.issues) {
      const key = result.issues[issuesKey].path?.at(0)?.key as [keyof T]
      if (key) {
        errorMap.set(key, result.issues[issuesKey].message)
      }
    }

    Object.assign(errors, Object.fromEntries(errorMap.entries()))
    return null
  }

  const resetForm = () => {
    Object.assign(form, state)
  }

  const resetErrors = () => {
    Object.assign(errors, errorState)
  }

  return {
    form,
    errors,
    validate,
    resetForm,
    resetErrors,
  }
}
