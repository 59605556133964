<template>
  <custom-button
    id="remove-pension-button"
    @click="removePension"
    colour="cfs-button"
    class="border w-72 rounded-circle">
    Remove age pension
  </custom-button>
</template>

<script>
import CustomButton from '@/components/CustomButton/CustomButton.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'RetirementCalculatorRemovePension',
  components: { CustomButton },
  computed: {
    ...mapGetters('RetirementCalculator', ['noAgePension'])
  },
  methods: {
    ...mapActions('RetirementCalculator', ['setNoAgePension']),
    removePension() {
      this.setNoAgePension()
    }
  }
}
</script>

<style scoped></style>
