import getApiInstance from '@/services/Api'

const api = getApiInstance()

export default {
  postUserSignUpData(data) {
    return api.Post('/onboard', data)
  },

  updateAuthIdForUser(id: number) {
    return api.Put(`/account/${id}`)
  },

  doesUserExists(email) {
    return api.Get(`/account_exists/${email}`)
  },

  patchUserMetaData({ payload }) {
    return api.Put('/user', payload)
  }
}
