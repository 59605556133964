import FinancialWellBeingCheckInService from '@/services/FinancialWellBeingCheckInService'
import { IFinancialWellBeingCheckIn } from '@/types/globalComponents/IFinancialWellBeingCheckIn'

const state = (): IFinancialWellBeingCheckIn => ({
  isLoading: true,
  questions: {
    onTrackFinancialGoal: 0,
    enoughMoneyForThings: 0,
    currentFinancialSituation: 0
  },
  created_at: '',
  message: ''
})

const getters = {
  created_at: (state): string => state.created_at,
  isLoading: (state): boolean => state.isLoading
}

const mutations = {
  SET_CREATED_AT(state, value: { created_at: string }) {
    state.created_at = value.created_at
  },
  SET_IS_LOADING(state, isLoading: boolean) {
    state.isLoading = isLoading
  },
  SET_FINANCIAL_WELL_BEING_CHECK_IN(state, value: IFinancialWellBeingCheckIn) {
    state.questions.onTrackFinancialGoal = value.questions.onTrackFinancialGoal
    state.questions.enoughMoneyForThings = value.questions.enoughMoneyForThings
    state.questions.currentFinancialSituation = value.questions.currentFinancialSituation
    state.created_at = value.created_at
    state.message = value.message
  }
}

const actions = {
  getLastCheckIn({ commit }) {
    FinancialWellBeingCheckInService.getLastCheckIn().then((res) => {
      if (res.data.data) {
        commit('SET_CREATED_AT', res.data.data)
      }
      commit('SET_IS_LOADING', false)
    })
  },
  submitCheckIn({ commit }, payload: IFinancialWellBeingCheckIn) {
    commit('SET_FINANCIAL_WELL_BEING_CHECK_IN', payload)
    FinancialWellBeingCheckInService.submitCheckIn(payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
