<template>
  <CFSView type="member" class="bg-blue-6">
    <div
      class="flex flex-col items-center justify-center min-h-100% drop-shadow-md w-full justify-self-center">
      <form
        @submit.prevent="OTPLogin()"
        class="max-w-[375px] w-100% text-left p-[40px] bg-white rounded-lg border-[2px] border-blue-5 otivo-drop-shadow">
        <h3 class="text-blue-1 text-22px">Please enter the SMS passcode</h3>
        <InputText
          label="SMS code"
          name="sms_code"
          :value="password"
          @input="(val) => (password = val)"
          place-holder="SMS code"
          data-test="smsCodeInput"
          :autofocus="true"
          class="w-100% m-auto mt-[26px] cfs-guidance-client-input"
          :allow-digits="true" />
        <div class="flex gap-1 mt-[10px]">
          <div>Didn't receive it?</div>
          <div tabindex="1" class="text-otivo-blue cursor-pointer" @click="resendSMS()">Resend</div>
        </div>
        <InputError class="pt-[10px] text-center" :error="smsError" v-if="smsError !== ''" />
        <OtivoButton
          type="submit"
          size="large"
          colour="blue"
          class="rounded-circle mt-[20px] w-100% md:w-fit"
          data-test="confirmBtn"
          :loading="loading"
          :disabled="!canSubmit">
          Confirm
        </OtivoButton>
      </form>
    </div>
  </CFSView>
</template>

<script lang="ts" setup>
import InputText from '@/components/BasicInputs/InputText.vue'
import { computed, onMounted, ref } from 'vue'
import { getSiteUri } from '@/lib/WhiteLabelHelpers'
import OtivoButton from '@/components/OtivoButton.vue'
import InputError from '@/components/BasicInputs/InputError.vue'
import { LoginObject } from '@/lib/Authenticator'
import { loginWithEmail } from '@/lib/AuthenticatorPlugin'
import { useDebounceFn } from '@vueuse/core'
import { useGuidanceClientStore } from '@/store/pinia/GuidanceClientStore'
import { useToast } from '@/composables/useToast.ts'
import { useRouter } from 'vue-router'
import CFSView from '@/views/Clients/CFS/CFSView.vue'
const router = useRouter()

const clientStore = useGuidanceClientStore()
const { successToast } = useToast()

const guidanceClientData = computed(() => clientStore.getGuidanceClientData)
const password = ref('')
const loading = ref(false)
const canSubmit = computed(() => password.value)
const resent = ref(false)
const smsError = ref('')

const OTPLogin = () => {
  loading.value = true
  const payload: LoginObject = {
    email: guidanceClientData.value.email,
    password: password.value.replace(/\s/g, ''),
    realm: 'cfs-users',
    redirectUri: getSiteUri() + 'dashboard',
  }

  loginWithEmail(payload).catch(() => {
    smsError.value = 'Incorrect SMS code.'
    loading.value = false
  })
}

const resendSMS = useDebounceFn(() => {
  const payload = {
    email: guidanceClientData.value.email,
    member_id: guidanceClientData.value.memberId,
  }

  clientStore
    .resendCFSClientSMS(payload)
    .then(() => {
      successToast('SMS Resent')
      resent.value = true
    })
    .catch((err) => {
      console.error('error resending SMS', err)
    })
}, 3500)

onMounted(() => {
  if (!guidanceClientData.value?.email && !guidanceClientData.value?.memberId) {
    router.replace({ name: 'clientSignIn' })
  }
})
</script>

<style scoped></style>
