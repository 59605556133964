import Pusher from 'pusher-js'
import { useIntrafundAdviceStore } from '@/store/pinia/IntrafundAdviceStore.ts'
import { useCallCentrePortalStore } from '@/store/pinia/adminPortal/CallCentrePortalStore.ts'
import User from '@/types/api/account/User.ts'
import { useModuleStatusesStore } from '@/store/pinia/ModuleStatusesStore.ts'
import { useSuperStore } from '@/store/pinia/SuperStore.ts'
import { superInvestmentRecommendationHandlers } from '@/components/CFS/IntrafundInvestment/Utils/superInvestmentRecommendationHandlers.ts'
import { useUserStore } from '@/store/pinia/UserStore.ts'
import { useCheckUser } from '@/composables/users/checkUser.ts'

const pusher = new Pusher(import.meta.env.VITE_PUSHER_CLIENT_KEY, {
  cluster: import.meta.env.VITE_PUSHER_CLUSTER,
})

export const webSocketHandler = () => {
  const intrafundAdviceStore = useIntrafundAdviceStore()

  const setupClientSuperWebsocket = (accountId: string) => {
    if (pusher.channel('update_super_' + accountId)) return // Stop if channel already created

    const { isUser } = useCheckUser()
    const { fetchSuperInvestmentAdvice } = superInvestmentRecommendationHandlers(
      useUserStore(),
      useSuperStore(),
      isUser,
    )
    const channel = pusher.subscribe('update_super_' + accountId)

    channel.bind('message', () => {
      useSuperStore()
        .fetchAllSupers()
        .then(() => {
          return fetchSuperInvestmentAdvice()
        })
    })
  }
  const setupTDConsentWebsocket = (superId: string) => {
    if (pusher.channel('td_consent_' + superId)) return // Stop if channel already created

    const channel = pusher.subscribe('td_consent_' + superId)

    channel.bind('message', () => {
      intrafundAdviceStore.fetchTermDepositConsent().catch((e) => console.error(e))
    })
  }
  // GT: If the user has consented for intrafund advice
  // Trigger the call to get advice
  const setupClientConsentWebsocket = (user: User) => {
    if (pusher.channel('flags_' + user.account_id)) return // Stop if channel already created

    const channel = pusher.subscribe('flags_' + user.account_id)

    channel.bind('message', async () => {
      const callCentrePortalStore = useCallCentrePortalStore()
      await callCentrePortalStore.getManagedUserData(user.auth0id)
    })
  }
  // GT: If the user has actioned the advice for intrafund advice
  // Update the supers
  const setupAdviceRequestWebsocket = (accountId: number) => {
    if (pusher.channel('change_request_' + accountId)) return // Stop if channel already created

    const channel = pusher.subscribe('change_request_' + accountId)

    channel.bind('message', () => {
      return useModuleStatusesStore().fetchModuleStatuses()
    })
  }

  return {
    setupClientSuperWebsocket,
    setupTDConsentWebsocket,
    setupClientConsentWebsocket,
    setupAdviceRequestWebsocket,
  }
}
