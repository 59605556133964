<template>
  <input-box-buttons
    name="suncorp-divisions"
    :buttons="[
      { name: '$77,000 or less', value: '77000_or_less' },
      { name: '$77,001 - $103,000', value: '770001_103000' },
      { name: '$103,001 - $155,000', value: '103001_155000' },
      { name: '$155,001 or more', value: '155001_or_more' },
      { name: 'Prefer not to disclose', value: 'prefer_not_to_disclose' }
    ]"
    @click="
      (value) => {
        $emit('click', value)
      }
    "
    :selected="selected"
    :multi-line="true" />
</template>

<script>
import InputBoxButtons from '../../BasicInputs/InputBoxButtons'
export default {
  name: 'IncomeBracketAnswer',
  components: { InputBoxButtons },
  props: {
    selected: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped></style>
