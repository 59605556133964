<template>
  <div>
    <input-toggle-button
      id="graph-input-toggle-button"
      name="assets-income-chart"
      class="border-grey-2"
      :buttons="[
        { name: 'Income', value: 'income' },
        { name: 'Assets', value: 'assets' }
      ]"
      :style="toggleButtonStyle"
      :selected="selectedSubPlan"
      @click="
        (value) => {
          selectedSubPlan = value
        }
      " />

    <transition enter-active-class="animated fadeIn fast" mode="out-in">
      <div :key="selectedSubPlan">
        <ret-calc-income-chart
          class="relative min-h-80"
          v-show="selectedSubPlan === 'income'"
          :user-data="userData"
          :partner-data="partnerData"
          :required-data="getIncomeChartRequiredData"
          :income-chart-data="graphData.income.years"
          :show-partner-data="enablePartnerForm"
          :loading="graphLoading" />
        <!-- ^ @has-pension="setHasPension" removed from above for CFS-->

        <ret-calc-assets-chart
          class="relative min-h-80"
          v-show="selectedSubPlan === 'assets'"
          :chart-data="getRetCalcAssetChartData"
          :user-data="userData"
          :partner-data="partnerData"
          :show-partner-data="enablePartnerForm"
          :loading="graphLoading" />

        <div class="flex items-center justify-between flex-col md:flex-row gap-4 md:gap-0">
          <retirement-calculator-change-assumptions class="self-center" />
          <retirement-calculator-remove-pension v-if="userHasPension" />
          <custom-button
            id="add-pension-button"
            colour="cfs-button"
            class="border w-72 rounded-circle"
            v-if="userHatesMoney"
            @click="userLikesMoney">
            Add age pension
          </custom-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import InputToggleButton from '@/components/BasicInputs/InputToggleButton.vue'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment/moment'
import RetCalcAssetsChart from '@/components/RetirementInvestment/Chart/AssetsChart/RetCalcAssetsChart.vue'
import RetCalcIncomeChart from '@/components/RetirementInvestment/Chart/IncomeChart/RetCalcIncomeChart.vue'
import RetirementCalculatorChangeAssumptions from '@/components/CFS/RetirementCalculatorComponents/RetirementCalculatorChangeAssumptions.vue'
import RetirementCalculatorRemovePension from '@/components/CFS/RetirementCalculatorComponents/RetirementCalculatorRemovePension.vue'
import CustomButton from '@/components/CustomButton/CustomButton.vue'

export default {
  name: 'RetirementCalculatorInvestmentCharts',
  components: {
    CustomButton,
    RetirementCalculatorRemovePension,
    RetirementCalculatorChangeAssumptions,
    RetCalcIncomeChart,
    RetCalcAssetsChart,
    InputToggleButton
  },
  computed: {
    ...mapGetters('RetirementCalculator', [
      'enablePartnerForm',
      'graphLoading',
      'getRetCalcAssetChartData',
      'userData',
      'partnerData',
      'graphData',
      'userHatesMoney'
    ]),
    userHasPension() {
      if (this.graphData) {
        return (
          this.graphData.income_stats.pension.user.startsFrom ||
          this.graphData.income_stats.pension.partner.startsFrom
        )
      }

      return false
    },
    getIncomeChartRequiredData() {
      const date = new Date()
      const userDob = moment(date.setFullYear(date.getFullYear() - this.userData.age)).format(
        'YYYY-MM-DD'
      )
      const partnerDob = this.userData.hasPartner
        ? moment(date.setFullYear(date.getFullYear() - this.partnerData.age)).format('YYYY-MM-DD')
        : false
      let totalSuperBalance = this.userData.current_super_balance
      if (this.enablePartnerForm) totalSuperBalance += this.partnerData.current_super_balance
      return {
        userRetirementAge: this.userData.retirement_age ?? 67,
        partnerRetirementAge: this.partnerData.retirement_age,
        userDob: userDob,
        partnerDob: partnerDob,
        firstName: 'You',
        partnerFirstName: "Partner's",
        showPartnerElement: this.enablePartnerForm,
        totalRetirementBalance: totalSuperBalance
      }
    }
  },
  methods: {
    ...mapActions('RetirementCalculator', ['userLikesMoney'])
  },
  data() {
    return {
      selectedSubPlan: 'income',
      toggleButtonStyle: {
        container: 'flex flex-row mb-12 justify-center md:justify-start gap-6',
        buttonWrapper: 'flex cursor-pointer',
        button: 'flex min-w-38 min-h-17 md:min-h-12 rounded-sm border',
        buttonText: 'flex font-sans text-center self-center mb3 text-[16px] font-bold mx-auto',
        active: 'bg-[#E5ECF9] text-[#164A9A] border-cfs-blue shadow-[0_0_0_2px_#E9E5FD]',
        inactive:
          'text-grey-1 border-grey-3 bg-white shadow-none hover:font-bold hover:shadow-[0_0_0_2px_#E9E5FD] hover:border-cfs-blue'
      }
    }
  }
}
</script>

<style scoped></style>
