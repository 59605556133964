<template>
  <div class="flex flex-col">
    <p class="paragraph-1" v-html="props.content.table_header" />
    <div
      class="grid grid-cols-1 otivo-shadow border-1px border-blue-4 divide-y divide-blue-5 rounded-lg bg-white mt-[40px]">
      <div class="grid grid-cols-12 px-[30px] md:px-[40px] gap-5 py-[20px] items-center">
        <p class="button-1 col-span-12 md:col-span-2 text-blue-1 font-bold">Step 1</p>
        <p class="paragraph-2 col-span-12 md:col-span-10">
          Save a copy of this SOA.
          <span
            :class="[downloadingSoa ? '' : 'underline cursor-pointer']"
            @click="triggerSOADownload">
            {{ downloadingSoa ? 'Generating...' : 'Click here to download' }}
          </span>
        </p>
      </div>
      <div v-for="(step, index) in remainingSteps" :key="index">
        <div class="grid grid-cols-12 px-[30px] md:px-[40px] gap-5 py-[20px] items-center">
          <p class="button-1 col-span-12 md:col-span-2 text-blue-1 font-bold">
            {{ `Step ${index + 2}` }}
          </p>
          <div class="col-span-12 md:col-span-10 space-y-5" v-html="step.description" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, onMounted, ref } from 'vue'
import CfsService from '@/services/CfsService.ts'
import { downloadSOA } from '@/lib/WhiteLabelHelpers'
import { useUserStore } from '@/store/pinia/UserStore.ts'
import { useToast } from '@/composables/useToast.ts'
import getPdsLink from '@/composables/superHelpers/getPdsLink.ts'
import getSuperFundContactDetails from '@/composables/superHelpers/getSuperFundContactDetails.ts'

const { successToast, errorToast } = useToast()

type Props = {
  content: {
    table_header: string
    table_step_2: string
    table_step_3: string
    table_step_4?: string
    table_step_5?: string
    table_step_6?: string
  }
}
const props = defineProps<Props>()

onMounted(async () => {
  const pdsLink = getPdsLink()
  const superfundContactDetails = await getSuperFundContactDetails()
  // <a/> tag required to be present in the content block with the correct ID in order for this to populate
  const pdsLinkTag = document.getElementById('product-pds-link')
  if (pdsLinkTag) pdsLinkTag?.setAttribute('href', pdsLink)

  const getInTouchBlock = document.getElementById('get-in-touch-block')
  if (getInTouchBlock) {
    getInTouchBlock.innerHTML = `
      <p class="paragraph-1">
        Website: <a target='_blank' href="https://${superfundContactDetails.data.web}">${superfundContactDetails.data.web}</a>
      </p>
      <p class="paragraph-1">
        Phone: <a href='tel:${superfundContactDetails.data.phone}'>${superfundContactDetails.data.phone}</a>
      </p>
    `
  }
})

const remainingSteps: ComputedRef<
  Array<{
    description: string | undefined
  }>
> = computed(() => {
  const arr = [
    {
      description: props.content.table_step_2,
    },
    {
      description: props.content.table_step_3,
    },
    {
      description: props.content.table_step_4,
    },
    {
      description: props.content.table_step_5 ?? '!RENDER', // currently dont exist on some content blocks
    },
    {
      description: props.content.table_step_6 ?? '!RENDER', // currently dont exist on some content blocks
    },
  ]
  return arr.filter((step) => step.description !== '!RENDER')
})

const userStore = useUserStore()
const downloadingSoa = ref(false)

const triggerSOADownload = async () => {
  const clientAccountId = userStore.getUser.account_id

  if (!clientAccountId) {
    console.error('Failed unable to download SOA') /**/
    return
  }

  try {
    downloadingSoa.value = true
    await CfsService.generateCfsSoa({ auth0id: userStore.getUser.auth0id })
    const res = await CfsService.downloadSoA({ clientAccountId: clientAccountId })
    if (res?.data) {
      downloadSOA(res.data?.pdf)
      successToast('Statement of Advice downloaded successfully')
    }
  } catch (err) {
    console.error('Error downloading SoA', err)
    errorToast('Failed to download SoA.')
  } finally {
    downloadingSoa.value = false
  }
}
</script>

<style scoped></style>
