import type { AxiosResponse } from 'axios'
import getApiInstance from '@/services/Api'
import type { ModuleStatusesType } from '@/store/pinia/ModuleStatusesStore.ts'
import type { CFSUserCreationObject } from '@/types/GlobalTypes.ts'
import type User from '@/types/api/account/User.ts'

const api = getApiInstance()
export default {
  async fetchUser(): Promise<AxiosResponse> {
    return api.Get('/v3/user').catch((error) => {
      console.warn('error getting user', error)
      return error
    })
  },

  async getUserByAuth0Id(auth0Id: string) {
    return api.Get<AxiosResponse<User>>(`/v3/user/${auth0Id}`)
  },

  async createManagedUser(userCreationObject: CFSUserCreationObject) {
    return api.Post<User>('/modularised_advice/users', userCreationObject)
  },

  async logUserAction(payload) {
    return api.Post('/user/log', payload)
  },
  async deactivateAccount() {
    return api.Post('/deactivate')
  },
  async surveyAnswers() {
    return api.Get('/deactivate/survey')
  },
  async cancellationSurvey(payload) {
    return api.Post('/deactivate/survey', payload)
  },
  async createGracePeriod() {
    return api.Post('/user/gracePeriod')
  },
  async changeEmail(payload) {
    return api.Put('/user/changeEmail', payload)
  },
  async updateUserEmail(payload) {
    return api.Put('/user/updateEmail', payload)
  },
  async getModuleStatuses(): Promise<AxiosResponse<ModuleStatusesType>> {
    return api.Get('/modularised_advice/user/get-module-statuses')
  },
  async UpdateDisclaimerFlags(payload) {
    return api.Put('/user/update-feature-flags', payload)
  },
  async getLoginCount() {
    return api.Get('/modularised_advice/logins-count')
  },
  isOnFreeTrial() {
    return api.Get('/user/free-trial')
  },
}
