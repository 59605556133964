<template>
  <div>
    <fitness-test-question-label
      :question-number="questionNumber"
      question="Do you have comprehensive car insurance?" />

    <input-box-buttons
      @click="setHaveCarInsurance"
      :buttons="[
        {
          name: 'Yes',
          value: 'yes',
        },
        {
          name: 'No',
          value: 'no',
        },
        {
          name: 'Self insured',
          value: 'self_insured',
        },
        {
          name: 'Don\'t have car',
          value: 'no_car',
        },
      ]"
      :selected="haveCarInsurance"
      name="living-situation"
      label="" />
  </div>
</template>

<script>
import CarInsuranceAnswer from './CarInsuranceAnswer'
import { mapState } from 'vuex'
import FitnessTestQuestionLabel from '../QuestionsLabel/FitnessTestQuestionLabel.vue'

import InputBoxButtons from '@/components/BasicInputs/InputBoxButtons.vue'
export default {
  name: 'HaveCarInsurance',
  components: { InputBoxButtons, FitnessTestQuestionLabel, CarInsuranceAnswer },

  computed: {
    ...mapState({
      haveCarInsurance: (state) => state.fitnessTest.questions.haveCarInsurance,
    }),
  },

  props: {
    questionNumber: {
      type: String,
      default: '',
    },
  },

  methods: {
    setHaveCarInsurance(value) {
      this.$store.dispatch('setFitnessTestHaveCarInsurance', value)
    },
  },
}
</script>

<style scoped></style>
