<template>
  <div class="flex flex-col rounded-lg sm:bg-blue-5 py-5 px-10 max-w-[455px]" v-if="product">
    <div class="my-5 flex flex-col h-full">
      <!-- <div -->
      <!--   class="w-fit bg-otivo-red flex flex-row justify-center items-center gap-1 rounded-t-[4px] py-[10px] px-5 text-white"> -->
      <!--   <LightningBolt class="w-3 h-3" fill1="#fff" /> -->
      <!--   <p class="button-2">Free 7-day trial</p> -->
      <!-- </div> -->
      <div class="flex flex-col grow h-full bg-white rounded-lg border-1px border-grey-field p-5">
        <h5 class="font-bold">Order summary</h5>
        <p class="paragraph-3 text-grey-3">12-month Otivo access</p>
        <p class="paragraph-2 pt-5">Easy unlimited access for 12 months covering...</p>
        <Divider />
        <div class="flex flex-col gap-5">
          <!-- <div class="flex gap-3 items-center">-->
          <!--   <BasicTick class="flex fill-otivo-blue h-4 w-4 flex-shrink-0"></BasicTick>-->
          <!--   <p class="paragraph-2 flex flex-grow-1">24-7 access to our online resource centre</p>-->
          <!-- </div>-->
          <div class="flex gap-3 items-center">
            <BasicTick class="flex fill-otivo-blue h-4 w-4 flex-shrink-0"></BasicTick>
            <p class="paragraph-2 flex flex-grow-1">Super investment advice</p>
          </div>
          <div class="flex gap-3 items-center">
            <BasicTick class="flex fill-otivo-blue h-4 w-4 flex-shrink-0"></BasicTick>
            <p class="paragraph-2 flex flex-grow-1">Salary sacrifice advice</p>
          </div>
          <div class="flex gap-3 items-center">
            <BasicTick class="flex fill-otivo-blue h-4 w-4 flex-shrink-0"></BasicTick>
            <p class="paragraph-2 flex flex-grow-1">Lump sum contributions advice</p>
          </div>
          <div class="flex gap-3 items-center">
            <BasicTick class="flex fill-otivo-blue h-4 w-4 flex-shrink-0"></BasicTick>
            <p class="paragraph-2 flex flex-grow-1">Personal insurance advice</p>
          </div>
        </div>
        <Divider class="mt-auto" />
        <h5 class="font-bold mt-[60px]" v-if="payment == 'credit'">
          ${{ product.yearly_price }}<span class="paragraph-2 text-grey-3"> / year</span>
        </h5>
        <h5 class="font-bold mt-[60px]" v-else>
          <span class="line-through decoration-otivo-red decoration-[3px] pr-2">${{ product.yearly_price }}</span><span
          class="text-otivo-red">$0</span><span class="paragraph-2 text-grey-3"> / year</span>
        </h5>
      </div>
    </div>
  </div>
  <div v-else class="py-20 flex flex-col justify-center items-center">
    <LumpSumSkeleton />
  </div>
</template>

<script setup lang="ts">
import BasicTick from '@/components/icons/BasicTick.vue'
import Divider from '@/components/CreateAccount/Paywall/Partials/Divider.vue'
import { Product } from '@/types/ProductInterface.ts'
import LumpSumSkeleton from '@/components/CFS/Skeletons/LumpSumSkeleton.vue'

defineProps<{ product: Product, payment: 'credit' | 'coupon' }>()
</script>

<style scoped></style>
