<template>
  <section class="flex flex-col gap-y-[20px] max-w-[560px]">
    <h5 class="font-bold pt-12">Please confirm before proceeding...</h5>
    <div class="flex flex-col items-center text-blue-1 gap-y-[20px]">
      <p>
        You’ll have unlimited access to Otivo’s advice for 12 months, from {{ currentDate }} to
        {{ expirationDate }}. We’ll send you a friendly reminder 30 days before your subscription
        ends, so you can decide if you’d like to continue receiving our advice. If at any time you
        need us, we can be contacted at <a href="mailto:info@otivo.com">info@otivo.com</a>
      </p>
      <p>
        If you choose to continue and agree to the privacy collection statement, you'll receive
        another email giving you access to your personal advice portal.
      </p>
    </div>
    <div
      class="rounded-sm w-full bg-white p-4 pr-2 border-1px border-grey-field overflow-auto"
      :class="{ 'h-auto': open }">
      <div class="flex cursor-pointer" @click="acknowledgePrivacyCollection">
        <span class="w-full text-grey-3 paragraph-2">
          Please read our privacy collection statement
        </span>
        <ChevronDown
          class="self-center mr-4"
          :class="{
            'transform rotate-180': open,
          }" />
      </div>
      <div v-if="open" class="py-3 text-grey-1 collapsible__content">
        <span>
          Otivo Pty Ltd ABN 47 602 457 732 (Otivo, we, us, or our) is committed to protecting your
          privacy. We collect your personal information from you (or your partner) when you (or your
          partner) register on, and use our platform to create financial plans.
          <br />
          <br />
        </span>
        <span>
          We use the personal information we collect for the purpose of providing advice on your
          financial situation. We do not trade, rent or sell your information or disclose it to any
          third parties.<br /><br />
        </span>
        <span>
          Our Privacy Policy contains more information about how to access and correct the
          information we hold about you and how to make a privacy related complaint. You can read it
          by visiting our website
          <a href="/content/privacy" target="_blank"> Privacy Policy. </a>
        </span>
      </div>
    </div>
    <div class="flex flex-row gap-x-[10px] cursor-pointer" @click="agreed = !agreed">
      <BaseCheckbox v-model:checked="agreed" name="terms-and-conditions" />
      <p class="paragraph-2 text-grey-1">
        I understand that the advice is provided by Otivo (Otivo Pty Ltd ABN 47 602 457 732 AFSL and
        Australian Credit Licence No. 485665) and is limited and does not cover all aspects of my
        circumstances. I agree to receive all communications electronically.
      </p>
    </div>

    <OtivoButton @click="handleNext" :loading="loading" class="mt-[60px] w-full sm:w-fit"
      >Next</OtivoButton
    >
    <span class="otivo-error-message text-center" v-if="error"> {{ error }}</span>
  </section>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import ChevronDown from '@/components/icons/ChevronDown.vue'
import { useAccountCreationStore } from '@/store/pinia/AccountCreationStore.ts'
import { useUserStore } from '@/store/pinia/UserStore.ts'
import { sendLegalWelcomeEmail } from '@/store/pinia/adminPortal/EmailManager.ts'
import { userAuth0Id } from '@/lib/AuthenticatorPlugin.ts'

//todo: refactor accountNumber to user store in hydra
const accountCreationStore = useAccountCreationStore()
const userStore = useUserStore()
const userAccountNumber = accountCreationStore.userAccountNumber
const getUser = computed(() => userStore.getUser)
//end todo
const emit = defineEmits<{
  (e: 'next', data: string): void
}>()

const getAdviceDate = (extraYears = 0) => {
  const date = new Date()
  const extraDays = extraYears * 365
  date.setDate(date.getDate() + extraDays)
  const month = date.toLocaleString('default', { month: 'long' })
  const year = date.getFullYear()
  return `${date.getDate()} ${month} ${year}`
}

const currentDate = ref(getAdviceDate())
const expirationDate = ref(getAdviceDate(1))

const agreed = ref(false)
const open = ref(false)
const loading = ref(false)
const error = ref('')
const acknowledgedPrivacy = ref(false)
const acknowledgePrivacyCollection = () => {
  open.value = !open.value
  if (open.value) acknowledgedPrivacy.value = true
}
const handleNext = async () => {
  error.value = ''
  loading.value = true
  // todo: refactor consent management in hydra
  if (agreed.value && acknowledgedPrivacy.value) {
    try {
      await accountCreationStore.setTermsAndConditionsAgreed(
        userAccountNumber ? userAccountNumber : getUser.value.account_id,
      )
      await sendLegalWelcomeEmail(userAuth0Id.value)

      emit('next', 'terms and conditions accepted')
    } catch (e) {
      console.error(e)
      loading.value = false
    }
  } else {
    error.value = 'Please agree to the privacy collection statement to continue'
  }
  loading.value = false
}
</script>

<style></style>
