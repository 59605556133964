<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5185 16H1.00176C0.736079 16 0.481277 15.8946 0.29341 15.7071C0.105543 15.5195 0 15.2652 0 15V12C0 11.7347 0.105543 11.4804 0.29341 11.2929C0.481277 11.1053 0.736079 11 1.00176 11H8.51498C8.78067 11 9.03547 11.1053 9.22334 11.2929C9.4112 11.4804 9.51674 11.7347 9.51674 12C9.51674 12.2652 9.4112 12.5195 9.22334 12.7071C9.03547 12.8946 8.78067 13 8.51498 13H2.00353V14H10.1554L14.3853 10.4825C14.5904 10.3241 14.8491 10.2513 15.1069 10.2793C15.3647 10.3074 15.6015 10.4342 15.7676 10.633C15.9337 10.8318 16.0161 11.0872 15.9974 11.3454C15.9787 11.6036 15.8605 11.8445 15.6676 12.0175L11.1596 15.7675C10.9798 15.9174 10.7529 15.9997 10.5185 16Z"
          :fill="fill1"/>
    <path d="M10.9042 5.1C10.8624 5.02739 10.8128 4.9595 10.7564 4.8975C10.0877 4.1925 9.09098 4 8.21194 3.8075C7.85882 3.735 7.10499 3.75 7.10499 3.3325C7.10499 2.915 7.99906 2.945 8.32714 2.985C8.58362 2.99401 8.82821 3.09522 9.01585 3.27C9.17792 3.48018 9.41699 3.61749 9.68047 3.65172C9.94396 3.68594 10.2103 3.61428 10.4208 3.4525C10.6314 3.29072 10.7689 3.05207 10.8032 2.78905C10.8375 2.52603 10.7657 2.26018 10.6036 2.05C10.2128 1.57155 9.67356 1.23647 9.07095 1.0975V1C9.07095 0.734784 8.96541 0.48043 8.77754 0.292893C8.58967 0.105357 8.33487 0 8.06919 0C7.8035 0 7.5487 0.105357 7.36083 0.292893C7.17297 0.48043 7.06742 0.734784 7.06742 1V1.0825C6.66164 1.17083 6.27977 1.34566 5.94795 1.595C5.61457 1.87245 5.36414 2.23616 5.2241 2.64627C5.08407 3.05638 5.05983 3.49705 5.15406 3.92C5.23223 4.28981 5.41216 4.63053 5.67362 4.9039C5.93507 5.17726 6.26771 5.37244 6.63416 5.4675C7.01714 5.59477 7.40885 5.6942 7.80622 5.765C8.3071 5.87 9.30887 5.975 9.30887 6.4925C9.30887 7.01 6.96224 6.96 6.51144 6.3875C6.34539 6.1803 6.10369 6.04743 5.8395 6.01813C5.57531 5.98883 5.31028 6.06549 5.10272 6.23125C4.89515 6.39701 4.76205 6.63829 4.7327 6.90201C4.70334 7.16574 4.78014 7.4303 4.94619 7.6375C5.48063 8.25463 6.20907 8.67241 7.01233 8.8225V9C7.01233 9.26522 7.11787 9.51957 7.30574 9.70711C7.4936 9.89464 7.7484 10 8.01409 10C8.27977 10 8.53457 9.89464 8.72244 9.70711C8.91031 9.51957 9.01585 9.26522 9.01585 9V8.855C9.46931 8.77273 9.90232 8.60289 10.2906 8.355C11.1421 7.79 11.7532 6.6325 10.9042 5.1Z"
          :fill="fill2"/>
  </svg>
</template>

<script setup lang="ts">
type Props = {
  fill1: string
  fill2: string
}

withDefaults(defineProps<Props>(), {
  fill1: '#0064FF',
  fill2: '#F00',
})
</script>

<style scoped></style>
