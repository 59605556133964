<template>
  <section class="flex flex-col gap-y-[20px] w-fit mx-auto max-w-[570px]">
    <h5 class="font-bold pt-12">Verify mobile number</h5>
    <form @submit.prevent="handleNext" class="flex flex-col gap-y-[10px] mb-[80px]">
      <BasePinInput
        ref="pinInput"
        autofocus
        label="Please enter the 6 digit code sent to your phone."
        :length="6"
        @complete="setPinAndTriggerNext" />
      <ClipLoader class="mt-[60px]" v-if="resending" color="var(--otivo-blue)" />
      <div v-else class="mt-[60px] text-blue-1 text-center">
        Didn't receive a code?
        <span class="underline decoration-blue-1 cursor-pointer" @click="resendSms"
          >Please click here to resend.
        </span>
      </div>

      <OtivoButton type="submit" class="mt-[60px] w-full" :loading="loading">Next</OtivoButton>
    </form>
  </section>
</template>
<script setup lang="ts">
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import BasePinInput from '@/components/Inputs/BasePinInput.vue'
import { computed, ref, shallowRef } from 'vue'
import getApiInstance from '@/services/Api.ts'
import { useToast } from '@/composables/useToast.ts'
import { useAccountCreationStore } from '@/store/pinia/AccountCreationStore.ts'
import { useThrottleFn } from '@vueuse/core'
import { useUserStore } from '@/store/pinia/UserStore.ts'

const { errorToast } = useToast()
const api = getApiInstance()
const accountCreationStore = useAccountCreationStore()

const user = computed(() => useUserStore().getUser)

const emit = defineEmits<{
  (e: 'next', data: string): void
}>()

const pin = ref('')
const pinInput = ref()
const loading = shallowRef(false)
const resending = ref(false)

const resendSms = useThrottleFn(async () => {
  resending.value = true
  try {
    const acc = accountCreationStore.userAccountNumber ?? user.value.account_id
    await api.Post(`/v3/account/resend-sms/${acc}`)
  } catch (e) {
    errorToast('Error resending SMS')
    console.error(e)
  } finally {
    resending.value = false
  }
}, 60000)

const setPinAndTriggerNext = async (number: string) => {
  pin.value = number
  await handleNext()
}
const handleNext = async () => {
  loading.value = true
  try {
    const res = await api.Post('/v3/account/verify-mobile', {
      verification_code: pin.value,
    })
    if (res) emit('next', 'mobile verified')
  } catch (e) {
    errorToast('Error verifying mobile')
    pinInput.value.value = []
    document.getElementById('pin-input-0')?.focus()
  } finally {
    loading.value = false
  }
}
</script>
