<template>
  <div
    class="w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]"
    :class="{'pause-on-hover': hoverPause }">
    <ul ref="listContainer"
        class="flex items-center justify-center md:justify-start [&_img]:max-w-none"
        :class="[{
          'animate-infinite-scroll-90': speed === 90,
          'animate-infinite-scroll-60': speed === 60,
          'animate-infinite-scroll-30': speed === 30,
          'animate-infinite-scroll-15': speed === 15,
        },
        listClass
        ]"
    >
      <li v-for="(item, index) in items" :key="`slider-${index}`">
        <component :is="item" />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'

interface Props {
  items: Array<unknown>
  speed?: 90 | 60 | 30 | 15
  listClass?: string
  hoverPause?: boolean
}

withDefaults(defineProps<Props>(), {
  speed: 30,
  listClass: 'space-x-5',
  hoverPause: false
})

const listContainer = ref<HTMLUListElement | null>(null)

onMounted(() => {
  if (listContainer.value) {
    const ul = listContainer.value
    const clone = ul.outerHTML
    ul.insertAdjacentHTML('afterend', clone)
    if (ul.nextSibling instanceof HTMLElement) {
      ul.nextSibling.setAttribute('aria-hidden', 'true')
    }
  }
})
</script>

<style scoped>
.pause-on-hover:hover > ul {
  animation-play-state: paused;
}
</style>
