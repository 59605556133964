import { Ref } from 'vue'
import { useLDFlag } from 'launchdarkly-vue-client-sdk'

export const useLaunchDarkly = () => {
  const isFeatureEnabled = (feature: Feature): Readonly<Ref<boolean | null>> => {
    return useLDFlag(feature, null)
  }

  return {
    isFeatureEnabled,
  }
}

export const FeatureList = {
  ENABLE_DTD: 'enable-dtd',
  ENABLE_CFS_MOD_2: 'enable-cfs-module-2',
  MAINTENANCE_MODE: 'force-frontend-maintenance-mode',
  ENABLE_CFS_MOD_2_3: 'enable-cfs-module-2.3',
  ENABLE_INSURANCE_MODULE: 'enable-insurance-module',
  HIDE_REPORTING: 'hide-reporting',
} as const

export type Feature = (typeof FeatureList)[keyof typeof FeatureList]
