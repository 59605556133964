<template>
  <div>
    <fitness-test-question-label
      :question-number="questionNumber"
      question="I'm comfortable with my current level of long-term savings and investments (including superannuation)" />

    <number-rating
      :selected="comfortableWithSavingsAndInvestments"
      @click="setComfortableWithSavingsAndInvestments" />
  </div>
</template>

<script>
import NumberRating from '../NumberRating/NumberRating.vue'

import { mapState } from 'vuex'
import FitnessTestQuestionLabel from '../QuestionsLabel/FitnessTestQuestionLabel.vue'

export default {
  name: 'ComfortableWithSavingsAndInvestments',
  components: { FitnessTestQuestionLabel, NumberRating },
  computed: {
    ...mapState({
      comfortableWithSavingsAndInvestments: (state) =>
        state.fitnessTest.questions.comfortableWithSavingsAndInvestments,
    }),
  },

  props: {
    questionNumber: {
      type: String,
      default: '',
    },
  },

  methods: {
    setComfortableWithSavingsAndInvestments(value) {
      this.$store.dispatch('setFitnessTestComfortableWithSavingsAndInvestments', value)
    },
  },
}
</script>

<style scoped></style>
