<template>
  <div>
    <fitness-test-question-label
      :question-number="questionNumber"
      question="Do you have health insurance?" />

    <input-box-buttons
      @click="setHaveHealthInsurance"
      :buttons="[
        {
          name: 'Yes',
          value: 'yes',
        },
        {
          name: 'No',
          value: 'no',
        },
        {
          name: 'Self insured',
          value: 'self_insured',
        },
      ]"
      :selected="haveHealthInsurance"
      name="living-situation"
      label="" />
  </div>
</template>

<script>
import HealthInsuranceAnswer from './HealthInsuranceAnswer'
import { mapState } from 'vuex'
import FitnessTestQuestionLabel from '../QuestionsLabel/FitnessTestQuestionLabel.vue'

import InputBoxButtons from '@/components/BasicInputs/InputBoxButtons.vue'
export default {
  name: 'HaveHealthInsurance',
  components: { InputBoxButtons, FitnessTestQuestionLabel, HealthInsuranceAnswer },

  computed: {
    ...mapState({
      haveHealthInsurance: (state) => state.fitnessTest.questions.haveHealthInsurance,
    }),
  },

  props: {
    questionNumber: {
      type: String,
      default: '',
    },
  },

  methods: {
    setHaveHealthInsurance(value) {
      this.$store.dispatch('setFitnessTestHaveHealthInsurance', value)
    },
  },
}
</script>

<style scoped></style>
