<template>
  <div class="relative border-otivo-blue flex flex-col h-[100vh]">
    <CallcentrePortalNavBar v-if="userStore.cfsClient" />
    <NavBar v-else />
    <ImpersonationBanner />
    <WhitelabelBanner v-if="isItWellbeinggateway || isItSurmount" />
    <div class="flex-grow">
      <Transition name="fade">
        <slot />
      </Transition>
    </div>
    <!--    <div-->
    <!--      v-if="authenticated && user && (environment === 'staging' || environment === 'development')"-->
    <!--      class="fixed bottom-[77px] max-h-100% max-w-100% md:bottom-0 right-0 z-90">-->
    <!--      <LLMChat-->
    <!--        ref="llmChat"-->
    <!--        :is-closeable="true"-->
    <!--        max-width="max-w-[460px]"-->
    <!--        @question="askQuestion"-->
    <!--        @reaction="(reaction) => handleReaction(reaction)">-->
    <!--        <template #header>-->
    <!--          <div-->
    <!--            :class="{ 'min-w-[360px] max-w-[500px]': llmChat.showChat }"-->
    <!--            class="flex justify-center gap-2 text-white">-->
    <!--            <BetaLogo class="flex-none" />-->
    <!--            <div :class="{ 'hidden ': !llmChat.showChat }">-->
    <!--              <h6 class="hidden md:block">Ask Otivo</h6>-->
    <!--              <p class="pr-[22px]">-->
    <!--                BETA version. Not financial advice. Answers may be inaccurate and misleading and-->
    <!--                should not be relied upon for financial decision-making.-->
    <!--              </p>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </template>-->
    <!--      </LLMChat>-->
    <!--    </div>-->
    <!--  Also sorry here Rich  -->
    <div
      v-if="userStore.cfsClient"
      class="text-mf1 md:text-f1 py-[40px] flex flex-col justify-center items-center space-y-[80px] bg-midnight">
      <CFSFooterNav />

      <div class="text-center text-blue-2">
        {{ providerDetails.provider }} ABN {{ providerDetails.abn }} AFSL
        {{
          providerDetails.afsl === '485665'
            ? ' + Australian Credit Licence ' + providerDetails.afsl
            : providerDetails.afsl
        }}
        <div>
          <!--            Email: <a href="mailto:otivohelp@otivo.com">otivohelp@otivo.com</a>-->
          Suite 2.01, 50 York St, Sydney, NSW 2000
        </div>
      </div>
    </div>
    <CustomFooter v-else class="w-full md:pt-4 z-10 bg-midnight text-blue-4 relative" />
  </div>
</template>

<script lang="ts" setup>
import CustomFooter from '@/components/Footer/CustomFooter.vue'
import TopMenuBar from '@/components/TopMenuBar/TopMenuBar.vue'
import { useStore } from 'vuex'
import WhitelabelBanner from '@/components/BrandComponents/HomePages/Assure/WhitelabelBanner.vue'
import ImpersonationBanner from '@/components/Admin/ImpersonationBanner.vue'
import { useAuth0 } from '@/lib/AuthenticatorPlugin'
import { computed, ref } from 'vue'
import NavBar from '@/components/NavBar/NavBar.vue'
import LLMChat from '@/components/LLMChat.vue'
import { Reaction, REACTIONS, useChatBotStore } from '@/store/pinia/ChatBotStore.ts'
import CFSFooterNav from '@/views/Clients/CFS/CFSFooterNav.vue'
import { useUserStore } from '@/store/pinia/UserStore.ts'
import CallcentrePortalNavBar from '@/views/Clients/CFS/CallcentrePortalNavBar.vue'

type Props = {
  fullHeight?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  fullHeight: true,
})

const store = useStore()
const auth0 = useAuth0()
const userStore = useUserStore()

const user = computed(() => userStore.getUser)
const authenticated = computed(() => auth0.isAuthenticated.value)
const showNavBar = computed(() => store.state.navBar.showNavBar)
const isItWellbeinggateway = computed(() => store.getters.isItWellbeinggateway)
const isItSurmount = computed(() => store.getters.isItSurmount)
const environment = computed(() => import.meta.env.MODE)
const providerDetails = computed(() => store.getters.getAFSLDetailsForWhiteLabel)
const showFullHeight = computed(() => (authenticated.value ? props.fullHeight : false))

const chatBotStore = useChatBotStore()
const llmChat = ref<InstanceType<typeof LLMChat> | null>(null)
const askQuestion = (question: string) => {
  if (question.length === 0) {
    return
  }

  chatBotStore.waitingForResponse = true

  chatBotStore
    .askQuestion(question)
    .then(() => {
      chatBotStore.waitingForResponse = false
    })
    .catch((err: Error) => console.error(err))
}

const handleReaction = ({ existingReaction, newReaction, answerId }: Reaction) => {
  if (existingReaction === newReaction) {
    // Remove reaction if user clicks same reaction twice
    newReaction = REACTIONS.NEUTRAL
  }

  chatBotStore.updateReaction(answerId, newReaction)
}
</script>
