<template>
  <path
    d="M25.3125 19.289C23.713 19.2834 22.1331 18.9349 20.6782 18.2667C19.2232 17.5985 17.9267 16.626 16.875 15.414L11.25 8.93677L19.6875 1.44133L25.3125 7.97509L28.8422 3.9021C30.2114 2.29534 32.0063 1.11268 34.0178 0.491773C36.0294 -0.129136 38.1749 -0.162767 40.2047 0.394792C45.9422 1.87974 52.0312 7.59325 52.0312 15.117H40.7812C40.7812 13.2643 38.4891 11.6238 37.3078 11.2985L33.75 15.3998C32.6996 16.6144 31.4037 17.5896 29.9487 18.2603C28.4936 18.9309 26.9131 19.2816 25.3125 19.289V19.289Z"
    :fill="fill" />
  <path
    d="M52.0313 25.4268V22.2306H40.7813V25.4409C32.8152 26.8557 25.6655 31.2212 20.7423 37.6764C15.8191 44.1315 13.4798 52.2077 14.186 60.3119C14.8921 68.4161 18.5923 75.9601 24.5567 81.4558C30.5212 86.9515 38.3169 90 46.4063 90C54.4956 90 62.2913 86.9515 68.2558 81.4558C74.2202 75.9601 77.9204 68.4161 78.6265 60.3119C79.3327 52.2077 76.9934 44.1315 72.0702 37.6764C67.1471 31.2212 59.9973 26.8557 52.0313 25.4409V25.4268ZM46.4063 79.0687C40.7615 79.0687 35.3479 76.8136 31.3565 72.7995C27.365 68.7853 25.1227 63.341 25.1227 57.6642C25.1227 51.9874 27.365 46.5431 31.3565 42.529C35.3479 38.5149 40.7615 36.2598 46.4063 36.2598C52.0454 36.2598 57.4536 38.5127 61.4411 42.5228C65.4286 46.5329 67.6688 51.9718 67.6688 57.643C67.6688 63.3142 65.4286 68.7531 61.4411 72.7632C57.4536 76.7734 52.0454 79.0262 46.4063 79.0262V79.0687Z"
    :fill="fill" />
</template>

<script>
export default {
  name: 'DebtTile',
  props: {
    fill: { type: String, default: '#D4E7FE' },
    fill1: { type: String, default: '#D4E7FE' },
    fill2: { type: String, default: '#D4E7FE' }
  }
}
</script>

<style scoped></style>
