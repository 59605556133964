<template>
  <tooltip-icon
    class=""
    v-if="message"
    :fill="fill"
    v-tooltip="{
      content: message,
      classes: cssClass,
    }"
    :type="type"
    :size="size" />
</template>

<script>
import TooltipIcon from '@/components/icons/TooltipIcon.vue'
export default {
  name: 'InfoCircle',
  components: {
    TooltipIcon,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    fill: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: '',
    },
    size: {
      default: '16',
      type: String,
    },
    cssClass: {
      type: String,
      default: '',
    },
  },
}
</script>
