<template>
  <basic-input class="gap-3" :class="{ 'flex-col': stackLabel }">
    <template #input-label>
      <div
        v-if="label !== ''"
        class="pt-4 paragraph-2"
        :class="{ 'set-w-28 md:set-w-48': !stackLabel }">
        <label :for="name">
          {{ this.uppercase(label) }}
        </label>
      </div>
    </template>
    <template #input-box>
      <div
        class="custom-select w-full"
        :class="{ 'bg-white': !disabled }"
        @keyup.enter="open = !open"
        @keyup.space="open = !open">
        <div
          class="selected rounded-[5px] border border-grey-2 ring-2 flex p-4 bg-white text-grey-1 overflow-x-clip hover:border-otivo-blue hover:!ring-otivo-blue"
          data-test="selected"
          :class="[
            {
              'box-open !rounded-b-none': open,
              'pointer-events-none text-grey-2': disabled,
              'border-red-1 ring-red-light': error !== '',
              'ring-transparent': error === '',
            },
            styles.button,
          ]"
          @click="open = !open"
          :tabindex="tabindex">
          <div class="paragraph-2 self-center mr-[10px]" :class="{ 'text-grey-2': disabled }">
            {{ selected.label }}
            <needs-work v-if="itemErrors.includes(selected.value)" />
          </div>
          <dropdown-arrow
            class="mr-0 md:mr-4 m-auto transform rotate-180"
            :stroke="disabled || !open ? '#888' : '#0064FF'"
            :class="{ 'transform-none': open, hidden: disabled }" />
        </div>
        <div
          class="items w-full p-2 bg-white rounded-[5px] rounded-t-none"
          data-test="items"
          :class="{ selectHide: !open }">
          <div
            v-for="(option, i) of options"
            :key="i"
            @click="selectOption(option, true)"
            @keyup.enter="selectOption(option, false)"
            @keyup.space="selectOption(option, false)"
            :class="option.disabled ? 'pointer-events-none cursor-index text-grey-2' : ''"
            :tabindex="option.disabled ? -1 : tabindex">
            <div v-if="option.label !== '-'" class="px-2 py-4 paragraph-2 flex justify-between">
              <div>{{ option.label }}</div>
              <needs-work v-if="itemErrors.includes(option.value)" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #input-errors>
      <input-error :error="error" />
      <input-info :info="info" />
    </template>
  </basic-input>
</template>

<script>
import DropdownArrow from '@/components/icons/DropdownArrow.vue'
import BasicInput from '@/components/BasicInputs/BasicInput.vue'
import InputError from '@/components/BasicInputs/InputError.vue'
import InputInfo from '@/components/BasicInputs/InputInfo.vue'
import { capitalize } from '@/lib/helpers'
import NeedsWork from '@/components/icons/NeedsWork.vue'

export default {
  name: 'InputSelectBox',
  components: { NeedsWork, InputInfo, InputError, BasicInput, DropdownArrow },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    selectInput: {
      type: [Object, String],
      required: false,
      default: null,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    resetButton: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    itemErrors: {
      type: Array,
      default: () => [],
    },
    info: {
      type: String,
      default: '',
    },
    stackLabel: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: Object,
      required: false,
      default: () => {
        return {
          button: '',
          arrow: '',
        }
      },
    },
  },
  emits: ['update-select-box', 'change'],
  data() {
    return {
      selected: {
        label: this.default
          ? this.default
          : this.selectInput && this.selectInput.label
          ? this.selectInput.label
          : this.options.length > 0
          ? this.options[0].label
          : null,
        value: this.selectInput && this.selectInput.value ? this.selectInput.value : null,
      },
      open: false,
    }
  },
  watch: {
    selectInput() {
      if (this.resetButton) {
        this.selected = {
          label: this.selectInput.label,
          value: this.selectInput.value,
        }
      }
    },
  },
  methods: {
    selectOption(option, close) {
      this.selected = option
      if (close) this.open = !this.open
      this.$emit('update-select-box', option.value, option.label)
      // i hate this
      this.$emit('change', Number(option.value))
    },
    uppercase(value) {
      return capitalize(value)
    },
  },
}
</script>

<style scoped>
/*select {*/
/*  @apply border-otivo-blue text-grey-4;*/
/*}*/
/*.custom-input-select select {*/
/*  @apply border border-solid rounded;*/
/*}*/

.custom-select {
  position: relative;
}

.custom-select .selected {
  cursor: pointer;
  user-select: none;
  overflow: visible;
}

/*.custom-select .selected.open {*/
/*  border-radius: 10px;*/
/*}*/

/*.custom-select .selected:after {*/
/*  position: absolute;*/
/*  content: "";*/
/*  top: 20px;*/
/*  right: 1em;*/
/*  width: 0;*/
/*  height: 0;*/
/*  border: 5px solid transparent;*/
/*  border-color: #0064FF transparent transparent transparent;*/
/*}*/

/*.custom-select .selected.open:after {*/
/*  top: 12px;*/
/*  border-color: transparent transparent #0064FF transparent;*/
/*}*/

.custom-select .items {
  color: #000000;
  border: 1px solid #caced3;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 100;
  overflow: visible !important;
}

.custom-select .items div {
  color: #000000;
  cursor: pointer;
  user-select: none;
  border-radius: 10px;
}

.custom-select .items div:hover {
  background-color: #d4e7fe;
  color: #0064ff;
}

.selectHide {
  display: none;
}
</style>
