<template>
  <RouterLink
    :to="to"
    class="flex items-center justify-start rounded-sm p-[10px] w-full font-semibold text-base text-blue-1 hover:text-otivo-blue hover:bg-blue-5"
    active-class="bg-blue-5 text-otivo-blue">
    {{ label }}
  </RouterLink>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'

const props = withDefaults(
  defineProps<{
    to: RouteLocationRaw
    icon: string
    label: string
    active?: boolean
  }>(),
  {
    icon: null,
    active: false,
  },
)
</script>

<style scoped></style>
