<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 16H1C0.734784 16 0.48043 15.8946 0.292893 15.7071C0.105357 15.5196 0 15.2652 0 15C0 14.7348 0.105357 14.4804 0.292893 14.2929C0.48043 14.1054 0.734784 14 1 14H15C15.2652 14 15.5196 14.1054 15.7071 14.2929C15.8946 14.4804 16 14.7348 16 15C16 15.2652 15.8946 15.5196 15.7071 15.7071C15.5196 15.8946 15.2652 16 15 16Z"
      fill="#0064FF" />
    <path
      d="M15 4.75H1.00001C0.767477 4.74941 0.542416 4.66779 0.363549 4.5192C0.184683 4.3706 0.0631938 4.16432 0.0199824 3.93583C-0.023229 3.70734 0.0145391 3.47094 0.126789 3.26729C0.239039 3.06363 0.418753 2.90547 0.635014 2.82L7.63501 0.0699996C7.86965 -0.0219904 8.13037 -0.0219904 8.36501 0.0699996L15.365 2.82C15.5813 2.90547 15.761 3.06363 15.8732 3.26729C15.9855 3.47094 16.0233 3.70734 15.98 3.93583C15.9368 4.16432 15.8153 4.3706 15.6365 4.5192C15.4576 4.66779 15.2326 4.74941 15 4.75V4.75ZM6.28001 2.75H9.72001L8.00001 2.075L6.28001 2.75Z"
      fill="#0064FF" />
    <path
      d="M2 13C1.73478 13 1.48043 12.8946 1.29289 12.7071C1.10536 12.5196 1 12.2652 1 12V7C1 6.73478 1.10536 6.48043 1.29289 6.29289C1.48043 6.10536 1.73478 6 2 6C2.26522 6 2.51957 6.10536 2.70711 6.29289C2.89464 6.48043 3 6.73478 3 7V12C3 12.2652 2.89464 12.5196 2.70711 12.7071C2.51957 12.8946 2.26522 13 2 13Z"
      fill="#FF0000" />
    <path
      d="M6 13C5.73478 13 5.48043 12.8946 5.29289 12.7071C5.10536 12.5196 5 12.2652 5 12V7C5 6.73478 5.10536 6.48043 5.29289 6.29289C5.48043 6.10536 5.73478 6 6 6C6.26522 6 6.51957 6.10536 6.70711 6.29289C6.89464 6.48043 7 6.73478 7 7V12C7 12.2652 6.89464 12.5196 6.70711 12.7071C6.51957 12.8946 6.26522 13 6 13Z"
      fill="#FF0000" />
    <path
      d="M10 13C9.73478 13 9.48043 12.8946 9.29289 12.7071C9.10536 12.5196 9 12.2652 9 12V7C9 6.73478 9.10536 6.48043 9.29289 6.29289C9.48043 6.10536 9.73478 6 10 6C10.2652 6 10.5196 6.10536 10.7071 6.29289C10.8946 6.48043 11 6.73478 11 7V12C11 12.2652 10.8946 12.5196 10.7071 12.7071C10.5196 12.8946 10.2652 13 10 13Z"
      fill="#FF0000" />
    <path
      d="M14 13C13.7348 13 13.4804 12.8946 13.2929 12.7071C13.1054 12.5196 13 12.2652 13 12V7C13 6.73478 13.1054 6.48043 13.2929 6.29289C13.4804 6.10536 13.7348 6 14 6C14.2652 6 14.5196 6.10536 14.7071 6.29289C14.8946 6.48043 15 6.73478 15 7V12C15 12.2652 14.8946 12.5196 14.7071 12.7071C14.5196 12.8946 14.2652 13 14 13Z"
      fill="#FF0000" />
  </svg>
</template>

<script>
export default {
  name: 'BankIcon'
}
</script>

<style scoped></style>
