<template>
  <!--  <g>-->
  <!--    <circle-->
  <!--      cx="7"-->
  <!--      cy="11"-->
  <!--      r="6"-->
  <!--      fill="#0064FF"-->
  <!--    />-->
  <!--    <path-->
  <!--      d="M7 4V8.5"-->
  <!--      stroke="#0064FF"-->
  <!--      stroke-width="2"-->
  <!--    />-->
  <!--    <path-->
  <!--      d="M7 3C7 2.33333 6.7 1 5.5 1C4 1 4.5 3 3 3C1.8 3 1.33333 1.66667 1 1"-->
  <!--      :stroke="active ? '#FF0000' : '#0064FF'"-->
  <!--      stroke-width="2"-->
  <!--    />-->
  <!--  </g>-->
  <svg width="18" height="18" viewBox="10 0 40 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.3755 10.3017C13.4689 10.2987 12.5734 10.1126 11.7488 9.75572C10.9241 9.39885 10.1893 8.87945 9.59321 8.23216L6.40503 4.77287L11.1873 0.769771L14.3755 4.25927L16.3761 2.084C17.1521 1.22587 18.1694 0.594252 19.3096 0.262642C20.4497 -0.0689677 21.6657 -0.0869295 22.8162 0.210847C26.0681 1.00391 29.5194 4.05534 29.5194 8.07355H23.143C23.143 7.0841 21.8438 6.20795 21.1743 6.03423L19.1578 8.22461C18.5624 8.87327 17.8279 9.3941 17.0032 9.75228C16.1785 10.1105 15.2827 10.2978 14.3755 10.3017Z"
      :fill="active ? '#FF0000' : '#0064FF'" />
    <path
      d="M26.3311 48.3009C21.7039 48.3095 17.2419 46.6717 13.8281 43.7117C10.4144 40.7516 8.29759 36.685 7.89674 32.3166C7.49588 27.9483 8.84016 23.5964 11.6635 20.1224C14.4869 16.6484 18.5836 14.3054 23.1429 13.557V11.8727H29.5193V13.5797C34.0786 14.328 38.1754 16.6711 40.9987 20.1451C43.8221 23.6191 45.1664 27.9709 44.7655 32.3393C44.3647 36.7077 42.2479 40.7743 38.8341 43.7344C35.4203 46.6944 30.9583 48.3322 26.3311 48.3236V48.3009Z"
      fill="#0064FF" />
  </svg>
</template>

<script>
export default {
  name: 'IconDebt',
  props: {
    active: { type: Boolean, default: true }
  }
}
</script>

<style scoped></style>
