<template>
  <div id="super-fund-facts" class="bg-otivo-blue text-center text-white py-[80px]">
    <h3 v-if="superFundFacts.heading" v-html="superFundFacts.heading" />
    <p class="intro-1 pt-[40px]">
      {{ superFundFacts.subHeading }}
    </p>
    <div class="flex flex-wrap gap-5 justify-center pt-[40px]">
      <div
        ref="cards"
        :key="index"
        v-for="(card, index) in superFundFacts.iterableItems"
        class="bg-white text-grey-1 py-[40px] px-[20px] rounded-[20px] w-[290px] fund-fact-shadow">
        <div class="h3 !text-otivo-red">
          {{ card.heading }}
        </div>
        <p class="paragraph-2 pt-2.5">
          {{ card.subHeading }}
        </p>
        <p class="button-2 font-bold pt-2.5">
          {{ card.body }}
        </p>
        <div class="headline-6 text-grey-2 pt-2.5">
          {{ card.footer }}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
type SuperFundFactsTileType = {
  heading: string
  subHeading: string
  body?: string
  footer?: string
}

type SuperFundFactsType = {
  heading: string
  subHeading: string
  iterableItems: Array<SuperFundFactsTileType>
}

interface Props {
  superFundFacts: SuperFundFactsType
}

defineProps<Props>()
</script>
<style lang="scss" scoped>
.fund-fact-shadow {
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  &:hover {
    transition: ease-in-out 150ms;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  }
}
</style>
