<template>
  <svg width="42" height="43" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_11425_181128)">
      <path
        d="M31.8742 20.2671C30.2781 17.8901 28.1211 15.9428 25.5939 14.5971C28.0942 12.064 29.5314 8.15271 29.5314 3.20459C29.5314 2.5084 29.2549 1.84072 28.7626 1.34844C28.2703 0.856152 27.6026 0.57959 26.9064 0.57959H7.98018C7.28399 0.57959 6.61631 0.856152 6.12403 1.34844C5.63175 1.84072 5.35518 2.5084 5.35518 3.20459C5.35518 8.15928 6.753 12.0705 9.24675 14.6037C6.45678 16.088 4.12269 18.3028 2.49402 21.011C0.865354 23.7193 0.00335539 26.8193 0.000184576 29.9796C-0.0185358 34.2008 1.38726 38.3049 3.99018 41.628C4.23636 41.9255 4.54515 42.1651 4.89453 42.3296C5.24391 42.494 5.62527 42.5794 6.01143 42.5796H20.4489C21.1451 42.5796 21.8128 42.303 22.3051 41.8107C22.7974 41.3185 23.0739 40.6508 23.0739 39.9546C23.0739 39.2584 22.7974 38.5907 22.3051 38.0984C21.8128 37.6061 21.1451 37.3296 20.4489 37.3296H7.34362C5.98978 35.1158 5.26595 32.5745 5.25018 29.9796C5.25681 26.7774 6.52285 23.7064 8.77481 21.4299C11.0268 19.1533 14.0839 17.8541 17.2858 17.8127H17.5942C19.5588 17.8403 21.4875 18.3423 23.2163 19.2758C24.9451 20.2093 26.4227 21.5468 27.5233 23.1743C27.9328 23.6912 28.5219 24.035 29.1733 24.1374C29.8248 24.2398 30.4909 24.0932 31.0393 23.7269C31.5876 23.3605 31.9779 22.8012 32.1326 22.1601C32.2873 21.519 32.1951 20.8432 31.8742 20.2671V20.2671ZM10.7692 5.82959H24.078C23.3496 11.178 20.3177 12.4971 17.6467 12.5627H17.1808C14.5164 12.4905 11.4977 11.1649 10.7692 5.82959Z"
        fill="#0064FF" />
      <path
        d="M39.375 33.3921H28.875C28.1788 33.3921 27.5111 33.1155 27.0188 32.6232C26.5266 32.131 26.25 31.4633 26.25 30.7671C26.25 30.0709 26.5266 29.4032 27.0188 28.9109C27.5111 28.4187 28.1788 28.1421 28.875 28.1421H39.375C40.0712 28.1421 40.7389 28.4187 41.2312 28.9109C41.7234 29.4032 42 30.0709 42 30.7671C42 31.4633 41.7234 32.131 41.2312 32.6232C40.7389 33.1155 40.0712 33.3921 39.375 33.3921Z"
        fill="#FF0000" />
      <path
        d="M39.375 42.5796H28.875C28.1788 42.5796 27.5111 42.303 27.0188 41.8107C26.5266 41.3185 26.25 40.6508 26.25 39.9546C26.25 39.2584 26.5266 38.5907 27.0188 38.0984C27.5111 37.6061 28.1788 37.3296 28.875 37.3296H39.375C40.0712 37.3296 40.7389 37.6061 41.2312 38.0984C41.7234 38.5907 42 39.2584 42 39.9546C42 40.6508 41.7234 41.3185 41.2312 41.8107C40.7389 42.303 40.0712 42.5796 39.375 42.5796Z"
        fill="#FF0000" />
    </g>
    <defs>
      <clipPath id="clip0_11425_181128">
        <rect width="42" height="42" fill="white" transform="translate(0 0.57959)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts" />
