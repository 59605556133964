<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.90137 3.465C8.63615 3.465 8.3818 3.35964 8.19426 3.17211C8.00672 2.98457 7.90137 2.73022 7.90137 2.465V1C7.90137 0.734783 8.00672 0.48043 8.19426 0.292893C8.3818 0.105357 8.63615 0 8.90137 0C9.16658 0 9.42094 0.105357 9.60847 0.292893C9.79601 0.48043 9.90137 0.734783 9.90137 1V2.465C9.90137 2.73022 9.79601 2.98457 9.60847 3.17211C9.42094 3.35964 9.16658 3.465 8.90137 3.465Z"
      :class="`fill-${fill1}`" />
    <path
      d="M8.79883 16.0004C8.53361 16.0004 8.27926 15.895 8.09172 15.7075C7.90419 15.52 7.79883 15.2656 7.79883 15.0004V13.6504C7.79883 13.3852 7.90419 13.1308 8.09172 12.9433C8.27926 12.7557 8.53361 12.6504 8.79883 12.6504C9.06404 12.6504 9.3184 12.7557 9.50593 12.9433C9.69347 13.1308 9.79883 13.3852 9.79883 13.6504V15.0004C9.79883 15.2656 9.69347 15.52 9.50593 15.7075C9.3184 15.895 9.06404 16.0004 8.79883 16.0004Z"
      :class="`fill-${fill1}`" />
    <path
      d="M9.04905 14.1676C7.02905 14.1676 4.98655 13.3576 4.01905 12.1451C3.85329 11.9379 3.77662 11.6733 3.80593 11.4096C3.83523 11.1459 3.9681 10.9046 4.1753 10.7388C4.3825 10.5731 4.64706 10.4964 4.91078 10.5257C5.1745 10.555 5.41579 10.6879 5.58155 10.8951C6.54405 12.1051 9.63655 12.6701 11.2415 11.5926C11.529 11.4026 12.189 10.9601 11.504 9.62757C10.899 9.08007 9.67905 8.83007 8.59405 8.60507C7.92478 8.48886 7.26536 8.32171 6.62155 8.10507C6.10812 7.97513 5.64156 7.70347 5.2751 7.32111C4.90865 6.93874 4.65706 6.46106 4.54905 5.94257C4.41445 5.34005 4.4477 4.71216 4.64518 4.12722C4.84265 3.54228 5.19679 3.02272 5.66905 2.62507C6.59155 1.89757 8.16905 1.55507 9.68405 1.74507C11.0065 1.91007 12.089 2.46007 12.729 3.29257C12.819 3.39528 12.8869 3.51531 12.9287 3.64527C12.9705 3.77523 12.9852 3.91237 12.972 4.04824C12.9588 4.18411 12.9179 4.31584 12.8518 4.4353C12.7858 4.55477 12.6959 4.65944 12.5879 4.74287C12.4798 4.8263 12.3558 4.88673 12.2236 4.92042C12.0913 4.95412 11.9535 4.96037 11.8187 4.9388C11.6839 4.91722 11.5549 4.86828 11.4398 4.79497C11.3246 4.72167 11.2257 4.62556 11.149 4.51257C10.839 4.11257 10.214 3.82757 9.43655 3.73007C8.34905 3.59257 7.32405 3.86507 6.91155 4.19007C6.73204 4.35185 6.5986 4.5583 6.52482 4.78842C6.45105 5.01853 6.43957 5.26408 6.49155 5.50007C6.51989 5.66501 6.59771 5.81742 6.7147 5.93708C6.83169 6.05675 6.9823 6.13801 7.14655 6.17007C7.19096 6.17961 7.23446 6.19299 7.27655 6.21007C7.83825 6.3977 8.41318 6.5431 8.99655 6.64507C10.4215 6.94007 12.049 7.27257 13.049 8.33757C13.1065 8.39862 13.1561 8.46666 13.1965 8.54007C14.2465 10.4401 13.9465 12.2026 12.3615 13.2551C11.3715 13.8792 10.219 14.1967 9.04905 14.1676Z"
      :class="`fill-${fill1}`" />
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue'
})
</script>

<style scoped></style>
