<template>
  <section>
    <p
      v-if="!showMore"
      class="text-otivo-blue button-1 flex flex-row items-center cursor-pointer"
      @click="showMore = true">
      <span class="mr-[5px] text-black"><arrow-right-icon width="12px" /></span>
      Read more
    </p>
    <div v-if="showMore">
      <p class="button-1 mt-[20px]">Different types of investments</p>
      <!-- !!!NOTE: IMO this should be data driven, it's not because it's gotta be reviewed tomorrow so... leaving this here-->
      <div class="paragraph-1 mt-[20px]">
        <p>
          There are two types of investments — growth assets (eg. shares and property) or income
          assets (eg. bonds and fixed income). Growth assets have the potential for higher returns,
          but also higher losses. On the other end of the scale, income assets typically have lower
          returns, but also lower losses.
        </p>
        <p
          class="mt-[20px]"
          v-if="['high growth', 'growth', 'balanced'].includes(riskRecommendation)">
          Because you're {{ userAge }}, you have time on your side to ride the ups and downs of the
          market. So that's why we're recommending your investments be moved into an option with a
          higher percentage of growth assets. However, as you get closer to retirement, you will
          naturally have less time to ride out these fluctuations. So while we're recommending a
          higher percentage of growth assets now, you should review your investments annually.
        </p>
        <p class="mt-[20px]" v-else>
          At age {{ userAge }}, an investment mix with less volatility is more suitable. That's why
          we're recommending your investment to be moved into an option with less growth assets.
        </p>
        <p class="mt-[20px]">
          You'll also see some {{ textAlternative }} below. While we've stated our recommendation,
          the decision is ultimately up to you.
        </p>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import ArrowRightIcon from '@/components/icons/ArrowRightIcon.vue'
import { computed, ref } from 'vue'

type Props = {
  riskRecommendation: string
  userAge: number
  hasMultipleAlternatives: boolean
}
const props = defineProps<Props>()
const showMore = ref(false)

const textAlternative = computed(
  () => 'alternative investment option' + (props.hasMultipleAlternatives ? 's' : '')
)
</script>

<style scoped></style>
