<template>
  <svg
    width="640"
    height="537"
    viewBox="0 0 640 537"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_2748_26548)">
      <rect x="20" y="10" width="600" height="477" rx="20" fill="white" />
    </g>
    <line
      x1="55"
      y1="446.918"
      x2="575"
      y2="446.918"
      stroke="#EEEBEB"
      stroke-width="30"
      stroke-linecap="round" />
    <line
      x1="55"
      y1="446.918"
      x2="205.663"
      y2="446.918"
      stroke="#CACED3"
      stroke-width="30"
      stroke-linecap="round" />
    <path
      d="M194.978 364.22C258.479 364.22 309.956 312.733 309.956 249.22C309.956 185.707 258.479 134.22 194.978 134.22C131.477 134.22 80 185.707 80 249.22C80 312.733 131.477 364.22 194.978 364.22Z"
      stroke="#FAFAFA"
      stroke-width="70"
      stroke-miterlimit="10" />
    <path
      d="M252.467 149.607C272.593 161.227 288.726 178.674 298.74 199.648C308.753 220.623 312.178 244.14 308.561 267.099C304.945 290.059 294.458 311.384 278.482 328.263C262.506 345.142 241.791 356.783 219.068 361.652C196.345 366.52 172.681 364.388 151.194 355.536C129.707 346.684 111.406 331.527 98.7032 312.064C86.0007 292.601 79.4928 269.744 80.0375 246.508C80.5821 223.271 88.1536 200.745 101.754 181.899"
      stroke="#FAFAFA"
      stroke-width="70"
      stroke-miterlimit="10" />
    <path
      d="M234.922 141.349C253.091 148.09 269.262 159.317 281.932 173.984C294.602 188.651 303.36 206.283 307.391 225.242C311.423 244.201 310.599 263.872 304.994 282.427C299.389 300.982 289.186 317.819 275.333 331.374C261.48 344.928 244.426 354.761 225.756 359.958C207.086 365.155 187.405 365.547 168.543 361.099C149.681 356.65 132.248 347.506 117.866 334.514C103.484 321.523 92.6177 305.106 86.2778 286.789"
      stroke="#EEEBEB"
      stroke-width="70"
      stroke-miterlimit="10" />
    <path
      d="M234.922 141.349C250.289 147.061 264.258 155.994 275.89 167.548C287.522 179.103 296.55 193.012 302.367 208.343C308.183 223.674 310.655 240.071 309.616 256.435C308.576 272.8 304.05 288.753 296.341 303.224C288.631 317.695 277.917 330.35 264.916 340.34C251.916 350.33 236.93 357.423 220.963 361.145C204.997 364.866 188.419 365.129 172.342 361.917C156.266 358.704 141.062 352.09 127.751 342.518"
      stroke="#FAFAFA"
      stroke-width="70"
      stroke-miterlimit="10" />
    <path
      d="M194.978 134.22C220.268 134.21 244.856 142.541 264.934 157.921C285.012 173.302 299.46 194.875 306.04 219.299C312.62 243.723 310.965 269.635 301.332 293.024C291.699 316.412 274.625 335.971 252.754 348.672"
      stroke="#EEEBEB"
      stroke-width="70"
      stroke-miterlimit="10" />
    <line
      x1="395"
      y1="112"
      x2="516"
      y2="112"
      stroke="#EEEBEB"
      stroke-width="30"
      stroke-linecap="round" />
    <line
      x1="55"
      y1="51"
      x2="346"
      y2="51"
      stroke="#EEEBEB"
      stroke-width="30"
      stroke-linecap="round" />
    <line
      x1="395"
      y1="181"
      x2="516"
      y2="181"
      stroke="#FAFAFA"
      stroke-width="30"
      stroke-linecap="round" />
    <line
      x1="395"
      y1="250"
      x2="516"
      y2="250"
      stroke="#EEEBEB"
      stroke-width="30"
      stroke-linecap="round" />
    <line
      x1="154"
      y1="235"
      x2="221"
      y2="235"
      stroke="#EEEBEB"
      stroke-width="30"
      stroke-linecap="round" />
    <line
      x1="154"
      y1="275"
      x2="221"
      y2="275"
      stroke="#FAFAFA"
      stroke-width="30"
      stroke-linecap="round" />
    <line
      x1="395"
      y1="319"
      x2="516"
      y2="319"
      stroke="#FAFAFA"
      stroke-width="30"
      stroke-linecap="round" />
    <line
      x1="395"
      y1="388"
      x2="516"
      y2="388"
      stroke="#EEEBEB"
      stroke-width="30"
      stroke-linecap="round" />
    <defs>
      <filter
        id="filter0_d_2748_26548"
        x="-10"
        y="0"
        width="660"
        height="537"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset dy="20" />
        <feGaussianBlur stdDeviation="15" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2748_26548" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2748_26548"
          result="shape" />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'BasiqExpensesBgImage'
}
</script>

<style scoped></style>
