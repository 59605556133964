<template>
  <svg width="12" height="13" viewBox="0 0 12 13" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.25 9.1875H0.75C0.335625 9.1875 0 8.85188 0 8.4375C0 8.02312 0.335625 7.6875 0.75 7.6875H11.25C11.6644 7.6875 12 8.02312 12 8.4375C12 8.85188 11.6644 9.1875 11.25 9.1875Z"
      fill="currentColor" />
    <path
      d="M8.99813 1.73063H8.44312V1.125C8.44312 0.710625 8.1075 0.375 7.69312 0.375C7.27875 0.375 6.94312 0.710625 6.94312 1.125V1.73063H5.05687V1.125C5.05687 0.710625 4.72125 0.375 4.30687 0.375C3.8925 0.375 3.55687 0.710625 3.55687 1.125V1.73063H3.00187C1.34625 1.73063 0 3.07687 0 4.7325V9.37313C0 11.0288 1.34625 12.375 3.00187 12.375H8.99813C10.6538 12.375 12 11.0288 12 9.37313V4.7325C12 3.07687 10.6538 1.73063 8.99813 1.73063ZM10.5 9.37313C10.5 10.2019 9.82688 10.875 8.99813 10.875H3.00187C2.17313 10.875 1.5 10.2019 1.5 9.37313V4.7325C1.5 3.90375 2.17313 3.23062 3.00187 3.23062H3.55687V4.125C3.55687 4.53937 3.8925 4.875 4.30687 4.875C4.72125 4.875 5.05687 4.53937 5.05687 4.125V3.23062H6.94312V4.125C6.94312 4.53937 7.27875 4.875 7.69312 4.875C8.1075 4.875 8.44312 4.53937 8.44312 4.125V3.23062H8.99813C9.82688 3.23062 10.5 3.90375 10.5 4.7325V9.37313Z"
      fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'CalendarIcon'
}
</script>

<style scoped></style>
