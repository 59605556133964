import { WhitelabelDataType } from '@/types/WhiteLabelTypes.ts'

export const useWhitelabelIdentifier = (whitelabelData: WhitelabelDataType) => {
  const isOtivoWhitelabel = () => whitelabelData.identifier?.toLowerCase().includes('otivo')

  const isCFSWhitelabel = () =>
    whitelabelData?.callcentre_portal &&
    (whitelabelData.identifier?.toUpperCase().includes('COLONIAL_FIRST_STATE') ||
      whitelabelData.frontend_url?.toLowerCase().includes('cfs'))

  return {
    isOtivoWhitelabel,
    isCFSWhitelabel,
  }
}
