<template>
  <BaseView>
    <div
      id="how-otivo-makes-money"
      class="bg-white"
      v-if="pageContent.currentPage === 'howOtivoMakesMoney'">
      <Breadcrumbs class="bg-blue-5" />
      <div class="items-center bg-blue-5 px-5">
        <div class="max-w-[800px] flex flex-col items-center m-auto py-[80px]">
          <h1
            class="w-100% justify-center text-center flex"
            v-html="pageContent.howOtivoMakesMoneyHero.heading" />
          <div
            class="intro-1 justify-center text-center flex mt-[40px]"
            v-html="pageContent.howOtivoMakesMoneyHero.subHeading" />
          <img :src="AlwaysOpen" alt="AlwaysOpen" class="m-auto mt-[40px]" />
          <div v-html="pageContent.howOtivoMakesMoneyHero.body" class="paragraph-1 mt-[40px]" />
        </div>
      </div>
      <OffsetCurve fill="white" class="bg-blue-5 md:-mb-[26px]" />
      <PersonalPricingTiles class="bg-white" :is-pricing-page="false" />
      <ThreeTileDisplay
        class="bg-otivo-blue"
        background-colour="otivo-blue"
        :top-curve-fill="userOnMobile() ? 'white' : undefined"
        :bottom-curve-fill="userOnMobile() ? 'midnight' : undefined"
        text-colour="white"
        :icon-boxes="pageContent.threeTileDisplay.iterableItems" />
    </div>
  </BaseView>
</template>

<script lang="ts" setup>
import BaseView from '@/views/BaseView.vue'
import AlwaysOpen from '@/components/SVGS/AlwaysOpen.svg?url'

import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import OffsetCurve from '@/components/Shapes/OffsetCurve.vue'
import PersonalPricingTiles from '@/components/BrandComponents/Pricing/PersonalPricingTiles.vue'
import ThreeTileDisplay from '@/components/BrandComponents/Otivo/ThreeTileDisplay.vue'
import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { userOnMobile } from '@/composables/userOnMobile.ts'
import { useHead } from '@unhead/vue'

const store = useStore()
onBeforeMount(() => store.dispatch('ContentStore/getContent', 'howOtivoMakesMoney'))
useHead({
  title: 'How Otivo makes money',
})
const pageContent = computed(() => store.getters['ContentStore/pageContent'])
</script>
