<template>
  <svg width="16" height="15" viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 14.1222H2.01C0.9025 14.1222 0 13.2322 0 12.1372V2.10722C0 1.01222 0.9025 0.122223 2.01 0.122223H9.99C11.0975 0.122223 12 1.01222 12 2.10722V8.68472C12 9.23722 11.5525 9.68472 11 9.68472C10.4475 9.68472 10 9.23722 10 8.68472V2.10722L2.01 2.12222L2 12.1372L13 12.1222C13.5525 12.1222 14 12.5697 14 13.1222C14 13.6747 13.5525 14.1222 13 14.1222Z"
      :fill="uniColor ? 'currentColor' : '#0064FF'" />
    <path
      d="M13 14.1222C11.345 14.1222 10 12.7772 10 11.1222V3.62222H14.015C15.11 3.62222 16 4.51222 16 5.60722V11.1222C16 12.7772 14.655 14.1222 13 14.1222ZM12 5.61722V11.1222C12 11.6747 12.4475 12.1222 13 12.1222C13.5525 12.1222 14 11.6747 14 11.1222V5.60722L12 5.61722Z"
      :fill="uniColor ? 'currentColor' : '#0064FF'" />
    <path
      d="M7.75 6.18472H4.25C3.6975 6.18472 3.25 5.73722 3.25 5.18472C3.25 4.63222 3.6975 4.18472 4.25 4.18472H7.75C8.3025 4.18472 8.75 4.63222 8.75 5.18472C8.75 5.73722 8.3025 6.18472 7.75 6.18472Z"
      :fill="uniColor ? 'currentColor' : '#FF0000'" />
    <path
      d="M7.75 9.68472H4.25C3.6975 9.68472 3.25 9.23722 3.25 8.68472C3.25 8.13222 3.6975 7.68472 4.25 7.68472H7.75C8.3025 7.68472 8.75 8.13222 8.75 8.68472C8.75 9.23722 8.3025 9.68472 7.75 9.68472Z"
      :fill="uniColor ? 'currentColor' : '#FF0000'" />
  </svg>
</template>

<script setup lang="ts">
type Props = {
  uniColor?: boolean
}

withDefaults(defineProps<Props>(), {
  uniColor: false
})
</script>
