import { computed, reactive } from 'vue'
import calculateAge from '@/composables/users/calculateAge.ts'
import { state } from '@/components/CFS/IntrafundInvestment/superInvestmentsState.ts'
import { avgAustLifeExpectancy } from '@/lib/helpers'
import { useUserStore } from '@/store/pinia/UserStore.ts'
import { useIntrafundAdviceStore } from '@/store/pinia/IntrafundAdviceStore.ts'

export type RecommendationCopyType = {
  aggressive: string
  conservative: string
  efficient: string
  mysuper: string
  noChange: string
  notEnoughData: string
}

export type RecommendationCopyKey = keyof RecommendationCopyType
export const useIntraRecommendationCopy = () => {
  const userStore = useUserStore()
  const userData = computed(() => userStore.getUser)
  const intrafundAdviceStore = useIntrafundAdviceStore()
  const superWidgetGraphData = computed(() => intrafundAdviceStore.getSuperWidgetGraphData)

  const copies = reactive<RecommendationCopyType>({
    aggressive:
      "[FIRSTNAME], based on your age, we think you should take a more aggressive approach with your investments. That's why we recommend you move to the <span class='text-otivo-red'>[RECOMMENDATION]</span> option.",
    conservative:
      "[FIRSTNAME], based on your age, we think now is the perfect time to take a more conservative approach to your investments. That's why we recommend you move to the <span class='text-otivo-red'>[RECOMMENDATION]</span> option.",
    efficient:
      "[FIRSTNAME], based on your age, we recommend you move to the <span class='text-otivo-red'>[RECOMMENDATION]</span> option.",
    mysuper:
      "[FIRSTNAME] based on your age, we think the <span class='text-otivo-red'>[RECOMMENDATION]</span> is the right choice for you. Given the limited investment choices, there aren't any other investment options that fit your risk category.",
    noChange:
      "[FIRSTNAME], great news! Based on your age, you're exactly where you should be, so we recommend you stick with your current investment options.",
    notEnoughData:
      '[FIRSTNAME], we don’t have enough data to recommend changing your current investment option right now. It’s best to stick with what you have for the moment.',
  })

  const recommendationParser = (
    copyType: RecommendationCopyKey,
    firstName: string,
    investmentName: string,
  ) => {
    const copy = copies[copyType]
    return copy.replace('[FIRSTNAME]', firstName).replace('[RECOMMENDATION]', investmentName)
  }

  const intraRecommendationCopy = computed(() => {
    let copyType: RecommendationCopyKey = 'noChange'
    const recommendedOption = superWidgetGraphData.value?.options.find(
      (item) => item.tag === 'recommended' || item.tag === 'mysuper',
    )

    const currentOption = superWidgetGraphData.value?.options.find((item) => item.tag === 'current')

    if (recommendedOption?.risk_profile_level && currentOption?.risk_profile_level) {
      if (recommendedOption.risk_profile_level > currentOption.risk_profile_level) {
        copyType = 'aggressive'
      } else if (recommendedOption?.risk_profile_level < currentOption?.risk_profile_level) {
        copyType = 'conservative'
      } else if (recommendedOption.risk_profile_level == currentOption.risk_profile_level) {
        copyType = 'efficient'
      } else {
        copyType = 'noChange'
      }
    }
    if (recommendedOption?.tag === 'mysuper') {
      copyType = 'mysuper'
    }
    if (
      superWidgetGraphData.value?.options.length === 1 &&
      superWidgetGraphData.value?.options[0].tag === 'current'
    ) {
      copyType = 'notEnoughData'
    }
    return recommendationParser(
      copyType,
      userData.value.first_name,
      recommendedOption?.investment.name as string,
    )
  })
  const userAge = computed(() => calculateAge(userData.value.dob))
  const intraRecExplainBody = computed(() => {
    const yearsTillSuper = state.preservationAge - userAge.value
    if (yearsTillSuper < 0) {
      return `At age ${userAge.value}, you may have had access to your super for ${Math.abs(
        yearsTillSuper,
      )} years. Taking the average life expectancy into account, your super has ${
        avgAustLifeExpectancy() - userAge.value
      } years to do some heavy lifting. Because of this, we don't ask about your own personal attitude to risk.`
    }
    return `At age ${
      userAge.value
    }, you have another ${yearsTillSuper} years until you could access your super. Taking the average life expectancy into account, your super has ${
      avgAustLifeExpectancy() - userAge.value
    }
  years to do some heavy lifting. Because of this, we don't ask about your own personal attitude to risk.`
  })

  return {
    intraRecommendationCopy,
    intraRecExplainBody,
  }
}
