import { calculateAge } from './helpers'
import { indexOf, isBoolean, some, startsWith } from 'lodash'
import moment from 'moment'
import { getStanfordBrownCompanies, getSurmountCompanies } from './WhiteLabelHelpers'

const dateOfBirthValidation = (dob) => {
  if (dob === '') {
    return { validated: false, message: 'Date must be in dd/mm/yyyy format' }
  }
  const bDateSlashFormat = moment(dob, 'DD/MM/YYYY', true)
  const bDateDashFormat = moment(dob, 'YYYY-MM-DD', true)

  if (!bDateDashFormat.isValid() && !bDateSlashFormat.isValid()) {
    return { validated: false, message: 'Date must be in dd/mm/yyyy format' }
  }

  const age = calculateAge(new Date(dob))
  if (age < 16 || age > 100) {
    return {
      validated: false,
      // DO WE WANT TO CHANGE THIS VALIDATION MESSAGE??
      message: 'Please enter a valid year of birth'
    }
  }

  return { validated: true, message: '' }
}

const validatePhoneNumber = (number) => {
  const allowedAreaCodes = {
    mobile: 614
  }
  let international = false
  if (number[0] === '+') {
    number = number.slice(1)
    international = !isBoolean(number)
  }
  if (startsWith(number, allowedAreaCodes.mobile)) international = true
  return (
    (String(number).length === 11 || (!international && String(number).length === 10)) &&
    some(international ? allowedAreaCodes : { mobile: 0o4 }, (code) => {
      const phoneCode = Number(String(number).slice(0, international ? 3 : 2))
      return phoneCode === code
    })
  )
}

const validatePassword = (password) => {
  const errors = []
  if (password.length < 8) {
    errors.push('Password must be at least 8 characters.')
  }
  if (password.search(/[A-Z]/) < 0) {
    errors.push('Password must contain at least one capital letter.')
  }
  if (password.search(/[a-z]/) < 0) {
    errors.push('Password must contain at least one lowercase letter.')
  }
  if (password.search(/[0-9]/) < 0) {
    errors.push('Password must contain at least one digit.')
  }
  // if (password.search(/(?=.*[!@#$%^&*])/) < 0) {
  //   errors.push('Password must contain at least one special character.')
  // }
  return errors
}

const validateEmailAddress = (email) => {
  // eslint-disable-next-line
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const validateEmailAddressWithDomain = (email, domain) => {
  // eslint-disable-next-line
  const re = new RegExp(
    `^(([^<>()\\[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)|(".+"))@${domain}$`
  )
  return re.test(String(email).toLowerCase())
}

const validSurmountCompany = (companyName) => {
  if (companyName === null || companyName === '') return false
  return (
    indexOf(
      getSurmountCompanies().map((el) => el.value.toLowerCase()),
      companyName.toLowerCase()
    ) !== -1
  )
}

const validStanfordBrownCompany = (companyName) => {
  if (companyName === null || companyName === '') return false
  return (
    indexOf(
      getStanfordBrownCompanies().map((el) => el.value.toLowerCase()),
      companyName.toLowerCase()
    ) !== -1
  )
}

const validChildCompany = (companyName, childCompanyList) => {
  if (companyName === null || companyName === '') return false
  return (
    indexOf(
      childCompanyList.map((el) => el.value.toLowerCase()),
      companyName.toLowerCase()
    ) !== -1
  )
}

export {
  dateOfBirthValidation,
  validatePhoneNumber,
  validatePassword,
  validateEmailAddress,
  validateEmailAddressWithDomain,
  validSurmountCompany,
  validStanfordBrownCompany,
  validChildCompany
}
