import api from '@/services/Api.ts'

export const useRedirectUserToWhitelabel = async (whitelabelId: number) => {
  const res = await api().Get('/v3/whitelabels')
  const whitelabels = res.data as { [key: number]: string }[]
  window.location.assign(`${whitelabels[whitelabelId]}/login`)
}

export const useRedirectAdminToWhitelabel = async (whitelabelId: number) => {
  const res = await api().Get('/v3/whitelabels')
  const whitelabels = res.data as { [key: number]: string }[]

  console.info('[REDIRECT] Redirecting to whitelabel:', whitelabels[whitelabelId])

  // Whitelabel 73 is the CFS Admin portal, be nice if we could change the URL from /cfsadmin to login
  whitelabelId === 73
    ? window.location.assign(`${whitelabels[whitelabelId]}/cfsadmin`)
    : window.location.assign(`${whitelabels[whitelabelId]}/login`)
}
