<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.2755 1.72402C12.7962 2.24472 12.7962 3.08894 12.2755 3.60964L4.79935 11.0858L1.05654 7.34297C0.535841 6.82227 0.535841 5.97805 1.05654 5.45735C1.57724 4.93665 2.42146 4.93665 2.94216 5.45735L4.79935 7.31454L10.3899 1.72402C10.9106 1.20332 11.7548 1.20332 12.2755 1.72402Z" />
  </svg>
</template>
<script lang="ts" setup></script>

<style scoped></style>
