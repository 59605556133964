<template>
  <div>
    <fitness-test-question-label
      :question-number="questionNumber"
      question="Do you have a financial plan?" />

    <input-box-buttons
      @click="setHaveFinancialPlan"
      :buttons="[
        {
          name: 'A comprehensive plan',
          value: 'a_comprehensive_plan',
        },
        {
          name: 'A rough plan',
          value: 'a_rough_plan',
        },
        {
          name: 'Not plan at all',
          value: 'not_plan_at_all',
        },
      ]"
      :selected="haveFinancialPlan"
      name="living-situation"
      label="" />
  </div>
</template>

<script>
import FinancialPlanAnswer from './FinancialPlanAnswer'
import { mapState } from 'vuex'
import FitnessTestQuestionLabel from '../QuestionsLabel/FitnessTestQuestionLabel.vue'

import InputBoxButtons from '@/components/BasicInputs/InputBoxButtons.vue'
export default {
  name: 'HaveFinancialPlan',
  components: { InputBoxButtons, FitnessTestQuestionLabel, FinancialPlanAnswer },
  computed: {
    ...mapState({
      haveFinancialPlan: (state) => state.fitnessTest.questions.haveFinancialPlan,
    }),
  },

  props: {
    questionNumber: {
      type: String,
      default: '',
    },
  },

  methods: {
    setHaveFinancialPlan(value) {
      this.$store.dispatch('setFitnessTestHaveFinancialPlan', value)
    },
  },
}
</script>

<style scoped></style>
