<template>
  <div
    class="mortgageIcon border rounded p-2 flex flex-col"
    @click="$emit('click')"
    @keyup.enter="$emit('click')">
    <svg class="md:h-28" viewBox="-10 -4 36 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 16H9V10.4662H7V16H5V8.45416H11V16Z" :fill="selected ? '#FF0000' : '#CACED3'" />
      <path
        d="M16 16H11V14.0004H14V8.31669L8 2.41549L2 8.31669V13.9879H5V15.9875H0V8.09674C9.37581e-05 7.88798 0.043207 7.68148 0.126651 7.49012C0.210096 7.29876 0.332081 7.12663 0.485 6.98449L6.985 0.400937C7.26017 0.143335 7.62303 0 8 0C8.37697 0 8.73983 0.143335 9.015 0.400937L15.515 6.98199C15.6682 7.12442 15.7904 7.29695 15.8738 7.48877C15.9573 7.68058 16.0002 7.88756 16 8.09674V16Z"
        :fill="selected ? '#0064FF' : '#CACED3'" />
    </svg>

    <div class="flex-1 flex">
      <div class="self-center text-center w-full">I have a mortgage</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MortgageIcon',
  props: {
    selected: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click', 'keyup']
}
</script>

<style scoped></style>
