import { v4 as uuidv4 } from 'uuid'

const SESSION_KEY = 'ask_otivo_super'

export default (): string => {
  let sessionId = localStorage.getItem(SESSION_KEY) as string

  if (!sessionId) {
    sessionId = uuidv4()
    localStorage.setItem(SESSION_KEY, sessionId)
  }

  return sessionId
}
