<template>
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.2754 3.59563C12.3879 1.89821 10.748 0.735772 8.4981 0.230798C6.24822 -0.274177 4.26082 0.0533065 2.7159 1.17575C1.39846 2.1482 0.463509 3.64813 0.0260311 5.49553C-0.00392264 5.62271 -0.0080732 5.75461 0.0138252 5.88343C0.0357235 6.01224 0.0832263 6.13535 0.153525 6.24549C0.299922 6.47121 0.528643 6.63087 0.790992 6.69047L5.74824 7.81541L4.5658 12.8602C4.53641 12.9894 4.533 13.1232 4.55577 13.2538C4.57854 13.3843 4.62705 13.5091 4.69846 13.6207C4.76987 13.7324 4.86277 13.8287 4.97175 13.9041C5.08073 13.9796 5.20361 14.0326 5.33326 14.0601C5.40876 14.0769 5.4859 14.0853 5.56325 14.0851C5.79282 14.0873 6.01643 14.012 6.19789 13.8714C6.37936 13.7307 6.50807 13.533 6.5632 13.3101L7.74814 8.26539L12.5379 9.34534C12.6141 9.3631 12.6921 9.37232 12.7704 9.37284C13.0003 9.37492 13.2242 9.29926 13.4057 9.15814C13.5872 9.01701 13.7157 8.8187 13.7703 8.59538C14.1953 6.79547 14.0203 5.02056 13.2754 3.59563ZM5.93823 5.80302C6.21822 4.99056 6.79069 3.67312 7.76814 2.76817C8.09037 3.92853 8.25847 5.12626 8.26812 6.33049L5.93823 5.80302ZM3.93833 2.78067C4.44729 2.41705 5.0333 2.17581 5.65075 2.0757C4.88657 3.05706 4.30177 4.16571 3.92333 5.35054L2.30842 4.98806C2.63425 4.11141 3.20309 3.34573 3.94833 2.78067H3.93833ZM11.9279 7.14795L10.318 6.78297C10.3411 5.47667 10.2068 4.17234 9.91803 2.89816C11.7904 4.03061 12.0229 5.89801 11.9279 7.14795Z"
      :class="`fill-${fill2}`" />
    <path
      d="M12.7255 15.9998C12.5592 15.9997 12.3954 15.9594 12.2481 15.8823C12.1906 15.8523 6.32587 12.8974 1.84109 15.8298C1.61479 15.9779 1.33968 16.0321 1.07412 15.9807C0.808555 15.9294 0.573444 15.7766 0.418664 15.5548C0.345951 15.4452 0.295573 15.3223 0.270416 15.1932C0.245258 15.0642 0.245816 14.9314 0.272056 14.8025C0.298297 14.6736 0.349705 14.5512 0.423335 14.4422C0.496966 14.3332 0.591373 14.2398 0.70115 14.1674C6.21587 10.5651 12.9255 13.9699 13.2005 14.1174C13.3187 14.1767 13.4237 14.2592 13.5093 14.36C13.5949 14.4608 13.6593 14.5777 13.6988 14.7039C13.7382 14.8301 13.7518 14.963 13.7388 15.0946C13.7258 15.2262 13.6864 15.3538 13.623 15.4698C13.5345 15.6303 13.4045 15.7642 13.2467 15.8574C13.0888 15.9506 12.9089 15.9998 12.7255 15.9998Z"
      :class="`fill-${fill1}`" />
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1?: string
  fill2?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-blue'
})
</script>
