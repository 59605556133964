<template>
  <svg width="80" height="80" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.6875 1.1075C15.6863 1.05967 15.6813 1.01201 15.6725 0.964996C15.6738 0.93918 15.6738 0.913312 15.6725 0.887496C15.6725 0.849996 15.65 0.814996 15.6375 0.779996C15.625 0.744996 15.62 0.712496 15.6075 0.679996C15.595 0.647496 15.59 0.649996 15.5825 0.632496C15.5572 0.58701 15.5296 0.54279 15.5 0.499996L15.46 0.449996C15.4275 0.409996 15.395 0.367496 15.36 0.329996C15.3418 0.312062 15.3216 0.296137 15.3 0.282496C15.2667 0.252066 15.2317 0.223681 15.195 0.197496L15.1025 0.147496L15.0025 0.0974959C14.9675 0.0824959 14.93 0.0749961 14.8925 0.0624961L14.795 0.0349959C14.7484 0.0325241 14.7016 0.0325241 14.655 0.0349959H14.585H10.1075C9.95646 0.0278464 9.80555 0.0514208 9.66389 0.104292C9.52224 0.157163 9.39279 0.238231 9.28337 0.342588C9.17396 0.446945 9.08686 0.57242 9.02735 0.711418C8.96784 0.850416 8.93715 1.00004 8.93715 1.15125C8.93715 1.30245 8.96784 1.45208 9.02735 1.59107C9.08686 1.73007 9.17396 1.85555 9.28337 1.9599C9.39279 2.06426 9.52224 2.14533 9.66389 2.1982C9.80555 2.25107 9.95646 2.27465 10.1075 2.2675H11.9375L8.56999 5.71L6.38249 3.565C6.27574 3.46124 6.14929 3.37992 6.01061 3.32582C5.87192 3.27172 5.7238 3.24594 5.57499 3.25C5.42556 3.25276 5.27825 3.28587 5.14202 3.34733C5.00578 3.40879 4.88346 3.4973 4.7825 3.6075L0.297495 8.4475C0.112324 8.66716 0.0188649 8.94969 0.036523 9.23645C0.054181 9.5232 0.181599 9.79213 0.392322 9.98741C0.603045 10.1827 0.880883 10.2893 1.16815 10.2851C1.45541 10.281 1.73004 10.1663 1.93499 9.965L5.64 5.965L7.81 8.095C8.0228 8.30146 8.30851 8.41556 8.60499 8.4125C8.75232 8.41132 8.89791 8.38062 9.03317 8.32222C9.16843 8.26382 9.29061 8.17891 9.3925 8.0725L13.455 3.875V5.5825C13.4686 5.86941 13.5921 6.14007 13.8 6.33831C14.0078 6.53656 14.284 6.64715 14.5712 6.64715C14.8585 6.64715 15.1347 6.53656 15.3425 6.33831C15.5504 6.14007 15.6739 5.86941 15.6875 5.5825V1.1075Z"
      :fill="fill1" />
    <path
      d="M15.07 14.14H14.25V9.33999C14.2565 9.21364 14.2373 9.08727 14.1935 8.96859C14.1496 8.84991 14.0821 8.74139 13.995 8.64964C13.9079 8.55789 13.803 8.48482 13.6867 8.4349C13.5705 8.38497 13.4453 8.35922 13.3188 8.35922C13.1922 8.35922 13.067 8.38497 12.9508 8.4349C12.8345 8.48482 12.7296 8.55789 12.6425 8.64964C12.5554 8.74139 12.4879 8.84991 12.444 8.96859C12.4002 9.08727 12.381 9.21364 12.3875 9.33999V14.14H10.7125V11.5825C10.7125 11.3358 10.6145 11.0993 10.4401 10.9249C10.2657 10.7505 10.0292 10.6525 9.7825 10.6525C9.53585 10.6525 9.2993 10.7505 9.12489 10.9249C8.95048 11.0993 8.8525 11.3358 8.8525 11.5825V14.14H7.25V10.335C7.25 10.2129 7.22594 10.0919 7.17921 9.97909C7.13247 9.86626 7.06397 9.76374 6.97761 9.67738C6.89125 9.59102 6.78873 9.52252 6.6759 9.47578C6.56306 9.42904 6.44213 9.40499 6.32 9.40499C6.19787 9.40499 6.07694 9.42904 5.9641 9.47578C5.85127 9.52252 5.74875 9.59102 5.66239 9.67738C5.57603 9.76374 5.50753 9.86626 5.46079 9.97909C5.41405 10.0919 5.39 10.2129 5.39 10.335V14.14H3.8075V11.64C3.8075 11.3933 3.70952 11.1568 3.53511 10.9824C3.3607 10.808 3.12415 10.71 2.8775 10.71C2.63085 10.71 2.3943 10.808 2.21989 10.9824C2.04548 11.1568 1.9475 11.3933 1.9475 11.64V14.14H1.1175C0.870848 14.14 0.6343 14.238 0.459891 14.4124C0.285482 14.5868 0.1875 14.8233 0.1875 15.07C0.1875 15.3166 0.285482 15.5532 0.459891 15.7276C0.6343 15.902 0.870848 16 1.1175 16H15.07C15.3167 16 15.5532 15.902 15.7276 15.7276C15.902 15.5532 16 15.3166 16 15.07C16 14.8233 15.902 14.5868 15.7276 14.4124C15.5532 14.238 15.3167 14.14 15.07 14.14Z"
      :fill="fill2" />
  </svg>
</template>

<script>
export default {
  name: 'SharesTile',
  props: {
    fill: { type: String, default: '#70D6FF' },
    fill1: { type: String, default: '#70D6FF' },
    fill2: { type: String, default: '#70D6FF' }
  }
}
</script>

<style scoped></style>
