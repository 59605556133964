<template>
  <Transition name="fade">
    <div
      v-if="showModal"
      id="modalMask"
      class="fixed top-0 left-0 w-100% h-100% bg-[#00000080] z-100" />
  </Transition>
  <Transition name="bounce">
    <div
      v-if="showModal"
      class="fixed top-0 left-0 w-100% h-100% px-[6px] md:px-0 md:pt-[100px] md:pb-[100px] z-100"
      @click="close">
      <component
        v-if="override"
        @click.stop=""
        :is="modalStore.component"
        v-bind="modalStore.props" />
      <dialog
        v-else
        id="modalContainer"
        ref="modalContainer"
        :style="styleOverride"
        :class="size"
        class="relative bg-grey-5 w-100% max-h-100% overflow-auto overscroll-contain otivo-drop-shadow"
        open
        @click.stop>
        <button
          data-test="closeModalBtn"
          v-if="modalStore.isCloseable"
          aria-label="Close"
          class="absolute top-[12px] md:top-[16px] right-[12px] md:right-[16px] z-100 cursor-pointer"
          type="button"
          id="closeModalBtn"
          @click.prevent="close">
          <CloseXIcon :style="white ? 'color: white;' : 'color: #a0a0a0;'" />
        </button>
        <component :is="modalStore.component" v-bind="modalStore.props" />
      </dialog>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { onKeyDown } from '@vueuse/core'
import { useModalStore } from '@/store/pinia/ModalStore'
import CloseXIcon from '@/components/icons/CloseXIcon.vue'

const modalStore = useModalStore()
const showModal = computed(() => modalStore.isOpen)
const size = computed(() => modalStore.size)
const override = computed(() => modalStore.override)
const styleOverride = computed(() => modalStore.styleOverride)
const white = computed(() => modalStore.white)

const body = ref<HTMLElement | null>()
const modalContainer = ref<HTMLElement | null>(null)
const firstFocusableElement = ref<HTMLElement | null>(null)
const lastFocusableElement = ref<HTMLElement | null>(null)

onMounted(() => {
  body.value = document.getElementById('body')
})

onKeyDown('Escape', () => {
  if (modalStore.isOpen) {
    close()
  }
})

watch(
  () => modalStore.isOpen,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      if (!body.value) return
      if (newValue) {
        // The modal is now visible, prevent background from scrolling
        body.value.style.overflowY = 'hidden'

        // Focus the last focusable element inside the modal
        nextTick(() => {
          if (modalContainer.value) {
            const focusableElements = modalContainer.value.querySelectorAll(
              'a[href], button, textarea, input, select',
            ) as NodeListOf<HTMLElement>

            if (focusableElements.length > 0) {
              firstFocusableElement.value = focusableElements[0]
              lastFocusableElement.value = focusableElements[focusableElements.length - 1]
              firstFocusableElement.value.focus()
            }
          }
        })

        window.addEventListener('keydown', trapFocus)
      } else {
        // The modal is now hidden, allow background scrolling again
        body.value.style.overflowY = 'auto'
        window.removeEventListener('keydown', trapFocus)
      }
    }
  },
)

const close = () => {
  if (modalStore.isCloseable) {
    modalStore.closeModal()
  }
}

const trapFocus = (event: KeyboardEvent) => {
  if (
    event.key === 'Tab' &&
    modalContainer.value &&
    firstFocusableElement.value &&
    lastFocusableElement.value
  ) {
    if (event.shiftKey) {
      // If the Shift key is pressed along with Tab, focus the last focusable element inside the modal
      if (document.activeElement === firstFocusableElement.value) {
        event.preventDefault()
        ;(lastFocusableElement.value as HTMLElement).focus()
      }
    } else {
      // If only Tab is pressed, focus the first focusable element inside the modal
      if (document.activeElement === lastFocusableElement.value) {
        event.preventDefault()
        ;(firstFocusableElement.value as HTMLElement).focus()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.small {
  max-width: 335px;
  padding: 32px 16px 16px;
  border-radius: 10px;
}

.medium {
  max-width: 600px;
  padding: 36px 40px 40px;
  border-radius: 20px;

  @media (max-width: 768px) {
    padding: 20px;
  }
}

.large {
  max-width: 800px;
  padding: 36px 40px 40px;
  border-radius: 20px;

  @media (max-width: 768px) {
    padding: 20px;
  }
}

.extra-large {
  max-width: 1000px;
  padding: 36px 40px 40px;
  border-radius: 20px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#modalContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#modalContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
