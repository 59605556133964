<template>
  <div>
    <div class="relative rounded-md shadow-sm">
      <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <span v-if="type === 'currency'" class="text-gray-500 sm:text-sm">$</span>
      </div>
      <input
        :id="name"
        :key="update"
        ref="inputElement"
        class="py-[10px] pr-[15px] border-1px rounded-[5px] text-button-3 w-full"
        :class="[
          type === 'currency' || type === 'percent' ? 'pl-5' : 'pl-3',
          disabled ? 'bg-grey-4 cursor-not-allowed' : 'bg-white',
          computedErrorMessage
            ? 'border-red-dark text-red-dark enabled:hover:otivo-outline-error enabled:focus:otivo-outline-error enabled:active:otivo-outline-error'
            : 'border-grey-field enabled:hover:otivo-outline enabled:focus:otivo-outline enabled:active:otivo-outline',
        ]"
        :disabled="disabled"
        :inputmode="inputMode"
        :maxlength="type === 'phone' ? phoneMaxLength : maxLength"
        :name="name"
        :placeholder="placeholder"
        :value="formattedValue"
        type="text"
        @input="(event: Event) => handleInput((event.target as HTMLInputElement).value)"
        @blur="() => (update += 1)"
        @keydown="handleKeydown" />
      <button v-if="clearable" class="absolute w-8 h-8 mt-2 -ml-8 text-lg z-40" @click="clearInput">
        <CloseXIcon />
      </button>
      <!--      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">-->
      <!--        <span v-if="type === 'currency'" class="text-gray-500 sm:text-sm" id="price-currency"-->
      <!--          >AUD</span-->
      <!--        >-->
      <!--      </div>-->
      <p v-if="computedErrorMessage" class="otivo-error-message absolute right-0">
        {{ computedErrorMessage }}
      </p>
    </div>
    <p v-if="softError" class="otivo-error-message">
      {{ softError }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import formatAsPercent from '@/composables/formatAsPercent'
import formatAsCurrency from '@/composables/formatAsCurrency'
import formatAsNumber from '@/composables/formatAsNumber'
import formatAsPhoneNumber from '@/composables/formatAsPhoneNumber.ts'
import CloseXIcon from '@/components/icons/CloseXIcon.vue'

export type BaseInputProps = {
  value: string | number | null | undefined
  name: string
  type?: 'text' | 'number' | 'currency' | 'percent' | 'email' | 'phone'
  validation?: 'alpha-numeric' | 'alpha' | 'numeric' | null
  placeholder?: string | null
  step?: number
  min?: number
  max?: number
  clearable?: boolean
  maxLength?: number
  enforceMinMax?: boolean
  errorMessage?: string
  softError?: string
  disabled?: boolean
}

const props = withDefaults(defineProps<BaseInputProps>(), {
  type: 'text',
  placeholder: '',
  step: 1,
  min: 0,
  clearable: false,
  max: Number.MAX_SAFE_INTEGER,
  maxLength: 100,
  enforceMinMax: false,
  errorMessage: '',
  softError: '',
  validation: null,
})
const emits = defineEmits<{
  (e: 'update:value', val: string | number): void
  (e: 'keyPress', val: string | number): void
  (e: 'cleared'): void
}>()

const update = ref(0)

const inputMode = computed(() => {
  if (props.type === 'number' || props.type === 'currency' || props.type === 'percent') {
    return 'decimal'
  }
  if (props.type === 'email') return 'email'
  if (props.type === 'phone') return 'tel'
  return 'text'
})
const propTypeIsText = computed(() => props.type === 'text' || props.type === 'email')
const phoneMaxLength = ref(12)
const formattedValue = ref('')
const localError = ref('')
const computedErrorMessage = computed(() => props.errorMessage || localError.value)

onMounted(() => {
  if (!props.value) return
  switch (props.type) {
    case 'number':
      formattedValue.value = formatAsNumber(props.value)
      break
    case 'currency':
      // formattedValue.value = formatAsCurrency(props.value)
      formattedValue.value = props.value.toLocaleString()
      break
    case 'percent':
      formattedValue.value = formatAsPercent(props.value)
      break
    case 'phone':
      formattedValue.value = formatAsPhoneNumber(props.value)
      break
    default:
      formattedValue.value = props.value.toString()
  }
})
watch(
  () => props.value,
  (newValue) => {
    if (newValue === undefined || newValue === null) return
    if (newValue.toString().length === props.maxLength)
      localError.value = `Max length of ${props.maxLength} reached`
    else localError.value = ''
    formatInputValue(newValue)
  },
)

const formatInputValue = (value: string | number) => {
  switch (props.type) {
    case 'number':
      formattedValue.value = formatAsNumber(value)
      break
    case 'currency':
      // formattedValue.value = formatAsCurrency(value)
      formattedValue.value = value.toLocaleString()
      break
    case 'percent':
      formattedValue.value = formatAsPercent(value)
      break
    case 'phone':
      formattedValue.value = formatAsPhoneNumber(value)
      break
    default:
      if (props.validation) {
        switch (props.validation) {
          case 'alpha':
            if (/[^a-zA-Z]/g.test(value.toString())) {
              localError.value = 'Only letters are allowed'
            }
            break
          case 'alpha-numeric':
            if (/[^a-zA-Z0-9]/g.test(value.toString())) {
              localError.value = 'Only letters and numbers are allowed'
            }
            break
          case 'numeric':
            if (/[^0-9]/g.test(value.toString())) {
              localError.value = 'Only numbers are allowed'
            }
            break
        }
      }
      formattedValue.value = value.toString()
  }
}

const handleInput = (text: string) => {
  if (propTypeIsText.value) {
    formattedValue.value = text
    emits('update:value', text)
    return
  }

  if (props.type === 'phone') {
    handlePhoneInput(text)
    positionCursor(text)
    return
  }

  // TODO: Add error message if user types a value greater than the max or less than the min?
  const nonDigitsStripped = text.replace(/[^\d.]/g, '')
  let number = nonDigitsStripped ? parseFloat(nonDigitsStripped) : 0
  if (props.enforceMinMax) number = handleNumberMinMax(number)

  if (props.type === 'number') {
    formattedValue.value = formatAsNumber(number)
  }

  if (props.type === 'currency') {
    // formattedValue.value = formatAsCurrency(number)
    formattedValue.value = number.toLocaleString()
  }
  if (props.type === 'percent') {
    if (number > 0) {
      const numberAsString = (parseFloat(nonDigitsStripped) / 100).toFixed(4)
      number = parseFloat(numberAsString)
    }
    formattedValue.value = formatAsPercent(number)
    emits('update:value', number)
    return
  }
  emits('update:value', number)

  positionCursor(text)
}

const inputElement = ref<HTMLInputElement | null>(null)
const handleNumberMinMax = (val: string | number) => {
  let number
  if (typeof val === 'string') {
    number = parseFloat(val)
  } else {
    number = val
  }
  return Math.min(Math.max(number, props.min), props.max)
}

// used to prevent the cursor from jumping to the end of the input
const positionCursor = (text: string) => {
  let cursorPosition: number | null
  if (inputElement.value) cursorPosition = inputElement.value.selectionStart

  nextTick(() => {
    if (inputElement.value && cursorPosition) {
      const newCursorPosition = cursorPosition - (text.length - formattedValue.value.length)
      inputElement.value.setSelectionRange(newCursorPosition, newCursorPosition)
    }
  })
}

const handlePhoneInput = (text: string): void => {
  const firstChar = text.charAt(0)
  const validFirstChar = firstChar === '+' || firstChar === '6'
  phoneMaxLength.value = validFirstChar ? 15 : 12
  formattedValue.value = formatAsPhoneNumber(text)
  const phoneNumberWithoutSpaces = formattedValue.value.replace(/ /g, '')

  emits('update:value', phoneNumberWithoutSpaces)
}

const handleKeydown = (event: KeyboardEvent) => {
  if (propTypeIsText.value) return

  if (event.key === 'ArrowUp') {
    let incrementedNumber = incrementNumber(formattedValue.value)

    if (props.type === 'number') formattedValue.value = formatAsNumber(incrementedNumber)
    if (props.type === 'currency') formattedValue.value = formatAsCurrency(incrementedNumber)
    if (props.type === 'percent') {
      formattedValue.value = formatAsPercent(incrementedNumber)
      if (incrementedNumber > 0) {
        incrementedNumber = roundTwoDecimalPlaces(incrementedNumber)
      }
    }

    emits('keyPress', incrementedNumber)
    emits('update:value', incrementedNumber)
  }

  if (event.key === 'ArrowDown') {
    let decrementedNumber = decrementNumber(formattedValue.value)

    if (props.type === 'number') formattedValue.value = formatAsNumber(decrementedNumber)
    if (props.type === 'currency') formattedValue.value = formatAsCurrency(decrementedNumber)
    if (props.type === 'percent') {
      formattedValue.value = formatAsPercent(decrementedNumber)
      if (decrementedNumber > 0) {
        decrementedNumber = roundTwoDecimalPlaces(decrementedNumber)
      }
    }

    emits('keyPress', decrementedNumber)
    emits('update:value', decrementedNumber)
  }

  preventNonNumericCharacters(event)
}

function preventNonNumericCharacters(event: KeyboardEvent) {
  const digitRegExp = new RegExp('\\d|\\.')
  const phoneRegExp = new RegExp('\\+|\\d')
  /*
   * Inspiration: https://codepen.io/hsucherng/pen/MzrxMM
   */
  if (event.ctrlKey || event.altKey || event.key.length !== 1) {
    return
  }

  if (props.type === 'phone') {
    if (!phoneRegExp.test(event.key)) {
      event.preventDefault()
    }
    return
  }

  if (!digitRegExp.test(event.key)) {
    event.preventDefault()
  }
}

const roundTwoDecimalPlaces = (numberToRound: number): number => {
  const rounded = numberToRound.toFixed(4)
  return parseFloat(rounded)
}

const incrementNumber = (num: string): number => {
  const removeCommas = num.replace(/[$,%]/g, '')
  const number = parseFloat(removeCommas)
  let incrementedNumber: number

  if (props.type === 'percent') {
    incrementedNumber = number / 100 + 0.01

    if (incrementedNumber > 1) incrementedNumber = 1
  } else {
    incrementedNumber = number + props.step

    if (incrementedNumber > props.max) incrementedNumber = props.max
  }

  return incrementedNumber
}

const decrementNumber = (num: string): number => {
  const removeCommas = num.replace(/[$,%]/g, '')
  const number = parseFloat(removeCommas)
  let decrementedNumber: number

  if (props.type === 'percent') {
    decrementedNumber = number / 100 - 0.01

    if (decrementedNumber < 0) decrementedNumber = 0
  } else {
    decrementedNumber = number - props.step

    if (decrementedNumber < props.min) decrementedNumber = props.min
  }

  return decrementedNumber
}

const clearInput = () => {
  formattedValue.value = ''
  emits('update:value', '')
  emits('cleared')
}
</script>

<style scoped>
input[type='text']::placeholder {
  color: var(--grey-3);
}
</style>
