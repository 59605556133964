<template>
  <div @click="tooltipClickRootFilter($event)" @mouseleave="mouseLeave($event)">
    <loader v-if="loading" />
    <high-charts
      v-else
      id="retIncomeChart"
      ref="retIncomeChart"
      :options="getOptions"
      :style="computedChartHeight" />
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'
import Loader from '@/components/Loader/Loader.vue'
import { getRootColor } from '@/lib/helpers'
import { mapGetters } from 'vuex'
import { incomeChartTooltip, significantFigureRound } from '@/lib/RetirementCalculatorHelper'

exportingInit(Highcharts)

export default {
  name: 'RetCalcIncomeChart',

  components: {
    Loader,
    'high-charts': Chart,
  },
  props: {
    incomeChartData: {
      type: Object,
      required: true,
    },
    showPartnerData: {
      type: Boolean,
      default: false,
    },
    userData: {
      type: Object,
      required: true,
    },
    partnerData: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      superExpirationDate: null,
      partnerSuperExpirationDate: null,
      numLegends: null,
    }
  },

  computed: {
    ...mapGetters('RetirementCalculator', ['enablePartnerForm']),
    computedChartHeight() {
      if (window.innerWidth > 600) {
        return null
      }
      return 'height: ' + (300 + this.numLegends * 50) + 'px'
    },
    getOptions() {
      return {
        chart: {
          type: 'column',
          style: {
            fontFamily: 'Raleway',
          },
          backgroundColor: null,
        },

        credits: {
          enabled: false,
        },

        exporting: {
          enabled: false,
        },

        title: {
          text: null,
          verticalAlign: 'bottom',
        },

        subtitle: {
          text: '',
        },

        yAxis: {
          title: {
            text: 'After tax income',
            style: {
              color: '#164A9A',
            },
          },
          plotLines: [
            {
              color: '#00885E',
              width: 1,
              value: Math.floor(this.userData.desired_retirement_income),
              zIndex: 5,
            },
          ],
        },
        xAxis: {
          title: {
            text: 'Your age',
            style: {
              color: '#164A9A',
            },
          },
          type: 'datetime',
          categories: this.getXAxisLabels(),
          plotLines: this.getPlotLines,
          labels: {
            enabled: true,
          },
        },

        tooltip: {
          useHTML: true,
          borderWidth: 0,
          padding: 0,
          borderRadius: 30,
          outside: true,
          formatter: function () {
            return this.point.tooltip
          },
        },

        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle',
          // reversed: true
        },

        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false,
            },
            events: {
              // try this, show on specifics, disable on overall
              mouseOut: () => {
                const chart = this.$refs.retIncomeChart.chart
                if (chart.tooltip.label) {
                  const tooltip = chart.tooltip.label.element
                  const tooltipHeading = document.getElementById('html-tooltip-heading')
                  const tooltipInfo = document.getElementById('html-tooltip-info')
                  if (tooltip.classList.contains('opacity-0')) tooltip.classList.remove('opacity-0')
                  if (tooltipHeading.classList.contains('opacity-0'))
                    tooltip.classList.remove('opacity-0')
                  if (tooltipInfo.classList.contains('opacity-0'))
                    tooltip.classList.remove('opacity-0')
                }
              },
              legendItemClick: (e) => {
                // get style from selected Legend Button
                const style = e.target.legendItem.styles
                const newStyle = { ...style }

                const activeObj = {
                  border: '#164A9A solid',
                  backgroundColor: '#E5ECF9',
                  boxShadow: '0 0 0 2px #EEEBEB',
                  color: '#333',
                }
                const inactiveObj = {
                  border: '#BEBEBE solid',
                  backgroundColor: 'white',
                  boxShadow: '0 0 0 2px #FFF',
                  color: '#333',
                }
                const isActive = style.backgroundColor === '#E5ECF9'
                const updateStyleObject = isActive ? inactiveObj : activeObj
                const compareKeys = Object.keys(updateStyleObject)

                compareKeys.forEach((key) => {
                  newStyle[key] = updateStyleObject[key]
                })
                // set new style to Legend Button
                e.target.legendItem.css(newStyle)
              },
            },
          },
        },

        series: this.getSeriesData,

        responsive: {
          rules: [
            {
              condition: {
                // maxWidth: 500
              },
              chartOptions: {
                legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                  className: 'b2 legendFlex',
                  symbolPadding: 0,
                  symbolWidth: 0,
                  symbolHeight: 0,
                  squareSymbol: false,
                  useHTML: true,
                  itemMarginTop: 10,
                  itemDistance: 40,
                  maxHeight: '2000px', // set super high to make sure it doesn't paginate
                  itemStyle: {
                    border: '#164A9A solid',
                    backgroundColor: '#E5ECF9',
                    borderRadius: '6px',
                    boxShadow: '0 0 0 2px #EEEBEB',
                    color: '#333',
                  },
                  itemHiddenStyle: {
                    border: '#BEBEBE solid',
                    backgroundColor: 'white',
                    borderRadius: '6px',
                    boxShadow: '0 0 0 2px #FFF',
                    color: '#333',
                  },
                },
              },
            },
          ],
        },
      }
    },

    getPlotLines() {
      // plot lines
      let userYPoint = 0
      let partnerYPoint = 0
      let expUserYPoint = 0
      let expPartnerYPoint = 0
      const graphYears = Object.keys(this.incomeChartData)
      const lastGraphYear = parseInt(graphYears[graphYears.length - 1])
      if (this.enablePartnerForm) {
        const userRetirementYear = this.userData.desired_retirement_age - this.userData.age
        const partnerRetirementYear = this.partnerData.desired_retirement_age - this.partnerData.age
        if (
          partnerRetirementYear < userRetirementYear + 7 &&
          partnerRetirementYear > userRetirementYear - 8
        ) {
          userYPoint = userRetirementYear <= partnerRetirementYear ? -1 : 15
          partnerYPoint = userRetirementYear <= partnerRetirementYear ? 15 : -1
        }
        if (this.superExpirationDate && this.partnerSuperExpirationDate) {
          const userExpYear = this.superExpirationDate
          const partnerExpYear = this.partnerSuperExpirationDate
          if (partnerExpYear < userExpYear + 7 && partnerExpYear > userExpYear - 10) {
            expUserYPoint = userExpYear <= partnerExpYear ? -1 : 15
            expPartnerYPoint = userExpYear <= partnerExpYear ? 15 : -1
          } else if (
            partnerExpYear < userRetirementYear + 7 &&
            partnerExpYear > userRetirementYear - 10
          ) {
            partnerYPoint = userRetirementYear <= partnerExpYear ? -1 : 15
            expPartnerYPoint = userExpYear <= partnerExpYear ? 15 : -1
          }
        }
      }

      const plotLines = []
      if (this.userData.desired_retirement_age > 0) {
        plotLines.push({
          color: getRootColor('grey-2'),
          label: {
            text: 'You retire',
            rotation: 0,
            style: {
              color: '#164A9A',
              fontWeight: 'bold',
            },
            // y: userYPoint
            y: 0,
          },
          width: 2,
          zIndex: 5,
          value: this.getPlotLineValueByRetirementAge(
            this.userData.age,
            this.userData.desired_retirement_age,
          ),
        })
      }

      if (
        this.enablePartnerForm &&
        this.partnerData.age > 0 &&
        this.partnerData.desired_retirement_age
      ) {
        const partnerRetireYearDate = new Date()
        partnerRetireYearDate.setFullYear(
          partnerRetireYearDate.getFullYear() +
            this.partnerData.desired_retirement_age -
            this.partnerData.age,
        )

        plotLines.push({
          color: getRootColor('grey-2'),
          label: {
            text: 'Partner retires',
            align: partnerRetireYearDate.getFullYear() >= lastGraphYear - 10 ? 'right' : 'left',
            style: {
              color: '#164A9A',
              fontWeight: 'bold',
            },
            rotation: 0,
            // y: partnerYPoint
            x: partnerRetireYearDate.getFullYear() >= lastGraphYear - 10 ? -10 : 10,
            y: 30,
          },
          width: 2,
          zIndex: 5,
          value: this.getPlotLineValueByRetirementAge(
            this.partnerData.age,
            this.partnerData.desired_retirement_age,
          ),
        })
      }

      if (this.superExpirationDate !== null) {
        const superExpirationYearDate = new Date()
        superExpirationYearDate.setFullYear(
          superExpirationYearDate.getFullYear() + this.superExpirationDate,
        )
        plotLines.push({
          color: getRootColor('grey-2'),
          label: {
            text: 'Super ends',
            align: superExpirationYearDate.getFullYear() >= lastGraphYear - 6 ? 'right' : 'left',
            style: {
              color: '#164A9A',
              fontWeight: 'bold',
            },
            rotation: 0,
            // y: expUserYPoint
            x: superExpirationYearDate.getFullYear() >= lastGraphYear - 6 ? -10 : 10,
            y: 15,
          },
          width: 2,
          zIndex: 5,
          value: this.superExpirationDate,
        })
      }
      if (this.enablePartnerForm && this.partnerSuperExpirationDate !== null) {
        const partnerSuperExpirationYearDate = new Date()
        partnerSuperExpirationYearDate.setFullYear(
          partnerSuperExpirationYearDate.getFullYear() + this.partnerSuperExpirationDate,
        )
        plotLines.push({
          color: getRootColor('grey-2'),
          label: {
            text: "Partner's super ends",
            rotation: 0,
            align:
              partnerSuperExpirationYearDate.getFullYear() >= lastGraphYear - 10 ? 'right' : 'left',
            style: {
              color: '#164A9A',
              fontWeight: 'bold',
            },
            // y: expPartnerYPoint
            x: partnerSuperExpirationYearDate.getFullYear() >= lastGraphYear - 10 ? -10 : 10,
            y: 45,
          },
          width: 2,
          zIndex: 5,
          value: this.partnerSuperExpirationDate,
        })
      }
      return plotLines
    },

    getSeriesData() {
      const alternativeProjection = []
      const cashAndFixedProjection = []
      const managedFundsProjection = []
      const pensionProjection = []
      const propertyProjection = []
      const salaryProjection = []
      const partnerSalaryProjection = []
      const sharesProjection = []
      const superProjection = []
      const partnerSuperProjection = []
      const partnerPensionProjection = []
      this.refreshExpirationDates()
      let superHasBegun = false
      let partnerSuperHasBegun = false
      Object.keys(this.incomeChartData).forEach((year) => {
        alternativeProjection.push({ income: this.incomeChartData[year].alternative, year: year })
        cashAndFixedProjection.push({ income: this.incomeChartData[year].cashAndFixed, year: year })
        managedFundsProjection.push({ income: this.incomeChartData[year].managedFunds, year: year })
        pensionProjection.push({ income: this.incomeChartData[year].pensionSF, year: year })
        propertyProjection.push({ income: this.incomeChartData[year].property, year: year })
        salaryProjection.push({ income: this.incomeChartData[year].salary, year: year })
        sharesProjection.push({ income: this.incomeChartData[year].shares, year: year })
        superProjection.push({ income: this.incomeChartData[year].superSF, year: year })
        partnerSalaryProjection.push({
          income: this.incomeChartData[year].partnerSalary,
          year: year,
        })
        partnerSuperProjection.push({
          income: this.incomeChartData[year].partnerSuperSF,
          year: year,
        })
        partnerPensionProjection.push({
          income: this.incomeChartData[year].partnerPensionSF,
          year: year,
        })

        if (
          this.superExpirationDate === null &&
          this.checkForCareerBreak(this.userData.age, this.userData.career_breaks, year) &&
          superHasBegun &&
          this.incomeChartData[year].super === 0
        ) {
          const date = new Date()
          date.setFullYear(year - 1 - date.getFullYear())
          this.superExpirationDate = date.getFullYear()
        }
        if (this.incomeChartData[year].super > 0) superHasBegun = true
        if (
          this.enablePartnerForm &&
          this.partnerSuperExpirationDate === null &&
          this.checkForCareerBreak(this.partnerData.age, this.partnerData.career_breaks, year) &&
          partnerSuperHasBegun &&
          this.incomeChartData[year].partnerSuper === 0
        ) {
          const date = new Date()
          date.setFullYear(year - 1 - date.getFullYear())
          this.partnerSuperExpirationDate = date.getFullYear()
        }
        if (this.incomeChartData[year].partnerSuper > 0) partnerSuperHasBegun = true
      })
      const series = []

      if (salaryProjection.filter((el) => el.income > 0).length > 0) {
        series.push({
          name: this.formatHTML('Your salary', getRootColor('cfs-blue')),
          data: salaryProjection.map((el) => {
            return {
              y: Math.round(el.income),
              tooltip: this.createTooltip(el, 'Salary', this.incomeChartData[el.year], 'salary'),
            }
          }),
          color: getRootColor('cfs-blue'),
          // pointWidth: 15
        })
      }

      if (superProjection.filter((el) => el.income > 0).length > 0) {
        series.push({
          name: this.formatHTML('Your super income', getRootColor('cfs-lightblue')),
          data: superProjection.map((el) => {
            return {
              y: significantFigureRound(el.income),
              tooltip: this.createTooltip(el, 'Super', this.incomeChartData[el.year], 'super'),
            }
          }),
          color: getRootColor('cfs-lightblue'),
          // pointWidth: 15
        })
      }

      if (pensionProjection.filter((el) => el.income > 0).length > 0) {
        series.push({
          name: this.formatHTML('Your age pension', getRootColor('cfs-grey50')),
          data: pensionProjection.map((el) => {
            if (el.income > 0) this.$emit('hasPension', true)
            return {
              y: significantFigureRound(el.income),
              tooltip: this.createTooltip(
                el,
                'Age pension',
                this.incomeChartData[el.year],
                'pension',
              ),
            }
          }),
          color: getRootColor('cfs-grey50'),
          // pointWidth: 15
        })
      }
      if (partnerSalaryProjection.filter((el) => el.income >= 0).length > 0) {
        series.push({
          name: this.formatHTML("Partner's salary", getRootColor('cfs-blue')),
          data: partnerSalaryProjection.map((el) => {
            return {
              y: Math.round(el.income),
              tooltip: this.createTooltip(
                el,
                "Partner's salary",
                this.incomeChartData[el.year],
                'partnerSalary',
              ),
            }
          }),
          color: getRootColor('otivo-blue'),
          // pointWidth: 15
        })
      }
      if (partnerSuperProjection.filter((el) => el.income > 0).length > 0) {
        series.push({
          name: this.formatHTML("Partner's super income", getRootColor('gold-1')),
          data: partnerSuperProjection.map((el) => {
            return {
              y: significantFigureRound(el.income),
              tooltip: this.createTooltip(
                el,
                "Partner's super",
                this.incomeChartData[el.year],
                'partnerSuper',
              ),
            }
          }),
          color: getRootColor('gold-1'),
        })
      }
      if (partnerPensionProjection.filter((el) => el.income > 0).length > 0) {
        series.push({
          name: this.formatHTML("Partner's age pension", getRootColor('grey-3')),
          data: partnerPensionProjection.map((el) => {
            return {
              y: significantFigureRound(el.income),
              tooltip: this.createTooltip(
                el,
                "Partner's age pension",
                this.incomeChartData[el.year],
                'partnerPension',
              ),
            }
          }),
          color: getRootColor('grey-3'),
          // pointWidth: 15
        })
      }

      if (managedFundsProjection.filter((el) => el.income > 0).length > 0) {
        series.push({
          name: this.formatHTML('Managed Funds', getRootColor('green-2')),
          data: managedFundsProjection.map((el) => {
            return {
              y: Math.round(el.income),
              tooltip: this.createTooltip(
                el,
                'Managed Funds',
                this.incomeChartData[el.year],
                'managedFunds',
              ),
            }
          }),
          color: getRootColor('green-2'),
          // pointWidth: 15
        })
      }

      if (propertyProjection.filter((el) => el.income > 0).length > 0) {
        series.push({
          name: this.formatHTML('Investment property', getRootColor('purple-2')),
          data: propertyProjection.map((el) => {
            return {
              y: Math.round(el.income),
              tooltip: this.createTooltip(
                el,
                'Property',
                this.incomeChartData[el.year],
                'property',
              ),
            }
          }),
          color: getRootColor('purple-2'),
          // pointWidth: 15
        })
      }

      if (sharesProjection.filter((el) => el.income > 0).length > 0) {
        series.push({
          name: this.formatHTML('Shares', getRootColor('red-1')),
          data: sharesProjection.map((el) => {
            return {
              y: Math.round(el.income),
              tooltip: this.createTooltip(el, 'Shares', this.incomeChartData[el.year], 'shares'),
            }
          }),
          color: getRootColor('red-1'),
          // pointWidth: 15
        })
      }

      if (cashAndFixedProjection.filter((el) => el.income > 0).length > 0) {
        series.push({
          name: this.formatHTML('Cash', getRootColor('green-1')),
          data: cashAndFixedProjection.map((el) => {
            return {
              y: Math.round(el.income),
              tooltip: this.createTooltip(
                el,
                'Cash',
                this.incomeChartData[el.year],
                'cashAndFixed',
              ),
            }
          }),
          color: getRootColor('green-1'),
          // pointWidth: 15
        })
      }

      if (alternativeProjection.filter((el) => el.income > 0).length > 0) {
        series.push({
          name: this.formatHTML('Other', getRootColor('gold-2')),
          data: alternativeProjection.map((el) => {
            return {
              y: Math.round(el.income),
              tooltip: this.createTooltip(
                el,
                'Other',
                this.incomeChartData[el.year],
                'alternative',
              ),
            }
          }),
          color: getRootColor('gold-2'),
          // pointWidth: 15
        })
      }
      this.setNumberOfLegends(series.length)
      return series
    },
  },

  methods: {
    setNumberOfLegends(seriesLength) {
      this.numLegends = seriesLength
    },
    tooltipClickRootFilter(e) {
      // Only fire if the target is the root svg, so not targeting any other elements of the graph
      if (e.target.className.baseVal === 'highcharts-root') {
        this.mouseLeave(e)
      }
    },
    mouseLeave() {
      const chart = this.$refs.retIncomeChart?.chart
      if (chart && !chart.tooltip.isHidden) {
        const tooltip = chart.tooltip.label.element
        const tooltipHeading = document.getElementById('html-tooltip-heading')
        const tooltipInfo = document.getElementById('html-tooltip-info')
        tooltip.classList.add('opacity-0')
        tooltipHeading.classList.add('opacity-0')
        tooltipInfo.classList.add('opacity-0')
      }
    },

    getCurrentUserAge(age, year) {
      return year - (new Date().getFullYear() - age)
    },

    createTooltip(el, title, chartData, type) {
      let totalValue = this.getTotalValue(chartData)
      if (this.showPartnerData)
        totalValue +=
          Math.round(chartData.partnerSalary) +
          significantFigureRound(chartData.partnerPensionSF) +
          significantFigureRound(chartData.partnerSuperSF)
      return incomeChartTooltip(
        el,
        chartData,
        type,
        significantFigureRound(this.userData.desired_retirement_income),
        this.showPartnerData,
        totalValue,
        this.getCurrentUserAge(this.userData.age, el.year),
        this.getCurrentUserAge(this.partnerData.age, el.year),
        this.userData.desired_retirement_age,
        this.partnerData.desired_retirement_age,
      )
    },

    getXAxisLabels() {
      const labels = []
      if (Object.keys(this.incomeChartData).length > 0) {
        let counter = 0
        while (counter < 92) {
          labels.push(this.userData.age + counter)
          ++counter
        }
      }
      return labels
    },

    getPlotLineValueByRetirementAge(age, retirementAge) {
      if (retirementAge && age) {
        const date = new Date()
        date.setFullYear(date.getFullYear() - age)
        date.setFullYear(date.getFullYear() + retirementAge)
        return date.getFullYear() - new Date().getFullYear()
      }
      return null
    },
    formatHTML(title, colour) {
      return `<div style="padding: 12px 20px; text-align: center; min-width: 240px" /> ${this.getSVGTick(
        colour,
      )} <div style="display:inline; margin-left: 4px;">${title}</div></div>`
    },
    getSVGTick(colour) {
      let svg =
        '<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" style="display: inline;">'
      svg += `<path d="M12 6.13843C12 9.45092 9.31371 12.1362 6 12.1362C2.68629 12.1362 0 9.45092 0 6.13843C0 2.82593 2.68629 0.140625 6 0.140625C9.31371 0.140625 12 2.82593 12 6.13843Z" fill="${colour}" />`
      svg +=
        '<path fill-rule="evenodd" clip-rule="evenodd" d="M9.53033 4.11021C9.82322 4.403 9.82322 4.8777 9.53033 5.17049L5.325 9.37428L3.21967 7.26972C2.92678 6.97693 2.92678 6.50223 3.21967 6.20945C3.51256 5.91666 3.98744 5.91666 4.28033 6.20945L5.325 7.25373L8.46967 4.11021C8.76256 3.81743 9.23744 3.81743 9.53033 4.11021Z" fill="white"/>'
      svg += '</svg>'
      return svg
    },
    getTotalValue(chartData) {
      return (
        Math.round(chartData.salary) +
        significantFigureRound(chartData.pensionSF) +
        significantFigureRound(chartData.superSF) +
        Math.round(chartData.shares) +
        Math.round(chartData.property) +
        Math.round(chartData.alternative) +
        Math.round(chartData.cashAndFixed)
      )
    },
    refreshExpirationDates() {
      this.superExpirationDate = null
      this.partnerSuperExpirationDate = null
    },
    checkForCareerBreak(age, careerBreaks, year) {
      let valid = true
      careerBreaks.forEach((careerBreak) => {
        if (careerBreak.age && careerBreak.duration) {
          if (
            this.getCurrentUserAge(age, year) >= careerBreak.age &&
            this.getCurrentUserAge(age, year) <= careerBreak.age + careerBreak.duration
          ) {
            valid = false
          }
        }
      })
      return valid
    },
  },
  emits: ['hasPension'],
}
</script>

<style scoped>
.legendFlex {
  @apply grid grid-cols-3;
}
</style>
