<template>
  <div
    class="border rounded p-2 flex flex-col"
    @click="$emit('click')"
    @keyup.enter="$emit('click')">
    <svg class="md:h-28" viewBox="-10 -2 36 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 11.75H1C0.734784 11.75 0.48043 11.6446 0.292893 11.4571C0.105357 11.2696 0 11.0152 0 10.75C0 10.4848 0.105357 10.2304 0.292893 10.0429C0.48043 9.85536 0.734784 9.75 1 9.75H15C15.2652 9.75 15.5196 9.85536 15.7071 10.0429C15.8946 10.2304 16 10.4848 16 10.75C16 11.0152 15.8946 11.2696 15.7071 11.4571C15.5196 11.6446 15.2652 11.75 15 11.75Z"
        :fill="selected ? '#FF0000' : '#CACED3'" />
      <path
        d="M12 1.8075H11.25V1C11.25 0.734784 11.1446 0.48043 10.9571 0.292893C10.7696 0.105357 10.5152 0 10.25 0C9.98478 0 9.73043 0.105357 9.54289 0.292893C9.35536 0.48043 9.25 0.734784 9.25 1V1.8075H6.75V1C6.75 0.734784 6.64464 0.48043 6.45711 0.292893C6.26957 0.105357 6.01522 0 5.75 0C5.48478 0 5.23043 0.105357 5.04289 0.292893C4.85536 0.48043 4.75 0.734784 4.75 1V1.8075H4C2.93913 1.8075 1.92172 2.22893 1.17157 2.97907C0.421427 3.72922 0 4.74663 0 5.8075V12C0 13.0609 0.421427 14.0783 1.17157 14.8284C1.92172 15.5786 2.93913 16 4 16H12C13.0609 16 14.0783 15.5786 14.8284 14.8284C15.5786 14.0783 16 13.0609 16 12V5.81C16.0003 5.2845 15.8971 4.76409 15.6962 4.2785C15.4954 3.79291 15.2008 3.35166 14.8293 2.97996C14.4578 2.60826 14.0168 2.3134 13.5313 2.11222C13.0458 1.91105 12.5255 1.8075 12 1.8075ZM14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14H4C3.46957 14 2.96086 13.7893 2.58579 13.4142C2.21071 13.0391 2 12.5304 2 12V5.81C2 5.27957 2.21071 4.77086 2.58579 4.39579C2.96086 4.02071 3.46957 3.81 4 3.81H4.75V5C4.75 5.26522 4.85536 5.51957 5.04289 5.70711C5.23043 5.89464 5.48478 6 5.75 6C6.01522 6 6.26957 5.89464 6.45711 5.70711C6.64464 5.51957 6.75 5.26522 6.75 5V3.8075H9.25V5C9.25 5.26522 9.35536 5.51957 9.54289 5.70711C9.73043 5.89464 9.98478 6 10.25 6C10.5152 6 10.7696 5.89464 10.9571 5.70711C11.1446 5.51957 11.25 5.26522 11.25 5V3.8075H12C12.5304 3.8075 13.0391 4.01821 13.4142 4.39329C13.7893 4.76836 14 5.27707 14 5.8075V12Z"
        :fill="selected ? '#0064FF' : '#CACED3'" />
    </svg>

    <div class="flex-1 flex">
      <div class="self-center text-center w-full">I am renting</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RentingIcon',
  props: {
    selected: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped></style>
