<template>
  <div id="our-mission" class="md:text-center px-[20px] w-full m-auto pt-10 bg-white">
    <div class="max-w-[860px] mx-auto space-y-10 pb-10">
      <h3 class="">Our mission</h3>
      <p class="intro-1">
        Otivo is on a mission to make financial advice accessible and affordable, helping the 91% of
        Australians without advice to be better off.
      </p>
      <p class="paragraph-1">
        That’s why we’ve partnered with super funds, financial advice firms, and some of Australia’s
        biggest employers to provide digital advice to thousands of people at
        <em>Colonial First State</em>, <em>Rio Tinto</em>, <em>Suncorp</em>, <em>Latitude</em>, and
        <em>Ernst & Young</em>.
      </p>
      <p class="paragraph-1">
        No wonder Sue Viskovic from Elixir Consulting called Otivo a
        <strong>‘game-changer’</strong>.
      </p>
      <div class="py-10 mx-auto w-[350px]">
        <img :src="BeBetterOffBadge" alt="" />
      </div>
      <p class="intro-2 text-otivo-blue text-center">
        “At Otivo, we want to help every Aussie take control of their financial future because we
        believe when you're better off, our wider community is better off too.”
      </p>
      <p class="paragraph-2 text-grey-2 text-center">Paul Feeney, Otivo Founder + CEO</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import BeBetterOffBadge from '@/assets/img/be-better-off-badge.svg?url'
</script>
<style scoped></style>
