<template>
  <h6 v-if="heading" class="pt-[20px]">
    {{ heading }}
  </h6>
  <p v-if="body" class="grey-2 paragraph-1 pt-[20px]">
    {{ body }}
  </p>
  <div class="flex flex-col gap-[20px] justify-end mt-[40px]">
    <OtivoButton colour="white" @click="testFunction"> Test function </OtivoButton>
    <OtivoButton colour="blue" @click="close"> Close </OtivoButton>
  </div>
</template>

<script lang="ts" setup>
import OtivoButton from '@/components/OtivoButton.vue'
import { useModalStore } from '@/store/pinia/ModalStore.ts'

type Props = {
  heading: string
  body: string
  testFunction: () => void
}

const modalStore = useModalStore()

const close = () => {
  modalStore.closeModal()
}

defineProps<Props>()
</script>

<style scoped></style>
