import { defineStore } from 'pinia'
import { Ref, ref, UnwrapRef } from 'vue'
import OpenApiService from '@/services/OpenApiService'

export const REACTIONS = {
  DISLIKE: 0,
  NEUTRAL: 0.5,
  LIKE: 1
}

export type ChatDataType = {
  answer: string
  id: number | null
  question: string
  reaction: number
}

export type Reaction = {
  existingReaction: number
  newReaction: number
  answerId: number
}

type AllChatDataType = {
  total: number | null
  per_page: number | null
  current_page: number | null
  last_page: number | null
  first_page_url: string | null
  last_page_url: string | null
  next_page_url: string | null
  prev_page_url: string | null
  path: string
  from: number | null
  to: number | null
  links: Array<{ active: boolean; label: string; url: string }>
  data: Array<ChatDataType>
}

type QuestionResponse = {
  answer: string
  data_id: number
  reaction: number
}

export const useChatBotStore = defineStore('chatBot', () => {
  // TODO: Is this going to break the normal chat bot?
  const allChatData = ref<AllChatDataType>({
    total: null,
    per_page: null,
    current_page: null,
    last_page: null,
    first_page_url: null,
    last_page_url: null,
    next_page_url: null,
    prev_page_url: null,
    path: '',
    from: null,
    to: null,
    links: [],
    data: []
  })
  const hasConsented = ref(false)
  const isLoadingConsent = ref(false)
  const waitingForResponse = ref(false)
  const pageContent = ref()
  const getQAHistory = () => {
    OpenApiService.getQAHistory()
      .then((res) => {
        // Chat History Array is reversed to show in the correct vertical order
        res.data.data.reverse()
        allChatData.value = res.data
      })
      .catch((err: Error) => console.error(err))
  }

  const askQuestion = async (question: string) => {
    allChatData.value.data.push({ question, answer: '', id: null, reaction: 0.5 })
    const response = await OpenApiService.askQuestion({ question }).catch((error) => {
      console.error(error)
      return
    })

    if (response) handleQuestionResponse(response.data, allChatData)
  }

  const askDocumentQuestion = async (superId: number, sessionId: string, question: string) => {
    allChatData.value.data.push({ question, answer: '', id: null, reaction: 0.5 })
    const response = await OpenApiService.askDocumentQuestion(superId, sessionId, {
      question
    }).catch((error) => {
      console.error(error)
      return
    })

    if (response) handleQuestionResponse(response.data, allChatData)
  }

  const handleQuestionResponse = (
    response: QuestionResponse,
    allChatData: Ref<UnwrapRef<AllChatDataType>>
  ) => {
    const answer: string = response.answer
    const questionId: number = response.data_id
    const lastIndex: number = allChatData.value.data.length - 1
    allChatData.value.data[lastIndex].answer = answer
    allChatData.value.data[lastIndex].id = questionId
  }

  const getNextPageChatHistory = async (nextPageNumber: number) => {
    const res = await OpenApiService.getNextPageChatHistory(nextPageNumber)
    const existingQA = allChatData.value.data
    const nextPageQA = res.data.data.reverse()

    res.data.data = [...nextPageQA, ...existingQA]
    allChatData.value = res.data
    return 'Successfully got the next page of questions and answers'
  }

  const updateReaction = (answerId: number, newReaction: number) => {
    OpenApiService.updateReaction({ answerId, newReaction })

    updateAnswerReaction(answerId, newReaction)
  }

  const updateDocumentReaction = (answerId: number, newReaction: number) => {
    OpenApiService.updateDocumentReaction({ answerId, newReaction })

    updateAnswerReaction(answerId, newReaction)
  }

  const updateAnswerReaction = (answerId: number, newReaction: number) => {
    const answer: ChatDataType | undefined = allChatData.value.data.find(
      (answer: ChatDataType): boolean => answer.id === answerId
    )

    if (answer) answer.reaction = newReaction
  }

  const getConsent = async () => {
    isLoadingConsent.value = true
    const res = await OpenApiService.getConsent()
    hasConsented.value = res.data.consent
    isLoadingConsent.value = false
  }

  const updateConsent = (consent: boolean) => {
    hasConsented.value = consent
    OpenApiService.updateConsent({ consent: consent })
  }

  return {
    allChatData,
    hasConsented,
    isLoadingConsent,
    waitingForResponse,
    pageContent,
    getQAHistory,
    askQuestion,
    askDocumentQuestion,
    getNextPageChatHistory,
    updateReaction,
    getConsent,
    updateConsent,
    updateDocumentReaction
  }
})
