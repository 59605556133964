<template>
  <div class="px-4 max-w-full">
    <div
      v-if="whitelabel?.render_whitelabel_logo && whitelabel.whitelabel_logo_img_id !== null"
      class="max-w-[1280px] mx-auto flex flex-col items-start justify-center mt-10 md:mt-20">
      <div>
        <img
          class="w-auto h-20 mx-6 md:mx-auto"
          :src="whitelabel.logo"
          :alt="`${whitelabel.name}-logo`" />
      </div>
    </div>
    <div
      v-if="showFooterNav"
      class="flex flex-col md:flex-row justify-between items-start my-10 md:my-20 mx-auto w-full md:max-w-[1280px]">
      <div
        class="w-full md:w-auto lg:mt-0 md:flex"
        :key="title"
        v-for="(links, title) in getWhiteLabelsLinks">
        <LinkTemplate
          :links="links"
          :title="title"
          title-classes="border-b-otivo-blue"
          header-colour="text-[#A0E6FA]"
          text-colour="blue-2"
          @scroll="scrollToTop" />
      </div>
      <div class="my-10 md:m-0 mx-5">
        <SocialIcons />
      </div>
    </div>
    <div
      class="text-mf1 md:h-auto md:my-10 md:text-f1 mb-10"
      :class="{ 'pb-38 md:pb-0': isAuthenticated }">
      <div class="table m-auto">
        <div
          class="table-cell lg:text-center px-4 tracking-wider font-semibold leading-6 text-blue-2">
          &copy; {{ getProviderDetails.provider }}
          <!-- If it's our AFSL we also mention the ACL -->
          ABN {{ getProviderDetails.abn }} AFSL
          {{ ' + Australian Credit Licence ' + getProviderDetails.afsl }}
          {{ getProviderDetails.address }}
        </div>
      </div>
    </div>
    <AdviserContactModal />
    <ContactUsModal />
    <PricingModal />
    <TimeoutModal />
    <SuncorpContactUsModal />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import ContactUsModal from '@/components/BrandComponents/ContactUs/ContactUsModal.vue'
import PricingModal from '@/components/BrandComponents/Pricing/PricingModal.vue'
import LinkTemplate from '@/components/Footer/LinkTemplate.vue'
import AdviserContactModal from '@/components/BrandComponents/ContactUs/AdviserContactModal.vue'
import SocialIcons from '@/components/Footer/SocialIcons.vue'
import TimeoutModal from '@/components/AppModals/TimeoutModal.vue'
import SuncorpContactUsModal from '@/components/BrandComponents/ContactUs/SuncorpContactUsModal.vue'
import { isAuthenticated, user as Auth0User, User } from '@/lib/AuthenticatorPlugin.ts'
import { getLinksForWhitelabel } from '@/composables/getLinksForWhitelabel.ts'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import checkAccountStatus from '@/composables/checkAccountStatus.ts'

const sessionStore = useSessionStore()
const whitelabel = computed(() => sessionStore.getWhitelabelData)
const getWhiteLabelsLinks = computed(() => getLinksForWhitelabel())
const showFooterNav = ref(false)

const store = useStore()
const getProviderDetails = computed(() => store.getters.getAFSLDetailsForWhiteLabel)
const showContactUsModal = () => {
  store.dispatch('renderContactUsModal', true)
}

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

onMounted(async () => {
  const user = Auth0User.value as User
  if (!user) {
    showFooterNav.value = true
    return
  }
  const status = await checkAccountStatus(user)
  showFooterNav.value = status.name === undefined
})
defineExpose({
  showContactUsModal,
  scrollToTop,
})
</script>

<style scoped></style>
