<template>
  <svg width="12" height="7" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.30841 7L6.30841 2L11.3084 7"
      :stroke="stroke"
      stroke-width="2"
      stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  name: 'DropdownArrow',
  props: {
    stroke: {
      type: String,
      default: '#0064FF'
    }
  }
}
</script>

<style scoped></style>
