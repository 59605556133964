import { formatCurrency, formatPercentage } from '@/lib/helpers'
import _ from 'lodash'
import { store as vueStore } from '@/store'

const updateRetirementObject = (retirement) => {
  retirement.errors = {
    userAge: '',
    partnerAge: '',
    totalRetirementBalance: '',
    userContributionRate: '',
    userAdditionalContribution: '',
    partnerContributionRate: '',
    partnerAdditionalContribution: '',
    userAgePension: '',
    partnerAgePension: '',
    general: ''
  }
  if (!retirement.open) {
    retirement.open = true
  }
  //
  // retirement.me.supers = retirement.me.supers.map(updateSuperObject)
  // retirement.partner.supers = retirement.partner.supers.map(updateSuperObject)
  return retirement
}

const getEmptySuperPlan = () => {
  return {
    fund_name: '',
    owner: 'single',
    balance: null,
    defined_benefit: null, // bool
    nominated_beneficiary: null, // bool
    other_investments: false,
    data_source: 'user',
    source: 'user',
    chant_west_product_id: null,
    smsf: null,
    allocation: [
      {
        chant_west_investment_name: '',
        chant_west_investment_id: null,
        percent_allocation: 100
      }
    ],
    no_investment_options: false
  }
}
const updateSuperObject = (superObject) => {
  superObject = resetSuperErrorInfos(superObject)

  // reset system generated inputs for super
  if (superObject.source === 'system') {
    // If the field is set to 1 then allow it to remain as one since the default is 0 and the user can partially
    // updated by the Insurance Optimise Form.
    superObject.defined_benefit = superObject.defined_benefit === 1 ? 1 : null
    superObject.nominated_beneficiary = superObject.nominated_beneficiary === 1 ? 1 : null
  }

  if (superObject.smsf === undefined) superObject.smsf = null
  if (superObject.chant_west_product_id === undefined) superObject.chant_west_product_id = ''
  superObject.old_chant_west_product_id = superObject.chant_west_product_id
  if (superObject.no_investment_options === undefined) superObject.no_investment_options = false
  if (superObject.allocation === undefined || _.isEmpty(superObject.allocation)) {
    superObject.allocation = [
      {
        chant_west_investment_name: '',
        chant_west_investment_id: null,
        percent_allocation: 100
      }
    ]
  }

  return superObject
}

const updateSuperObjectBeforePosting = (superObject) => {
  superObject.source = 'user'
  if (superObject.defined_benefit === null) {
    superObject.defined_benefit = false
  }
  if (superObject.chant_west) {
    delete superObject.chant_west
  }
  return superObject
}

const resetSuperErrorInfos = (object) => {
  object.error = getSuperannuationErrors()
  object.info = getSuperannuationInfos()
  return object
}

const getSuperannuationErrors = () => {
  return {
    fund_name: '',
    balance: '',
    additional_contributions: '',
    defined_benefit: '',
    nominated_beneficiary: '',
    smsf: '',
    chant_west_product_id: '',
    allocation: ''
  }
}

const getSuperannuationInfos = () => {
  return {
    chant_west_product_id: ''
  }
}

const getAverageSuperBalance = () => 114367

// need to change following figures every year
const getMaximumEmployerSuperContribution = () => {
  return vueStore.state.whitelabelDefaultValues.maxContributionPerAnnumForSuperGuarantee
}

const getMinSuperContributionRate = () => {
  return vueStore.state.whitelabelDefaultValues.minSuperGuarantee
}

const getSuperContributionThreshHoldSalary = () => {
  return vueStore.state.whitelabelDefaultValues.maxSalaryPerAnnumForSuperGuarantee
}

// Law changed to make the minimum zero
const getSuperContributionThreshHoldMinSalary = () => 0

// concessional cap
const getMaximumUserSuperContribution = () => {
  return vueStore.state.whitelabelDefaultValues.maxSuperContributions
}

const getMaximumUserSuperMonthlyContribution = () =>
  Math.floor(getMaximumUserSuperContribution() / 12)

const getGrossAnnualIncomeIncludingSuper = (salaryExcludingSuper, superRate) => {
  if (superRate === undefined) superRate = getMinSuperContributionRate()
  let yearlySuperContribution = salaryExcludingSuper * (superRate / 100)

  if (yearlySuperContribution > getMaximumEmployerSuperContribution()) {
    yearlySuperContribution = getMaximumEmployerSuperContribution()
  }

  return salaryExcludingSuper + yearlySuperContribution
}

const calculateSuperContributionRateForNetIncome = (netIncome) => {
  // if (netIncome > getSuperContributionThreshHoldSalary()) {
  //   return getMinSuperContributionRate()
  // }

  if (netIncome < getSuperContributionThreshHoldMinSalary()) {
    return 0
  }

  return getMinSuperContributionRate()
}

const getSuperContributionRatesBySalary = (salary) => {
  const minRate = getMinSuperContributionRate()
  let maxRate = 25

  if (salary > getSuperContributionThreshHoldSalary()) {
    maxRate = getMinSuperContributionRate()
  } else if (salary < getMaximumUserSuperContribution()) {
    maxRate = 25
  } else {
    maxRate = Number(((getMaximumUserSuperContribution() / salary) * 100).toFixed(4))
  }

  return { minRate: minRate, maxRate: maxRate }
}

const calculateEmployerRequiredContribution = (salary, contributionRate) => {
  if (!salary) return 0
  contributionRate = contributionRate !== null ? contributionRate : getMinSuperContributionRate()

  const contribution = (salary - salary / (1 + contributionRate / 100)) / 12
  const maxEmployerContribution = getMaximumEmployerSuperContribution() / 12
  return salary > getSuperContributionThreshHoldSalary() && contribution > maxEmployerContribution
    ? maxEmployerContribution
    : contribution
}

const calculateUserExtraContributions = (salary, contributionRate) => {
  if (!salary) return 0
  const minContributionRate = getMinSuperContributionRate()
  const tempRate = contributionRate || minContributionRate
  let contributions = (salary - salary / (1 + tempRate / 100)) / 12
  const minRequiredContribution = (salary - salary / (1 + minContributionRate / 100)) / 12

  const maxMonthlyContributions = getMaximumUserSuperMonthlyContribution()

  contributions = contributions > maxMonthlyContributions ? maxMonthlyContributions : contributions

  contributions =
    contributions > minRequiredContribution ? contributions - minRequiredContribution : 0

  return contributions
}

const getEmptyRetirementConsent = () => {
  return {
    debt: {
      consent: false,
      type: 'debt',
      plan_id: ''
    },
    savings: {
      consent: false,
      type: 'savings',
      plan_id: ''
    }
  }
}

const createAssetsChartTooltip = (
  el,
  title,
  userDob,
  partnerDob,
  firstName,
  partnerFirstName,
  showPartnerElement,
  userRetirementAge,
  partnerRetirementAge,
  renderUser = true,
  renderPartner = false
) => {
  const isSuper = title.toLowerCase() === 'super'
  const isPartnerSuper = title.toLowerCase() === 'partner super'
  const isNetAssets = title.toLowerCase() === 'net assets'
  const isAlternative = title.toLowerCase() === 'alternative'
  const isProperty = title.toLowerCase() === 'property'
  const userAge = el.year - new Date(userDob).getFullYear()
  let tooltip = ''
  const partnerAge = ''

  tooltip += `<strong>${el.year}</strong> <br/>`

  if (isProperty) {
    tooltip += '<strong>Investment property</strong> <br/>'
  } else if (isAlternative) {
    tooltip += '<strong>Other</strong> <br/>'
  } else if (!isNetAssets) {
    tooltip += `<strong>${title}</strong> <br/>`
  }

  if (renderUser) {
    tooltip += `<strong>${firstName}</strong> ${userAge} `
  }

  if (renderPartner && showPartnerElement) {
    const partnerAge = el.year - new Date(partnerDob).getFullYear()
    tooltip += `<strong>${partnerFirstName}</strong> ${partnerAge}`
  }

  if (isNetAssets) {
    tooltip += '<br/>'
    let netAssets = el.startingValue
    if (el.debtBalance) {
      netAssets -= el.debtBalance
    }
    tooltip += `<strong>Net assets</strong> ${formatCurrency(netAssets)}`
  }

  if (!isNetAssets) {
    const value = el.startingValue
    tooltip += `<br /> <strong>Balance</strong>: ${formatCurrency(value)}`
  }

  if (el.debtBalance !== undefined && el.debtBalance > 0) {
    tooltip += `<br /> <strong>Debt balance</strong>: ${formatCurrency(el.debtBalance)}`
  }

  if (
    import.meta.env.MODE !== 'production' &&
    import.meta.env.MODE !== 'staging' &&
    (isSuper || isPartnerSuper)
  ) {
    tooltip += `<br /> <strong>Growth</strong>: ${formatCurrency(
      el.annualGrowth
    )} (${formatPercentage(el.annualGrowthPercentage.toFixed(2))})`
    tooltip += `<br /> <strong>Earning on super balance</strong>: ${formatCurrency(
      el.earningsOnSuperBalance
    )}`
    tooltip += `<br /> <strong>Contributions</strong>: ${formatCurrency(el.contributions)}`
    tooltip += `<br /> <strong>Growth on contributions</strong>: ${formatCurrency(
      el.earningsOnContributions
    )}`
    tooltip += '<br /> <strong>Less</strong>:'

    if (isSuper && userAge >= userRetirementAge) {
      tooltip += `<br /> <strong>Drawings</strong>: ${formatCurrency(el.drawDown)}`
      tooltip += `<br /> <strong>Pension Amount</strong>: ${formatCurrency(el.pensionIncome)}`
      tooltip += `<br /> <strong>Pension reason</strong>: ${el.pensionSource}`
    }

    if (isPartnerSuper && renderPartner && partnerAge >= partnerRetirementAge) {
      tooltip += `<br /> <strong>Partner Drawings</strong>: ${formatCurrency(el.drawDown)}`
      tooltip += `<br /> <strong>Partner Pension Amount</strong>: ${formatCurrency(
        el.pensionIncome
      )}`
      tooltip += `<br /> <strong>Partner Pension reason</strong>: ${el.pensionSource}`
    }
    tooltip += `<br /> <strong>Tax on super balance</strong>: ${formatCurrency(
      el.taxOnEarningsOnSuperBalance
    )}`
    tooltip += `<br /> <strong>Tax on contributions</strong>: ${formatCurrency(
      el.taxOnContributions
    )}`

    tooltip += `<br /> <strong>Gross annual salary</strong>: ${formatCurrency(
      el.grossAnnualSalary
    )}`
    tooltip += `<br /> <strong>Contribution rate</strong>: ${formatPercentage(el.contributionRate)}`

    tooltip += `<br /> <strong>Running starting value</strong>: ${formatCurrency(el.startingValue)}`
    tooltip += `<br /> <strong>Running closing value</strong>: ${formatCurrency(el.closingValue)}`
  }

  return tooltip
}

const validateSuperPlan = (superObject) => {
  let validated = true
  const errorsObject = getSuperannuationErrors()
  const smsf = superObject.smsf

  if (
    (smsf || superObject.chant_west_product_id === 0) &&
    (!superObject.fund_name || superObject.fund_name === '')
  ) {
    validated = false
    errorsObject.fund_name = 'Please provide fund name'
  }

  if (superObject.balance === null) {
    validated = false
    errorsObject.balance = 'Please enter an amount'
  } else if (superObject.balance > MAX_SUPER_FUND_LIMIT) {
    validated = false
    errorsObject.balance = `Superannuation balance should not be more than ${formatCurrency(
      MAX_SUPER_FUND_LIMIT
    )}`
  }

  if (superObject.defined_benefit === null) {
    validated = false
    errorsObject.defined_benefit = 'Please select an option'
  }

  if (superObject.nominated_beneficiary === null) {
    validated = false
    errorsObject.nominated_beneficiary = 'Please select an option'
  }

  if (smsf === '' || smsf === null) {
    validated = false
    errorsObject.smsf = 'Please select a valid option'
  }

  if (!smsf && superObject.chant_west_product_id !== 0) {
    if (
      _.isNil(superObject.chant_west_product_id) ||
      typeof superObject.chant_west_product_id !== 'number'
    ) {
      validated = false
      errorsObject.chant_west_product_id = 'Please select a fund from the list'
    }

    if (!superObject.no_investment_options) {
      if (
        superObject.allocation.filter((el) => typeof el.chant_west_investment_id !== 'number')
          .length > 0
      ) {
        validated = false
        errorsObject.allocation = 'Please select an option from the list'
      }

      if (superObject.allocation.reduce((acc, el) => acc + el.percent_allocation, 0) !== 100) {
        validated = false
        errorsObject.allocation = 'Total of all investment options should be 100%'
      }
    }
  }

  return { validated: validated, errors: errorsObject }
}

export const MAX_SUPER_FUND_LIMIT = 5_000_000
export const MAX_GROSS_ANNUAL_SALARY_LIMIT = 1_000_000

export {
  updateRetirementObject,
  getEmptySuperPlan,
  updateSuperObject,
  updateSuperObjectBeforePosting,
  getAverageSuperBalance,
  resetSuperErrorInfos,
  getSuperContributionThreshHoldSalary,
  getMaximumEmployerSuperContribution,
  getSuperContributionRatesBySalary,
  calculateEmployerRequiredContribution,
  getMaximumUserSuperContribution,
  getMaximumUserSuperMonthlyContribution,
  calculateUserExtraContributions,
  getEmptyRetirementConsent,
  createAssetsChartTooltip,
  getGrossAnnualIncomeIncludingSuper,
  getMinSuperContributionRate,
  getSuperContributionThreshHoldMinSalary,
  calculateSuperContributionRateForNetIncome,
  validateSuperPlan
}
