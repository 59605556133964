<template>
  <basic-input class="gap-3">
    <template #input-label>
      <div v-if="label !== ''" class="set-w-28 md:set-w-48 pt-4 paragraph-2">
        <label :class="{ required: required }" :for="name">
          {{ capitalize(label) }}
        </label>
      </div>
    </template>
    <template #input-box>
      <textarea
        :id="name"
        :class="[{ ['border-2px border-red-dark']: this.error !== '' }, inputClasses]"
        :cols="columns"
        :disabled="disabled"
        :placeholder="placeHolder"
        :rows="rows"
        :value="value"
        autocomplete="off"
        class="rounded paragraph-2 block w-full focus:outline-none bg-white p-4 h-24 border border-otivo-blue ring-2 ring-blue-4"
        @blur="emitBlur($event)"
        @input="updateValue($event)" />
    </template>
    <template #input-errors>
      <input-error v-if="error !== ''" :error="error" class="text-center" />
      <input-info v-if="info !== ''" :info="info" />
    </template>
  </basic-input>
</template>

<script>
import InputError from './InputError'
import InputInfo from './InputInfo'
import { capitalize as u } from '@/lib/helpers'
import BasicInput from '@/components/BasicInputs/BasicInput.vue'
export default {
  name: 'InputTextArea',
  components: { BasicInput, InputInfo, InputError },
  props: {
    name: {
      type: String,
      required: true,
    },
    rows: {
      type: String,
      default: '20',
    },
    columns: {
      type: String,
      default: '8',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    placeHolder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    info: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputClasses: {
      type: String,
      default: '',
    },
  },
  emits: ['input', 'blur'],
  methods: {
    emitBlur(event) {
      this.$emit('blur', event.target.value)
    },

    updateValue(event) {
      this.$emit('input', event.target.value)
    },
    capitalize(value) {
      return u(value)
    },
  },
}
</script>

<style scoped>
input:disabled {
  @apply border-none rounded bg-grey-light px-2 mt-2;
}

.inline-input-box input:disabled {
  @apply px-0 mt-0;
}
</style>
