<template>
  <div>
    <fitness-test-question-label :question-number="questionNumber" question="Your gender" />

    <input-box-buttons
      @click="setUserGender"
      :buttons="[
        {
          name: 'Male',
          value: 'male',
        },
        {
          name: 'Female',
          value: 'female',
        },
        {
          name: 'Prefer not to say',
          value: 'unknown',
        },
      ]"
      :selected="userGender"
      name="living-situation"
      label="" />
  </div>
</template>

<script>
import GenderAnswer from './genderAnswer'
import { mapState } from 'vuex'
import FitnessTestQuestionLabel from '../QuestionsLabel/FitnessTestQuestionLabel.vue'

import InputBoxButtons from '@/components/BasicInputs/InputBoxButtons.vue'
export default {
  name: 'UserGender',
  components: { InputBoxButtons, FitnessTestQuestionLabel, GenderAnswer },
  computed: {
    ...mapState({
      userGender: (state) => state.fitnessTest.questions.userGender,
    }),
  },

  props: {
    questionNumber: {
      type: String,
      default: '',
    },
  },

  methods: {
    setUserGender(value) {
      this.$store.dispatch('setFitnessTestUserGender', value)
    },
  },
}
</script>

<style scoped></style>
