<template>
  <button
    class="py-4 px-8 rounded-xl ring-red-1 ring-0"
    :class="[`${colour}`, largeText ? 'button-1' : 'button-2']"
    :style="customStyle"
    :type="type"
    :disabled="disabled || loading"
    @click="$emit('click')">
    <!--  Unused ring added to try and import the red-1 colour for the hover state  -->
    <slot> Button</slot>
  </button>
</template>

<script>
export default {
  name: 'CustomButton',
  components: {},
  emits: ['click'],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
    colour: {
      type: String,
      default: 'blue',
    },
    largeText: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/themes/themes.scss';

button {
  @apply font-bold;
  cursor: pointer;
}

button:disabled {
  @apply opacity-50;
  pointer-events: none;
  cursor: not-allowed;
}

.red {
  @apply bg-otivo-red border-otivo-red text-white hover:bg-red-1;
}

.white {
  @apply border border-grey-1 hover:bg-grey-4 text-otivo-blue bg-white;
}

.grey {
  @apply text-grey-1 bg-grey-4 rounded-circle hover:bg-grey-3;
}

//default
.blue {
  @apply bg-otivo-blue hover:bg-blue-1 text-white;
}
</style>
