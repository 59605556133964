<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.3255 9.20751C15.0751 9.1215 14.8007 9.13849 14.5628 9.25473C14.3248 9.37098 14.1427 9.57699 14.0565 9.8275C13.1971 12.325 10.7664 14 8.00597 14C6.94071 14.0104 5.88954 13.7559 4.94685 13.2593C4.00416 12.7628 3.19946 12.0397 2.605 11.155H5.60276C5.86778 11.155 6.12195 11.0496 6.30934 10.8621C6.49674 10.6746 6.60202 10.4202 6.60202 10.155C6.60202 9.88979 6.49674 9.63543 6.30934 9.4479C6.12195 9.26036 5.86778 9.15501 5.60276 9.15501H0.01194V14.5575C0.01194 14.8227 0.117218 15.077 0.304615 15.2646C0.492011 15.4521 0.746175 15.5575 1.01119 15.5575C1.27621 15.5575 1.53038 15.4521 1.71777 15.2646C1.90517 15.077 2.01045 14.8227 2.01045 14.5575V13.5575C3.60965 15.1299 5.76406 16.0075 8.00597 16C11.6208 16 14.8109 13.7825 15.945 10.48C15.988 10.3557 16.006 10.2242 15.9981 10.093C15.9902 9.96176 15.9565 9.83337 15.899 9.71518C15.8414 9.59699 15.7611 9.49131 15.6627 9.4042C15.5643 9.31709 15.4497 9.25025 15.3255 9.20751Z"
      :fill="fill1"
      :class="`fill-${fill1}`" />
    <path
      d="M15.0007 0.440066C14.7357 0.440066 14.4816 0.545422 14.2942 0.732957C14.1068 0.920492 14.0015 1.17484 14.0015 1.44006V2.44005C12.4023 0.867879 10.2477 -0.0089992 8.00597 6.96494e-05C4.39117 6.96494e-05 1.20105 2.22005 0.0668991 5.52003C0.0185802 5.64544 -0.00397744 5.77932 0.000573895 5.91366C0.00512524 6.04799 0.0366922 6.18003 0.0933886 6.30188C0.150085 6.42374 0.230747 6.5329 0.330554 6.62285C0.430361 6.7128 0.547264 6.78169 0.674277 6.8254C0.80129 6.86912 0.935806 6.88676 1.06979 6.87727C1.20376 6.86778 1.33446 6.83136 1.45405 6.77018C1.57365 6.70901 1.6797 6.62433 1.76585 6.52121C1.85201 6.41809 1.9165 6.29865 1.95549 6.17002C2.81235 3.67504 5.24303 2.00005 8.00597 2.00005C9.07127 1.98941 10.1225 2.24381 11.0652 2.74039C12.008 3.23698 12.8126 3.96019 13.4069 4.84503H10.4092C10.1442 4.84503 9.88999 4.95039 9.7026 5.13793C9.5152 5.32546 9.40992 5.57981 9.40992 5.84503C9.40992 6.11024 9.5152 6.36459 9.7026 6.55213C9.88999 6.73966 10.1442 6.84502 10.4092 6.84502H16V1.44006C16 1.17484 15.8947 0.920492 15.7073 0.732957C15.5199 0.545422 15.2658 0.440066 15.0007 0.440066Z"
      :fill="fill1"
      :class="`fill-${fill1}`" />
  </svg>
</template>
<script lang="ts" setup>
type Props = {
  fill1?: string
}

withDefaults(defineProps<Props>(), {
  fill1: '#888888',
})
</script>

<style scoped></style>
