<template>
  <div class="media-tiles">
    <div class="fix-width-m-auto">
      <h5 class="text-center text-blue-4 bold mb-[40px]">
        {{ mediaTilesContent.heading }}
      </h5>
      <div class="flex flex-col md:flex-row justify-center gap-[32px]">
        <div
          v-for="(tile, index) in mediaTiles"
          :key="index"
          class="md:w-30% rounded border-1px border-blue-4.5 bg-white md:min-h-[310px] relative mediaShadow"
          @click="redirectLink(tile.link)"
          :class="{ 'cursor-pointer': tile.link !== '' }">
          <div class="text-center pt-4" v-if="tile.type === 'text'">
            <h5>
              {{ tile.body }}
            </h5>
            <h5 class="text-grey-2 uppercase mt-8 md:mt-16">
              {{ tile.reference }}
            </h5>
            <div class="h-12 md:h-20 flex items-end">
              <img
                v-if="tile.logo !== ''"
                class="self-center m-auto px-20 py-2 md:py-4"
                data-aos="fade-up"
                data-aos-duration="900"
                data-aos-once="true"
                :src="tile.logo"
                :alt="tile.reference" />
            </div>
          </div>
          <div v-else-if="tile.type === 'video'">
            <div
              @click="playPauseVideo(tile.title)"
              class="cursor-pointer relative bg-blue-5 p-[20px] rounded-t">
              <img
                :id="'playButton' + tile.title"
                class="cursor-pointer absolute m-auto left-0 right-0 top-0 bottom-0 h-[50px] w-[70px]"
                src="https://store.otivo.com/OtherImages/play_button_faded.png" />
              <video
                :id="'mediaVideo' + tile.title"
                preload="auto"
                height="400"
                class="border-0px cursor-pointer"
                playsinline
                :poster="tile.poster">
                <source :src="tile.body" />
              </video>
            </div>

            <div class="flex justify-between items-center gap-4 h-[55px] m-[20px]">
              <img
                v-if="tile.logo !== ''"
                class="self-center w-[70px]"
                data-aos="fade-up"
                data-aos-duration="900"
                data-aos-once="true"
                :src="tile.logo"
                :alt="tile.reference" />
              <div class="text-right">
                <p class="headline-7 text-grey-2 uppercase">
                  {{ tile.reference }}
                </p>
                <p class="paragraph-4 text-otivo-blue">
                  {{ tile.date }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

type Props = {
  mediaTilesContent?: { heading: string }
}

defineProps<Props>()

const mediaTiles = ref([
  {
    title: 'sunrise',
    type: 'video',
    body: 'https://store.otivo.com/Videos/2023_02_09_Channel_7_Sunrise.mp4',
    reference: 'Sunrise',
    date: 'February 2023',
    logo: 'https://store.otivo.com/Logo/media/grey/sunrise.png',
    poster: 'https://store.otivo.com/images/2023_02_09_Channel_7_Sunrise_02.png',
    link: '',
  },
  {
    title: 'seven_news_brisbane',
    type: 'video',
    body: 'https://store.otivo.com/Videos/2022_09_06_Channel_7_News.mp4',
    reference: '7NEWS Brisbane',
    date: 'September 2022',
    logo: 'https://store.otivo.com/Logo/media/grey/7-news-logo.png',
    poster: 'https://store.otivo.com/images/2023_02_08_Channel_7_Mortgage+Stress.png',
    link: '',
  },
  {
    title: 'sbs',
    type: 'video',
    body: 'https://store.otivo.com/Videos/SBS_compressed.mp4',
    reference: 'SBS World NEWS',
    date: 'April 2022',
    logo: 'https://store.otivo.com/Logo/media/grey/sbs_grey.png',
    poster: 'https://store.otivo.com/images/thumbnail_nathan.png',
    link: '',
  },
])

const redirectLink = (link) => {
  if (link !== '') {
    window.open(link, '_blank')
  }
}

const playPauseVideo = (title) => {
  const video = document.getElementById('mediaVideo' + title)
  const playButton = document.getElementById('playButton' + title)
  video.controls = true
  if (video.paused) playButton.classList.add('hidden')
}
</script>
<style scoped>
.mediaShadow {
  box-shadow: 0 10px 15px 0 rgba(51, 51, 51, 0.15);
}

.mediaShadow:hover {
  box-shadow: 0 10px 15px 0 rgba(51, 51, 51, 0.4);
}
</style>
