<template>
  <section class="max-w-[1100px] mx-auto py-[40px] px-[20px] md:px-[40px]">
    <h1 class="text-otivo-blue">Sample Assets</h1>

    <!--  Icons  -->
    <div class="mt-[40px]">
      <Collapse :default-open="false">
        <template #heading>
          <div>
            <div class="headline-2 underline">icons</div>
            <p class="paragraph-1 mt-[20px]">'@/components/icons/'</p>
          </div>
        </template>
        <template #body>
          <div
            class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-[10px] md:gap-[20px] mt-[40px]">
            <div class="icon-border">
              <MagnifyingGlass class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;MagnifyingGlass /&gt;</p>
            </div>
            <div class="icon-border">
              <AdditionIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;AdditionIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <SubtractionIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;SubtractionIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <MultiplyIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;MultiplyIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <AlertLightning class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;AlertLightning /&gt;</p>
            </div>
            <div class="icon-border">
              <ArrowLeftIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;ArrowLeftIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <ArrowRightIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;ArrowRightIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <ArrowSide class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;ArrowSide /&gt;</p>
            </div>
            <div class="icon-border">
              <ArrowWide class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;ArrowWide /&gt;</p>
            </div>
            <div class="icon-border">
              <ArticleIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;ArticleIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <AssureIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;AssureIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <BankAltIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;BankAltIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <BankIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;BankIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <BasicTick class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;BasicTick /&gt;</p>
            </div>
            <div class="icon-border">
              <BasiqExpensesBgImage class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;BasiqExpensesBgImage /&gt;</p>
            </div>
            <div class="icon-border">
              <BeachUmbrellaIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;BeachUmbrellaIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <BombIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;BombIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <BookIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;BookIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <BroadcastIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;BroadcastIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <BuildingIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;BuildingIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <BurgerManIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;BurgerManIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <CalendarIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;CalendarIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <CardNumberIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;CardNumberIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <CarLoanIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;CarLoanIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <CashflowIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;CashflowIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <CheckIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;CheckIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <ChevronRightIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;ChevronRightIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <CircleCheck class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;CircleCheck /&gt;</p>
            </div>
            <div class="icon-border">
              <CoffeeIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;CoffeeIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <img :src="Collaboration" alt="Collaboration" class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;Collaboration /&gt;</p>
            </div>
            <div class="icon-border">
              <CrownIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;CrownIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <DebtIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;DebtIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <DiamondIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;DiamondIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <DollarIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;DollarIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <DollarIconColour class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;DollarIconColour /&gt;</p>
            </div>
            <div class="icon-border">
              <DollarsIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;DollarsIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <DropdownArrow class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;DropdownArrow /&gt;</p>
            </div>
            <div class="icon-border">
              <EditIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;EditIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <EqualsIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;EqualsIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <FacebookIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;FacebookIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <GmailIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;GmailIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <HolidayIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;HolidayIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <HomeIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;HolidayIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <IncomeAndExpensesIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;IncomeAndExpensesIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <InsuranceColouredIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;InsuranceColouredIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <InsuranceIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;InsuranceIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <LightningBolt class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;LightningBolt /&gt;</p>
            </div>
            <div class="icon-border">
              <LineGraph class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;LineGraph /&gt;</p>
            </div>
            <div class="icon-border">
              <LockIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;LockIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <MoneyBagIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;MoneyBagIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <MonthlyCashFlowIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;MonthlyCashFlowIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <MonthlySpendDetailIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;MonthlySpendDetailIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <MortgageColouredIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;MortgageColouredIcon /&gt;</p>
            </div>

            <div class="icon-border">
              <NeedsWork class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;NeedsWork /&gt;</p>
            </div>
            <div class="icon-border group">
              <NewspaperIcon class="icon-size" hover1="otivo-red" hover2="otivo-blue" />
              <p class="paragraph-2 mt-[10px]">&lt;NewspaperIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <OnTrack class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;OnTrack /&gt;</p>
            </div>
            <div class="icon-border">
              <OtivoIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;OtivoIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <OtivoLogo class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;OtivoLogo /&gt;</p>
            </div>
            <div class="icon-border">
              <OwnAndOweIcon class="icon-size fill-otivo-blue" />
              <p class="paragraph-2 mt-[10px]">&lt;OwnAndOweIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <PartnerIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;PartnerIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <PeopleIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;PeopleIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <PlanIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;PlanIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <PlusIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;PlusIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <PlusIconFilled class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;PlusIconFilled /&gt;</p>
            </div>
            <div class="icon-border">
              <PodcastIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;PodcastIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <ProfileIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;ProfileIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <ReadingIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;ReadingIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <RefreshIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;RefreshIcon /&gt;</p>
            </div>

            <div class="icon-border">
              <RetirementIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;RetirementIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <SaveSun class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;SaveSun /&gt;</p>
            </div>
            <div class="icon-border">
              <SavingsIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;SavingsIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <Scales class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;Scales /&gt;</p>
            </div>
            <div class="icon-border">
              <Secure class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;Secure /&gt;</p>
            </div>
            <div class="icon-border">
              <SquigglyLine fill="blue" class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;SquigglyLine /&gt;</p>
            </div>
            <div class="icon-border">
              <StarMmpMarker class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;StarMmpMarker /&gt;</p>
            </div>
            <div class="icon-border">
              <StopwatchIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;StopwatchIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <SuitcaseIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;SuitcaseIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <SunIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;SunIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <ThereforeIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;ThereforeIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <ThunderPinIcon class="icon-size fill-otivo-blue" />
              <p class="paragraph-2 mt-[10px]">&lt;ThunderPinIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <TooltipIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;TooltipIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <TrashIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;TrashIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <MortgageIcon />
              <p class="paragraph-2 mt-[10px]">&lt;MortgageIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <OwnIcon />
              <p class="paragraph-2 mt-[10px]">&lt;OwnIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <RentingIcon />
              <p class="paragraph-2 mt-[10px]">&lt;RentingIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <QuestionBoxIcon fill="otivo-blue" class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;QuestionBoxIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <DebtPigIcon fill="otivo-blue" class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;DebtPigIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <DefaultIcon fill="otivo-blue" class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;DefaultIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <OnlineCareIcon fill="otivo-blue" class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;OnlineCareIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <QuestionCircle fill="otivo-blue" class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;QuestionCircle /&gt;</p>
            </div>
            <div class="icon-border">
              <SearchIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;SearchIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <ThumbsUpIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;ThumbsUpIcon /&gt;</p>
            </div>
          </div>
        </template>
      </Collapse>
    </div>

    <!--  Socials  -->
    <div class="mt-[40px]">
      <Collapse :default-open="false">
        <template #heading>
          <div>
            <div class="headline-2 underline">Socials</div>
            <p class="paragraph-1 mt-[20px]">'@/components/icons/Socials/'</p>
          </div>
        </template>
        <template #body>
          <div
            class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-[10px] md:gap-[20px] mt-[40px]">
            <div class="icon-border">
              <EmailIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;EmailIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <FBIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;FBIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <LinkedInIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;LinkedInIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <TwitterIcon class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;TwitterIcon /&gt;</p>
            </div>
          </div>
        </template>
      </Collapse>
    </div>

    <!--  Awards  -->
    <div class="mt-[40px]">
      <Collapse :default-open="false">
        <template #heading>
          <div>
            <div class="headline-2 underline">Awards</div>
            <p class="paragraph-1 mt-[20px]">'@/components/icons/Awards/'</p>
          </div>
        </template>
        <template #body>
          <div
            class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[10px] md:gap-[20px] mt-[40px]">
            <div class="icon-border">
              <FinderB2BInnovator2022 background-fill="white" line-fill="grey-1" />
              <p class="paragraph-2 mt-[10px]">&lt;FinderB2BInnovator2022 /&gt;</p>
            </div>
            <div class="icon-border">
              <FinniesWealthManagement2023 background-fill="white" line-fill="grey-1" />
              <p class="paragraph-2 mt-[10px]">&lt;FinniesWealthManagement2023 /&gt;</p>
            </div>
            <div class="icon-border">
              <IFADigitalAdviceStrategy2022 background-fill="white" line-fill="grey-1" />
              <p class="paragraph-2 mt-[10px]">&lt;IFADigitalAdviceStrategy2022 /&gt;</p>
            </div>
            <div class="icon-border">
              <IFADigitalAdviceStrategy2023 background-fill="white" line-fill="grey-1" />
              <p class="paragraph-2 mt-[10px]">&lt;IFADigitalAdviceStrategy2023 /&gt;</p>
            </div>
            <div class="icon-border">
              <IFAInnovatorOfTheYear2022 background-fill="white" line-fill="grey-1" />
              <p class="paragraph-2 mt-[10px]">&lt;IFAInnovatorOfTheYear2022 /&gt;</p>
            </div>
            <div class="icon-border">
              <IFATransformationOfTheYear2022 background-fill="white" line-fill="grey-1" />
              <p class="paragraph-2 mt-[10px]">&lt;IFATransformationOfTheYear2022 /&gt;</p>
            </div>
          </div>
        </template>
      </Collapse>
    </div>

    <!--  SVGs  -->
    <div class="mt-[40px]">
      <Collapse :default-open="false">
        <template #heading>
          <div>
            <div class="headline-2 underline">SVGs</div>
            <p class="paragraph-1 mt-[20px]">'@/components/SVGS/'</p>
          </div>
        </template>
        <template #body>
          <div
            class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[10px] md:gap-[20px] mt-[40px]">
            <div class="icon-border">
              <img :src="AlwaysOpen" alt="AlwaysOpen" class="max-w-[100%]" />
              <p class="paragraph-2 mt-[10px]">&lt;AlwaysOpen /&gt;</p>
            </div>
            <div class="icon-border">
              <img :src="PeopleWithSigns" alt="PeopleWithSigns" class="max-w-[100%]" />
              <p class="paragraph-2 mt-[10px]">&lt;PeopleWithSigns /&gt;</p>
            </div>
            <div class="icon-border">
              <img :src="ZenDudeWithText" alt="ZenDudeWithText" class="max-w-[100%]" />
              <p class="paragraph-2 mt-[10px]">&lt;ZenDudeWithText /&gt;</p>
            </div>
            <div class="icon-border">
              <img :src="ManLoungingInChair" alt="ManLoungingInChair" class="max-w-[100%]" />
              <p class="paragraph-2 mt-[10px]">&lt;ManLoungingInChair /&gt;</p>
            </div>
            <div class="icon-border">
              <img :src="PersonLayingInChair" alt="PersonLayingInChair" class="max-w-[100%]" />
              <p class="paragraph-2 mt-[10px]">&lt;PersonLayingInChair /&gt;</p>
            </div>
            <div class="icon-border">
              <img :src="PersonWithCameraLeft" alt="PersonWithCameraLeft" class="max-w-[100%]" />
              <p class="paragraph-2 mt-[10px]">&lt;PersonWithCameraLeft /&gt;</p>
            </div>
            <div class="icon-border">
              <img :src="PersonWithCameraRight" alt="PersonWithCameraRight" class="max-w-[100%]" />
              <p class="paragraph-2 mt-[10px]">&lt;PersonWithCameraRight /&gt;</p>
            </div>
            <div class="icon-border">
              <img :src="BetterOffPhone" alt="BetterOffPhone" class="max-w-[100%]" />
              <p class="paragraph-2 mt-[10px]">&lt;BetterOffPhone /&gt;</p>
            </div>
            <div class="icon-border">
              <img
                :src="EmployeePagePhoneImage"
                class="max-w-[100%]"
                alt="EmployeePagePhoneImage" />
              <p class="paragraph-2 mt-[10px]">&lt;EmployeePagePhoneImage /&gt;</p>
            </div>
            <div class="icon-border">
              <img :src="LawnChairGang" alt="LawnChairGang" />
              <p class="paragraph-2 mt-[10px]">&lt;LawnChairGang /&gt;</p>
            </div>
            <div class="icon-border">
              <img
                :src="ManWomanCoffeeTableLaptop"
                alt="ManWomanCoffeeTableLaptop"
                class="max-w-[100%]" />
              <p class="paragraph-2 mt-[10px]">&lt;ManWomanCoffeeTableLaptop /&gt;</p>
            </div>
            <div class="icon-border">
              <MediaLogos class="max-w-[100%]" />
              <p class="paragraph-2 mt-[10px]">&lt;MediaLogos /&gt;</p>
            </div>
            <div class="icon-border">
              <img :src="PersonCarryingWeight" alt="PersonCarryingWeight" class="max-w-[100%]" />
              <p class="paragraph-2 mt-[10px]">&lt;PersonCarryingWeight /&gt;</p>
            </div>
            <div class="icon-border">
              <img :src="PersonOnLaptop" alt="PersonOnLaptop" class="max-w-[100%]" />
              <p class="paragraph-2 mt-[10px]">&lt;PersonOnLaptop /&gt;</p>
            </div>
            <div class="icon-border">
              <img :src="PersonOnPhone" alt="PersonOnPhone" class="max-w-[100%]" />
              <p class="paragraph-2 mt-[10px]">&lt;PersonOnPhone /&gt;</p>
            </div>
            <div class="icon-border">
              <img :src="TechHelpWithShadow" alt="TechHelpWithShadow" class="max-w-[100%]" />
              <p class="paragraph-2 mt-[10px]">&lt;TechHelpWithShadow /&gt;</p>
            </div>
            <div class="icon-border">
              <img
                :src="TechHelpWithShadowBlue"
                alt="TechHelpWithShadowBlue"
                class="max-w-[100%]" />
              <p class="paragraph-2 mt-[10px]">&lt;TechHelpWithShadowBlue /&gt;</p>
            </div>
            <div class="icon-border">
              <img :src="TechHelpWithShadowV2" alt="TechHelpWithShadowV2" class="max-w-[100%]" />
              <p class="paragraph-2 mt-[10px]">&lt;TechHelpWithShadowV2 /&gt;</p>
            </div>

            <div class="icon-border">
              <HowFitAreYou />
              <p class="paragraph-2 mt-[10px]">&lt;HowFitAreYou /&gt;</p>
            </div>
            <div class="icon-border">
              <BetaLogo class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;BetaLogo /&gt;</p>
            </div>
            <div class="icon-border">
              <Chevron fill="otivo-blue" class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;Chevron /&gt;</p>
            </div>
            <div class="icon-border">
              <SuperSun fill="otivo-blue" class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;SuperSun /&gt;</p>
            </div>
            <div class="icon-border">
              <ThumbsDown fill="otivo-blue" class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;ThumbsDown /&gt;</p>
            </div>
            <div class="icon-border">
              <ThumbsUp fill="otivo-blue" class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;ThumbsUp /&gt;</p>
            </div>
            <div class="icon-border">
              <UnlinkIcon fill="otivo-blue" class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;UnlinkIcon /&gt;</p>
            </div>
            <div class="icon-border">
              <img :src="FitnessFreedom" alt="FitnessFreedom" />
              <p class="paragraph-2 mt-[10px]">&lt;FitnessFreedom /&gt;</p>
            </div>
            <div class="icon-border">
              <img :src="SecurityTeam" alt="SecurityTeam" class="max-w-[100%]" />
              <p class="paragraph-2 mt-[10px]">&lt;SecurityTeam /&gt;</p>
            </div>
            <div class="icon-border">
              <img :src="ThisCouldBeYou" alt="ThisCouldBeYou" />
              <p class="paragraph-2 mt-[10px]">&lt;ThisCouldBeYou /&gt;</p>
            </div>
            <div class="icon-border">
              <img :src="BornOnThe5thofGeoff19JephityJeff" alt="BornOnThe5thofGeoff19JephityJeff" />
              <p class="paragraph-2 mt-[10px]">&lt;BornOnThe5thofGeoff19JephityJeff /&gt;</p>
            </div>
            <div class="icon-border">
              <img :src="CoffeeLaptop" alt="CoffeeLaptop" />
              <p class="paragraph-2 mt-[10px]">&lt;CoffeeLaptop /&gt;</p>
            </div>
          </div>
        </template>
      </Collapse>
    </div>

    <!--  OtivoIcons  -->
    <div class="mt-[40px]">
      <Collapse>
        <template #heading>
          <div>
            <div class="headline-2 underline">OtivoIcons</div>
            <p class="paragraph-1 mt-[20px]">'@/components/OtivoIcons/'</p>
          </div>
        </template>
        <template #body>
          <div
            class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[10px] md:gap-[20px] mt-[40px]">
            <div class="icon-border">
              <OtivoIconBase class="self-center" width="40px" height="40px">
                <IconDashboard />
              </OtivoIconBase>
              <p class="paragraph-2 mt-[10px]">&lt;IconDashboard /&gt;</p>
            </div>
            <div class="icon-border">
              <IconDebt class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;IconDebt /&gt;</p>
            </div>
            <div class="icon-border">
              <IconInsurance class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;IconInsurance /&gt;</p>
            </div>
            <div class="icon-border">
              <IconRetire class="icon-size" />
              <p class="paragraph-2 mt-[10px]">&lt;IconRetire /&gt;</p>
            </div>
            <div class="icon-border">
              <OtivoIconBase class="self-center" width="40px" height="40px">
                <IconSaving />
              </OtivoIconBase>
              <p class="paragraph-2 mt-[10px]">&lt;IconSaving /&gt;</p>
            </div>
          </div>
        </template>
      </Collapse>
    </div>

    <!--  MegaMenu  -->
    <div class="mt-[40px]">
      <Collapse>
        <template #heading>
          <div>
            <div class="headline-2 underline">MegaMenu</div>
            <p class="paragraph-1 mt-[20px]">'@/components/OtivoIcons/MegaMenu/'</p>
          </div>
        </template>
        <template #body>
          <div
            class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[10px] md:gap-[20px] mt-[40px]">
            <div class="icon-border">
              <OtivoIconBase class="self-center" view-box="0 0 80 60" width="80px" height="80px">
                <CreditCardTile class="icon-size" />
              </OtivoIconBase>
              <p class="paragraph-2 mt-[10px]">&lt;CreditCardTile /&gt;</p>
            </div>
            <div class="icon-border">
              <OtivoIconBase class="self-center" view-box="0 0 80 100" width="80px" height="80px">
                <DebtTile class="icon-size" />
              </OtivoIconBase>
              <p class="paragraph-2 mt-[10px]">&lt;DebtTile /&gt;</p>
            </div>
            <div class="icon-border">
              <OtivoIconBase class="self-center" view-box="0 0 100 100" width="80px" height="80px">
                <HandMoneyTile fill="#D4E7FE" class="icon-size" />
              </OtivoIconBase>
              <p class="paragraph-2 mt-[10px]">&lt;HandMoneyTile /&gt;</p>
            </div>
            <div class="icon-border">
              <OtivoIconBase class="self-center" view-box="0 0 80 80" width="80px" height="80px">
                <HomeLoanTile class="icon-size" />
              </OtivoIconBase>
              <p class="paragraph-2 mt-[10px]">&lt;HomeLoanTile /&gt;</p>
            </div>
            <div class="icon-border">
              <OtivoIconBase class="self-center" view-box="0 0 60 120" width="80px" height="80px">
                <InsuranceTile class="icon-size" />
              </OtivoIconBase>
              <p class="paragraph-2 mt-[10px]">&lt;InsuranceTile /&gt;</p>
            </div>
            <div class="icon-border">
              <OtivoIconBase class="self-center" view-box="0 0 20 100" width="80px" height="80px">
                <RetirementTile class="icon-size" />
              </OtivoIconBase>
              <p class="paragraph-2 mt-[10px]">&lt;RetirementTile /&gt;</p>
            </div>
            <div class="icon-border">
              <OtivoIconBase class="self-center" view-box="0 0 60 120" width="80px" height="80px">
                <SavingsTile class="icon-size" />
              </OtivoIconBase>
              <p class="paragraph-2 mt-[10px]">&lt;SavingsTile /&gt;</p>
            </div>
            <div class="icon-border">
              <OtivoIconBase class="self-center" view-box="0 0 80 80" width="80px" height="80px">
                <SharesTile fill1="#D4E7FE" fill2="#D4E7FE" class="icon-size" />
              </OtivoIconBase>
              <p class="paragraph-2 mt-[10px]">&lt;SharesTile /&gt;</p>
            </div>
            <div class="icon-border">
              <OtivoIconBase class="self-center" view-box="0 0 80 80" width="80px" height="80px">
                <SuperTile fill1="#D4E7FE" fill2="#D4E7FE" class="icon-size" />
              </OtivoIconBase>
              <p class="paragraph-2 mt-[10px]">&lt;SuperTile /&gt;</p>
            </div>
          </div>
        </template>
      </Collapse>
    </div>
  </section>
</template>

<script lang="ts" setup>
import Collapse from '@/components/SaveAndCollapse/Collapse.vue'
import AdditionIcon from '@/components/icons/AdditionIcon.vue'
import AlertLightning from '@/components/icons/AlertLightning.vue'
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue'
import ArrowRightIcon from '@/components/icons/ArrowRightIcon.vue'
import ArrowSide from '@/components/icons/ArrowSide.vue'
import ArrowWide from '@/components/icons/ArrowWide.vue'
import ArticleIcon from '@/components/icons/ArticleIcon.vue'
import AssureIcon from '@/components/icons/AssureIcon.vue'
import BankAltIcon from '@/components/icons/BankAltIcon.vue'
import BankIcon from '@/components/icons/BankIcon.vue'
import BasicTick from '@/components/icons/BasicTick.vue'
import BasiqExpensesBgImage from '@/components/icons/BasiqExpensesBgImage.vue'
import BeachUmbrellaIcon from '@/components/icons/BeachUmbrellaIcon.vue'
import BombIcon from '@/components/icons/BombIcon.vue'
import BookIcon from '@/components/icons/BookIcon.vue'
import BroadcastIcon from '@/components/icons/BroadcastIcon.vue'
import BuildingIcon from '@/components/icons/BuildingIcon.vue'
import BurgerManIcon from '@/components/icons/BurgerManIcon.vue'
import CalendarIcon from '@/components/icons/CalendarIcon.vue'
import CardNumberIcon from '@/components/icons/CardNumberIcon.vue'
import CarLoanIcon from '@/components/icons/CarLoanIcon.vue'
import CashflowIcon from '@/components/icons/CashflowIcon.vue'
import CheckIcon from '@/components/icons/CheckIcon.vue'
import ChevronRightIcon from '@/components/icons/ChevronRightIcon.vue'
import CircleCheck from '@/components/icons/CircleCheck.vue'
import CoffeeIcon from '@/components/icons/CoffeeIcon.vue'
import Collaboration from '@/components/icons/Collaboration.svg?url'
import CrownIcon from '@/components/icons/CrownIcon.vue'
import DebtIcon from '@/components/icons/DebtIcon.vue'
import DiamondIcon from '@/components/icons/DiamondIcon.vue'
import DollarIcon from '@/components/icons/DollarIcon.vue'
import DollarIconColour from '@/components/icons/DollarIconColour.vue'
import DollarsIcon from '@/components/icons/DollarsIcon.vue'
import DropdownArrow from '@/components/icons/DropdownArrow.vue'
import EditIcon from '@/components/icons/EditIcon.vue'
import FacebookIcon from '@/components/icons/FacebookIcon.vue'
import GmailIcon from '@/components/icons/GmailIcon.vue'
import HomeIcon from '@/components/icons/HomeIcon.vue'
import EqualsIcon from '@/components/icons/EqualsIcon.vue'
import HolidayIcon from '@/components/icons/HolidayIcon.vue'
import IncomeAndExpensesIcon from '@/components/icons/IncomeAndExpensesIcon.vue'
import InsuranceColouredIcon from '@/components/icons/InsuranceColouredIcon.vue'
import InsuranceIcon from '@/components/icons/InsuranceIcon.vue'
import LightningBolt from '@/components/icons/LightningBolt.vue'
import LineGraph from '@/components/icons/LineGraph.vue'
import LockIcon from '@/components/icons/LockIcon.vue'
import MoneyBagIcon from '@/components/icons/MoneyBagIcon.vue'
import MonthlyCashFlowIcon from '@/components/icons/MonthlyCashFlowIcon.vue'
import MonthlySpendDetailIcon from '@/components/icons/MonthlySpendDetailIcon.vue'
import MortgageColouredIcon from '@/components/icons/MortgageColouredIcon.vue'
import MortgageIcon from '@/components/icons/MortgageIcon.vue'
import NeedsWork from '@/components/icons/NeedsWork.vue'
import NewspaperIcon from '@/components/icons/NewspaperIcon.vue'
import OnTrack from '@/components/icons/OnTrack.vue'
import OtivoIcon from '@/components/icons/OtivoIcon.vue'
import OtivoLogo from '@/components/icons/OtivoLogo.vue'
import OwnAndOweIcon from '@/components/icons/OwnAndOweIcon.vue'
import OwnIcon from '@/components/icons/OwnIcon.vue'
import PartnerIcon from '@/components/icons/PartnerIcon.vue'
import PeopleIcon from '@/components/icons/PeopleIcon.vue'
import PlanIcon from '@/components/icons/PlanIcon.vue'
import PlusIcon from '@/components/icons/PlusIcon.vue'
import PodcastIcon from '@/components/icons/PodcastIcon.vue'
import ProfileIcon from '@/components/icons/ProfileIcon.vue'
import ReadingIcon from '@/components/icons/ReadingIcon.vue'
import RefreshIcon from '@/components/icons/RefreshIcon.vue'
import RentingIcon from '@/components/icons/RentingIcon.vue'
import RetirementIcon from '@/components/icons/RetirementIcon.vue'
import SaveSun from '@/components/icons/SaveSun.vue'
import SavingsIcon from '@/components/icons/SavingsIcon.vue'
import Scales from '@/components/icons/Scales.vue'
import Secure from '@/components/icons/Secure.vue'
import SquigglyLine from '@/components/icons/SquigglyLine.vue'
import StarMmpMarker from '@/components/icons/StarMmpMarker.vue'
import StopwatchIcon from '@/components/icons/StopwatchIcon.vue'
import SuitcaseIcon from '@/components/icons/SuitcaseIcon.vue'
import SunIcon from '@/components/icons/SunIcon.vue'
import ThereforeIcon from '@/components/icons/ThereforeIcon.vue'
import ThunderPinIcon from '@/components/icons/ThunderPinIcon.vue'
import TooltipIcon from '@/components/icons/TooltipIcon.vue'
import FinderB2BInnovator2022 from '@/components/icons/Awards/FinderB2BInnovator2022.vue'
import FinniesWealthManagement2023 from '@/components/icons/Awards/FinniesWealthManagement2023.vue'
import IFADigitalAdviceStrategy2022 from '@/components/icons/Awards/IFADigitalAdviceStrategy2022.vue'
import IFADigitalAdviceStrategy2023 from '@/components/icons/Awards/IFADigitalAdviceStrategy2023.vue'
import IFAInnovatorOfTheYear2022 from '@/components/icons/Awards/IFAInnovatorOfTheYear2022.vue'
import IFATransformationOfTheYear2022 from '@/components/icons/Awards/IFATransformationOfTheYear2022.vue'
import FitnessFreedom from '@/components/SVGS/FitnessFreedom.svg?url'
import SecurityTeam from '@/components/SVGS/SecurityTeam.svg?url'
import ThisCouldBeYou from '@/components/SVGS/ThisCouldBeYou.svg?url'
import EmailIcon from '@/components/icons/Socials/EmailIcon.vue'
import FBIcon from '@/components/icons/Socials/FBIcon.vue'
import LinkedInIcon from '@/components/icons/Socials/LinkedInIcon.vue'
import TwitterIcon from '@/components/icons/Socials/TwitterIcon.vue'
import AlwaysOpen from '@/components/SVGS/AlwaysOpen.svg?url'
import BetaLogo from '@/components/SVGS/BetaLogo.vue'
import BetterOffPhone from '@/components/BrandComponents/Otivo/Personal/BetterOffPhone.vue'
import Chevron from '@/components/SVGS/Chevron.vue'
import HowFitAreYou from '@/components/SVGS/HowFitAreYou.vue'
import LawnChairGang from '@/components/SVGS/LawnChairGang.svg?url'
import ManLoungingInChair from '@/components/SVGS/ManLoungingInChair.svg?url'
import ManWomanCoffeeTableLaptop from '@/components/SVGS/ManWomanCoffeeTableLaptop.svg?url'
import MediaLogos from '@/components/SVGS/MediaLogos.vue'
import PersonCarryingWeight from '@/components/SVGS/PersonCarryingWeight.svg?url'
import PersonLayingInChair from '@/components/SVGS/PersonLayingInChair.svg?url'
import PersonOnLaptop from '@/components/SVGS/PersonOnLaptop.svg?url'
import PersonOnPhone from '@/components/SVGS/PersonOnPhone.svg?url'
import SuperSun from '@/components/SVGS/SuperSun.vue'
import TechHelpWithShadow from '@/components/SVGS/TechHelpWithShadow.svg?url'
import TechHelpWithShadowBlue from '@/components/SVGS/TechHelpWithShadowBlue.svg?url'
import TechHelpWithShadowV2 from '@/components/SVGS/TechHelpWithShadowV2.svg?url'
import ThumbsDown from '@/components/SVGS/ThumbsDown.vue'
import ThumbsUp from '@/components/SVGS/ThumbsUp.vue'
import UnlinkIcon from '@/components/SVGS/UnlinkIcon.vue'
import ZenDudeWithText from '@/components/SVGS/ZenDudeWithText.svg?url'
import PeopleWithSigns from '@/components/SVGS/PeopleWithSigns.svg?url'
import PersonWithCameraLeft from '@/components/SVGS/PersonWithCameraLeft.svg?url'
import PersonWithCameraRight from '@/components/SVGS/PersonWithCameraRight.svg?url'
import EmployeePagePhoneImage from '@/components/SVGS/EmployeePagePhoneImage.svg?url'
import IconDebt from '@/components/OtivoIcons/IconDebt.vue'
import IconInsurance from '@/components/OtivoIcons/IconInsurance.vue'
import IconRetire from '@/components/OtivoIcons/IconRetire.vue'
import IconSaving from '@/components/OtivoIcons/IconSaving.vue'
import OtivoIconBase from '@/components/OtivoIcons/OtivoIconBase.vue'
import IconDashboard from '@/components/OtivoIcons/IconDashboard.vue'
import CreditCardTile from '@/components/OtivoIcons/MegaMenu/CreditCardTile.vue'
import DebtTile from '@/components/OtivoIcons/MegaMenu/DebtTile.vue'
import HandMoneyTile from '@/components/OtivoIcons/MegaMenu/HandMoneyTile.vue'
import HomeLoanTile from '@/components/OtivoIcons/MegaMenu/HomeLoanTile.vue'
import InsuranceTile from '@/components/OtivoIcons/MegaMenu/InsuranceTile.vue'
import RetirementTile from '@/components/OtivoIcons/MegaMenu/RetirementTile.vue'
import SavingsTile from '@/components/OtivoIcons/MegaMenu/SavingsTile.vue'
import SharesTile from '@/components/OtivoIcons/MegaMenu/SharesTile.vue'
import SuperTile from '@/components/OtivoIcons/MegaMenu/SuperTile.vue'
import SubtractionIcon from '@/components/icons/SubtractionIcon.vue'
import PlusIconFilled from '@/components/icons/PlusIconFilled.vue'
import TrashIcon from '@/components/icons/TrashIcon.vue'
import QuestionBoxIcon from '@/components/icons/QuestionBoxIcon.vue'
import MagnifyingGlass from '@/components/SVGS/MagnifyingGlass.vue'
import BornOnThe5thofGeoff19JephityJeff from '@/components/SVGS/BornOnThe5thofGeoff19JephityJeff.svg?url'
import CoffeeLaptop from '@/components/SVGS/CoffeeLaptopLady.svg?url'
import MultiplyIcon from '@/components/icons/MultiplyIcon.vue'
import QuestionCircle from '@/components/icons/QuestionCircle.vue'
import SearchIcon from '@/components/icons/SearchIcon.vue'
import DebtPigIcon from '@/components/icons/DebtPigIcon.vue'
import DefaultIcon from '@/components/icons/DefaultIcon.vue'
import OnlineCareIcon from '@/components/icons/OnlineCareIcon.vue'
import ThumbsUpIcon from '@/components/icons/ThumbsUpIcon.vue'
</script>

<style lang="scss" scoped>
.icon-border {
  @apply bg-white flex flex-col items-center justify-between p-[10px] rounded;
}

.icon-size {
  width: 42px;
  height: 42px;
}

.icon-size:hover {
  transform: scale(1.1);
}
</style>
