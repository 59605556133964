<template>
  <div id="USPs">
    <h3
      v-if="uspData.heading"
      class="headline-3 font-bold text-center px-[20px] md:px-0"
      v-html="uspData.heading" />
    <div
      v-if="uspData.subHeading"
      class="intro-1 mt-[40px] text-[18px] text-center leading-[150%] font-thin px-[20px] md:px-0"
      v-html="uspData.subHeading" />
    <slot name="banner-slot" />
    <div
      class="fix-width-m-auto flex flex-wrap justify-center gap-8 text-center px-[20px] md:px-0 py-20">
      <div
        :key="index"
        v-for="(usp, index) in uspData.iterableItems"
        class="w-[380px] h-[300px] rounded-lg border border-blue-4.5 p-11.5 pt-14 otivo-shadow">
        <component
          :is="icons[index]"
          :fill1="fill1"
          :fill2="fill2"
          class="m-auto w-12 h-12"
          width="42"
          height="43" />
        <div class="mh6 md:h6 text-xl font-bold mt-6">
          {{ usp.heading }}
        </div>
        <p class="paragraph-2 mt-3">
          {{ usp.body }}
        </p>
      </div>
    </div>
    <div class="text-center mt-10" v-if="displayHowItWorks">
      **$319K?
      <span class="underline cursor-pointer" @click="openHowWeWorkThisOutModal"
        >Here’s how we worked this out</span
      >
    </div>
    <OtivoButton
      class="mx-auto"
      typography="button-1"
      size="medium"
      @click="$emit('button-clicked')">
      {{ uspData.buttonText }}
    </OtivoButton>
  </div>
</template>

<script lang="ts" setup>
import { IterableItemDefaultType } from '@/types/globalComponents/IterableItemDefaultType'
import OtivoButton from '@/components/OtivoButton.vue'
import HowWeWorkItOutModal from '@/components/BrandComponents/Otivo/HowWeWorkItOutModal.vue'
import { useModalStore } from '@/store/pinia/ModalStore.ts'

type USPData = {
  heading: string
  subHeading?: string
  buttonText: string
  iterableItems: Array<IterableItemDefaultType>
}

interface Props {
  uspData: USPData
  icons: Array<unknown>
  fill1?: string
  fill2?: string
  displayHowItWorks?: boolean
}

defineEmits(['button-clicked'])

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red',
  displayHowItWorks: false,
})

const modalStore = useModalStore()
const openHowWeWorkThisOutModal = () => {
  modalStore.openModal(HowWeWorkItOutModal, {})
}
</script>
