<template>
  <div>
    <div class="flex h-full">
      <custom-check-box
        :size="size"
        class="self-center check-box"
        :checked="checked"
        :name="name + ' checkbox'"
        :disabled="disabled"
        @click="toggleSelected"
        @keyup.enter="toggleSelected"
        :tabindex="0" />
      <input-label :label="label" class="self-center pl-2" :for="`${name}-label`" />
    </div>
    <div>
      <input-error v-if="error" :error="error" class="text-center" />
    </div>
  </div>
</template>

<script>
import InputError from '@/components/BasicInputs/InputError.vue'
import CustomCheckBox from '@/components/BasicInputs/CustomCheckBox/CustomCheckBox.vue'
import InputLabel from '@/components/BasicInputs/InputLabel.vue'
export default {
  name: 'InputCheckBox',

  components: {
    InputLabel,
    CustomCheckBox,
    InputError,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: null,
    },
  },
  emits: ['click', 'keyup.enter'],
  methods: {
    toggleSelected(value) {
      this.$emit('click', value)
      // typeof (value) === 'boolean' ? this.$emit('click', value) : this.$emit('click', value === 'on')
    },
  },
}
</script>
