<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 47211">
      <path
        id="Vector"
        d="M20.7177 10.9375C20.7177 4.79688 17.1058 0 12.5088 0C7.91184 0 4.29993 4.79688 4.29993 10.9375C4.29993 17.0781 7.91184 21.875 12.5088 21.875C17.1058 21.875 20.7177 17.0625 20.7177 10.9375ZM12.5088 18.75C9.75688 18.75 7.42712 15.1719 7.42712 10.9375C7.42712 6.70312 9.75688 3.125 12.5088 3.125C15.2607 3.125 17.5905 6.70312 17.5905 10.9375C17.5905 15.1719 15.2607 18.75 12.5088 18.75Z"
        :class="`fill-${fill1}`" />
      <path
        id="Vector_2"
        d="M24.2668 16.3437C23.5319 15.8906 22.5781 16.125 22.1247 16.8593C21.9996 17.0625 18.935 21.875 12.5086 21.875C6.08218 21.875 3.01753 17.0625 2.89244 16.875C2.439 16.1406 1.4852 15.9062 0.750312 16.3437C0.0154214 16.7968 -0.219118 17.75 0.218689 18.4843C0.375049 18.75 4.26841 25 12.4929 25C20.7175 25 24.6108 18.75 24.7672 18.4843C25.2206 17.75 24.9861 16.7968 24.2512 16.3437H24.2668Z"
        :class="`fill-${fill2}`" />
      <path
        id="Vector_3"
        d="M14.0725 9.76562V8.59375C14.0725 7.73438 13.3689 7.03125 12.5089 7.03125C11.6489 7.03125 10.9453 7.73438 10.9453 8.59375V9.76562H9.77258C8.9126 9.76562 8.20898 10.4688 8.20898 11.3281C8.20898 12.1875 8.9126 12.8906 9.77258 12.8906H10.9453V14.0625C10.9453 14.9219 11.6489 15.625 12.5089 15.625C13.3689 15.625 14.0725 14.9219 14.0725 14.0625V12.8906H15.2452C16.1052 12.8906 16.8088 12.1875 16.8088 11.3281C16.8088 10.4688 16.1052 9.76562 15.2452 9.76562H14.0725Z"
        :class="`fill-${fill3}`" />
    </g>
  </svg>
</template>

<script setup lang="ts">
interface Props {
  fill1?: string
  fill2?: string
  fill3?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red',
  fill3: 'otivo-red',
})
</script>
