<template>
  <div>
    <custom-button :colour="colour" @click="toggleModal" class="px-4 w-full">
      {{ buttonText }}
    </custom-button>
    <modal v-if="showModal" @close="toggleModal">
      <template #header>
        <h3 class="p-4 md:px-10 md:pt-14 md:pb-0">
          Send us your email, and we’ll send you a FREE trial.
        </h3>
      </template>
      <template #body>
        <div class="px-4 md:px-10">
          <div class="pb-4">Your future self will thank you for it.</div>
          <div class="md:flex md:gap-16 pr-4 items-center">
            <input-text
              name="email"
              type="email"
              place-holder="Your email"
              :value="this.email"
              @input="updateEmail"
              class="py-4 w-full md:text-left"
              :allow-digits="true" />
            <custom-button class="w-full md:w-72 mb1 md:b1" @click="submitEmail">
              Lets do this
            </custom-button>
          </div>
          <img
            :src="svg"
            alt="Man Pointing at Phone"
            class="w-full md:w-80% m-auto -mb-14 md:pt-8" />
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from '../../../Modals/Modal'
import CustomButton from '../../../CustomButton/CustomButton'
import ReadingIcon from '@/components/icons/ReadingIcon.vue'
import InputText from '@/components/BasicInputs/InputText.vue'
import MiscellaneousService from '@/services/MiscellaneousService'
import BornOnThe5thofGeoff19JephityJeff from '@/components/SVGS/BornOnThe5thofGeoff19JephityJeff.svg?url'
import { useToast } from '@/composables/useToast.ts'

const { successToast, errorToast } = useToast()

export default {
  name: 'FreeTrialEmailModal',
  components: { InputText, ReadingIcon, CustomButton, Modal },
  props: {
    colour: {
      type: String,
      default: 'red',
    },
    buttonText: {
      type: String,
      default: 'Sign up for a free trial',
    },
  },
  data() {
    return {
      email: '',
      showModal: false,
      svg: BornOnThe5thofGeoff19JephityJeff,
    }
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal
    },
    updateEmail(value) {
      this.email = value
    },
    submitEmail() {
      MiscellaneousService.postInterestedUser({ email: this.email })
        .then((res) => {
          if (res.data.error) {
            errorToast('Something went wrong. Email must be valid.')
          } else {
            this.toggleModal()
            successToast('Subscribed')
          }
        })
        .catch((e) => {
          errorToast('Something went wrong. Please try again.')
        })
    },
  },
}
</script>

<style scoped></style>
