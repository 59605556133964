<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.3 42.292H2.615A2.614 2.614 0 0 1 0 39.676v-32.7A2.617 2.617 0 0 1 2.615 4.36h7.19a2.614 2.614 0 0 1 1.85 4.466c-.491.49-1.156.766-1.85.766H5.23V37.06h27.455v-4.578a2.617 2.617 0 0 1 4.463-1.85c.49.49.766 1.156.766 1.85v7.194a2.617 2.617 0 0 1-2.614 2.616Z"
      :class="`fill-${fill1}`" />
    <path
      d="M14.65 32.188h-4.53v-4.474a12.7 12.7 0 0 1 3.732-9.038L32.234.292l1.307.163a9.647 9.647 0 0 1 8.296 8.32L42 10.081 23.625 28.447a12.784 12.784 0 0 1-8.976 3.74ZM33.991 5.956l-16.44 16.422a7.51 7.51 0 0 0-2.164 4.532 7.543 7.543 0 0 0 4.537-2.165l16.4-16.461a4.4 4.4 0 0 0-2.333-2.328Z"
      :class="`fill-${fill2}`" />
  </svg>
</template>

<script lang="ts" setup>
type Props = {
  fill1?: string
  fill2?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red',
})
</script>

<style scoped></style>
