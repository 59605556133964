<template>
  <div class="mt-1 flex gap-4">
    <input
      :type="type"
      class="focusHere"
      :id="name"
      :name="name"
      :checked="checked"
      @click="$emit('click', $event.target.checked)"
      :disabled="disabled" />
    <label class="min-w-8 max-w-8 min-h-8 max-h-8" :for="name" />

    <input-label :label="label" class="paragraph-1" />
  </div>
</template>

<script>
import InputLabel from '@/components/BasicInputs/InputLabel.vue'
export default {
  name: 'SmallCheckbox',
  components: { InputLabel },
  props: {
    name: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'checkbox',
    },
    label: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>
input[type='checkbox'] + label {
  display: block;
  cursor: pointer;
}

input[type='checkbox'] {
  display: none;
}
input[type='checkbox'] + label:before {
  @apply border-otivo-blue;
}
input[type='checkbox'] + label:before {
  content: url('https://store.otivo.com/OtherImages/small_tick.png');
  border: 0.1em solid #0064ff;
  border-radius: 0.4em;
  display: table-cell;
  width: 1em;
  height: 1em;
  margin-right: 0.2em;
  vertical-align: middle;
  color: transparent;
  transition: 0.2s;
  text-align: center;
  padding: 0.1em;
}

input[type='checkbox'] + label:active:before {
  transform: scale(0);
}

input[type='checkbox']:checked + label:before {
  /* Note :: change bg color in the components */
  @apply bg-otivo-blue border-otivo-blue;
  color: #fff;
  width: 1em;
  height: 1em;
  padding: 0.1em;
}
</style>
