<template>
  <div
    v-if="selectedOption"
    :class="selectedOption.style[statusStyle].style"
    class="flex items-center gap-[4px] h-fit w-fit px-[3px] py-[2px]">
    <component
      class="w-3"
      v-if="selectedOption.icon"
      :is="selectedOption.icon"
      :fill1="selectedOption.style[statusStyle].fill1"
      :fill2="selectedOption.style[statusStyle].fill2" />
    {{ selectedOption.text }} {{ dateActioned }}
  </div>
</template>

<script lang="ts" setup>
import { ModuleStatus, useModuleStatusesStore } from '@/store/pinia/ModuleStatusesStore.ts'
import { computed } from 'vue'

type Props = {
  state: ModuleStatus | string
  statusStyle?: 'solid' | 'outline'
  dateActioned?: string
}

const props = withDefaults(defineProps<Props>(), {
  statusStyle: 'solid',
  dateActioned: '',
})

const options = useModuleStatusesStore().options
const selectedOption = computed(() => options[props.state] ?? null)
</script>

<style scoped></style>
