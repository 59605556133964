<template>
  <basic-input class="gap-3" :multi-line="multiLine">
    <template #input-label>
      <div
        class="mt-4 text-left gap-1 paragraph-2 flex flex-row"
        :class="multiLine ? 'mb-4' : 'set-w-48'"
        v-if="label">
        <input-label :label="label" :for="name" />
        <info-circle class="button-2 ml-2" :message="title" />
      </div>
    </template>
    <template #input-box>
      <div class="w-full text-center flex gap-2 md:gap-4 justify-center flex-wrap md:flex-nowrap">
        <template v-for="(element, index) in buttons" :key="'addInvestment' + index">
          <div
            class="py-4 px-2 rounded justify-around button-2 bg-white w-full flex"
            :class="[
              getClassList(element.value, index),
              { animatedBackground: enableAnimatedBackground },
            ]"
            @click="onButtonClick(element.value)"
            @keyup.enter="onButtonClick(element.value)"
            @keyup.space="onButtonClick(element.value)"
            :tabindex="0">
            <div class="align-middle self-center">
              <span v-html="element.name" />
              <input-error-icon :error="element.error" />
            </div>
          </div>
        </template>
      </div>
    </template>
    <template #input-errors>
      <input-error class="text-center pb-4" :error="error" />
    </template>
  </basic-input>
</template>
<script>
/**
 * TODO: MAJOR REFACTOR
 */
import InfoCircle from '@/components/InfoCircle/InfoCircle.vue'
import InputError from '@/components/BasicInputs/InputError.vue'
import InputErrorIcon from '@/components/BasicInputs/InputErrorIcon.vue'
import InputLabel from '@/components/BasicInputs/InputLabel.vue'
import BasicInput from '@/components/BasicInputs/BasicInput.vue'

export default {
  name: 'InputBoxButtons',
  components: { BasicInput, InputError, InputLabel, InfoCircle, InputErrorIcon },
  emits: ['click'],
  data() {
    return {
      active: '',
    }
  },
  props: {
    error: {
      type: String,
      default: '',
    },
    colourIndex: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    minWidth: {
      type: String,
      default: null,
    },
    maxWidth: {
      type: String,
      default: null,
    },
    enableAnimatedBackground: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Array,
      default() {
        return [
          { name: 'Button 1', value: 'b1' },
          { name: 'Button 2', value: 'b2' },
          { name: 'Button 3', value: 'b3' },
          { name: 'Button 4', value: 'b4' },
          { name: 'Button 5', value: 'b5' },
        ]
      },
    },
    locked: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: [Number, String, Boolean],
      default: '',
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dynamicColor: {
      type: String,
      default: null,
    },
    multiLine: {
      type: Boolean,
      default: false,
    },
    applyClasses: {
      type: Array,
      default: () => [],
    },
    borderRing: {
      type: Boolean,
      default: true,
    },
    textColour: {
      type: String,
      default: 'text-grey-1',
    },
    disabledButtons: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    onButtonClick(value) {
      if (!this.locked) {
        this.$emit('click', value)
      }
    },
    getClassList: function (value, index, element) {
      const classList = {}

      this.applyClasses.forEach((newclass) => {
        classList[newclass] = true
      })

      if (this.maxWidth !== null) {
        classList[`max-w-${this.maxWidth}`] = true
      }

      if (this.minWidth !== null) {
        classList[`min-w-${this.minWidth}`] = true
      }

      if (this.disabled) {
        classList['bg-grey-light'] = true
        classList['border-grey-light'] = true
        classList['bg-grey-cursor-not-allowed'] = true
        classList['pointer-events-none'] = true
      } else {
        classList['cursor-pointer'] = true
      }

      // separate classes for buttons..
      if (this.colourIndex && this.colourIndex[index]) {
        const classes = this.colourIndex[index].split(' ')
        classes.forEach((css) => {
          classList[css] = true
        })
        classList['shadow-lg'] = value === this.selected
        value === this.selected
          ? (classList['opacity-100'] = true)
          : (classList['opacity-50'] = true)
      } else {
        classList.border = this.borderRing
        if (!this.enableAnimatedBackground) classList.ring = this.borderRing
        if (value === this.selected) {
          if (!this.enableAnimatedBackground) classList['ring-grey-4'] = true
          classList['border-otivo-blue'] = true
          classList['text-otivo-blue'] = true
          classList['bg-blue-5'] = true
          if (this.enableAnimatedBackground) classList.animateSelected = true
          else classList.selected = true
        } else if (this.disabledButtons.includes(value)) {
          classList['bg-grey-light'] = true
          classList['border-grey-light'] = true
          classList['bg-grey-cursor-not-allowed'] = true
          classList['pointer-events-none'] = true
          classList['ring-transparent'] = true
        } else {
          classList['ring-transparent'] = true
          classList['border-grey-3'] = true
          classList[this.textColour] = true
          // classList['bg-transparent'] = true // why was this here?
        }
      }
      return classList
    },
  },
}
</script>

<style lang="scss" scoped>
.animatedBackground {
  position: relative;
  transition: 200ms ease;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    border-radius: 9px;
    top: 0;
    left: 50%;
    width: 0;
    height: 100%;
    background: var(--otivo-blue);
    z-index: -1;
    transition: 400ms cubic-bezier(0.47, 1.44, 0.31, 1);
  }
}

.animateSelected {
  background: none;
  color: white;
  z-index: 2;
  border: none;

  &.animatedBackground {
    &:before {
      left: 0;
      top: 0;
      width: 100%;
      scale: 1.05;
    }
  }
}

///* Set Custom Properties Here */
//@supports (background: paint(houdini)) {
//  @property --gradPoint {
//    syntax: '<percentage>';
//    inherits: false;
//    initial-value: 0%;
//  }
//}
//
//.animatedBackground {
//  --gradPoint: 0%;
//  background: white; /* fallback value */
//  background: linear-gradient(360deg, var(--otivo-blue) var(--gradPoint), white var(--gradPoint));
//  transition: --gradPoint 0.2s, filter 0.8s;
//}
//
//@supports (background: paint(houdini)) {
//  .selected {
//    &.animatedBackground {
//      --gradPoint: 100%;
//      font-weight: bold;
//      color: white;
//    }
//  }
//}
</style>
