import { createStore } from 'vuex'
import profile from '@/store/modules/Profile.js'
import signUp from '@/store/modules/SignUp.js'
import navBar from '@/store/modules/NavBar.js'
import notifications from '@/store/modules/Notifications.js'
import monthlyExpenses from '@/store/modules/MonthlyExpenses.js'
import helpers from '@/store/modules/Helpers.js'
import partnerInfo from '@/store/modules/PartnerInfo.js'
import nextSteps from '@/store/modules/NextSteps.js'
import monthlyCashFlow from '@/store/modules/MonthlyCashFlow.js'
import planStages from '@/store/modules/PlanStages.js'
import userHistory from '@/store/modules/History.js'
import fitnessTest from '@/store/modules/FitnessTest.js'
import userServiceSubscriptions from '@/store/modules/UserServiceSubscriptions.js'
import whitelabelDefaultValues from '@/store/modules/WhitelabelDefaultValues.js'
import adminReport from '@/store/modules/AdminReport.js'
import complianceFlags from '@/store/modules/ComplianceFlags.js'
import chantWestSupers from '@/store/modules/ChantWestSupers.js'
import retirementCalculatorStore from '@/store/modules/RetirementCalculatorStore'
import cart from '@/store/modules/Cart.js'
import coupons from '@/store/modules/Coupons.js'
import dashboard from '@/store/modules/Otivo/Dashboard'
import retirementCalculatorErrorStore from '@/store/modules/RetirementCalculatorErrorStore'
import contentStore from '@/store/modules/ContentStore'
import financialWellBeingCheckInStore from '@/store/modules/FinancialWellBeingCheckInStore'
import CFSUserStore from '@/store/modules/CFSUserStore'

export const store = createStore({
  modules: {
    ContentStore: contentStore,
    FinancialWellBeingCheckInStore: financialWellBeingCheckInStore,
    RetirementCalculator: retirementCalculatorStore,
    RetirementCalculatorErrors: retirementCalculatorErrorStore,
    adminReport: adminReport,
    cart: cart,
    chantWestSupers: chantWestSupers,
    complianceFlags: complianceFlags,
    contentStore: contentStore,
    coupons: coupons,
    dashboard: dashboard,
    fitnessTest: fitnessTest,
    helpers: helpers,
    monthlyCashFlow: monthlyCashFlow,
    monthlyExpenses: monthlyExpenses,
    navBar: navBar,
    cfsUserStore: CFSUserStore,
    nextSteps: nextSteps,
    notifications: notifications,
    partnerInfo: partnerInfo,
    planStages: planStages,
    profile: profile,
    signUp: signUp,
    userHistory: userHistory,
    userServiceSubscriptions: userServiceSubscriptions,
    whitelabelDefaultValues: whitelabelDefaultValues,
  },
})
