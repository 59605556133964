<template>
  <svg width="16" height="17" viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_14666_217879)">
      <path
        d="M4.5 3.80415C4.21565 3.80316 3.93478 3.7412 3.67612 3.62241C3.41746 3.50362 3.18696 3.33073 3 3.11526L2 1.96376L3.5 0.631236L4.5 1.79279L5.1275 1.06871C5.37092 0.78306 5.69001 0.57281 6.04761 0.462426C6.40522 0.352043 6.78665 0.346064 7.1475 0.445185C8.1675 0.709176 9.25 1.72491 9.25 3.06246H7.25C7.25 2.7331 6.8425 2.44146 6.6325 2.38363L6 3.11275C5.81327 3.32867 5.58288 3.50204 5.3242 3.62127C5.06553 3.7405 4.78454 3.80285 4.5 3.80415Z"
        :class="`fill-${fill2}`" /> />
      <path
        d="M9.25001 4.89536V4.32715H7.25001V4.89787C5.83382 5.1494 4.56276 5.92549 3.68752 7.07307C2.81229 8.22065 2.39643 9.65641 2.52196 11.0972C2.64749 12.5379 3.3053 13.8791 4.36565 14.8561C5.426 15.8331 6.8119 16.375 8.25001 16.375C9.68812 16.375 11.074 15.8331 12.1344 14.8561C13.1947 13.8791 13.8525 12.5379 13.9781 11.0972C14.1036 9.65641 13.6877 8.22065 12.8125 7.07307C11.9373 5.92549 10.6662 5.1494 9.25001 4.89787V4.89536ZM8.25001 14.4317C7.2465 14.4317 6.28409 14.0308 5.5745 13.3172C4.86491 12.6035 4.46626 11.6357 4.46626 10.6265C4.46626 9.61725 4.86491 8.64938 5.5745 7.93576C6.28409 7.22214 7.2465 6.82123 8.25001 6.82123C9.25253 6.82123 10.214 7.22174 10.9229 7.93465C11.6318 8.64756 12.03 9.61448 12.03 10.6227C12.03 11.6309 11.6318 12.5978 10.9229 13.3107C10.214 14.0236 9.25253 14.4242 8.25001 14.4242V14.4317Z"
        :class="`fill-${fill1}`" /> />
    </g>
    <defs>
      <clipPath id="clip0_14666_217879">
        <rect width="16" height="16" fill="white" transform="translate(0 0.375)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1?: string
  fill2?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-blue'
})
</script>
