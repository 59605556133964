<template>
  <svg
    :class="{ 'white-color': white }"
    class="retirementIcon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 96">
    <defs>
      <path id="a" d="M0 0h96v96H0z" />
    </defs>
    <g class="icon" fill="none" fill-rule="evenodd">
      <path
        class="icon"
        fill="#3F5CBA"
        fill-rule="nonzero"
        d="M60.246 34.91l3.758 5.999-7.039-.277.276 7.087-5.958-3.784-3.28 6.277-3.281-6.277-5.958 3.784.276-7.087-7.039.277 3.758-6-6.233-3.303 6.233-3.303-3.758-6 7.039.277-.276-7.087 5.958 3.784L48.002 13l3.28 6.277 5.959-3.784-.276 7.087 7.039-.277-3.758 6 6.233 3.303-6.233 3.303zm-12.244 6c5.103 0 9.239-4.166 9.239-9.304s-4.136-9.303-9.239-9.303c-5.102 0-9.238 4.165-9.238 9.303 0 5.138 4.136 9.303 9.238 9.303zm34.382 35.764c1.887-1.336 4.235-3 8.616-3v5.075c-2.787 0-4.016.871-5.718 2.076-1.887 1.336-4.235 2.999-8.616 2.999-4.38 0-6.73-1.663-8.616-3-1.702-1.204-2.932-2.075-5.718-2.075s-4.016.87-5.717 2.076c-1.887 1.336-4.236 2.999-8.616 2.999s-6.729-1.663-8.616-3c-1.701-1.204-2.93-2.075-5.717-2.075-2.786 0-4.016.87-5.717 2.076-1.887 1.336-4.236 2.999-8.616 2.999s-6.729-1.663-8.616-3C9.016 79.62 7.786 78.75 5 78.75v-5.074c4.38 0 6.729 1.663 8.616 2.999 1.701 1.205 2.93 2.075 5.717 2.075 2.786 0 4.016-.87 5.717-2.075 1.887-1.336 4.236-2.999 8.616-2.999s6.729 1.663 8.616 2.999c1.701 1.205 2.93 2.075 5.717 2.075 2.786 0 4.016-.87 5.717-2.075 1.887-1.336 4.236-2.999 8.616-2.999s6.73 1.663 8.616 2.999c1.702 1.205 2.932 2.075 5.718 2.075 2.787 0 4.016-.87 5.718-2.075zM48.89 59.984l5.267.263a68.891 68.891 0 0 1 29.714 9.726c-1.92.804-3.334 1.805-4.387 2.55-1.473 1.043-1.627 1.152-2.82 1.152-1.191 0-1.345-.109-2.819-1.152-2.071-1.467-5.539-3.922-11.514-3.922-5.974 0-9.442 2.455-11.515 3.922-1.472 1.043-1.626 1.152-2.818 1.152-1.192 0-1.346-.109-2.82-1.152-2.071-1.467-5.539-3.922-11.513-3.922s-9.442 2.455-11.514 3.922c-1.473 1.043-1.627 1.152-2.82 1.152-1.191 0-1.345-.109-2.819-1.152-1.052-.745-2.466-1.745-4.386-2.55a69.077 69.077 0 0 1 13.148-6.183A69.134 69.134 0 0 1 48 59.974c.297 0 .594.006.891.01z" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'RetirementIcon',
  props: {
    white: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.icon {
  @apply fill-otivo-blue;
}
.white-color .icon {
  @apply fill-white;
}
</style>
