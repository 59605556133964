<template>
  <div>
    <svg
      class="offset-curve -mt-1"
      :class="[
        `fill-${fill} stroke-${fill}`,
        [isUserOnMobile() ? [rotate ? 'translate-y-[3px]' : 'translate-y-[0.5px]'] : '']
      ]"
      width="100%"
      viewBox="0 0 1440 101"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg">
      <path :class="{ rotate: rotate }" d="M0 100.869C492 -124.131 1440 100.869 1440 100.869H0Z" />
    </svg>
  </div>
</template>
<script lang="ts" setup>
import { isUserOnMobile } from '@/utilities'

interface Props {
  fill?: string
  rotate?: boolean
}

withDefaults(defineProps<Props>(), {
  fill: 'otivo-blue',
  rotate: false
})
</script>

<style lang="scss" scoped>
.rotate {
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}
</style>
