<template>
  <div
    ref="cards"
    class="bg-white hover:bg-blue-1 hover:text-white px-8 pt-10 mx-2 pb-24 rounded testimonial-slide-shadow relative w-[290px] h-[290px] pointer-events-none">
    <h6 class="text-otivo-blue pb-4">"{{ data.heading }}"</h6>
    <p v-if="data.subHeading" class="text-grey-2 paragraph-2">"{{ data.subHeading }}"</p>
    <div
      class="text-right text-[12px] font-bold text-grey-darkest uppercase absolute right-8 bottom-10">
      {{ data.footer }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { TestimonialType } from '@/types/globalComponents/TestimonialType'

interface Props {
  data: TestimonialType
}

defineProps<Props>()
</script>

<style scoped>
.testimonial-slide-shadow {
  box-shadow: 0px 10px 20px rgba(51, 51, 51, 0.15);
}
</style>
