<template>
  <div class="flex flex-col items-center justify-center max-w-[570px] w-100%">
    <form @submit.prevent="next">
      <div id="capture-account-number" class="w-100% max-w-[375px] transition-opacity">
        <h6>Your account number</h6>
        <BaseInput
          class="w-100% mt-[20px]"
          name="capture-account-number"
          placeholder="Your account number"
          :error-message="accountNumberErrorMessage"
          :value="accountNumber"
          @update:value="validateAccountNumber" />
        <p class="paragraph-2 text-blue-1 mt-[20px]">
          Please add your account number so we can link the advice to your products
        </p>
      </div>
      <OtivoButton type="submit" class="w-100% max-w-[375px] mt-[60px]"> Next </OtivoButton>
    </form>
  </div>
</template>

<script setup lang="ts">
import BaseInput from '@/components/Inputs/BaseInput.vue'
import { useAccountCreationStore } from '@/store/pinia/AccountCreationStore.ts'
import { ref } from 'vue'
import { mod10Checksum } from '@/utilities.ts'
import OtivoButton from '@/components/OtivoButton.vue'

const accountCreationStore = useAccountCreationStore()
const accountNumber = ref(accountCreationStore.superAccountNumber ?? '')
const accountNumberErrorMessage = ref()

const emit = defineEmits<{
  (e: 'next', data: string): void
}>()

const validateAccountNumber = (val: string) => {
  if (!mod10Checksum('001' + val) || val === '' || val.length !== 12) {
    accountNumberErrorMessage.value = 'Please enter a valid account number'
  } else {
    accountNumberErrorMessage.value = undefined
    accountNumber.value = val
    accountCreationStore.setAccountNumber(val)
  }
}

const next = () => {
  if (accountNumber.value === '')
    accountNumberErrorMessage.value = 'Please enter your account number'
  if (accountNumberErrorMessage.value) return
  emit('next', 'captured account number')
}
</script>

<style scoped></style>
