<template>
  <div :id="anchor" class="text-left" ref="anchorRef">
    <div
      class="accordion-title flex justify-between cursor-pointer py-3 border-b"
      :class="[`border-${borderColour}`, styles.base]"
      @click="onClick">
      <div :class="styles.title">
        {{ title }}
      </div>
      <span class="flex-end self-center">
        <arrow-wide
          class="self-center mr-4"
          :fill="chevronColour"
          :class="open ? 'transition transform rotate-0' : 'transition transform rotate-180'" />
      </span>
    </div>
    <transition name="slide-fade">
      <div
        class="accordion-content transition slide-in py-3"
        :class="styles.content"
        v-show="open"
        v-html="content" />
    </transition>
  </div>
</template>

<script lang="ts" setup>
import ArrowWide from '@/components/icons/ArrowWide.vue'
import { onMounted, ref, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const anchorRef = ref<HTMLElement | null>(null)
const router = useRouter()
const route = useRoute()
type AccordionStyle = {
  title: string
  content: string
  base: string
}
interface Props {
  id: string
  title: string
  anchor: string
  content: string
  borderColour?: string
  chevronColour?: string
  styles?: AccordionStyle
}
const props = withDefaults(defineProps<Props>(), {
  id: 'accordion-id',
  title: 'Accordion Title',
  content: 'Content goes here',
  borderColour: 'grey-2',
  chevronColour: '#888',
  styles: () => {
    return {
      title: 'text-16px',
      content: '',
      base: ''
    }
  }
})

onMounted(() => {
  if (route.hash && route.hash == '#' + props.anchor) {
    open.value = !open.value
  }
  if (route.hash) {
    nextTick(() => {
      const el = anchorRef.value
      if (route.hash && route.hash == '#' + props.anchor) {
        open.value = true
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' })
        }
      }
    })
  }
})

const open = ref(false)

const onClick = () => {
  open.value = !open.value
  if (props.anchor && open.value) {
    router.push({ name: route.name?.toString(), hash: '#' + props.anchor })
  } else {
    history.replaceState('', '', location.pathname)
  }
}
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  animation: anim-slide-fade 0.5 ease-in;
}

.slide-fade-leave-active {
  animation: anim-slide-fade 0.5 reverse;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

@keyframes anim-slide-fade {
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

:deep(ul) {
  list-style: disc;
  margin-left: 20px;
}
</style>
