<template>
  <svg width="16" height="17" viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 12.875C7.10999 12.875 6.23996 12.6111 5.49994 12.1166C4.75992 11.6221 4.18314 10.9193 3.84254 10.0971C3.50195 9.27481 3.41283 8.37001 3.58647 7.4971C3.7601 6.62418 4.18869 5.82236 4.81802 5.19302C5.44736 4.56369 6.24918 4.1351 7.1221 3.96147C7.99501 3.78783 8.89981 3.87695 9.72208 4.21754C10.5443 4.55814 11.2471 5.13492 11.7416 5.87494C12.2361 6.61496 12.5 7.48499 12.5 8.375C12.5 9.56848 12.0259 10.7131 11.182 11.557C10.3381 12.4009 9.19348 12.875 8 12.875ZM8 5.875C7.50555 5.875 7.0222 6.02162 6.61108 6.29633C6.19995 6.57103 5.87952 6.96148 5.6903 7.41829C5.50108 7.87511 5.45158 8.37778 5.54804 8.86273C5.6445 9.34768 5.8826 9.79314 6.23224 10.1428C6.58187 10.4924 7.02732 10.7305 7.51228 10.827C7.99723 10.9234 8.4999 10.8739 8.95671 10.6847C9.41353 10.4955 9.80397 10.1751 10.0787 9.76393C10.3534 9.35281 10.5 8.86946 10.5 8.375C10.5 7.71196 10.2366 7.07608 9.76777 6.60724C9.29893 6.13839 8.66304 5.875 8 5.875Z"
      :class="`fill-${fill1}`" />
    <path
      d="M8 2.875C7.73478 2.875 7.48043 2.76964 7.29289 2.58211C7.10536 2.39457 7 2.14022 7 1.875V1.375C7 1.10978 7.10536 0.85543 7.29289 0.667893C7.48043 0.480357 7.73478 0.375 8 0.375C8.26522 0.375 8.51957 0.480357 8.70711 0.667893C8.89464 0.85543 9 1.10978 9 1.375V1.875C9 2.14022 8.89464 2.39457 8.70711 2.58211C8.51957 2.76964 8.26522 2.875 8 2.875Z"
      :class="`fill-${fill2}`" />
    <path
      d="M8 16.375C7.73478 16.375 7.48043 16.2696 7.29289 16.0821C7.10536 15.8946 7 15.6402 7 15.375V14.875C7 14.6098 7.10536 14.3554 7.29289 14.1679C7.48043 13.9804 7.73478 13.875 8 13.875C8.26522 13.875 8.51957 13.9804 8.70711 14.1679C8.89464 14.3554 9 14.6098 9 14.875V15.375C9 15.6402 8.89464 15.8946 8.70711 16.0821C8.51957 16.2696 8.26522 16.375 8 16.375Z"
      :class="`fill-${fill2}`" />
    <path
      d="M1.5 9.375H1C0.734784 9.375 0.48043 9.26964 0.292893 9.08211C0.105357 8.89457 0 8.64022 0 8.375C0 8.10978 0.105357 7.85543 0.292893 7.66789C0.48043 7.48036 0.734784 7.375 1 7.375H1.5C1.76522 7.375 2.01957 7.48036 2.20711 7.66789C2.39464 7.85543 2.5 8.10978 2.5 8.375C2.5 8.64022 2.39464 8.89457 2.20711 9.08211C2.01957 9.26964 1.76522 9.375 1.5 9.375Z"
      :class="`fill-${fill2}`" />
    <path
      d="M15 9.375H14.5C14.2348 9.375 13.9804 9.26964 13.7929 9.08211C13.6054 8.89457 13.5 8.64022 13.5 8.375C13.5 8.10978 13.6054 7.85543 13.7929 7.66789C13.9804 7.48036 14.2348 7.375 14.5 7.375H15C15.2652 7.375 15.5196 7.48036 15.7071 7.66789C15.8946 7.85543 16 8.10978 16 8.375C16 8.64022 15.8946 8.89457 15.7071 9.08211C15.5196 9.26964 15.2652 9.375 15 9.375Z"
      :class="`fill-${fill2}`" />
    <path
      d="M3.40517 4.78017C3.27363 4.77994 3.14343 4.75377 3.02203 4.70315C2.90062 4.65253 2.7904 4.57845 2.69767 4.48517L2.34267 4.12517C2.18497 3.93329 2.10432 3.68959 2.11642 3.44152C2.12852 3.19344 2.23251 2.95875 2.40813 2.78313C2.58375 2.60751 2.81844 2.50352 3.06652 2.49142C3.31459 2.47932 3.55829 2.55997 3.75017 2.71767L4.10267 3.07267C4.24253 3.21237 4.33784 3.3904 4.37657 3.58425C4.4153 3.7781 4.39571 3.97908 4.32027 4.1618C4.24483 4.34452 4.11692 4.50079 3.95271 4.61085C3.7885 4.72091 3.59535 4.77983 3.39767 4.78017H3.40517Z"
      :class="`fill-${fill2}`" />
    <path
      d="M12.9494 14.3251C12.6867 14.3233 12.4353 14.2182 12.2494 14.0326L11.8969 13.6776C11.7965 13.5866 11.7157 13.4762 11.6592 13.3531C11.6028 13.2299 11.572 13.0966 11.5687 12.9611C11.5653 12.8257 11.5895 12.691 11.6398 12.5652C11.6901 12.4395 11.7655 12.3252 11.8613 12.2294C11.957 12.1336 12.0713 12.0583 12.1971 12.008C12.3229 11.9577 12.4575 11.9335 12.593 11.9368C12.7284 11.9402 12.8617 11.971 12.9849 12.0274C13.108 12.0838 13.2184 12.1647 13.3094 12.2651L13.6569 12.6251C13.7944 12.7653 13.8875 12.9429 13.9247 13.1357C13.9619 13.3285 13.9415 13.528 13.866 13.7093C13.7906 13.8906 13.6634 14.0457 13.5004 14.1552C13.3374 14.2647 13.1458 14.3238 12.9494 14.3251Z"
      :class="`fill-${fill2}`" />
    <path
      d="M12.5959 4.78017C12.3983 4.77983 12.2051 4.72091 12.0409 4.61085C11.8767 4.50079 11.7488 4.34452 11.6733 4.1618C11.5979 3.97908 11.5783 3.7781 11.617 3.58425C11.6558 3.3904 11.7511 3.21237 11.8909 3.07267L12.2509 2.71767C12.4428 2.55997 12.6865 2.47932 12.9346 2.49142C13.1827 2.50352 13.4174 2.60751 13.593 2.78313C13.7686 2.95875 13.8726 3.19344 13.8847 3.44152C13.8968 3.68959 13.8161 3.93329 13.6584 4.12517L13.3034 4.47767C13.2113 4.57231 13.1014 4.64777 12.98 4.69969C12.8585 4.75161 12.728 4.77896 12.5959 4.78017Z"
      :class="`fill-${fill2}`" />
    <path
      d="M3.05002 14.3254C2.85366 14.3241 2.66202 14.265 2.49902 14.1555C2.33601 14.046 2.20885 13.8909 2.1334 13.7096C2.05795 13.5283 2.03755 13.3288 2.07474 13.136C2.11192 12.9432 2.20506 12.7656 2.34252 12.6254L2.69752 12.2729C2.78848 12.1725 2.89888 12.0916 3.02204 12.0352C3.14519 11.9788 3.27853 11.948 3.41395 11.9446C3.54938 11.9413 3.68407 11.9655 3.80985 12.0158C3.93563 12.0661 4.04989 12.1414 4.14568 12.2372C4.24146 12.333 4.31679 12.4473 4.36709 12.5731C4.41738 12.6988 4.4416 12.8335 4.43827 12.969C4.43493 13.1044 4.40412 13.2377 4.34769 13.3609C4.29127 13.484 4.21041 13.5944 4.11002 13.6854L3.75002 14.0329C3.56416 14.2185 3.31272 14.3236 3.05002 14.3254Z"
      :class="`fill-${fill2}`" />
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1?: string
  fill2?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-blue'
})
</script>