import CfsService from '@/services/CfsService'
import {
  ClientUser,
  ShowDownloadPDFButtonOptionsType,
  TermDepositResponse,
} from '@/types/CallcentrePortalTypes.ts'
import { FieldValuePairType } from '@/types/GlobalTypes.ts'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useGuidanceClientErrorsStore } from '@/store/modules/GuidanceClientErrorsStore.ts'
import { useIntrafundAdviceStore } from '@/store/pinia/IntrafundAdviceStore.ts'
import { useGuidanceSuperStore } from '@/store/pinia/GuidanceSuperStore.ts'
import { useModuleStatusesStore } from '@/store/pinia/ModuleStatusesStore.ts'
import { useRouter } from 'vue-router'
import { managedClientTemplate } from '@/lib/IntrafundGuidanceHelper.ts'
import { FlagKey } from '@/store/pinia/AccountFlagsStore.ts'
import { useUserStore } from '@/store/pinia/UserStore.ts'
import { useSuperStore } from '@/store/pinia/SuperStore.ts'

export const useGuidanceClientStore = defineStore('GuidanceClientStore', () => {
  const router = useRouter()
  const intrafundAdviceStore = useIntrafundAdviceStore()
  const guidanceSuperStore = useGuidanceSuperStore()
  const clientErrorsStore = useGuidanceClientErrorsStore()
  const moduleStatusStore = useModuleStatusesStore()
  const userStore = useUserStore()

  const guidanceClient = ref<ClientUser>(managedClientTemplate())
  const guidanceClientConsent = ref<boolean>(false)
  const guidanceClientConsentEmailSent = ref<boolean>(false)
  const guidanceClientsSearched = ref<boolean>(false)
  const readClientLoading = ref<boolean>(false)
  const showDownloadPDFButton = ref<ShowDownloadPDFButtonOptionsType>('complete') // complete is now the 'download' state
  const currentAdviceName = ref<FlagKey>()

  const getModuleStatuses = computed(() => moduleStatusStore.getStatuses)
  const getCurrentAdviceTargetName = computed(() => currentAdviceName.value)
  const clientLoggedIn = computed(() => guidanceClient.value.auth0Id !== '')
  const getGuidanceClientConsent = computed(() => guidanceClientConsent.value)
  const getGuidanceClientConsentEmailSent = computed(() => guidanceClientConsentEmailSent.value)
  const getGuidanceClientData = computed(() => guidanceClient.value)
  const getGuidanceClientsSearched = computed(() => guidanceClientsSearched.value)
  const getReadClientLoading = computed(() => readClientLoading.value)
  const getShowDownloadPDFButton = computed(() => showDownloadPDFButton.value)

  interface ConsentResponse {
    account_id: number
    expires_at: string
    consented: boolean
    fee_consent_generated_flag: number
    fee_consent_generated_at: string
    fee_consent_url: string
    advice_received: number
    cash_investment_acknowledged: number
    consentEmailSent: boolean
  }

  const confirmCFSMemberDetails = (payload: { email: string; member_id: string }) => {
    return CfsService.confirmMemberDetails(payload)
  }

  const setCFSClient = (payload: ClientUser) => {
    // Set full client object
    return (guidanceClient.value = payload)
  }

  // const createCFSClient = async (): Promise<boolean | AxiosResponse<any, any>> => {
  //   const valid = clientErrorsStore.validateClientAccount(
  //     guidanceClient.value,
  //     guidanceSuperStore.getClientSuperObject,
  //   )
  //
  //   if (valid) {
  //     await createAndProcessCFSClient()
  //   }
  //
  //   return valid
  // }

  // async function createAndProcessCFSClient() {
  //   try {
  //     const payload = preparePayload()
  //     const res = await CfsService.createCFSClient(payload)
  //
  //     if (!res) {
  //       throw new Error('No response from createCFSClient')
  //     }
  //
  //     return await processCFSClientResponse(res, payload.account_number)
  //   } catch (err) {
  //     handleErrors(err)
  //   }
  // }

  // function preparePayload() {
  //   const payload = convertPayloadToSnakeCase(guidanceClient.value)
  //   payload.account_number = guidanceSuperStore.getClientSuperObject.accountNumber
  //   return payload
  // }
  //
  // async function processCFSClientResponse(res: AxiosResponse, accountNumber: string) {
  //   const chantWestProduct =
  //     await guidanceSuperStore.getChantWestProductFromAccountNumber(accountNumber)
  //
  //   const updateSuperPayload = {
  //     account_number: accountNumber,
  //     chant_west_product_id: chantWestProduct?.chantWestProductId,
  //     fund_name: chantWestProduct?.fundName,
  //   }
  //
  //   const { data, superId } = res.data
  //   updateGuidanceSuperFields(superId, data)
  //
  //   try {
  //     await CfsService.updateModularisedSuper(updateSuperPayload, superId)
  //     postIntrafundAdvice(data, superId)
  //
  //     navRouteStore.fetchNavRoutes()
  //   } catch (error) {
  //     console.error('Error updating modularised super:', error)
  //     throw error // Rethrow to be caught by the outer catch block
  //   }
  // }

  // function updateGuidanceSuperFields(superId: string, data: GenericObject<any>) {
  //   guidanceSuperStore.updateCFSSuperField({ field: 'id', value: superId })
  //   api.setClientHeader(data.auth0id)
  //   updateGuidanceClientField({ field: 'auth0Id', value: data.auth0id })
  //   updateGuidanceClientField({ field: 'accountId', value: data.account.id })
  //   guidanceSuperStore.addEmptySuperFund()
  // }

  // function postIntrafundAdvice(data: GenericObject<any>, superId: string) {
  //   const intrafundAdvicePayload = {
  //     member_id: data.user_metadata.member_id,
  //     tag: 'cfs',
  //     advice_stage: 1,
  //   }
  //   CfsService.postIntrafundAdviceRequest(intrafundAdvicePayload, superId)
  //   window.localStorage.setItem(
  //     'cfsClientAuthId',
  //     JSON.stringify({
  //       auth0Id: data.auth0id,
  //       timestamp: new Date().getTime(),
  //     }),
  //   )
  // }

  // function handleErrors(err) {
  //   if (err.response) {
  //     if (err.response.status === 409) {
  //       clientErrorsStore.setClientErrors({
  //         memberId: err.response.data.type === 'memberId' ? err.response.data.error : '',
  //         firstName: '',
  //         lastName: '',
  //         email: err.response.data.type === 'email' ? err.response.data.error : '',
  //         mobile: '',
  //         dob: '',
  //       })
  //     } else if (err.response?.data?.type === 'accountNumberExists') {
  //       clientErrorsStore.setSuperWidgetErrorField({
  //         field: 'accountNumber',
  //         value: err.response?.data?.message,
  //       })
  //     }
  //   } else {
  //     console.error('Unhandled error:', err)
  //   }
  //   throw err
  // }

  // const editCFSClient = async (auth0id: string) => {
  //   const valid = clientErrorsStore.validateClientAccount(
  //     guidanceClient.value,
  //     guidanceSuperStore.getClientSuperObject,
  //   )
  //
  //   if (valid) {
  //     const payload = {
  //       ...guidanceClient.value,
  //       super_data: convertPayloadToSnakeCase(guidanceSuperStore.getClientSuperObject),
  //     }
  //     return CfsService.updateCFSClient(convertPayloadToSnakeCase(payload), auth0id)
  //       .then((res) => {
  //         // Making sure we're only updating the primary super account
  //         return res
  //       })
  //       .catch((err) => {
  //         if (err.response.status === 409) {
  //           clientErrorsStore.setClientErrors({
  //             memberId: err.response.data.type === 'member_id' ? err.response.data.error : '',
  //             firstName: '',
  //             lastName: '',
  //             email: err.response.data.type === 'email' ? err.response.data.error : '',
  //             mobile: '',
  //             dob: '',
  //           })
  //         }
  //         return err
  //       })
  //   }
  //   return valid
  // }
  // redundant
  const updateGuidanceClientField = (payload: FieldValuePairType<unknown>) => {
    // Generic[T] update for guidanceClient
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    guidanceClient.value[payload.field] = payload.value
  }

  /**
   * FLAG RELATED
   */
  const setGuidanceClientConsent = (payload: {
    consented: boolean
    expiresAt: number
    account: {
      account_flags: {
        guidance_client_consent_email_sent: boolean
      }
    }
  }): void => {
    guidanceClientConsent.value = payload.consented
    guidanceClientConsentEmailSent.value =
      payload.account.account_flags.guidance_client_consent_email_sent
  }

  /**
   * UI RELATED
   */
  const clientRouteRedirect = (consentResponse: boolean) => {
    console.log(consentResponse)

    const routeName = router.currentRoute.value.name
    if (routeName === 'clientDisclaimer' && consentResponse) {
      router
        .replace({ name: 'superInvestments' })
        .catch((err) => console.warn('error redirecting to superWidget', err))
    }
  }
  const readClientData = async (auth0Id: string, allowRedirect: boolean = true): Promise<void> => {
    if (readClientLoading.value) return

    const guidanceSuperStore = useGuidanceSuperStore()
    readClientLoading.value = true

    try {
      const { data } = await CfsService.readClientData({ auth0Id })
      const clientData: ClientUser = data

      const payload: ClientUser = {
        ...clientData,
        auth0Id,
      }

      const superIndex = guidanceSuperStore.getSuperIndex ?? 0
      const supersArray = guidanceSuperStore.getSupersArray

      guidanceSuperStore.setSupersArray(supersArray)
      setCFSClient(payload)

      if (
        supersArray.length > 0 &&
        supersArray[superIndex]?.id &&
        guidanceSuperStore.getClientSuperObject.id === ''
      ) {
        await guidanceSuperStore.getClientSuper(supersArray[superIndex].id)
      }

      guidanceSuperStore.addEmptySuperFund()

      const consentResult = await fetchGuidanceClientConsent()
      if (allowRedirect) clientRouteRedirect(consentResult)
    } catch (error) {
      console.error('Error reading client data:', error)
    } finally {
      readClientLoading.value = false
    }
  }

  /**
   * FLAG RELATED
   */
  const fetchGuidanceClientConsent = async (): Promise<boolean> => {
    const intrafundAdviceStore = useIntrafundAdviceStore()
    const userStore = useUserStore()

    try {
      await intrafundAdviceStore.fetchTermDepositConsent()

      const accountId = guidanceClient.value?.accountId ?? userStore.getUser?.account_id

      if (!accountId) {
        throw new Error('Account ID is not set')
      }

      // Fetch the consent flag
      const response = await CfsService.getConsentFlag(accountId)
      const consentData = response.data as ConsentResponse

      const currentTimestamp = Date.now()
      const expirationTimestamp = parseInt(consentData.expires_at)

      const isConsentValid = expirationTimestamp > currentTimestamp && consentData.consented

      guidanceClientConsent.value = isConsentValid
      guidanceClientConsentEmailSent.value = consentData.consentEmailSent

      return isConsentValid
    } catch (error) {
      console.error('Error fetching guidance client consent:', error)
      return false
    }
  }
  /**
   * AUTH/LOGIN RELATED
   */
  const resendCFSClientSMS = (payload: { email: string; memberId: string }) => {
    return CfsService.resendClientSMS(payload)
  }

  const resetCFSClient = () => {
    guidanceClient.value = managedClientTemplate()
    guidanceClientConsentEmailSent.value = false
    intrafundAdviceStore.updateTermDepositConsent({} as TermDepositResponse)
    guidanceClientConsent.value = false
  }

  const logoutClientAccount = () => {
    // resetClient()
    guidanceSuperStore.resetClientSuper()
    // resetCFSSearchMemberResults()
    clientErrorsStore.resetSuperWidgetErrors()
  }

  /**
   * FLAGS
   */
  const setGuidanceClientConsentFlag = () => {
    const date = new Date().getTime()
    return CfsService.setConsentFlag({
      consented: true,
      cash_investment_acknowledged: intrafundAdviceStore.getHasCashAndTermDepositInvestments,
      expires_at: date + 31536000000, // + 1 year
      account_id: guidanceClient.value.accountId,
    })
  }

  /**
   * FLAGS
   */
  const setAdviceReceivedFlag = () => {
    return CfsService.setConsentFlag({
      advice_received: true,
      account_id: guidanceClient.value.accountId,
    })
  }

  /**
   * UI RELATED
   */
  const setShowDownloadPDFButton = (value: ShowDownloadPDFButtonOptionsType) => {
    showDownloadPDFButton.value = value
  }

  const setAdviceRouterTargetName = (routeName: string) => {
    currentAdviceName.value = routeName as FlagKey
  }

  return {
    clientLoggedIn,
    // getCFSSearchMembersResults,
    getGuidanceClientConsent,
    getGuidanceClientConsentEmailSent,
    getGuidanceClientData,
    getGuidanceClientsSearched,
    getReadClientLoading,
    getShowDownloadPDFButton,
    getCurrentAdviceTargetName,
    getModuleStatuses,
    setAdviceRouterTargetName,
    confirmCFSMemberDetails,
    // createCFSClient,
    // editCFSClient,
    fetchGuidanceClientConsent,
    logoutClientAccount,
    readClientData,
    resendCFSClientSMS,
    resetCFSClient,
    setAdviceReceivedFlag,
    setCFSClient,
    setGuidanceClientConsent,
    setGuidanceClientConsentFlag,
    setShowDownloadPDFButton,
    updateGuidanceClientField,
  }
})
