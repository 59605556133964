<template>
  <div>
    <h4 class="text-blue-1 font-bold text-22px p-6 md:p-0">
      Please confirm your details are correct.
    </h4>
    <div class="md:mt-[40px]">
      <MemberInfoForm :confirmation-page="true" />
      <SuperWidgetForm
        class="my-[40px] p-[20px] bg-white otivo-drop-shadow rounded !border-blue-4.5"
        :disabled-fields="cfsClient"
        :super-fund-options="[]"
        :loading-form="false"
        :default-open="true"
        :disable-optimise="true"
        :is-collapsable="false"
        :preservation-age="preservationAge" />
      <p class="paragraph-2 text-grey-2 p-8 md:max-w-[895px]">
        Otivo's advice relies on the personal information you've given us. If any of this
        information is incomplete or incorrect, you risk receiving advice which may not be
        appropriate for you. If you need to update any personal information, please call CFS on 13
        13 36.
      </p>
    </div>
    <div class="flex flex-col gap-[20px] w-100% md:w-fit my-[40px]">
      <OtivoButton
        @click="$emit('click')"
        data-test="infoCorrectbtn"
        colour="blue"
        class="w-100% md:w-fit"
        size="large">
        My information is correct
      </OtivoButton>
      <OtivoButton
        @click="useSessionStore().logout()"
        colour="white"
        size="large"
        data-test="infoIncorrectbtn"
        class="w-100% md:w-100%">
        I'm not proceeding
      </OtivoButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import SuperWidgetForm from '@/components/SuperWidget/SuperWidgetForm.vue'
import { useUserStore } from '@/store/pinia/UserStore'
import { computed, onBeforeMount } from 'vue'
import { useSessionStore } from '@/store/pinia/SessionStore'
import OtivoButton from '@/components/OtivoButton.vue'
import { state } from '@/components/CFS/IntrafundInvestment/superInvestmentsState.ts'
import RetirementService from '@/services/RetirementService.ts'
import MemberInfoForm from '@/components/CFS/Guidance/MemberInfoForm.vue'

defineEmits<{ (e: 'click'): void }>()

const userStore = useUserStore()
const cfsClient = userStore.cfsClient
onBeforeMount(async () => {
  state.preservationAge = await RetirementService.getUserPreservationAge()
})

const preservationAge = computed(() => state.preservationAge)
</script>
<style scoped></style>
