<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6175 8.64817L7.84699 5.87768L10.6175 3.1072C10.8624 2.86227 11 2.53008 11 2.1837C11 1.83733 10.8624 1.50514 10.6175 1.26021C10.3725 1.01528 10.0404 0.877686 9.69398 0.877686C9.3476 0.877686 9.01541 1.01528 8.77048 1.26021L6 4.03069L3.22951 1.26021C2.98459 1.01528 2.6524 0.877686 2.30602 0.877686C1.95964 0.877686 1.62745 1.01528 1.38252 1.26021C1.1376 1.50514 1 1.83733 1 2.1837C1 2.53008 1.1376 2.86227 1.38252 3.1072L4.15301 5.87768L1.38252 8.64817C1.1376 8.8931 1 9.22529 1 9.57167C1 9.91804 1.1376 10.2502 1.38252 10.4952C1.62745 10.7401 1.95964 10.8777 2.30602 10.8777C2.6524 10.8777 2.98459 10.7401 3.22951 10.4952L6 7.72467L8.77048 10.4952C9.01541 10.7401 9.3476 10.8777 9.69398 10.8777C10.0404 10.8777 10.3725 10.7401 10.6175 10.4952C10.8624 10.2502 11 9.91804 11 9.57167C11 9.22529 10.8624 8.8931 10.6175 8.64817Z"
      :class="`fill-${fill1}`" />
  </svg>
</template>
<script setup lang="ts">
interface Props {
  fill1: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'grey-3',
})
</script>
<style scoped></style>
