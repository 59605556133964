<template>
  <div class="lump-sum-skeleton">
    <h5 />
    <h6 />
    <p />
    <p />
    <p />
    <p />
    <p />
    <div class="button"></div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.lump-sum-skeleton {
  width: 100%;
  height: 100%;
  padding: 20px 40px 40px 40px;
  & h6 {
    border-radius: 10px;
    height: 20px;
    margin: 20px 0;
    width: 100px;
  }
  & h5 {
    border-radius: 10px;
    height: 40px;
    margin: 20px 0;
    width: 50%;
  }
  & p {
    width: 100%;
    height: 10px;
    margin-top: 5px;
  }
  & .button {
    width: 325px;
    height: 40px;
    margin-top: 20px;
    border-radius: 10px;
  }
  & h5,
  p,
  h6,
  .button {
    animation: loading 1.5s infinite;
  }
}
</style>
