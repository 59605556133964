const updateDependent = (object) => {
  if (object.childcare_cost === undefined) {
    object.childcare_cost = null
  }

  if (object.primary_cost === undefined) {
    object.primary_cost = null
  }

  if (object.secondary_cost === undefined) {
    object.secondary_cost = null
  }

  return object
}

const updateDependentObjectBeforePosting = (object) => {
  // save it to 0 if no values and above conditions are not met
  // if (object['childcare_cost'] === null) {
  //   object['childcare_cost'] = 0
  // }
  //
  // if (object['primary_cost'] === null) {
  //   object['primary_cost'] = 0
  // }
  //
  // if (object['secondary_cost'] === null) {
  //   object['secondary_cost'] = 0
  // }

  return object
}

const getAverageDependentsCostForYear = (costType) => {
  const costsArray = []
  costsArray.childcare_cost = 25000
  costsArray.primary_cost = 2000
  costsArray.secondary_cost = 5000

  return costsArray[costType]
}

export { updateDependent, updateDependentObjectBeforePosting, getAverageDependentsCostForYear }
