<template>
  <div>
    <fitness-test-question-label
      :question-number="questionNumber"
      question=" Do you have income protection insurance?" />
    <input-box-buttons
      @click="setHaveIncomeProtection"
      :buttons="[
        {
          name: 'Yes',
          value: 'yes',
        },
        {
          name: 'No',
          value: 'no',
        },
        {
          name: 'Self insured',
          value: 'self_insured',
        },
      ]"
      :selected="haveIncomeProtection"
      name="living-situation"
      label="" />
  </div>
</template>

<script>
import IncomeProtectionAnswer from './IncomeProtectionAnswer'
import { mapState } from 'vuex'
import FitnessTestQuestionLabel from '../QuestionsLabel/FitnessTestQuestionLabel.vue'

import InputBoxButtons from '@/components/BasicInputs/InputBoxButtons.vue'
export default {
  name: 'HaveIncomeProtection',
  components: { InputBoxButtons, FitnessTestQuestionLabel, IncomeProtectionAnswer },
  computed: {
    ...mapState({
      haveIncomeProtection: (state) => state.fitnessTest.questions.haveIncomeProtection,
    }),
  },

  props: {
    questionNumber: {
      type: String,
      default: '',
    },
  },

  methods: {
    setHaveIncomeProtection(value) {
      this.$store.dispatch('setFitnessTestHaveIncomeProtection', value)
    },
  },
}
</script>

<style scoped></style>
