export default (flexDirection = 'row', gap = '5px') => {
  return {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
      const div = getOrCreateLegendList(chart, options.containerID)
      div.style.gap = gap
      div.style.flexDirection = flexDirection

      // Remove old legend items
      while (div.firstChild) {
        div.firstChild.remove()
      }

      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart)

      items.forEach((item) => {
        const legendItem = document.createElement('div')
        legendItem.style.alignItems = 'center'
        legendItem.style.cursor = 'pointer'
        legendItem.style.display = 'flex'
        legendItem.style.flexWrap = 'wrap'
        legendItem.style.padding = '5px'
        legendItem.style.borderRadius = '4px'
        legendItem.style.border = '1px solid #EEEBEB'

        if (item.hidden) {
          // disabled style
          legendItem.style.background = 'white'
          legendItem.style.borderLeft = `3px solid ${item.strokeStyle}`
        } else {
          // enabled style
          legendItem.style.background = 'white'
          legendItem.style.borderLeft = `3px solid ${item.fillStyle}`
        }

        legendItem.onclick = () => {
          const { type } = chart.config
          if (type === 'pie' || type === 'doughnut') {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index)
          } else {
            chart.setDatasetVisibility(
              item.datasetIndex,
              !chart.isDatasetVisible(item.datasetIndex)
            )
          }
          chart.update()
        }

        // Leaving here as an example of how to create a legend item with a color box
        // // Color box
        const boxSpan = document.createElement('span')
        boxSpan.style.background = item.fillStyle
        boxSpan.style.borderColor = item.strokeStyle
        boxSpan.style.borderWidth = item.lineWidth + 'px'
        boxSpan.style.display = 'inline-block'
        boxSpan.style.flexShrink = '0'
        boxSpan.style.height = '20px'
        boxSpan.style.marginRight = '10px'
        boxSpan.style.width = '20px'

        // Text
        const textContainer = document.createElement('p')
        //   TEXT COLOUR
        textContainer.style.color = item.hidden ? '#888' : item.fillStyle
        // textContainer.style.color = '#000'
        textContainer.style.fontSize = '10px'
        textContainer.style.textTransform = 'uppercase'
        textContainer.style.fontFamily = 'Raleway'
        textContainer.style.fontWeight = 'bold'
        textContainer.style.margin = '0'
        textContainer.style.padding = '0'
        // textContainer.style.textDecoration = item.hidden ? 'line-through' : ''

        const text = document.createTextNode(item.text)
        textContainer.appendChild(text)
        // legendItem.appendChild(boxSpan)
        legendItem.appendChild(textContainer)
        div.appendChild(legendItem)
      })
    }
  }
}

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id)
  let listContainer = legendContainer.querySelector('div')

  if (!listContainer) {
    listContainer = document.createElement('div')
    listContainer.style.display = 'flex'
    listContainer.style.flexDirection = 'row'
    listContainer.style.flexWrap = 'wrap'
    listContainer.style.justifyContent = 'left'
    listContainer.style.gap = '5px'
    listContainer.style.margin = '0'
    listContainer.style.padding = '0'

    legendContainer.appendChild(listContainer)
  }

  return listContainer
}
