<template>
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4442_7914)">
      <path
        :class="`fill-${fill} group-hover:fill-${hover ?? fill}`"
        d="M4 0.503906H2.5C1.1225 0.503906 0 1.62641 0 3.00391V4.50391C0 5.05641 0.4475 5.50391 1 5.50391C1.5525 5.50391 2 5.05641 2 4.50391V3.00391C2 2.72891 2.225 2.50391 2.5 2.50391H4C4.5525 2.50391 5 2.05641 5 1.50391C5 0.951406 4.5525 0.503906 4 0.503906Z"
        fill="currentColor" />
      <path
        :class="`fill-${fill} group-hover:fill-${hover ?? fill}`"
        d="M15 11.5039C14.4475 11.5039 14 11.9514 14 12.5039V14.0039C14 14.2789 13.775 14.5039 13.5 14.5039H12C11.4475 14.5039 11 14.9514 11 15.5039C11 16.0564 11.4475 16.5039 12 16.5039H13.5C14.8775 16.5039 16 15.3814 16 14.0039V12.5039C16 11.9514 15.5525 11.5039 15 11.5039Z"
        fill="currentColor" />
      <path
        :class="`fill-${fill} group-hover:fill-${hover ?? fill}`"
        d="M4 14.5039H2.5C2.225 14.5039 2 14.2789 2 14.0039V12.5039C2 11.9514 1.5525 11.5039 1 11.5039C0.4475 11.5039 0 11.9514 0 12.5039V14.0039C0 15.3814 1.1225 16.5039 2.5 16.5039H4C4.5525 16.5039 5 16.0564 5 15.5039C5 14.9514 4.5525 14.5039 4 14.5039Z"
        fill="currentColor" />
      <path
        :class="`fill-${fill} group-hover:fill-${hover ?? fill}`"
        d="M13.5 0.503906H12C11.4475 0.503906 11 0.951406 11 1.50391C11 2.05641 11.4475 2.50391 12 2.50391H13.5C13.775 2.50391 14 2.72891 14 3.00391V4.50391C14 5.05641 14.4475 5.50391 15 5.50391C15.5525 5.50391 16 5.05641 16 4.50391V3.00391C16 1.62641 14.8775 0.503906 13.5 0.503906Z"
        fill="currentColor" />
      <path
        :class="`fill-${fill} group-hover:fill-${hover ?? fill}`"
        d="M8 14.0039C8.55228 14.0039 9 13.5562 9 13.0039C9 12.4516 8.55228 12.0039 8 12.0039C7.44772 12.0039 7 12.4516 7 13.0039C7 13.5562 7.44772 14.0039 8 14.0039Z"
        fill="currentColor" />
      <path
        :class="`fill-${fill} group-hover:fill-${hover ?? fill}`"
        d="M10.9173 5.5539C10.5923 4.1989 9.39484 3.2514 8.00234 3.2514C7.76734 3.2514 7.53234 3.2789 7.30234 3.3339C6.51234 3.5239 5.82234 4.0339 5.41234 4.7364C5.23234 5.0464 5.23234 5.4289 5.41234 5.7389C5.58484 6.0564 5.91984 6.2564 6.28734 6.2564C6.64734 6.2514 6.96984 6.0564 7.13984 5.7489C7.31484 5.4464 7.64484 5.2564 7.99984 5.2564C8.10484 5.2564 8.20984 5.2739 8.31234 5.3039C8.57734 5.3864 8.79484 5.5814 8.90984 5.8339C9.12984 6.3164 8.93734 6.8864 8.47234 7.1339C7.53734 7.6639 6.97734 8.6539 6.99984 9.7189V9.7539C6.99984 10.3064 7.44734 10.7539 7.99984 10.7539C8.55234 10.7539 8.99984 10.3064 8.99984 9.7539V9.7139C8.98734 9.3764 9.14984 9.0664 9.42734 8.8939C10.6223 8.2489 11.2348 6.8764 10.9173 5.5539Z"
        fill="currentColor" />
    </g>
    <defs>
      <clipPath>
        <rect width="16" height="16" fill="white" transform="translate(0 0.503906)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script lang="ts" setup>
type Props = {
  fill?: string
  hover?: string
}

withDefaults(defineProps<Props>(), {
  fill: 'blue-1',
  hover: 'otivo-blue',
})
</script>

<style scoped></style>
