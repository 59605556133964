<template>
  <base-view>
    <div id="advice-practices" v-if="pageContent.currentPage === 'advicePracticesPage'">
      <Breadcrumbs />
      <LandingPageModule
        class="items-center"
        :landing-page-content="pageContent.advicePracticesHero"
        :show-button="true"
        @button-clicked="showContactUsModal"
        background-color="blue-5">
        <template #svgComponent>
          <img
            class="max-w-[300px] md:max-w-[25%] mx-auto"
            src="../../../../assets/img/finadvice.png"
            alt="financial advice" />
        </template>
      </LandingPageModule>
      <RegularCurve fill="otivo-blue" class="bg-blue-5 h-6 w-full" />
      <div class="bg-otivo-blue text-white">
        <div
          class="md:py-[80px] m-auto md:w-[1500px] flex flex-col md:flex-row justify-center items-center gap-14">
          <img
            class="h-[225px] md:h-[340px] w-[245px] md:w-[380px]"
            src="@/assets/img/fin-advice-phone.png"
            alt="fin-advice-phone-image" />
          <div class="flex flex-col justify-center p-6 md:p-6">
            <h3 class="md:w-[600px]" v-html="pageContent.advicePracticesImageAndText.heading" />
            <div class="intro-1 mt-[30px] md:w-[670px]">
              {{ pageContent.advicePracticesImageAndText.subHeading }}
            </div>
            <div class="intro-1 !font-bold mt-[30px]">
              {{ pageContent.advicePracticesImageAndText.body }}
            </div>
          </div>
        </div>
      </div>
      <RegularCurve fill="white" class="bg-otivo-blue h-6 w-full" />
      <USPs
        @button-clicked="showContactUsModal"
        class="py-[60px] bg-white"
        :usp-data="pageContent.advicePracticesUsps"
        :icons="uspsIcons">
        <template #banner-slot>
          <div class="hidden md:flex flex-col items-center">
            <div class="banner">
              <div
                class="banner-section-1"
                :class="[
                  { active: bannerActive === 1 },
                  { inactive: bannerActive === 3 || bannerActive === 2 },
                ]"
                @click="bannerSelect(1)">
                <div class="flex items-center">
                  <span class="flex text-otivo-red text-[30px] leading-9 mr-[20px]">10%</span>
                  <div class="flex banner-content">
                    Current Australians with access to face-to-face advice
                  </div>
                </div>
              </div>
              <div class="link" v-if="bannerActive === 0">
                <div class="right-triangle" />
              </div>
              <div
                class="banner-section-2"
                :class="[
                  { active: bannerActive === 2 },
                  { inactive: bannerActive === 3 || bannerActive === 1 },
                ]"
                @click="bannerSelect(2)">
                <div class="flex items-center">
                  <span class="flex text-[30px] leading-9 mr-[20px]">10%</span>
                  <div class="flex banner-content">
                    A hybrid face-to-face and online model (Otivo) allows you to service more
                    clients
                  </div>
                </div>
              </div>
              <div class="link-2" v-if="bannerActive === 0">
                <div class="right-triangle" />
              </div>
              <div
                class="banner-section-3"
                :class="[
                  { active: bannerActive === 3 },
                  { inactive: bannerActive === 2 || bannerActive === 1 },
                ]"
                @click="bannerSelect(3)">
                <div class="flex items-center">
                  <span class="flex text-[30px] leading-9 mr-[20px]">80%</span>
                  <div class="flex banner-content">
                    When you team up with Otivo, there’s no need to turn people away and miss out on
                    future client opportunities.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </USPs>
      <div class="bg-grey-1 flex flex-col py-[60px] align-center text-white relative">
        <div class="m-auto px-6 xl:pl-80 relative">
          <img
            src="@/assets/img/looking-at-computer.png"
            alt="looking at computer"
            class="hidden xl:block absolute right-[660px] w-[760px] -bottom-[260px] z-0" />
          <div class="headline-2 xl:w-[620px]">
            “{{ pageContent.fordScottTestimonial.heading }}”
          </div>
          <div class="headline-5 mt-[30px] xl:w-[650px]">
            "{{ pageContent.fordScottTestimonial.subHeading }}"
          </div>
          <div class="xl:flex justify-between align-bottom mt-[60px]">
            <div class="paragraph-2 mt-4">Tim Scott, Managing Director of Ford Scott</div>
            <img
              class="w-[110px] mt-[10px] xl:mt-0"
              src="@/assets/img/fordscott.png"
              alt="ford scott quote" />
          </div>
        </div>
      </div>
      <HowItWorksModule
        class="bg-blue-5 pt-[60px] z-10 relative"
        :how-it-works="pageContent.advicePracticesHowItWorks" />
      <Awards
        class="bg-blue-5 py-20 hidden md:block"
        :color="{
          textColour: 'grey-1',
          svgLineFill: 'grey-1',
          svgImageBackgroundFill: 'blue-5',
        }" />
      <RegularCurve fill="blue-5" class="bg-otivo-blue h-6 w-full" :rotate="true" />
      <BreathingPhoneSection
        class="bg-otivo-blue z-500"
        :content="pageContent.breathingPhoneComponent"
        @button-clicked="showContactUsModal" />
      <ThreeTileDisplay
        class="bg-white z-100"
        :icon-boxes="pageContent.threeTileDisplay.iterableItems" />
      <RegularCurve class="h-6 w-full md:hidden bg-white fill-midnight" fill="midnight" />
      <HowWeWorkItOutModal
        @close="showHowWeWorkItOutModal = false"
        v-if="showHowWeWorkItOutModal" />
    </div>
  </base-view>
</template>

<script setup lang="ts">
import Awards from '@/components/Footer/Awards.vue'
import BaseView from '@/views/BaseView.vue'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import HowItWorksModule from '@/components/HowItWorksModule/HowItWorksModule.vue'
import HowWeWorkItOutModal from '@/components/BrandComponents/Otivo/HowWeWorkItOutModal.vue'
import LandingPageModule from '@/components/BrandComponents/LandingPageModule.vue'
import ThreeTileDisplay from '@/components/BrandComponents/Otivo/ThreeTileDisplay.vue'
import USPs from '@/components/BrandComponents/Otivo/USPs.vue'
import { computed, onBeforeMount, ref } from 'vue'
import { useStore } from 'vuex'

import BankSVG from '@/components/SVGS/BrandIcons/16px/bank.svg'
import IncomeSVG from '@/components/SVGS/BrandIcons/16px/income-and-expenses.svg'
import CashflowSVG from '@/components/SVGS/BrandIcons/16px/cashflow.svg'
import PartnersSVG from '@/components/SVGS/BrandIcons/16px/partners.svg'
import BriefingSVG from '@/components/SVGS/BrandIcons/16px/premium-briefing-plan.svg'
import PapersSVG from '@/components/SVGS/BrandIcons/16px/media-newspaper.svg'
import { useHead } from '@unhead/vue'

import RegularCurve from '@/components/Shapes/RegularCurve.vue'
import BreathingPhoneSection from '@/components/BreathingPhoneSection.vue'

const store = useStore()

useHead({
  title: "Otivo's digital advice solutions for advice practices",
})

onBeforeMount(() => store.dispatch('ContentStore/getContent', 'advicePracticesPage'))
const pageContent = computed(() => store.getters['ContentStore/pageContent'])

const showHowWeWorkItOutModal = ref(false)
const bannerActive = ref(0)
const bannerSelect = (num) => {
  if (window.innerWidth <= 1024) {
    if (bannerActive.value === num) bannerActive.value = 0
    else bannerActive.value = num
  }
}
const showContactUsModal = () => {
  store.dispatch('renderContactUsModal', true)
}
const uspsIcons: Array<unknown> = [
  IncomeSVG,
  BankSVG,
  CashflowSVG,
  PartnersSVG,
  BriefingSVG,
  PapersSVG,
]
</script>

<style lang="scss" scoped>
.banner {
  transition: ease-in-out 500ms;
  height: 100px;
  @apply w-90% lg:w-auto flex flex-row justify-center my-[60px] relative;
}

.link {
  width: 50px;

  .right-triangle {
    border-style: solid;
    border-width: 50px 0 50px 50px;
    border-color: transparent transparent transparent var(--blue-5);
  }

  @apply bg-otivo-blue;
}

.link-2 {
  width: 50px;

  .right-triangle {
    border-style: solid;
    border-width: 50px 0 50px 50px;
    border-color: transparent transparent transparent var(--otivo-blue);
  }

  @apply bg-blue-1;
}

.banner-section-1,
.banner-section-2,
.banner-section-3 {
  cursor: pointer;
  overflow: hidden;
  transition: ease-out 333ms;
  width: 33%;

  &.inactive {
    transition: ease-in 333ms;
    width: 0;
    opacity: 0;
  }

  &.active {
    width: 85%;
    height: 100%;
    z-index: 99;
    padding: 0 10px;
    border-radius: 50px;
    transition: ease-in-out 333ms;
    position: absolute;

    .banner-content {
      @apply flex;
    }
  }

  .banner-content {
    @apply hidden lg:flex;
  }

  @media screen and (min-width: 1024px) {
    width: auto;
    cursor: auto;
  }
}

.banner-section-1 {
  height: 100%;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding-left: 20px;
  @media screen and (min-width: 1024px) {
    width: 250px;
  }
  @apply bg-blue-5 flex content-center justify-center;
}

.banner-section-2 {
  color: white;
  padding-left: 20px;
  @media screen and (min-width: 1024px) {
    width: 250px;
  }
  @apply bg-otivo-blue flex content-center justify-center;
}

.banner-section-3 {
  color: white;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  @media screen and (min-width: 1024px) {
    width: 400px;
  }
  @apply bg-blue-1 flex content-center justify-center px-[20px];
}
</style>
