<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :aria-labelledby="iconName">
    <title :id="iconName" lang="en">{{ iconName }}</title>
    <g :fill="fill">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'OtivoIconBase',
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
      default: 12
    },
    height: {
      type: [Number, String],
      default: 12
    },
    fill: {
      type: String,
      default: 'currentColor'
    },
    viewBox: {
      type: String,
      default: '0 0 16 16'
    }
  }
}
</script>

<style scoped></style>
