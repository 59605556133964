<template>
  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 15H2.01C0.9025 15 0 14.11 0 13.015V2.985C0 1.89 0.9025 1 2.01 1H9.99C11.0975 1 12 1.89 12 2.985V9.5625C12 10.115 11.5525 10.5625 11 10.5625C10.4475 10.5625 10 10.115 10 9.5625V2.985L2.01 3L2 13.015L13 13C13.5525 13 14 13.4475 14 14C14 14.5525 13.5525 15 13 15Z"
      :class="`fill-${fill1} group-hover:fill-${hover1 ?? fill1}`" />
    <path
      d="M13 15C11.345 15 10 13.655 10 12V4.5H14.015C15.11 4.5 16 5.39 16 6.485V12C16 13.655 14.655 15 13 15ZM12 6.495V12C12 12.5525 12.4475 13 13 13C13.5525 13 14 12.5525 14 12V6.485L12 6.495Z"
      :class="`fill-${fill1} group-hover:fill-${hover1 ?? fill1}`" />
    <path
      d="M7.75 7.0625H4.25C3.6975 7.0625 3.25 6.615 3.25 6.0625C3.25 5.51 3.6975 5.0625 4.25 5.0625H7.75C8.3025 5.0625 8.75 5.51 8.75 6.0625C8.75 6.615 8.3025 7.0625 7.75 7.0625Z"
      :class="`fill-${fill2} group-hover:fill-${hover2 ?? fill2}`" />
    <path
      d="M7.75 10.5625H4.25C3.6975 10.5625 3.25 10.115 3.25 9.5625C3.25 9.01 3.6975 8.5625 4.25 8.5625H7.75C8.3025 8.5625 8.75 9.01 8.75 9.5625C8.75 10.115 8.3025 10.5625 7.75 10.5625Z"
      :class="`fill-${fill2} group-hover:fill-${hover2 ?? fill2}`" />
  </svg>
</template>

<script setup lang="ts">
type Props = {
  fill1?: string
  fill2?: string
  hover1?: string | null
  hover2?: string | null
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red',
  hover1: null,
  hover2: null
})
</script>

<style scoped></style>
