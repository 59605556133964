<template>
  <!--  gap-3 deliberately omitted here because sometimes this component will be placed side by side-->
  <!--  Just place in the location where you call this component-->
  <basic-input :class="{ 'flex-col': stackLabel }">
    <template #input-label>
      <div
        v-if="label !== ''"
        class="pt-4 paragraph-2 flex flex-row"
        :class="{ 'set-w-28 md:set-w-48': !stackLabel }">
        <input-label v-bind="$attrs" :label="label" :for="name" />
        <info-circle
          :fill="tooltip.colour"
          :css-class="tooltip.class"
          :message="title"
          class="ml-1" />
      </div>
    </template>
    <template #input-box>
      <basic-input-currency
        class="w-full paragraph-2"
        v-bind="$attrs"
        :id="name"
        :placeholder="placeholder"
        :value="value"
        :class="applyClasses"
        :disabled="disabled"
        inputmode="decimal"
        @input="$emit('input', $event)"
        @blur="$emit('blur', $event)"
        :error="error" />
    </template>
    <template #input-errors>
      <input-error v-if="error" :error="error" />
      <input-info v-if="info" :info="info" />
    </template>
  </basic-input>
</template>

<script setup lang="ts">
import { computed, watchEffect } from 'vue'
import InputError from '@/components/BasicInputs/InputError.vue'
import InputInfo from '@/components/BasicInputs/InputInfo.vue'
import InfoCircle from '@/components/InfoCircle/InfoCircle.vue'
import BasicInput from '@/components/BasicInputs/BasicInput.vue'
import InputLabel from '@/components/BasicInputs/InputLabel.vue'
import BasicInputCurrency from '@/components/BasicInputs/BasicInputCurrency.vue'

type Props = {
  name: string
  label?: string
  title?: string
  value?: string | number
  placeholder?: string
  inputClasses?: string
  info?: string
  error?: string
  negativeAllowed?: boolean
  required?: boolean
  disabled?: boolean
  smallInput?: boolean
  stackLabel?: boolean
  tooltip?: Record<string, unknown>
}
const props = withDefaults(defineProps<Props>(), {
  label: '',
  title: '',
  value: '',
  negativeAllowed: false,
  placeholder: '$0',
  inputClasses: '',
  info: '',
  error: '',
  required: false,
  disabled: false,
  smallInput: false,
  stackLabel: false,
  tooltip: () => ({
    colour: undefined,
    class: undefined
  })
})

defineEmits<{
  (e: 'input', val: number | string): void
  (e: 'blur', val: number | string): void
}>()

const applyClasses = computed(() => {
  return props.inputClasses + `${props.smallInput ? ' p-2' : ' p-4'}`
})
</script>

<style scoped></style>
