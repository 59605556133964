const getAllWhiteLabels = () => {
  return {
    ahomeformumordad: {
      analyticsId: 'UA-57564901-8',
    },
    austrac: {
      analyticsId: 'UA-57564901-8',
    },
    bestinterestadvice: {
      analyticsId: 'UA-57564901-2',
    },
    bdo: {
      analyticsId: 'UA-57564901-8',
    },
    cenitex: {
      analyticsId: 'UA-57564901-32',
    },
    certefinancialwellbeing: {
      analyticsId: 'UA-57564901-32',
    },
    dmirs: {
      analyticsId: 'UA-57564901-8',
    },
    fordscott: {
      analyticsId: 'UA-57564901-8',
    },
    financialchoice: {
      analyticsId: 'UA-57564901-8',
    },
    djacapital: {
      analyticsId: 'UA-57564901-8',
    },
    vigourmedia: {
      analyticsId: 'UA-57564901-31',
    },
    merck: {
      analyticsId: 'UA-57564901-8',
    },
    tfnsw: {
      analyticsId: 'UA-57564901-30',
    },
    wapha: {
      analyticsId: 'UA-57564901-28',
    },
    ing: {
      analyticsId: 'UA-57564901-27',
    },
    together: {
      analyticsId: 'UA-57564901-26',
    },
    necaus: {
      analyticsId: 'UA-57564901-25',
    },
    surfcoastshirevic: {
      analyticsId: 'UA-57564901-24',
    },
    meem: {
      analyticsId: 'UA-57564901-21',
    },
    qldrail: {
      analyticsId: 'UA-57564901-22',
    },
    surmount: {
      analyticsId: 'UA-57564901-23',
    },
    sunsuperemployees: {
      analyticsId: 'UA-57564901-13',
    },
    mygoalgetter: {
      analyticsId: 'UA-57564901-14',
    },
    hallandwilcox: {
      analyticsId: 'UA-57564901-15',
    },
    suncorpstaff: {
      analyticsId: 'UA-57564901-12',
    },
    stanfordbrown: {
      analyticsId: 'UA-57564901-12',
    },
    latitude: {
      analyticsId: 'UA-57564901-9',
    },
    benestarstaff: {
      analyticsId: 'UA-57564901-11',
    },
    benestar: {
      analyticsId: 'UA-57564901-8',
    },
    medibankstaff: {
      analyticsId: 'UA-57564901-17',
    },
    woodside: {
      analyticsId: 'UA-57564901-18',
    },
    riotinto: {
      analyticsId: 'UA-57564901-19',
    },
    sharesight: {
      analyticsId: 'UA-57564901-19',
    },
    telstra: {
      analyticsId: 'UA-57564901-20',
    },
    ey: {
      analyticsId: 'UA-57564901-4',
    },
    financialresilience: {
      analyticsId: 'UA-57564901-33',
    },
    unblockyourwealth: {
      analyticsId: 'UA-57564901-33',
    },
    moneydna: {
      analyticsId: 'UA-57564901-33',
    },
    pacifichydro: {
      analyticsId: 'UA-57564901-1',
    },
    tasnetworks: {
      analyticsId: 'UA-57564901-8',
    },
    wellbeinggateway: {
      analyticsId: 'UA-57564901-7',
    },
    www: {
      analyticsId: 'UA-57564901-1',
    },
  }
}

const getHostName = () => {
  let host = ''
  let hostNamesArray = window.location.hostname.split('.', 1)
  if (hostNamesArray.length > 0) {
    hostNamesArray = hostNamesArray[0].split('-', 1)
    if (hostNamesArray.length > 0) {
      host = hostNamesArray[0]
    }
  }

  return host.toLowerCase()
}
const getWhiteLabelFromHost = () => {
  const host = getHostName()
  if (
    import.meta.env.MODE === 'development' ||
    host === 'staging' ||
    host === 'dev' ||
    window.location.hostname === 'otivo.devel' ||
    window.location.hostname === 'stage.otivo.dev' ||
    window.location.hostname === 'uat.otivo.dev' ||
    window.location.hostname === 'dev-1.otivo.dev' ||
    window.location.hostname === 'dev-2.otivo.dev' ||
    window.location.hostname === 'dev-3.otivo.dev'
  ) {
    return 'www'
  }

  return host
}

/**
 * TODO:
 * REPLACE THIS AS A NAV BUILDER - REBUILD ENTIRE SIDE MENU
 *
 **/
const getLinksSpecificToWhiteLabels = (getCurrentWhitelabelObject, authenticated) => {
  const servicesPages = {
    Services: {
      employees: {
        name: 'Employee wellbeing',
        link: '/employee-wellbeing',
      },
      financialAdviceFirms: {
        name: 'Advice practices',
        link: '/advice-practices',
      },
      superFunds: {
        name: 'Super funds',
        link: '/super-funds',
      },
      personalPricing: {
        name: 'Personal pricing',
        link: '/personal-pricing',
      },
    },
  }
  const brandPages = {
    About: {
      aboutOtivo: {
        name: 'About Otivo',
        link: '/our-team',
      },
      // adviceOverview: {
      //   name: 'Advice overview',
      //   link: '/advice-overview',
      // },
      howWeMakeMoney: {
        name: 'How Otivo makes money',
        link: '/how-otivo-makes-money',
      },
      security: {
        name: 'Security',
        link: '/security',
      },
      media: {
        name: 'Media',
        link: '/media',
      },
      contactUs: {
        name: 'Contact us',
        link: '/contact',
      },
    },
    Resources: {
      financialFitness: {
        name: 'Financial fitness test',
        link: '/financial-fitness',
      },
      // wealthCalculator: {
      //   name: 'Wealth calculator',
      //   link: '/wealth-calculator'
      // },
      // helpCentre: {
      //   name: 'Help centre',
      //   link: '/help-centre'
      // },
      faq: {
        name: 'FAQ',
        link: '/faq',
      },
      learn: {
        name: 'Learn',
        link: '/learn',
      },
      otivoSuperReport: {
        name: 'Otivo Super Report',
        link: '/super-report',
      },
      // Nathan request:
      // Removed temporarily as its not working properly in production
      // askOtivoSuper: {
      //   name: 'Ask Otivo super',
      //   link: '/ask-otivo-super'
      // }
    },
  }
  const legalStuff = {
    Legal_stuff: {
      privacy: {
        name: 'Privacy',
        link: !getCurrentWhitelabelObject.whitelabelPrivacyPolicy?.includes('/content/privacy')
          ? getCurrentWhitelabelObject.whitelabelPrivacyPolicy
          : '/content/privacy',
      },
      financialServiceGuide: {
        name: 'Financial services guide',
        link: !getCurrentWhitelabelObject.whitelabelFSG?.includes('/fsg')
          ? getCurrentWhitelabelObject.whitelabelFSG
          : 'https://store.otivo.com/FSG/FSCGv1.pdf',
      },
      termsAndConditions: {
        name: 'Terms and conditions',
        link: !getCurrentWhitelabelObject.whitelabelTermsAndConditions?.includes(
          '/content/termsAndConditions',
        )
          ? getCurrentWhitelabelObject.whitelabelTermsAndConditions
          : '/content/termsAndConditions',
      },
      copyright: {
        name: 'Copyright',
        link:
          getCurrentWhitelabelObject.whitelabelCopyright !== null
            ? getCurrentWhitelabelObject.whitelabelCopyright
            : null,
      },
    },
  }
  let allLinks = { ...brandPages, ...legalStuff }
  if (getWhiteLabelFromHost() === 'www') allLinks = { ...servicesPages, ...allLinks }
  if (authenticated) {
    delete allLinks.Solutions
    return allLinks
  }
  return allLinks
}

const getAssetsS3BucketLink = () => 'https://store.otivo.com'

const getAPIUrl = () => {
  if (import.meta.env.MODE === 'development') {
    if (import.meta.env.VITE_TEST_BASE_API_URL) {
      return import.meta.env.VITE_TEST_BASE_API_URL
    }

    if (location.hostname !== '127.0.0.1') {
      return `https://api-${location.hostname}/api`
    }
  } else return `https://api-${location.hostname}/api`
}

const getSiteUri = () => {
  return `${location.origin}/`
}

const getLogoutUri = () => {
  return 'otivo://home'
}

const getRedirectUri = () => {
  return `${getSiteUri()}auth`
}

const getBrowser = () => {
  // eslint-disable-next-line one-var
  const ua = navigator.userAgent
  let tem
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
  if (/iPad|iPhone/.test(ua)) {
    return {
      name: 'iPhone',
    }
  }
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return { name: 'IE', version: tem[1] || '' }
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR\/(\d+)/)
    if (tem != null) {
      return { name: 'Opera', version: tem[1] }
    }
  }
  if (window.navigator.userAgent.indexOf('Edge') > -1) {
    // eslint-disable-next-line no-useless-escape
    tem = ua.match(/Edge\/(\d+)/)
    if (tem != null) {
      return { name: 'Edge', version: tem[1] }
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1])
  }
  return {
    name: M[0],
    version: +M[1],
  }
}

const downloadSOA = (data, fileName = 'soa.pdf') => {
  downloadFile('pdf', data, fileName)
}

const downloadFile = (fileType = 'pdf', base64Data, fileName) => {
  let blobType = ''
  let linkSource = ''
  switch (fileType) {
    case 'pdf':
      linkSource = `data:application/pdf;base64,${base64Data}`
      blobType = 'application/pdf'
      break
    case 'zip':
      linkSource = `data:text/plain;base64,${base64Data}`
      blobType = 'application/zip'
      break
  }

  if (browserRequiresBlob()) {
    base64Data = base64Data.replace('\r', '')
    base64Data = base64Data.replace('\n', '')
    base64Data = base64Data.replace(/\s/g, '')
    const bytes = formatBase64(base64Data)
    const blob = new Blob([bytes], { type: blobType })
    if (browserIsIE()) {
      window.navigator.msSaveOrOpenBlob(blob, fileName)
    } else {
      const url = URL.createObjectURL(blob)
      if (getBrowser().name === 'iPhone') {
        window.location.assign(url)
      } else {
        window.open(url, '_blank')
      }
    }
  } else {
    const downloadLink = document.createElement('a')
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }
}

const browserRequiresBlob = () => {
  const browserName = getBrowser().name
  return browserIsIE() || browserName === 'Safari' || browserName === 'iPhone'
}

const browserIsIE = () => {
  const browserName = getBrowser().name
  return (
    (window.navigator && window.navigator.msSaveOrOpenBlob) ||
    browserName === 'MSIE' ||
    browserName === 'IE'
  )
}

const formatBase64 = (base64Data) => {
  const binaryString = window.atob(base64Data)
  const binaryLen = binaryString.length
  const bytes = new Uint8Array(binaryLen)
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes
}

/**
 * Move this to NOVA
 **/
const getSurmountCompanies = () => {
  return [
    { value: 'alice_springs_town_council', name: 'Alice Springs Town Council' },
    { value: 'all_hallows_school', name: 'All Hallows School' },
    { value: 'aveo_group_limited', name: 'Aveo Group Limited' },
    { value: 'ballina_shire_council', name: 'Ballina Shire Council' },
    { value: 'banana_shire_council', name: 'Banana Shire Council' },
    { value: 'barkly_regional_council', name: 'Barkly Regional Council' },
    { value: 'brighter_access', name: 'Brighter Access' },
    { value: 'brisbane_grammar_school', name: 'Brisbane Grammar School' },
    { value: 'bundaberg_regional_council', name: 'Bundaberg Regional Council' },
    { value: 'byron_shire_council', name: 'Byron Shire Council' },
    { value: 'cairns_regional_council', name: 'Cairns Regional Council' },
    { value: 'cassowary_coast_regional_council', name: 'Cassowary Coast Regional Council' },
    { value: 'catholic_education_services_-_cairns', name: 'Catholic Education Services - Cairns' },
    {
      value: 'central_australian_aboriginal_congress',
      name: 'Central Australian Aboriginal Congress',
    },
    { value: 'central_desert_regional_council', name: 'Central Desert Regional Council' },
    { value: 'charles_darwin_university', name: 'Charles Darwin University' },
    { value: 'city_of_darwin', name: 'City of Darwin' },
    { value: 'city_of_subiaco', name: 'City Of Subiaco' },
    { value: 'cruise_whitsundays_pty_ltd', name: 'Cruise Whitsundays Pty Ltd' },
    { value: 'electro_optic_systems', name: 'Electro Optic Systems' },
    { value: 'fredon_qld_pty_limited', name: 'Fredon (Qld) Pty Limited' },
    { value: 'g_&_s_engineering_services', name: 'G & S Engineering Services' },
    { value: 'genesis_care_m3', name: 'Genesis Care M3' },
    { value: 'gladstone_regional_council', name: 'Gladstone Regional Council' },
    { value: 'glenelg_shire_council', name: 'Glenelg Shire Council' },
    { value: 'golding_contractors_pty_ltd', name: 'Golding Contractors Pty Ltd' },
    { value: 'gwydir_shire_council', name: 'Gwydir Shire Council' },
    { value: 'gympie_regional_council', name: 'Gympie Regional Council' },
    { value: 'hdr_pty_ltd', name: 'HDR Pty Ltd' },
    { value: 'hillcrest_christian_college', name: 'Hillcrest Christian College' },
    { value: 'hinchinbrook_shire_council', name: 'Hinchinbrook Shire Council' },
    { value: 'ior_services', name: 'IOR Services' },
    { value: 'ipswich_city_council', name: 'Ipswich City Council' },
    { value: 'kdr_gold_coast_pty_ltd', name: 'KDR Gold Coast Pty Ltd' },
    { value: 'liebherr-australia_pty_ltd', name: 'Liebherr-Australia Pty Ltd' },
    { value: 'livingstone_shire_council', name: 'Livingstone Shire Council' },
    { value: 'lockyer_valley_regional_council', name: 'Lockyer Valley Regional Council' },
    { value: 'logicamms_ltd', name: 'LogiCamms Ltd' },
    { value: 'mackay_regional_council', name: 'Mackay Regional Council' },
    { value: 'mackellar_epsa_pty_ltd', name: 'Mackellar EPSA Pty Ltd' },
    {
      value: 'michael_page_international_australia_pty_ltd',
      name: 'Michael Page International (Australia) Pty Ltd',
    },
    { value: 'moorvale_earthmoving_pty_ltd', name: 'Moorvale Earthmoving Pty Ltd' },
    { value: 'moree_plains_shire_council', name: 'Moree Plains Shire Council' },
    { value: 'motors_tas_pty_ltd', name: 'Motors Tas Pty Ltd' },
    { value: 'mynesight_pty_ltd', name: 'Mynesight Pty Ltd' },
    { value: 'nilsen_nt_pty_ltd', name: 'Nilsen (NT) Pty Ltd' },
    { value: 'noosa_shire_council', name: 'Noosa Shire Council' },
    { value: 'orange_city_council', name: 'Orange City Council' },
    { value: 'pacific_industrial_company', name: 'Pacific Industrial Company' },
    { value: 'paspaley_pearls_pty_ltd', name: 'Paspaley Pearls Pty Ltd' },
    { value: 'port_macquarie_hastings_council', name: 'Port Macquarie Hastings Council' },
    { value: 'prok_conveyor', name: 'PROK Conveyor' },
    { value: 'queanbeyan-palerang_regional_council', name: 'Queanbeyan-Palerang Regional Council' },
    { value: 'red_hat_asia-pacific_pty_ltd', name: 'Red Hat Asia-Pacific Pty Ltd' },
    { value: 'rockhampton_regional_council', name: 'Rockhampton Regional Council' },
    { value: 'roper_gulf_regional_council', name: 'Roper Gulf Regional Council' },
    { value: 'salvation_army_employment_plus', name: 'Salvation Army Employment Plus' },
    { value: 'sandvik_australia', name: 'Sandvik Australia' },
    { value: 'sandvik_mining_&_construction', name: 'Sandvik Mining & Construction' },
    { value: 'scenic_rim_regional_council', name: 'Scenic Rim Regional Council' },
    { value: 'see_civil_pty_ltd', name: 'SEE Civil Pty Ltd' },
    { value: 'singleton_council', name: 'Singleton Council' },
    { value: 'southcott_pty_ltd', name: 'Southcott Pty Ltd' },
    { value: 'southern_downs_regional_council', name: 'Southern Downs Regional Council' },
    { value: 'southern_grampians_shire_council', name: 'Southern Grampians Shire Council' },
    { value: 'st_patricks_college_shorncliffe', name: "St Patrick's College Shorncliffe" },
    { value: 'tablelands_regional_council', name: 'Tablelands Regional Council' },
    {
      value: 'the_information_management_group_pty_ltd',
      name: 'The Information Management Group Pty Ltd',
    },
    { value: 'timevale_pty_ltd', name: 'Timevale Pty Ltd' },
    { value: 'tweed_shire_council', name: 'Tweed Shire Council' },
    { value: 'unitywater', name: 'Unitywater' },
    { value: 'university_of_the_sunshine_coast', name: 'University of the Sunshine Coast' },
    { value: 'vanderfield', name: 'Vanderfield' },
    { value: 'victoria_daly_regional_council', name: 'Victoria Daly Regional Council' },
    { value: 'western_downs_regional_council', name: 'Western Downs Regional Council' },
    { value: 'whitsunday_regional_council', name: 'Whitsunday Regional Council' },
    { value: 'wickham_freight_lines_pty_ltd', name: 'Wickham Freight Lines Pty Ltd' },
    { value: 'wollongong_city_council', name: 'Wollongong City Council' },
    { value: 'ymca_of_the_northern_territory', name: 'YMCA of the Northern Territory' },
  ]
}

const getStanfordBrownCompanies = () => {
  return [
    { value: 'stanfordbrown', name: 'Stanford Brown' },
    { value: 'dynatrace', name: 'Dynatrace' },
  ]
}

const reportUrls = (reportName) => {
  const s3Url = getAssetsS3BucketLink()
  const reports = {
    covid: `${s3Url}/Reports/Financial_stress_during_COVID_19_May2020.pdf`,
    general: `${s3Url}/Reports/MapMyPlan_Financial_Stress_in_Australian_Workplace.pdf`,
  }

  return reports[reportName] ? reports[reportName] : reports.general
}

const alternateContactMessageTypes = () => {
  return [
    {
      name: 'Wealth Creation',
      value: 'wealth_creation',
      selected: false,
    },
    {
      name: 'Retirement',
      value: 'retirement',
      selected: false,
    },
    {
      name: 'Insurance',
      value: 'insurance',
      selected: false,
    },
    {
      name: 'Investments',
      value: 'investments',
      selected: false,
    },
    {
      name: 'Superannuation',
      value: 'super',
      selected: false,
    },
    {
      name: 'Other',
      value: 'other',
      selected: false,
    },
  ]
}

export {
  getHostName,
  getSurmountCompanies,
  getStanfordBrownCompanies,
  getWhiteLabelFromHost,
  getLinksSpecificToWhiteLabels,
  getAssetsS3BucketLink,
  getAllWhiteLabels,
  getAPIUrl,
  getSiteUri,
  getLogoutUri,
  getRedirectUri,
  getBrowser,
  downloadSOA,
  downloadFile,
  reportUrls,
  alternateContactMessageTypes,
}
