<template>
  <div class="flex justify-center items-start h-100% py-[80px]">
    <div
      class="text-grey-1 bg-white rounded-[10px] w-[1366px] border-[1px] border-blue-5 otivo-drop-shadow">
      <div class="p-[40px] flex flex-col justify-between h-100%">
        <div class="flex justify-between">
          <h3 class="text-blue-1">Super user admin table</h3>
        </div>
        <div class="flex justify-center items-center py-[40px] gap-4">
          <portalSearchInput
            @search="searchByField"
            @input="searchByField"
            name="Search Input"
            place-holder="Nick Dare"
            class="w-50%" />
        </div>
        <div class="text-center flex justify-center -align-center">
          <OtivoButton @click="triggerCreateModal" colour="blue" size="large" class="px-8">
            Create staff account
          </OtivoButton>
        </div>
        <LumpSumSkeleton v-if="!showPortalStaff" />
        <div class="flex-grow mt-[40px] mx-auto" v-else>
          <table class="border-0 bg-grey-4">
            <thead>
              <tr>
                <th v-for="(field, index) in fields" :key="index">
                  <div class="p-4 cursor-pointer w-full flex gap-4 bg-grey-4" v-if="field.value">
                    {{ field.label }}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="border border-grey-5 bg-white">
                <td colspan="8" v-if="loading">
                  <BeatLoader class="my-[40px] text-center" color="var(--otivo-blue)" />
                </td>
              </tr>
            </tbody>
            <SuperUserAdminTableRows
              v-if="portalStaff"
              :table-data="portalStaff"
              @edit="openEditStaffModal"
              @delete="deleteStaffMember"
              @restore="restoreStaffMember" />
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import portalCreateStaffModal from '@/components/CFS/CFSCreateStaffModal.vue'
import portalEditStaffModal from '@/components/CFS/CFSEditStaffModal.vue'
import portalSearchInput from '@/components/CFS/CFSSearchInput.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import SuperUserAdminTableRows from '@/components/CFS/SuperUserAdminTableRows.vue'
import LumpSumSkeleton from '@/components/CFS/Skeletons/LumpSumSkeleton.vue'
import { useTemporaryPortalStore } from '@/store/pinia/TemporaryPortalStore.ts'
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import CFSEditStaffModal from '@/components/CFS/CFSEditStaffModal.vue'
import CFSCreateStaffModal from '@/components/CFS/CFSCreateStaffModal.vue'

/**
 * TODO: refactor out vueX implementation
 * swap out TempStore for PortalStore for 3.0
 */

const vuexStore = useStore()
const portalStore = useTemporaryPortalStore()
const modalStore = useModalStore()

const loading = ref(false)
const fields = [
  {
    label: 'First name',
    value: 'first_name',
    type: 'string',
  },
  {
    label: 'Last name',
    value: 'last_name',
    type: 'string',
  },
  {
    label: 'Email',
    value: 'email',
    type: 'string',
  },
  {
    label: 'Contact center',
    value: 'contact_centre',
    type: 'boolean',
  },
  {
    label: 'Super user',
    value: 'super_user',
    type: 'boolean',
  },
  {
    label: 'Back office',
    value: 'back_office',
    type: 'boolean',
  },
  {
    label: 'Blocked',
    value: 'blocked',
    type: 'boolean',
  },
]

//Todo: refactor out portal object
const portalStaff = computed(() => portalStore.portalStaff)
const editModalIndex = ref(null)
const portalSearchableStaff = ref([])

watch(portalStaff, (newStaff) => {
  if (newStaff.length > 0) portalSearchableStaff.value = newStaff
})

onMounted(() => {
  portalStore.getAllPortalStaff()
})

const showPortalStaff = computed(() => portalStaff.value.length > 0)

const searchByField = (val) => {
  portalSearchableStaff.value = portalStaff.value.filter((staff) => {
    const name = (staff.first_name + ' ' + staff.last_name).toLowerCase()
    return name.includes(val.toLowerCase())
  })
}

const triggerCreateModal = () => {
  modalStore.openModal(CFSCreateStaffModal)
  // vuexStore.commit('SET_CFS_CREATE_STAFF_MODAL', true)
}

const restoreStaffMember = (index: number) => {
  portalStore.restoreStaffMember(portalStaff.value[index].id)
}

const deleteStaffMember = (index: number) => {
  portalStore.deleteStaffMember(portalStaff.value[index].id)
}

const editStaffMember = async (obj) => {
  loading.value = true
  await portalStore.editStaffMember(obj)
  loading.value = false
}

const openEditStaffModal = (index) => {
  modalStore.openModal(CFSEditStaffModal, {
    staffObject: portalStaff.value[index],
  })
  // vuexStore.commit('SET_CFS_EDIT_STAFF_MODAL', true)
}

const mapAccount = (member) => ({
  id: member.id,
  firstName: member.first_name,
  lastName: member.last_name,
  email: member.email,
  mobile: member.mobile,
  role: member.contact_centre ? 'callcentre' : 'backoffice',
  superUser: member.super_user,
})
</script>
