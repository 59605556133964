<template>
  <div class="flex">
    <div
      class="h-32 w-32 text-center mx-2 flex justify-around p-4 border opacity-50 cursor-pointer rounded lg:hover:border-otivo-blue"
      @click="$emit('click', 'male')"
      :class="{ selected: 'male' === selected }">
      <div class="self-center">
        <svg
          class="h-20 w-20"
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 53.545 53.545"
          style="enable-background: new 0 0 53.545 53.545"
          xml:space="preserve">
          <g>
            <g>
              <circle style="fill: #010002" cx="26.686" cy="4.507" r="4.507" />
              <path
                style="fill: #010002"
                d="M28.256,11.163c-1.123-0.228-2.344-0.218-3.447,0.042c-7.493,0.878-9.926,9.551-9.239,16.164
                c0.298,2.859,4.805,2.889,4.504,0c-0.25-2.41-0.143-6.047,1.138-8.632c0,3.142,0,6.284,0,9.425c0,0.111,0.011,0.215,0.016,0.322
                c-0.003,0.051-0.015,0.094-0.015,0.146c0,7.479-0.013,14.955-0.322,22.428c-0.137,3.322,5.014,3.309,5.15,0
                c0.242-5.857,0.303-11.717,0.317-17.578c0.244,0.016,0.488,0.016,0.732,0.002c0.015,5.861,0.074,11.721,0.314,17.576
                c0.137,3.309,5.288,3.322,5.15,0c-0.309-7.473-0.32-14.949-0.32-22.428c0-0.232-0.031-0.443-0.078-0.646
                c-0.007-3.247-0.131-6.497-0.093-9.742c1.534,2.597,1.674,6.558,1.408,9.125c-0.302,2.887,4.206,2.858,4.504,0
                C38.678,20.617,36.128,11.719,28.256,11.163z" />
            </g>
          </g>
        </svg>

        <div class="pt-1">Male</div>
      </div>
    </div>

    <div
      class="h-32 w-32 text-center mx-2 flex justify-around p-4 border opacity-50 cursor-pointer rounded lg:hover:border-otivo-blue"
      @click="$emit('click', 'female')"
      :class="{ selected: 'female' === selected }">
      <div class="self-center">
        <svg
          class="h-20 w-20"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          style="enable-background: new 0 0 512 512"
          xml:space="preserve">
          <g>
            <g>
              <path
                d="M384.977,276.115c-0.33-0.902-33.149-90.694-47.603-132.749c-9.325-27.136-24.962-40.895-46.474-40.895
                c-29.934,0-39.899,0-69.8,0c-21.512,0-37.149,13.759-46.474,40.895c-14.452,42.055-47.272,131.848-47.603,132.749
                c-3.812,10.425,1.549,21.968,11.975,25.779c10.425,3.812,21.968-1.551,25.779-11.975c0.273-0.748,22.776-62.314,38.824-107.645
                c1.435,4.712,4.37,8.758,8.233,11.627l0.048,29.451l-34.573,87.18c-1.885,4.755,0.875,10.067,5.834,11.259
                c5.161,1.24,11.996,2.93,19.41,4.782V487.88c0,13.321,10.799,24.12,24.12,24.12c13.321,0,24.12-10.799,24.12-24.12V337.975
                c3.464,0.307,6.948,0.305,10.413-0.006V487.88c0,13.321,10.799,24.12,24.12,24.12c13.321,0,24.12-10.799,24.12-24.12V326.551
                c7.407-1.852,14.236-3.538,19.398-4.776c4.924-1.18,7.731-6.492,5.84-11.261l-34.567-87.163l0.048-29.451
                c3.864-2.868,6.797-6.914,8.233-11.627c16.047,45.331,38.551,106.897,38.824,107.645c3.812,10.426,15.354,15.787,25.779,11.975
                C383.428,298.083,388.789,286.54,384.977,276.115z" />
            </g>
          </g>
          <g>
            <g>
              <circle cx="256" cy="43.898" r="43.898" />
            </g>
          </g>
        </svg>
        <div class="pt-1">Female</div>
      </div>
    </div>

    <div
      v-if="isItHallandwilcox"
      class="h-32 w-32 text-center mx-2 flex justify-around p-4 border opacity-50 cursor-pointer rounded lg:hover:border-otivo-blue"
      @click="$emit('click', 'non_binary')"
      :class="{ selected: 'non_binary' === selected }">
      <div class="self-center">
        <div class="pt-1">Non Binary</div>
      </div>
    </div>

    <div
      v-else
      class="h-32 w-32 text-center mx-2 flex justify-around p-4 border opacity-50 cursor-pointer rounded lg:hover:border-otivo-blue"
      @click="$emit('click', 'unknown')"
      :class="{ selected: 'unknown' === selected }">
      <div class="self-center">
        <div class="pt-1">Prefer not to say</div>
      </div>
    </div>

    <div
      v-if="isItHallandwilcox"
      class="h-32 w-32 text-center mx-2 flex justify-around p-4 border opacity-50 cursor-pointer rounded lg:hover:border-otivo-blue"
      @click="$emit('click', 'other')"
      :class="{ selected: 'other' === selected }">
      <div class="self-center">
        <div class="pt-1">Other</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWhiteLabelFromHost } from '@/lib/WhiteLabelHelpers'
import { mapGetters } from 'vuex'

export default {
  name: 'genderAnswer',
  props: {
    selected: {
      type: String,
      required: true
    }
  },
  computed: {
    getWhiteLabel() {
      return getWhiteLabelFromHost()
    },

    ...mapGetters(['isItHallandwilcox'])
  }
}
</script>

<style scoped>
.selected svg * {
  @apply fill-otivo-blue !important;
}
.selected svg {
  @apply fill-otivo-blue;
}
</style>
