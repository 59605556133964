import InvestmentOption from '@/types/api/super/InvestmentOption.ts'
import Allocation from '@/types/api/super/Allocation.ts'
import { cloneObject } from '@/utilities.ts'

export type IntrafundAdviceRequest = {
  id: number
  advice_stage: number
  change_actioned: boolean
  last_actioned: string | null
  requested_change_id: number | null
  investment_switch_requested_at: string | null
}

type SuperObject = {
  id: string
  fund_name: string
  owner: string
  balance: number
  phase: string
  defined_benefit: boolean
  nominated_beneficiary: string
  retirement_age: number
  desired_annual_income: number
  salary_sacrifice: number
  contribution_rate: number
  chant_west_product_id: number
  self_managed: 0 | 1
  investments: InvestmentOption[]
  insurance?: unknown[] // Replace when defined
  updated_at: string
  account_number: string
  advice_request: IntrafundAdviceRequest | null
}

export const createNewSuperObject = (): SuperObject =>
  cloneObject({
    id: '',
    fund_name: 'New super product',
    owner: 'single',
    balance: 0,
    phase: 'accumulation',
    defined_benefit: false,
    nominated_beneficiary: 0,
    retirement_age: 67,
    desired_annual_income: 0,
    salary_sacrifice: 0,
    contribution_rate: 0,
    chant_west_product_id: 0,
    self_managed: 0,
    investments: [],
    updated_at: '',
    account_number: '',
    advice_request: 1,
  })

export default SuperObject

export type SuperPayload = {
  auth0id?: string
  account_number: string
  allocation: Allocation
  chant_west_product_id?: number
  balance: number
  data_source: string
}
