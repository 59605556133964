<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    viewBox="0 0 1440 20"
    :class="[
      `stroke-${fill} fill-${fill}`,
      [isUserOnMobile() ? [[rotate ? 'mb-[-1px]' : 'mt-[-1px]']] : ''],
    ]">
    <path
      :class="[{ rotate: rotate }, `fill-${fill}`]"
      d="M0 26V0c160.957 8.44773 435.852 25.9906 759.738 26h.54C1077.65 25.9913 1327.55 11.0468 1500 0v26H0ZM760.278 26h-.54.54Z" />
  </svg>
</template>

<script setup lang="ts">
import { isUserOnMobile } from '@/utilities'

interface Props {
  fill?: string
  rotate?: boolean
}

withDefaults(defineProps<Props>(), {
  fill: 'white',
  rotate: false,
})
</script>

<style lang="scss" scoped>
.rotate {
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}
</style>
