<template>
  <div id="cfs-assumptions-modal" style="font-family: 'IBM Plex Sans', Raleway, serif">
    <div
      class="cursor-pointer mt-4 flex gap-2 items-center"
      @click="
        () => {
          showModal = true
        }
      ">
      <arrow-side />
      <span class="text-cfs-blue mp2 md:p2"> How we worked this out </span>
      <info-circle css-class="cfs-tooltip" fill="cfs-blue" message="Click here!" />
    </div>

    <modal
      v-if="showModal"
      :id="`cfs-assumption-modal`"
      @close="
        () => {
          showModal = false
        }
      ">
      <template #header>
        <div class="mh4 md:h4" style="font-family: 'IBM Plex Sans', Raleway, serif">
          Change assumptions
        </div>
      </template>
      <template #body>
        <loader v-if="loading" />
        <div v-else style="font-family: 'IBM Plex Sans', Raleway, serif">
          <input-percentage
            class="py-3"
            name="inflation-rate"
            label="Inflation rate"
            :value="inflation"
            @input="setInflationRate"
            :min="0"
            :max="5" />
          <input-percentage
            class="py-3"
            name="wage-growth"
            label="Wage growth"
            :value="wageGrowth"
            @input="setWageGrowth"
            :min="0"
            :max="6" />
        </div>

        <!--    investment returns and tax    -->
        <div
          class="flex font-medium text-center py-2 pt-4 gap-3"
          style="font-family: 'IBM Plex Sans', Raleway, serif">
          <div class="w-25% md:min-w-30%">Investment option</div>
          <div class="w-20%">Investment return</div>
          <div class="w-20%">Tax on earnings</div>
          <div class="w-20%">Fees (%)</div>
        </div>
        <!-- investments -->
        <div
          v-for="(assumption, index) in assumptionValues"
          :key="`assumption-${index}`"
          class="py-3 flex gap-3 items-center"
          style="font-family: 'IBM Plex Sans', Raleway, serif">
          <div class="flex w-25% md:min-w-30%">
            {{ getAssumptionName(assumption.type) }}
          </div>

          <input-percentage
            class="flex w-20%"
            :value="assumption.rateOfReturn"
            name="Investment return"
            @input="
              (value) => {
                changeAssumptionReturn(`${assumption.type}`, value)
              }
            " />

          <input-percentage
            v-if="assumption.type === 'superFunds'"
            class="flex w-20%"
            :name="`${assumption.type}-tax-earnings-${index}`"
            :value="assumption.taxOnEarnings"
            @input="
              (value) => {
                changeTaxOnEarnings(`${assumption.type}`, value)
              }
            "
            :min="0"
            :max="15" />
          <div v-else class="flex w-20% p-4" />

          <input-percentage
            v-if="assumption.type === 'superFunds' || assumption.type === 'superFundsDrawDown'"
            class="flex w-20%"
            :name="`${assumption.type}-admin-fee-${index}`"
            :value="assumption.adminFee"
            @input="
              (value) => {
                changeAdminRate(`${assumption.type}`, value)
              }
            "
            :min="0"
            :max="5"
            :disabled="assumption.name.toLowerCase() === 'current investment'" />
          <div v-else class="flex w-20% p-4" />
        </div>
        <div style="font-family: 'IBM Plex Sans', Raleway, serif" class="flex flex-col gap-6 my-6">
          <input-currency
            name="Annual Insurance Premium"
            label="Annual Insurance Premium"
            :value="insurancePremium"
            @input="updateInsurancePremium"
            class="w-full" />
          <input-currency
            name="Annual Admin Fees"
            label="Annual Admin Fees"
            :value="annualAdminFees"
            @input="updateAnnualAdminFees"
            class="w-full" />
        </div>
      </template>

      <template #footer>
        <div>
          <div class="text-right" v-if="!loading">
            <custom-button
              class="p-3 w-32 button-2"
              @click="reRenderGraph()"
              colour="cfs-button"
              style="font-family: 'IBM Plex Sans', Raleway, serif">
              Save
            </custom-button>
          </div>
          <hr class="my-5" />
          <more-retirement-assumptions
            plan="CFS_RETIREMENT_INCOME_CALC"
            style="font-family: 'IBM Plex Sans', Raleway, serif" />
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import CustomButton from '@/components/CustomButton/CustomButton.vue'
import Modal from '@/components/Modals/Modal.vue'
import InputPercentage from '@/components/BasicInputs/InputPercentage.vue'
import InfoCircle from '@/components/InfoCircle/InfoCircle.vue'
import { mapActions, mapGetters } from 'vuex'
import { formatPercentage } from '@/lib/helpers'
import Loader from '@/components/Loader/Loader.vue'
import MoreRetirementAssumptions from '@/components/RetirementInvestment/Summary/MoreRetirementAssumptions.vue'
import ArrowSide from '@/components/icons/ArrowSide.vue'
import InputCurrency from '@/components/BasicInputs/InputCurrency.vue'
import { useToast } from '@/composables/useToast.ts'

const { successToast, errorToast } = useToast()

export default {
  name: 'RetirementCalculatorChangeAssumptions',

  components: {
    InputCurrency,
    ArrowSide,
    MoreRetirementAssumptions,
    Loader,
    InfoCircle,
    InputPercentage,
    Modal,
    CustomButton,
  },

  data() {
    return {
      showModal: false,
      loading: false,
    }
  },

  methods: {
    ...mapActions('RetirementCalculator', ['getRetirementCalculatorData']),
    getSelectedValue(type) {
      const selected = this.retirementCalculator.assumptionValues.filter((el) => el.type === type)
      if (selected.length === 0) {
        return 'balanced'
      }

      return selected[0].value
    },

    updateInsurancePremium(value) {
      this.$store.commit('RetirementCalculator/UPDATE_INSURANCE_PREMIUM', value)
    },

    updateAnnualAdminFees(value) {
      this.$store.commit('RetirementCalculator/UPDATE_ANNUAL_ADMIN_FEES', value)
    },

    changeSelectedValue(type, value, label) {
      this.$store.commit('RetirementCalculator/UPDATE_RET_CALC_ASSUMPTION_VALUE', {
        type: type,
        value: value,
        label: label,
      })
    },

    changeTaxOnEarnings(type, value) {
      this.$store.commit('RetirementCalculator/SET_RET_CALC_TAX_ON_EARNINGS', {
        type: type,
        value: value,
      })
    },

    changeAssumptionReturn(type, value) {
      this.$store.commit('RetirementCalculator/SET_RET_CALC_INVEST_RETURN', {
        type: type,
        value: value,
      })
    },

    changeAdminRate(type, value) {
      this.$store.commit('RetirementCalculator/SET_RET_CALC_INVEST_ADMIN_FEE', {
        type: type,
        value: value,
      })
    },

    setInflationRate(value) {
      // this.$store.dispatch('setInvestInflationRate', value)
      this.$store.commit('RetirementCalculator/SET_INVEST_INFLATION_RATE', value)
    },

    setWageGrowth(value) {
      // this.$store.dispatch('setInvestmentWageGrowth', value)
      this.$store.commit('RetirementCalculator/SET_INVESTMENT_WAGE_GROWTH', value)
    },

    setDiscountRate(value) {
      this.$store.dispatch('setInvestDiscountRate', value)
    },

    reRenderGraph() {
      this.loading = true
      this.$store
        .dispatch('RetirementCalculator/getRetirementCalculatorData', {
          root: true,
        })
        .then(() => {
          successToast('Assumptions are updated')
          this.loading = false
          this.showModal = false
        })
        .catch((err) => {
          this.loading = false
          errorToast('Error occurred, please try again')
          throw err
        })
    },

    getTaxOnEarnings(rate) {
      return rate ? formatPercentage(rate) : 'N/A'
    },

    getAssumptionName(type) {
      const typeNames = {
        shares: 'Shares',
        superFunds: 'Superannuation',
        superFundsDrawDown: 'Superannuation after retirement',
        cashAndFixed: 'Cash',
        managedFunds: 'Managed funds and unlisted',
        property: 'Investment property',
        alternative: 'Other',
      }

      return typeNames[type]
    },
    assumptionArray(assumption) {
      const outputArray = [
        { value: 'cash', label: 'Cash' },
        { value: 'conservative', label: 'Conservative' },
        { value: 'moderate', label: 'Moderate' },
        { value: 'balanced', label: 'Balanced' },
        { value: 'default', label: 'Starting assumption' },
        { value: 'growth', label: 'Growth' },
        { value: 'highGrowth', label: 'High Growth' },
      ]
      if (assumption.type === 'super' && this.supers[0].chant_west_product_id !== '') {
        outputArray.push({
          value: 'Current investment',
          label: 'Current investment',
        })
      }
      return outputArray
    },
  },

  computed: {
    ...mapGetters(['investments', 'supers']),
    ...mapGetters('RetirementCalculator', [
      'assumptionValues',
      'insurancePremium',
      'annualAdminFees',
      'wageGrowth',
      'inflation',
    ]),
  },
}
</script>

<style scoped>
.custom-percentage :deep(.input-label) {
  @apply hidden;
}

.custom-percentage :deep(input) {
  @apply text-center font-medium;
}

.hide-input-label :deep(.input-label) {
  @apply hidden;
}
</style>
