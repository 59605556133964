<template>
  <div :id="`${type}-dropdown`" class="relative border-dashed" ref="dropdown">
    <div class="relative w-full">
      <input
        autocomplete="off"
        name="dropdown-filter"
        type="text"
        :value="inputString"
        :placeholder="selected.label"
        tabindex="0"
        @focus="
          () => {
            inputString = ''
            open = true
          }
        "
        @keydown.enter="open = !open"
        @input="(event: Event) => filter((event.target as HTMLInputElement).value)"
        class="dropdown-filter flex justify-between border-grey-3 enabled:hover:otivo-outline enabled:focus:otivo-outline enabled:active:otivo-outline py-[10px] px-[15px] border-[1px] rounded-[5px] text-button-3 w-full"
        :class="{
          ['rounded-b-none otivo-outline']: open,
          'bg-grey-4 border-grey-field cursor-not-allowed': disabled,
        }"
        :disabled="disabled" />
      <Chevron
        @click="
          () => {
            inputString = ''
            open = true
          }
        "
        fill="#696969"
        class="absolute top-50% transform translate-y-[-50%] right-[15px]"
        :class="{ ['rotate-180']: open }" />
    </div>
    <div
      v-if="open"
      class="absolute bg-white w-100% border border-t-0 border-grey-2 otivo-outline border-r-0 rounded rounded-t-none drop-shadow overflow-hidden z-10">
      <div
        v-if="filteredItems.length"
        class="max-h-[150px] overflow-auto"
        data-test="dropDownResults">
        <div
          tabindex="0"
          v-for="(item, idx) in filteredItems"
          :id="`item-${idx}`"
          :key="idx"
          @click="select(item)"
          @keydown.enter="select(item)"
          :data-test="`${type}-result-${sanitise((item.label as string).toLowerCase())}`"
          class="cursor-pointer p-4 text-14px font-medium hover:bg-blue-4 :active:otivo-outline :focus:otivo-outline focus:bg-otivo-4">
          {{ item.label }}
        </div>
      </div>
      <div v-else class="flex justify-center pt-4 pb-4">
        <SyncLoader v-if="loading" color="#0064FF" />
        <div class="font-medium" v-else>No results</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
/**
 * Dropdown:
 * @param type: string
 * @param items: InputItem[]
 * @param existingItem: InputItem | null
 */
import { computed, onMounted, ref, watchEffect } from 'vue'
import { sanitise } from '@/composables/sanitise'
import { onClickOutside } from '@vueuse/core'
import { InputItem } from '@/types/InputItem.ts'
import Chevron from '@/components/SVGS/Chevron.vue'
import SyncLoader from 'vue-spinner/src/SyncLoader.vue'

type DropdownProps = {
  type: string
  items: InputItem<string>[]
  existingItem: InputItem<string> | null
  placeholder?: string
  loading?: boolean
  disabled?: boolean
}

const props = withDefaults(defineProps<DropdownProps>(), {
  placeholder: '',
  loading: false,
  disabled: false,
})
const emit = defineEmits({ selected: (item: InputItem<string>) => item })

const dropdown = ref(null)
const open = ref(false)
const inputString = ref(props.existingItem?.label || '')
const selected = ref<InputItem<string>>({
  label: props.existingItem ? props.existingItem.value : props.placeholder ?? 'Select an option',
  value: '',
})

onClickOutside(dropdown, () => {
  open.value = false
})

onMounted(() => {
  if (props.existingItem) {
    selected.value = props.existingItem
    inputString.value = selected.value.label
  }
})

const filter = (input: string) => {
  inputString.value = input
}

const filteredItems = computed(() => {
  return props.items.filter((item) => {
    const label = item.label as string
    return label.toLowerCase().includes(inputString.value.toLowerCase())
  })
})
const select = (item: InputItem<string>) => {
  selected.value = item
  inputString.value = item.label
  open.value = false
  emit('selected', item)
}

watchEffect(() => {
  if (!props.existingItem) {
    inputString.value = ''
    selected.value = {
      label: props.placeholder ?? 'Select an option',
      value: '',
    }
    return
  }

  if (props.existingItem !== selected.value) {
    selected.value = props.existingItem
    inputString.value = props.existingItem.label
  }
})
</script>

<style lang="scss" scoped>
.dropdown-filter {
  &::placeholder {
    color: var(--grey-1);
    opacity: 1; /* Firefox */
  }

  &::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: var(--grey-1);
  }
}
</style>
