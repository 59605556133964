import * as LD from 'launchdarkly-js-sdk-common'
import { useLDClient } from 'launchdarkly-vue-client-sdk'

const isInternalIp = (ip: string): boolean => {
  // Replace this with your actual logic to determine if an IP is internal
  const internalIpRanges = ['220.233.140.138']
  return internalIpRanges.some((range) => ip.startsWith(range))
}

const isTestUser = (email: string): boolean => {
  return email.toLowerCase().includes('test') || email.toLowerCase().includes('no-reply+')
}

const getUserIp = async (): Promise<string> => {
  try {
    const res = await fetch('https://api.ipify.org?format=json')
    const data = await res.json()
    return data.ip
  } catch {
    return 'unknown'
  }
}

export const createLDContext = async (tenant: string): Promise<LD.LDContext> => {
  const ip = await getUserIp()
  const isInternal = isInternalIp(ip)

  let context: LD.LDContext = {
    kind: 'user',
    key: 'anonymous',
    name: 'Anonymous User',
    anonymous: true,
    custom: {
      tenant,
    },
  }

  if (isInternal) {
    context.name = 'Internal Anonymous User'
    context.ip = ip
  }

  return context
}

export const updateLDContext = async (
  tenant: string,
  userId: string,
  userEmail: string,
): Promise<void> => {
  const ldClient = useLDClient()
  if (!ldClient) {
    console.error('LaunchDarkly client not initialized')
    return
  }

  const ip = await getUserIp()
  const isInternal = isInternalIp(ip)

  let context: LD.LDContext = {
    kind: 'user',
    key: userId,
    name: `${tenant} - User`,
    anonymous: false,
    custom: {
      tenant,
    },
  }

  if (isTestUser(userEmail)) {
    context.key = 'test-user'
    context.name = 'Test User'
  } else if (isInternal) {
    context.ip = ip
  }

  await ldClient.identify(context)
}
