<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99999 0C6.41774 0 4.87102 0.469192 3.55543 1.34824C2.23983 2.22729 1.21445 3.47672 0.608952 4.93853C0.00345099 6.40034 -0.154976 8.00887 0.153706 9.56072C0.462388 11.1126 1.22431 12.538 2.34313 13.6569C3.46195 14.7757 4.88742 15.5376 6.43926 15.8463C7.99111 16.155 9.59964 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6517 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1571 3.84344 13.6568 2.34315C12.1565 0.842855 10.1217 0 7.99999 0ZM7.99999 14C6.8133 14 5.65326 13.6481 4.66657 12.9888C3.67987 12.3295 2.91084 11.3925 2.45671 10.2961C2.00258 9.19974 1.88376 7.99334 2.11528 6.82946C2.34679 5.66557 2.91823 4.59647 3.75735 3.75736C4.59646 2.91824 5.66556 2.3468 6.82944 2.11529C7.99333 1.88378 9.19973 2.0026 10.2961 2.45672C11.3924 2.91085 12.3295 3.67988 12.9888 4.66658C13.6481 5.65327 14 6.81331 14 8C14 9.5913 13.3678 11.1174 12.2426 12.2426C11.1174 13.3679 9.59128 14 7.99999 14Z"
      :class="`fill-${fill1}`" />
    <path
      d="M12 7H9V4C9 3.73478 8.89464 3.48043 8.70711 3.29289C8.51957 3.10536 8.26522 3 8 3C7.73478 3 7.48043 3.10536 7.29289 3.29289C7.10536 3.48043 7 3.73478 7 4V7H4C3.73478 7 3.48043 7.10536 3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8C3 8.26522 3.10536 8.51957 3.29289 8.70711C3.48043 8.89464 3.73478 9 4 9H7V12C7 12.2652 7.10536 12.5196 7.29289 12.7071C7.48043 12.8946 7.73478 13 8 13C8.26522 13 8.51957 12.8946 8.70711 12.7071C8.89464 12.5196 9 12.2652 9 12V9H12C12.2652 9 12.5196 8.89464 12.7071 8.70711C12.8946 8.51957 13 8.26522 13 8C13 7.73478 12.8946 7.48043 12.7071 7.29289C12.5196 7.10536 12.2652 7 12 7Z"
      :class="`fill-${fill2}`" />
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1?: string
  fill2?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red',
})
</script>

<style scoped></style>
