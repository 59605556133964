<template>
  <svg width="136" height="9" viewBox="0 0 136 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 5.06988C13.9668 3.60705 22.5373 3.49242 33.9444 6.2159C39.7958 7.61291 44.9727 5.0803 50 3.15986C54.8785 1.29626 58.9436 2.25783 63.9444 2.73541C71.5688 3.46355 78.4536 2.77786 86 2.77786C93.6403 2.77786 101.105 5.48564 108.556 6.5979C114.898 7.54471 119.269 6.76635 125.222 6.5979C128.417 6.50748 131.265 6.87874 134 5.83389"
      :stroke="fill"
      stroke-width="3"
      stroke-linecap="round" />
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill: string
}

withDefaults(defineProps<Props>(), {
  fill: 'fill-white'
})
</script>

<style scoped></style>
