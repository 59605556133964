<template>
  <input
    v-bind="$attrs"
    class="rounded p-4 border paragraph-2 ring-2 w-full"
    :value="computedValue"
    @input="handleInput"
    @blur="handleBlur"
    :placeholder="placeholder"
    :class="setClasses" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { clearNumberString, formatCurrency } from '@/lib/helpers'

type Props = {
  label?: string
  value?: string | number
  placeholder?: string
  applyClasses?: string
  error?: string
  negativeAllowed?: boolean
  required?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  label: '',
  value: '',
  negativeAllowed: false,
  placeholder: '$0',
  applyClasses: '',
  error: '',
  required: false
})

const emit = defineEmits<{
  (e: 'input', val: number | string): void
  (e: 'blur', val: number | string): void
}>()

const handleInput = (event: Event) => {
  if (!event) return
  let val = clearNumberString((event.target as HTMLInputElement).value)
  val = formatInput(val)
  emit('input', val)
}

const handleBlur = (event: Event) => {
  if (!event) return
  let val = clearNumberString((event.target as HTMLInputElement).value)
  val = formatInput(val)
  emit('blur', val)
}

const formatInput = (value: string) => {
  if (value.indexOf('-') !== -1) {
    value = value.replace('-', '')
    if (props.negativeAllowed) {
      value = `-${value}`
    }
  }
  const val = +value
  // fallback
  if (isNaN(val)) return 0
  return val
}

const setClasses = computed(() => {
  if (props.error !== '' || (props.value === null && props.required)) {
    return 'border-red-dark ring-red-light' + props.applyClasses
  }
  return (
    'border-grey-2 ring-transparent focus:border-otivo-blue focus:ring-blue-5 ' + props.applyClasses
  )
})

const computedValue = computed(() => {
  if (props.value) {
    return formatCurrency(+props.value)
  }
  return ''
})
</script>

<style scoped>
input:disabled {
  @apply border-grey-light rounded bg-grey-light ring-grey-light text-grey-2;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* To prevent the default outline */
input:focus {
  outline: none !important;
}
</style>
