<template>
  <fieldset class="flex flex-col gap-[10px]">
    <label
      v-for="item in items"
      :key="item.value"
      :class="{ 'text-red-dark': errorMessage }"
      class="checkbox-label paragraph-1 flex gap-[20px]">
      <input
        :checked="defaultValue ? item.value === defaultValue.value : null"
        :class="errorMessage ? '!border-red-dark' : 'border-grey-field'"
        :name="name"
        :value="item.value"
        class="enabled:hover:otivo-outline enabled:focus:otivo-outline enabled:active:otivo-outline"
        type="radio"
        @change="handleChange(item)" />
      {{ item.label }}
    </label>
    <p v-if="errorMessage" class="otivo-error-message">
      {{ errorMessage }}
    </p>
  </fieldset>
</template>

<script lang="ts" setup>
import { InputItem } from '@/types/InputItem'

type Props = {
  items: Array<InputItem>
  name: string
  errorMessage?: string
  defaultValue?: InputItem | null
}

defineProps<Props>()
const emits = defineEmits<{
  (e: 'selected', val: InputItem): void
}>()

const handleChange = (selected: InputItem) => {
  emits('selected', selected)
}
</script>

<style scoped>
input[type='radio'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  cursor: pointer;

  position: relative;
  width: 20px;
  height: 20px;
  border: 1px solid var(--grey-3);
  border-radius: 50%;
}

input[type='radio']::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: -1px;
  left: -1px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 7px solid var(--otivo-blue);
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

input[type='radio']:checked::before {
  transform: scale(1);
}
</style>
