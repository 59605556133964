// import PlanStagesService from '@/services/PlanStagesService'
import _ from 'lodash'

/**
 * NOTE: PROBABLY REDUNDANT with new dashboard. Check this
 */
const state = () => ({
  debt: 1,
  insurance: 1,
  retirement: 1,
  savings: 1,
  orderedPlans: ['debt', 'retirement', 'insurance', 'savings']
})

const getters = {
  getDebtPlanStage(state) {
    return state.debt
  },

  getRetirementPlanStage(state) {
    return state.retirement
  },

  getSavingsPlanStage(state) {
    return state.savings
  },

  getInsurancePlanStage(state) {
    return state.insurance
  },

  getRecommendationsOrder(state) {
    return state.orderedPlans
  },

  getPlansOrder(state) {
    // return state.orderedPlans
    return ['debt', 'retirement', 'insurance', 'savings']
  },

  getPlanStrengthScore(state) {
    // stage 1 = 1
    // stage 2 = 3
    // stage 4 = 5
    // stage 3 = 4
    // (give extra credit for completing all the fields in each section which is why stage 2 gets 3 points not 2)

    const score = _.reduce(
      [state.debt, state.savings, state.retirement, state.insurance],
      (sum, stage) => {
        if (stage !== 1) {
          sum += stage + 1
        } else {
          sum += 1
        }
        return sum
      },
      0
    )

    return Number((score / 4).toFixed(2))
  }
}

const mutations = {
  SET_DEBT_PLAN_STAGE(state, stage) {
    state.debt = stage
  },

  SET_INSURANCE_PLAN_STAGE(state, stage) {
    state.insurance = stage
  },

  SET_RETIREMENT_PLAN_STAGE(state, stage) {
    state.retirement = stage
  },

  SET_SAVINGS_PLAN_STAGE(state, stage) {
    state.savings = stage
  },

  SET_ORDERED_PLANS(state, orderedPlans) {
    state.orderedPlans = orderedPlans
  }
}

const actions = {
  // fetchAllPlanStages ({dispatch}) {
  //   PlanStagesService.getPlanStages()
  //     .then((res) => {
  //       dispatch('setAllPlanStages', res.data.data)
  //     })
  // },

  setAllPlanStages({ commit }, stages) {
    const stageKeys = Object.keys(stages)

    stageKeys.map((plan) => {
      const stage = stages[plan]
      switch (plan) {
        case 'debt':
          commit('SET_DEBT_PLAN_STAGE', stage.stage)
          break
        case 'insurance':
          commit('SET_INSURANCE_PLAN_STAGE', stage.stage)
          break
        case 'retirement':
          commit('SET_RETIREMENT_PLAN_STAGE', stage.stage)

          break
        case 'savings':
          commit('SET_SAVINGS_PLAN_STAGE', stage.stage)
          break
        default:
          break
      }
    })
  },

  setOrderedPlans({ commit }, orderedPlans) {
    commit('SET_ORDERED_PLANS', orderedPlans)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
