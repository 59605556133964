import {
  ClientSearchResult,
  ClientSearchParams,
  SearchParams,
} from '@/types/CallcentrePortalTypes.ts'
import CfsService from '@/services/CfsService.ts'
import { AxiosError, AxiosResponse } from 'axios'

export const searchForClient = async (
  searchValue: ClientSearchParams | SearchParams,
): Promise<Array<ClientSearchResult>> => {
  /**
   * TODO: convert from cfs
   * Need an AdminPortal service *
   */
  return CfsService.getClients(searchValue)
    .then((res: AxiosResponse) => {
      const results: Array<ClientSearchResult> = res.status === 200 ? res.data.data : []
      return results
    })
    .catch((err: AxiosError) => {
      if (err.response?.status === 404) return [] as Array<ClientSearchResult>
      console.error('Error searching for client: ', err)
      return [] as Array<ClientSearchResult>
    })
}
