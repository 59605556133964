<template>
  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.59392 16.0004C5.32969 16.0004 5.07629 15.895 4.88946 15.7075C4.70262 15.52 4.59766 15.2656 4.59766 15.0004C4.59766 14.7352 4.70262 14.4808 4.88946 14.2933C5.07629 14.1057 5.32969 14.0004 5.59392 14.0004C6.93639 14.0004 7.00363 12.8229 7.00363 12.5854V7.27539C7.00363 7.01017 7.1086 6.75582 7.29543 6.56828C7.48227 6.38075 7.73567 6.27539 7.9999 6.27539C8.26412 6.27539 8.51753 6.38075 8.70436 6.56828C8.8912 6.75582 8.99616 7.01017 8.99616 7.27539V12.5854C8.99616 13.9579 8.08956 16.0004 5.59392 16.0004Z"
      :class="`fill-${fill2} group-hover:fill-${hover2 ?? fill2}`" />
    <path
      d="M16 8H0L0.0298881 6.9725C0.146949 3.0625 3.64882 0 8 0C12.3512 0 15.8531 3.0625 15.9701 6.9725L16 8ZM2.17684 6H13.8232C13.203 3.72 10.8045 2 8 2C5.19552 2 2.79701 3.72 2.17684 6Z"
      :class="`fill-${fill1} group-hover:fill-${hover1 ?? fill1}`" />
  </svg>
</template>

<script setup lang="ts">
type Props = {
  fill1?: string
  fill2?: string
  hover1?: string | null
  hover2?: string | null
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red',
  hover1: null,
  hover2: null,
})
</script>

<style scoped></style>
