<template>
  <ul class="">
    <li
      v-for="(item, index) in links"
      class="mh6 md:text-button1"
      :key="index">
      <SideNavLink :to="item.to" :label="item.label" :active="item.active" />
    </li>
    <li>
      <SideNavSubMenu heading="Reporting" :links="reportingLinks" />
    </li>
    <li>
      <SideNavSubMenu heading="Settings" :links="settingLinks" :render-top-border="false" />
    </li>
  </ul>
</template>

<script setup lang="ts">
import SideNavLink from '@/app/components/SideNavLink.vue'
import SideNavSubMenu from '@/app/components/SideNavSubMenu.vue'

const links = [{ to: { name: 'SearchForm' }, label: 'Client search' }]
const reportingLinks = [
  { to: { name: 'portalReportingOverview' }, label: 'Overview' },
  { to: { name: 'portalReportingEngagement' }, label: 'Engagement' },
  // { to: '#client-filter', label: 'Client filter' }
]
const settingLinks = [{ to: { name: 'cfsHome' }, label: 'Admin users' }]
</script>

<style scoped>
</style>
