<template>
  <div id="how-it-works-module" class="text-center" :class="`text-${textColour}`">
    <div class="fix-width-m-auto relative">
      <h3 class="px-[20px] md:px-0">
        {{ howItWorks.heading }}
      </h3>
      <div class="intro-1 pt-8 max-w-[800px] m-auto px-[20px] md:px-0">
        {{ howItWorks.subHeading }}
      </div>
      <div
        class="relative w-full text-left justify-center gap-20 hidden lg:flex pt-[30px] h-[450px]">
        <img :src="linesSVG" alt="Lines" class="absolute top-0 pt-40 flex m-auto" />
        <div
          :key="index"
          class="w-64 flex"
          :class="[index % 2 === 0 ? 'flex-col' : 'flex-col-reverse justify-end mt-24']"
          v-for="(step, index) in howItWorks.iterableItems">
          <img
            :src="icons[index]"
            :alt="'Icon' + index"
            class="relative z-10 w-[160px] h-[160px] self-center" />
          <div class="flex gap-2 my-6">
            <div
              class="headline-5 bold pt-1 bg-otivo-red rounded-circle text-white text-center h-10 min-w-10">
              {{ index + 1 }}
            </div>
            <div>
              <div class="headline-6 pb-2.5 w-full">
                {{ step.heading }}
              </div>
              <p class="paragraph-2" :class="`text-${bodyTextColour}`">
                {{ step.body }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <Slider
        :slides="howItWorks.iterableItems"
        :component="HowItWorksSlide"
        class="lg:hidden pt-4 md:pt-12" />

      <div v-if="howItWorks.tagline" class="py-8 md:pt-12">
        {{ howItWorks.tagline }}
        <span
          class="underline cursor-pointer"
          v-if="howItWorks.taglineLink"
          @click="openHowWeWorkThisOutModal">
          {{ howItWorks.taglineLink }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IterableItemDefaultType } from '@/types/globalComponents/IterableItemDefaultType'
import Lines from '@/components/SVGS/Lines.svg?url'
import LinesBlue2 from '@/components/SVGS/LinesBlue2.svg?url'
import Slider from '@/components/BrandComponents/Otivo/Slider.vue'
import HowItWorksSlide from '@/components/BrandComponents/Otivo/HowItWorksSlide.vue'
import HowItWorksBubbleThree from '@/components/BrandComponents/Otivo/HowItWorks/HowItWorksBubbleThree.svg?url'
import HowItWorksBubbleTwo from '@/components/BrandComponents/Otivo/HowItWorks/HowItWorksBubbleTwo.svg?url'
import HowItWorksBubbleOne from '@/components/BrandComponents/Otivo/HowItWorks/HowItWorksBubbleOne.svg?url'
import HowItWorksBubbleFour from '@/components/BrandComponents/Otivo/HowItWorks/HowItWorksBubbleFour.svg?url'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import HowWeWorkItOutModal from '@/components/BrandComponents/Otivo/HowWeWorkItOutModal.vue'

type HowItWorksType = {
  heading: string
  subHeading: string
  iterableItems: Array<IterableItemDefaultType>
  tagline?: string
  taglineLink?: string
}

interface Props {
  howItWorks: HowItWorksType
  textColour?: string
  bodyTextColour?: string
  lines?: 'default' | 'blue-2'
}

// disclaimer was set as an option prop, but I don't see it being used anywhere, so I haven't set a default
// value. Can it just be removed?
const props = withDefaults(defineProps<Props>(), {
  textColour: 'grey-1',
  bodyTextColour: 'grey-2',
  lines: 'default',
})

const modalStore = useModalStore()

const openHowWeWorkThisOutModal = () => {
  modalStore.openModal(HowWeWorkItOutModal, {})
}

const linesSVG = props.lines === 'blue-2' ? LinesBlue2 : Lines

const icons: Array<unknown> = [
  HowItWorksBubbleThree,
  HowItWorksBubbleTwo,
  HowItWorksBubbleOne,
  HowItWorksBubbleFour,
]
</script>
