<template>
  <div class="max-w-[1100px] mx-auto py-[40px]">
    <h1 class="text-otivo-blue">Components</h1>

    <!-- Date Input -->
    <div class="mt-[40px]">
      <Collapse :default-open="false">
        <template #heading>
          <div>
            <h2 class="underline">Base Date Input &lt;BaseDateInput /&gt;</h2>
            <p class="mt-[20px] paragraph-1">
              This component represents a date input field that allows users to select a date. It
              accepts several props for customization, and the input field provides visual feedback
              when interacted with, enhancing the user experience.
            </p>
          </div>
        </template>
        <template #body>
          <div class="table-container">
            <p class="table-heading">Props</p>
            <div class="table-row">
              <p class="required">value</p>
              <p>string (format YYYY-MM-DD)</p>
            </div>
            <div class="table-row">
              <p class="required">name</p>
              <p>string</p>
            </div>
            <div class="table-row">
              <p>min?</p>
              <p>string (format YYYY-MM-DD)</p>
            </div>
            <div class="table-row">
              <p>max?</p>
              <p>string (format YYYY-MM-DD)</p>
            </div>
            <div class="table-row">
              <p>errorMessage?</p>
              <p>string</p>
            </div>
            <div class="table-row">
              <p>disabled?</p>
              <p>boolean</p>
            </div>
            <p class="table-heading">Emits</p>
            <div class="table-row">
              <p>@input</p>
              <p>string (format YYYY-MM-DD)</p>
            </div>
          </div>
        </template>
      </Collapse>
      <div class="flex flex-wrap gap-[20px] md:gap-x-[40px] md:gap-y-[20px] mt-[20px]">
        <div>
          <p class="intro-1 mt-[20px]">Default date input</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            Value: <span class="font-bold">{{ dateInputData }}</span>
          </p>
          <BaseDateInput v-model:value="dateInputData" name="defaultDateInput" />
        </div>
        <div>
          <p class="intro-1 mt-[20px]">Date input with an existing date</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            Value: <span class="font-bold">{{ dateInputExistingDateData }}</span>
          </p>
          <BaseDateInput v-model:value="dateInputExistingDateData" name="defaultDateInput" />
        </div>
        <div>
          <p class="intro-1 mt-[20px]">Error state</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            Value: <span class="font-bold">{{ dateErrorData }}</span>
          </p>
          <BaseDateInput
            v-model:value="dateErrorData"
            :error-message="dateErrorMessage"
            name="dateInputError" />
        </div>
        <div>
          <p class="intro-1 mt-[20px]">Disabled date input</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">Value: <span class="font-bold">N/A</span></p>
          <BaseDateInput
            v-model:value="dateErrorData"
            :disabled="true"
            class="mt-[20px]"
            name="defaultDateInput" />
        </div>
      </div>
    </div>

    <!-- Tab Button -->
    <div class="mt-[40px]">
      <Collapse :default-open="false">
        <template #heading>
          <div>
            <h2 class="underline">Base Tab Button &lt;BaseTabButton /&gt;</h2>
            <p class="mt-[20px] paragraph-1">
              This component is designed for creating visually distinct tabs in user interfaces. It
              offers a seamless transition between active and inactive states, providing a clear
              visual cue for user-selected options. The tab's appearance changes smoothly upon
              clicking or hovering, enhancing the interactive experience.
            </p>
          </div>
        </template>
        <template #body>
          <div class="table-container">
            <p class="table-heading">Props</p>
            <div class="table-row">
              <p class="required">option</p>
              <p>InputItem</p>
            </div>
            <div class="table-row">
              <p class="required">isActive</p>
              <p>boolean</p>
            </div>
            <p class="table-heading">Emits</p>
            <div class="table-row">
              <p>@change</p>
              <p>InputItem</p>
            </div>
          </div>
        </template>
      </Collapse>
      <div class="flex flex-wrap gap-[20px] md:gap-x-[40px] md:gap-y-[20px] mt-[20px]">
        <div class="min-w-[326px]">
          <p class="intro-1 mt-[20px]">Default tab buttons</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            Value: <span class="font-bold">{{ activeTab }}</span>
          </p>
          <div :class="`grid-cols-${tabButtonData.length}`" class="grid gap-[20px]">
            <BaseTabButton
              v-for="(option, index) in tabButtonData"
              :key="index"
              :is-active="option.value === activeTab.value"
              :option="option"
              :disabled="option.disabled"
              @change="(val) => (activeTab = val)" />
          </div>
        </div>
        <div>
          <p class="intro-1 mt-[20px]">Default tab buttons</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            Value: <span class="font-bold">{{ activeTab2 }}</span>
          </p>
          <div
            :class="`grid-cols-${tabButtonData2.length}`"
            class="grid gap-[20px] bg-blue-5 p-[5px] rounded-[10px] w-fit mt-[20px]">
            <BaseTabButton
              v-for="(option, index) in tabButtonData2"
              :key="index"
              :is-active="option.value === activeTab2.value"
              :option="option"
              :disabled="option.disabled"
              @change="(val) => (activeTab2 = val)" />
          </div>
        </div>
      </div>
    </div>

    <!-- Input-->
    <div class="mt-[40px]">
      <Collapse :default-open="false">
        <template #heading>
          <div>
            <h2 class="underline">Input &lt;BaseInput /&gt;</h2>
            <p class="mt-[20px] paragraph-1">
              This component serves as a versatile input field with specialized formatting. It's
              useful for scenarios where users need to enter different types of values, such as
              text, numbers, currencies, or percentages. The component dynamically adjusts its
              appearance and behavior based on the provided type, allowing for intuitive input and
              interactions. It supports various formatting options, incrementing or decrementing
              values, and preventing non-numeric characters to enhance user experience.
            </p>
          </div>
        </template>
        <template #body>
          <div class="table-container">
            <p class="table-heading">Props</p>
            <div class="table-row">
              <p class="required">v-model:value</p>
              <p>string | number</p>
            </div>
            <div class="table-row">
              <p class="required">name</p>
              <p>string</p>
            </div>
            <div class="table-row">
              <p>type? (default = text)</p>
              <p>text | number | currency | percent | email | phone</p>
            </div>
            <div class="table-row">
              <p>placeholder?</p>
              <p>string</p>
            </div>
            <div class="table-row">
              <p>step? (default = 1,000)</p>
              <p>number</p>
            </div>
            <div class="table-row">
              <p>min? (default = 0)</p>
              <p>number</p>
            </div>
            <div class="table-row">
              <p>max? (default = 10,000)</p>
              <p>number</p>
            </div>
            <div class="table-row">
              <p>maxLength? (default = 10,000)</p>
              <p>number</p>
            </div>
            <div class="table-row">
              <p>errorMessage?</p>
              <p>string</p>
            </div>
            <div class="table-row">
              <p>disabled?</p>
              <p>boolean</p>
            </div>
            <p class="table-heading">Emits</p>
            <div class="table-row">
              <p>@update:value</p>
              <p>string | number</p>
            </div>
          </div>
        </template>
      </Collapse>
      <div class="flex flex-wrap gap-[20px] md:gap-x-[40px] md:gap-y-[20px] mt-[20px]">
        <div>
          <p class="intro-1 mt-[20px]">Default text input (returns a string)</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            Value: <span class="font-bold">{{ inputDemoData }}</span>
          </p>
          <BaseInput
            v-model:value="inputDemoData"
            name="defaultDemoInput"
            placeholder="I am a placeholder" />
        </div>
        <div>
          <p class="intro-1 mt-[20px]">Number text input</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            Value: <span class="font-bold">{{ inputNumberData }}</span>
          </p>
          <BaseInput v-model:value="inputNumberData" name="demoNumberInput" type="number" />
        </div>
        <div>
          <p class="intro-1 mt-[20px]">Currency text input</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            Value: <span class="font-bold">{{ inputCurrencyData }}</span>
          </p>
          <BaseInput v-model:value="inputCurrencyData" name="demoCurrencyInput" type="currency" />
        </div>
        <div>
          <p class="intro-1 mt-[20px]">Percent text input</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            Value: <span class="font-bold">{{ inputPercentData }}</span>
          </p>
          <PercentageInput
            v-model:value="inputPercentData"
            class="h-[20px] w-full"
            name="demoPercentInput" />
        </div>
        <div>
          <p class="intro-1 mt-[20px]">Mobile phone text input</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            Value: <span class="font-bold">{{ inputMobilePhoneData }}</span>
          </p>
          <BaseInput
            v-model:value="inputMobilePhoneData"
            name="demoMobilePhoneInput"
            type="phone" />
        </div>
        <div>
          <p class="intro-1 mt-[20px]">Error state</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            Value: <span class="font-bold">{{ inputErrorData }}</span>
          </p>
          <BaseInput
            v-model:value="inputErrorData"
            :error-message="inputErrorMessage"
            name="demoInputError" />
        </div>
        <div>
          <p class="intro-1 mt-[20px]">Disabled text input</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">Value: <span class="font-bold">N/A</span></p>
          <BaseInput
            v-model:value="inputDemoDisabledData"
            :disabled="true"
            name="demoDisabledInput"
            placeholder="I am disabled" />
        </div>
      </div>
    </div>

    <!-- Drop Down-->
    <div class="mt-[40px]">
      <Collapse :default-open="false">
        <template #heading>
          <div>
            <h2 class="underline">Drop Down &lt;BaseDropDown /&gt;</h2>
            <p class="mt-[20px] paragraph-1">
              This component offers a versatile dropdown selection interface. It supports
              configurable options and displays a default or selected value. Users can open the
              dropdown by clicking the button, which toggles a list of options. Keyboard navigation
              is supported, making it accessible and user-friendly.
            </p>
          </div>
        </template>
        <template #body>
          <div class="table-container">
            <p class="table-heading">Props</p>
            <div class="table-row">
              <p class="required">options</p>
              <p>Array&lt;InputItem&gt;</p>
            </div>
            <div class="table-row">
              <p>placeholder?</p>
              <p>string</p>
            </div>
            <div class="table-row">
              <p>defaultValue?</p>
              <p>InputItem</p>
            </div>
            <div class="table-row">
              <p>errorMessage?</p>
              <p>string</p>
            </div>
            <div class="table-row">
              <p>disabled? (default = false)</p>
              <p>boolean</p>
            </div>
            <p class="table-heading">Emits</p>
            <div class="table-row">
              <p>@selected</p>
              <p>InputItem</p>
            </div>
          </div>
        </template>
      </Collapse>
      <div class="flex flex-wrap gap-[20px] md:gap-x-[40px] md:gap-y-[20px] mt-[20px]">
        <div>
          <p class="intro-1 mt-[20px]">
            First option is selected if no placeholder or default value is passed (default
            behaviour)
          </p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            Selected: <span class="font-bold">{{ selectMenuSelectedItemDefault }}</span>
          </p>
          <BaseDropDown
            :options="selectMenuDataDefault"
            @selected="
              (selectedItem) => {
                selectMenuSelectedItemDefault = selectedItem
              }
            " />
        </div>
        <div>
          <p class="intro-1 mt-[20px]">No option is selected if a placeholder is passed</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            Selected: <span class="font-bold">{{ selectMenuSelectedItemPlaceholder }}</span>
          </p>
          <BaseDropDown
            :options="selectMenuDataPlaceholder"
            placeholder="I am placeholder"
            @selected="
              (selectedItem) => {
                selectMenuSelectedItemPlaceholder = selectedItem
              }
            " />
        </div>
        <div>
          <p class="intro-1 mt-[20px]">Default value is passed</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            Selected: <span class="font-bold">{{ selectMenuSelectedItemDefaultValue }}</span>
          </p>
          <BaseDropDown
            :default-value="selectMenuSelectedItemDefaultValue"
            :options="selectMenuDataDefaultValue"
            @selected="
              (selectedItem) => {
                selectMenuSelectedItemDefaultValue = selectedItem
              }
            " />
        </div>
        <div>
          <p class="intro-1 mt-[20px]">Error state</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            Selected: <span class="font-bold">{{ selectMenuErrorData }}</span>
          </p>
          <BaseDropDown
            :error-message="selectMenuErrorMessage"
            :options="selectMenuDataDefault"
            placeholder="You forgot me"
            @selected="
              (selectedItem) => {
                selectMenuErrorData = selectedItem
              }
            " />
        </div>
        <div>
          <p class="intro-1 mt-[20px]">Disabled</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            Selected: <span class="font-bold">N/A</span>
          </p>
          <BaseDropDown :disabled="true" :options="selectMenuDataDisabled" />
        </div>
      </div>
    </div>

    <!-- Radio button -->
    <div class="mt-[40px]">
      <Collapse :default-open="false">
        <template #heading>
          <div>
            <h2 class="underline">Radio Button &lt;BaseRadioButtons /&gt;</h2>
            <p class="mt-[20px] paragraph-1">
              This component creates a set of radio buttons with a selectable label. It supports
              customizable options and an optional legend for grouping. Users can choose from the
              provided items by clicking the corresponding radio button. The radio buttons feature
              enhanced styling with dynamic transformations for visual feedback on selection.
            </p>
          </div>
        </template>
        <template #body>
          <div class="table-container">
            <p class="table-heading">Props</p>
            <div class="table-row">
              <p class="required">items</p>
              <p>Array&lt;InputItem&gt;</p>
            </div>
            <div class="table-row">
              <p class="required">name</p>
              <p>string</p>
            </div>
            <div class="table-row">
              <p>errorMessage?</p>
              <p>string</p>
            </div>
            <div class="table-row">
              <p>defaultValue?</p>
              <p>&lt;InputItem&gt; | null</p>
            </div>
            <p class="table-heading">Emits</p>
            <div class="table-row">
              <p>@selected</p>
              <p>string | number</p>
            </div>
          </div>
        </template>
      </Collapse>
      <div class="flex flex-wrap gap-[20px] md:gap-x-[40px] md:gap-y-[20px] mt-[20px]">
        <div class="min-w-[346px]">
          <p class="intro-1 mt-[20px]">Sample string radio buttons</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            Selected: <span class="font-bold">{{ sampleStringSelectedRadioItem }}</span>
          </p>
          <BaseRadioButtons
            :items="sampleStringRadioButtonData"
            name="sampleStringRadioButton"
            @selected="
              (selectedItem) => {
                sampleStringSelectedRadioItem = selectedItem
              }
            " />
        </div>
        <div>
          <p class="intro-1 mt-[20px]">Sample boolean radio buttons</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            Selected: <span class="font-bold">{{ sampleBooleanSelectedRadioItem }}</span>
          </p>
          <BaseRadioButtons
            :items="sampleBooleanRadioButtonData"
            name="sampleBoolenRadioButton"
            @selected="
              (selectedItem) => {
                sampleBooleanSelectedRadioItem = selectedItem
              }
            " />
        </div>
        <div class="min-w-[280px]">
          <p class="intro-1 mt-[20px]">Sample number radio buttons</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            Selected: <span class="font-bold">{{ sampleNumberSelectedRadioItem }}</span>
          </p>
          <BaseRadioButtons
            :default-value="sampleNumberSelectedRadioItem"
            :items="sampleNumberRadioButtonData"
            name="sampleNumberRadioButton"
            @selected="
              (selectedItem) => {
                sampleNumberSelectedRadioItem = selectedItem
              }
            " />
        </div>
        <div>
          <p class="intro-1 mt-[20px]">Error state</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            Selected: <span class="font-bold">{{ sampleErrorSelectedRadioItem }}</span>
          </p>
          <BaseRadioButtons
            :error-message="sampleRadioItemErrorMessage"
            :items="sampleErrorRadioButtonData"
            name="sampleErrorRadioButton"
            @selected="
              (selectedItem) => {
                sampleErrorSelectedRadioItem = selectedItem
              }
            " />
        </div>
      </div>
    </div>

    <!-- Checkbox -->
    <div class="mt-[40px]">
      <Collapse :default-open="false">
        <template #heading>
          <div>
            <h2 class="underline">Checkbox &lt;BaseCheckbox /&gt;</h2>
            <p class="mt-[20px] paragraph-1">
              This component generates a styled checkbox with adjustable sizing. Users can interact
              with the checkbox to toggle its checked state. Visual transformations provide feedback
              on checkbox selection.
            </p>
          </div>
        </template>
        <template #body>
          <div class="table-container">
            <p class="table-heading">Props</p>
            <div class="table-row">
              <p>v-model:checked? (default = false)</p>
              <p>boolean</p>
            </div>
            <div class="table-row">
              <p class="required">name</p>
              <p>string</p>
            </div>
            <div class="table-row">
              <p>errorMessage?</p>
              <p>string</p>
            </div>
            <div class="table-row">
              <p>disabled? (default = false)</p>
              <p>boolean</p>
            </div>
            <p class="table-heading">Emits</p>
            <div class="table-row">
              <p>@update:checked</p>
              <p>boolean</p>
            </div>
          </div>
        </template>
      </Collapse>
      <div class="flex flex-wrap gap-[20px] md:gap-x-[40px] md:gap-y-[20px] mt-[20px]">
        <div>
          <p class="intro-1 mt-[20px]">Small checkbox (default)</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            State: <span class="font-bold">{{ smallSampleCheckbox }}</span>
          </p>
          <BaseCheckbox
            v-model:checked="smallSampleCheckbox"
            class="mt-[10px]"
            name="small-sample-checkbox" />
        </div>
        <div>
          <p class="intro-1 mt-[20px]">Error state</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            State: <span class="font-bold">{{ errorCheckbox }}</span>
          </p>
          <BaseCheckbox
            v-model:checked="errorCheckbox"
            :error-message="errorCheckboxMessage"
            class="mt-[10px]"
            name="small-sample-checkbox" />
        </div>
        <div>
          <p class="intro-1 mt-[20px]">Disabled checkbox</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">State: <span class="font-bold">N/A</span></p>
          <BaseCheckbox class="mt-[10px]" disabled name="disabled-sample-checkbox" />
        </div>
      </div>
    </div>

    <!-- Switch -->
    <div class="mt-[40px]">
      <Collapse :default-open="false">
        <template #heading>
          <div>
            <h2 class="underline">Switch &lt;BaseSwitch /&gt;</h2>
            <p class="mt-[20px] paragraph-1">
              This component offers a customizable toggle switch. It displays optional labels for
              both true and false states. Users can interact by clicking on the switch or labels to
              change its state. Visual cues, such as an outline and color change, provide feedback.
              The toggle design features smooth animations, enhancing user experience.
            </p>
          </div>
        </template>
        <template #body>
          <div class="table-container">
            <p class="table-heading">Props</p>
            <div class="table-row">
              <p class="required">name</p>
              <p>string</p>
            </div>
            <div class="table-row">
              <p class="required">v-model:checked</p>
              <p>boolean</p>
            </div>
            <div class="table-row">
              <p>icons? (default = true)</p>
              <p>boolean</p>
            </div>
            <div class="table-row">
              <p>textTrue?</p>
              <p>string</p>
            </div>
            <div class="table-row">
              <p>textFalse?</p>
              <p>string</p>
            </div>
            <p class="table-heading">Emits</p>
            <div class="table-row">
              <p>@update:checked</p>
              <p>boolean</p>
            </div>
          </div>
        </template>
      </Collapse>
      <div class="flex flex-wrap gap-[20px] md:gap-[40px] mt-[20px]">
        <div>
          <p class="intro-1 mt-[20px]">Toggle without text (default)</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            State: <span class="font-bold">{{ labelLessToggle }}</span>
          </p>
          <BaseSwitch
            v-model:checked="labelLessToggle"
            :icons="false"
            class="mt-[10px]"
            name="sampleLabelLessToggle" />
        </div>
        <div>
          <p class="intro-1 mt-[20px]">Toggle with text</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            State: <span class="font-bold">{{ sampleToggle2 }}</span>
          </p>
          <BaseSwitch
            v-model:checked="sampleToggle2"
            :icons="false"
            class="mt-[10px]"
            name="sampleToggle2"
            text-false="No"
            text-true="Yes" />
        </div>
      </div>
      <div class="flex flex-wrap gap-[20px] md:gap-[40px] mt-[20px]">
        <div>
          <p class="intro-1 mt-[20px]">Toggle without text (default) Icon</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            State: <span class="font-bold">{{ labelLessToggle2 }}</span>
          </p>
          <BaseSwitch
            v-model:checked="labelLessToggle2"
            class="mt-[10px]"
            name="sampleLabelLessToggle2" />
        </div>
        <div>
          <p class="intro-1 mt-[20px]">Toggle with text</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            State: <span class="font-bold">{{ sampleToggle }}</span>
          </p>
          <BaseSwitch
            v-model:checked="sampleToggle"
            class="mt-[10px]"
            name="sampleToggle"
            text-false="No"
            text-true="Yes" />
        </div>
        <div>
          <p class="intro-1 mt-[20px]">Toggle with text</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            State: <span class="font-bold">{{ disabledToggle }}</span>
          </p>
          <BaseSwitch
            v-model:checked="disabledToggle"
            class="mt-[10px]"
            name="disabledToggle"
            text-false="No"
            text-true="Yes"
            disabled />
        </div>
      </div>
    </div>

    <!-- Switch With Text -->
    <div class="mt-[40px]">
      <Collapse :default-open="false">
        <template #heading>
          <div>
            <h2 class="underline">Switch With Text &lt;BaseSwitchWithText /&gt;</h2>
            <p class="mt-[20px] paragraph-1">
              This component offers a stylish toggle switch with customizable labels. Users can
              interact with the switch to toggle its state. Visual cues, such as an outline and
              smooth animations, provide user feedback. The component's design combines a sleek
              appearance with intuitive interaction.
            </p>
          </div>
        </template>
        <template #body>
          <div class="table-container">
            <p class="table-heading">Props</p>
            <div class="table-row">
              <p class="required">name</p>
              <p>string</p>
            </div>
            <div class="table-row">
              <p class="required">v-model:checked</p>
              <p>boolean</p>
            </div>
            <div class="table-row">
              <p>textTrue?</p>
              <p>string</p>
            </div>
            <div class="table-row">
              <p>textFalse?</p>
              <p>string</p>
            </div>
            <p class="table-heading">Emits</p>
            <div class="table-row">
              <p>@update:checked</p>
              <p>boolean</p>
            </div>
          </div>
        </template>
      </Collapse>
      <div class="flex flex-wrap gap-[20px] md:gap-[40px] mt-[20px]">
        <div>
          <p class="intro-1 mt-[20px]">Toggle with text</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            State: <span class="font-bold">{{ sampleToggleWithText }}</span>
          </p>
          <BaseSwitchWithText
            v-model:checked="sampleToggleWithText"
            class="mt-[10px]"
            name="sampleToggleWithText"
            text-false="False"
            text-true="True" />
        </div>
      </div>
    </div>

    <!-- Status -->
    <div class="mt-[40px]">
      <Collapse :default-open="false">
        <template #heading>
          <div>
            <h2 class="underline">Status &lt;Status /&gt;</h2>
            <p class="mt-[20px] paragraph-1">
              This component is a versatile status message component designed to dynamically render
              and style status messages based on various states. It offers flexibility in presenting
              different types of status information within Otivo.
            </p>
          </div>
        </template>
        <template #body>
          <div class="table-container">
            <p class="table-heading">Props</p>
            <div class="table-row">
              <p class="required">state</p>
              <p>processing | ready | infoNeeded | actioned | onTrack | comingSoon</p>
            </div>
            <div class="table-row">
              <p class="required">statusStyle? (default = solid)</p>
              <p>solid | outline</p>
            </div>
            <div class="table-row">
              <p>dateActioned?</p>
              <p>string</p>
            </div>
          </div>
        </template>
      </Collapse>
      <div class="mt-[20px]">
        <p class="intro-1 mt-[20px]">Badge - Outline</p>
        <div class="flex flex-wrap gap-[20px] md:gap-x-[40px] md:gap-y-[20px] mt-[20px]">
          <Status state="processing" status-style="outline" />
          <Status state="ready" status-style="outline" />
          <Status state="infoNeeded" status-style="outline" />
          <Status state="actioned" status-style="outline" />
          <Status date-actioned="28 days ago" state="actioned" status-style="outline" />
          <Status state="comingSoon" status-style="outline" />
        </div>
        <p class="intro-1 mt-[20px]">Badge - Solid</p>
        <div class="flex flex-wrap gap-[20px] md:gap-x-[40px] md:gap-y-[20px] mt-[20px]">
          <Status state="processing" status-style="solid" />
          <Status state="ready" status-style="solid" />
          <Status state="infoNeeded" status-style="solid" />
          <Status state="actioned" status-style="solid" />
          <Status date-actioned="28 days ago" state="actioned" status-style="solid" />
          <Status state="comingSoon" status-style="solid" />
        </div>
      </div>
    </div>

    <!--  Base Range Input  -->
    <div class="mt-[40px]">
      <Collapse :default-open="false">
        <template #heading>
          <div>
            <h2 class="underline">Range Input &lt;BaseRangeInput /&gt;</h2>
            <p class="mt-[20px] paragraph-1">
              This component offers a customizable range input. It allows users to select a value
              within a specified range using a draggable slider. The tooltip dynamically displays
              the selected value as well as a recommended value, if provided. The component is
              highly customizable, with options to adjust the appearance of the slider, tooltip, and
              labels.
            </p>
          </div>
        </template>
        <template #body>
          <div class="table-container">
            <p class="table-heading">Props</p>
            <div class="table-row">
              <p class="required">v-model:value</p>
              <p>number</p>
            </div>
            <div class="table-row">
              <p class="required">min</p>
              <p>number</p>
            </div>
            <div class="table-row">
              <p class="required">max</p>
              <p>number</p>
            </div>
            <div class="table-row">
              <p>step? (default = 1)</p>
              <p>number</p>
            </div>
            <div class="table-row">
              <p>ballColour? (default = 'blue-2)</p>
              <p>string</p>
            </div>
            <div class="table-row">
              <p>ballBackgroundColour? (default = 'white')</p>
              <p>string</p>
            </div>
            <div class="table-row">
              <p>tooltipColour? (default = 'midnight')</p>
              <p>string</p>
            </div>
            <div class="table-row">
              <p>tooltipTextColour? (default = 'white')</p>
              <p>string</p>
            </div>
            <div class="table-row">
              <p>showTooltip? (default = true)</p>
              <p>boolean</p>
            </div>
            <div class="table-row">
              <p>recommendedValue? (default = undefined)</p>
              <p>number</p>
            </div>
            <div class="table-row">
              <p>disabled</p>
              <p>boolean</p>
            </div>
            <p class="table-heading">Emits</p>
            <div class="table-row">
              <p>@update:value</p>
              <p>number</p>
            </div>
          </div>
        </template>
      </Collapse>
      <div class="flex flex-wrap gap-[20px] md:gap-[40px] mt-[20px]">
        <div>
          <p class="intro-1 mt-[20px]">Base Range Input (default)</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            Value: <span class="font-bold">{{ sampleBaseRangeInputValue }}</span>
          </p>
          <BaseRangeInput
            :min="0"
            :max="100"
            v-model:value="sampleBaseRangeInputValue"
            ball-background-colour="grey-5" />
        </div>
        <div>
          <p class="intro-1 mt-[20px]">Base Range Input with Recommended Value</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">
            Value:
            <span class="font-bold">{{ sampleBaseRangeInputValueWithRecommendedValue }}</span>
          </p>
          <BaseRangeInput
            :min="0"
            :max="100"
            v-model:value="sampleBaseRangeInputValueWithRecommendedValue"
            :recommended-value="69"
            ball-background-colour="grey-5">
            <template #minLabel>
              <p class="button-1 text-blue-1">$0</p>
            </template>
            <template #maxLabel>
              <div class="text-right">
                <p class="button-1 text-blue-1">$100</p>
                <p class="menu-1 text-grey-2">Maximum</p>
              </div>
            </template>
          </BaseRangeInput>
        </div>
        <div>
          <p class="intro-1 mt-[20px]">Disabled Base Range Input</p>
          <p class="paragraph-1 mt-[10px] mb-[20px]">Value: <span class="font-bold">33</span></p>
          <BaseRangeInput
            :min="0"
            :max="100"
            :disabled="true"
            :value="33"
            ball-background-colour="grey-5" />
        </div>
      </div>
    </div>

    <!-- Button -->
    <div class="mt-[40px]">
      <Collapse :default-open="false">
        <template #heading>
          <div>
            <h2 class="underline">Button &lt;OtivoButton /&gt;</h2>
            <p class="mt-[20px] paragraph-1">
              This component creates a versatile button element with various styling options. It
              supports different sizes, colors, and typography variations. The button can be
              disabled or show a loading state with an optional spinner. The component provides
              accessible interactions and visual feedback. It's a flexible solution for creating
              interactive buttons with customizable appearances.
            </p>
          </div>
        </template>
        <template #body>
          <div class="table-container">
            <p class="table-heading">Props</p>
            <div class="table-row">
              <p class="required">Button Text (default slot)</p>
            </div>
            <div class="table-row">
              <p>disabled? (default = false)</p>
              <p>boolean</p>
            </div>
            <div class="table-row">
              <p>loading? (default = false)</p>
              <p>boolean</p>
            </div>
            <div class="table-row">
              <p>customStyleOverrides? (default = '')</p>
              <p>string</p>
            </div>
            <div class="table-row">
              <p>size? (default = medium)</p>
              <p>large | medium | small | mobile</p>
            </div>
            <div class="table-row">
              <p>colour? (default = blue)</p>
              <p>blue | red | white | sky</p>
            </div>
            <div class="table-row">
              <p>typography? (default = button-1)</p>
              <p>button-1 | button-2 | button-3</p>
            </div>
            <p class="table-heading">Emits</p>
            <div class="table-row">
              <p>@click</p>
              <p>void</p>
            </div>
          </div>
        </template>
      </Collapse>
      <div class="flex flex-wrap gap-[20px] md:gap-x-[40px] md:gap-y-[20px] mt-[20px]">
        <div>
          <OtivoButton>Blue Otivo button (default)</OtivoButton>
        </div>
        <div>
          <OtivoButton colour="red" @click="handleButtonClick"> Red Otivo button </OtivoButton>
        </div>
        <div>
          <OtivoButton colour="white" @click="handleButtonClick"> White Otivo button </OtivoButton>
        </div>
        <div>
          <OtivoButton colour="sky" @click="handleButtonClick"> Sky Otivo button </OtivoButton>
        </div>
        <div>
          <OtivoButton size="large" @click="handleButtonClick"> Large Otivo button </OtivoButton>
        </div>
        <div>
          <OtivoButton @click="handleButtonClick"> Medium Otivo button (default) </OtivoButton>
        </div>
        <div>
          <OtivoButton size="small" @click="handleButtonClick"> Small Otivo button </OtivoButton>
        </div>
        <div>
          <OtivoButton size="mobile" @click="handleButtonClick"> Mobile Otivo button </OtivoButton>
        </div>
        <div>
          <OtivoButton disabled> Disabled Otivo button </OtivoButton>
        </div>
        <div>
          <OtivoButton loading> Loading Otivo button </OtivoButton>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="mt-[40px]">
      <Collapse :default-open="false">
        <template #heading>
          <div>
            <h2 class="underline">Modal (ModalStore.ts)</h2>
            <p class="mt-[20px] paragraph-1">
              The modal store utilizes Pinia. The store maintains information about the modal's
              visibility, content, properties, and size. The store provides methods to open and
              close modals, as well as an option to prevent modals from closing automatically. By
              centralizing modal-related logic, it simplifies the process of managing and
              controlling modals across the application.
            </p>
          </div>
        </template>
        <template #body>
          <div class="table-container">
            <p class="table-heading">Methods</p>
            <div class="table-row">
              <p>
                openModal(<span class="required">modalComponent</span>, modalProps?, modalSize?)
              </p>
              <div>
                <p><span class="required">modalComponent</span>: VueComponent</p>
                <p>modalProps?: object</p>
                <p>modalSize?: small | medium | large (default)</p>
              </div>
            </div>
            <div class="table-row">
              <p>closeModal()</p>
            </div>
            <div class="table-row">
              <p>preventAutoClose()</p>
            </div>
          </div>
        </template>
      </Collapse>
      <div class="flex flex-wrap gap-[20px] md:gap-x-[40px] md:gap-y-[20px] mt-[20px]">
        <OtivoButton @click="openLargeModal"> Open large modal (default) </OtivoButton>
        <OtivoButton @click="openMediumModal"> Open medium modal </OtivoButton>
        <OtivoButton @click="openSmallModal"> Open small modal </OtivoButton>
        <OtivoButton @click="openManualModal"> Manually closed modal </OtivoButton>
      </div>
    </div>
    <!-- Table -->
    <div class="mt-[40px]">
      <Collapse :default-open="false">
        <template #heading>
          <div>
            <h2 class="underline">Table (OtivoTable.vue)</h2>
            <p class="mt-[20px] paragraph-1">
              This component creates a table with customizable columns and rows. It supports a
              variety of column types, including strings and input fields. To create a table, pass
              in an array of objects left to right top to bottom. This is to account for the mobile
              view, where the table is displayed in a single column.
            </p>
          </div>
        </template>
        <template #body>
          <div class="table-container">
            <p class="table-heading">Props</p>
            <div class="table-row">
              <p>component</p>
              <div>Either text field or component</div>
            </div>
            <div class="table-row">
              <p>value</p>
              <div>Value for input field</div>
            </div>
            <div class="table-row">
              <p>fieldType</p>
              <div>
                <p>heading</p>
                <p>html</p>
                <p>text</p>
                <p>number</p>
                <p>currency</p>
                <p>percent</p>
                <p>email</p>
                <p>phone</p>
              </div>
            </div>
            <div class="table-row">
              <p>class</p>
              <div>Class assigned to component</div>
            </div>
            <div class="table-row">
              <p>disabled</p>
              <div>boolean</div>
            </div>
            <div class="table-row">
              <p>name</p>
              <div>Non front facing name key</div>
            </div>
          </div>
        </template>
      </Collapse>
      <OtivoTable :table-data="tableData" :number-of-columns="2" class="mt-[40px]" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import BaseDropDown from '@/components/Inputs/BaseDropDown.vue'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import BaseRadioButtons from '@/components/Inputs/BaseRadioButtons.vue'
import BaseSwitch from '@/components/Inputs/BaseSwitch.vue'
import BaseSwitchWithText from '@/components/Inputs/BaseSwitchWithText.vue'
import BaseTabButton from '@/components/Inputs/BaseTabButton.vue'
import Collapse from '@/components/SaveAndCollapse/Collapse.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import SampleLargeModal from '@/components/Modals/SampleLargeModal.vue'
import SampleManualCloseModal from '@/components/Modals/SampleManualCloseModal.vue'
import SampleMediumModal from '@/components/Modals/SampleMediumModal.vue'
import SampleSmallModal from '@/components/Modals/SampleSmallModal.vue'
import { InputItem } from '@/types/InputItem'
import { markRaw, ref } from 'vue'
import { useModalStore } from '@/store/pinia/ModalStore'
import BaseDateInput from '@/components/Inputs/BaseDateInput.vue'
import Status from '@/components/Status.vue'
import OtivoTable from '@/components/OtivoTable.vue'
import BaseRangeInput from '@/components/Inputs/BaseRangeInput.vue'
import PercentageInput from '@/components/BasicInputs/PercentageInput.vue'

// Date Input
const dateInputData = ref('')
const dateInputExistingDateData = ref('2023-04-16')
const dateErrorData = ref('')
const dateErrorMessage = ref('Date is required')

// Tab Button
const tabButtonData = ref<Array<InputItem>>([
  { label: 'Income', value: 'income', disabled: false },
  { label: 'Asset', value: 'asset', disabled: false },
  { label: 'Liability', value: 'liability', disabled: false },
  { label: 'Disabled', value: 'disabled', disabled: true },
])
const activeTab = ref<InputItem>({ label: '', value: '' })
const tabButtonData2 = ref<Array<InputItem>>([
  { label: 'Income', value: 'income', disabled: false },
  { label: 'Asset', value: 'asset', disabled: false },
  { label: 'Disabled', value: 'disabled', disabled: true },
])
const activeTab2 = ref<InputItem>({ label: 'Asset', value: 'asset' })

// Input
const inputDemoData = ref('')
const inputDemoDisabledData = ref('')
const inputNumberData = ref(1000)
const inputCurrencyData = ref(10000)
const inputPercentData = ref(0.5)
const inputMobilePhoneData = ref('04')
const inputErrorData = ref('Whoopsie')
const inputErrorMessage = ref('An error occurred, please fix it')

// Drop Down
const selectMenuDataDefault = ref([
  { label: 'Form item 1', value: 1 },
  { label: 'Form item 2', value: 2 },
  { label: 'Form item 3', value: 3 },
  { label: 'Form item 4', value: 4 },
  { label: 'Form item 5', value: 5 },
])
const selectMenuSelectedItemDefault = ref<InputItem>({ label: '', value: '' })
const selectMenuDataPlaceholder = ref([
  { label: 'Form item 1', value: 1 },
  { label: 'Form item 2', value: 2 },
  { label: 'Form item 3', value: 3 },
  { label: 'Form item 4', value: 4 },
  { label: 'Form item 5', value: 5 },
])
const selectMenuSelectedItemPlaceholder = ref<InputItem>({ label: '', value: '' })
const selectMenuDataDefaultValue = ref([
  { label: 'Form item 1', value: 1 },
  { label: 'Form item 2', value: 2 },
  { label: 'Form item 3', value: 3 },
  { label: 'Form item 4', value: 4 },
  { label: 'Form item 5', value: 5 },
])
const selectMenuSelectedItemDefaultValue = ref<InputItem>({ label: 'Form item 3', value: 3 })
const selectMenuDataDisabled = ref([{ label: 'Disabled menu', value: 1 }])
const selectMenuErrorData = ref<InputItem>({ label: '', value: '' })
const selectMenuErrorMessage = ref('This field is required, please select an option')

// Radio buttons
const sampleStringRadioButtonData = ref([
  {
    label: 'Dean',
    value: 'dean',
  },
  {
    label: 'Joy',
    value: 'Joy',
  },
  {
    label: 'Matt',
    value: 'matt',
  },
  {
    label: 'Nick D',
    value: 'nickD',
  },
  {
    label: 'Nick',
    value: 'nick',
  },
  {
    label: 'Rich',
    value: 'rich',
  },
  {
    label: 'Rubens',
    value: 'rubens',
  },
])
const sampleStringSelectedRadioItem = ref<InputItem | null>(null)
const sampleBooleanRadioButtonData = ref([
  {
    label: 'True',
    value: true,
  },
  {
    label: 'False',
    value: false,
  },
])
const sampleBooleanSelectedRadioItem = ref<InputItem | null>(null)
const sampleNumberRadioButtonData = ref([
  {
    label: 'One',
    value: 1,
  },
  {
    label: 'Two',
    value: 2,
  },
  {
    label: 'Three',
    value: 3,
  },
  {
    label: 'Four',
    value: 4,
  },
])
const sampleNumberSelectedRadioItem = ref<InputItem | null>(
  sampleNumberRadioButtonData.value.at(-1),
)
const sampleErrorRadioButtonData = ref([
  {
    label: 'One',
    value: 1,
  },
  {
    label: 'Two',
    value: 2,
  },
  {
    label: 'Three',
    value: 3,
  },
  {
    label: 'Four',
    value: 4,
  },
])
const sampleErrorSelectedRadioItem = ref<InputItem | null>(null)
const sampleRadioItemErrorMessage = ref('Please select an item')
// Checkbox
const smallSampleCheckbox = ref(false)
const errorCheckbox = ref(false)
const errorCheckboxMessage = ref('Checkbox is required')

// Toggle
const sampleToggle = ref(false)
const labelLessToggle = ref(true)
const sampleToggleWithText = ref(false)
const sampleToggle2 = ref(false)
const labelLessToggle2 = ref(true)
const disabledToggle = ref(false)

// Base Range Input
const sampleBaseRangeInputValue = ref(69)
const sampleBaseRangeInputValueWithRecommendedValue = ref(69)

// Button
const handleButtonClick = () => {
  alert('The button was clicked!')
}

// Modal
const modalStore = useModalStore()

const openLargeModal = () => {
  modalStore.openModal(SampleLargeModal, {
    heading: 'This is a sample of the large modal, large is the default',
    body: 'The content goes here and can be customized however you like!',
    testFunction: () => alert('Long live the modal!'),
  })
}

const openMediumModal = () => {
  modalStore.openModal(
    SampleMediumModal,
    {
      heading: 'This is a sample of the medium modal',
      body: 'The content goes here and can be customized however you like!',
      testFunction: () => alert('Long live the modal!'),
    },
    'medium',
  )
}

const openSmallModal = () => {
  modalStore.openModal(
    SampleSmallModal,
    {
      heading: 'This is a sample of the small modal',
      body: 'The content goes here and can be customized however you like!',
      testFunction: () => alert('Long live the modal!'),
    },
    'small',
  )
}

const openManualModal = () => {
  modalStore.openModal(SampleManualCloseModal, {
    heading: 'This modal must be closed manually',
    body: 'The developer must explicitly decide how this modal will be closed.',
    testFunction: () => alert('Functions can run without closing the modal.'),
  })
  modalStore.preventAutoClose()
}

const tableData = ref([
  {
    component: 'Column 1',
    fieldType: 'heading',
  },
  {
    component: 'Column 2',
    fieldType: 'heading',
  },
  {
    component: 'Row 1',
    fieldType: 'html',
  },
  {
    component: markRaw(BaseInput),
    fieldType: 'currency',
    name: 'row_1_currency',
    value: 123,
  },
  {
    component: 'Row 2',
    fieldType: 'html',
  },
  {
    component: markRaw(BaseInput),
    fieldType: 'number',
    name: 'row_2_number',
    value: 123,
  },
  {
    component: 'Row 3',
    fieldType: 'html',
  },
  {
    component: markRaw(BaseInput),
    fieldType: 'input',
    name: 'row_3_disabled_input',
    disabled: true,
    value: 123,
  },
])
</script>

<style lang="scss" scoped>
@import '@/assets/css/themes/OtivoMain/typography';

.table-container {
  @apply border-[2px] border-blue-5 divide-blue-5 divide-y overflow-auto rounded-[20px] mt-[20px];
}

.table-heading {
  @apply bg-blue-5 text-blue-1 p-[20px];
}

.table-row {
  @apply grid grid-cols-2 divide-blue-5 divide-x;

  @extend .paragraph-1;
}

.table-row > * {
  @apply p-[20px];
}

.required {
  font-weight: bold;
}
</style>
