<template>
  <BaseView>
    <div class="flex flex-col bg-blue-6 items-center relative py-[60px]">
      <div class="headline-1 text-center mx-[10px]" v-html="content.heading" />
      <div class="intro-1 mt-[20px] md:w-[800px] text-center mx-[10px]">
        {{ content.subHeading }}
      </div>
      <section class="flex flex-col w-full px-6 lg:w-100">
        <div class="flex flex-col justify-center">
          <Dropdown
            :items="selectMenuDataDefault"
            :existing-item="null"
            placeholder="16-24"
            @selected="handleRangeSelected"
            class="mt-[20px]"
            type="text" />
          <div class="flex flex-col mt-[20px]">
            <div class="flex justify-between">
              <p class="menu-1 text-blue-1">Salary Contributions</p>
              <p class="menu-1 text-blue-1">{{ formatAsCurrency(contributionAmount) }}</p>
            </div>
            <BaseRangeInput
              v-model:value="contributionAmount"
              :show-tooltip="false"
              :max="maxContribution"
              :min="minContribution"
              :step="step"
              ball-background-colour="blue-6">
            </BaseRangeInput>
          </div>
        </div>
      </section>
      <div class="flex max-w-[80rem] w-full justify-center relative h-full">
        <div class="flex w-full md:gap-[40px] mt-[20px] h-[450px] justify-center">
          <!-- Blue circle -->
          <div class="flex flex-col justify-end items-center">
            <div class="text-blue-1 menu-1 mt-2">WITHOUT ADVICE</div>
            <div class="blueCube" :style="'height:' + 170 + 'px;'">
              <div class="frontSquare" :style="'height:' + 150 + 'px;'"></div>
              <div class="rightSquare" :style="'height:' + 152 + 'px;'"></div>
              <div class="topSquare" :style="'bottom:' + 118 + 'px;'"></div>
              <div
                class="text-white text-center button-2 w-[140px] absolute left-1"
                :style="'bottom:' + 118 / 2 + 'px;'"
                v-if="regularValue">
                Your super might stay at
                <span>
                  {{ formatAsCurrency(regularValue) }}
                </span>
              </div>
            </div>
            <img class="w-[210px] -mt-5" :src="CircleBase" alt="Circle Base" />
          </div>
          <!-- Red circle with text -->
          <div class="flex flex-col justify-end items-center">
            <div>
              <OtivoIcon
                fill-1="otivo-red"
                fill-2="otivo-red"
                class="fill-otivo-red w-[34px] h-[34px] m-auto" />
              <div class="text-otivo-red menu-1 mt-2">WITH ADVICE</div>
            </div>
            <div class="redCube" :style="'height:' + (220 + redCubeHeight) + 'px'">
              <div class="frontSquare" :style="'height:' + (200 + redCubeHeight) + 'px;'"></div>
              <div class="rightSquare" :style="'height:' + (202 + redCubeHeight) + 'px;'"></div>
              <div class="topSquare" :style="'bottom:' + (158 + redCubeHeight) + 'px;'"></div>
              <div
                class="text-white text-center button-2 w-[180px] absolute left-1"
                :style="'bottom:' + (158 + redCubeHeight) / 2 + 'px;'"
                v-if="otivoValue">
                Your super can grow to
                <span class="button-1 !text-[26px]">
                  {{ formatAsCurrency(otivoValue) }}
                </span>
              </div>
            </div>
            <img class="w-[270px] -mt-5" :src="CircleBase" alt="Circle Base" />
          </div>
        </div>
        <div class="hidden md:block absolute -bottom-10 -right-10 w-[200px]">
          <img :src="Skibbidy" alt="Super Boost Hunter" />
          <img class="mt-10" :src="CircleBase" alt="Circle Base" />
        </div>
      </div>
      <OtivoButton
        class="mt-[80px]"
        size="large"
        @click="createAccountWithModuleStream('salarySacrificeContributions')">
        Help me grow my super
      </OtivoButton>
      <div class="mt-10 fix-width-m-auto p-2 text-xs leading-4">
        Assumptions: These projections are in today’s dollars, based on an annual salary of $90,000
        with the compulsory employer super guarantee remaining unchanged until age 65. We also
        assume super will grow at 4% per year, after inflation, tax, and fees. Insurance premiums,
        if any, are excluded. The projections are based on average super balances by age and current
        super and tax laws, which may change. Please keep in mind that these results aren’t
        guaranteed and might differ from those of a defined benefit fund. The information provided
        is general in nature and does not take into account your specific financial situation or
        needs.
      </div>
    </div>
  </BaseView>
</template>

<script setup lang="ts">
import Dropdown from '@/components/BasicInputs/Dropdown.vue'
import BaseRangeInput from '@/components/Inputs/BaseRangeInput.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import Skibbidy from '@/assets/img/skibbidy.png'
import CircleBase from '@/assets/img/circle-base.png'
import BaseView from '@/views/BaseView.vue'
import { computed, ref } from 'vue'
import createAccountWithModuleStream from '@/composables/createAccountWithModuleStream.ts'
import formatAsCurrency from '@/composables/formatAsCurrency.ts'
import OtivoIcon from '@/components/icons/OtivoIcon.vue'

const redCubeHeight = computed(() => mapContributionToSize(contributionAmount.value, 0, 150))

const maxContribution = ref(20000)
const minContribution = ref(5000)
const contributionAmount = ref(5000)
const step = ref(100)

const returnRate = ref(0.04)
const salary = 90000
const employerContribution = 0.115

const content = {
  heading: `How much can I <span class='text-otivo-red'>boost my super</span><br> with salary sacrifice?`,
  subHeading:
    "By following our advice to add more from their salary into super, the average person's retirement balance is boosted by $180,356.",
}

const selectMenuDataDefault = ref([
  { label: '16-24', value: 0 },
  { label: '25-34', value: 1 },
  { label: '35-44', value: 2 },
  { label: '45-54', value: 3 },
  { label: '55-64', value: 4 },
  { label: '65-74', value: 5 },
])

const valuesBasedOnAge = ref([
  { startingSuperBalance: 8000, yearsTillRetirement: 45 },
  { startingSuperBalance: 45000, yearsTillRetirement: 35.5 },
  { startingSuperBalance: 90000, yearsTillRetirement: 25.5 },
  { startingSuperBalance: 170000, yearsTillRetirement: 15.5 },
  { startingSuperBalance: 290000, yearsTillRetirement: 5.5 },
  { startingSuperBalance: 220000, yearsTillRetirement: 1 },
])
const selectedIndex = ref(0)
const selectedPeriod = computed(() => valuesBasedOnAge.value[selectedIndex.value])

// Function to map contribution to cube size
const mapContributionToSize = (contribution: number, minSize: number, maxSize: number) => {
  const range = maxContribution.value - minContribution.value
  const scale = (maxSize - minSize) / range
  return Math.round(minSize + scale * (contribution - minContribution.value))
}

const handleRangeSelected = (selected: { label: string; value: number }) => {
  selectedIndex.value = selected?.value
}

const calculateEndBalance = (principal: number, rate: number, period: number): number => {
  return principal * Math.pow(1 + rate, period)
}

const calculateCompulsoryContributions = (rate: number, periods: number): number => {
  const salaryContribution = salary - salary / (1 + employerContribution)
  return (salaryContribution * (Math.pow(1 + rate, periods) - 1)) / rate
}

const calculateAdditionalContributions = (extra: number, rate: number, periods: number): number => {
  return (extra * (Math.pow(1 + rate, periods) - 1)) / rate
}

const calculateBalanceWithExtraContributions = (
  principal: number,
  rate: number,
  periods: number,
): number => {
  const endBalance = calculateEndBalance(principal, rate, periods)
  const compulsoryContributions = calculateCompulsoryContributions(rate, periods)
  const addContributions = calculateAdditionalContributions(contributionAmount.value, rate, periods)
  return endBalance + compulsoryContributions + addContributions
}

const calculateBalanceWithoutExtraContributions = (
  principal: number,
  rate: number,
  periods: number,
): number => {
  const endBalance = calculateEndBalance(principal, rate, periods)
  const compulsoryContributions = calculateCompulsoryContributions(rate, periods)
  return endBalance + compulsoryContributions
}

const otivoValue = computed(() =>
  calculateBalanceWithExtraContributions(
    selectedPeriod.value.startingSuperBalance,
    returnRate.value,
    selectedPeriod.value.yearsTillRetirement,
  ),
)
const regularValue = computed(() =>
  calculateBalanceWithoutExtraContributions(
    selectedPeriod.value.startingSuperBalance,
    returnRate.value,
    selectedPeriod.value.yearsTillRetirement,
  ),
)
</script>

<style lang="scss" scoped>
.blueCube {
  position: relative;
  height: 200px;
  width: 167px;
}

.redCube {
  position: relative;
  width: 215px;
}

.blueCube > .frontSquare {
  background: #0064ff;
  width: 150px;
}

.blueCube > .rightSquare {
  width: 20px;
  left: 148px;
  background: #0037a1;
}

.blueCube > .topSquare {
  width: 25px;
  height: 75px;
  background: #0037a1;
  left: 72px;
}

.redCube > .frontSquare {
  width: 190px;
  background: #f00;
}

.redCube > .rightSquare {
  width: 20px;
  left: 189px;
  background: #982c17;
}

.redCube > .topSquare {
  width: 25px;
  height: 95px;
  background: #982c17;
  left: 92px;
}

.frontSquare {
  border: solid 1px #333;
  border-radius: 1px 0 1px 1px;
  float: left;
  position: absolute;
  bottom: 0;
}

.rightSquare {
  border: solid 1px #333;
  border-top: 0;
  border-radius: 0 4px 1px 2px;
  transform: skew(0deg, 150deg);
  float: left;
  position: absolute;
  bottom: 5px;
}

.topSquare {
  border: solid 1px #333;
  border-radius: 2px 1px 4px 0;
  border-bottom: 0;
  float: left;
  transform: rotate(-30deg) skew(60deg, 0deg);
  position: absolute;
}
</style>
