<template>
  <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1616_16557)">
      <path
        :class="`fill-${fill1} group-hover:fill-${hover1 ?? fill1}`"
        d="M13.25 12.36C12.6876 11.3413 11.8417 10.5076 10.815 9.96C11.2886 9.41755 11.5959 8.75023 11.7004 8.03778C11.8048 7.32533 11.7019 6.59787 11.404 5.94233C11.1061 5.28678 10.6257 4.73087 10.0203 4.34102C9.41492 3.95116 8.71008 3.74385 7.99001 3.74385C7.26995 3.74385 6.56511 3.95116 5.95971 4.34102C5.35431 4.73087 4.87395 5.28678 4.57603 5.94233C4.27811 6.59787 4.17523 7.32533 4.27967 8.03778C4.38411 8.75023 4.69147 9.41755 5.16501 9.96C4.14411 10.512 3.30522 11.3483 2.75001 12.3675C2.63858 12.5981 2.61987 12.8626 2.69772 13.1066C2.77558 13.3506 2.94407 13.5554 3.16847 13.6788C3.39287 13.8022 3.65607 13.8349 3.90381 13.77C4.15155 13.7051 4.36495 13.5476 4.50001 13.33C4.84548 12.7045 5.35218 12.183 5.96743 11.8197C6.58268 11.4564 7.28402 11.2645 7.99854 11.264C8.71306 11.2635 9.41467 11.4544 10.0304 11.8168C10.6462 12.1793 11.1537 12.7 11.5 13.325C11.5603 13.4454 11.6443 13.5524 11.747 13.6395C11.8496 13.7267 11.9688 13.7922 12.0974 13.8321C12.226 13.8721 12.3613 13.8857 12.4953 13.872C12.6293 13.8584 12.7591 13.8179 12.877 13.7529C12.9949 13.6878 13.0985 13.5997 13.1815 13.4937C13.2645 13.3876 13.3253 13.2659 13.3601 13.1359C13.3949 13.0058 13.4031 12.87 13.3842 12.7367C13.3653 12.6034 13.3197 12.4752 13.25 12.36ZM6.25001 7.5C6.25001 7.15388 6.35265 6.81553 6.54494 6.52775C6.73723 6.23996 7.01055 6.01566 7.33032 5.88321C7.65009 5.75075 8.00195 5.7161 8.34142 5.78362C8.68089 5.85115 8.99271 6.01782 9.23745 6.26256C9.48219 6.5073 9.64886 6.81912 9.71639 7.15859C9.78391 7.49806 9.74925 7.84992 9.6168 8.16969C9.48435 8.48946 9.26005 8.76277 8.97226 8.95507C8.68447 9.14736 8.34613 9.25 8.00001 9.25C7.53588 9.25 7.09076 9.06562 6.76257 8.73743C6.43439 8.40924 6.25001 7.96413 6.25001 7.5Z" />
      <path
        :class="`fill-${fill2} group-hover:fill-${hover2 ?? fill2}`"
        d="M13.4175 3.5825C13.9698 3.5825 14.4175 3.13479 14.4175 2.5825C14.4175 2.03022 13.9698 1.5825 13.4175 1.5825C12.8652 1.5825 12.4175 2.03022 12.4175 2.5825C12.4175 3.13479 12.8652 3.5825 13.4175 3.5825Z" />
      <path
        :class="`fill-${fill2} group-hover:fill-${hover2 ?? fill2}`"
        d="M15.6351 5.605C15.5993 5.47574 15.5379 5.35501 15.4545 5.24999C15.371 5.14496 15.2673 5.05779 15.1495 4.99367C15.0317 4.92954 14.9022 4.88977 14.7687 4.87671C14.6352 4.86366 14.5005 4.8776 14.3725 4.91769C14.2445 4.95778 14.1259 5.02321 14.0237 5.11009C13.9215 5.19696 13.8378 5.30351 13.7777 5.42338C13.7175 5.54325 13.6821 5.67401 13.6735 5.80785C13.6649 5.9417 13.6833 6.07592 13.7276 6.2025C14.1064 7.40884 14.0944 8.704 13.6934 9.90312C13.2923 11.1022 12.5227 12.144 11.4944 12.8797C10.4661 13.6154 9.2316 14.0075 7.96722 13.9999C6.70284 13.9923 5.47316 13.5855 4.45374 12.8375C3.43431 12.0895 2.67725 11.0386 2.29061 9.83473C1.90396 8.63089 1.90752 7.33567 2.30075 6.13397C2.69399 4.93227 3.45681 3.88551 4.48032 3.14312C5.50383 2.40073 6.73572 2.00065 8.00012 2C8.61062 1.99956 9.21761 2.0923 9.80012 2.275C10.0534 2.35457 10.3279 2.33026 10.5633 2.20742C10.7986 2.08459 10.9756 1.87328 11.0551 1.62C11.1347 1.36672 11.1104 1.09221 10.9875 0.856848C10.8647 0.62149 10.6534 0.444567 10.4001 0.365003C8.69303 -0.172482 6.85501 -0.122021 5.17997 0.508314C3.50494 1.13865 2.08964 2.31245 1.1604 3.84202C0.231166 5.37159 -0.158342 7.16858 0.0541666 8.94562C0.266675 10.7227 1.06892 12.3771 2.33263 13.6445C3.59633 14.9118 5.2485 15.7188 7.02493 15.9364C8.80137 16.154 10.5995 15.7696 12.1317 14.8447C13.6639 13.9199 14.8418 12.5079 15.4769 10.8347C16.112 9.1615 16.1677 7.32363 15.6351 5.615V5.605Z" />
    </g>
    <defs>
      <clipPath id="clip0_1616_16557">
        <rect fill="white" height="16" width="16" />
      </clipPath>
    </defs>
  </svg>
</template>
<script lang="ts" setup>
type Props = {
  fill1?: string
  fill2?: string
  hover1?: string | null
  hover2?: string | null
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-red',
  fill2: 'otivo-blue',
  hover1: null,
  hover2: null
})
</script>
