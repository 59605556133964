<template>
  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.7753 40C17.3882 39.9996 17.004 39.9341 16.6386 39.8063C16.0104 39.6027 15.4628 39.2051 15.0745 38.6706C14.6862 38.1361 14.4773 37.4922 14.4777 36.8314V25.1631H8.23222C7.61485 25.1678 7.00852 24.9993 6.48196 24.6768C5.95541 24.3542 5.5297 23.8905 5.25313 23.3381C4.99085 22.7922 4.89482 22.1812 4.977 21.5811C5.05917 20.981 5.31593 20.4184 5.71529 19.9633L22.1159 1.13897C22.5585 0.634792 23.1423 0.275273 23.7915 0.107007C24.4407 -0.0612597 25.1254 -0.0305153 25.757 0.195255C26.3853 0.398784 26.9329 0.796373 27.3211 1.33089C27.7094 1.86541 27.9183 2.50933 27.9179 3.17014V14.8384H34.1634C34.7815 14.83 35.3894 14.997 35.9166 15.3199C36.4438 15.6429 36.8689 16.1087 37.1425 16.6634C37.4048 17.2093 37.5008 17.8203 37.4186 18.4204C37.3364 19.0205 37.0797 19.5831 36.6803 20.0383L20.2797 38.8625C19.9681 39.2207 19.5833 39.5076 19.1511 39.7039C18.719 39.9001 18.2498 40.0011 17.7753 40ZM12.3292 20.1632H19.6739V31.7753L30.0664 19.8383H22.7217V8.2262L12.3292 20.1632Z"
      :class="`fill-${fill1}`"
      :fill="fill1" />
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1?: string
}
withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue'
})
</script>
