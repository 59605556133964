import { defineStore } from 'pinia'
import { extend } from '@vue/shared'
import { Ref, ref, shallowRef } from 'vue'
import { GenericObject } from '@/types/GlobalTypes.ts'

const componentType = extend({})
type VueComponent = InstanceType<typeof componentType>
type ModalSize = 'small' | 'medium' | 'large' | 'extra-large' | string

export const useModalStore = defineStore('ModalStore', () => {
  const isOpen = ref(false)
  const isCloseable = ref(true)
  const component = shallowRef<null | VueComponent>(null)
  const props = ref<GenericObject<unknown>>({})
  const size: Ref<ModalSize> = ref('large')
  const white = ref(false)
  // style will just inline style the modal
  const styleOverride = ref()
  /**
   * Override will overwrite the entire modal styles and close buttons
   */
  const override = ref(false)

  const openModal = (
    modalComponent: VueComponent,
    modalProps?: GenericObject<unknown>,
    modalSize: ModalSize = 'large',
    modalOverride = false,
    style?: string,
    setXToWhite?: boolean,
  ) => {
    isOpen.value = true
    component.value = modalComponent
    props.value = modalProps ?? {}
    size.value = modalSize
    override.value = modalOverride
    styleOverride.value = style
    white.value = setXToWhite ?? false
  }

  const closeModal = (onComplete?: () => unknown) => {
    if (onComplete) onComplete()
    isOpen.value = false
    component.value = null
    props.value = {}
    isCloseable.value = true
  }

  // This prevents the modal from being closed, modal must be explicitly closed by component
  const preventAutoClose = () => {
    isCloseable.value = false
  }

  return {
    isOpen,
    isCloseable,
    component,
    props,
    size,
    override,
    styleOverride,
    white,
    openModal,
    closeModal,
    preventAutoClose,
  }
})
