<template>
  <modal
    @close="$emit('close', 'no')"
    container-styles="p-[20px] pt-[40px] rounded otivo-drop-shadow relative">
    <template #header>
      <div class="mh4 md:h4">
        {{ heading || capitalize }}
      </div>
    </template>

    <template #body>
      <div v-if="!loading" class="flex my-2 text-14px">
        <div class="py-2">
          <p v-if="msg !== ''">
            {{ msg || capitalize }}
          </p>
          <p class="py-2" v-if="msg_second !== ''">
            {{ msg_second || capitalize }}
          </p>
        </div>
        <slot />
      </div>
      <loader v-if="loading" />
    </template>

    <template #footer>
      <div class="flex flex-row-reverse">
        <custom-button
          class="mx-5 w-32 rounded"
          colour="blue"
          :class="{ 'bg-red': danger === 'no' }"
          :disabled="loading"
          @click="$emit('close', 'no')">
          {{ no_btn_copy }}
        </custom-button>
        <custom-button
          class="w-32 rounded border"
          colour="white"
          :class="{ 'bg-red': danger === 'yes' }"
          :disabled="loading"
          @click="$emit('close', 'yes')">
          {{ yes_btn_copy }}
        </custom-button>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '../Modals/Modal.vue'

import CustomButton from '../CustomButton/CustomButton.vue'

import Loader from '../Loader/Loader.vue'

export default {
  name: 'ConfirmAlertBox',
  components: { Loader, CustomButton, Modal },
  props: {
    msg: {
      type: String,
      default: '',
    },
    msg_second: {
      type: String,
      default: '',
    },
    no_btn_copy: {
      type: String,
      default: 'No',
    },
    yes_btn_copy: {
      type: String,
      default: 'Yes',
    },
    heading: {
      type: String,
      required: true,
    },
    danger: {
      type: String,
      default: 'yes',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
}
</script>

<style scoped></style>
