import getApiInstance from '@/services/Api'

const api = getApiInstance()

export default {
  getQAHistory() {
    return api.Get('/llm/historyAuth')
  },

  getNextPageChatHistory(nextPageNumber: number) {
    return api.Get(`/llm/historyAuth?page=${nextPageNumber}`)
  },

  askQuestion(payload: { question: string }) {
    return api.Post('/llm/qa_auth', payload)
  },

  askDocumentQuestion(superId: number, sessionId: string, payload: { question: string }) {
    return api.Post(`/llm/document/${superId}?sessionId=${sessionId}`, payload)
  },

  updateReaction(payload: { answerId: number; newReaction: number }) {
    return api.Put(`/llm/${payload.answerId}`, payload)
  },

  updateDocumentReaction(payload: { answerId: number; newReaction: number }) {
    return api.Put(`/llm/document/qa/${payload.answerId}`, payload)
  },

  getConsent() {
    return api.Get('/llm/consent')
  },

  updateConsent(payload: { consent: boolean }) {
    return api.Post('/llm/updateConsent', payload)
  }
}
