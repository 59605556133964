<template>
  <BaseView>
    <div class="flex flex-col items-center justify-center min-h-100% py-[40px]">
      <Loader v-if="!user.auth0id" />
      <section v-if="!clientConfirmDetails" class="mt-10">
        <ClientConfirmationConfirmDetails @click="clientConfirmDetails = true" />
      </section>
      <div
        v-else
        class="max-w-[560px] w-100% text-left py-[40px] px-[30px] md:p-[40px] bg-white rounded-lg border-[2px] border-blue-5 otivo-drop-shadow">
        <div class="headline-5 bold">{{ pageContent.paymentDisclaimer.heading }}...</div>
        <div class="flex flex-col gap-3 mt-[20px]">
          <SmallCheckbox
            class="check-box"
            :checked="feeConsentLocal"
            name="Guidance Client Consent"
            data-test="guidanceAdviceConsentOne"
            @click="toggleFeeConsent()"
            @keyup.enter="toggleGuidanceConsent()"
            :tabindex="0"
            :label="checkboxText" />
          <SmallCheckbox
            class="check-box"
            :checked="clientConsentLocal"
            name="Guidance Client Consent"
            data-test="guidanceAdviceConsentTwo"
            @click="toggleGuidanceConsent()"
            @keyup.enter="toggleGuidanceConsent()"
            :tabindex="0"
            :label="pageContent.paymentDisclaimer.checkBox" />
        </div>
        <div
          v-for="(paragraph, index) in paragraphText"
          class="paragraph-1 text-blue-1 mt-[20px]"
          :key="index">
          <div v-html="paragraph" />
        </div>
        <PrivacyCollectionStatement class="my-[20px]" :open="privacyOpen" @click="openPrivacy()" />
        <div class="flex flex-col w-100% gap-[20px] md:w-fit pt-10">
          <OtivoButton
            size="large"
            :disabled="!privacyHasBeenOpened || !clientConsentLocal || !feeConsentLocal"
            @click="acknowledgePrivacy()"
            colour="blue"
            class="w-100%"
            data-test="proceedButton"
            :loading="loading"
            :large-text="true">
            Proceed
          </OtivoButton>
          <OtivoButton
            size="large"
            colour="white"
            class="w-100% md:w-fit"
            @click="router.push('/dashboard')">
            Do not proceed
          </OtivoButton>
        </div>
      </div>
    </div>
  </BaseView>
</template>

<script lang="ts" setup>
import PrivacyCollectionStatement from '@/components/SignUp/Step2/PrivacyCollectionStatement.vue'
import { computed, onBeforeMount, ref } from 'vue'
import SmallCheckbox from '@/components/BasicInputs/CustomCheckBox/SmallCheckbox.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useUserStore } from '@/store/pinia/UserStore'
import { DisclaimerComponentsType } from '@/types/CallcentrePortalTypes.ts'
import User from '@/types/api/account/User.ts'
import { useAccountCreationStore } from '@/store/pinia/AccountCreationStore.ts'
import BaseView from '@/views/BaseView.vue'
import { useModalStore } from '@/store/pinia/ModalStore'
import UserRenewedWelcomeBackModal from '@/components/Modals/UserRenewedWelcomeBackModal.vue'
import { sendLegalWelcomeEmail } from '@/store/pinia/adminPortal/EmailManager.ts'
import ClientConfirmationConfirmDetails from '@/components/CFS/CFSClientConfirmationSteps/ClientConfirmationConfirmDetails.vue'
import Loader from '@/components/Loader/Loader.vue'

const store = useStore()
const userStore = useUserStore()
const router = useRouter()
const accountCreationStore = useAccountCreationStore()

const loading = ref(false)
const privacyOpen = ref(false)
const privacyHasBeenOpened = ref(false)
const clientConfirmDetails = ref(false)
const feeConsentLocal = ref(false)
const clientConsentLocal = ref(false)
const modalstore = useModalStore()
const route = useRoute()

const user = computed((): User => userStore.getUser)

const pageContent = computed(
  (): DisclaimerComponentsType => store.getters['ContentStore/pageContent'],
)
const isUserEmpty = computed(() => {
  if (!user.value) return true
  return Object.keys(user.value).length === 0
})

const memberId = computed(() => user.value.member_id ?? '-')
onBeforeMount(() => {
  if (isUserEmpty.value) {
    router.push('/dashboard')
  }
  store.dispatch('ContentStore/getContent', 'clientDisclaimer')
})

const isNewUser = computed(() => !user.value.fee_consent_expires_at)

const openPrivacy = () => {
  privacyOpen.value = !privacyOpen.value
  privacyHasBeenOpened.value = true
}

const acknowledgePrivacy = async () => {
  loading.value = true

  const wasNewUser = isNewUser.value

  try {
    const { auth0id, account_id, fee_consent_expires_at, account_deactivated } = user.value

    if (wasNewUser) {
      await sendLegalWelcomeEmail(auth0id)
      await accountCreationStore.setTermsAndConditionsAgreed(account_id)
    } else {
      await accountCreationStore.renewTermsAndConditionsAgreed(
        account_id,
        fee_consent_expires_at,
        account_deactivated,
      )
    }

    const target =
      wasNewUser && route.params?.adviceTargetName
        ? `/dashboard/${route.params.adviceTargetName}`
        : '/dashboard'

    await userStore.fetchUser()
    loading.value = false
    await router.push(target)

    if (!wasNewUser) {
      modalstore.openModal(UserRenewedWelcomeBackModal, {
        handleCloseModal: modalstore.closeModal,
      })
    }
  } catch (error) {
    console.error(error)
    loading.value = false
  }
}

const toggleGuidanceConsent = () => {
  clientConsentLocal.value = !clientConsentLocal.value
}
const toggleFeeConsent = () => {
  feeConsentLocal.value = !feeConsentLocal.value
}

const today = new Date()
const formatDate = (timestamp: number) =>
  new Date(timestamp).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })

const currentExpirationDate = computed(() => {
  const expirationTimestamp = isNewUser.value
    ? today.getTime()
    : parseInt(user.value.fee_consent_expires_at, 10)
  const expirationDate = new Date(expirationTimestamp)

  return expirationDate < today ? formatDate(today.getTime()) : formatDate(expirationDate.getTime())
})

const newExpirationDate = computed(() => {
  if (isNewUser.value) {
    const nextYear = new Date(today)
    nextYear.setFullYear(nextYear.getFullYear() + 1)
    return formatDate(nextYear.getTime())
  }

  const expirationTimestamp = parseInt(user.value.fee_consent_expires_at, 10)
  const expirationDate = new Date(expirationTimestamp)

  if (expirationDate < today) {
    const nextYear = new Date(today)
    nextYear.setFullYear(nextYear.getFullYear() + 1)
    return formatDate(nextYear.getTime())
  } else {
    expirationDate.setFullYear(expirationDate.getFullYear() + 1)
    return formatDate(expirationDate.getTime())
  }
})

const checkboxText = computed(() => {
  return `I agree to a one-off deduction of $88 (includes GST) from my nominated CFS super account number ${memberId.value} so that I can receive superannuation advice about my investments,
salary sacrifice, lump sum contributions and personal insurance for 12 months starting from ${currentExpirationDate.value}, ending on ${newExpirationDate.value}.`
})

const paragraphText = computed(() => {
  return [
    `Otivo will send you an opt-in reminder 30 days before the end date so that you can decide whether to continue to receive personal advice from Otivo. Details on the fees payable will be included in the opt-in reminder.`,
    `Once you've read the privacy collection statement and agree to proceed, you'll receive an instant email providing guidance and access to your personal advice portal.`,
  ]
})
</script>
