<template>
  <div>
    <h4 class="text-blue-1 font-bold text-22px">Please confirm before proceeding</h4>
    <div class="paragraph-2 mt-[20px]">
      <p>
        As some of your super is invested in a Term Deposit, we recommend that you keep it until the
        maturity date is reached to avoid break fees and loss of investment earnings. Given this,
        our advice will exclude the Term Deposit and focus on identifying a suitable investment
        option for the remaining balance of your super.
      </p>
      <div class="flex gap-3 my-[40px]">
        <SmallCheckbox
          class="check-box"
          :checked="checked"
          name="Guidance Term Deposits Consent"
          data-test="guidanceTermDepositsConsent"
          @click="checked = !checked"
          @keyup.enter="checked = !checked"
          :tabindex="1"
          label="I understand and would like advice on how to invest the rest of my super." />
      </div>
    </div>
    <div class="w-100% md:w-fit flex flex-col gap-[16px] mt-[40px]">
      <OtivoButton
        size="large"
        @click="() => onProceed(checked)"
        colour="blue"
        data-test="proceedButton"
        :loading="loading"
        :disabled="!checked">
        Proceed
      </OtivoButton>
      <OtivoButton
        size="large"
        @click="() => onProceed(false)"
        colour="white"
        data-test="doNotProceedButton"
        :loading="loading">
        Do not proceed
      </OtivoButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import OtivoButton from '@/components/OtivoButton.vue'
import SmallCheckbox from '@/components/BasicInputs/CustomCheckBox/SmallCheckbox.vue'

defineProps<{
  onProceed: (consented: boolean) => void
  loading: boolean
}>()
const checked = ref(false)
</script>
