<template>
  <basic-input class="gap-3">
    <template #input-label>
      <input-label class="set-w-48" label="What's your message about?" />
    </template>
    <template #input-box>
      <div class="md:grid grid-cols-3 gap-4 my-2">
        <div v-for="(messageType, index) in messageTypes">
          <small-checkbox
            :checked="messageType.selected"
            :name="messageType.name + ' checkbox'"
            :label="messageType.name"
            @click="
              (value) => {
                this.$emit('click', value, index)
              }
            " />
        </div>
      </div>
    </template>
    <template #input-errors>
      <input-error v-if="this.errors" class="text-center" :error="this.errors" />
    </template>
  </basic-input>
</template>

<script>
import InputError from '@/components/BasicInputs/InputError.vue'
import InputLabel from '@/components/BasicInputs/InputLabel.vue'
import SmallCheckbox from '@/components/BasicInputs/CustomCheckBox/SmallCheckbox.vue'
import BasicInput from '@/components/BasicInputs/BasicInput.vue'
export default {
  name: 'ContactMessageTypes',
  components: { BasicInput, InputLabel, InputError, SmallCheckbox },
  props: {
    messageTypes: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
    },
  },
}
</script>

<style scoped></style>
