<template>
  <div id="ret-calc-ud">
    <form @submit.prevent="$emit('click')">
      <loader v-if="loading" />
      <div v-else class="text-left md:w-900px m-auto">
        <h5 class="mh5 md:h5 mb-8 text-left">Super details</h5>
        <div class="button-2 flex gap-6 my-4 items-center">
          <div class="set-w-28 md:set-w-58" />
          <div class="w-full">YOUR DETAILS</div>
          <div class="w-full flex gap-3 items-center">
            <input-check-box
              id="enable-partner-checkbox"
              data-test="showPartnerDetails"
              :checked="enablePartnerForm"
              @click="togglePartnerForm"
              name="enable partner check" />
            <span>PARTNER DETAILS</span>
          </div>
        </div>
        <div class="flex gap-6 my-6">
          <div class="flex gap-1 pt-4 set-w-28 md:set-w-58">
            <input-label :label="getRetCalcLabelContent.ret_calc_age?.label" class="paragraph-2" />
            <info-circle
              css-class="cfs-tooltip"
              fill="cfs-blue"
              :message="getRetCalcLabelContent.ret_calc_age?.tooltip_content" />
          </div>
          <input-number
            name="Retirement Calculator Age"
            place-holder="Age"
            data-test="userCurrentAge"
            :value="userData.age"
            @input="updateUserData($event, 'age')"
            :error="userErrors.age"
            :min="18"
            :max="87"
            class="w-full"
            @blur="setPreservationAge()" />
          <input-number
            name="Retirement Calculator Partner Age"
            place-holder="Age"
            data-test="partnerCurrentAge"
            :value="partnerData.age"
            @input="updatePartnerData($event, 'age')"
            :error="partnerErrors.age"
            :min="18"
            :max="87"
            v-if="enablePartnerForm"
            :disabled="!enablePartnerForm"
            class="w-full"
            @blur="setPreservationAge(true)" />
        </div>
        <div class="flex gap-6 my-6">
          <div class="flex gap-1 pt-4 set-w-28 md:set-w-58">
            <input-label
              :label="getRetCalcLabelContent.ret_calc_income?.label"
              class="paragraph-2" />
            <info-circle
              css-class="cfs-tooltip"
              fill="cfs-blue"
              :message="getRetCalcLabelContent.ret_calc_income?.tooltip_content" />
          </div>
          <div class="w-full flex flex-col md:flex-row gap-3">
            <input-currency
              :error="userErrors.income"
              :value="userData.income"
              class="w-full"
              name="Retirement Calculator Income"
              data-test="userIncome"
              @blur="getNetSalary()"
              @input="updateUserData($event, 'income')" />
            <input-select-box
              :options="incomePeriods"
              :select-input="incomeFrequencySelectInput(userData.income_time_period)"
              class="w-full"
              name="User Income Period"
              data-test="userIncomeFrequency"
              @update-select-box="updateUserData($event, 'income_time_period')" />
          </div>
          <div class="w-full flex flex-col md:flex-row gap-3" v-if="enablePartnerForm">
            <input-currency
              :disabled="!enablePartnerForm"
              :error="partnerErrors.income"
              :value="partnerData.income"
              class="w-full"
              name="Retirement Calculator Income"
              data-test="partnerIncome"
              @blur="getNetSalary(true)"
              @input="updatePartnerData($event, 'income')" />
            <input-select-box
              name="User Income Period"
              class="w-full"
              data-test="partnerIncomeFrequency"
              :disabled="!enablePartnerForm"
              :select-input="incomeFrequencySelectInput(partnerData.income_time_period)"
              @update-select-box="updatePartnerData($event, 'income_time_period')"
              :options="incomePeriods" />
          </div>
        </div>
        <div class="flex gap-6 my-6">
          <div class="flex gap-1 pt-4 set-w-28 md:set-w-58">
            <input-label
              :label="getRetCalcLabelContent.ret_calc_super_balance?.label"
              class="paragraph-2" />
            <info-circle
              css-class="cfs-tooltip"
              fill="cfs-blue"
              :message="getRetCalcLabelContent.ret_calc_super_balance?.tooltip_content" />
          </div>
          <input-currency
            name="Retirement Calculator Super Balance"
            data-test="userSuperBalance"
            :value="userData.current_super_balance"
            @input="updateUserData($event, 'current_super_balance')"
            :error="userErrors.current_super_balance"
            class="w-full" />
          <input-currency
            name="Retirement Calculator Partner Super Balance"
            data-test="partnerSuperBalance"
            :value="partnerData.current_super_balance"
            @input="updatePartnerData($event, 'current_super_balance')"
            :error="partnerErrors.current_super_balance"
            class="w-full"
            v-if="enablePartnerForm"
            :disabled="!enablePartnerForm" />
        </div>
        <div class="flex gap-6 my-6">
          <div class="flex gap-1 pt-4 set-w-28 md:set-w-58">
            <input-label
              :label="getRetCalcLabelContent.ret_calc_contribution_rate?.label"
              class="paragraph-2" />
            <info-circle
              css-class="cfs-tooltip"
              fill="cfs-blue"
              :message="getRetCalcLabelContent.ret_calc_contribution_rate?.tooltip_content" />
          </div>
          <input-percentage
            name="Retirement Calculator Contribution rate"
            :place-holder="formatPercentage(getMinSuperContributionRate())"
            data-test="userContributionRate"
            :value="userData.contribution_rate"
            @input="updateEmployerContributionRate(false, $event)"
            :error="userErrors.contribution_rate"
            class="w-full"
            :info="
              userData.contribution_rate < getMinSuperContributionRate()
                ? `Zero applies when there’s no employer contributing to your super. If there is the employer rate is ${formatPercentage(
                    getMinSuperContributionRate(),
                  )}`
                : ''
            "
            :max="25" />
          <input-percentage
            name="Retirement Calculator Partner Contribution rate"
            data-test="partnerContributionRate"
            :place-holder="formatPercentage(getMinSuperContributionRate())"
            :value="partnerData.contribution_rate"
            @input="updateEmployerContributionRate(true, $event)"
            :error="partnerErrors.contribution_rate"
            class="w-full"
            v-if="enablePartnerForm"
            :disabled="!enablePartnerForm"
            :info="
              partnerData.contribution_rate < getMinSuperContributionRate()
                ? `Zero applies when there’s no employer contributing to your super. If there is the employer rate is ${formatPercentage(
                    getMinSuperContributionRate,
                  )}`
                : ''
            "
            :max="25" />
        </div>

        <div class="flex gap-6 my-6">
          <div class="set-w-28 md:set-w-58 pt-4 paragraph-2">
            <input-label :label="getRetCalcLabelContent.ret_calc_ret_income?.label" />
            <info-circle
              id="ret-income"
              css-class="cfs-tooltip"
              fill="cfs-blue"
              :message="getRetCalcLabelContent.ret_calc_ret_income?.tooltip_content"
              class="ml-1" />
          </div>
          <input-currency
            name="Retirement Calculator Retirement Income"
            data-test="targetRetirementIncome"
            place-holder="Normally 70% of your net income"
            :value="userData.desired_retirement_income"
            @input="updateUserData($event, 'desired_retirement_income')"
            :error="userErrors.desired_retirement_income"
            class="w-full" />
        </div>
        <div class="flex gap-6 mt-6">
          <div class="set-w-28 md:set-w-58 pt-4 paragraph-2">
            <input-label :label="getRetCalcLabelContent.ret_calc_ret_age?.label" />
            <info-circle
              css-class="cfs-tooltip"
              fill="cfs-blue"
              :message="getRetCalcLabelContent.ret_calc_ret_age?.tooltip_content"
              class="ml-1" />
          </div>
          <input-number
            name="Retirement Calculator Retirement Age"
            data-test="userRetirementAge"
            place-holder="Age"
            :value="userData.desired_retirement_age"
            :default-value="67"
            @input="updateUserData($event, 'desired_retirement_age')"
            :error="userErrors.desired_retirement_age"
            class="w-full"
            :min="18"
            :max="87" />
          <input-number
            name="Retirement Calculator Partner Retirement Age"
            data-test="partnerRetirementAge"
            place-holder="Age"
            :value="partnerData.desired_retirement_age"
            :default-value="67"
            @input="updatePartnerData($event, 'desired_retirement_age')"
            :error="partnerErrors.desired_retirement_age"
            class="w-full"
            v-if="enablePartnerForm"
            :disabled="!enablePartnerForm"
            :min="18"
            :max="87" />
        </div>
        <div class="flex gap-6 mb-6">
          <div class="set-w-28 md:set-w-58" />
          <input-label
            class="paragraph-2 ml-4 mt-2"
            v-if="userData.super_access_age !== null"
            :label="
              `You can access your super at ${userData.super_access_age}` +
              (enablePartnerForm
                ? `, your partner can access their super at ${partnerData.super_access_age} `
                : '')
            " />
        </div>
        <div class="flex gap-6 my-6">
          <div class="set-w-28 md:set-w-58 pt-4 paragraph-2">
            <input-label :label="getRetCalcLabelContent.ret_calc_own_home?.label" />
            <info-circle
              css-class="cfs-tooltip"
              fill="cfs-blue"
              :message="getRetCalcLabelContent.ret_calc_own_home?.tooltip_content"
              class="ml-1" />
          </div>
          <input-select-box
            name="Owns home"
            data-test="ownsHome"
            class="w-full"
            :error="userErrors.hasHome"
            @update-select-box="updateUserData($event, 'hasHome')"
            :select-input="
              userData.hasHome !== null
                ? {
                    label: userData.hasHome ? 'Yes' : 'No',
                    value: userData.hasHome,
                  }
                : { label: '-', value: null }
            "
            :reset-button="true"
            :options="[
              {
                label: '-',
                value: null,
                disabled: true,
              },
              {
                label: 'Yes',
                value: true,
                id: 'Yes',
              },
              {
                label: 'No',
                value: false,
                id: 'No',
              },
            ]" />
        </div>
      </div>
      <div class="flex justify-center m-auto my-12 items-center gap-4">
        <custom-button
          id="update-graph-data-button"
          data-test="nextBtn"
          type="submit"
          colour="cfs-button"
          class="rounded-circle p-5 w-44 button-2"
          :loading="graphLoading">
          {{ displaySummary ? 'Update' : 'Next' }}
        </custom-button>
      </div>
    </form>
  </div>
</template>

<script>
import InputCurrency from '@/components/BasicInputs/InputCurrency.vue'
import InputPercentage from '@/components/BasicInputs/InputPercentage.vue'
import InputNumber from '@/components/BasicInputs/InputNumber.vue'
import CustomButton from '@/components/CustomButton/CustomButton.vue'
import InputLabel from '@/components/BasicInputs/InputLabel.vue'
import { mapGetters, mapActions } from 'vuex'
import InputCheckBox from '@/components/BasicInputs/InputCheckBox.vue'
import InputSelectBox from '@/components/BasicInputs/InputSelectBox.vue'
import InfoCircle from '@/components/InfoCircle/InfoCircle.vue'
import Loader from '@/components/Loader/Loader.vue'
import { formatCurrency, formatPercentage } from '@/lib/helpers'
import RetCalcService from '@/services/RetCalcService'
import moment from 'moment'
import { getMinSuperContributionRate } from '@/lib/RetirementPlanHelper'

export default {
  name: 'RetirementCalculatorUserData',
  components: {
    InfoCircle,
    InputSelectBox,
    InputCheckBox,
    InputLabel,
    CustomButton,
    InputNumber,
    InputPercentage,
    InputCurrency,
    Loader,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      incomePeriods: [
        {
          label: 'Yearly',
          value: 'yearly',
        },
        {
          label: 'Monthly',
          value: 'monthly',
        },

        {
          label: 'Fortnightly',
          value: 'fortnightly',
        },
        {
          label: 'Weekly',
          value: 'weekly',
        },
      ],
    }
  },
  emits: ['loaded', 'click'],
  mounted() {
    this.getDisclaimer()
    this.$emit('loaded')
  },
  computed: {
    ...mapGetters('RetirementCalculator', [
      'userData',
      'partnerData',
      'enablePartnerForm',
      'graphLoading',
      'displaySummary',
      'disclaimer',
    ]),
    ...mapGetters('RetirementCalculatorErrors', ['userErrors', 'partnerErrors']),
    ...mapGetters(['getRetCalcLabelContent']), // WhitelabelDefaultValues Store - TODO: refactor namespace
  },
  methods: {
    getMinSuperContributionRate,
    ...mapActions('RetirementCalculator', [
      'getDisclaimer',
      'togglePartnerForm',
      'updateUserDataKeyPair',
      'updatePartnerDataKeyPair',
    ]),
    ...mapActions('RetirementAges', ['getPreservationAgeForBirthDate']),
    formatCurrency,
    formatPercentage,

    updateEmployerContributionRate(partner, value) {
      if (partner) {
        this.updatePartnerData(value, 'contribution_rate')
        this.getNetSalary(true)
      } else {
        this.updateUserData(value, 'contribution_rate')
        this.getNetSalary(false)
      }
    },

    updateUserData(value, field) {
      this.updateUserDataKeyPair({ field: field, value: value })
    },
    updatePartnerData(value, field) {
      this.updatePartnerDataKeyPair({ field: field, value: value })
    },
    getTotalIncome() {
      let salary = 0
      salary += this.userData.annual_net_income
      if (this.enablePartnerForm) salary += this.partnerData.annual_net_income
      return salary
    },
    getNetSalary(isPartner = false) {
      const objectVariable = isPartner ? 'partnerData' : 'userData'
      let income = this[objectVariable].income
      if (this[objectVariable].income_time_period === 'weekly') {
        income = this[objectVariable].income * 52
      } else if (this[objectVariable].income_time_period === 'fortnightly') {
        income = this[objectVariable].income * 26
      } else if (this[objectVariable].income_time_period === 'monthly') {
        income = this[objectVariable].income * 12
      }
      const payload = {
        gas: income,
        desRetInc: isPartner ? this.partnerData.contribution_rate : this.userData.contribution_rate,
        addCont: isPartner
          ? this.partnerData.before_tax_contribution
          : this.userData.before_tax_contribution,
        isPartner: isPartner,
      }
      RetCalcService.getNetIncome(payload)
        .then((res) => {
          isPartner
            ? this.updatePartnerData(res.data.netSalaryPerAnnum, 'annual_net_income')
            : this.updateUserData(res.data.netSalaryPerAnnum, 'annual_net_income')
          this.updateUserData(this.getSalaryPlaceHolder(), 'desired_retirement_income')
        })
        .finally(() => {
          const labelPayload = {
            desiredRetIncome: this.getSalaryPlaceHolder(),
            hasPartner: this.enablePartnerForm,
          }
          if (isPartner) {
            labelPayload.partnerGAS = payload.gas
            labelPayload.userGAS = this.userData.income
          } else {
            labelPayload.userGAS = payload.gas
            labelPayload.partnerGAS = this.partnerData.income
          }
          this.$store.dispatch('getLabelContent', {
            values: labelPayload,
            fields: ['ret_calc_ret_income'],
          })
        })
    },
    getSalaryPlaceHolder() {
      return this.getTotalIncome() > 0 ? (this.getTotalIncome() * 0.7).toFixed(0) : 50000
    },
    setPreservationAge(isPartner = false) {
      const date = new Date()
      date.setMonth(1)
      if (isPartner) {
        this.getPreservationAgeForBirthDate({
          birthDate: this.formatFullYear(date, this.partnerData.age),
          isUser: false,
        }).then((res) => {
          this.updatePartnerData(res, 'super_access_age')
        })
      } else {
        this.getPreservationAgeForBirthDate({
          birthDate: this.formatFullYear(date, this.userData.age),
          isUser: true,
        }).then((res) => {
          this.updateUserData(res, 'super_access_age')
        })
      }
    },
    formatFullYear(date, age) {
      return moment(date.setFullYear(date.getFullYear() - age)).format('YYYY-MM-DD')
    },
    incomeFrequencySelectInput(frequency) {
      return this.incomePeriods.find((option) => {
        return option.value === frequency
      })
    },
  },
}
</script>

<style scoped></style>
