<template>
  <div class="underline" v-if="authenticated" :class="getSOAClasses" @click="openSoaModal">
    Download Statement of Advice
  </div>
</template>

<script lang="ts" setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import { useAuth0 } from '@/lib/AuthenticatorPlugin'
const store = useStore()
const auth0 = useAuth0()

const authenticated = computed(() => auth0.isAuthenticated.value)
const checkIfUserBasicInfoFormFilled = computed(() => store.getters.checkIfUserBasicInfoFormFilled)

const getSOAClasses = computed(() => {
  if (checkIfUserBasicInfoFormFilled.value) {
    return {
      'text-black': true,
      'cursor-pointer': true
    }
  }
  return {
    'pointer-events-none': true,
    'text-grey': true,
    'cursor-not-allowed': true
  }
})

const openSoaModal = () => {
  store.dispatch('renderSoaModal', true)
}
</script>
