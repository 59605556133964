import ProfileService from '@/services/ProfileService'
import _ from 'lodash'
import { updateDependent, updateDependentObjectBeforePosting } from '@/lib/ProfileHelper'
import { calculateAge, capitalize } from '@/lib/helpers'

import moment from 'moment'

const state = () => ({
  userCreated: '',
  account_id: '',
  auth0_id: '',
  email: '',
  isSocial: false,
  firstName: '',
  lastName: '',
  isSubscribed: false,
  forceSubscription: false,
  picture: '',
  dob: '',
  phone: 0,
  onBoarded: false,
  whiteLabelAdmin: false,
  superAdmin: false,
  supplementary_info: {
    isUserBasicInfoFormTriggeredAndFilled: false,
    // user_info
    // user basic info form questions
    work_status: 'employed',
    living_state: '',
    postcode_id: '',
    living_situation: '',
    property_value: 0,
    gross_annual_salary: null, // before tax and including super
    monthly_net_salary: null, // after tax and super (net)
    annual_super_income: null,
    dependents: [
      {
        name: '',
        age: null,
        owner: 'single',
        cover_till_independence: 0,
        childcare_cost: null,
        primary_cost: null,
        secondary_cost: null
      }
    ],
    info: {
      gross_annual_salary: '',
      annual_super_income: ''
    }
  },
  // todo :: check if can populate from the api endpoint
  // included in supplementary questions
  have_additional_income: false,
  additional_income: [
    { type: 'cash', name: 'Investment income from cash', amount_pa: null },
    // { type: 'pension', name: 'Age pension', amount_pa: null },
    // { type: 'share', name: 'Dividends income from shares', amount_pa: null },
    { type: 'property', name: 'Rental income from property', amount_pa: null },
    { type: 'unlisted', name: 'Distributions from funds', amount_pa: null },
    { type: 'alternative', name: 'Alternative', amount_pa: null }
  ],
  share: { type: 'share', name: 'Dividends income from shares', amount_pa: null },
  net_worth: {
    net_wealth: 0,
    total_assets: 0,
    total_debt: 0
  },
  scoring: [],
  fitnessScoreAndNetWealthLoading: false,
  subscriptions: [],
  ownAFSL: null,
  isOtivo: null,
  // only for medibank
  mediBankDivision: '',
  company_name: '',
  whitelabel_name: '',

  // Zendesk
  zendesk_jwt: '',
  zendesk_jwt_widget: ''
})

const getters = {
  zendesk_jwt(state) {
    return state.zendesk_jwt
  },

  zendesk_jwt_widget(state) {
    return state.zendesk_jwt_widget
  },

  account_id(state) {
    return state.account_id
  },

  zendesk_user_email(state) {
    return {
      name: { value: state.firstName + ' ' + state.lastName, readOnly: true },
      email: { value: state.email, readOnly: true }
    }
  },

  zendesk_user_identify(state) {
    return {
      name: state.firstName + ' ' + state.lastName,
      email: state.email,
      organization: state.whitelabel_name
    }
  },

  auth0_id(state) {
    return state.auth0_id
  },

  email(state) {
    return state.email
  },

  getUserAge(state) {
    return calculateAge(new Date(state.dob))
  },

  userCreated(state) {
    return state.userCreated
  },

  firstName(state) {
    const name =
      state.firstName && state.firstName.length > 10
        ? `${state.firstName.slice(0, 10)}...`
        : state.firstName
    return capitalize(name)
  },

  lastName(state) {
    return capitalize(state.lastName)
  },

  fullName(state, getters) {
    return `${getters.firstName} ${getters.lastName}`
  },

  isSubscribed(state) {
    return state.isSubscribed
  },

  forceSubscription(state) {
    return state.forceSubscription
  },

  picture(state) {
    return state.picture
  },

  profile(state) {
    return state
  },

  onBoarded(state) {
    return state.onBoarded
  },

  isUserRetired(state) {
    return state.supplementary_info.work_status === 'retired'
  },

  checkIfUserBasicInfoFormFilled(state) {
    return state.supplementary_info.isUserBasicInfoFormTriggeredAndFilled
  },

  superAdmin(state) {
    return state.superAdmin
  },

  whiteLabelAdmin(state) {
    return state.whiteLabelAdmin
  },

  renderDependentComponents(state) {
    return (
      state.supplementary_info.dependents.filter((el) => el.name !== '' && el.age !== null).length >
      0
    )
  },

  isSocialProfile(state) {
    return state.isSocial
  },

  hasAdditionalIncome(state) {
    return state.have_additional_income
  }
}

const mutations = {
  SET_WHITELABEL_NAME: function (state, name) {
    state.whitelabel_name = name
  },

  SET_USER_BASIC_INFO: function (state, profile) {
    if (profile.profile) {
      state.account_id = profile.profile.account_id
    }

    state.auth0_id = profile.user_id
    state.userCreated = profile.created_at
    state.email = profile.email
    state.isSocial = profile.identities.filter((el) => el.isSocial).length > 0
    state.firstName = profile.given_name ? profile.given_name : ''
    state.lastName = profile.family_name ? profile.family_name : ''
    state.isSubscribed = !profile.subscription_expired

    if (profile.user_metadata) {
      const metaData = profile.user_metadata
      state.dob = metaData.dob ? moment(metaData.dob).format('YYYY-MM-DD') : ''
      state.phone = metaData.phone ? Number(metaData.phone) : 0

      if (metaData.first_name) {
        state.firstName = metaData.first_name
      }

      if (metaData.last_name) {
        state.lastName = metaData.last_name
      }
    }

    state.picture = profile.picture
    state.onBoarded = profile.onboarded

    state.superAdmin = profile.superAdmin
    state.whiteLabelAdmin = profile.whitelabel_admin
    state.zendesk_jwt = profile.zendesk_jwt
    state.zendesk_jwt_widget = profile.zendesk_jwt_widget
  },

  SET_USER_IS_SUBSCRIBED(state) {
    state.isSubscribed = true
  },

  RESET_PROFILE(state) {
    state.email = null
    state.firstName = null
    state.lastName = null
    state.picture = null
  },

  SET_PROFILE_FIRST_NAME(state, value) {
    state.firstName = value
  },

  SET_PROFILE_LAST_NAME(state, value) {
    state.lastName = value
  },

  SET_PROFILE_EMAIL(state, value) {
    state.email = value
  },

  SET_USER_FINANCIAL_SCORE(state, value) {
    state.scoring = value
  },

  SET_USER_NET_WORTH(state, value) {
    state.net_worth = value
  },

  SET_ADDITIONAL_INCOMES_FROM_API(state, incomes) {
    const expenseNames = {
      cash: 'Investment income from cash',
      pension: 'Age pension',
      government: 'Government pension',
      share: 'Dividends income from shares',
      property: 'Rental income from property',
      unlisted: 'Distributions from funds',
      alternative: 'Alternative'
    }
    let haveAdditionalIncome = false
    state.additional_income = incomes.map((income) => {
      income.name = expenseNames[income.type]
      if (income.amount_pa && income.amount_pa > 0) {
        haveAdditionalIncome = true
      }
      return income
    })
    state.have_additional_income = haveAdditionalIncome
  },

  UPDATE_ADDITIONAL_INCOME_BALANCE(state, { index, balance }) {
    state.additional_income[index].amount_pa = balance
  },

  SET_PROFILE_EMPLOYMENT_SITUATION(state, value) {
    state.supplementary_info.work_status = value
  },

  SET_PROFILE_LIVING_STATE(state, value) {
    state.supplementary_info.living_state = value
  },

  SET_PROFILE_POSTCODE(state, value) {
    state.supplementary_info.postcode = value
  },

  SET_PROFILE_POSTCODE_ID(state, value) {
    state.supplementary_info.postcode_id = value
  },

  SET_PROFILE_LIVING_SITUATION_TYPE(state, value) {
    state.supplementary_info.living_situation = value
  },

  SET_PROFILE_PROPERTY_VALUE(state, value) {
    state.supplementary_info.property_value = value
  },

  SET_PROFILE_ANNUAL_SALARY(state, value) {
    state.supplementary_info.gross_annual_salary = value
  },

  SET_PROFILE_MONTHLY_SALARY(state, value) {
    state.supplementary_info.monthly_net_salary = value
  },

  SET_PROFILE_ANNUAL_SUPER(state, value) {
    state.supplementary_info.annual_super_income = value
  },

  SET_PROFILE_ADDITIONAL_INCOME(state, value) {
    state.have_additional_income = value
  },

  SET_PROFILE_HAS_SHARES(state, value) {
    state.have_shares = value
  },

  SET_SUPPLEMENTARY_INFO_ERROR(state, { property, error }) {
    state.supplementary_info.error[property] = error
  },

  UPDATE_USER_PHONE_NUMBER(state, value) {
    state.phone = value
  },

  UPDATE_USER_DOB(state, value) {
    state.dob = value
  },

  PUSH_NEW_DEPENDENT(state, doesUserHavePartner) {
    state.supplementary_info.dependents.push({
      name: '',
      age: null,
      owner: doesUserHavePartner ? 'shared' : 'single',
      cover_till_independence: 0,
      childcare_cost: null,
      primary_cost: null,
      secondary_cost: null
    })
  },

  REMOVE_DEPENDENT(state, index) {
    state.supplementary_info.dependents.splice(index, 1)
  },

  SET_DEPENDENT_AGE(state, { value, index }) {
    state.supplementary_info.dependents[index].age = value
  },

  SET_CARE_TAKER_OF_DEPENDENT(state, { value, index }) {
    state.supplementary_info.dependents[index].owner = value
  },

  SET_EXPENSES_OF_DEPENDENT(state, { value, index }) {
    state.supplementary_info.dependents[index].cover_till_independence = value
  },

  SET_DEPENDENT_NAME(state, { value, index }) {
    state.supplementary_info.dependents[index].name = value
  },

  SET_DEPENDENT_PRIMARY(state, { value, index }) {
    state.supplementary_info.dependents[index].primary_cost = value
  },

  SET_DEPENDENT_SECONDARY(state, { value, index }) {
    state.supplementary_info.dependents[index].secondary_cost = value
  },

  SET_DEPENDENT_CHILDCARE(state, { value, index }) {
    state.supplementary_info.dependents[index].childcare_cost = value
  },

  SET_IS_SUPPLEMENTARY_FORM_FILLED(state, value) {
    state.supplementary_info.isUserBasicInfoFormTriggeredAndFilled = value
  },

  SET_ALL_DEPENDENTS(state, dependents) {
    state.supplementary_info.dependents = dependents.map(updateDependent)
    // empty dependent
    if (state.supplementary_info.dependents.length === 0) {
      state.supplementary_info.dependents.push({
        name: '',
        age: null,
        owner: 'single',
        cover_till_independence: 0,
        childcare_cost: null,
        primary_cost: null,
        secondary_cost: null
      })
    }
  },

  RESET_DEPENDENTS(state) {
    state.supplementary_info.dependents = [
      {
        name: '',
        age: null,
        owner: 'single',
        cover_till_independence: 0,
        childcare_cost: null,
        primary_cost: null,
        secondary_cost: null
      }
    ]
  },

  SET_FITNESS_SCORE_AND_NET_WEALTH_LOADING(state, value) {
    state.fitnessScoreAndNetWealthLoading = value
  },

  SET_USER_SUBSCRIPTIONS(state, values) {
    state.subscriptions = values
  },

  UPDATE_USER_EMAIL_SUBSCRIPTION(state, { id, value }) {
    state.subscriptions = state.subscriptions.map((el) => {
      if (el.id === id) {
        el.status = value
      }
      return el
    })
  },

  SET_PROFILE_MEDI_BANK_DIVISION_IN_PROFILE(state, value) {
    state.mediBankDivision = value
  },

  SET_PROFILE_COMPANY_NAME(state, value) {
    state.company_name = value
  },

  SET_FORCE_SUBSCRIPTION(state, value) {
    state.forceSubscription = value
  },

  SET_IS_OTIVO(state, value) {
    state.isOtivo = value
  },

  SET_HAS_OWN_AFSL(state, value) {
    state.ownAFSL = value
  }
}

const actions = {
  resetProfile({ commit }) {
    commit('RESET_PROFILE')
  },

  setProfileFirstName({ commit }, value) {
    commit('SET_PROFILE_FIRST_NAME', value)
  },

  setProfileLastName({ commit }, value) {
    commit('SET_PROFILE_LAST_NAME', value)
  },

  setProfileEmail({ commit }, value) {
    commit('SET_PROFILE_EMAIL', value)
  },

  setUserBasicInfo({ commit, rootGetters }, payload) {
    commit('SET_USER_BASIC_INFO', payload)
    const wlObj = rootGetters.getCurrentWhitelabelObject
    commit('SET_WHITELABEL_NAME', wlObj.name)
    if (payload.whitelabel) {
      commit('SET_IS_OTIVO', payload.whitelabel.is_otivo)
      commit('SET_HAS_OWN_AFSL', payload.whitelabel.own_afsl)
    }
  },

  async setIsSubscribed({ commit }) {
    commit('SET_USER_IS_SUBSCRIBED')
  },

  setForceSubscription({ commit }, value) {
    commit('SET_FORCE_SUBSCRIPTION', value)
  },

  setFitnessScoreAndNetWealthLoading({ commit }, value) {
    commit('SET_FITNESS_SCORE_AND_NET_WEALTH_LOADING', value)
  },

  fetchFinancialScoreDataAndNetWealth({ dispatch }) {
    dispatch('setFitnessScoreAndNetWealthLoading', true)
    ProfileService.getFitnessScoreAndNetWealth()
      .then((res) => {
        dispatch('setUserFinancialScoreData', res.data.fitness_score)
        dispatch('setUserNetWorth', res.data.net_position)
        dispatch('setFitnessScoreAndNetWealthLoading', false)
      })
      .catch((err) => {
        dispatch('setFitnessScoreAndNetWealthLoading', false)
        throw err
      })
  },

  setUserFinancialScoreData({ commit }, score) {
    commit('SET_USER_FINANCIAL_SCORE', score)
  },

  setUserNetWorth({ commit }, netWorth) {
    commit('SET_USER_NET_WORTH', netWorth)
  },

  checkIfSupplementaryFormTriggeredAndFilled({ commit, getters, rootGetters }) {
    // flag is used for supp form
    // there are two supp forms first normal without bank details, second is with bank details
    // monthlyExpenses condition added as it's the last field input
    if (
      getters.profile.supplementary_info.living_state !== null &&
      getters.profile.supplementary_info.gross_annual_salary !== null &&
      rootGetters.totalMonthlyExpense !== null
    ) {
      commit('SET_IS_SUPPLEMENTARY_FORM_FILLED', true)
    } else {
      commit('SET_IS_SUPPLEMENTARY_FORM_FILLED', false)
    }

    // basiq first supp form is filled, render second supp form i.e. income and expenses
    if (
      getters.profile.supplementary_info.postcode_id !== '' &&
      getters.profile.supplementary_info.monthly_net_salary === null
    ) {
      commit('SET_BASIQ_RENDER_INCOME_EXPENSES_MODAL', true)
    }
  },

  getProfileSupplementaryInfo({ dispatch }) {
    return ProfileService.getProfile().then((res) => {
      dispatch('setSupplementaryInfo', res.data.data.data)
    })
  },

  setSupplementaryInfo({ commit, dispatch, rootGetters }, info) {
    if (info.additional_income && info.additional_income.length > 0) {
      commit('SET_ADDITIONAL_INCOMES_FROM_API', info.additional_income)
    }

    if (info.gross_annual_salary >= 0) {
      dispatch('setProfileAnnualSalary', info.gross_annual_salary)
    }

    if (info.monthly_income && info.monthly_income >= 0) {
      dispatch('setProfileMonthlySalary', info.monthly_income)
    }

    if (info.living_situation) {
      dispatch('setProfileLivingSituationType', info.living_situation)
    }

    if (info.postcode) {
      dispatch('setProfilePostcode', info.postcode)
    }

    if (info.postcode_id) {
      dispatch('setProfilePostcodeId', info.postcode_id)
      dispatch('setOptimizeInsurancePostcode', { owner: 'single', value: info.postcode_id })
    }

    if (info.monthly_expenses && info.monthly_expenses >= 0) {
      dispatch('setTotalMonthlyExpense', info.monthly_expenses)
    }
    if (info.work_status) {
      dispatch('setProfileEmploymentSituation', info.work_status)
    }

    if (info.what_state_do_you_live_in !== undefined) {
      dispatch('setProfileLivingState', info.what_state_do_you_live_in)
    }

    if (info.value_of_home > 0) {
      dispatch('setProfilePropertyValue', info.value_of_home)
    }

    if (info.annual_income_from_super > 0) {
      dispatch('setProfileAnnualSuper', info.annual_income_from_super)
    }

    if (info.mediBankDivision) {
      dispatch('setProfileMedibankDivision', info.mediBankDivision)
    }

    if (info.company_name) {
      dispatch('setProfileCompanyName', info.company_name)
    }

    dispatch('checkIfSupplementaryFormTriggeredAndFilled')
  },

  putProfileSupplementaryInfo({ commit, dispatch, getters, rootGetters }) {
    const supplementaryInfo = _.cloneDeep(getters.profile.supplementary_info)
    supplementaryInfo.monthly_expenses = rootGetters.totalMonthlyExpense
    supplementaryInfo.dob = moment(getters.profile.dob).format('YYYY-MM-DD')
    if (supplementaryInfo.property_value && supplementaryInfo.property_value > 0) {
      supplementaryInfo.value_of_home = supplementaryInfo.property_value
    }
    if (supplementaryInfo.annual_super_income && supplementaryInfo.annual_super_income > 0) {
      supplementaryInfo.annual_income_from_super = supplementaryInfo.annual_super_income
    }
    supplementaryInfo.additional_income = _.cloneDeep(
      getters.profile.additional_income.filter((el) => el.amount_pa !== null)
    )
    supplementaryInfo.additional_income.share = getters.profile.share

    supplementaryInfo.have_additional_income = _.cloneDeep(getters.profile.have_additional_income)
    supplementaryInfo.have_shares = _.cloneDeep(getters.profile.have_shares)
    supplementaryInfo.what_state_do_you_live_in = supplementaryInfo.living_state
    supplementaryInfo.postcode_id = getters.profile.supplementary_info.postcode_id
    supplementaryInfo.dependents = supplementaryInfo.dependents
      .filter((el) => el.name !== '')
      .map(updateDependentObjectBeforePosting)
    supplementaryInfo.user_super_balance = _.find(rootGetters.supers, { owner: 'single' }).balance
    supplementaryInfo.user_contribution_rate = rootGetters.userContributionRate

    return ProfileService.putProfile(supplementaryInfo)
  },

  updateOtherIncomeBalance({ commit }, payload) {
    commit('UPDATE_ADDITIONAL_INCOME_BALANCE', payload)
  },

  setProfileEmploymentSituation({ commit, dispatch }, value) {
    if (value === 'retired') {
      dispatch('setProfileAnnualSalary', 0)
      dispatch('setProfileMonthlySalary', 0)
    }
    commit('SET_PROFILE_EMPLOYMENT_SITUATION', value)
  },

  setProfileLivingState({ commit }, value) {
    commit('SET_PROFILE_LIVING_STATE', value)
  },

  setProfilePostcode({ commit }, value) {
    commit('SET_PROFILE_POSTCODE', value)
  },

  setProfilePostcodeId({ commit }, value) {
    commit('SET_PROFILE_POSTCODE_ID', value)
  },

  setProfileLivingSituationType({ commit }, value) {
    commit('SET_PROFILE_LIVING_SITUATION_TYPE', value)
  },

  setProfilePropertyValue({ commit }, value) {
    commit('SET_PROFILE_PROPERTY_VALUE', value)
  },

  setProfileAnnualSalary({ commit, rootGetters, dispatch }, value) {
    commit('SET_PROFILE_ANNUAL_SALARY', value)
  },

  setProfileMonthlySalary({ commit }, value) {
    commit('SET_PROFILE_MONTHLY_SALARY', value)
  },

  setProfileAnnualSuper({ commit }, value) {
    commit('SET_PROFILE_ANNUAL_SUPER', value)
  },

  setProfileAdditionalIncome({ commit }, value) {
    commit('SET_PROFILE_ADDITIONAL_INCOME', value)
  },

  setProfileHasShares({ commit }, value) {
    commit('SET_PROFILE_HAS_SHARES', value)
  },

  updateUserPhoneNumber({ commit }, value) {
    commit('UPDATE_USER_PHONE_NUMBER', value)
  },

  updateUserDOB({ commit, dispatch, state }, value) {
    commit('UPDATE_USER_DOB', value)
    dispatch(
      'RetirementAges/getPreservationAgeForBirthDate',
      { birthDate: state.dob, isUser: true },
      { root: true }
    )
    dispatch(
      'RetirementAges/getPensionAgeForBirthDate',
      { birthDate: state.dob, isUser: true },
      { root: true }
    )
  },

  setDependentAge({ commit }, payload) {
    commit('SET_DEPENDENT_AGE', payload)
  },

  updateCaretakerOfDependent({ commit }, payload) {
    commit('SET_CARE_TAKER_OF_DEPENDENT', payload)
  },

  updateExpensesOfDependent({ commit }, payload) {
    commit('SET_EXPENSES_OF_DEPENDENT', payload)
  },

  setDependentName({ commit }, payload) {
    commit('SET_DEPENDENT_NAME', payload)
  },

  setDependentPrimary({ commit }, payload) {
    commit('SET_DEPENDENT_PRIMARY', payload)
  },

  setDependentSecondary({ commit }, payload) {
    commit('SET_DEPENDENT_SECONDARY', payload)
  },

  setDependentChildcare({ commit }, payload) {
    commit('SET_DEPENDENT_CHILDCARE', payload)
  },

  setAllDependents({ commit }, dependents) {
    commit('SET_ALL_DEPENDENTS', dependents)
  },

  resetDependents({ commit }) {
    commit('RESET_DEPENDENTS')
  },

  calculateUserMonthlySalary({ getters, rootGetters, dispatch }) {
    return ProfileService.getMonthlySalaryFromGrossAnnualSalary({
      grossAnnualSalary: getters.profile.supplementary_info.gross_annual_salary,
      superRate: rootGetters.retirement.userContributionRate,
      additionalIncome: rootGetters.retirement.userAdditionalContribution
    }).then((res) => {
      dispatch('setProfileMonthlySalary', res.data.netSalaryPerMonth)
    })
  },

  setUserSubscriptions({ commit }, payload) {
    commit('SET_USER_SUBSCRIPTIONS', payload)
  },

  updateUserEmailSubscription({ commit }, payload) {
    commit('UPDATE_USER_EMAIL_SUBSCRIPTION', payload)
  },

  setProfileMedibankDivision({ commit }, value) {
    commit('SET_PROFILE_MEDI_BANK_DIVISION_IN_PROFILE', value)
  },

  setProfileCompanyName({ commit }, value) {
    commit('SET_PROFILE_COMPANY_NAME', value)
  },

  deleteDependent({ commit }, payload) {
    commit('REMOVE_DEPENDENT', payload.index)
    if (payload.dependent.id) {
      ProfileService.removeDependant(parseInt(payload.dependent.id)).then((res) => res)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
