import SignUpService from '@/services/SignUpService'

const state = () => ({
  accountId: null,
  name: {
    error: ''
  },
  credit_card: {
    pay_off: '',
    credit_bal: null
  },
  expense: {
    three_months: '',
    expense_bal: null
  },
  living_situation: {
    situation_type: '',
    house_value: null,
    mortgage_bal: null
  },
  super: {
    bal: null,
    error: ''
  },

  companyName: null,

  inProgress: false,
  on_board_action: {
    message: '',
    heading: ''
  },
  free_trial: false
})

// only write dynamic getter
const getters = {
  getSignUpAnswers(state) {
    return state
  },
  isSignUpInProgress(state) {
    return state.inProgress
  },
  freeTrial(state) {
    return state.free_trial
  }
}

const mutations = {
  SET_SIGN_UP_QUESTION_ANSWER(state, { keys, value }) {
    const tempAnswer = Object.assign({}, state)
    // update value of the answer object desired key
    keys.reduce((prev, next, index, array) => {
      if (index + 1 === array.length) {
        prev[next] = value
      }
      return prev[next]
    }, tempAnswer)
    state = tempAnswer
  },
  SET_SIGN_UP_IN_PROGRESS(state, value) {
    state.inProgress = value
  },
  UPDATE_LIVING_SITUATION_TYPE(state, value) {
    state.living_situation.situation_type = value
  },
  SET_SIGN_UP_COMPANY_NAME(state, value) {
    state.companyName = value
  },
  SET_FREE_TRIAL(state, value) {
    state.free_trial = value
  }
}

const actions = {
  setSignUpInProgress({ commit, dispatch }, value) {
    commit('SET_SIGN_UP_IN_PROGRESS', value)
    dispatch('setShowNavBar', !value)
    dispatch('renderHomepageButtons', !value)
  },
  setSignUpQuestionsAnswers({ commit }, payload) {
    commit('SET_SIGN_UP_QUESTIONS_ANSWERS', payload)
  },
  // todo :: refactor
  setSignUpQuestionAnswer({ commit }, payload) {
    commit('SET_SIGN_UP_QUESTION_ANSWER', payload)
  },
  setSignUpCompanyName({ commit }, value) {
    commit('SET_SIGN_UP_COMPANY_NAME', value)
  },
  updateLivingSituationType({ commit }, value) {
    commit('UPDATE_LIVING_SITUATION_TYPE', value)
  },
  async postUserSignUpData({ dispatch, rootGetters, state }, answers) {
    if (!answers) answers = state
    // if (JSON.parse(window.localStorage.getItem('signUpDataObject'))) {
    //   answers = JSON.parse(window.localStorage.getItem('signUpDataObject'))
    // }

    const payload = {
      cc_paid_off: answers.credit_card.pay_off,
      cc_balance: answers.credit_card.credit_bal === null ? 0 : answers.credit_card.credit_bal,
      three_months_savings: answers.expense.three_months === 'yes',
      savings_balance: answers.expense.expense_bal === null ? 0 : answers.expense.expense_bal,
      living_situation: answers.living_situation.situation_type,
      value_of_home:
        answers.living_situation.house_value === null ? 0 : answers.living_situation.house_value,
      mortgage_balance:
        answers.living_situation.mortgage_bal === null ? 0 : answers.living_situation.mortgage_bal,
      super_balance: answers.super.bal === null ? 0 : answers.super.bal,
      free_trial: state.free_trial
    }

    if (
      rootGetters.isItSurmount ||
      rootGetters.isItStanfordbrown ||
      rootGetters.isItCertefinancialwellbeing
    ) {
      payload.company_name = answers.companyName
    }

    const onBoardData = {}
    Object.assign(onBoardData, payload)
    onBoardData.cc_paid_off = payload.cc_paid_off ? 1 : 0
    onBoardData.three_months_savings = payload.three_months_savings ? 1 : 0
    onBoardData.free_trial = payload.free_trial
    dispatch('setUserOnBoardData', onBoardData)

    const response = await SignUpService.postUserSignUpData(payload)
    if (response.message === undefined && response.data.message === 'Onboard created.') {
      const newData = response.data.data
      // store account_id to localstorage
      if (localStorage.getItem('account_id')) localStorage.removeItem('account_id') // remove just incase of a failed previous attempt
      localStorage.setItem('account_id', newData.account_id)

      // set fitness graph data
      dispatch('setUserFinancialScoreData', newData.scoring)

      // set net wealth data
      dispatch('setUserNetWorth', newData.net_worth)

      // set on board action
      dispatch('setUserNextAction', newData.action)
      return { created: true }
    }
    return { created: false, error: response.message }
  }
  // async updateAuthIdForUser () {
  //   return SignUpService.updateAuthIdForUser(state.accountId)
  // },
}
export default {
  state,
  getters,
  mutations,
  actions
}
