<template>
  <div>
    <fitness-test-question-label :question-number="questionNumber" question="Your postcode" />

    <Autocomplete
      id="postcode"
      :get-items="getPostcodeData"
      placeholder="Enter your suburb"
      @selected="(postcode) => setPostcode(postcode)"
      type="postcode" />
  </div>
</template>

<script setup lang="ts">
import Autocomplete from '@/components/BasicInputs/Autocomplete.vue'
import FitnessTestQuestionLabel from '../QuestionsLabel/FitnessTestQuestionLabel.vue'
import { PostcodeType } from '@/types/GlobalTypes'
import { getPostcodeData } from '@/composables/getPostcodeData'
import { useStore } from 'vuex'

const store = useStore()
type Props = {
  questionNumber: string
  error: string
}

const setPostcode = (postcode: PostcodeType) => {
  store.dispatch('setFitnessTestState', postcode.state.abbreviation)
  store.dispatch('setFitnessTestPostcode', postcode.value)
}
</script>

<style scoped></style>
