<template>
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="5.57031" width="12" height="3" rx="1.5" :class="`fill-${fill}`" />
  </svg>
</template>

<script setup lang="ts">
withDefaults(defineProps<{ fill?: string }>(), {
  fill: 'grey-2'
})
</script>

<style scoped></style>
