<template>
  <basic-input class="flex gap-3" :class="{ 'md:flex-col': stackLabel }">
    <template #input-label>
      <div v-if="label !== ''" class="flex set-w-28 md:set-w-48 pt-4 paragraph-2">
        <input-label :for="name" :label="label" />
      </div>
    </template>
    <template #input-box>
      <input
        autocomplete="off"
        class="block w-full bg-transparent border border-grey-2 focus:border-otivo-blue ring-transparent ring-2 focus:ring-blue-4 rounded focus:outline-none shadow-none p-4"
        :class="classesForInput"
        :id="name"
        type="text"
        :placeholder="placeHolder"
        :value="getValue"
        @input="setEventValue($event.target.value)"
        :disabled="disabled" />
    </template>
    <template #input-errors>
      <input-error v-if="error" :error="error" />
    </template>
  </basic-input>
</template>

<script>
import InputError from './InputError'
import InputLabel from './InputLabel'
import BasicInput from '@/components/BasicInputs/BasicInput.vue'
export default {
  name: 'InputPhoneNumber',
  components: { BasicInput, InputLabel, InputError },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String],
      default: null,
    },
    placeHolder: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    inputClasses: {
      type: String,
      default: 'paragraph-2',
    },
    internationalCode: {
      type: Boolean,
      default: true,
    },
    stackLabel: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  computed: {
    getValue() {
      if (!isNaN(this.value) && this.value !== 0 && this.value !== null) {
        const numbers =
          (this.internationalCode || String(this.value).slice(0, 2) === '61' ? '+' : '') +
          String(this.value)
        const tempValue = []
        for (
          let i = 0;
          i < numbers.length && i < 12;
          i += this.checkForNumberSpacePlacement(numbers, i)
        ) {
          tempValue.push(numbers.substr(i, this.checkForNumberSpacePlacement(numbers, i)))
        }
        return tempValue.join(' ')
      } else if (!this.internationalCode) {
        return String(this.value)
      }
      return '+61 '
    },
    classesForInput() {
      if (this.error !== '') {
        return this.inputClasses.concat(' border-red-dark ').concat('border-2px')
      }
      return this.inputClasses
    },
  },
  methods: {
    checkForNumberSpacePlacement(numbers, i) {
      return !this.internationalCode && i === 0 && numbers[0] !== '+' ? 4 : 3
    },
    setEventValue(value) {
      // event.target.phone_number = true
      let eventVal = value
      if (this.internationalCode) eventVal = eventVal.replace('+', '')
      eventVal = eventVal.replace(' ', '')
      eventVal = eventVal.replace(/[^0-9+.-]+/g, '')
      eventVal = eventVal.substr(0, this.internationalCode ? 11 : 12)
      this.$emit('setPhoneNumber', eventVal)
      eventVal = Number(eventVal)
      if (this.value !== eventVal) {
        // if value is changed then emit the handle input event
        event.target.value = eventVal
        this.$emit('input', eventVal)
      } else {
        // reset the value of the text box to the original state
        // do not allow to input any characters
        event.target.value = this.getValue
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/themes/cfs/guidanceInputStyles.scss';
input:disabled {
  @apply border-grey-light rounded bg-grey-light ring-grey-light text-grey-2 border-0;
  -webkit-text-fill-color: var(--grey-2);
}
</style>
