<template>
  <div>
    <fitness-test-question-label
      :question-number="questionNumber"
      question="How worried are you about your current financial situation?" />

    <input-box-buttons
      @click="setCurrentFinancialSituation"
      :buttons="[
        { value: 'not_worried_at_all', name: 'Not worried at all' },
        { value: 'not_very_worried', name: 'Not very worried' },
        { value: 'quite_worried', name: 'Quite worried' },
        { value: 'very_worried', name: 'Very worried' },
      ]"
      :selected="currentFinancialSituation"
      name="living-situation"
      label="" />
  </div>
</template>

<script>
import FinancialSituationAnswer from './FinancialSituationAnswer'
import { mapState } from 'vuex'
import FitnessTestQuestionLabel from '../QuestionsLabel/FitnessTestQuestionLabel.vue'

import InputBoxButtons from '@/components/BasicInputs/InputBoxButtons.vue'
export default {
  name: 'CurrentFinancialSituation',
  components: { InputBoxButtons, FitnessTestQuestionLabel, FinancialSituationAnswer },
  computed: {
    ...mapState({
      currentFinancialSituation: (state) => state.fitnessTest.questions.currentFinancialSituation,
    }),
  },

  props: {
    questionNumber: {
      type: String,
      default: '',
    },
  },

  methods: {
    setCurrentFinancialSituation(value) {
      this.$store.dispatch('setFitnessTestCurrentFinancialSituation', value)
    },
  },
}
</script>

<style scoped></style>
