import { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { adminAuthGuard } from '@/router/clients/cfs/cfsRouteGuards.ts'
import { adviceRouteGuard } from '@/composables/adviceRouteGuard.ts'
import { adviceAuthGuard } from '@/router/routeGuards.ts'
import { IUserStore } from '@/store/pinia/UserStore.ts'
import { IAccountFlagsStore } from '@/store/pinia/AccountFlagsStore.ts'
import { ISessionStore } from '@/store/pinia/SessionStore.ts'
import superInvestments from '@/components/CFS/IntrafundInvestment/SuperInvestments.vue'

export default (
  userStore: IUserStore,
  sessionStore: ISessionStore,
  accountFlagsStore: IAccountFlagsStore,
) => {
  return [
    {
      path: '/dashboard',
      component: () => import('@/views/AdviceView.vue'),
      beforeEnter: (to, from, next) => adviceAuthGuard(to, from, next, userStore),
      children: [
        {
          path: '',
          name: 'clientDashboard',
          component: () => import('@/views/ClientDashboard.vue'),
        },
        {
          path: 'how-the-advice-works/:adviceTargetName',
          name: 'howTheAdviceWorksPage',
          component: () => import('@/components/CFS/HowTheAdviceWorksPage.vue'),
        },
        {
          path: 'how-advice-works',
          name: 'HowAdviceWorks',
          component: () => import('@/components/CFS/CFSClientConfirmationSteps/HowAdviceWorks.vue'),
        },
        {
          path: 'details',
          name: 'clientDetails',
          component: () => import('@/components/CFS/Guidance/UserProfile.vue'),
        },
        {
          path: 'financial-summary',
          name: 'clientFinancialSummary',
          component: () => import('@/components/FinancialSummary/FinancialSummary.vue'),
        },
        {
          path: 'member-summary',
          name: 'guidanceMemberSummary',
          component: () => import('@/components/CFS/Guidance/GuidanceMemberSummary.vue'),
          beforeEnter: async (to, from, next) => {
            if (
              sessionStore.getWhitelabelData.callcentre_portal ||
              sessionStore.getWhitelabelData.admin_portal
            ) {
              await adminAuthGuard(to, from, next, userStore, sessionStore)
            } else next(from)
          },
        },
        {
          path: 'insurance-expenses',
          name: 'insuranceExpenses',
          component: () => import('@/views/Otivo/Dashboard/Insurance/InsuranceExpenses.vue'),
          beforeEnter: async (
            to: RouteLocationNormalized,
            from: RouteLocationNormalized,
            next: NavigationGuardNext,
          ) => {
            if (typeof to.name === 'string' && userStore.getUser?.user_role === 'client') {
              const insuranceCompleted =
                !!accountFlagsStore.getFlag('personalInsurance').questions_completed
              if (!insuranceCompleted) {
                next({ name: 'clientDashboard' })
              }
            }
            next()
          },
        },
        {
          path: 'super-investments',
          name: 'superInvestments',
          component: () => superInvestments,
          beforeEnter: (to, from, next) =>
            adviceRouteGuard(to, from, next, userStore, accountFlagsStore, sessionStore),
        },
        {
          path: 'salary-sacrifice-contributions',
          name: 'salarySacrificeContributions',
          component: () =>
            import('@/components/CFS/SalarySacrifice/SalarySacrificeContribution.vue'),
          beforeEnter: (to, from, next) =>
            adviceRouteGuard(to, from, next, userStore, accountFlagsStore, sessionStore),
        },
        {
          path: 'lump-sum-concessional-contributions',
          name: 'lumpSumConcessionalContributions',
          component: () => import('@/components/CFS/LumpSumContributions.vue'),
          props: { type: 'concessional' },
          beforeEnter: (to, from, next) =>
            adviceRouteGuard(to, from, next, userStore, accountFlagsStore, sessionStore),
        },
        {
          path: 'lump-sum-non-concessional-contributions',
          name: 'lumpSumNonConcessionalContributions',
          component: () => import('@/components/CFS/LumpSumContributions.vue'),
          props: { type: 'non_concessional' },
          beforeEnter: (to, from, next) =>
            adviceRouteGuard(to, from, next, userStore, accountFlagsStore, sessionStore),
        },
        {
          path: 'personal-insurance',
          name: 'personalInsurance',
          component: () => import('@/views/Otivo/Dashboard/Insurance/InsuranceView.vue'),
          beforeEnter: (to, from, next) =>
            adviceRouteGuard(to, from, next, userStore, accountFlagsStore, sessionStore),
        },
        {
          path: 'concessional-contributions-guide',
          name: 'guidanceConcessionalContributionsGuide',
          component: () => import('@/components/Modals/HowToCheckConcessionalContributions.vue'),
        },
        {
          path: '/non-concessional-contributions-guide',
          name: 'clientNonConcessionalContributionsGuide',
          component: () => import('@/components/Modals/HowToCheckNonConcessionalContributions.vue'),
        },
      ],
    },
    {
      path: '/dashboard',
      redirect: '/dashboard',
    },
  ] as RouteRecordRaw[]
}
