import ProfileService from '@/services/ProfileService'

const state = () => ({
  monthlyCashFlow: {
    debt: 0,
    commitments: 0,
    remaining: 0,
    investmentIncome: 0,
    income: 0,
    expenses: 0,
    cashSavings: 0, // total actual savings of user without offset
    totalSavings: 0, // total actual savings of user with offset
    totalLiquidAssets: 0,
    savingsCommitments: 0,
    unallocatedSavings: 0, // todo :: track unallocated cash
    offsetBalance: null,
    savingsPlans: {},
    loading: true,
    dataSources: {},
    destitute: false
  }
})

const getters = {
  monthlyCashFlow(state) {
    return state.monthlyCashFlow
  },

  getOffsetBalance(state, rootGetters) {
    return rootGetters.liabilities.reduce((accumulator, liability) => {
      if (liability.offset_balance > 0) accumulator += liability.offset_balance
      return accumulator
    }, 0)
  },

  getIsDestitute(state) {
    return state.monthlyCashFlow.destitute
  },

  getCashSavings(state) {
    return state.monthlyCashFlow.cashSavings
  },

  getTotalCash(state, rootGetters) {
    return state.monthlyCashFlow.cashSavings + rootGetters.getOffsetBalance
  },

  getTotalLiquidAssets(state) {
    return state.monthlyCashFlow.totalLiquidAssets
  },

  getUnallocatedCash(state) {
    return state.monthlyCashFlow.unallocatedSavings
  },

  getDataSources(state) {
    return state.monthlyCashFlow.dataSources
  }
}

const mutations = {
  SET_MONTHLY_CASH_FLOW(state, monthlyCashFlow) {
    if (monthlyCashFlow) {
      state.monthlyCashFlow.commitments = monthlyCashFlow.total_commitments
        ? monthlyCashFlow.total_commitments
        : 0
      state.monthlyCashFlow.remaining = monthlyCashFlow.remaining_monthly_income
        ? monthlyCashFlow.remaining_monthly_income
        : 0
      state.monthlyCashFlow.expenses = monthlyCashFlow.expenses
      state.monthlyCashFlow.debt = monthlyCashFlow.debt
      state.monthlyCashFlow.cashSavings = monthlyCashFlow.savings_balance
      state.monthlyCashFlow.totalSavings = monthlyCashFlow.total_savings
      state.monthlyCashFlow.totalLiquidAssets = monthlyCashFlow.total_liquid_assets
      state.monthlyCashFlow.savingsCommitments = monthlyCashFlow.savings_commitments
      state.monthlyCashFlow.investmentCommitments = monthlyCashFlow.investment_commitments
      state.monthlyCashFlow.unallocatedSavings = monthlyCashFlow.unallocated_savings
      state.monthlyCashFlow.investmentIncome = monthlyCashFlow.investment_income
      state.monthlyCashFlow.savingsPlans = monthlyCashFlow.savings_plans.plans
      state.monthlyCashFlow.dataSources = monthlyCashFlow.dataSources
        ? monthlyCashFlow.dataSources
        : {}
      state.monthlyCashFlow.destitute = monthlyCashFlow.destitute
        ? monthlyCashFlow.destitute
        : false
      let income = 0
      income += monthlyCashFlow.user_monthly_income ? monthlyCashFlow.user_monthly_income : 0
      income += monthlyCashFlow.partner_monthly_income ? monthlyCashFlow.partner_monthly_income : 0
      state.monthlyCashFlow.income = income
    }
  },

  SET_MONTHLY_CASH_FLOW_LOADING(state, value) {
    state.monthlyCashFlow.loading = value
  },

  SET_PROFILE_OFFSET_BALANCE(state, value) {
    state.monthlyCashFlow.offsetBalance = value
  },

  SET_CASH_SAVINGS(state, value) {
    state.monthlyCashFlow.cashSavings = value
  },

  SET_UNALLOCATED_CASH(state, value) {
    state.monthlyCashFlow.unallocatedSavings = value
  }
}

const actions = {
  fetchMonthlyCashFlow({ dispatch, commit }) {
    commit('SET_MONTHLY_CASH_FLOW_LOADING', true)
    ProfileService.getCashFlow().then((res) => {
      dispatch('setMonthlyCashFlow', res.data.data)
      commit('SET_MONTHLY_CASH_FLOW_LOADING', false)
    })
    // .catch(() => dispatch('fetchMonthlyCashFlow'))
  },

  setMonthlyCashFlow({ commit, dispatch, getters, rootGetters }, monthlyCashFlow) {
    commit('SET_MONTHLY_CASH_FLOW', monthlyCashFlow)
    if (monthlyCashFlow.rdf_target)
      dispatch('setRainyDayRecommendedAmount', monthlyCashFlow.rdf_target)
    if (monthlyCashFlow.expenses) dispatch('setTotalMonthlyExpense', monthlyCashFlow.expenses)
  },

  setCashSavings({ commit, dispatch }, value) {
    commit('SET_CASH_SAVINGS', value)
    dispatch('adjustCurrentlySavedForSavingPlans')
  },

  adjustCurrentlySavedForSavingPlans({ commit, rootGetters, dispatch, getters }) {
    rootGetters.savings.map((el, index) => {
      // update rainy day balance on change of cash savings
    })
    dispatch('calculateUnallocatedCash')
  },

  calculateUnallocatedCash({ commit, rootGetters, getters }) {
    const totalExistingSavings = rootGetters.savings.reduce((accumulator, el) => {
      if (el.current_value > 0 && el.type !== 'rainy_day') accumulator += el.current_value
      return accumulator
    }, 0)
    const value = getters.getTotalCash - totalExistingSavings
    commit('SET_UNALLOCATED_CASH', value < 0 ? 0 : value)
  }
  // todo :: change with an api endpoint for respective plans
  // isAmountGreaterThanRemainingMonthlyCashFlow ({getters, rootGetters}, amount) {
  //   return rootGetters.checkIfUserBasicInfoFormFilled ? amount > getters.monthlyCashFlow.remaining : false
  // }
}

export default {
  state,
  getters,
  mutations,
  actions
}
