<template>
  <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.6875 23.1245C26.7698 23.1245 24.9844 19.562 24.9844 17.162C24.9826 16.8505 25.0363 16.5413 25.1428 16.2495L29.4084 4.71201C29.5838 4.23768 29.8958 3.82931 30.3029 3.54114C30.7099 3.25297 31.1929 3.09863 31.6875 3.09863C32.1821 3.09863 32.665 3.25297 33.0721 3.54114C33.4792 3.82931 33.7911 4.23768 33.9665 4.71201L38.2322 16.2495C38.3386 16.5413 38.3923 16.8505 38.3906 17.162C38.3906 19.562 36.6051 23.1245 31.6875 23.1245ZM29.9325 17.4245C30.0665 17.7433 30.4687 18.1245 31.6875 18.1245C32.9062 18.1245 33.3084 17.7433 33.4425 17.4245L31.6875 12.6745L29.9325 17.4245Z"
      :class="`fill-${fill2}`" />
    <path
      d="M7.31248 23.1245C2.39482 23.1245 0.609353 19.562 0.609353 17.162C0.607648 16.8505 0.661338 16.5413 0.767791 16.2495L5.03342 4.71201C5.20883 4.23768 5.52079 3.82931 5.92787 3.54114C6.33494 3.25297 6.81786 3.09863 7.31248 3.09863C7.8071 3.09863 8.29002 3.25297 8.69709 3.54114C9.10416 3.82931 9.41613 4.23768 9.59154 4.71201L13.8572 16.2495C13.9636 16.5413 14.0173 16.8505 14.0156 17.162C14.0156 19.562 12.2301 23.1245 7.31248 23.1245ZM5.55748 17.4245C5.69154 17.7433 6.09373 18.1245 7.31248 18.1245C8.53123 18.1245 8.93342 17.7433 9.06748 17.4245L7.31248 12.6745L5.55748 17.4245Z"
      :class="`fill-${fill2}`" />
    <path
      d="M36.5625 0H2.4375C1.79103 0 1.17105 0.263393 0.713928 0.732234C0.256808 1.20107 0 1.83696 0 2.5C0 3.16304 0.256808 3.79893 0.713928 4.26777C1.17105 4.73661 1.79103 5 2.4375 5H17.0625V27.5H11.5781C11.1767 27.4997 10.7815 27.6011 10.4275 27.7952C10.0735 27.9893 9.77168 28.27 9.54891 28.6125L4.67391 36.1125C4.42893 36.4891 4.28826 36.9268 4.2669 37.379C4.24554 37.8312 4.34429 38.2808 4.55263 38.68C4.76096 39.0791 5.07105 39.4127 5.44979 39.6452C5.82854 39.8777 6.26173 40.0003 6.70312 40H32.2969C32.7495 40 33.1933 39.8707 33.5783 39.6266C33.9634 39.3825 34.2746 39.0333 34.477 38.618C34.6795 38.2028 34.7652 37.7379 34.7245 37.2755C34.6839 36.8131 34.5185 36.3714 34.2469 36L28.7625 28.5C28.5355 28.1895 28.241 27.9375 27.9026 27.7639C27.5641 27.5904 27.1909 27.5 26.8125 27.5H21.9375V5H36.5625C37.209 5 37.829 4.73661 38.2861 4.26777C38.7432 3.79893 39 3.16304 39 2.5C39 1.83696 38.7432 1.20107 38.2861 0.732234C37.829 0.263393 37.209 0 36.5625 0ZM27.4219 35H11.2552L12.8822 32.5H25.5938L27.4219 35Z"
      :class="`fill-${fill1}`" />
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1?: string
  fill2?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red'
})
</script>

<style scoped></style>
