<template>
  <div>
    <fitness-test-question-label
      :question-number="questionNumber"
      question="My current living situation is" />
    <!--    <living-situation-answer-->
    <!--      :selected="livingSituation"-->
    <!--      @click="setLivingSituation"-->
    <!--    />-->
    <input-box-buttons
      @click="setLivingSituation"
      :buttons="[
        {
          name: 'I own outright',
          value: 'own',
        },
        {
          name: 'I am renting',
          value: 'rent',
        },
        {
          name: 'I have a mortgage',
          value: 'mortgage',
        },
      ]"
      :selected="livingSituation"
      name="living-situation"
      label="" />
  </div>
</template>

<script>
import LivingSituationAnswer from './LivingSituationAnswer.vue'
import { mapState } from 'vuex'
import FitnessTestQuestionLabel from '../QuestionsLabel/FitnessTestQuestionLabel.vue'
import InputBoxButtons from '@/components/BasicInputs/InputBoxButtons.vue'
export default {
  name: 'LivingSituationStatus',
  components: { InputBoxButtons, FitnessTestQuestionLabel, LivingSituationAnswer },

  computed: {
    ...mapState({
      livingSituation: (state) => state.fitnessTest.questions.livingSituation,
    }),
  },

  props: {
    questionNumber: {
      type: String,
      default: '',
    },
  },

  methods: {
    setLivingSituation(value) {
      this.$store.dispatch('setFitnessTestLivingSituation', value)
    },
  },
}
</script>

<style scoped></style>
