<template>
  <div class="relative min-h-[100vh] flex flex-col">
    <div class="bg-white w-full">
      <CallcentrePortalNavBar />
      <GuidanceUserBanner class="max-w-[1184px] md:px-[20px] m-auto flex py-8" />
    </div>
    <div class="md:py-20 flex-grow flex justify-center relative">
      <div class="md:flex gap-[40px] w-100% md:max-w-[1127px] justify-center">
        <SideMenuNavigation
          v-if="cfsClient && !disableMenu && guidanceClientConsent"
          class="hidden md:flex md:flex-col min-w-[200px] max-w-[200px]" />
        <slot />
        <!--        Hide router if slot is filled. I hate this, its temporary. My eyes-->
        <div v-if="!$slots.default" class="w-100%">
          <router-view></router-view>
        </div>
      </div>
      <!--      <div class="fixed bottom-[77px] max-h-100% max-w-100% md:bottom-0 right-0 z-90">-->
      <!--        <LLMChat-->
      <!--          v-if="userRole === 'callcentre' && clientLoggedIn && environment === 'development'"-->
      <!--          :is-closeable="true"-->
      <!--          :pre-loaded-questions="preLoadedQuestions"-->
      <!--          max-width="max-w-[460px]"-->
      <!--          @question="askQuestion"-->
      <!--          @reaction="(reaction) => handleReaction(reaction)">-->
      <!--          <template #header>-->
      <!--            <h5 class="text-white">Ask Otivo super</h5>-->
      <!--          </template>-->
      <!--        </LLMChat>-->
      <!--      </div>-->
    </div>
    <div
      :class="type === 'member' ? 'bg-midnight' : 'bg-grey-1'"
      class="text-mf1 py-[40px] md:text-f1 flex flex-col justify-center items-center space-y-[80px]">
      <CFSFooterNav />

      <div :class="type === 'member' ? 'text-blue-2' : 'text-white'" class="text-center">
        {{ whitelabelData.provider }} ABN {{ whitelabelData.abn }} AFSL
        {{
          whitelabelData.afsl === '485665'
            ? ' + Australian Credit Licence ' + whitelabelData.afsl
            : whitelabelData.afsl
        }}
        <div>
          <!--            Email: <a href="mailto:otivohelp@otivo.com">otivohelp@otivo.com</a>-->
          {{ whitelabelData.address }}
        </div>
      </div>
    </div>
    <TimeoutModal />
  </div>
</template>

<script lang="ts" setup>
import GuidanceUserBanner from '@/components/CFS/Guidance/PortalUserBanner.vue'
import Pusher from 'pusher-js'
import TimeoutModal from '@/components/AppModals/TimeoutModal.vue'
import getAskOtivoSessionId from '@/composables/getAskOtivoSessionId'
import { Reaction, REACTIONS, useChatBotStore } from '@/store/pinia/ChatBotStore'
import { computed, onMounted, ref } from 'vue'
import { isAuthenticated, userAuth0Id, userRole } from '@/lib/AuthenticatorPlugin'
import { useSessionStore } from '@/store/pinia/SessionStore'
import { useUserStore } from '@/store/pinia/UserStore'
import { useGuidanceClientStore } from '@/store/pinia/GuidanceClientStore'
import { useIntrafundAdviceStore } from '@/store/pinia/IntrafundAdviceStore'
import { useGuidanceSuperStore } from '@/store/pinia/GuidanceSuperStore'
import CFSFooterNav from '@/views/Clients/CFS/CFSFooterNav.vue'
import SideMenuNavigation from '@/components/NavBar/SideMenuNavigation.vue'
import getApiInstance from '@/services/Api.ts'
import useInactivityTimeout from '@/composables/inactivityTimeout.ts'
import { WhitelabelDataType } from '@/types/WhiteLabelTypes.ts'
import CallcentrePortalNavBar from '@/views/Clients/CFS/CallcentrePortalNavBar.vue'

interface Props {
  type?: string
  disableMenu?: boolean
}

withDefaults(defineProps<Props>(), { type: 'dark', disableMenu: false })

const clientStore = useGuidanceClientStore()
const intrafundAdviceStore = useIntrafundAdviceStore()
const guidanceSuperStore = useGuidanceSuperStore()
const userStore = useUserStore()
const cfsClient = userStore.cfsClient
const sessionStore = useSessionStore()
const inactivityTimeout = useInactivityTimeout(sessionStore) // this is fine
const whitelabelData = sessionStore.getWhitelabelData as WhitelabelDataType
const cfsAuthenticated = computed(() => {
  return isAuthenticated.value && sessionStore.getWhitelabelData.callcentre_portal === 1
})
const clientLoggedIn = computed(() => clientStore.clientLoggedIn)
const guidanceClientData = computed(() => clientStore.getGuidanceClientData)
const guidanceClientConsent = computed(() => clientStore.getGuidanceClientConsent)
const clientSuperObject = computed(() => guidanceSuperStore.getClientSuperObject)
const clientData = JSON.parse(window.localStorage.getItem('activeManagedUser'))

const environment = computed(() => import.meta.env.MODE)

const pusher = new Pusher(import.meta.env.VITE_PUSHER_CLIENT_KEY, {
  cluster: import.meta.env.VITE_PUSHER_CLUSTER,
})
const setupClientConsentWebsocket = (accountId: string) => {
  if (!guidanceClientConsent.value) {
    const channel = pusher.subscribe('flags_' + accountId)
    channel.bind('message', (res) => {
      intrafundAdviceStore.fetchTermDepositConsent()
      clientStore.setGuidanceClientConsent(res.consentFlags)
    })
  }
}
const setupAdviceRequestWebsocket = (accountId: string) => {
  // Just getting the current super because swap process fires the getClientSuper action anyway
  // And basing it off of accountId not super id makes it easier to set up
  if (clientSuperObject.value?.id) {
    const channel = pusher.subscribe('change_request_' + accountId)
    channel.bind('message', () => {
      guidanceSuperStore.getClientSuper(clientSuperObject.value.id)
    })
  }
}

onMounted(() => {
  /**
   * Need to implement the child routing to stop this calling so many times.
   * Slight performance hit but not too bad.
   *
   */
  const cfsClient = userRole.value === 'member' || userRole.value === 'client'
  if (cfsAuthenticated.value && (clientData || cfsClient)) {
    // On a refresh the client won't have set themselves in local storage so we need to check the userStore
    const auth0Id: string = clientData?.auth0Id ?? userAuth0Id.value
    if (auth0Id) {
      getApiInstance().setClientHeader(auth0Id)
      clientStore.updateGuidanceClientField({
        value: auth0Id,
        field: 'auth0Id',
      })
      clientStore.readClientData(auth0Id).then(() => {
        // Calling websockets here as we need the accountId and the pusher channel wasn't working in the store
        const accountId = guidanceClientData.value.accountId
        if (userRole.value === 'callcentre' && accountId) {
          setupClientConsentWebsocket(accountId)
          setupAdviceRequestWebsocket(accountId)
        }
      })
    } else console.error('no auth0id')
  }
  setTimer()
})
const setTimer = () => {
  userStore.cfsClient ? inactivityTimeout.start(20) : inactivityTimeout.start(120)
}

const chatBotStore = useChatBotStore()
const sessionId = ref(getAskOtivoSessionId())

const preLoadedQuestions = [
  'How can this product help me achieve my retirement goals?',
  'What are the fees and charges associated with this product?',
  'What are the investment options available within this product?',
]
const askQuestion = (question: string) => {
  if (question.length === 0) {
    return
  }

  chatBotStore.waitingForResponse = true

  const CFS_FIRSTCHOICE_WHOLESALE_PERSONAL_SUPER = 780 // TODO: Update to other PDS

  chatBotStore
    .askDocumentQuestion(CFS_FIRSTCHOICE_WHOLESALE_PERSONAL_SUPER, sessionId.value, question)
    .then(() => {
      chatBotStore.waitingForResponse = false
    })
    .catch((err: Error) => console.error(err))
}

const handleReaction = ({ existingReaction, newReaction, answerId }: Reaction) => {
  if (existingReaction === newReaction) {
    // Remove reaction if user clicks same reaction twice
    newReaction = REACTIONS.NEUTRAL
  }

  chatBotStore.updateDocumentReaction(answerId, newReaction)
}
</script>
