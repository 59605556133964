import { formatCurrency, getMonthNameByIndex } from './helpers'
import _ from 'lodash'
import globalConstants from '@/GlobalConstants/globalConstants'

const gc = globalConstants()

const updateSavingObject = (object) => {
  // to show processing
  object.processing = false

  if (typeof object.completed === 'number') {
    object.completed = object.completed === 1
  }

  if (object.source === undefined) {
    object.source = 'user'
  }

  object.error = getSavingsErrors()

  if (object.open === undefined) {
    object.open = false
  }

  return object
}

const updateSavingObjectBeforePosting = (object) => {
  object.source = 'user'
  if (object.number_of_months === null) object.number_of_months = 0
  if (object.current_value === null) object.current_value = 0
  return object
}

const createSavingObjectAfterSaving = (object) => {
  const tempObject = _.cloneDeep(object)
  return updateSavingObject({
    id: tempObject.id,
    name: tempObject.name,
    type: tempObject.type,
    owner: tempObject.owner,
    purchase_price: tempObject.purchase_price,
    current_value: tempObject.current_value,
    rate: tempObject.rate,
    shared: tempObject.shared === 1,
    number_of_months: tempObject.number_of_months,
    amount: tempObject.amount,
    completed: tempObject.completed === 1,
    priority: tempObject.priority,
    monthly_commitment: tempObject.monthly_commitment
  })
}

const projectSavingsMonthlyContribution = ({
  existingSavings,
  depositRequired,
  rate,
  numberOfMonths
}) => {
  // calculate monthly commitment value
  if (rate <= 0) {
    return Math.ceil(depositRequired / numberOfMonths)
  }

  const pv = existingSavings // PV= existing savings (starting value of the savings)
  const fv = depositRequired // FV= target amount
  const apr = rate / 100 // APR = Annual Percentage Rate
  const r = apr / 12 // r = Periodic Interest Rate = APR/number of interest periods per year
  const n = numberOfMonths // N = Total number of interest periods (interest periods per year * number of years)
  // P = Monthly Payment

  // FORMULA
  //  P = r / (((1 + r) ^ N) - 1) * (pv * ((1 + r) ^ N) - fv)
  const first = Math.pow(1 + r, n) - 1
  const second = pv * Math.pow(1 + r, n) - fv
  const monthlyInstallment = (r / first) * second * -1
  return monthlyInstallment < 0 ? 0 : Math.ceil(monthlyInstallment)
}

const projectSavingsMonthlyContributionWithFallBackValues = (savingsObject) => {
  let numberOfMonths = savingsObject.number_of_months > 240 ? 240 : savingsObject.number_of_months
  numberOfMonths =
    numberOfMonths <= 0 ? getAverageTermForSavings(savingsObject.type) : numberOfMonths

  const interestRate =
    savingsObject.rate <= 0 ? getAverageInterestRateSavings(savingsObject.type) : savingsObject.rate
  const existingSavings = savingsObject.current_value
  const depositRequired =
    savingsObject.amount <= 0
      ? getAverageDepositRequiredSavings(savingsObject.type)
      : savingsObject.deposit_required
  return savingsObject.monthly_commitment <= 0
    ? projectSavingsMonthlyContribution({
        existingSavings: existingSavings,
        depositRequired: depositRequired,
        rate: interestRate,
        numberOfMonths: numberOfMonths
      })
    : savingsObject.monthly_commitment
}

const getAverageInterestRateSavings = (savingsType) => {
  const savingsInterestRate = []
  savingsInterestRate[gc.PROPERTY_SAVINGS_PLAN] = 2
  savingsInterestRate[gc.RAINY_DAY_PLAN] = 2
  savingsInterestRate[gc.HOLIDAY_DAY_SAVINGS_PLAN] = 2
  savingsInterestRate[gc.RENOVATION_DAY_SAVINGS_PLAN] = 2
  savingsInterestRate[gc.KIDS_EDUCATION_DAY_SAVINGS_PLAN] = 2
  savingsInterestRate[gc.CAR_SAVINGS_PLAN] = 2
  savingsInterestRate[gc.WEDDING_SAVINGS_PLAN] = 2
  savingsInterestRate[gc.OTHER_SAVING_PLAN] = 2

  return savingsInterestRate[savingsType]
}

const getAverageTermForSavings = (savingsType) => {
  const savingsTerm = []
  savingsTerm[gc.PROPERTY_SAVINGS_PLAN] = 20
  savingsTerm[gc.RAINY_DAY_PLAN] = 12
  savingsTerm[gc.HOLIDAY_DAY_SAVINGS_PLAN] = 12
  savingsTerm[gc.RENOVATION_DAY_SAVINGS_PLAN] = 12
  savingsTerm[gc.KIDS_EDUCATION_DAY_SAVINGS_PLAN] = 12
  savingsTerm[gc.CAR_SAVINGS_PLAN] = 12
  savingsTerm[gc.WEDDING_SAVINGS_PLAN] = 12
  savingsTerm[gc.OTHER_SAVING_PLAN] = 12

  return savingsTerm[savingsType]
}

const getAverageExistingSavings = (savingsType) => {
  const existingSavings = []
  existingSavings[gc.PROPERTY_SAVINGS_PLAN] = 1000
  existingSavings[gc.RAINY_DAY_PLAN] = 3000
  existingSavings[gc.HOLIDAY_DAY_SAVINGS_PLAN] = 862
  existingSavings[gc.RENOVATION_DAY_SAVINGS_PLAN] = 862
  existingSavings[gc.KIDS_EDUCATION_DAY_SAVINGS_PLAN] = 862
  existingSavings[gc.CAR_SAVINGS_PLAN] = 862
  existingSavings[gc.WEDDING_SAVINGS_PLAN] = 862
  existingSavings[gc.OTHER_SAVING_PLAN] = 862

  return existingSavings[savingsType]
}

const getAverageDepositRequiredSavings = (savingsType) => {
  const savingsAverageDeposit = []
  savingsAverageDeposit[gc.PROPERTY_SAVINGS_PLAN] = 686200 * 0.2 // avg home value * 20%
  savingsAverageDeposit[gc.RAINY_DAY_PLAN] = 9000
  savingsAverageDeposit[gc.HOLIDAY_DAY_SAVINGS_PLAN] = 2000
  savingsAverageDeposit[gc.RENOVATION_DAY_SAVINGS_PLAN] = 2000
  savingsAverageDeposit[gc.KIDS_EDUCATION_DAY_SAVINGS_PLAN] = 2000
  savingsAverageDeposit[gc.CAR_SAVINGS_PLAN] = 2000
  savingsAverageDeposit[gc.WEDDING_SAVINGS_PLAN] = 2000
  savingsAverageDeposit[gc.OTHER_SAVING_PLAN] = 2000

  return savingsAverageDeposit[savingsType]
}

const getAllSavingsTypes = () => {
  return [
    { name: 'Property', value: gc.PROPERTY_SAVINGS_PLAN },
    { name: 'Rainy Day', value: gc.RAINY_DAY_PLAN },
    { name: 'Holiday', value: gc.HOLIDAY_DAY_SAVINGS_PLAN },
    { name: 'Renovation', value: gc.RENOVATION_DAY_SAVINGS_PLAN },
    { name: 'Kids Education', value: gc.KIDS_EDUCATION_DAY_SAVINGS_PLAN },
    { name: 'Car', value: gc.CAR_SAVINGS_PLAN },
    { name: 'Wedding', value: gc.WEDDING_SAVINGS_PLAN },
    { name: 'Other', value: gc.OTHER_SAVING_PLAN }
  ]
}

const generateSavingsProjection = ({
  projectionMonths,
  startingBalance,
  monthlyCommitmentValue,
  interestRate,
  existingSavings,
  depositRequired,
  savingsType
}) => {
  // generate graph with average values and as user inputs change to user values
  // show graph maximum for 20 years
  projectionMonths = projectionMonths > 240 ? 240 : projectionMonths
  projectionMonths =
    projectionMonths <= 0 ? getAverageTermForSavings(savingsType) : projectionMonths

  interestRate = interestRate === null ? getAverageInterestRateSavings(savingsType) : interestRate
  existingSavings = existingSavings != null ? existingSavings : 0
  depositRequired =
    depositRequired <= 0 ? getAverageDepositRequiredSavings(savingsType) : depositRequired

  monthlyCommitmentValue =
    monthlyCommitmentValue <= 0
      ? projectSavingsMonthlyContribution({
          existingSavings: existingSavings,
          depositRequired: depositRequired,
          rate: interestRate,
          numberOfMonths: projectionMonths
        })
      : monthlyCommitmentValue

  // start calculations
  const monthlyCalculations = []
  let counter = 0

  // do calculations for all the months
  while (counter <= projectionMonths) {
    if (
      monthlyCalculations.length > 0 &&
      monthlyCalculations[monthlyCalculations.length - 1].total >= depositRequired
    ) {
      // break total is above deposit required
      break
    }

    counter++

    // generate x axis labels
    const currentDate = new Date()
    currentDate.setMonth(currentDate.getMonth() + counter)
    const currentMonth =
      getMonthNameByIndex(currentDate.getMonth()) + '-' + currentDate.getFullYear()

    // generate projection data points
    if (monthlyCalculations.length === 0) {
      let interest = 0
      let total = startingBalance + monthlyCommitmentValue
      if (startingBalance > 0) {
        interest = startingBalance * (interestRate / 100 / 12)
        total += interest
      }

      monthlyCalculations.push({
        interest: interest,
        lastMonthBalance: startingBalance,
        committed: monthlyCommitmentValue,
        currentMonthLabel: currentMonth,
        total: total
      })
    } else {
      const lastMonthBal = monthlyCalculations[monthlyCalculations.length - 1].total
      const interest = lastMonthBal * (interestRate / 100 / 12)
      const total = Math.round(lastMonthBal + interest + monthlyCommitmentValue)
      if (total - (depositRequired + existingSavings) <= monthlyCommitmentValue) {
        monthlyCalculations.push({
          interest: interest,
          lastMonthBalance: lastMonthBal,
          committed: monthlyCommitmentValue,
          currentMonthLabel: currentMonth,
          total: total
        })
      }
    }
  }

  return {
    monthlyCalculations: monthlyCalculations,
    reachedIn: monthlyCalculations.length
  }
}

const predictSavingPeriod = ({ existingSavings, targetAmount, monthlyPayment, rate }) => {
  // period =  Log[(d + r s)/(d + r x)]/Log[1 + r]
  // x = existing savings
  // d =  periodic payment
  // r = interest rate per period (in years)
  // s = target Amount
  const monthlyInterestRate = rate / 100 / 12
  const numerator = Math.log(
    (monthlyPayment + monthlyInterestRate * targetAmount) /
      (monthlyPayment + monthlyInterestRate * existingSavings)
  )
  const denominator = Math.log(1 + monthlyInterestRate)
  return Math.abs(numerator / denominator)
}

const getSavingsErrors = () => {
  return {
    name: '',
    current_value: '',
    rate: '',
    purchase_price: '',
    amount: '',
    monthly_expense: '',
    number_of_months: '',
    monthly_commitment: ''
  }
}

const validateSavingsPlan = (savingsObject, actualSavings, totalCash) => {
  let validated = true
  const errorObject = getSavingsErrors()
  let savingsCurrentValue = { reset: false, value: 0 }

  // common validations
  if (savingsObject.name === undefined || savingsObject.name === '') {
    validated = false
    errorObject.name = 'Please provide Saving plan name'
  }

  // if (savingsObject.type !== gc.RAINY_DAY_PLAN && (!savingsObject.rate || savingsObject.rate < 0)) {
  //   validated = false
  //   errorObject.rate = 'Please enter valid interest rate'
  // }

  if (totalCash < actualSavings) {
    validated = false
    errorObject.current_value = 'You do not have enough savings, please update cash summary'
  }

  if (savingsObject.type === gc.PROPERTY_SAVINGS_PLAN) {
    if (savingsObject.rate < 0 || savingsObject.rate > 15) {
      validated = false
      errorObject.rate = `Interest rate should not be ${
        savingsObject.rate < 0 ? 'less than 0' : 'more than 15'
      }%`
    }

    if (savingsObject.purchase_price === 0) {
      validated = false
      errorObject.purchase_price = 'Please enter price of property'
    }

    if (savingsObject.number_of_months <= 0) {
      validated = false
      errorObject.number_of_months = 'Please provide a valid term'
    }

    if (savingsObject.number_of_months > 240) {
      validated = false
      errorObject.number_of_months = 'Term should be less than 20 yrs'
    }

    if (savingsObject.amount <= 0) {
      validated = false
      errorObject.amount = 'Saving target should be more than $0'
    }

    if (savingsObject.current_value > savingsObject.amount) {
      validated = false
      errorObject.current_value = "Existing savings can't be more than saving target"
      savingsCurrentValue = { reset: true, value: Math.ceil(savingsObject.purchase_price * 0.2) }
    }

    if (savingsObject.amount < savingsObject.purchase_price * 0.05) {
      validated = false
      errorObject.amount = `Deposit can't be less than ${formatCurrency(
        savingsObject.purchase_price * 0.05
      )}`
      savingsCurrentValue = { reset: true, value: Math.ceil(savingsObject.purchase_price * 0.05) }
    }

    if (savingsObject.amount > savingsObject.purchase_price) {
      validated = false
      errorObject.amount = 'Deposit amount can not be more than purchase price'
    }

    if (savingsObject.monthly_commitment <= 0) {
      validated = false
      errorObject.monthly_commitment = 'Please provide monthly amount required'
    }
  } else if (savingsObject.type === gc.RAINY_DAY_PLAN) {
    if (savingsObject.rate && savingsObject.rate !== 0 && savingsObject.rate > 15) {
      validated = false
      errorObject.rate = `Interest rate should not be ${
        savingsObject.rate <= 0 ? 'less than 0' : 'more than 15'
      }%`
    }
  } else {
    // HOLIDAY_DAY_SAVINGS_PLAN, RENOVATION_DAY_SAVINGS_PLAN, KIDS_EDUCATION_DAY_SAVINGS_PLAN,
    // OTHER_SAVING_PLAN, CAR_SAVINGS_PLAN, WEDDING_SAVINGS_PLAN
    if (savingsObject.number_of_months <= 0) {
      validated = false
      errorObject.number_of_months = 'Please provide a valid term'
    }

    if (savingsObject.number_of_months > 240) {
      validated = false
      errorObject.number_of_months = 'Term should be less than 20 yrs'
    }

    if (savingsObject.amount <= 0) {
      validated = false
      errorObject.amount = 'Needed amount should be more than $0'
    }

    if (savingsObject.amount < savingsObject.current_value) {
      validated = false
      errorObject.current_value = 'Total saved should be less than the target'
    }

    if (savingsObject.monthly_commitment <= 0) {
      validated = false
      errorObject.monthly_commitment = 'Please provide monthly amount required'
    }

    if (savingsObject.rate > 15) {
      validated = false
      errorObject.rate = `Interest rate should not be ${
        savingsObject.rate <= 0 ? 'less than 0' : 'more than 15'
      }%`
    }
  }

  return {
    validated: validated,
    errors: errorObject,
    savingsCurrentValue: savingsCurrentValue
  }
}
export {
  updateSavingObject,
  updateSavingObjectBeforePosting,
  createSavingObjectAfterSaving,
  projectSavingsMonthlyContribution,
  getAverageInterestRateSavings,
  getAverageTermForSavings,
  getAverageExistingSavings,
  getAverageDepositRequiredSavings,
  getAllSavingsTypes,
  generateSavingsProjection,
  projectSavingsMonthlyContributionWithFallBackValues,
  predictSavingPeriod,
  getSavingsErrors,
  validateSavingsPlan
}
