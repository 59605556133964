<template>
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_19556_74765)">
      <path
        d="M15.0005 14.4082C14.8513 14.408 14.7041 14.3745 14.5696 14.31C14.435 14.2456 14.3166 14.1518 14.223 14.0357L9.95052 8.75318L7.23552 11.6907C7.13505 11.7995 7.01166 11.8846 6.87428 11.9399C6.73689 11.9952 6.58894 12.0193 6.44111 12.0104C6.29328 12.0016 6.14926 11.96 6.01945 11.8887C5.88965 11.8174 5.7773 11.7182 5.69052 11.5982L0.190525 3.99318C0.107214 3.88725 0.0461759 3.76557 0.0110756 3.63545C-0.0240247 3.50534 -0.0324626 3.36947 -0.0137309 3.23601C0.00500076 3.10255 0.0505153 2.97425 0.120079 2.85883C0.189642 2.7434 0.281819 2.64323 0.391072 2.56432C0.500325 2.48542 0.6244 2.42941 0.755844 2.39966C0.887287 2.36992 1.02339 2.36705 1.15597 2.39122C1.28855 2.4154 1.41487 2.46613 1.52736 2.54036C1.63984 2.61459 1.73616 2.71079 1.81052 2.82318L6.59552 9.43568L9.26552 6.54568C9.36287 6.44232 9.4811 6.36087 9.61237 6.30676C9.74363 6.25264 9.88492 6.2271 10.0268 6.23183C10.1687 6.23656 10.308 6.27146 10.4354 6.33419C10.5627 6.39693 10.6753 6.48607 10.7655 6.59568L15.7655 12.7807C15.8832 12.9267 15.9575 13.1028 15.9798 13.289C16.0022 13.4752 15.9718 13.6639 15.892 13.8337C15.8122 14.0034 15.6863 14.1472 15.5287 14.2488C15.3711 14.3504 15.188 14.4056 15.0005 14.4082Z"
        :class="`fill-${fill1} group-hover:fill-${hover1 ?? fill1}`" />
      <path
        d="M13 16.6875H3C2.20435 16.6875 1.44129 16.3714 0.87868 15.8088C0.316071 15.2462 0 14.4832 0 13.6875V3.6875C0 2.89185 0.316071 2.12879 0.87868 1.56618C1.44129 1.00357 2.20435 0.6875 3 0.6875H13C13.7956 0.6875 14.5587 1.00357 15.1213 1.56618C15.6839 2.12879 16 2.89185 16 3.6875V13.6875C16 14.4832 15.6839 15.2462 15.1213 15.8088C14.5587 16.3714 13.7956 16.6875 13 16.6875ZM3 2.6875C2.73478 2.6875 2.48043 2.79286 2.29289 2.98039C2.10536 3.16793 2 3.42228 2 3.6875V13.6875C2 13.9527 2.10536 14.2071 2.29289 14.3946C2.48043 14.5821 2.73478 14.6875 3 14.6875H13C13.2652 14.6875 13.5196 14.5821 13.7071 14.3946C13.8946 14.2071 14 13.9527 14 13.6875V3.6875C14 3.42228 13.8946 3.16793 13.7071 2.98039C13.5196 2.79286 13.2652 2.6875 13 2.6875H3Z"
        :class="`fill-${fill2} group-hover:fill-${hover2 ?? fill2}`" />
    </g>
    <defs>
      <clipPath id="clip0_19556_74765">
        <rect width="16" height="16" fill="white" transform="translate(0 0.6875)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts" setup>
type Props = {
  fill1?: string
  fill2?: string
  hover1?: string | null
  hover2?: string | null
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red',
  hover1: null,
  hover2: null
})
</script>
