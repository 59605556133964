<template>
  <modal v-if="showPricingModal" @close="closeModal()" class="p-0">
    <template #header>
      <h1 class="text-center">Financial freedom starts here</h1>
    </template>
    <template #body>
      <h4 class="mb-4 mx-4 font-normal text-center">
        All our plans come with a <span class="font-bold">free {{ freeTrial() }} day trial</span>.
        So why not take us for a spin?
      </h4>
    </template>
    <template #footer>
      <div class="md:flex justify-center w-full self-center pb-8">
        <div
          v-for="(product, index) in filteredProducts"
          :key="product.product.code"
          class="w-100% md:w-68 md:mx-6">
          <div
            class="hidden md:block h-10 w-28 bg-otivo-red pt-2 text-white font-bold text-center ml-2 rounded-t-sm"
            v-if="index % 2 !== 0">
            Best buy
          </div>
          <div class="md:h-10" v-else-if="index % 2 === 0" />
          <div
            class="border rounded border-otivo-blue h-100% mb-4"
            :class="{ 'shadow-lg': index % 2 !== 0 }">
            <product-features
              :product="product.product"
              :features="product.features"
              :index="index"
              :is-modal="showModal" />
            <free-trial-email-modal
              class="w-70% m-auto pb-4 md:pb-0"
              colour="blue"
              button-text="Snap up your FREE trial here" />
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import FreeTrialEmailModal from '@/components/BrandComponents/Otivo/FreeTrial/FreeTrialEmailModal.vue'
import Modal from '@/components/Modals/Modal.vue'
import ProductFeatures from '@/components/Subscription/ProductFeatures.vue'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'PricingModal',
  components: { FreeTrialEmailModal, Modal, ProductFeatures },
  computed: {
    ...mapState({
      products: (state) => state.whitelabelDefaultValues.whitelabelDataObject.products,
      showPricingModal: (state) => state.navBar.showPricingModal,
    }),
    ...mapGetters(['getCurrentWhitelabelObject']),
    showModal() {
      return window.innerWidth > 767
    },
    filteredProducts() {
      // Filtering hidden products
      return this.products.filter((prod) => prod.product.hidden === 0)
    },
  },
  methods: {
    closeModal() {
      this.$store.dispatch('renderPricingModal', false)
    },
    closeAndSignUp() {
      this.$store.dispatch('renderPricingModal', false)
      this.$router.push({ name: 'signUpStep1' })
    },
    freeTrial() {
      return this.getCurrentWhitelabelObject.freeDays === 31
        ? 'month'
        : this.getCurrentWhitelabelObject.freeDays + ' day'
    },
    buttonText() {
      return 'Start your FREE ' + this.freeTrial() + ' trial'
    },
  },
}
</script>

<style scoped></style>
