import { useSuperStore } from '@/store/pinia/SuperStore.ts'
import { computed } from 'vue'
import { getAPIUrl } from '@/lib/WhiteLabelHelpers'

export default () => {
  const superStore = useSuperStore()
  const apiDomain = getAPIUrl()
  const activeSuperObject = computed(() => superStore.getActiveSuperObject)
  return `${apiDomain}/chant-west/pds/${activeSuperObject.value.chant_west_product_id}`
}
