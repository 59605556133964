import CfsService from '@/services/CfsService'
import { useGuidanceClientStore } from '@/store/pinia/GuidanceClientStore'

// TODO: refactor/remove

const state = () => ({
  cfs: {
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    mobile: null,
    userType: null,
  },
  cfsLoading: false,
  cfsStaff: [],
  cfsActionModal: null,
  cfsCreateStaffModal: false,
  cfsEditStaffModal: false,
})

const getters = {
  cfsCreateStaffModal: (state) => state.cfsCreateStaffModal,
  cfsEditStaffModal: (state) => state.cfsEditStaffModal,
  cfsStaff: (state) => state.cfsStaff,
}

const mutations = {
  SET_C_F_S_ACTION_MODAL_ID(state, id) {
    state.cfsActionModal = id
  },
  CLEAR_C_F_S_ACTION_MODAL_ID(state) {
    state.cfsActionModal = null
  },
  SET_CFS_USER_DATA(state, value) {
    state.cfs = value
  },
  SET_CFS_STAFF(state, value) {
    state.cfsStaff = value
  },
  SET_CFS_USER_DATA_LOADING(state, value) {
    state.cfsLoading = value
  },
  SET_CFS_CREATE_STAFF_MODAL(state, value) {
    state.cfsCreateStaffModal = value
  },
  SET_CFS_EDIT_STAFF_MODAL(state, value) {
    state.cfsEditStaffModal = value
  },
}

const actions = {
  setCFSUserData({ commit }, payload) {
    commit('SET_CFS_USER_DATA_LOADING', false)
    commit('SET_CFS_USER_DATA', payload)
  },
  setCFSStaff({ commit }, payload) {
    commit('SET_CFS_STAFF', payload)
  },
  requestOpenActionModal({ commit }, id) {
    commit('SET_C_F_S_ACTION_MODAL_ID', id)
  },

  /**
   * TODO: convert from CFS
   * Determine if this is required any longer, CFS users should just be regular 'user' or if admin portal managed user 'ManagedUser'
   * @param dispatch
   * @returns {*}
   */
  getCFSUserInfo({ dispatch }) {
    return CfsService.getCFSUserInfo()
      .then((res) => {
        const user = res.data.data
        let action = 'setCFSUserData'
        if (user.userType === 'client') return useGuidanceClientStore().setCFSClient(user)
        dispatch(action, user)
        return user
      })
      .catch((err) => {
        console.warn(err)
      })
  },
  getAllCFSStaff({ dispatch }) {
    return CfsService.getAllCFSStaff()
      .then((res) => {
        dispatch('setCFSStaff', res.data.data)
      })
      .catch((err) => {
        console.warn(err)
      })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
