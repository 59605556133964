<template>
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="`fill-${fill}`">
    <g clip-path="url(#clip0_3355_75817)">
      <path
        d="M1 4.49438L1 9.36938C1 10.4025 1.84187 11.2444 2.875 11.2444L6.5 11.2444C6.91437 11.2444 7.25 10.9088 7.25 10.4944C7.25 10.08 6.91437 9.74438 6.5 9.74438L2.875 9.74438C2.66875 9.74438 2.5 9.57563 2.5 9.36938L2.5 4.49438C2.5 4.08001 2.16437 3.74438 1.75 3.74438C1.33562 3.74438 1 4.08001 1 4.49438Z"
        fill="currentColor" />
      <path
        d="M9 4.49438L9 9.36938C9 10.4025 8.15813 11.2444 7.125 11.2444L6 11.2444C5.58562 11.2444 5.25 10.9088 5.25 10.4944C5.25 10.08 5.58562 9.74438 6 9.74438L7.125 9.74438C7.33125 9.74438 7.5 9.57563 7.5 9.36938L7.5 4.49438C7.5 4.08001 7.83563 3.74438 8.25 3.74438C8.66438 3.74438 9 4.08001 9 4.49438Z"
        fill="currentColor" />
      <rect x="3" width="4" height="1.5" rx="0.75" fill="currentColor" />
      <path
        d="M9.2 2.80273H0.8C0.358 2.80273 0 2.46711 0 2.05273C0 1.63836 0.358 1.30273 0.8 1.30273H9.2C9.642 1.30273 10 1.63836 10 2.05273C10 2.46711 9.642 2.80273 9.2 2.80273Z"
        fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_3355_75817">
        <rect width="10" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    fill?: string
  }>(),
  {
    fill: 'grey-3'
  }
)
</script>
