<template>
  <div
    v-if="impersonating"
    class="bg-otivo-red w-100% text-white relative flex justify-center py-[5px]">
    <p class="paragraph-2 w-fit" v-if="fullName">You are logged in as {{ fullName }}</p>
    <OtivoTextButton class="text-white ml-[20px]" @click="stopImpersonating">
      cancel
    </OtivoTextButton>
  </div>
</template>

<script setup lang="ts">
import { store } from '@/store/index.ts'
import { computed } from 'vue'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import OtivoTextButton from '@/components/OtivoTextButton.vue'
import getApiInstance from '@/services/Api.ts'

const sessionStore = useSessionStore()
const fullName = computed(() => store.getters.fullName)
const impersonating = computed(() => sessionStore.getImpersonationState)

const stopImpersonating = () => {
  sessionStorage.removeItem('impersonationToken')
  getApiInstance().setImpersonationHeader('')
  sessionStore.setImpersonationState(false)
}
</script>

<style scoped></style>
