<template>
  <div class="bg-blue-6 w-100% h-100%">
    <BeatLoader
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      color="var(--otivo-blue)"
      size="25px" />
  </div>
</template>

<script setup lang="ts">
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'
</script>

<style scoped></style>
