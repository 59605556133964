import getApiInstance from '@/services/Api.ts'

const api = getApiInstance()

export default {
  createPortalStaffMember(payload) {
    return api.Post('/cfs/users', payload)
  },

  updatePortalStaffMember(payload) {
    return api.Put(`/cfs/users/${payload.id}`, payload)
  },

  deleteStaffMember(id: string) {
    return api.Delete(`/cfs/users/${id}`)
  },

  restoreStaffMember(id: string) {
    return api.Put(`/cfs/restore-users/${id}`)
  },

  getAllPortalStaff(sortObject) {
    const payload = { params: sortObject }
    return api.Get('/cfs/users', payload)
  },
}
