import AdminReportService from '@/services/AdminReportService'
import _ from 'lodash'
import moment from 'moment'

// TODO: RICH MAJOR REFACTOR, NAMESPACE STORE
const state = () => ({
  overviewLoading: true,
  debtLoading: true,
  savingsLoading: true,
  insuranceLoading: true,
  retirementLoading: true,
  fitnessTestLoading: true,
  linkedAccountsLoading: true,
  emailReportsLoading: true,
  ageGroupsLoading: true,
  ageGroupsTotal: {},

  userStatsLoading: true,
  userStats: {},

  dateRange: {
    startDate: null,
    endDate: null
  },

  monthRange: [],

  overview: {
    cashFlow: {},
    dailyData: [],
    genderTotals: {},
    otherTotals: {},
    stateTotals: {},
    loginByMonth: {},
    optimise: {},
    totals: {},
    whitelabel_id: null
  },

  debt: {},
  savings: {},
  retirement: {},
  insurance: {},
  investment: {},
  superannuation: {},
  fitnessTest: {},
  linkedAccounts: {},
  emailReports: {},

  selectedWhiteLabel: 'all',
  selectedSalaryBand: 'all',

  whiteLabels: [],
  companies: [],
  selectedCompany: ''
})

const getters = {
  getFitnessTest: (state) => state.fitnessTest,

  getAdminReport(state) {
    return state
  },

  getAdminReportFilteredDailyData(state) {
    const startTIme = new Date(state.dateRange.startDate).getTime()
    const endTime = new Date(state.dateRange.endDate).getTime()
    return state.overview.dailyData.filter((el) => {
      const elTime = new Date(el.on_date).getTime()
      return elTime >= startTIme && elTime <= endTime
    })
  },

  getAdminReportTotals(state) {
    return state.overview.totals
  },

  getTotalPlanUsers(state, getters) {
    return (
      getters.getAdminReportTotals.personalPlanAndFitnessTest +
      getters.getAdminReportTotals.personalPlanOnly
    )
  },

  getAdminReportPercentageTotals(state, getters) {
    const totalPlanUsers = getters.getTotalPlanUsers
    return {
      completedSuppFormNumber: state.overview.otherTotals.completedSuppForm,
      completedSuppFormPercentage: (
        (state.overview.otherTotals.completedSuppForm / totalPlanUsers) *
        100
      ).toFixed(2),
      havePartnerPercentage: (
        (state.overview.otherTotals.havePartner / totalPlanUsers) *
        100
      ).toFixed(2)
    }
  },

  getLoginByMonths(state) {
    return state.overview.loginByMonth
  },

  getAdminReportAllMonthValues(state) {
    return state.monthRange
  }
}

const mutations = {
  SET_LOADING_OF_ADMIN_OVERVIEW_REPORT(state, value) {
    state.overviewLoading = value
  },

  SET_LOADING_OF_ADMIN_AGE_GROUP_REPORT(state, value) {
    state.ageGroupsLoading = value
  },

  SET_LOADING_OF_USER_STATS_ADMIN_REPORT(state, value) {
    state.userStatsLoading = value
  },

  SET_LOADING_OF_ADMIN_DEBT_REPORT(state, value) {
    state.debtLoading = value
  },

  SET_LOADING_OF_ADMIN_SAVINGS_REPORT(state, value) {
    state.savingsLoading = value
  },

  SET_LOADING_OF_ADMIN_INSURANCE_REPORT(state, value) {
    state.insuranceLoading = value
  },

  SET_LOADING_OF_ADMIN_RETIREMENT_REPORT(state, value) {
    state.retirementLoading = value
  },

  SET_LOADING_OF_ADMIN_FITNESS_TEST_REPORT(state, value) {
    state.fitnessTestLoading = value
  },

  SET_LOADING_OF_ADMIN_LINKED_ACCOUNTS_REPORT(state, value) {
    state.linkedAccountsLoading = value
  },

  SET_LOADING_OF_ADMIN_EMAIL_REPORTS_REPORT(state, value) {
    state.emailReportsLoading = value
  },

  SET_ADMIN_REPORT_DATE_RANGE(state, payload) {
    state.dateRange = payload
  },

  SET_ADMIN_REPORT_MONTH_RANGE(state, payload) {
    state.monthRange = payload
  },

  SET_ADMIN_REPORT_OVERVIEW_DATA(state, payload) {
    state.overview = { ...state.overview, ...payload.overview }
  },

  SET_TOTAL_USERS(state, payload) {
    state.overview.totals.totalUsers = payload
  },

  SET_AGE_REPORT_DATA(state, payload) {
    state.ageGroupsTotal = payload.ageStats
  },

  SET_USER_STATS_DATA(state, payload) {
    state.userStats = payload.userStats
  },

  SET_ADMIN_REPORT_DEBT_DATA(state, payload) {
    state.debt = payload.debt
  },

  SET_ADMIN_REPORT_WHITELABEL_COMPANIES(state, payload) {
    state.companies = payload
  },

  SET_ADMIN_REPORT_SELECTED_COMPANY(state, payload) {
    state.selectedCompany = payload
  },

  SET_ADMIN_REPORT_SAVINGS_DATA(state, payload) {
    state.savings = payload.savings
  },

  SET_ADMIN_REPORT_INSURANCE_DATA(state, payload) {
    state.insurance = payload.insurance
  },

  SET_ADMIN_REPORT_RETIREMENT_DATA(state, payload) {
    state.retirement = payload.retirement
    state.investment = payload.investment
    state.superannuation = payload.superannuation
  },

  SET_ADMIN_REPORT_FITNESS_TEST_DATA(state, payload) {
    state.fitnessTest = payload.fitnessTest
  },

  SET_ADMIN_REPORT_LINKED_ACCOUNTS_DATA(state, payload) {
    state.linkedAccounts = payload.linkedAccounts
  },

  SET_ADMIN_REPORT_EMAIL_REPORTS_DATA(state, payload) {
    state.emailReports = payload.emailReports
  },

  SET_SELECTED_WHITELABEL_ID(state, value) {
    state.selectedWhiteLabel = value
    state.overview.whitelabel_id = value
  },

  SET_SELECTED_SALARY_BAND(state, value) {
    state.selectedSalaryBand = value
  },

  SET_ALL_WHITE_LABELS(state, value) {
    state.whiteLabels = value
  },

  SET_ADMIN_REPORT_OPTIMISE_DATA(state, value) {
    state.overview.optimise = value
  },

  SET_ADMIN_REPORT_CASHFLOW_DATA(state, value) {
    state.overview.cashFlow = value
  },

  SET_ADMIN_REPORT_DAILY_LOGINS_DATA(state, value) {
    state.overview.loginByMonth = value
  }
}

const actions = {
  getWhiteLabelAdminReport({ dispatch, rootGetters }) {
    if (rootGetters.profile.superAdmin) {
      dispatch('getAllWhitelabels')
    }
    return Promise.all([
      dispatch('getAdminReportOverview'),

      dispatch('getAdminReportOptimiseAverages'),
      dispatch('getAdminReportCashFlow'),
      dispatch('getAdminReportDailyLogins'),

      dispatch('getAdminReportDebt'),
      dispatch('getAdminReportSavings'),
      dispatch('getAdminReportInsurance'),
      dispatch('getAdminReportRetirement'),
      dispatch('getFitnessTestReport'),
      dispatch('getLinkedAccountsReport'),
      dispatch('getEmailReportsReport'),
      dispatch('getAgeGroupsAdminReport'),
      dispatch('getUserStatsAdminReport'),
      dispatch('getAdminReportCompanies')
    ])
  },

  getAgeGroupsAdminReport({ commit, getters }) {
    commit('SET_LOADING_OF_ADMIN_AGE_GROUP_REPORT', true)
    return AdminReportService.getAgeReport(
      getters.getAdminReport.selectedWhiteLabel,
      getters.getAdminReport.selectedSalaryBand,
      getters.getAdminReport.selectedCompany
    )
      .then((res) => {
        commit('SET_AGE_REPORT_DATA', res.data.data)
        commit('SET_LOADING_OF_ADMIN_AGE_GROUP_REPORT', false)
      })
      .catch((err) => {
        commit('SET_LOADING_OF_ADMIN_AGE_GROUP_REPORT', false)
        throw err
      })
  },

  getUserStatsAdminReport({ commit, getters }) {
    commit('SET_LOADING_OF_USER_STATS_ADMIN_REPORT', true)
    return AdminReportService.getUserStats(
      getters.getAdminReport.selectedWhiteLabel,
      getters.getAdminReport.selectedSalaryBand,
      getters.getAdminReport.selectedCompany
    )
      .then((res) => {
        commit('SET_USER_STATS_DATA', res.data.data)
        commit('SET_LOADING_OF_USER_STATS_ADMIN_REPORT', false)
      })
      .catch((err) => {
        commit('SET_LOADING_OF_USER_STATS_ADMIN_REPORT', false)
        throw err
      })
  },

  getAdminReportCompanies({ commit, dispatch, getters }) {
    return AdminReportService.getWhitelabelCompanies(
      getters.getAdminReport.selectedWhiteLabel,
      getters.getAdminReport.selectedSalaryBand
    )
      .then((res) => {
        dispatch('updateWhitelabelCompanies', res.data.data)
      })
      .catch((err) => {
        throw err
      })
  },

  getAdminReportOverview({ commit, dispatch, getters }) {
    commit('SET_LOADING_OF_ADMIN_OVERVIEW_REPORT', true)
    return AdminReportService.getOverview(
      getters.getAdminReport.selectedWhiteLabel,
      getters.getAdminReport.selectedSalaryBand,
      getters.getAdminReport.selectedCompany
    )
      .then((res) => {
        dispatch('updateAdminReportsOverview', res.data.data)
        commit('SET_LOADING_OF_ADMIN_OVERVIEW_REPORT', false)
      })
      .catch((err) => {
        commit('SET_LOADING_OF_ADMIN_OVERVIEW_REPORT', false)
        throw err
      })
  },

  getAdminReportOptimiseAverages({ commit, dispatch, getters }) {
    return AdminReportService.getOptimiseAverages(
      getters.getAdminReport.selectedWhiteLabel,
      getters.getAdminReport.selectedSalaryBand,
      getters.getAdminReport.selectedCompany
    )
      .then((res) => {
        dispatch('updateAdminReportsOptimiseAverages', res.data.data?.optimise)
      })
      .catch((err) => {
        throw err
      })
  },

  getAdminReportDailyLogins({ commit, dispatch, getters }) {
    return AdminReportService.getDailyLogins(
      getters.getAdminReport.selectedWhiteLabel,
      getters.getAdminReport.selectedSalaryBand,
      getters.getAdminReport.selectedCompany
    )
      .then((res) => {
        dispatch('updateAdminReportsDailyLogins', res.data.data?.loginsByMonth)
      })
      .catch((err) => {
        throw err
      })
  },

  getAdminReportCashFlow({ commit, dispatch, getters }) {
    return AdminReportService.getCashFlow(
      getters.getAdminReport.selectedWhiteLabel,
      getters.getAdminReport.selectedSalaryBand,
      getters.getAdminReport.selectedCompany
    )
      .then((res) => {
        dispatch('updateAdminReportsCashFlow', res.data.data?.cashFlow)
      })
      .catch((err) => {
        throw err
      })
  },

  getAdminReportDebt({ commit, dispatch, getters }) {
    commit('SET_LOADING_OF_ADMIN_DEBT_REPORT', true)
    return AdminReportService.getDebt(
      getters.getAdminReport.selectedWhiteLabel,
      getters.getAdminReport.selectedSalaryBand,
      getters.getAdminReport.selectedCompany
    )
      .then((res) => {
        dispatch('updateAdminReportsDebt', res.data.data)
        commit('SET_LOADING_OF_ADMIN_DEBT_REPORT', false)
      })
      .catch((err) => {
        commit('SET_LOADING_OF_ADMIN_DEBT_REPORT', false)
        throw err
      })
  },

  getAdminReportSavings({ commit, dispatch, getters }) {
    commit('SET_LOADING_OF_ADMIN_SAVINGS_REPORT', true)
    return AdminReportService.getSavings(
      getters.getAdminReport.selectedWhiteLabel,
      getters.getAdminReport.selectedSalaryBand,
      getters.getAdminReport.selectedCompany
    )
      .then((res) => {
        dispatch('updateAdminReportsSavings', res.data.data)
        commit('SET_LOADING_OF_ADMIN_SAVINGS_REPORT', false)
      })
      .catch((err) => {
        commit('SET_LOADING_OF_ADMIN_SAVINGS_REPORT', false)
        throw err
      })
  },

  getAdminReportInsurance({ commit, dispatch, getters }) {
    commit('SET_LOADING_OF_ADMIN_INSURANCE_REPORT', true)
    return AdminReportService.getInsurance(
      getters.getAdminReport.selectedWhiteLabel,
      getters.getAdminReport.selectedSalaryBand,
      getters.getAdminReport.selectedCompany
    )
      .then((res) => {
        dispatch('updateAdminReportsInsurance', res.data.data)
        commit('SET_LOADING_OF_ADMIN_INSURANCE_REPORT', false)
      })
      .catch((err) => {
        commit('SET_LOADING_OF_ADMIN_INSURANCE_REPORT', false)
        throw err
      })
  },

  getAdminReportRetirement({ commit, dispatch, getters }) {
    commit('SET_LOADING_OF_ADMIN_RETIREMENT_REPORT', true)
    return AdminReportService.getRetireAndInvest(
      getters.getAdminReport.selectedWhiteLabel,
      getters.getAdminReport.selectedSalaryBand,
      getters.getAdminReport.selectedCompany
    )
      .then((res) => {
        dispatch('updateAdminReportsRetirement', res.data.data)
        commit('SET_LOADING_OF_ADMIN_RETIREMENT_REPORT', false)
      })
      .catch((err) => {
        commit('SET_LOADING_OF_ADMIN_RETIREMENT_REPORT', false)
        throw err
      })
  },

  getFitnessTestReport({ commit, dispatch, getters }) {
    commit('SET_LOADING_OF_ADMIN_RETIREMENT_REPORT', true)
    return AdminReportService.getFitnessTestReport(
      getters.getAdminReport.selectedWhiteLabel,
      getters.getAdminReport.selectedSalaryBand,
      getters.getAdminReport.selectedCompany
    )
      .then((res) => {
        dispatch('updateAdminReportsFitnessTest', res.data.data)
        commit('SET_LOADING_OF_ADMIN_FITNESS_TEST_REPORT', false)
      })
      .catch((err) => {
        commit('SET_LOADING_OF_ADMIN_FITNESS_TEST_REPORT', false)
        throw err
      })
  },

  getLinkedAccountsReport({ commit, dispatch, getters }) {
    commit('SET_LOADING_OF_ADMIN_LINKED_ACCOUNTS_REPORT', true)
    return AdminReportService.getLinkedAccountsReport(
      getters.getAdminReport.selectedWhiteLabel,
      getters.getAdminReport.selectedSalaryBand,
      getters.getAdminReport.selectedCompany
    )
      .then((res) => {
        dispatch('updateAdminReportsLinkedAccounts', res.data.data)
        commit('SET_LOADING_OF_ADMIN_LINKED_ACCOUNTS_REPORT', false)
      })
      .catch((err) => {
        commit('SET_LOADING_OF_ADMIN_LINKED_ACCOUNTS_REPORT', false)
        throw err
      })
  },

  getEmailReportsReport({ commit, dispatch, getters }) {
    commit('SET_LOADING_OF_ADMIN_EMAIL_REPORTS_REPORT', true)
    return AdminReportService.getEmailReports(
      getters.getAdminReport.selectedWhiteLabel,
      getters.getAdminReport.selectedSalaryBand,
      getters.getAdminReport.selectedCompany
    )
      .then((res) => {
        dispatch('updateAdminReportsEmailReports', res.data.data)
        commit('SET_LOADING_OF_ADMIN_EMAIL_REPORTS_REPORT', false)
      })
      .catch((err) => {
        commit('SET_LOADING_OF_ADMIN_EMAIL_REPORTS_REPORT', false)
        throw err
      })
  },

  updateAdminReportsOverview({ commit, dispatch, getters }, payload) {
    commit('SET_ADMIN_REPORT_OVERVIEW_DATA', payload)

    // @todo - move calculate total to backend
    let totalUsers = 0
    _.forEach(payload.overview.totals, (total) => {
      totalUsers += total
    })
    commit('SET_TOTAL_USERS', totalUsers)

    commit('SET_SELECTED_WHITELABEL_ID', payload.whitelabel_id)
    const dailyData = payload.overview.dailyData
    const startDate = dailyData.length > 0 ? dailyData[0].on_date : new Date()
    const endDate = dailyData.length > 0 ? dailyData[dailyData.length - 1].on_date : new Date()
    dispatch('setAdminReportRange', {
      startDate: startDate,
      endDate: endDate
    })
    const months = []
    let dateStart = moment(startDate, 'DD MMM YYYY')
    const dateEnd = moment(endDate, 'DD MMM YYYY')
    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
      months.push({
        label: dateStart.format('MMM YYYY'),
        value: dateStart.format('YYYY-MM')
      })
      dateStart = dateStart.add(1, 'month')
    }
    dispatch('setAdminReportMonthRange', months)
  },

  updateAdminReportsOptimiseAverages({ commit, dispatch }, payload) {
    commit('SET_ADMIN_REPORT_OPTIMISE_DATA', payload)
  },

  updateAdminReportsCashFlow({ commit, dispatch }, payload) {
    commit('SET_ADMIN_REPORT_CASHFLOW_DATA', payload)
  },

  updateAdminReportsDailyLogins({ commit, dispatch }, payload) {
    commit('SET_ADMIN_REPORT_DAILY_LOGINS_DATA', payload)
  },

  updateWhitelabelCompanies({ commit, dispatch }, payload) {
    commit('SET_ADMIN_REPORT_WHITELABEL_COMPANIES', payload)
  },

  setWhitelabelCompany({ commit, dispatch }, payload) {
    commit('SET_ADMIN_REPORT_SELECTED_COMPANY', payload)
  },

  updateAdminReportsDebt({ commit, dispatch }, payload) {
    commit('SET_ADMIN_REPORT_DEBT_DATA', payload)
  },

  updateAdminReportsSavings({ commit, dispatch }, payload) {
    commit('SET_ADMIN_REPORT_SAVINGS_DATA', payload)
  },

  updateAdminReportsInsurance({ commit, dispatch }, payload) {
    commit('SET_ADMIN_REPORT_INSURANCE_DATA', payload)
  },

  updateAdminReportsRetirement({ commit, dispatch }, payload) {
    commit('SET_ADMIN_REPORT_RETIREMENT_DATA', payload)
  },

  updateAdminReportsFitnessTest({ commit, dispatch }, payload) {
    commit('SET_ADMIN_REPORT_FITNESS_TEST_DATA', payload)
  },

  updateAdminReportsLinkedAccounts({ commit, dispatch }, payload) {
    commit('SET_ADMIN_REPORT_LINKED_ACCOUNTS_DATA', payload)
  },

  updateAdminReportsEmailReports({ commit, dispatch }, payload) {
    commit('SET_ADMIN_REPORT_EMAIL_REPORTS_DATA', payload)
  },

  getAllWhitelabels({ dispatch }) {
    return AdminReportService.getAllWhiteLabels().then((res) => {
      dispatch('setAllWhitelabels', res.data.data)
    })
  },

  setAdminReportRange({ commit }, payload) {
    commit('SET_ADMIN_REPORT_DATE_RANGE', payload)
  },

  setAdminReportMonthRange({ commit }, payload) {
    commit('SET_ADMIN_REPORT_MONTH_RANGE', payload)
  },

  setAllWhitelabels({ commit }, payload) {
    commit('SET_ALL_WHITE_LABELS', payload)
  },

  setSelectedWhitelabelId({ commit, dispatch }, id) {
    commit('SET_SELECTED_WHITELABEL_ID', id)
    dispatch('getWhiteLabelAdminReport')
  },

  setSelectedSalaryBand({ commit, dispatch }, value) {
    commit('SET_SELECTED_SALARY_BAND', value)
    dispatch('getWhiteLabelAdminReport')
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
