<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="138.05"
    height="153.628"
    viewBox="0 0 138.05 153.628">
    <g transform="translate(0 0)">
      <path
        style="fill: #6b6c6e"
        d="M138.469,167.708,99.59,87.967,150.731,14.08l39.418,80.274Z"
        transform="translate(-52.098 -14.08)" />
      <path
        style="fill: #2dc0d2"
        d="M11.48,132.516,25.7,44.954,110.113,14.08,96,102.4Z"
        transform="translate(-11.48 -14.08)" />
    </g>
    <path
      style="fill: #0a535d"
      d="M100.11,88.183l11.2,23.323,25.554-9.023,14.111-88.4Z"
      transform="translate(-52.338 -14.08)" />
  </svg>
</template>

<script>
export default {
  name: 'AssureIcon'
}
</script>

<style scoped></style>
