<template>
  <div id="slider-component">
    <div
      ref="sliderContainer"
      class="m-auto overflow-hidden flex relative h-100% border-box"
      :style="`min-height: ${sliderHeight + dynamicPadding * 2}px`"
      :class="[cursorStyle]"
      @mousedown="grabCard"
      @mouseup="releaseCard"
      @mousemove.prevent="slideCards"
      @mouseenter="stopSlidingInterval"
      @mouseleave="startSlidingInterval"
      @touchstart="grabCard"
      @touchend="releaseCard"
      @touchmove="slideCards">
      <div
        id="slider"
        ref="slider"
        class="flex duration-300"
        :class="`h-[${sliderHeight}px]`"
        :style="`transform: translate(${
          isUserOnMobile() || slides.length === 1 ? translate + slideOffset : translate
        }px);`">
        <component
          v-for="(slide, slideIndex) in localSlidesRef"
          class="max-w-[386px]"
          :key="slideIndex"
          :is="component"
          :styles="styles"
          :index="slideIndex"
          :data="slide" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
/**
 * NOTE:
 * inifiniteScroll is currently disabled until such time that we can commit to developing a carousel behaviour
 * TODO: Make slider able to handle number of tiles
 */
import { computed, onMounted, ref, shallowRef } from 'vue'
import { isUserOnMobile } from '@/utilities'

interface Props {
  startingIndex?: number
  animateScroll?: boolean
  infiniteScroll?: boolean
  slides: Array<object>
  component: unknown
  preventScroll?: boolean
  padding?: {
    small: number
    big: number
  }
  styles?: string
}

const props = withDefaults(defineProps<Props>(), {
  startingIndex: 0,
  animateScroll: false,
  infiniteScroll: false,
  preventScroll: false,
  padding: () => {
    return {
      small: 20,
      big: 40,
    }
  },
  styles: '',
})

let yStart = 0
const grabbing = ref(false)
const currentPosition = ref(0)
const sliderContainer = ref<HTMLElement | null>(null)
const slider = ref<HTMLElement | null>(null)
const locationSelected = ref(0)
const startPosition = ref(0)
const index = ref(0)
const sliderHeight = ref(0)
const translate = ref(0)
const localSlidesRef = shallowRef(props.slides)
const slideDistance = ref(0)
const slideOffset = ref(0)

onMounted(() => {
  sliderHeight.value = slider.value.offsetHeight

  slideDistance.value = slider.value.getBoundingClientRect().width / props.slides.length
  startSlidingInterval()
  const viewport = sliderContainer.value.getBoundingClientRect().width
  slideOffset.value = (viewport - slideDistance.value) / 2

  if (props.startingIndex && !props.preventScroll) {
    changeStartingPosition()
  }
})
const dynamicPadding = computed(() => {
  if (window.innerWidth > 768) return props.padding.big
  return props.padding.small
})
const cursorStyle = computed(() => {
  if (props.preventScroll) return ''
  else if (grabbing.value) return 'cursor-grabbing'
  else return 'cursor-grab'
})

let slidingInterval

const startSlidingInterval = () => {
  if (props.animateScroll) {
    // timeout is the amount of time before the slide changes position
    slidingInterval = setInterval(() => slideRight(), 4000)
  }
}

const stopSlidingInterval = () => {
  clearInterval(slidingInterval)
  slidingInterval = undefined
}

// sets the starting position based on the startingIndex passed in
const changeStartingPosition = () => {
  translate.value = translate.value - props.startingIndex * slideDistance.value
  index.value = props.startingIndex
}

// set the location clicked (or pressed) to determine starting position
const grabCard = (e) => {
  if (isUserOnMobile()) yStart = e.touches[0].clientY
  if (props.preventScroll) {
    return
  }
  if (props.animateScroll) {
    stopSlidingInterval()
  }
  grabbing.value = true
  locationSelected.value = isUserOnMobile() ? e.touches[0].pageX : e.offsetX
}

// used to make the card look like it is sliding before it locks into place
const slideCards = (e) => {
  if (!grabbing.value) {
    return
  }
  const endPosition = isUserOnMobile() ? e.touches[0].pageX : e.offsetX

  // prevent X axis slide on Y axis scroll
  currentPosition.value = startPosition.value - (locationSelected.value - endPosition)
}

// Determines which way the card should slide
const releaseCard = (e) => {
  // prevent x axis on Y axis scroll
  if (isUserOnMobile()) {
    const yAxisChange = yStart - e.changedTouches[0].clientY
    if (yAxisChange < 50 && yAxisChange > -50) {
      determineSlide()
    }
  } else {
    determineSlide()
  }
}

const determineSlide = () => {
  if (currentPosition.value < startPosition.value) slideRight()
  else slideLeft()
  grabbing.value = false
}

const slideRight = () => {
  index.value++
  // adds a copy of the first card to the back of the array for infinite scrolling
  // if (props.infiniteScroll) {
  //   const frontSlide = localSlidesRef.value.shift()
  //   localSlidesRef.value = [...localSlidesRef.value, frontSlide]
  //   translate.value = translate.value - slideDistance.value
  // } else {
  // prevents the user from scrolling past the last card
  if (index.value < props.slides.length) {
    translate.value = translate.value - slideDistance.value
  } else {
    index.value = props.slides.length - 1
  }
  // }
}

const slideLeft = () => {
  index.value--

  // if (props.infiniteScroll) {
  //   const lastSlide = localSlidesRef.value.pop()
  //   localSlidesRef.value = [lastSlide, ...localSlidesRef.value]
  //   translate.value = translate.value + slideDistance.value
  // }

  if (index.value === -1) {
    index.value = 0
    return
  }
  translate.value = translate.value + slideDistance.value
}
</script>
