<template>
  <svg
    width="21px"
    height="15px"
    viewBox="0 0 21 15"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1">
    <!-- Generated by Pixelmator Pro 2.1.2 -->
    <defs>
      <image
        id="image"
        width="21px"
        height="15px"
        xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAPCAYAAAALWoRrAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAFaADAAQAAAABAAAADwAAAAAiS50tAAABDElEQVQ4Ea2Ua2vCMBSG395s7bx92Adn8VZw4v//MbLJRB2CIE6Qbd20zUyiDT2gK6keKM17Lk/OaUOMP264s9mCF0W/2H1+IT4khfG2Y6FWeUC57EJCV+st4jguDJSFP8B3tEe/04QpHDcDz+2kHNnp2Qff89KlfO9592kiCeQIAg1ajyR9s93hgz+6JsfXLcrLJ1DG6OlijOXVX4yT8d9my4tJuk7SqW7xtXzS6fhlhiQ5jTwI25jOl0o/Dzp4nSwkx7JMhN0Ak+m70qNhT+1BoN32kwq4noOsLjk2wl5A4lmtAnwhoaZpQPwk33ezMW0tOMLkN23Uq0gdhKohRL3gCDP4JVXs3Pyz4RED3kzjX15EagAAAABJRU5ErkJggg==" />
    </defs>
    <use id="Background-Layer" xlink:href="#image" x="0px" y="0px" width="21px" height="15px" />
  </svg>
</template>

<script>
export default {
  name: 'CardNumberIcon'
}
</script>

<style scoped></style>
