<template>
  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 11.995C10.125 11.995 9.7675 11.785 9.595 11.425C9.3575 10.925 9.57 10.33 10.07 10.0925C11.545 9.3925 12.5 7.885 12.5 6.25C12.5 3.9075 10.5925 2 8.25 2C5.9075 2 4 3.9075 4 6.25C4 7.8425 4.88 9.29 6.295 10.025C6.785 10.28 6.975 10.8825 6.7225 11.3725C6.4675 11.8625 5.865 12.0525 5.375 11.8C3.2925 10.72 2 8.5925 2 6.25C2 2.8025 4.8025 0 8.25 0C11.6975 0 14.5 2.8025 14.5 6.25C14.5 8.65 13.0975 10.8675 10.93 11.8975C10.7925 11.9625 10.645 11.995 10.5025 11.995H10.5Z"
      fill="#0064FF" />
    <path
      d="M8.25 9.5C6.4575 9.5 5 8.0425 5 6.25C5 4.4575 6.4575 3 8.25 3C10.0425 3 11.5 4.4575 11.5 6.25C11.5 8.0425 10.0425 9.5 8.25 9.5ZM8.25 5C7.56 5 7 5.56 7 6.25C7 6.94 7.56 7.5 8.25 7.5C8.94 7.5 9.5 6.94 9.5 6.25C9.5 5.56 8.94 5 8.25 5Z"
      fill="#FF0000" />
    <path
      d="M8.25 16C7.6975 16 7.25 15.5525 7.25 15V8.5C7.25 7.9475 7.6975 7.5 8.25 7.5C8.8025 7.5 9.25 7.9475 9.25 8.5V15C9.25 15.5525 8.8025 16 8.25 16Z"
      fill="#FF0000" />
    <path
      d="M13.3402 16H3.16016C2.60766 16 2.16016 15.5525 2.16016 15C2.16016 14.4475 2.60766 14 3.16016 14H13.3402C13.8927 14 14.3402 14.4475 14.3402 15C14.3402 15.5525 13.8927 16 13.3402 16Z"
      fill="#0064FF" />
  </svg>
</template>

<script>
export default {
  name: 'PodcastIcon'
}
</script>

<style scoped></style>
