import * as jose from 'jose'

export default async (
  jweToken: string,
  secret: string,
): Promise<{ company_code: string; global_user_id: number }> => {
  // setup as privateKeyPem because thats what they gave me
  const privateKeyPem = `-----BEGIN PRIVATE KEY-----
${secret}
-----END PRIVATE KEY-----`
  const privateKey = await jose.importPKCS8(privateKeyPem, 'RSA-OAEP-256')
  const res = await jose.jwtDecrypt(jweToken, privateKey)
  return new Promise((resolve) =>
    resolve({
      company_code: res.payload.companycode as string,
      global_user_id: res.payload.globaluserid as string,
    }),
  )
}
