import getApiInstance from '@/services/Api'
const api = getApiInstance()
export default {
  getNotifications() {
    return api.Get('/notification')
  },

  deleteNotification(id) {
    return api.Delete(`/notification/${id}`)
  }
}
