<template>
  <div
    class="grid grid-flow-row md:grid-flow-col lg:justify-items-stretch gap-5 w-full max-w-[1240px] px-5 md:px-20 ">
    <div class="col-span-full mb-5 lg:col-span-1">
      <img
        src="@/assets/img/otivo-logo-white-text.png"
        class="w-full max-w-[100px] h-auto"
        alt="otivo-logo" />
    </div>
    <section v-for="(section, idx) in getLinksForWhitelabel()" :key="idx"
             class="row-start-auto col-span-full md:col-span-1 md:row-start-2 lg:row-start-1 lg:col-span-auto w-full md:mx-auto">
      <LinkTemplate
        :links="section"
        text-colour="blue-2"
        class="paragraph-3 flex flex-col md:w-full"
        header-colour="button-1 text-blue-4"
        :title="idx.toString()" />
    </section>
  </div>
</template>

<script setup lang="ts">
import { getLinksForWhitelabel } from '@/composables/getLinksForWhitelabel.ts'
import LinkTemplate from '@/components/Footer/LinkTemplate.vue'
</script>

<style scoped></style>
