import { PageContentType } from '@/types/GlobalTypes'
import ContentService from '@/services/ContentService'

interface ContentState {
  pageContent: PageContentType
}
const state = (): ContentState => ({
  pageContent: null,
})

const getters = {
  pageContent: (state) => state.pageContent,
}

const mutations = {
  UPDATE_CONTENT_DATA(state, value) {
    state.pageContent = value
  },
}

const actions = {
  async getContent({ commit }, pageRef: string) {
    await ContentService.getPageContent(pageRef).then((res) => {
      commit('UPDATE_CONTENT_DATA', res.data)
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
