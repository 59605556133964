<template>
  <!--  <modal v-if="cfsEditStaffModal" @close="closeModal">-->
  <!--    <template #header>-->
  <h4 class="mh4 md:h4">Edit staff account</h4>
  <!--    </template>-->
  <!--    <template #body>-->
  <div style="'padding: 40px'">
    <input-text
      class="my-4"
      name="cfs-staff-first-name"
      label="First name"
      :value="cfsStaff.first_name"
      @input="
        (first_name) => {
          cfsStaff.first_name = first_name
        }
      "
      place-holder="First name"
      :error="errors.first_name" />
    <input-text
      class="my-4"
      name="cfs-staff-last-name"
      label="Last name"
      :value="cfsStaff.last_name"
      @input="
        (last_name) => {
          cfsStaff.last_name = last_name
        }
      "
      place-holder="Last name"
      :error="errors.last_name" />
    <input-text
      class="my-4"
      name="cfs-staff-email"
      label="Email"
      :value="cfsStaff.email"
      @input="
        (email) => {
          cfsStaff.email = email
        }
      "
      place-holder="Email"
      :error="errors.email"
      type="email"
      :allow-digits="true" />
    <input-phone-number-with-country-code
      name="cfs-staff-phone-number"
      label="Phone number"
      v-model:phone-number="cfsStaff.mobile"
      v-model:country-code="cfsStaff.country_code"
      :error="errors.mobile"
      :only-australian-number="false" />

    <input-select-box
      class="my-4"
      name="staff-role-selection"
      label="Staff role"
      :select-input="mappedRole"
      @update-select-box="updateStaffRole"
      :options="[
        {
          label: '-',
          value: null,
          disabled: true,
        },
        {
          label: 'Guidance centre',
          value: 'callcentre',
        },
        {
          label: 'Back office',
          value: 'backoffice',
        },
      ]" />
  </div>
  <!--    </template>-->
  <!--    <template #footer>-->
  <div class="text-center">
    <OtivoButton
      :loading="loading"
      @click="createStaffAccount"
      colour="blue"
      class="m-auto text-center px-8 my-4">
      Edit staff account
    </OtivoButton>
  </div>
  <!--    </template>-->
  <!--  </modal>-->
</template>

<script>
import CustomButton from '../CustomButton/CustomButton'
import InputText from '../BasicInputs/InputText'
import InputSelectBox from '../BasicInputs/InputSelectBox'
import { validateEmailAddress, validatePhoneNumber } from '@/lib/ValidationHelper'
import { useTemporaryPortalStore } from '@/store/pinia/TemporaryPortalStore.ts'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import InputPhoneNumberWithCountryCode from '@/components/BasicInputs/InputPhoneNumberWithCountryCode.vue'
import OtivoButton from '@/components/OtivoButton.vue'

export default {
  name: 'CFSEditStaffModal',
  components: {
    OtivoButton,
    InputPhoneNumberWithCountryCode,
    InputSelectBox,
    InputText,
    CustomButton,
  },
  props: {
    staffObject: {
      type: Object,
      required: true,
    },
  },
  emits: ['edit-staff-member'],
  data() {
    return {
      loading: false,
      cfsStaff: {
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        country_code: '+61',
        role: null,
      },
      errors: {
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        role: '',
      },
    }
  },
  watch: {
    staffObject: {
      handler: function () {
        if (this.staffObject !== null && this.staffObject !== undefined) {
          const { country_code, phone_number: mobile } = this.extractCountryCodeAndNumber(
            this.staffObject.mobile,
          )
          this.cfsStaff = {
            ...this.staffObject,
            mobile,
            country_code,
          }
        } else {
          this.cfsStaff = {
            first_name: '',
            last_name: '',
            email: '',
            country_code: '+61',
            mobile: '',
            role: null,
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    mappedRole() {
      return this.cfsStaff.role === 'callcentre'
        ? {
            label: 'Guidance centre',
            value: 'callcentre',
          }
        : {
            label: 'Back office',
            value: 'backoffice',
          }
    },
  },
  methods: {
    async createStaffAccount() {
      const portalStore = useTemporaryPortalStore()
      this.loading = true
      if (this.validateStaffAccount) {
        const staffData = this.cfsStaff
        const mobile =
          staffData.mobile.charAt(0) === '0' ? staffData.mobile.slice(1) : staffData.mobile
        staffData.mobile = staffData.country_code + mobile
        await portalStore.editStaffMember(staffData)
        this.loading = false
        useModalStore().closeModal()
        // this.$emit('edit-staff-member', staffData)
        // this.closeModal()
      }
    },
    updateStaffRole(value) {
      this.cfsStaff.role = value
    },
    validateStaffAccount() {
      let valid = true
      if (this.cfsStaff.first_name === '') {
        this.errors.first_name = 'Please enter a first name'
        valid = false
      }
      if (this.cfsStaff.last_name === '') {
        this.errors.last_name = 'Please enter a last name'
        valid = false
      }
      if (!validateEmailAddress(this.cfsStaff.email)) {
        this.errors.email = 'Please provide a valid email'
        valid = false
      }
      if (!validatePhoneNumber(this.cfsStaff.mobile)) {
        this.errors.mobile = 'Please provide a valid mobile number'
        valid = false
      }
      if (this.cfsStaff.role === null) {
        this.errors.role = 'Please select a staff role'
        valid = false
      }
      return valid
    },

    extractCountryCodeAndNumber(number) {
      const result = {
        country_code: '+61',
        phone_number: '',
      }
      /**
       * If the number is not a phone number, return the result
       */
      if (!/[^a-zA-Z ]/.test(number)) {
        return result
      }
      if (number.startsWith('04')) {
        result.phone_number = number
        return result
      }
      const endIndex = number.startsWith('+') ? 3 : 2
      result.country_code = number.slice(0, endIndex)
      result.phone_number = number.slice(endIndex)
      return result
    },
  },
}
</script>

<style scoped></style>
