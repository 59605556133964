<template>
  <div id="overview-tiles" class="grid grid-cols-4 gap-[23px] py-[40px]">
    <div id="total-members" class="overview-tile !bg-blue-1 !border-blue-1">
      <div class="button-4 !text-white font-bold">
        TOTAL MEMBERS <span class="font-normal">(since inception)</span>
      </div>
      <h1 class="text-white mt-[10px]">
        {{ formatAsNumber(verifyMetrics.inception) }}
      </h1>
    </div>
    <div id="total-members" class="overview-tile">
      <div class="button-4 font-bold">Unverified Members</div>
      <h1 class="mt-[10px]">
        {{ formatAsNumber(verifyMetrics.unverified) }}
      </h1>
    </div>
    <div id="total-members" class="overview-tile">
      <div class="button-4 font-bold">Invalid Members</div>
      <h1 class="mt-[10px]">
        {{ formatAsNumber(verifyMetrics.invalid) }}
      </h1>
    </div>
    <div id="total-members" class="overview-tile">
      <div class="button-4 font-bold">Valid Members <span class="font-normal"></span></div>
      <h1 class="mt-[10px]">
        {{ formatAsNumber(verifyMetrics.valid) }}
      </h1>
    </div>
  </div>
</template>

<script setup lang="ts">
import formatAsNumber from '@/composables/formatAsNumber.ts'
import { useCallcentreReportingStore } from '@/store/pinia/CallcentrePortalReportingStore.ts'
import { computed, onMounted } from 'vue'

const reportingStore = useCallcentreReportingStore()

const verifyMetrics = computed(() => reportingStore.getVerificationMetrics)
// const tableTitle = ref('Unverified Members')

// const tableTitles = {
//   unverified: 'Unverified Members',
//   valid: 'Valid Members',
//   invalid: 'Invalid Members'
// }

// const filterByStatus = (title: string) => {
//   tableTitle.value = title
// }

onMounted(() => {
  reportingStore.getAccountVerificationMetrics()
})
</script>

<style scoped lang="scss">
.overview-tile {
  justify-content: space-between;
  color: var(--grey-1);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  //box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  border: 1px solid var(--blue-3);
  min-width: 250px;

  .button-4 {
    color: var(--grey-2);
  }
}
</style>
