<template>
  <svg height="13" width="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.7399 2.89011c-.90252-.84705-2.06939-1.40185-3.33096-1.58377-1.26156-.18191-2.55206.01854-3.68392.57223L2.34303.581483C2.27415.526013 2.1855.497 2.09481.500246c-.09069.003246-.17673.038513-.2409.098745-.06418.060231-.10175.140985-.10521.226103-.00346.085118.02745.168323.08655.232966l1.18735 1.1144c.02297.02176.04249.04651.05797.07347.00423.00715.00804.01391.01185.02145.03562.07035.04413.15012.02408.2257-.02006.07558-.06744.14228-.13405.18873-.06662.04645-.14835.06976-.23123.06596-.08289-.00381-.16181-.03449-.22328-.08681 0 0-.00338-.00239-.00508-.00398l-.00888-.00714-.67704-.63544c-.06856-.05719-.15782-.08764-.24951-.0851-.09168.00253-.17888.03785-.24374.09872-.06486.06088-.10249.14272-.10519.22877-.0027.08606.02974.16983.09067.23419l.67703.63543c.00297.00278.00466.00596.00762.00874l.00423.00477c.05619.05762.08924.13181.09346.20982.00422.07801-.02065.15496-.07034.21762-.04968.06266-.12108.10712-.2019.12573-.08083.0186-.16604.0102-.24098-.02377-.00769-.00331-.01518-.00703-.02243-.01112-.02876-.01452-.05525-.03269-.0787-.05402l-.16926-.15885c-.06888-.05547-.15753-.08449-.24822-.08124-.090688.00324-.176727.03851-.240902.09874-.064174.06023-.10175.14099-.105208.22611-.003459.08512.027454.16832.086554.23296l.363906.34155C.671284 5.18596.440968 6.22379.518653 7.26173.596339 8.29966.978985 9.29708 1.62411 10.1432c.64513.8462 1.52749 1.508 2.54912 1.912 1.02163.404 2.14256.5343 3.23834.3765s2.12353-.5975 2.96913-1.2703c.8456-.6729 1.476-1.55251 1.8212-2.54127.3452-.98877.3917-2.04797.1343-3.06003-.2574-1.01205-.8086-1.93735-1.5925-2.67317l-.0038.00318ZM9.04354 9.2584c-.08678.08169-.17802.1591-.27335.23193-.72801.55687-1.65901.82487-2.59451.74677-.93549-.0782-1.80146-.4962-2.41326-1.16501-.6118-.6688-.92102-1.53547-.86175-2.4152.05928-.87973.48238-1.70293 1.17908-2.29407.6967-.59113 1.61188-.90344 2.55041-.87033.93853.0331 1.82614.40901 2.4736 1.04757.64745.63855 1.00354 1.48923.99224 2.37066-.0112.88142-.38889 1.72386-1.05246 2.34768Z"
      :class="`fill-${fill1}`" />
    <path
      d="M10.7398 2.88931c-.90252-.84705-2.06939-1.40185-3.33096-1.58377-1.26156-.18191-2.55206.01854-3.68392.57223 0 0 .78029-.18984 1.15646-.24703 1.00131-.15293 2.0285-.00392 2.93388.42562.90538.42954 1.64232 1.11747 2.10487 1.96489.46257.84743.62687 1.81068.46937 2.75129-.1576.9406-.62882 1.81009-1.34605 2.48346-.08679.0817-.17803.15911-.27336.23194-.73535.59286-1.64498.96256-2.60881 1.06016-.96384.0976-1.93655-.0814-2.78972-.5135-.85318-.43197-1.54671-1.09668-1.98902-1.90632C.940217 7.31864.769902 6.40214.894077 5.49977c.055432-.4039.286043-1.23394.286043-1.23394C.667378 5.18834.437062 6.22617.514747 7.2641.592432 8.30203.975079 9.29945 1.6202 10.1456c.64513.8462 1.52749 1.508 2.54912 1.912 1.02164.4039 2.14257.5343 3.23835.3765 1.09577-.1578 2.12353-.5975 2.96913-1.2704.8456-.6728 1.476-1.55243 1.8212-2.5412.3452-.98876.3917-2.04797.1343-3.06002-.2574-1.01206-.8086-1.93736-1.5925-2.67317Z"
      :class="`fill-${fill2}`" />
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1: string
  fill2: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red',
})
</script>

<style scoped></style>
