<template>
  <div id="fitness-test-questions">
    <h3>Free financial fitness test</h3>

    <div class="py-6 leading-normal">
      Complete the questions below to get your financial fitness score and see how you measure up
      and against other Aussies.
    </div>
    <form @submit.prevent="saveFitnessTest()">
      <living-situation-status
        class="py-4 select-none"
        question-number="1"
        key="question-1"
        id="question-1"
        :class="{ 'label-opacity-50': counter !== 1 }" />

      <can-pay-bills
        class="py-4 select-none"
        question-number="2"
        key="question-2"
        id="question-2"
        :class="{ 'label-opacity-50': counter !== 2 }"
        v-if="counter > 1" />

      <can-pay-credit-card
        class="py-4 select-none"
        question-number="3"
        key="question-3"
        id="question-3"
        :class="{ 'label-opacity-50': counter !== 3 }"
        v-if="counter > 2" />

      <can-cover-expenses
        class="py-4 select-none"
        question-number="4"
        key="question-4"
        id="question-4"
        :class="{ 'label-opacity-50': counter !== 4 }"
        v-if="counter > 3" />

      <have-health-insurance
        class="py-4 select-none"
        question-number="5"
        key="question-5"
        id="question-5"
        :class="{ 'label-opacity-50': counter !== 5 }"
        v-if="counter > 4" />

      <have-car-insurance
        class="py-4 select-none"
        question-number="6"
        key="question-6"
        id="question-6"
        :class="{ 'label-opacity-50': counter !== 6 }"
        v-if="counter > 5" />

      <have-life-insurance
        class="py-4 select-none"
        question-number="7"
        key="question-7"
        id="question-7"
        :class="{ 'label-opacity-50': counter !== 7 }"
        v-if="counter > 6" />

      <have-income-protection
        class="py-4 select-none"
        question-number="8"
        key="question-8"
        id="question-8"
        :class="{ 'label-opacity-50': counter !== 8 }"
        v-if="counter > 7" />

      <have-trauma-insurance
        class="py-4 select-none"
        question-number="9"
        key="question-9"
        id="question-9"
        :class="{ 'label-opacity-50': counter !== 9 }"
        v-if="counter > 8" />

      <have-home-insurance
        class="py-4 select-none"
        question-number="10"
        key="question-10"
        id="question-10"
        :class="{ 'label-opacity-50': counter !== 10 }"
        v-if="counter > 9" />

      <enough-financial-knowledge
        class="py-4 select-none"
        question-number="11"
        key="question-11"
        id="question-11"
        :class="{ 'label-opacity-50': counter !== 11 }"
        v-if="counter > 10" />

      <on-track-financial-goal
        class="py-4 select-none"
        question-number="12"
        key="question-12"
        id="question-12"
        :class="{ 'label-opacity-50': counter !== 12 }"
        v-if="counter > 11" />

      <enough-for-retirement
        class="py-4 select-none"
        question-number="13"
        key="question-13"
        id="question-13"
        :class="{ 'label-opacity-50': counter !== 13 }"
        v-if="counter > 12" />

      <comfortable-with-savings-and-investments
        class="py-4 select-none"
        question-number="14"
        key="question-14"
        id="question-14"
        :class="{ 'label-opacity-50': counter !== 14 }"
        v-if="counter > 13" />

      <have-financial-plan
        class="py-4 select-none"
        question-number="15"
        key="question-15"
        id="question-15"
        :class="{ 'label-opacity-50': counter !== 15 }"
        v-if="counter > 14" />

      <enough-money-for-things
        class="py-4 select-none"
        question-number="16"
        key="question-16"
        id="question-16"
        :class="{ 'label-opacity-50': counter !== 16 }"
        v-if="counter > 15" />

      <current-financial-situation
        class="py-4 select-none"
        question-number="17"
        key="question-17"
        id="question-17"
        :class="{ 'label-opacity-50': counter !== 17 }"
        v-if="counter > 16" />

      <div v-if="counter > 17" class="font-medium text-16px py-6">Demographics</div>

      <age-group
        class="py-4 select-none"
        question-number="18"
        key="question-18"
        id="question-18"
        :class="{ 'label-opacity-50': counter !== 18 }"
        v-if="counter > 17" />

      <user-gender
        class="py-4 select-none"
        question-number="19"
        key="question-19"
        id="question-19"
        :class="{ 'label-opacity-50': counter !== 19 }"
        v-if="counter > 18" />

      <user-location
        class="py-4 select-none"
        question-number="20"
        key="question-20"
        id="question-20"
        :class="{ 'label-opacity-50': counter !== 20 }"
        v-if="counter > 19"
        :error="postcodeError" />

      <sun-corp-division
        class="py-4 select-none"
        question-number="21"
        key="question-21"
        id="question-21"
        :class="{ 'label-opacity-50': counter !== 21 }"
        v-if="counter > 20 && isItSuncorpstaff" />

      <medibank-division-question
        class="py-4 select-none"
        question-number="21"
        key="question-21"
        id="question-21"
        :class="{ 'label-opacity-50': counter !== 21 }"
        v-if="counter > 20 && isItMedibankstaff" />

      <sun-corp-income-bracket
        class="py-4 select-none"
        question-number="22"
        key="question-22"
        id="question-22"
        :class="{ 'label-opacity-50': counter !== 22 }"
        v-if="counter > 21 && isItSuncorpstaff" />

      <div class="text-center pt-8">
        <custom-button
          v-if="counter > fitnessTest.totalQuestions"
          class="bg-otivo-blue p-4 text-white font-medium w-64 appearance-none focus:outline-none"
          type="submit"
          :loading="loading"
          :disabled="disableSubmitButton">
          Calculate my score
        </custom-button>

        <div v-else>
          <div class="text-center">
            <label>Progress</label>
          </div>
          <div class="flex">
            <div class="self-center">1</div>

            <div class="flex m-2 bg-grey-light rounded flex-grow self-center">
              <div class="bg-otivo-blue h-3 rounded" :style="{ width: getProgressBarWidth }" />
            </div>

            <div class="self-center">
              {{ fitnessTest.totalQuestions }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import CanPayBills from './CanPayBills/CanPayBills'
import CanPayCreditCard from './CanPayCreditCard/CanPayCreditCard'
import CanCoverExpenses from './CanCoverExpenses/CanCoverExpenses'
import HaveHealthInsurance from './HaveHealthInsurance/HaveHealthInsurance'
import HaveLifeInsurance from './HaveLifeInsurance/HaveLifeInsurance'
import HaveIncomeProtection from './HaveIncomeProtection/HaveIncomeProtection'
import HaveCarInsurance from './HaveCarInsurance/HaveCarInsurance'
import HaveTraumaInsurance from './HaveTraumaInsurance/HaveTraumaInsurance'
import HaveHomeInsurance from './HaveHomeInsurance/HaveHomeInsurance'
import EnoughFinancialKnowledge from './EnoughFinancialKnowledge/EnoughFinancialKnowledge'
import OnTrackFinancialGoal from './OnTrackFinancialGoal/OnTrackFinancialGoal'
import EnoughForRetirement from './EnoughForRetirement/EnoughForRetirement'
import ComfortableWithSavingsAndInvestments from './ComfortableWithSavingsAndInvestements/ComfortableWithSavingsAndInvestments'
import HaveFinancialPlan from './HaveFinancialPlan/HaveFinancialPlan'
import EnoughMoneyForThings from './EnoughMoneyForThings/EnoughMoneyForThings'
import CurrentFinancialSituation from './CurrentFinancialSituation/CurrentFinancialSituation'
import AgeGroup from './AgeGroup/AgeGroup'
import UserGender from './UserGender/UserGender'
import UserLocation from './UserLocation/UserLocation'
import LivingSituationStatus from './LivingSituationStatus/LivingSituationStatus'
import CustomButton from '../CustomButton/CustomButton.vue'

import { useStore } from 'vuex'
import SunCorpDivision from './SunCorpFitnessTestQuestions/SunCorpDivision'
import SunCorpIncomeBracket from './SunCorpFitnessTestQuestions/SunCorpIncomeBracket'
import MedibankDivisionQuestion from './MedibankDivisionQuestion/MedibankDivisionQuestion'
import { validSurmountCompany } from '@/lib/ValidationHelper'
import { scrollToBottom } from '@/lib/helpers'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useToast } from '@/composables/useToast.ts'

const loading = ref(false)
const postcodeError = ref('')

const store = useStore()
const { errorToast } = useToast()
const emit = defineEmits(['revealResults'])

onMounted(() => {
  if (isItSuncorpstaff.value) {
    store.commit('SET_TOTAL_FITNESS_TEST_QUESTIONS', 22)
  } else if (isItMedibankstaff.value) {
    store.commit('SET_TOTAL_FITNESS_TEST_QUESTIONS', 21)
  } else if (disableSubmitButton.value) {
    store.dispatch('setSignUpCompanyName', 'mt_isa')
  }
})

const isItSuncorpstaff = computed(() => store.getters.isItSuncorpstaff)
const isItMedibankstaff = computed(() => store.getters.isItMedibankstaff)
const fitnessTest = computed(() => store.getters.fitnessTest)
const counter = computed(() => store.getters.getQuestionCounter)
const disableSubmitButton = computed(
  () =>
    store.getters.isItSurmount && !validSurmountCompany(fitnessTest.value.questions.companyName),
)
const getProgressBarWidth = computed(() => {
  let percentage = Math.trunc((counter.value / fitnessTest.value.totalQuestions) * 100)
  if (percentage === 100) {
    percentage = 99
  }
  return [`${percentage}%`]
})

watch(counter, () => scrollToBottom('fitness-test'))

const saveFitnessTest = () => {
  loading.value = true
  if (fitnessTest.value.questions.postcode_id !== '') {
    store
      .dispatch('calculateFitnessScore')
      .then(() => {
        loading.value = false
        emit('revealResults')
        nextTick(() => window.scrollTo({ left: 0, top: 0, behavior: 'smooth' }))
      })
      .catch((err) => {
        loading.value = false
        errorToast('Error occurred, please try again')
        throw err
      })
  } else {
    postcodeError.value = 'Please select a valid postcode'
    loading.value = false
  }
}
</script>
