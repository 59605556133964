import { reactive } from 'vue'

// Types for validation rules
type ValidationRule<T> = {
  required?: boolean | ((context: any) => boolean)
  validate: (value: T) => boolean
  message: string
}

type ValidationRules<T> = {
  [K in keyof T]?: ValidationRule<T[K]>
}

interface ValidationContext {
  [key: string]: any
}

interface ValidationResult<T> {
  isValid: boolean
  errors: { [K in keyof T]: string }
}

// Common validation patterns that can be reused
export const commonValidators = {
  email: (value: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(value)
  },

  date: (value: string) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/
    if (!dateRegex.test(value)) return false
    const date = new Date(value)
    return !isNaN(date)
  },

  mobile: (value: string) => {
    const cleanNumber = value.replace(/\s+/g, '')
    return (
      /^\d{10}$/.test(cleanNumber) ||
      /^\+61\d{9}$/.test(cleanNumber) ||
      /^61\d{9}$/.test(cleanNumber)
    )
  },

  numeric: (value: string) => /^\d+$/.test(value),

  required: (value: any) => {
    if (typeof value === 'string') return value.trim().length > 0
    return value !== null && value !== undefined
  },
}

/**
 * Creates a form validator composable
 * @param errorStructure - Object that defines the structure of form errors
 * @param validationRules - Optional validation rules for fields
 */
export function useFormValidator<T extends { [key: string]: string }>(
  errorStructure: T,
  validationRules?: ValidationRules<T>,
) {
  // Create reactive error object
  const formErrors = reactive<T>({ ...errorStructure })

  /**
   * Validates a form object against defined rules
   * @param form - The form data to validate
   */
  const validateForm = (form: T): ValidationResult<T> => {
    let isValid = true

    // Clear all previous errors
    Object.keys(formErrors).forEach((key) => {
      formErrors[key as keyof T] = ''
    })

    // If no validation rules provided, return valid
    if (!validationRules) {
      return { isValid: true, errors: { ...formErrors } }
    }

    // Validate each field that has rules
    Object.entries(validationRules).forEach(([fieldName, rules]) => {
      if (!rules) return

      const value = form[fieldName as keyof T]

      if (!commonValidators.required(value) && rules.required) {
        formErrors[fieldName as keyof T] = 'This field is required'
        isValid = false
        return
      }

      if (!rules.validate(value) && rules.required) {
        formErrors[fieldName as keyof T] = rules.message
        isValid = false
      }
    })

    return {
      isValid,
      errors: { ...formErrors },
    }
  }

  return {
    formErrors,
    validateForm,
  }
}
