<template>
  <label v-bind="$attrs" :class="{ required: required }" v-html="label" />
</template>

<script setup lang="ts">
type LabelProps = {
  label: string
  required?: boolean
}

withDefaults(defineProps<LabelProps>(), {
  required: false
})
</script>
