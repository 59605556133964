<template>
  <!--  gap-3 deliberately omitted here because sometimes this component will be placed side by side-->
  <!--  Just place in the location where you call this component-->
  <basic-input :class="{ 'md:flex-col': stackLabel }">
    <template #input-label>
      <div
        v-if="label !== ''"
        class="pt-4 paragraph-2"
        :class="{ 'set-w-28 md:set-w-48': !stackLabel }">
        <label :for="name">
          {{ this.capitalize(label) }}
        </label>
        <info-circle class="ml-2 inline" :message="title" />
      </div>
    </template>
    <template #input-box>
      <input
        autocomplete="off"
        class="w-full p-4 border ring-2 rounded paragraph-2"
        :class="[applyColor]"
        :id="name"
        type="text"
        :placeholder="placeHolder"
        :value="getValue()"
        @focus="inputFocused($event)"
        @input="setEventValue($event)"
        @blur="checkMinMax($event)"
        inputmode="decimal"
        :disabled="disabled" />
    </template>
    <template #input-errors>
      <div v-if="error">
        <input-error :error="error" />
        <input-info
          v-if="dataInfo !== '' || info !== ''"
          :info="dataInfo !== '' ? dataInfo : info" />
      </div>
    </template>
  </basic-input>
</template>

<script>
import InputError from '@/components/BasicInputs/InputError.vue'
import { formatPercentage, capitalize as fc } from '@/lib/helpers'
import InfoCircle from '@/components/InfoCircle/InfoCircle.vue'
import InputInfo from '@/components/BasicInputs/InputInfo.vue'
import BasicInput from '@/components/BasicInputs/BasicInput.vue'
export default {
  name: 'InputPercentage',
  components: { BasicInput, InputInfo, InfoCircle, InputError },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      default: null,
    },
    error: {
      type: String,
      default: '',
    },
    placeHolder: {
      type: String,
      default: '0%',
    },
    title: {
      type: String,
      default: '',
    },
    info: {
      type: String,
      default: '',
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    smallInput: {
      type: Boolean,
      default: false,
    },
    stackLabel: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    inputClasses: {
      type: String,
      default: '',
    },
  },
  emits: ['input', 'focus', 'blur', 'mounted'],
  data() {
    return {
      type: 'text',
      touched: false,
      dataInfo: '',
    }
  },
  watch: {
    type() {
      this.getValue()
    },
  },
  computed: {
    applyColor() {
      if (this.error || (this.value === null && this.required)) {
        return (
          this.inputClasses + ` border-red-dark ring-red-light ${this.smallInput ? 'p-2' : 'p-4'}`
        )
      } else {
        return (
          this.inputClasses +
          ` ring-transparent border-grey-2 focus:border-otivo-blue focus:ring-blue-4 ${
            this.smallInput ? 'p-2' : 'p-4'
          }`
        )
      }
    },
  },
  methods: {
    getValue() {
      if (isNaN(this.value) || this.value === null) {
        return ''
      }
      return this.type === 'text' ? formatPercentage(this.value) : this.value
    },
    setEventValue(event) {
      this.dataInfo = ''
      let value = event.target.value.replace(/[^0-9-.]+/g, '')

      if (isNaN(value)) {
        value = 0
      } else if (value > 100) {
        value = 100
      } else if (value < 0) {
        value = 1
      }
      event.target.value = value
      this.$emit('input', Math.abs(value))
    },

    inputFocused(event) {
      this.type = 'number'
      this.$emit('focus', event)
    },

    checkMinMax(event) {
      this.type = 'text'
      if (this.value > this.max) {
        this.dataInfo = `Max ${formatPercentage(this.max)}`
        this.$emit('input', this.max)
      }

      if (this.value < this.min) {
        this.dataInfo = `Min ${formatPercentage(this.min)}`
        this.$emit('input', this.min)
      }

      this.$emit('blur', event)
    },
    capitalize(value) {
      return fc(value)
    },
  },
  mounted() {
    this.$emit('mounted')
  },
}
</script>

<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:disabled {
  @apply bg-grey-light ring-0 border-none rounded text-grey-2;
}

/* To prevent the default outline */
input:focus {
  outline: none !important;
}
</style>
