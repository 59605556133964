<template>
  <div
    class="border border-blue-3 rounded-[10px] overflow-hidden md:grid"
    :style="'grid-template-columns: repeat(' + numberOfColumns + ', minmax(0, 1fr));'">
    <!-- Grid template columns style used as tailwind didn't want to generate   -->
    <div
      v-for="(tableContent, index) in tableData"
      :key="index"
      class="flex justify-between items-center p-[20px] border-r border-blue-5 h-[80px] md:border-t"
      :class="{
        'bg-blue-5': tableContent.fieldType === 'heading',
        'border-t': (index - 1) % numberOfColumns === 0,
      }">
      <p
        v-if="tableContent.fieldType === 'heading'"
        class="button-1 text-blue-1 md:border-blue-3 flex items-center">
        {{ tableContent.component }}
      </p>
      <div
        v-else-if="tableContent.fieldType === 'html'"
        class="button-1 text-grey-1"
        v-html="tableContent.component" />
      <component
        v-else-if="tableContent"
        :is="tableContent.component"
        :value="tableContent.value"
        :name="tableContent.name"
        :type="tableContent.fieldType"
        :class="tableContent.class"
        :disabled="tableContent.disabled"
        @input="$emit('input', $event.target.value, tableContent.name)" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { TableDataType } from '@/types/CallcentrePortalTypes.ts'

defineEmits(['input'])
defineProps<{
  tableData: Array<TableDataType>
  numberOfColumns: number
}>()
</script>

<style scoped></style>
