<template>
  <div>
    <div class="flex flex-col text-white text-center items-center max-w-[1280px]">
      <img width="460" height="92" :src="NineOutOfTenPeople" alt="9 in 10 people highlighted" />
      <div class="mh3 md:h3 mt-[40px]">
        9 out of 10 Australians want advice from their super fund<sup>*</sup>
      </div>
      <p class="pt-[20px] intro-1">
        And with five million people set to access super over the next decade, there’s no better
        time for forward-thinking super funds to team up with Otivo's tried-and-tested licensed
        advice.
      </p>
      <OtivoButton colour="white" size="large" class="mt-[40px] w-fit" @click="goToReport()">
        Download our free Super Report
      </OtivoButton>
      <p class="mt-[40px] paragraph-2">
        *Otivo research has found that almost 9 in 10 (86%) Australians would like their
        superannuation fund to offer financial advice.
      </p>
    </div>
  </div>
</template>
<script setup lang="ts">
import OtivoButton from '@/components/OtivoButton.vue'
import NineOutOfTenPeople from '@/components/SVGS/NineOutOfTenPeople.svg?url'
import { useRouter } from 'vue-router'

const router = useRouter()
const goToReport = () => {
  router.push('/super-report')
}
</script>

<style scoped></style>
