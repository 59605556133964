<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.6675 5.83286C7.19146 5.5887 6.68036 5.42002 6.1525 5.33286C6.69722 5.12301 7.15133 4.72915 7.4361 4.21957C7.72087 3.70999 7.81834 3.11683 7.71161 2.54292C7.60488 1.96901 7.30069 1.45055 6.85176 1.07741C6.40284 0.704276 5.8375 0.5 5.25375 0.5C4.67 0.5 4.10466 0.704276 3.65574 1.07741C3.20681 1.45055 2.90262 1.96901 2.79589 2.54292C2.68916 3.11683 2.78663 3.70999 3.0714 4.21957C3.35617 4.72915 3.81028 5.12301 4.355 5.33286C3.13814 5.54339 2.03444 6.17614 1.23793 7.11987C0.441424 8.06361 0.00309376 9.25793 0 10.4929C0 10.7581 0.105357 11.0124 0.292893 11.2C0.48043 11.3875 0.734784 11.4929 1 11.4929H9.5C9.76522 11.4929 10.0196 11.3875 10.2071 11.2C10.3946 11.0124 10.5 10.7581 10.5 10.4929C10.5019 9.53079 10.2385 8.58681 9.73882 7.76469C9.23911 6.94257 8.52241 6.27414 7.6675 5.83286ZM5.25 2.49286C5.34889 2.49286 5.44556 2.52219 5.52779 2.57713C5.61001 2.63207 5.6741 2.71016 5.71194 2.80152C5.74978 2.89288 5.75969 2.99342 5.74039 3.09041C5.7211 3.1874 5.67348 3.27649 5.60355 3.34642C5.53363 3.41634 5.44454 3.46396 5.34755 3.48326C5.25056 3.50255 5.15002 3.49265 5.05866 3.4548C4.9673 3.41696 4.88921 3.35287 4.83426 3.27065C4.77932 3.18842 4.75 3.09175 4.75 2.99286C4.75 2.86025 4.80268 2.73308 4.89645 2.63931C4.99021 2.54554 5.11739 2.49286 5.25 2.49286ZM2.1575 9.49286C2.36893 8.83906 2.78213 8.2691 3.33777 7.86484C3.8934 7.46058 4.56287 7.24283 5.25 7.24286C5.77199 7.24033 6.28677 7.36473 6.75 7.60536C7.50945 7.99997 8.0826 8.67822 8.345 9.49286H2.1575Z"
      fill="#FF0000" />
    <path
      d="M11.645 9.57536C12.1904 9.36626 12.6453 8.97279 12.9309 8.46326C13.2164 7.95372 13.3146 7.36029 13.2082 6.78595C13.1019 6.21162 12.7978 5.69265 12.3488 5.31911C11.8997 4.94558 11.3341 4.74106 10.75 4.74106C10.1659 4.74106 9.60025 4.94558 9.15121 5.31911C8.70217 5.69265 8.3981 6.21162 8.29177 6.78595C8.18544 7.36029 8.28357 7.95372 8.56912 8.46326C8.85467 8.97279 9.30962 9.36626 9.855 9.57536C8.63687 9.78611 7.53219 10.42 6.73552 11.3652C5.93886 12.3105 5.50133 13.5066 5.5 14.7429C5.5 15.0081 5.60536 15.2624 5.79289 15.45C5.98043 15.6375 6.23478 15.7429 6.5 15.7429H15C15.2652 15.7429 15.5196 15.6375 15.7071 15.45C15.8946 15.2624 16 15.0081 16 14.7429C15.9987 13.5066 15.5611 12.3105 14.7645 11.3652C13.9678 10.42 12.8631 9.78611 11.645 9.57536V9.57536ZM10.75 6.74286C10.8489 6.74286 10.9456 6.77219 11.0278 6.82713C11.11 6.88207 11.1741 6.96016 11.2119 7.05152C11.2498 7.14288 11.2597 7.24342 11.2404 7.34041C11.2211 7.4374 11.1735 7.52649 11.1036 7.59642C11.0336 7.66634 10.9445 7.71396 10.8475 7.73326C10.7506 7.75255 10.65 7.74265 10.5587 7.7048C10.4673 7.66696 10.3892 7.60287 10.3343 7.52065C10.2793 7.43842 10.25 7.34175 10.25 7.24286C10.25 7.11025 10.3027 6.98308 10.3964 6.88931C10.4902 6.79554 10.6174 6.74286 10.75 6.74286ZM7.6575 13.7429C7.86883 13.089 8.282 12.5189 8.83764 12.1145C9.39328 11.7102 10.0628 11.4923 10.75 11.4923C11.4372 11.4923 12.1067 11.7102 12.6624 12.1145C13.218 12.5189 13.6312 13.089 13.8425 13.7429H7.6575Z"
      fill="#0064FF" />
  </svg>
</template>

<script>
export default {
  name: 'PartnerIcon'
}
</script>

<style scoped></style>
