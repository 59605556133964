<template>
  <BaseView class="bg-blue-6">
    <div class="flex flex-col flex-grow items-center">
      <div class="flex mt-15 md:mt-[120px]">
        <AccountCreationProgress :complete-percentage="progress" />
      </div>
      <div
        class="container flex flex-col px-5 md:px-10 w-100% h-100% py-5 sm:py-10 md:py-20 items-center">
        <RouterView @next="handleNext"></RouterView>
      </div>
    </div>

    <CustomFooter class="bg-midnight text-blue-4 relative w-full md:pt-4 z-10" />
  </BaseView>
</template>

<script setup lang="ts">
import AccountCreationProgress from '@/components/CreateAccount/Partials/AccountCreationProgress.vue'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
import CustomFooter from '@/components/Footer/CustomFooter.vue'
import BaseView from '@/views/BaseView.vue'

const router = useRouter()

// handling forward and back navigation for percentage bar
const progress = computed(() => {
  let value = 5
  switch (router.currentRoute.value.name) {
    case 'DeactivateAccount':
      return 33
    case 'DeactivateSurvey':
      return 66
    case 'DeactivateFarewell':
      return 100
  }
  return value
})

const handleNext = (val: string | number) => {
  switch (val) {
    case 'deactivate account':
      router.push({ name: 'DeactivateSurvey' })
      break

    case 'survey complete':
      router.push({ name: 'DeactivateFarewell' })
      break

    default:
      throw new Error('Error handling next step in deactivate account')
  }
}
</script>

<style scoped></style>
