<template>
  <modal v-if="suncorpContactUsForm" @close="toggleShowModal">
    <template #header>
      <h4>Contact us</h4>
    </template>
    <template #body>
      <sun-corp-contact-us-form class="text-left" @close="toggleShowModal" />
    </template>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from '@/components/Modals/Modal.vue'
import SunCorpContactUsForm from '@/components/BrandComponents/ContactUs/SunCorpContactUsForm.vue'
export default {
  name: 'SuncorpContactUsModal',
  components: { SunCorpContactUsForm, Modal },
  computed: {
    ...mapGetters(['suncorpContactUsForm']),
  },
  methods: {
    toggleShowModal() {
      this.$store.commit('SUNCORP_CONTACT_US_MODAL', !this.suncorpContactUsForm)
    },
  },
}
</script>

<style scoped></style>
