const state = () => ({
  opened: false,
  showNavBar: true,
  soaModal: false,
  showContactUsModal: false,
  showBrochureModal: false,
  showPricingModal: false,
  showHomepageButtons: false,
  customerHomepage: true,
  adviserContactModal: false,
  suncorpContactUsForm: false
})

const getters = {
  suncorpContactUsForm: (state) => state.suncorpContactUsForm,
  showNavBar: (state) => state.showNavBar,
  showContactUsModal: (state) => state.showContactUsModal,
  adviserContactModal: (state) => state.adviserContactModal
}

const mutations = {
  TOGGLE_SIDEBAR(state) {
    state.opened = !state.opened
  },
  CLOSE_SIDEBAR(state) {
    state.opened = false
  },
  SET_SHOW_NAV_BAR(state, value) {
    state.showNavBar = value
  },
  RENDER_SOA_MODAL(state, value) {
    state.soaModal = value
  },
  RENDER_CONTACT_US_MODAL(state, value) {
    state.showContactUsModal = value
  },
  RENDER_BROCHURE_MODAL(state, value) {
    state.showBrochureModal = value
  },
  RENDER_PRICING_MODAL(state, value) {
    state.showPricingModal = value
  },
  RENDER_HOMEPAGE_BUTTONS(state, value) {
    state.showHomepageButtons = value
  },
  TOGGLE_ADVISER_CONTACT_MODAL(state) {
    state.adviserContactModal = !state.adviserContactModal
  },
  SUNCORP_CONTACT_US_MODAL(state, value) {
    state.suncorpContactUsForm = value
  }
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }) {
    commit('CLOSE_SIDEBAR')
  },
  setShowNavBar({ commit }, value) {
    commit('SET_SHOW_NAV_BAR', value)
  },

  renderSoaModal({ commit }, value) {
    commit('RENDER_SOA_MODAL', value)
  },

  renderContactUsModal({ commit }, value) {
    commit('RENDER_CONTACT_US_MODAL', value)
  },

  renderPricingModal({ commit }, value) {
    commit('RENDER_PRICING_MODAL', value)
  },

  renderHomepageButtons({ commit }, value) {
    commit('RENDER_HOMEPAGE_BUTTONS', value)
  },

  toggleAdviserContactModal({ commit }, value) {
    commit('TOGGLE_ADVISER_CONTACT_MODAL', value)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
