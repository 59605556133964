import ContentService from '@/services/ContentService'
import { modifyFitnessTestResultObject } from '@/lib/FitnessTestHelper'
import _ from 'lodash'

// TODO: RICH MAJOR REFACTOR, NAMESPACE STORE
const state = () => ({
  questionCounter: 1,
  totalQuestions: 20,
  questions: {
    canPayBills: '',
    canPayCreditCard: '',
    livingSituation: '',
    canCoverExpenses: '',
    haveHealthInsurance: '',
    haveCarInsurance: '',
    haveLifeInsurance: '',
    haveIncomeProtection: '',
    haveTraumaInsurance: '',
    haveHomeInsurance: '',
    enoughFinancialKnowledge: '',
    onTrackFinancialGoal: '',
    enoughForRetirement: '',
    comfortableWithSavingsAndInvestments: '',
    haveFinancialPlan: '',
    enoughMoneyForThings: '',
    currentFinancialSituation: '',
    ageGroup: '',
    userGender: '',
    postcode_id: '',
    state: '',

    // sun corp specific questions
    sunCorpDivision: '',
    sunCorpIncomeBracket: '',

    // medibank specific questions
    mediBankDivision: '',
    companyName: ''
  },

  resultResponse: {
    debtScore: 0,
    financialStressScore: 0,
    insuranceScore: 0,
    retireScore: 0,
    savingsScore: 0,
    score: 0,
    scorePercent: 0
  },

  counter: 1
})

const getters = {
  fitnessTest(state) {
    return state
  },
  getQuestionCounter: (state) => state.questionCounter,
  getFitnessTestPostcodeId(state) {
    return state.questions.postcode_id
  }
}

const mutations = {
  RESET_FITNESS_TEST(state) {
    const data = _.cloneDeep(state.questions)
    Object.keys(data).map((el) => {
      state.questions[el] = ''
    })
  },

  INCREASE_FITNESS_TEST_COUNTER(state) {
    if (state.questionCounter < state.totalQuestions) state.questionCounter++
  },

  SET_TOTAL_FITNESS_TEST_QUESTIONS(state, value) {
    state.totalQuestions = value
  },

  SET_FITNESS_TEST_CAN_PAY_BILLS(state, value) {
    if (state.questions.canPayBills === '' && value !== '') state.questionCounter++
    state.questions.canPayBills = value
  },

  SET_FITNESS_TEST_CAN_PAY_CREDIT_CARD(state, value) {
    if (state.questions.canPayCreditCard === '' && value !== '') state.questionCounter++
    state.questions.canPayCreditCard = value
  },

  SET_FITNESS_TEST_LIVING_SITUATION(state, value) {
    if (state.questions.livingSituation === '' && value !== '') state.questionCounter++
    state.questions.livingSituation = value
  },

  SET_FITNESS_TEST_CAN_COVER_EXPENSES(state, value) {
    if (state.questions.canCoverExpenses === '' && value !== '') state.questionCounter++
    state.questions.canCoverExpenses = value
  },

  SET_FITNESS_TEST_HAVE_HEALTH_INSURANCE(state, value) {
    if (state.questions.haveHealthInsurance === '' && value !== '') state.questionCounter++
    state.questions.haveHealthInsurance = value
  },

  SET_FITNESS_TEST_HAVE_CAR_INSURANCE(state, value) {
    if (state.questions.haveCarInsurance === '' && value !== '') state.questionCounter++
    state.questions.haveCarInsurance = value
  },

  SET_FITNESS_TEST_HAVE_LIFE_INSURANCE(state, value) {
    if (state.questions.haveLifeInsurance === '' && value !== '') state.questionCounter++
    state.questions.haveLifeInsurance = value
  },

  SET_FITNESS_TEST_HAVE_INCOME_PROTECTION(state, value) {
    if (state.questions.haveIncomeProtection === '' && value !== '') state.questionCounter++
    state.questions.haveIncomeProtection = value
  },

  SET_FITNESS_TEST_HAVE_TRAUMA_INSURANCE(state, value) {
    if (state.questions.haveTraumaInsurance === '' && value !== '') state.questionCounter++
    state.questions.haveTraumaInsurance = value
  },

  SET_FITNESS_TEST_HAVE_HOME_INSURANCE(state, value) {
    if (state.questions.haveHomeInsurance === '' && value !== '') state.questionCounter++
    state.questions.haveHomeInsurance = value
  },

  SET_FITNESS_TEST_ENOUGH_FINANCIAL_KNOWLEDGE(state, value) {
    if (state.questions.enoughFinancialKnowledge === '' && value !== '') state.questionCounter++
    state.questions.enoughFinancialKnowledge = value
  },

  SET_FITNESS_TEST_ON_TRACK_FINANCIAL_GOAL(state, value) {
    if (state.questions.onTrackFinancialGoal === '' && value !== '') state.questionCounter++
    state.questions.onTrackFinancialGoal = value
  },

  SET_FITNESS_TEST_ENOUGH_FOR_RETIREMENT(state, value) {
    if (state.questions.enoughForRetirement === '' && value !== '') state.questionCounter++
    state.questions.enoughForRetirement = value
  },

  SET_FITNESS_TEST_COMFORTABLE_WITH_SAVINGS_AND_INVESTMENTS(state, value) {
    if (state.questions.comfortableWithSavingsAndInvestments === '' && value !== '')
      state.questionCounter++
    state.questions.comfortableWithSavingsAndInvestments = value
  },

  SET_FITNESS_TEST_HAVE_FINANCIAL_PLAN(state, value) {
    if (state.questions.haveFinancialPlan === '' && value !== '') state.questionCounter++
    state.questions.haveFinancialPlan = value
  },

  SET_FITNESS_TEST_MONEY_FOR_THINGS(state, value) {
    if (state.questions.enoughMoneyForThings === '' && value !== '') state.questionCounter++
    state.questions.enoughMoneyForThings = value
  },

  SET_FITNESS_TEST_CURRENT_FINANCIAL_SITUATION(state, value) {
    if (state.questions.currentFinancialSituation === '' && value !== '') state.questionCounter++
    state.questions.currentFinancialSituation = value
  },

  SET_FITNESS_TEST_AGE_GROUP(state, value) {
    if (state.questions.ageGroup === '' && value !== '') state.questionCounter++
    state.questions.ageGroup = value
  },

  SET_FITNESS_TEST_USER_GENDER(state, value) {
    if (state.questions.userGender === '' && value !== '') state.questionCounter++
    state.questions.userGender = value
  },

  SET_FITNESS_TEST_POSTCODE(state, value) {
    if (state.questions.postcode_id === '' && value !== '') state.questionCounter++
    state.questions.postcode_id = value
  },

  SET_FITNESS_TEST_STATE(state, value) {
    // if (state.questions.userLocation === '' && value !== '') state.questionCounter++
    state.questions.state = value
  },

  SET_FITNESS_TEST_SUN_CORP_DIVISION(state, value) {
    if (state.questions.sunCorpDivision === '' && value !== '') state.questionCounter++
    state.questions.sunCorpDivision = value
  },

  SET_FITNESS_TEST_SUN_CORP_INCOME_BRACKET(state, value) {
    if (state.questions.sunCorpIncomeBracket === '' && value !== '') state.questionCounter++
    state.questions.sunCorpIncomeBracket = value
  },

  SET_FITNESS_TEST_MEDI_BANK_DIVISION(state, value) {
    if (state.questions.mediBankDivision === '' && value !== '') state.questionCounter++
    state.questions.mediBankDivision = value
  },

  SET_FITNESS_TEST_COMPANY_NAME(state, value) {
    state.questions.companyName = value
  },

  SET_FITNESS_TEST_SCORE_DATA(state, res) {
    state.resultResponse = modifyFitnessTestResultObject({
      debtScore: res.data.data.debt_score,
      financialStressScore: res.data.data.financial_stress_score,
      insuranceScore: res.data.data.insurance_score,
      retireScore: res.data.data.retire_score,
      savingsScore: res.data.data.savings_score,
      scorePercent: res.data.data.score_percent
    })
  }
}

const actions = {
  resetFitnessTest({ commit }) {
    commit('RESET_FITNESS_TEST')
  },

  setFitnessTestCanPayBills({ commit }, value) {
    commit('SET_FITNESS_TEST_CAN_PAY_BILLS', value)
  },

  setFitnessTestCanPayCreditCard({ dispatch, commit }, value) {
    commit('SET_FITNESS_TEST_CAN_PAY_CREDIT_CARD', value)

    const keys = 'credit_card-pay_off'.split('-')
    dispatch('setSignUpQuestionAnswer', { keys: keys, value: value > 2 ? 'yes' : 'no' })
  },

  setFitnessTestLivingSituation({ dispatch, commit }, value) {
    commit('SET_FITNESS_TEST_LIVING_SITUATION', value)
    dispatch('updateLivingSituationType', value)
  },

  setFitnessTestCanCoverExpenses({ dispatch, commit }, value) {
    commit('SET_FITNESS_TEST_CAN_COVER_EXPENSES', value)

    const keys = 'expense-three_months'.split('-')
    dispatch('setSignUpQuestionAnswer', { keys: keys, value: value > 2 ? 'yes' : 'no' })
  },

  setFitnessTestHaveHealthInsurance({ commit }, value) {
    commit('SET_FITNESS_TEST_HAVE_HEALTH_INSURANCE', value)
  },

  setFitnessTestHaveCarInsurance({ commit }, value) {
    commit('SET_FITNESS_TEST_HAVE_CAR_INSURANCE', value)
  },

  setFitnessTestHaveLifeInsurance({ commit }, value) {
    commit('SET_FITNESS_TEST_HAVE_LIFE_INSURANCE', value)
  },

  setFitnessTestHaveIncomeProtection({ commit }, value) {
    commit('SET_FITNESS_TEST_HAVE_INCOME_PROTECTION', value)
  },

  setFitnessTestHaveTraumaInsurance({ commit }, value) {
    commit('SET_FITNESS_TEST_HAVE_TRAUMA_INSURANCE', value)
  },

  setFitnessTestHaveHomeInsurance({ commit }, value) {
    commit('SET_FITNESS_TEST_HAVE_HOME_INSURANCE', value)
  },

  setFitnessTestEnoughFinancialKnowledge({ commit }, value) {
    commit('SET_FITNESS_TEST_ENOUGH_FINANCIAL_KNOWLEDGE', value)
  },

  setFitnessTestOnTrackFinancialGoal({ commit }, value) {
    commit('SET_FITNESS_TEST_ON_TRACK_FINANCIAL_GOAL', value)
  },

  setFitnessTestEnoughForRetirement({ commit }, value) {
    commit('SET_FITNESS_TEST_ENOUGH_FOR_RETIREMENT', value)
  },

  setFitnessTestComfortableWithSavingsAndInvestments({ commit }, value) {
    commit('SET_FITNESS_TEST_COMFORTABLE_WITH_SAVINGS_AND_INVESTMENTS', value)
  },

  setFitnessTestHaveFinancialPlan({ commit }, value) {
    commit('SET_FITNESS_TEST_HAVE_FINANCIAL_PLAN', value)
  },

  setFitnessTestEnoughMoneyForThings({ commit }, value) {
    commit('SET_FITNESS_TEST_MONEY_FOR_THINGS', value)
  },

  setFitnessTestCurrentFinancialSituation({ commit }, value) {
    commit('SET_FITNESS_TEST_CURRENT_FINANCIAL_SITUATION', value)
  },

  setFitnessTestAgeGroup({ commit }, value) {
    commit('SET_FITNESS_TEST_AGE_GROUP', value)
  },

  setFitnessTestUserGender({ commit }, value) {
    commit('SET_FITNESS_TEST_USER_GENDER', value)
  },

  setFitnessTestPostcode({ commit }, value) {
    commit('SET_FITNESS_TEST_POSTCODE', value)
  },
  setFitnessTestState({ commit }, value) {
    commit('SET_FITNESS_TEST_STATE', value)
  },

  setFitnessTestSunCorpDivision({ commit }, value) {
    commit('SET_FITNESS_TEST_SUN_CORP_DIVISION', value)
  },

  setFitnessTestSunCorpIncomeBracket({ commit }, value) {
    commit('SET_FITNESS_TEST_SUN_CORP_INCOME_BRACKET', value)
  },

  setFitnessTestMediBankDivision({ commit }, value) {
    commit('SET_FITNESS_TEST_MEDI_BANK_DIVISION', value)
  },

  setFitnessTestCompanyName({ commit, dispatch }, value) {
    dispatch('setSignUpCompanyName', value)
    commit('SET_FITNESS_TEST_COMPANY_NAME', value)
    if (value !== '') commit('INCREASE_FITNESS_TEST_COUNTER')
  },

  calculateFitnessScore({ getters, rootGetters, commit }) {
    //  submit data with email

    const data = _.cloneDeep(getters.fitnessTest.questions)
    data.email = rootGetters.email
    data.counter = getters.questionCounter
    data.company_name = getters.fitnessTest.questions.companyName

    this.loading = true
    return ContentService.calculateFitnessTestScore(data).then((res) => {
      commit('SET_FITNESS_TEST_SCORE_DATA', res)
      return res
    })
  },

  setUserFitnessTestData({ dispatch }, payload) {
    if (payload.canPayBills) dispatch('setFitnessTestCanPayBills', payload.canPayBills)
    if (payload.canPayCreditCard)
      dispatch('setFitnessTestCanPayCreditCard', payload.canPayCreditCard)
    if (payload.livingSituation) dispatch('setFitnessTestLivingSituation', payload.livingSituation)
    if (payload.canCoverExpenses)
      dispatch('setFitnessTestCanCoverExpenses', payload.canCoverExpenses)
    if (payload.haveHealthInsurance)
      dispatch('setFitnessTestHaveHealthInsurance', payload.haveHealthInsurance)
    if (payload.haveCarInsurance)
      dispatch('setFitnessTestHaveCarInsurance', payload.haveCarInsurance)
    if (payload.haveLifeInsurance)
      dispatch('setFitnessTestHaveLifeInsurance', payload.haveLifeInsurance)
    if (payload.haveIncomeProtection)
      dispatch('setFitnessTestHaveIncomeProtection', payload.haveIncomeProtection)
    if (payload.haveTraumaInsurance)
      dispatch('setFitnessTestHaveTraumaInsurance', payload.haveTraumaInsurance)
    if (payload.haveHomeInsurance)
      dispatch('setFitnessTestHaveHomeInsurance', payload.haveHomeInsurance)
    if (payload.enoughFinancialKnowledge)
      dispatch('setFitnessTestEnoughFinancialKnowledge', payload.enoughFinancialKnowledge)
    if (payload.onTrackFinancialGoal)
      dispatch('setFitnessTestOnTrackFinancialGoal', payload.onTrackFinancialGoal)
    if (payload.enoughForRetirement)
      dispatch('setFitnessTestEnoughForRetirement', payload.enoughForRetirement)
    if (payload.comfortableWithSavingsAndInvestments)
      dispatch(
        'setFitnessTestComfortableWithSavingsAndInvestments',
        payload.comfortableWithSavingsAndInvestments
      )
    if (payload.haveFinancialPlan)
      dispatch('setFitnessTestHaveFinancialPlan', payload.haveFinancialPlan)
    if (payload.enoughMoneyForThings)
      dispatch('setFitnessTestEnoughMoneyForThings', payload.enoughMoneyForThings)
    if (payload.currentFinancialSituation)
      dispatch('setFitnessTestCurrentFinancialSituation', payload.currentFinancialSituation)
    if (payload.ageGroup) dispatch('setFitnessTestAgeGroup', payload.ageGroup)
    if (payload.userGender) dispatch('setFitnessTestUserGender', payload.userGender)
    if (payload.postcode_id) dispatch('setFitnessTestPostcode', payload.postcode_id)
    if (payload.state) dispatch('setFitnessTestState', payload.state)

    // sun corp specific questions
    if (payload.sunCorpDivision) dispatch('setFitnessTestSunCorpDivision', payload.sunCorpDivision)
    if (payload.sunCorpIncomeBracket)
      dispatch('setFitnessTestSunCorpIncomeBracket', payload.sunCorpIncomeBracket)

    // medibank specific questions
    if (payload.mediBankDivision)
      dispatch('setFitnessTestMediBankDivision', payload.mediBankDivision)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
