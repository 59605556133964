import PurchaseService from '@/services/PurchaseService'

const state = () => ({
  cart: {
    products: [],
    subtotal: 0.0,
    discount: {
      type: 'percent',
      amount: 0.0
    },
    total: 0.0,
    purchaseOrder: {
      attributes: {
        product_id: 0,
        quantity: 0,
        coupon: {
          id: 0,
          admin_user_id: '',
          product_id: 0,
          code: '',
          name: '',
          description: '',
          discount_figure: 0,
          validity: {
            id: 0,
            coupon_id: 0,
            valid_from: '',
            valid_to: '',
            number_of_coupons: 0
          }
        },
        whitelabel_id: 0,
        user_auth0id: '',
        product: {
          id: 0,
          name: '',
          code: '',
          whitelabel_id: 0,
          subscription: 0,
          duration: 0,
          monthly_price: 0,
          yearly_price: 0
        }
      },
      is_trial: false,
      transaction_cost: 0,
      payment_required: true,
      signed: ''
    }
  }
})

const getters = {
  getCart(state) {
    return state.cart
  },
  getTotal(state) {
    return state.cart.total
  },
  getSubTotal(state) {
    return state.cart.subtotal
  },
  getProducts(state) {
    return state.cart.products
  }
}

const mutations = {
  ADD_PRODUCT(state, payload) {
    const product = payload.product

    if (payload.type === 'monthly' || product.yearly_price === 0) {
      product.duration = 31
    } else if (payload.type === 'yearly' || product.monthly_price === 0) {
      product.duration = 365
    }

    const indexOf = state.cart.products.indexOf(product)
    if (indexOf < 0) {
      state.cart.products.push(product)
      state.cart.subtotal += product.duration === 31 ? product.monthly_price : product.yearly_price
      state.cart.total = state.cart.subtotal
    }
  },
  REMOVE_PRODUCT(state, product) {
    const indexOf = state.cart.products.indexOf(product)
    if (indexOf >= 0) {
      state.cart.products.splice(indexOf, 1)
      state.cart.subtotal -= product.duration === 31 ? product.monthly_price : product.yearly_price
      state.cart.total = state.cart.subtotal
    }
  },
  APPLY_DISCOUNT(state, coupon) {
    if (coupon && coupon.valid) {
      state.cart.discount.type = coupon.type
      state.cart.discount.amount = coupon.discount
      if (state.cart.total > 0 || state.cart.subtotal > 0) {
        const type = state.cart.discount.type
        if (type === 'percent') {
          state.cart.total = state.cart.subtotal * (1 - state.cart.discount.amount / 100)
        } else {
          const total = state.cart.subtotal - state.cart.discount.amount
          state.cart.total = total >= 0 ? total : 0.0
        }
      }
    } else {
      state.cart.total = state.cart.subtotal
      state.cart.discount.type = 'percent'
      state.cart.discount.amount = 0.0
    }
  },
  RESET_CART(state) {
    state.cart = {
      products: [],
      subtotal: 0.0,
      discount: {
        type: 'percent',
        amount: 0.0
      },
      total: 0.0,
      purchaseOrder: {
        attributes: {
          product_id: 0,
          quantity: 0,
          coupon: {
            id: 0,
            admin_user_id: '',
            product_id: 0,
            code: '',
            name: '',
            description: '',
            discount_figure: 0,
            validity: {
              id: 0,
              coupon_id: 0,
              valid_from: '',
              valid_to: '',
              number_of_coupons: 0
            }
          },
          whitelabel_id: 0,
          user_auth0id: '',
          product: {
            id: 0,
            name: '',
            code: '',
            whitelabel_id: 0,
            subscription: 0,
            duration: 0,
            monthly_price: 0,
            yearly_price: 0
          }
        },
        is_trial: false,
        transaction_cost: 0,
        transaction_id: '',
        payment_required: true,
        signed: ''
      }
    }
  },
  CREATE_PURCHASE_ORDER(state, couponCode) {
    state.cart.purchaseOrder.attributes.product_id = state.cart.products[0].id
    state.cart.purchaseOrder.attributes.quantity = 1
    state.cart.purchaseOrder.attributes.coupon.code = couponCode
  },
  VALIDATE_PURCHASE_ORDER(state, order) {
    state.cart.purchaseOrder = order
  },
  SET_TRANSACTION_ID(state, id) {
    state.cart.purchaseOrder.transaction_id = id
  }
}

const actions = {
  async addProduct({ commit }, payload) {
    commit('ADD_PRODUCT', payload)
  },
  async removeProduct({ commit, dispatch }, product) {
    commit('REMOVE_PRODUCT', product)
    dispatch('setCoupon', {
      code: '',
      discount: 0,
      message: '',
      type: '',
      valid: false
    })
  },
  async applyDiscount({ commit }, coupon) {
    commit('APPLY_DISCOUNT', coupon)
  },
  async resetCart({ commit, dispatch }) {
    commit('RESET_CART')
    dispatch('setCoupon', {
      code: '',
      discount: 0,
      message: '',
      type: '',
      valid: false
    })
  },
  async validateOrder({ dispatch }, { coupon, cart }) {
    if (cart.products.length > 0) {
      const purchaseOrder = {
        product_id: cart.products[0].id,
        quantity: 1
      }
      if (coupon && coupon.code) {
        purchaseOrder.coupon = coupon.code
      }
      return PurchaseService.validatePurchaseOrder(purchaseOrder).then((response) =>
        dispatch('setPurchaseOrder', response.data)
      )
    } else {
      return dispatch('resetPurchaseOrder')
    }
  },
  async setPurchaseOrder({ commit }, orderData) {
    commit('VALIDATE_PURCHASE_ORDER', orderData)
  },
  resetPurchaseOrder({ dispatch }) {
    const orderData = {
      attributes: {
        product_id: 0,
        quantity: 0,
        coupon: {
          id: 0,
          admin_user_id: '',
          product_id: 0,
          code: '',
          name: '',
          description: '',
          discount_figure: 0,
          validity: {
            id: 0,
            coupon_id: 0,
            valid_from: '',
            valid_to: '',
            number_of_coupons: 0
          }
        },
        whitelabel_id: 0,
        user_auth0id: '',
        product: {
          id: 0,
          name: '',
          code: '',
          whitelabel_id: 0,
          subscription: 0,
          duration: 0,
          monthly_price: 0,
          yearly_price: 0
        }
      },
      is_trial: false,
      transaction_cost: 0,
      payment_required: true,
      signed: ''
    }
    dispatch('setPurchaseOrder', orderData)
  },
  setTransactionId({ commit }, id) {
    commit('SET_TRANSACTION_ID', id)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
