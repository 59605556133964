<template>
  <Collapse
    v-if="activeSuper"
    class="bg-white"
    :default-open="isAdmin() || (activeSuper.investments && activeSuper.investments.length <= 0)">
    <template #heading>
      <div class="flex gap-1">
        <h6 class="text-blue-1">Account details</h6>
      </div>
    </template>
    <template #body>
      <Loader v-if="loadingForm" />
      <form v-else @submit.prevent="saveSuper" id="super-widget-form" class="flex flex-col">
        <div class="flex flex-col w-full gap-4 md:gap-8">
          <div class="grid grid-cols-2 grid-rows-2 gap-4">
            <!--ACCOUNT NO-->
            <div class="flex flex-col col-span-1 gap-2" v-if="isCallcentrePortal">
              <label for="cfs-account-number" class="capitalize w-fit paragraph-2">
                <span class="flex flex-row">
                  Account number
                  <!-- TODO: make the message either generic or based whitelabel config -->
                  <InfoCircle
                    class="flex ml-2"
                    message="Your CFS Super account number which starts with 011 or 051 or 065" />
                </span>
              </label>
              <BaseInput
                type="text"
                validation="numeric"
                :soft-error="accountNumberError"
                :disabled="isUser() || disableButtons"
                v-model:value="form.account_number"
                name="cfs-account-number"
                placeholder="Account number"
                class="w-full" />
            </div>
            <!--PRESERVATION AGE-->
            <div class="flex flex-col col-span-1 gap-2">
              <label for="preservation-age" class="capitalize w-fit paragraph-2">
                <span class="flex flex-row">
                  Preservation age
                  <InfoCircle class="flex ml-2" message="The age you could access your super" />
                </span>
              </label>
              <BaseInput
                type="text"
                disabled
                :value="state.preservationAge"
                class="w-full"
                name="preservation-age" />
            </div>
            <!--SUPER BALANCE-->
            <div class="flex flex-col col-span-1 gap-2">
              <label for="super-balance" class="capitalize w-fit paragraph-2">
                <span class="flex flex-row">
                  Super balance
                  <InfoCircle
                    class="flex ml-2"
                    message="Your current super balance for this account" />
                </span>
              </label>
              <BaseInput
                name="super-balance"
                type="currency"
                :disabled="disableButtons"
                :soft-error="
                  form.balance != null && (form.balance > 10000000 || form.balance <= 0)
                    ? 'Please check balance'
                    : ''
                "
                v-model:value="form.balance"
                class="w-full" />
            </div>
            <!--MEMBER AGE-->
            <div class="flex flex-col col-span-1 gap-2">
              <label for="member-age" class="capitalize w-fit paragraph-2">
                <span class="flex flex-row">
                  Member age
                  <InfoCircle class="flex ml-2" message="Your current age according to your DOB" />
                </span>
              </label>
              <BaseInput
                name="member-age"
                type="text"
                disabled
                :value="calculateAge(userData.dob)"
                class="w-full" />
            </div>
          </div>

          <!-- Super Fund-->
          <div class="flex flex-col md:w-1/2 col-span-2 md:col-span-1 gap-2 md:pr-2">
            <label for="super-fund" class="capitalize w-fit paragraph-2">
              <span class="flex flex-row">
                Super fund
                <InfoCircle class="flex ml-2" message="Your current super fund" />
              </span>
            </label>
            <Autocomplete
              v-if="isOtivoWhitelabel()"
              @selected="setSuperfund"
              :get-items="getSuperFundNames"
              :existing-value="selectedSuperFund"
              ref="super-fund-auto-complete"
              class="w-full"
              id="autocomplete-select-superfund"
              placeholder="Enter your Super fund"
              type="superfund" />

            <BaseInput
              v-else
              type="text"
              disabled
              :value="activeSuper.fund_name"
              class="w-full"
              name="super-fund" />
          </div>
        </div>
        <div class="w-full flex flex-col gap-6 mt-[20px]" v-if="activeSuper.chant_west_product_id">
          <div
            v-for="(item, itemIndex) in form.allocation.filter(
              (allocation) => !allocation.deleted_at,
            )"
            class="flex flex-col md:flex-row items-end gap-6"
            :key="itemIndex">
            <div class="flex flex-col w-full flex-grow gap-4">
              <InputLabel :label="`Investment option ${itemIndex + 1}`" />
              <Dropdown
                :items="superInvestmentOptions"
                placeholder="Select your investment option"
                type="investmentOptions"
                class="text-left w-100%"
                :data-test="'investmentOption' + itemIndex"
                :disabled="disableButtons"
                @selected="
                  (investmentOption) =>
                    setInvestmentAllocationItemInformation(investmentOption, itemIndex)
                "
                :existing-item="{
                  label: item.chant_west_investment_name ?? '',
                  value: item.chant_west_investment_id?.toString(),
                }" />
            </div>

            <div class="flex w-100% md:w-25% flex-col col-span-1 gap-2">
              <label for="CFS_details_id" class="capitalize w-full paragraph-2 flex">
                Allocation
              </label>
              <PercentageInput
                :value="item.percent_allocation"
                :disabled="disableButtons"
                :data-test="`investmentOptionAllocation${itemIndex}`"
                @update:value="(val: number) => (item.percent_allocation = val)"
                :error-message="totalAllocationPercentage > 100 ? 'Total must be 100%' : ''" />
            </div>
            <div
              v-if="itemIndex > 0 && !disableButtons"
              class="md:w-[40px] h-[40px] flex justify-center text-white bg-otivo-blue hover:bg-blue-1 rounded items-center cursor-pointer"
              @click="deleteInvestmentAllocation(itemIndex)">
              <SubtractionIcon fill="white" style="width: 40px" icon="minus" class="heading-3" />
            </div>
            <div v-else class="md:min-w-[40px] min-h-[40px] flex justify-center"></div>
          </div>
        </div>

        <div
          class="flex flex-row self-center justify-center mt-10 gap-3 items-center cursor-pointer">
          <p class="button-1 otivo-error-message" v-if="allocationPercentageError">
            {{ allocationPercentageError }}
          </p>
        </div>

        <div
          v-if="!disableButtons"
          class="flex flex-col md:flex-row self-center justify-center mt-10 gap-3 items-center cursor-pointer"
          @click="() => form.allocation.push(superAllocationTemplate())">
          <p class="button-1 flex text-otivo-blue">Add another investment option</p>
          <div
            class="w-[40px] h-[40px] flex justify-center text-white bg-otivo-blue hover:bg-blue-1 rounded items-center cursor-pointer">
            <AdditionIcon
              fill="white"
              data-test="addOption"
              class="heading-3"
              style="width: 40px" />
          </div>
        </div>
        <div
          class="flex mt-20 justify-center gap-[20px]"
          v-if="consent && !termDepositConsentStatus.waiting && !disableButtons">
          <OtivoButton
            :large-text="true"
            class="w-72"
            colour="white"
            data-test="saveBtn"
            :loading="saving">
            Save
          </OtivoButton>
        </div>
        <div v-else-if="isAdmin()" class="m-auto text-center">
          <p
            v-if="!consent && consentEmailSent"
            class="paragraph-1 text-red-1 mt-6"
            data-test="awaitingMemberSignIn">
            Activation email sent. Awaiting member sign-in.
          </p>
          <OtivoButton
            :large-text="true"
            class="m-auto w-72 mt-6"
            colour="white"
            type="submit"
            data-test="saveBtn"
            :disabled="disableButtons"
            :loading="saving">
            Save
          </OtivoButton>
        </div>

        <div class="flex gap-[10px]" v-if="isAdmin() && activeSuper.investments">
          <div
            v-if="activeSuper.investments.length && !consent && consentEmailSent"
            @click="sendOnboardEmail(true)"
            class="underline text-otivo-blue button-2 cursor-pointer hover:text-blue-1 mt-[20px] w-full"
            :class="canBeDeleted ? 'text-left' : 'text-center'"
            data-test="resendOnboardEmailBtn"
            style="justify-self: center">
            Resend onboard email
          </div>
          <div
            class="underline text-otivo-blue button-2 cursor-pointer hover:text-blue-1 mt-[20px] w-full"
            :class="activeSuper.investments.length && !consent ? 'text-right' : 'text-center'"
            v-if="canBeDeleted"
            @click="deleteSuperObject()">
            Delete super account
          </div>
        </div>
      </form>
    </template>
  </Collapse>
  <LumpSumSkeleton v-else />
</template>

<script lang="ts" setup>
import { computed, useTemplateRef, onBeforeMount, reactive, ref, watch } from 'vue'
import Autocomplete, { AutoCompleteItemObjectType } from '@/components/BasicInputs/Autocomplete.vue'
import AdditionIcon from '@/components/icons/AdditionIcon.vue'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import Collapse from '@/components/SaveAndCollapse/Collapse.vue'
import Dropdown from '@/components/BasicInputs/Dropdown.vue'
import InfoCircle from '@/components/InfoCircle/InfoCircle.vue'
import InputLabel from '@/components/BasicInputs/InputLabel.vue'
import InvestmentOption from '@/types/api/super/InvestmentOption.ts'
import Loader from '@/components/Loader/Loader.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import RetirementService from '@/services/RetirementService.ts'
import SubtractionIcon from '@/components/icons/SubtractionIcon.vue'
import calculateAge from '@/composables/users/calculateAge.ts'
import superAllocationTemplate from '@/composables/superHelpers/superAllocationTemplate.ts'
import { getSuperFundNames, generateOtivoAccountNumber } from '@/composables/getSuperFundNames.ts'
import { SuperFundAllocationNameType } from '@/types/CallcentrePortalTypes.ts'
import { sendGuidanceOnboardEmail } from '@/store/pinia/adminPortal/EmailManager.ts'
import { state } from '@/components/CFS/IntrafundInvestment/superInvestmentsState.ts'
import { useCheckUser } from '@/composables/users/checkUser.ts'
import { useIntrafundAdviceStore } from '@/store/pinia/IntrafundAdviceStore'
import { useSuperStore } from '@/store/pinia/SuperStore.ts'
import { useToast } from '@/composables/useToast.ts'
import { useUserStore } from '@/store/pinia/UserStore'
import { useAccountFlagsStore } from '@/store/pinia/AccountFlagsStore.ts'
import { useModuleStatusesStore } from '@/store/pinia/ModuleStatusesStore.ts'

import PercentageInput from '@/components/BasicInputs/PercentageInput.vue'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import getChantwestProductFromAccountNumber from '@/composables/superHelpers/getChantwestProductFromAccountNumber.ts'
import SuperService from '@/services/SuperService.ts'
import LumpSumSkeleton from '@/components/CFS/Skeletons/LumpSumSkeleton.vue'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import UpdateSuperFundModal from '@/components/Modals/UpdateSuperFundModal.vue'
import { useWhitelabelIdentifier } from '@/composables/isOtivoWhiteLabel.ts'

type Props = {
  loadingForm?: boolean
}

withDefaults(defineProps<Props>(), {
  loadingForm: false,
})

const userStore = useUserStore()
const superStore = useSuperStore()
const intrafundAdviceStore = useIntrafundAdviceStore()
const accountFlagsStore = useAccountFlagsStore()
const moduleStatusesStore = useModuleStatusesStore()
const isCallcentrePortal = computed(() => useSessionStore().getWhitelabelData.callcentre_portal)

const { isUser, isAdmin } = useCheckUser()
const { successToast, errorToast } = useToast()
const { isOtivoWhitelabel } = useWhitelabelIdentifier(useSessionStore().getWhitelabelData)

const emit = defineEmits<{
  (e: 'optimizeAdvice'): void
}>()

const saving = ref(false)
const selectSuperFundRef = useTemplateRef('super-fund-auto-complete')
// const deleteSuperModal = ref(false)

const form = reactive<{
  chant_west_product_id: number | null
  fund_name: string
  account_number: string
  balance: number | null
  allocation: InvestmentOption[]
  data_source: string | null
}>({
  chant_west_product_id: null,
  fund_name: '',
  account_number: '',
  balance: null,
  allocation: [],
  data_source: isAdmin() ? 'guidance' : 'user', // this column stores where the data was set from
})

const alreadyUsed = (accountNumber: string) =>
  superStore.getSupersArray.some((superAccount) => superAccount.account_number === accountNumber)
const accountNumberError = ref('')
watch(
  () => form.account_number,
  (newValue) => {
    if (newValue && !alreadyUsed(newValue)) {
      accountNumberError.value = ''
      const product = getChantwestProductFromAccountNumber(
        newValue,
        useSessionStore().getWhitelabelData.superfund_config.super_products,
      )
      activeSuper.value.account_number = newValue
      activeSuper.value.fund_name = product.fund_name
      activeSuper.value.chant_west_product_id = product.chant_west_product_id
    } else {
      if (activeSuper.value.account_number !== newValue) {
        accountNumberError.value = 'Account number already in use'
      }
    }
  },
)

const userData = computed(() => userStore.getUser)
const activeSuper = computed(() => superStore.getActiveSuperObject)
const disableButtons = computed(
  () =>
    ['processing', 'actioned'].includes(moduleStatusesStore.getStatuses.superInvestments) ||
    userStore.cfsClient,
)
const superInvestmentOptions = computed(() => superStore.getSuperProductInvestmentOptions)
const consent = computed(() => userData.value.has_consented)
const consentEmailSent = computed(() =>
  accountFlagsStore.getFlag('guidance_client_consent_email_sent'),
)
const canBeDeleted = computed(
  () => superStore.getSupersArray.length !== 1 && isAdmin() && activeSuper.value.id !== '',
)
const termDepositConsentStatus = computed(() => intrafundAdviceStore.termDepositConsentStatus)

const totalAllocationPercentage = computed(() => {
  const investmentOptions = form.allocation.filter((item: InvestmentOption) => !item.deleted_at)
  return investmentOptions.reduce((acc, curr) => acc + curr.percent_allocation, 0)
})

const selectedSuperFund = computed(() => {
  return {
    value: form.chant_west_product_id,
    name: form.fund_name,
  }
})

onBeforeMount(async () => {
  state.preservationAge = await RetirementService.getUserPreservationAge()
})

watch(
  () => activeSuper.value,
  async (newValue) => {
    if (newValue) {
      form.account_number = newValue.account_number ?? ''
      form.balance = newValue.balance ?? null
      form.allocation = newValue.investments.length
        ? newValue.investments
        : [superAllocationTemplate()]
      form.chant_west_product_id = newValue.chant_west_product_id
      form.fund_name = newValue.fund_name
    }
  },
  {
    immediate: true,
  },
)

const modalStore = useModalStore()
const setSuperfund = (fund: AutoCompleteItemObjectType) => {
  if (!fund) {
    errorToast('No super fund found')
    throw new Error('No super fund found')
  }

  if (activeSuper.value.chant_west_product_id == fund.value) {
    superStore.setInvestmentOptionsForSuper(+fund.value)
    form.chant_west_product_id = activeSuper.value.chant_west_product_id
    form.fund_name = activeSuper.value.fund_name
    form.allocation = activeSuper.value.investments
    form.account_number = activeSuper.value.account_number
    return
  }

  modalStore.preventAutoClose()
  modalStore.openModal(UpdateSuperFundModal, {
    onCancel: () => {
      selectSuperFundRef.value?.undoSelect()
      form.chant_west_product_id = +activeSuper.value.chant_west_product_id
      form.fund_name = activeSuper.value.fund_name
      form.allocation = activeSuper.value.investments.length
        ? activeSuper.value.investments
        : [superAllocationTemplate()]
      form.account_number = activeSuper.value.account_number
    },
    onProceed: () => {
      // reset investments
      superStore.setInvestmentOptionsForSuper(+fund.value)
      form.chant_west_product_id = +fund.value
      form.fund_name = fund.name
      form.allocation = [superAllocationTemplate()]
      form.account_number = generateOtivoAccountNumber()
    },
  })
}

const setInvestmentAllocationItemInformation = (
  value: SuperFundAllocationNameType,
  index: number,
) => {
  const investment = form.allocation[index]
  investment.chant_west_investment_name = value.label
  investment.chant_west_investment_id = Number(value.value)
}

const allocationPercentageError = ref<string | null>()
const saveSuper = async () => {
  saving.value = true
  allocationPercentageError.value = null
  try {
    if (form.balance === null || form.balance <= 0) {
      throw new Error('Error updating super, no balance')
    }
    if (totalAllocationPercentage.value !== 100) {
      allocationPercentageError.value = 'Total must be 100%'
      throw new Error('Error updating super, allocation total not 100%')
    }

    if (form.allocation.some((item) => !item.chant_west_investment_id)) {
      throw new Error('Error updating super, missing investment option')
    }
    // if no id, brand new super object!
    if (!activeSuper.value.id) await saveNewSuper()
    if (
      activeSuper.value.advice_request?.advice_stage === 1 ||
      !activeSuper.value.advice_request?.advice_stage
    ) {
      const intrafundAdvicePayload = {
        previous_investment_options: JSON.stringify(form.allocation),
        advice_stage: 2,
        super_account_number: activeSuper.value.account_number,
        member_id: userData.value.member_id ?? '',
      }
      await intrafundAdviceStore.updateIntrafundAdviceRequest(intrafundAdvicePayload)
    }
    await superStore.updateSuperObject(activeSuper.value.id, form)

    if (!consentEmailSent.value && isCallcentrePortal.value) await sendOnboardEmail()
    successToast('Super updated!')
    emit('optimizeAdvice')
  } catch (e) {
    errorToast((e as Error).message ?? 'Failed to update super')
    console.error(e)
  }
  saving.value = false
}

const deleteSuperObject = async () => {
  try {
    await SuperService.deleteSuper(activeSuper.value.id)
    superStore.removeSuperObject(activeSuper.value.id)
    successToast('Super deleted')
  } catch (e) {
    errorToast('Failed to delete super')
    console.error(e)
  }
}

const saveNewSuper = async () => {
  const newSuper = await SuperService.createNewSuper({
    account_id: userData.value.account_id,
    ...activeSuper.value,
    ...form,
  })
  activeSuper.value.id = newSuper.data.id
}

const sendOnboardEmail = async (forceSend = false) => {
  try {
    const emailResponse = await sendGuidanceOnboardEmail(userData.value.auth0id, forceSend)

    if (emailResponse.data?.email_sent) {
      successToast('Onboard email sent')
      // Refreshing for the email sent message and resend button
      await accountFlagsStore.fetchAccountFlags()
    }
  } catch (err) {
    errorToast('Failed to send onboard email')
    console.error(err)
  }
}

const deleteInvestmentAllocation = (index: number) => {
  if (!form.allocation[index].id) form.allocation.splice(index, 1)
  else form.allocation[index].deleted_at = new Date().toISOString()
}
</script>
