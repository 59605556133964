<template>
  <div>
    <div class="px-6 pt-10 md:pb-6">
      <div class="flex gap-1 justify-between text-left text-otivo-blue">
        <h4 class="self-end pb-4">
          {{ product.name }}
        </h4>
        <div
          v-if="index % 2 !== 0"
          class="menu-1 bg-otivo-red p-[10px] rounded self-start text-white">
          SPECIAL
        </div>
      </div>
      <div v-if="product.duration === 31" class="text-left">
        <h3 class="pb-2 text-left">
          ${{ product.monthly_price }} <span class="paragraph-2">per month</span>
        </h3>
        <div class="paragraph-2 min-h-16 md:min-h-16 py-2">Month-to-month plan billed monthly</div>
      </div>
      <div v-else-if="product.yearly_price > 0" class="text-left align-center">
        <h3 class="pb-2 text-left">
          ${{ (product.yearly_price / 12).toFixed(2) }} <span class="md:p2 mp2">per month</span>
        </h3>
        <div class="pb-2 paragraph-2 min-h-16 md:min-h-16 py-2">
          1 year plan billed annually (${{ product.yearly_price.toFixed(2) }} total)
        </div>
      </div>
    </div>
    <h6
      class="bg-grey-5 flex items-center p-[20px] text-left min-h-[86px]"
      v-html="product.description" />
    <div class="flex flex-col justify-center px-[20px] min-h-[316px]">
      <div :key="index" v-for="(feature, index) in features" class="w-100% flex py-4">
        <img
          class="w-6 h-6 mr-4 self-start"
          v-tooltip="{ content: isModal ? feature.headline : feature.details }"
          :alt="feature.headline"
          :src="feature.image_url" />
        <div class="mp2 md:p2 text-left" v-html="feature.details" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Feature, Product } from '@/types/ProductInterface'

interface Props {
  product: Product
  features: Feature[]
  index: number
  isModal?: boolean
}

withDefaults(defineProps<Props>(), {
  isModal: true
})
</script>
