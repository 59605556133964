import { reactive } from 'vue'

export const state = reactive({
  activeModal: '',
  graphLoaded: false,
  isEligibleForRecommendations: true,
  loading: false,
  preservationAge: 60,
  riskRecommendation: 'balanced',
  selectedCurrentOption: false,
  showRecommendations: false,
})
