<template>
  <div
    v-if="navBar.opened"
    class="w-full md:w-[375px] z-60 shadow overflow-auto relative bg-white rounded-bl md:rounded-br h-full md:h-auto">
    <!-- Profile Button -->
    <div @click="toggleSideBar()" class="text-left p-4 cursor-pointer" data-test="closeSideBarBtn">
      <MultiplyIcon class="w-4 h-4" />
    </div>
    <div v-if="adviserEmailContact || ampDemo" class="flex justify-center mb-4">
      <CustomButton colour="red" class="w-72" @click="showAdviserContactModal">
        Contact your adviser
      </CustomButton>
    </div>
    <div v-if="isItStanfordbrown" class="flex justify-center mb-4">
      <a href="https://stanfordbrown.com.au/" target="_blank">
        <CustomButton colour="red" class="w-72"> Contact Stanford Brown</CustomButton>
      </a>
    </div>
    <!-- Admin Report -->
    <div
      v-if="profile.superAdmin || profile.whiteLabelAdmin"
      @click="toggleSideBar()"
      :class="getClassList()"
      class="pb-4 mx-4">
      <RouterLink to="/report" class="w-full px-2 leading-none font-medium no-underline text-black">
        <div class="my-3 text-grey-2">Reports</div>
      </RouterLink>
    </div>
    <div :key="title" v-for="(links, title) in getWhiteLabelsLinks" class="px-4">
      <LinkTemplate
        :links="links"
        :title="title"
        :title-classes="'border-b-grey-4'"
        :data-test="title + 'SideBar'"
        text-colour="grey-2"
        header-colour="text-grey-1"
        :collapsable="true"
        :default-open="false" />
    </div>
    <div @click="toggleSideBar()" :class="getClassList()" class="flex text-grey-2 items-center">
      <RouterLink
        to="/profile"
        data-test="profileBtn"
        class="flex justify-center p-4 rounded-xl bg-otivo-blue hover:bg-blue-1 max-w-75% w-full">
        <span class="button-1 text-white font-bold">Profile</span>
      </RouterLink>
      <!--            <profile-nav-bar-link-->
      <!--              class="RouterLink intro-1"-->
      <!--            />-->
    </div>
    <div class="flex flex-col gap-6 p-6">
      <OtivoButton v-if="!authenticated" colour="red" @click="triggerSignIn" id="signInBtn">
        Log in
      </OtivoButton>
      <OtivoButton v-if="!authenticated" @click="triggerSignUp" id="getStartedBtn">
        Get started
      </OtivoButton>
      <OtivoButton v-if="authenticated" @click="triggerLogOut"> Log out </OtivoButton>
    </div>
    <DownloadSoaButton class="my-3 text-grey-2 intro-1" @click="toggleSideBar()" />

    <div class="mx-4">
      <div class="ml-2 my-2 pl-4">
        <div class="flex flex-col max-w-75%">
          <SunCorpStaffBenefitsButton @click="toggleSideBar()" />
          <SunCorpContactStaffFinancialServices class="mt-3" @click="toggleSideBar()" />
          <SunSuperGetAdviceButton class="mt-3" @click="toggleSideBar()" />
          <EyStaffBenefits class="my-3" @click="toggleSideBar()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DownloadSoaButton from '@/components/DownloadSoa/DownloadSoaButton.vue'
import SunCorpStaffBenefitsButton from '@/components/SunCorp/SunCorpStaffBenefitsButton.vue'
import SunCorpContactStaffFinancialServices from '@/components/SunCorp/SunCorpContactStaffFinancialServices.vue'
import SunSuperGetAdviceButton from '@/components/SunSuper/SunSuperGetAdviceButton.vue'
import EyStaffBenefits from '@/components/EyStaffBenefits/EyStaffBenefits.vue'
import LinkTemplate from '@/components/Footer/LinkTemplate.vue'
import CustomButton from '@/components/CustomButton/CustomButton.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import { useAuth0 } from '@/lib/AuthenticatorPlugin'
import { useSessionStore } from '@/store/pinia/SessionStore'
import MultiplyIcon from '@/components/icons/MultiplyIcon.vue'
import { getLinksForWhitelabel } from '@/composables/getLinksForWhitelabel.ts'

export default {
  name: 'NavSideBar',
  components: {
    MultiplyIcon,
    OtivoButton,
    CustomButton,
    LinkTemplate,
    EyStaffBenefits,
    SunSuperGetAdviceButton,
    SunCorpContactStaffFinancialServices,
    SunCorpStaffBenefitsButton,
    DownloadSoaButton,
  },
  emits: ['click'],
  created() {
    this.auth0 = useAuth0()
  },
  computed: {
    ...mapGetters([
      'checkIfUserBasicInfoFormFilled',
      'getWhiteLabel',
      'profile',
      'isItStanfordbrown',
      'getCurrentWhitelabelObject',
    ]),
    ...mapState({
      navBar: (state) => state.navBar,
    }),

    authenticated() {
      return this.auth0.isAuthenticated
    },

    getWhiteLabelsLinks() {
      return getLinksForWhitelabel()
    },

    ampDemo() {
      return this.getWhiteLabel === 'ampdemo'
    },
    adviserEmailContact() {
      return (
        this.getCurrentWhitelabelObject.adviser_contact_email !== null &&
        this.getCurrentWhitelabelObject.adviser_contact_email !== ''
      )
    },
  },
  data() {
    return {
      auth0: null,
    }
  },
  methods: {
    ...mapActions(['logout', 'toggleSideBar']),
    getClassList() {
      return {
        enabled: this.authenticated,
        hidden: !this.authenticated,
      }
    },

    showAdviserContactModal() {
      this.$store.dispatch('toggleAdviserContactModal', true)
    },

    triggerSignUp() {
      this.auth0.createAccount()
    },

    triggerSignIn() {
      this.auth0.login()
      this.$store.dispatch('closeSideBar')
      // this.$store.dispatch('toggleTriggerSignIn')
    },
    triggerLogOut() {
      this.$store.dispatch('closeSideBar')
      useSessionStore().logout()
    },
  },
}
</script>

<style scoped>
.enabled a {
  @apply text-black;
}
</style>
