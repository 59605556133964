<template>
  <BaseView>
    <div id="employee-page" v-if="pageContent.currentPage === 'corporateWellbeingPage'">
      <Breadcrumbs />
      <LandingPageModule
        class="items-center"
        :landing-page-content="{
          heading:
            'Otivo will help your employees<br> find <span class=text-otivo-red> peace of mind</span>',
          subHeading:
            'With over 60%* of Aussies worried about retiring comfortably, there’s no better time to<br> give your employees financial advice.',
          buttonText: 'Ask about our affordable pricing models',
        }"
        :show-button="true"
        :show-disclaimer-text="true"
        @button-clicked="showContactUsModal"
        background-color="blue-5">
        <template #svgComponent>
          <img :src="WeCallItFinancialZen" alt="We Call It Financial Zen" />
        </template>
      </LandingPageModule>
      <RegularCurve fill="blue-5" class="bg-white h-6 w-full" :rotate="true" />
      <USPs
        class="py-[60px] bg-white"
        :usp-data="corporateWellbeingUSPs"
        :icons="uspsIcons"
        @button-clicked="showContactUsModal"
        :display-how-it-works="true" />
      <div class="bg-white w-full pb-10">
        <TrustedBy />
      </div>
      <RegularCurve fill="blue-5" class="bg-white h-6 w-full" />
      <HowItWorksModule class="pt-18 bg-blue-5" :how-it-works="pageContent.howItWorks" />
      <Awards
        class="bg-blue-5 py-20 hidden md:block"
        :color="{
          textColour: userOnMobile() ? 'white' : 'grey-2',
          svgLineFill: userOnMobile() ? 'white' : 'grey-2',
          svgImageBackgroundFill: userOnMobile() ? 'grey-2' : 'blue-5',
        }" />
      <RegularCurve fill="blue-5" class="bg-otivo-blue h-6 w-full" :rotate="true" />
      <div class="bg-otivo-blue">
        <GettingStarted
          class="mb-20 md:mb-0"
          :getting-started="pageContent.gettingStarted"
          @button-clicked="showContactUsModal">
        </GettingStarted>
        <InvertRegularCurve fill="blue-5" class="hidden md:block w-full h-6 relative z-20" />
        <RegularCurve fill="blue-5" class="md:hidden fill-white bg-otivo-blue h-6 w-full" />
      </div>
      <Testimonials
        :testimonial-page-data="pageContent.testimonials"
        class="py-[40px] bg-blue-5 relative z-20" />
      <!--      <OtivoPartners-->
      <!--        @button-clicked="showContactUsModal"-->
      <!--        :partners-page-data="pageContent.partners"-->
      <!--        class="bg-white"-->
      <!--        logo-colour="black" />-->
      <RegularCurve fill="white" class="md:hidden fill-white bg-blue-5 h-6 w-full" :rotate="true" />
      <ThreeTileDisplay
        :icon-boxes="pageContent.threeTileDisplay.iterableItems"
        class="bg-blue-5" />
      <RegularCurve fill="midnight" class="md:hidden fill-midnight bg-blue-5 h-6 w-full" />
    </div>
  </BaseView>
</template>

<script setup lang="ts">
import BaseView from '@/views/BaseView.vue'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import HowItWorksModule from '@/components/HowItWorksModule/HowItWorksModule.vue'
import LandingPageModule from '@/components/BrandComponents/LandingPageModule.vue'
import PlanIcon from '@/components/icons/PlanIcon.vue'
import Testimonials from '@/components/BrandComponents/Otivo/Testimonials.vue'
import ThreeTileDisplay from '@/components/BrandComponents/Otivo/ThreeTileDisplay.vue'
import USPs from '@/components/BrandComponents/Otivo/USPs.vue'
import WeCallItFinancialZen from '@/components/SVGS/WeCallItFinancialZen.svg?url'
import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import RegularCurve from '@/components/Shapes/RegularCurve.vue'
import GettingStarted from '@/components/GettingStarted.vue'
import Awards from '@/components/Footer/Awards.vue'
import { userOnMobile } from '@/composables/userOnMobile.ts'
import InvertRegularCurve from '@/components/Shapes/InvertRegularCurve.vue'
import SunIcon from '@/components/icons/SunIcon.vue'
import CoffeeIcon from '@/components/icons/CoffeeIcon.vue'
import CrownIcon from '@/components/icons/CrownIcon.vue'
import DiamondIcon from '@/components/icons/DiamondIcon.vue'
import ThumbsUpIcon from '@/components/icons/ThumbsUpIcon.vue'
import TrustedBy from '@/components/BrandComponents/Otivo/TrustedBy/TrustedBy.vue'

import { useHead } from '@unhead/vue'

useHead({
  title: "Otivo's digital advice solutions for employee wellbeing",
})

const store = useStore()
onBeforeMount(() => store.dispatch('ContentStore/getContent', 'corporateWellbeingPage'))
const pageContent = computed(() => store.getters['ContentStore/pageContent'])
const showContactUsModal = () => {
  store.dispatch('renderContactUsModal', true)
}
const uspsIcons: Array<unknown> = [
  PlanIcon,
  SunIcon,
  CoffeeIcon,
  ThumbsUpIcon,
  CrownIcon,
  DiamondIcon,
]

const corporateWellbeingUSPs = {
  heading: 'Why investing in <span class="svg-underline">people</span> makes sense',
  buttonText: 'Ask how we can help your business',
  iterableItems: [
    {
      heading: 'Help your employees be better off',
      body: 'People who use Otivo are better off on average by $319K** at retirement. Plus 47% say they’re on track to meet their financial goals.',
    },
    {
      heading: 'Advice designed for everyone',
      body: 'Otivo’s easy to follow financial advice is for everyone — no matter where they are in life. After just six months, 43% say they have enough know-how to make sound financial decisions.',
    },
    {
      heading: 'Convert financial stress into financial zen',
      body: 'Make a real difference to the 60%* of people who are feeling financially stressed. After using Otivo for six months, 34% of people feel less worried about their finances.',
    },
    {
      heading: 'Increase employee engagement',
      body: 'Happy workers are more loyal and productive. This means you get back around 3.6 hours each week normally lost to financial stress.',
    },
    {
      heading: 'Become a more desirable employer',
      body: 'In an increasingly competitive employment market, employee perks are becoming an attractive asset for both new and existing employees.',
    },
    {
      heading: 'A proven solution that works',
      body: 'We’ve been helping thousands of Australians from blue-chip employers such as Suncorp, Rio Tinto, E&Y and many more for over 7 years.',
    },
  ],
}
</script>
