<template>
  <p v-if="info !== ''" class="mt-1 md:mt-2">
    {{ info !== undefined ? info : '' }}
  </p>
</template>

<script>
export default {
  name: 'InputInfo',
  props: {
    info: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped></style>
