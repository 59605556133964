<template>
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30" cy="30" r="30" fill="#FFF" />
    <circle cx="30.5" cy="30.5" r="10.9375" :class="`fill-${fill1}`" />
    <path d="M30.5 15.1875V13" stroke="#FF0000" stroke-width="4" stroke-linecap="round" />
    <path d="M30.5 48V45.8125" stroke="#FF0000" stroke-width="4" stroke-linecap="round" />
    <path d="M15.1875 30.5L13 30.5" stroke="#FF0000" stroke-width="4" stroke-linecap="round" />
    <path d="M48 30.5L45.8125 30.5" stroke="#FF0000" stroke-width="4" stroke-linecap="round" />
    <path
      d="M19.6724 19.6724L18.1256 18.1256"
      stroke="#FF0000"
      stroke-width="4"
      stroke-linecap="round" />
    <path
      d="M42.8744 42.8743L41.3276 41.3275"
      stroke="#FF0000"
      stroke-width="4"
      stroke-linecap="round" />
    <path
      d="M41.3275 19.6724L42.8743 18.1256"
      stroke="#FF0000"
      stroke-width="4"
      stroke-linecap="round" />
    <path
      d="M18.1256 42.8743L19.6724 41.3275"
      stroke="#FF0000"
      stroke-width="4"
      stroke-linecap="round" />
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue'
})
</script>

<style scoped></style>
