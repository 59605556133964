<template>
  <div>
    <div class="relative">
      <textarea
        :id="name"
        :key="update"
        :rows="rows"
        :cols="columns"
        ref="inputElement"
        class="py-[10px] pr-[15px] border-1px rounded-[5px] text-button-3 w-full rounded-md shadow-sm"
        :class="[
          'pl-3',
          disabled ? 'bg-grey-4 cursor-not-allowed' : 'bg-white',
          computedErrorMessage
            ? 'border-red-dark text-red-dark enabled:hover:otivo-outline-error enabled:focus:otivo-outline-error enabled:active:otivo-outline-error'
            : 'border-grey-field enabled:hover:otivo-outline enabled:focus:otivo-outline enabled:active:otivo-outline',
        ]"
        :disabled="disabled"
        :maxlength="maxLength"
        :name="name"
        :placeholder="placeholder"
        :value="formattedValue"
        @input="(event: Event) => handleInput((event.target as HTMLInputElement).value)"
        @blur="() => (update += 1)" />
      <p v-if="computedErrorMessage" class="otivo-error-message absolute right-0">
        {{ computedErrorMessage }}
      </p>
    </div>
    <p v-if="softError" class="otivo-error-message">
      {{ softError }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'

export type BaseInputProps = {
  value: string | null | undefined
  name: string
  placeholder?: string | null
  maxLength?: number
  errorMessage?: string
  softError?: string
  disabled?: boolean
  rows?: number
  columns?: number
}

const props = withDefaults(defineProps<BaseInputProps>(), {
  type: 'text',
  placeholder: '',
  maxLength: 200,
  errorMessage: '',
  softError: '',
  rows: 8,
  columns: 50,
})
const emits = defineEmits<{
  (e: 'update:value', val: string | number): void
  (e: 'keyPress', val: string | number): void
  (e: 'cleared'): void
}>()

const update = ref(0)

const formattedValue = ref('')
const localError = ref('')
const computedErrorMessage = computed(() => props.errorMessage || localError.value)

onMounted(() => {
  if (!props.value) return
  formattedValue.value = props.value.toString()
})

watch(
  () => props.value,
  (newValue) => {
    if (newValue === undefined || newValue === null) return
    if (newValue.toString().length === props.maxLength)
      localError.value = `Max length of ${props.maxLength} reached`
    else localError.value = ''
  },
)

const handleInput = (text: string) => {
  if (text.length > props.maxLength) {
    text = text.substring(0, props.maxLength)
  }
  formattedValue.value = text
  emits('update:value', text)
}

const inputElement = ref<HTMLInputElement | null>(null)
</script>

<style scoped>
textarea {
  resize: none;
}
</style>
