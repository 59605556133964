<template>
  <!--  <modal v-if="cfsCreateStaffModal" @close="closeModal">-->
  <!--    <template #header>-->
  <h4 class="mh4 md:h4">Create staff account</h4>
  <!--    </template>-->
  <!--    <template #body>-->
  <div>
    <input-text
      class="my-4"
      name="cfs-staff-first-name"
      label="First name"
      :value="cfsStaff.firstName"
      @input="
        (val) => {
          cfsStaff.firstName = val
        }
      "
      place-holder="First name"
      :error="errors.firstName" />
    <input-text
      class="my-4"
      name="cfs-staff-last-name"
      label="Last name"
      :value="cfsStaff.lastName"
      @input="
        (val) => {
          cfsStaff.lastName = val
        }
      "
      place-holder="Last name"
      :error="errors.lastName" />
    <input-text
      class="my-4"
      name="cfs-staff-email"
      label="Email"
      :value="cfsStaff.email"
      @input="
        (val) => {
          cfsStaff.email = val
        }
      "
      place-holder="Email"
      :error="errors.email"
      type="email"
      :allow-digits="true" />
    <input-phone-number-with-country-code
      class="my-4"
      name="cfs-staff-phone-number"
      label="Phone number"
      v-model:phone-number="cfsStaff.phone"
      v-model:country-code="cfsStaff.countryCode"
      :error="errors.phone"
      :only-australian-number="false" />
    <input-select-box
      class="my-4"
      name="staff-role-selection"
      label="Staff role"
      :select-input="cfsStaff.role"
      :error="errors.role"
      :options="[
        {
          label: '-',
          value: null,
          disabled: true,
          selected: true,
        },
        {
          label: 'Guidance centre',
          value: 'callcentre',
        },
        {
          label: 'Back office',
          value: 'backoffice',
        },
      ]"
      @update-select-box="updateStaffRole" />
  </div>
  <!--    </template>-->
  <!--    <template #footer>-->
  <div class="text-center">
    <OtivoButton
      :loading="loading"
      @click="createStaffAccount"
      colour="blue"
      class="m-auto text-center px-8 my-4">
      Create staff account
    </OtivoButton>
  </div>
  <!--    </template>-->
  <!--  </modal>-->
</template>

<script>
import { mapGetters } from 'vuex'
import CustomButton from '../CustomButton/CustomButton'
import InputText from '../BasicInputs/InputText'
import InputPhoneNumberWithCountryCode from '../BasicInputs/InputPhoneNumberWithCountryCode.vue'
import InputSelectBox from '../BasicInputs/InputSelectBox'
import { validateEmailAddressWithDomain } from '@/lib/ValidationHelper'
import { useTemporaryPortalStore } from '@/store/pinia/TemporaryPortalStore.ts'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import OtivoButton from '@/components/OtivoButton.vue'

export default {
  name: 'CFSCreateStaffModal',
  components: {
    OtivoButton,
    InputSelectBox,
    InputPhoneNumberWithCountryCode,
    InputText,
    CustomButton,
  },
  emits: ['create-staff-account'],
  data() {
    return {
      loading: false,
      cfsStaff: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        countryCode: '+61',
        role: null,
      },
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        role: '',
      },
    }
  },
  computed: {
    ...mapGetters(['cfsCreateStaffModal']),
  },
  methods: {
    async createStaffAccount() {
      this.loading = true
      if (await this.validateStaffAccount()) {
        await useTemporaryPortalStore().createStaffMember(
          this.convertStaffToSnakeCase(this.cfsStaff),
        )
        this.loading = false
        this.cfsStaff = {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          role: null,
        }
        useModalStore().closeModal()
      }
    },
    updateStaffRole(value) {
      this.cfsStaff.role = value
    },
    closeModal() {
      this.$store.commit('SET_CFS_CREATE_STAFF_MODAL', false)
    },
    async validateStaffAccount() {
      let valid = true
      this.errors = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        role: '',
      }
      if (this.cfsStaff.firstName === '') {
        this.errors.firstName = 'Please enter a first name'
        valid = false
      }
      if (this.cfsStaff.lastName === '') {
        this.errors.lastName = 'Please enter a last name'
        valid = false
      }
      if (
        !validateEmailAddressWithDomain(this.cfsStaff.email, 'cfs.com.au') &&
        !validateEmailAddressWithDomain(this.cfsStaff.email, 'kpmg.com.au') &&
        !validateEmailAddressWithDomain(this.cfsStaff.email, 'otivo.com')
      ) {
        this.errors.email = 'Please provide a valid email, must be a cfs.com.au email address'
        valid = false
      }
      if (!this.cfsStaff.phone) {
        this.errors.phone = 'Please provide a valid phone number'
        valid = false
      }
      if (this.cfsStaff.role === null) {
        this.errors.role = 'Please select a staff role'
        valid = false
      }
      return valid
    },
    convertStaffToSnakeCase(staffData) {
      const phone = staffData.phone.charAt(0) === '0' ? staffData.phone.slice(1) : staffData.phone
      return {
        first_name: staffData.firstName,
        last_name: staffData.lastName,
        email: staffData.email,
        phone: staffData.countryCode + phone,
        role: staffData.role,
      }
    },
  },
}
</script>

<style scoped></style>
