<template>
  <div class="flex">
    <div
      v-for="(option, index) in options"
      class="flex h-20 w-24 justify-around m-2 border opacity-50 cursor-pointer rounded lg:hover:border-otivo-blue"
      :class="{ selected: selected === option.value }"
      :key="index"
      @click="$emit('click', option.value)">
      <div class="mx-3 self-center text-center">
        {{ option.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FinancialSituationAnswer',
  data() {
    return {
      options: [
        { value: 'not_worried_at_all', name: 'Not worried at all' },
        { value: 'not_very_worried', name: 'Not very worried' },
        { value: 'quite_worried', name: 'Quite worried' },
        { value: 'very_worried', name: 'Very worried' }
      ]
    }
  },
  props: {
    selected: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped></style>
