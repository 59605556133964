<template>
  <div
    class="border rounded p-2 flex flex-col"
    @click="$emit('click')"
    @keyup.enter="$emit('click')">
    <svg class="md:h-28" viewBox="-10 -2 36 24" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2024_23079)">
        <path
          d="M13 12.5H3C2.77886 12.4993 2.56419 12.4253 2.38959 12.2896C2.21498 12.1539 2.09028 11.9641 2.035 11.75L0.0349966 4.25C-0.0145836 4.06401 -0.00943616 3.86768 0.0498206 3.68455C0.109077 3.50142 0.219925 3.33928 0.36906 3.2176C0.518194 3.09591 0.699276 3.01985 0.890572 2.99855C1.08187 2.97725 1.27525 3.0116 1.4475 3.0975L4.62 4.685L7.1425 0.484997C7.23134 0.336918 7.35702 0.214368 7.50729 0.129284C7.65756 0.0442003 7.82731 -0.000518799 8 -0.000518799C8.17268 -0.000518799 8.34243 0.0442003 8.4927 0.129284C8.64297 0.214368 8.76865 0.336918 8.8575 0.484997L11.38 4.6925L14.5525 3.105C14.7242 3.01924 14.9169 2.9847 15.1077 3.00549C15.2984 3.02627 15.4792 3.10152 15.6284 3.22223C15.7776 3.34295 15.8889 3.50403 15.949 3.68627C16.0091 3.86851 16.0155 4.06421 15.9675 4.25L13.9675 11.75C13.9121 11.9645 13.787 12.1546 13.6119 12.2903C13.4368 12.4261 13.2216 12.4998 13 12.5ZM3.7675 10.5H12.2325L13.4625 5.8875L11.4475 6.8875C11.2221 7.00025 10.9625 7.02375 10.7205 6.95329C10.4786 6.88283 10.2721 6.72362 10.1425 6.5075L8 2.9425L5.8575 6.515C5.72788 6.73112 5.52144 6.89033 5.27948 6.96079C5.03752 7.03125 4.77788 7.00775 4.5525 6.895L2.5375 5.895L3.7675 10.5Z"
          :fill="selected ? '#0064FF' : '#CACED3'" />
        <path
          d="M13 16H3C2.73478 16 2.48043 15.8946 2.29289 15.7071C2.10536 15.5196 2 15.2652 2 15C2 14.7348 2.10536 14.4804 2.29289 14.2929C2.48043 14.1054 2.73478 14 3 14H13C13.2652 14 13.5196 14.1054 13.7071 14.2929C13.8946 14.4804 14 14.7348 14 15C14 15.2652 13.8946 15.5196 13.7071 15.7071C13.5196 15.8946 13.2652 16 13 16Z"
          :fill="selected ? '#FF0000' : '#CACED3'" />
      </g>
      <defs>
        <clipPath id="clip0_2024_23079">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <div class="flex-1 flex">
      <div class="self-center text-center w-full">I own outright</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OwnIcon',
  props: {
    selected: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped></style>
