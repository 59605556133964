import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import useInactivityTimer from '@/composables/inactivityTimeout.ts'
import { isAuthenticated, login, userRole } from '@/lib/AuthenticatorPlugin.ts'
import { ISessionStore } from '@/store/pinia/SessionStore.ts'
import { IUserStore } from '@/store/pinia/UserStore.ts'

export const otivoAdminGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
  userStore: IUserStore,
  sessionStore: ISessionStore,
): Promise<void> => {
  const inactivityTimer = useInactivityTimer(sessionStore)
  if (isAuthenticated.value) {
    userStore.fetchUser()
    inactivityTimer.start(120)
    if (userRole.value === 'user') sessionStore.logout()
    else next()
  } else next({ name: 'login' })
}

export const adminAuthGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
  userStore: IUserStore,
  sessionStore: ISessionStore,
): Promise<void> => {
  const inactivityTimer = useInactivityTimer(sessionStore)
  if (isAuthenticated.value) {
    userStore.fetchUser()
    inactivityTimer.start(120)
    switch (userRole.value) {
      case 'superadmin':
        next()
        break
      case 'callcentre':
        if (to.path !== '/admin-portal/home') next()
        else next('/admin-portal/search-users')
        break
      case 'backoffice':
        if (to.path !== '/admin-portal/home') next()
        else next('/admin-portal/reports')
        break
      default:
        console.error('error no cfs admin role')
        sessionStore.logout()
    }
  } else next('/cfsadmin')
}

export const clientAuthGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
): Promise<void> => {
  if (isAuthenticated.value) {
    next()
  } else {
    login()
  }
}
