<template>
  <div class="flex gap-y-[20px] flex-col max-w-md mx-auto">
    <p class="paragraph-1">{{ label }}</p>
    <div class="flex justify-center w-full gap-x-[10px]">
      <PinInputRoot
        v-model="value"
        :placeholder="placeholder"
        type="number"
        class="flex gap-[10px] items-center mt-1"
        @complete="handleComplete">
        <PinInputInput
          :id="`pin-input-${index}`"
          v-for="(id, index) in length"
          :key="id"
          :index="index"
          class="w-[54px] h-[49px] px-[15px] rounded-[5px] text-center border-1px border-[#ddd] enabled:hover:otivo-outline enabled:focus:otivo-outline enabled:active:otivo-outline" />
      </PinInputRoot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { PinInputInput, PinInputRoot } from 'radix-vue'

type Props = {
  placeholder: string
  label: string
  length: number
}

withDefaults(defineProps<Props>(), {
  length: 4,
  placeholder: '',
})

const emit = defineEmits<{
  (e: 'complete', value: string): void
}>()

const value = ref<string[]>([])
const handleComplete = (e: string[]) => {
  emit('complete', e.join(''))
}
defineExpose({
  value,
})
</script>
