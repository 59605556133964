import RetCalcService from '@/services/RetCalcService'
import {
  assetsChartTooltip,
  defaultRateOfReturnValues,
  defaultRetCalcData
} from '@/lib/RetirementCalculatorHelper'
import _ from 'lodash'
import getGraphAssumptions from '@/services/GraphAssumptionsService'
import globalConstants from '@/GlobalConstants/globalConstants'
import moment from 'moment'
import { capitalize } from 'vue'
import { cloneObject } from '@/utilities'
import { getMinSuperContributionRate } from '@/lib/RetirementPlanHelper'
const gc = globalConstants()
const returnValues = defaultRateOfReturnValues()

const state = () => ({
  userData: cloneObject(defaultRetCalcData),
  partnerData: cloneObject(defaultRetCalcData),
  userHatesMoney: false,
  incomeAssets: [
    {
      type: null,
      current_value: null,
      income: null,
      frequency: 'yearly',
      shared: true
    }
  ],
  enablePartnerForm: false,
  displaySummary: false,
  graphLoading: false,
  graphData: {},
  inflation: returnValues.inflation,
  wageGrowth: returnValues.wageGrowth,
  insurancePremium: returnValues.insurancePremium,
  annualAdminFees: returnValues.annualAdminFees,
  assumptionValues: returnValues.assumptionValues,
  noAgePension: false,
  disclaimer: ''
})

const getters = {
  userData: (state) => state.userData,
  partnerData: (state) => state.partnerData,
  disclaimer: (state) => state.disclaimer,
  enablePartnerForm: (state) => state.enablePartnerForm,
  displaySummary: (state) => state.displaySummary,
  incomeAssetArray: (state) => state.incomeAssets,
  graphData: (state) => state.graphData,
  graphLoading: (state) => state.graphLoading,
  insurancePremium: (state) => state.insurancePremium,
  annualAdminFees: (state) => state.annualAdminFees,
  assumptionValues: (state) => state.assumptionValues,
  wageGrowth: (state) => state.wageGrowth,
  incomeStatsStatements: (state) => state.graphData.income_stats_statements,
  userHatesMoney: (state) => state.userHatesMoney,
  getRetCalcAssetChartData(state) {
    const seriesData = mapAllRetCalcAssetsData(state)
    return reformatSeriesData(seriesData, state)
  },
  noAgePension: (state) => state.noAgePension,
  inflation: (state) => state.inflation
}
// END GETTERS

const mutations = {
  UPDATE_USER_DATA(state, payload) {
    state.userData[payload.field] = payload.value
  },
  UPDATE_PARTNER_DATA(state, payload) {
    state.partnerData[payload.field] = payload.value
  },
  UPDATE_USER_PART_TIME_DATA(state, payload) {
    state.userData.part_time[payload.key] = payload.value
  },
  UPDATE_PARTNER_PART_TIME_DATA(state, payload) {
    state.partnerData.part_time[payload.key] = payload.value
  },
  TOGGLE_PARTNER_FORM(state) {
    state.enablePartnerForm = !state.enablePartnerForm
  },
  DISPLAY_SUMMARY(state, val) {
    state.displaySummary = val
  },
  UPDATE_INCOME_ASSET(state, payload) {
    state.incomeAssets[payload.index][payload.field] = payload.value
  },
  ADD_INCOME_ASSET(state) {
    state.incomeAssets.push({
      type: null,
      current_value: null,
      income: null,
      frequency: 'yearly',
      shared: true
    })
  },
  REMOVE_INCOME_ASSET(state, index) {
    state.incomeAssets.splice(index, 1)
  },
  UPDATE_CAREER_BREAK(state, payload) {
    state[payload.owner].career_breaks[payload.index][payload.field] = payload.value
  },
  ADD_CAREER_BREAK(state, owner) {
    state[owner].career_breaks.push({
      age: null,
      duration: null
    })
  },
  REMOVE_CAREER_BREAK(state, payload) {
    state[payload.owner].career_breaks.splice(payload.index, 1)
  },
  USER_HATES_MONEY(state, payload) {
    state.userHatesMoney = payload
  },
  REFRESH_DATA(state, target = 'all') {
    if (target === 'user' || target === 'all') state.userData = cloneObject(defaultRetCalcData)
    if (target === 'partner' || target === 'all')
      state.partnerData = cloneObject(defaultRetCalcData)
    if (target !== 'partner') {
      state.incomeAssets = [
        {
          type: null,
          current_value: null,
          income: null,
          frequency: 'yearly',
          shared: false
        }
      ]
    }
  },
  RESET_ASSUMPTIONS(state) {
    state.inflation = returnValues.inflation
    state.wageGrowth = returnValues.wageGrowth
    state.insurancePremium = returnValues.insurancePremium
    state.annualAdminFees = returnValues.annualAdminFees
    state.assumptionValues = returnValues.assumptionValues
  },

  SET_RETIREMENT_CALC_DATA(state, res) {
    state.graphData = res
  },

  UPDATE_INSURANCE_PREMIUM(state, value) {
    state.insurancePremium = value
  },

  UPDATE_ANNUAL_ADMIN_FEES(state, value) {
    state.annualAdminFees = value
  },

  GRAPH_LOADING(state, value) {
    state.graphLoading = value
  },

  UPDATE_RET_CALC_ASSUMPTION_VALUE(state, { type, value, label }) {
    state.assumptionValues = state.assumptionValues.map((el) => {
      if (el.type === type) {
        // fallback
        el.name = label
        el.rateOfReturn =
          type === 'superFundsDrawDown' && label === 'Starting assumption'
            ? 6.5
            : gc.INVESTMENT_RETURN_PERCENTAGES[value]
        if (el.type.toLowerCase() === 'super')
          el.taxOnEarnings = gc.INVESTMENT_TAX_ON_EARNINGS[value]
      }
      return el
    })
  },
  HIDE_PENSION(state, bool) {
    state.noAgePension = bool
  },
  SET_DISCLAIMER(state, disclaimer) {
    state.disclaimer = disclaimer
  },

  SET_RET_CALC_TAX_ON_EARNINGS(state, { type, value }) {
    state.assumptionValues = state.assumptionValues.map((el) => {
      if (el.type === type) {
        // fallback
        el.taxOnEarnings = value
      }
      return el
    })
  },
  SET_RET_CALC_INVEST_RETURN(state, { type, value }) {
    state.assumptionValues = state.assumptionValues.map((el) => {
      if (el.type === type) {
        // fallback
        el.rateOfReturn = value
      }
      return el
    })
  },
  SET_RET_CALC_INVEST_ADMIN_FEE(state, { type, value }) {
    state.assumptionValues = state.assumptionValues.map((el) => {
      if (el.type === type) {
        // fallback
        el.adminFee = value
      }
      return el
    })
  },
  SET_INVESTMENT_WAGE_GROWTH(state, value) {
    state.wageGrowth = value
  },
  SET_INVEST_INFLATION_RATE(state, value) {
    state.inflation = value
  }
}

// TODO: refactor this to component && js module unless absolutely necessary
const actions = {
  createLocalStorage() {
    window.localStorage.setItem('counter', '1')
  },

  incrementCounter() {
    let count = Number(window.localStorage.getItem('counter'))
    count += 1
    window.localStorage.setItem('counter', count.toString())
  },

  refreshRetCalc({ commit, state, dispatch }) {
    commit('REFRESH_DATA')
    commit('RESET_ASSUMPTIONS')
    commit('HIDE_PENSION', false)
    commit('USER_HATES_MONEY', false)
    if (state.enablePartnerForm) commit('TOGGLE_PARTNER_FORM')
    commit('DISPLAY_SUMMARY', false)
    dispatch('setMinContributionRates')
    window.scrollTo(0, 0)
  },
  setMinContributionRates({ commit }) {
    commit('UPDATE_USER_DATA', { field: 'contribution_rate', value: getMinSuperContributionRate() })
    commit('UPDATE_PARTNER_DATA', {
      field: 'contribution_rate',
      value: getMinSuperContributionRate()
    })
  },

  updateUserDataKeyPair({ commit }, object) {
    commit('UPDATE_USER_DATA', object)
  },

  updateUserPartTimeData({ commit }, object) {
    commit('UPDATE_USER_PART_TIME_DATA', object)
  },

  updatePartnerPartTimeData({ commit }, object) {
    commit('UPDATE_PARTNER_PART_TIME_DATA', object)
  },

  updatePartnerDataKeyPair({ commit }, object) {
    commit('UPDATE_PARTNER_DATA', object)
  },

  togglePartnerForm({ commit }) {
    commit('REFRESH_DATA', 'partner')
    commit('UPDATE_PARTNER_DATA', {
      field: 'contribution_rate',
      value: getMinSuperContributionRate()
    })
    commit('TOGGLE_PARTNER_FORM')
  },

  addCareerBreak({ commit }, owner: string) {
    commit('ADD_CAREER_BREAK', owner)
  },

  removeCareerBreak({ commit }, object: object) {
    commit('REMOVE_CAREER_BREAK', object)
    commit('RetirementCalculatorErrors/REMOVE_CAREER_BREAK_ERROR', object, { root: true })
  },

  userLikesMoney({ commit, dispatch }) {
    commit('GRAPH_LOADING', true)
    commit('HIDE_PENSION', false)
    commit('USER_HATES_MONEY', false)
    dispatch('getRetirementCalculatorData')
  },

  async getRetirementCalculatorData({ commit, dispatch, state }) {
    const valid = await dispatch(
      'RetirementCalculatorErrors/validateRetirementCalculatorFields',
      null,
      { root: true }
    )
    if (!valid) {
      commit('GRAPH_LOADING', false)
      return false
    }

    let income = state.userData.income
    let partnerIncome = state.partnerData.income ? state.partnerData.income : undefined
    if (state.userData.income_time_period === 'weekly') {
      income = state.userData.income * 52
      partnerIncome = partnerIncome ? partnerIncome * 52 : undefined
    } else if (state.userData.income_time_period === 'fortnightly') {
      income = state.userData.income * 26
      partnerIncome = partnerIncome ? partnerIncome * 26 : undefined
    } else if (state.userData.income_time_period === 'monthly') {
      income = state.userData.income * 12
      partnerIncome = partnerIncome ? partnerIncome * 12 : undefined
    }
    const uData = state.userData
    const partData = state.partnerData
    const bTaxCont =
      uData.before_tax_frequency === 'yearly'
        ? uData.before_tax_contribution
        : uData.before_tax_contribution * 12
    let pBTaxCont = null
    if (state.enablePartnerForm)
      pBTaxCont =
        partData.before_tax_frequency === 'yearly'
          ? partData.before_tax_contribution
          : partData.before_tax_contribution * 12
    const aTaxCont =
      uData.after_tax_frequency === 'yearly'
        ? uData.after_tax_contribution
        : uData.after_tax_contribution * 12
    const pATaxCont =
      partData.after_tax_frequency === 'yearly'
        ? partData.after_tax_contribution
        : partData.after_tax_contribution * 12

    const payload = {
      userGAS: income,
      userSAB: uData.current_super_balance,
      userDesiredRet: uData.desired_retirement_age,
      desiredRetIncome: uData.desired_retirement_income,
      insurancePremiums: state.insurancePremium,
      superFee: state.annualAdminFees,
      investmentAssumptions: [...state.assumptionValues],
      inflation: state.inflation,
      wageGrowth: state.wageGrowth,
      userSG: uData.contribution_rate,
      age: uData.age,
      additionalCont: bTaxCont,
      userBeforeTaxFrequency: uData.before_tax_frequency,
      afterTaxCont: aTaxCont,
      oneOffContribution: { age: uData.one_off_age, amount: uData.one_off_contribution },
      hasHome: uData.hasHome,
      hasPartner: state.enablePartnerForm,
      partnerAge: partData.age ? partData.age : undefined,
      partnerGAS: partnerIncome,
      partnerSAB: partData.current_super_balance ? partData.current_super_balance : undefined,
      partnerSG: partData.contribution_rate ? partData.contribution_rate : undefined,
      partnerRetAge: partData.desired_retirement_age ? partData.desired_retirement_age : undefined,
      pAdditionalCont: state.enablePartnerForm ? pBTaxCont : undefined,
      partnerBeforeTaxFrequency: partData.before_tax_frequency
        ? partData.before_tax_frequency
        : undefined,
      pAfterTaxCont: state.enablePartnerForm ? pATaxCont : undefined,
      pOneOffContribution: state.enablePartnerForm
        ? {
            age: partData.one_off_age,
            amount: partData.one_off_contribution
          }
        : undefined,
      investments: [],
      noAgePension: state.noAgePension,
      careerBreak: uData.career_breaks,
      pCareerBreak: partData.career_breaks,
      partTime: {
        age: uData.part_time.part_time_starting_age,
        duration: uData.part_time.part_time_duration,
        daysWorking: uData.part_time.part_time_days
      },
      pPartTime: {
        age: partData.part_time.part_time_starting_age,
        duration: partData.part_time.part_time_duration,
        daysWorking: partData.part_time.part_time_days
      }
    }

    if (state.incomeAssets) {
      _.forEach(state.incomeAssets, (el) => {
        if (el.type) {
          let income = 0
          if (el.type !== 'home') {
            const multiplier =
              el.frequency === 'monthly'
                ? 1
                : el.frequency === 'yearly'
                ? 1 / 12
                : el.frequency === 'fortnightly'
                ? 26 / 12
                : 52 / 12
            income = el.income * multiplier
          }
          const elName = el.type !== 'cashAndFixed' ? capitalize(el.type) : 'Cash'
          const investIndex = payload.investments.findIndex((investment) => {
            return investment.type === el.type
          })
          if (investIndex >= 0) {
            payload.investments[investIndex].monthly_income += income
            payload.investments[investIndex].value += el.current_value
          } else {
            payload.investments.push({
              name: elName,
              type: el.type,
              regular_deposit_amount_to_invest: 0,
              reinvesting_whats_left: 0,
              monthly_income: income,
              monthly_expenses: 0,
              value: el.current_value,
              using_to_support_retirement: 1,
              owner: 'single'
            })
          }
        }
      })
    }
    return await RetCalcService.postRetirementCalculatorData(payload)
      .then((res) => {
        dispatch('incrementCounter')
        commit('SET_RETIREMENT_CALC_DATA', res.data)
        commit('GRAPH_LOADING', false)
        window.scrollTo(0, 0)
        commit('DISPLAY_SUMMARY', true)
        const seriesData = mapAllRetCalcAssetsData(state)
        const doesUserHateMoney = seriesData[1].data.find(
          (el) => el.itemData.pensionSource === 'User has opted not to include the age pension.'
        )
        if (doesUserHateMoney) commit('USER_HATES_MONEY', true)
        else commit('USER_HATES_MONEY', false)
        return true
      })
      .catch((err) => {
        commit('GRAPH_LOADING', false)
        if (err.response.data?.field.startsWith('partner_')) {
          dispatch('addErrorFromBackend', err.response.data.field.slice(8))
          commit(
            'RetirementCalculatorErrors/UPDATE_PARTNER_ERROR',
            {
              value: err.response.data.message,
              field: err.response.data.field.slice(8)
            },
            { root: true }
          )
        } else {
          dispatch('addErrorFromBackend', err.response.data.field)
          commit(
            'RetirementCalculatorErrors/UPDATE_USER_ERROR',
            {
              value: err.response.data.message,
              field: err.response.data.field
            },
            { root: true }
          )
        }
        return false
      })
  },

  fetchPreservationAgesForRetCalc({ commit }) {
    commit('RetirementAges/SET_PRESERVATION_AGES_LOADING', true, { root: true })
    return RetCalcService.getPreservationAgesForRetCalc()
      .then((res) => {
        commit('RetirementAges/SET_PRESERVATION_AGES', res.data, { root: true })
        commit('RetirementAges/SET_PRESERVATION_AGES_LOADING', false, { root: true })
      })
      .catch((err) => {
        commit('RetirementAges/SET_PRESERVATION_AGES_LOADING', false)
        throw err
      })
  },

  setNoAgePension({ commit, dispatch }) {
    commit('GRAPH_LOADING', true)
    commit('HIDE_PENSION', true)
    dispatch('getRetirementCalculatorData')
  },

  getDisclaimer({ commit }) {
    getGraphAssumptions('CFS_ASSUMPTIONS_AND_LIMITATIONS')
      .then((response) => {
        if (response.data) {
          commit('SET_DISCLAIMER', response.data.data.assumptions)
        }
      })
      .catch((err) => {
        console.warn('error reading disclaimer data', err)
      })
  },
  addErrorFromBackend({ commit, rootGetters }, errDataField) {
    const errorLocationArray = rootGetters['RetirementCalculatorErrors/errorLocations']
    switch (errDataField) {
      case 'after_tax_contribution':
      case 'one_off_contribution':
      case 'one_off_age':
      case 'before_tax_contribution':
        if (!errorLocationArray.includes(2)) errorLocationArray.push(2)
    }
    commit('RetirementCalculatorErrors/SET_ERROR_LOCATIONS', errorLocationArray, { root: true })
  },

  setGraphLoading({ commit }, value) {
    commit('GRAPH_LOADING', value)
  },

  async downloadPDF({ state, getters }, pngs) {
    let incomeAssets = null
    if (state.incomeAssets.length > 0 && state.incomeAssets[0].type !== null) {
      incomeAssets = state.incomeAssets
    }
    const payload = {
      userData: getters.userData,
      partnerData: getters.partnerData,
      incomeAssets: incomeAssets,
      incomeStatsStatements: getters.incomeStatsStatements,
      graphs: pngs,
      assumptionValues: getters.assumptionValues,
      noAgePension: state.noAgePension
    }

    return RetCalcService.downloadPDF(payload)
  },

  async downloadPDFIphoneTest({ state, getters }, payload) {
    return RetCalcService.downloadPDF(payload)
  }
}

const mapSeriesData = (combinedAssetsData) => {
  const seriesData = []
  seriesData.push({
    name: 'Net assets',
    data: Object.keys(combinedAssetsData).map((mappingYearItem) => {
      // add offset and cash savings in the assets
      combinedAssetsData[mappingYearItem].startingValue += 0
      // Use the closing value as the value for the graph
      let value = combinedAssetsData[mappingYearItem].startingValue
      // add total savings
      if (combinedAssetsData[mappingYearItem].debtBalance) {
        value -= combinedAssetsData[mappingYearItem].debtBalance
      }
      return {
        y: Math.floor(value),
        itemData: combinedAssetsData[mappingYearItem],
        name: 'Net assets'
      }
    })
  })
  return seriesData
}

const reformatSeriesData = (seriesData, state) => {
  seriesData.map((el) => {
    el.data = el.data.map((dataEl) => {
      let renderUser = true
      let renderPartner = true
      if (el.name.toLowerCase() === 'super') {
        renderPartner = false
      } else if (el.name.toLowerCase() === 'partner super') {
        renderUser = false
      } else if (el.name.toLowerCase() !== 'net assets') {
        renderUser = false
        renderPartner = false
      }
      const date = new Date()
      const userDOB = moment(date.setFullYear(date.getFullYear() - state.userData.age)).format(
        'YYYY-MM-DD'
      )
      let partnerDOB = '0'
      if (state.partnerData.age !== null && state.enablePartnerForm) {
        const date = new Date()
        partnerDOB = moment(date.setFullYear(date.getFullYear() - state.partnerData.age)).format(
          'YYYY-MM-DD'
        )
      }
      const tooltip = assetsChartTooltip(
        dataEl.itemData,
        el.name,
        userDOB,
        partnerDOB,
        'You',
        'Partner',
        state.enablePartnerForm,
        state.userData.age,
        state.partnerData.age,
        renderUser,
        renderPartner
      )

      return {
        y: dataEl.y,
        tooltip: tooltip
      }
    })
    return el
  })
  return seriesData
}

const formatInitialSeriesData = (state) => {
  const combinedAssetsData = {}
  const tempSeriesData = state.graphData.asset.map((el) => {
    let assetName = ''
    let yearsData = []
    let type = ''
    const userBirthYear = new Date().getFullYear() - state.userData.age
    if (el.years) {
      yearsData = Object.keys(el.years)
        .filter((year) => Number(year) - userBirthYear <= 92)
        .map((mappingYearItem) => {
          assetName = el.years[mappingYearItem].name
          const isSuper = assetName.toLowerCase() === 'super'
          const isPartnerSuper = assetName.toLowerCase() === 'partner super'
          type = el.years[mappingYearItem].type
          if (isSuper || isPartnerSuper) {
            el.years[mappingYearItem].startingValue = el.years[mappingYearItem].startingValueSF
            el.years[mappingYearItem].closingValue = el.years[mappingYearItem].closingValueFull
          }

          const startingValue = el.years[mappingYearItem].startingValue
          const closingValue = el.years[mappingYearItem].closingValue
          // Annual growth can be negative!
          const annualGrowth = closingValue - startingValue

          // already present
          if (combinedAssetsData[mappingYearItem]) {
            combinedAssetsData[mappingYearItem] = {
              startingValue: combinedAssetsData[mappingYearItem].startingValue + startingValue,
              annualGrowth: combinedAssetsData[mappingYearItem].annualGrowth + annualGrowth,
              closingValue: combinedAssetsData[mappingYearItem].closingValue + closingValue,
              debtBalance: 0,
              year: mappingYearItem
            }
          } else {
            combinedAssetsData[mappingYearItem] = {
              startingValue: startingValue,
              annualGrowth: annualGrowth,
              closingValue: closingValue,
              debtBalance: 0,
              year: mappingYearItem
            }
          }
          return {
            y: Math.floor(startingValue),
            itemData: el.years[mappingYearItem],
            name: assetName
          }
        })
    }

    return {
      name: assetName,
      type: type,
      data: yearsData
    }
  })

  return { combinedAssetsData, tempSeriesData }
}

const mapAllRetCalcAssetsData = (state) => {
  const { combinedAssetsData, tempSeriesData } = formatInitialSeriesData(state)
  const seriesData = mapSeriesData(combinedAssetsData)
  seriesData.filter((el) => el.name.toLowerCase() === 'net assets')
  tempSeriesData.forEach((el) => {
    seriesData.push(el)
  })

  return seriesData
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
