<template>
  <!--  verification table -->
  <div
    class="flex flex-col items-center justify-center pb-[80px] bg-white rounded px-8 shadow-md shadow-grey-light">
    <div class="flex justify-between items-center w-full py-8">
      <h5 class="font-bold text-blue-1">{{ title }}</h5>
      <div class="align-middle flex items-center">
        <span class="w-[25rem]">
          <BaseInput
            name="search"
            placeholder="Search Member Id"
            v-model:value="search"
            :disabled="loading"
            clearable
            @cleared="fetchUsers" />
        </span>
        <OtivoButton class="ml-4" @click="fetchUsers" :disabled="loading">Search</OtivoButton>
      </div>
    </div>
    <table class="table-auto w-full border-collapse rounded">
      <thead class="rounded-t">
        <tr class="bg-blue-1 rounded-t uppercase text-white text-left">
          <th class="pt-4 px-4 pb-2 text-center rounded-tl">#</th>
          <th class="pt-4 px-4 pb-2">Account Created</th>
          <th class="pt-4 px-4 pb-2">Member ID</th>
          <th class="pt-4 px-4 pb-2">Super Account No</th>
          <th class="pt-4 px-4 pb-2">First Name</th>
          <th class="pt-4 px-4 pb-2">Last Name</th>
          <th class="pt-4 px-4 pb-2">Email</th>
          <th class="pt-4 px-4 pb-2">Mobile</th>
          <th class="pt-4 px-4 pb-2">Date of Birth</th>
          <th class="pt-4 px-4 pb-2">Status</th>
          <th class="pt-4 px-0 pb-2 rounded-tr text-center">Validate Members</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, index) in users" :key="index" class="border-b border-b-grey-4 text-left">
          <td class="p-4 text-center">{{ index + 1 }}</td>
          <td class="p-4">{{ user.account_created_at }}</td>
          <td class="p-4 font-black">{{ user.member_id }}</td>
          <td class="p-4 font-black" :class="user.masked ? 'blur-sm' : ''">
            {{ user.super_account_no }}
          </td>
          <td class="p-4 font-black" :class="user.masked ? 'blur-sm' : ''">
            {{ user.first_name }}
          </td>
          <td class="p-4 font-black" :class="user.masked ? 'blur-sm' : ''">{{ user.last_name }}</td>
          <td class="p-4" :class="user.masked ? 'blur-sm' : ''">{{ user.email }}</td>
          <td class="p-4" :class="user.masked ? 'blur-sm' : ''">{{ user.mobile_number }}</td>
          <td class="p-4" :class="user.masked ? 'blur-sm' : ''">{{ user.dob }}</td>
          <td class="p-4">
            {{ user.status }}
          </td>
          <td class="py-4 px-0">
            <div class="flex justify-end">
              <span v-if="!user.masked">
                <button
                  :class="[validBtnClasses(user)]"
                  class="button-2 inline-flex border rounded-l-sm px-6 py-4"
                  @click="setAccountStatus(user.id, 'valid')"
                  :disabled="user.masked">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-2"
                    :class="user.masked ? 'text-grey-3' : 'text-green-2'">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.6095 3.72378C14.1302 4.24448 14.1302 5.0887 13.6095 5.60939L6.13333 13.0855L2.39052 9.34273C1.86983 8.82203 1.86983 7.97781 2.39052 7.45711C2.91122 6.93641 3.75544 6.93641 4.27614 7.45711L6.13333 9.3143L11.7239 3.72378C12.2446 3.20308 13.0888 3.20308 13.6095 3.72378Z"
                      fill="currentColor" />
                  </svg>
                  Valid
                </button>
                <button
                  :class="[invalidBtnClasses(user)]"
                  class="button-2 inline-flex border rounded-r-sm px-6 py-4"
                  @click="setAccountStatus(user.id, 'invalid')"
                  :disabled="user.masked">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-2"
                    :class="user.masked ? 'text-grey-3' : 'text-red-1'">
                    <path
                      d="M12.6175 10.6482L9.84699 7.87768L12.6175 5.1072C12.8624 4.86227 13 4.53008 13 4.1837C13 3.83733 12.8624 3.50514 12.6175 3.26021C12.3725 3.01528 12.0404 2.87769 11.694 2.87769C11.3476 2.87769 11.0154 3.01528 10.7705 3.26021L8 6.03069L5.22951 3.26021C4.98459 3.01528 4.6524 2.87769 4.30602 2.87769C3.95964 2.87769 3.62745 3.01528 3.38252 3.26021C3.1376 3.50514 3 3.83733 3 4.1837C3 4.53008 3.1376 4.86227 3.38252 5.1072L6.15301 7.87768L3.38252 10.6482C3.1376 10.8931 3 11.2253 3 11.5717C3 11.918 3.1376 12.2502 3.38252 12.4952C3.62745 12.7401 3.95964 12.8777 4.30602 12.8777C4.6524 12.8777 4.98459 12.7401 5.22951 12.4952L8 9.72467L10.7705 12.4952C11.0154 12.7401 11.3476 12.8777 11.694 12.8777C12.0404 12.8777 12.3725 12.7401 12.6175 12.4952C12.8624 12.2502 13 11.918 13 11.5717C13 11.2253 12.8624 10.8931 12.6175 10.6482Z"
                      fill="currentColor" />
                  </svg>

                  Invalid
                </button>
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!--  ./ verification table  -->
  </div>
</template>

<script setup lang="ts">
import OtivoButton from '@/components/OtivoButton.vue'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import { onMounted, ref } from 'vue'
import getApiInstance from '@/services/Api.ts'
import { useToast } from '@/composables/useToast.ts'

type AccountDetails = {
  id: string
  account_id: string
  account_created_at: string
  status: string
  member_id: string
  verified_by: string
  verified_at: string
  super_account_no: string
  first_name: string
  last_name: string
  dob: string
  mobile_number: string

  email: string
  auth0id: string
  masked: boolean
}

type MemberStatus = 'pending' | 'valid' | 'invalid'

const emit = defineEmits(['updated'])

const api = getApiInstance()
const { errorToast, successToast } = useToast()

const users = ref<AccountDetails[]>([] as AccountDetails[])
const loading = ref(false)
const search = ref<string>('')
const status = ref<MemberStatus>('pending')
const title = ref<string>('Verify member')

const fetchUsers = async () => {
  users.value = []
  loading.value = true
  let params = {
    status: status.value,
  }
  if (search.value !== '') {
    params = { ...params, search: search.value }
  }
  const query = new URLSearchParams(params)
  const queryString = query.toString()

  try {
    const response = await api.Get(`/v3/callcentre/account-verifications?${queryString}`)
    users.value = response?.data.data
    await revealDetails(0)
    title.value = search.value == '' ? 'Verify member' : 'Results for Member ID ' + search.value
  } catch (e) {
    console.error(e)
    const message =
      search.value === ''
        ? 'Failed to fetch users'
        : `Failed to fetch user with member id of ${search.value}`
    errorToast(message)
  } finally {
    loading.value = false
  }
}

const revealDetails = async (index: number) => {
  const user = users.value[index]
  const response = await api.Get(`/v3/callcentre/account-verifications/${user.id}`)
  const details = response?.data.data
  users.value[index] = details
}

const setAccountStatus = async (id: number, status: MemberStatus) => {
  try {
    await api.Put(`/v3/callcentre/account-verifications/${id}`, { status: status })
    successToast('User verified')
    await fetchUsers()
    emit('updated')
  } catch (e) {
    console.error(e)
    errorToast('Failed to verify user')
  }
}

onMounted(() => {
  fetchUsers()
})

const validBtnClasses = (user: AccountDetails) => {
  return {
    'text-grey-3 bg-grey-5': user.masked,
    'border-grey-3 border-r-grey-4 text-grey-2 hover:bg-green-5 hover:text-green-2 hover:border-green-2':
      !user.masked && user.status === 'pending',
    'border-green-2 text-green-2 border-l-green-2 bg-green-5': user.status === 'valid',
  }
}
const invalidBtnClasses = (user: AccountDetails) => {
  return {
    'text-grey-3 bg-grey-5': user.masked,
    'border-grey-3 text-grey-2 border-l-grey-4 hover:bg-red-light hover:text-red-1 hover:border-red-1':
      !user.masked && user.status === 'pending',
    'border-red-1 text-red-1 bg-red-light border-l-red-1': user.status === 'invalid',
  }
}
</script>

<style scoped></style>
