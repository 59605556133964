<template>
  <div>
    <fitness-test-question-label
      :question-number="questionNumber"
      question=" I'll have enough for a comfortable retirement" />

    <number-rating :selected="enoughForRetirement" @click="setEnoughForRetirement" />
  </div>
</template>

<script>
import NumberRating from '../NumberRating/NumberRating.vue'

import { mapState } from 'vuex'
import FitnessTestQuestionLabel from '../QuestionsLabel/FitnessTestQuestionLabel.vue'

export default {
  name: 'EnoughForRetirement',
  components: { FitnessTestQuestionLabel, NumberRating },
  computed: {
    ...mapState({
      enoughForRetirement: (state) => state.fitnessTest.questions.enoughForRetirement,
    }),
  },

  props: {
    questionNumber: {
      type: String,
      default: '',
    },
  },

  methods: {
    setEnoughForRetirement(value) {
      this.$store.dispatch('setFitnessTestEnoughForRetirement', value)
    },
  },
}
</script>

<style scoped></style>
