import api from '@/services/Api.ts'
import { IAccountFlagsStore } from '@/store/pinia/AccountFlagsStore.ts'
import { ISuperStore } from '@/store/pinia/SuperStore.ts'

export default (
  callcentreStore,
  accountFlagsStore: IAccountFlagsStore,
  superStore: ISuperStore,
) => {
  api().setClientHeader('')
  callcentreStore.reset()
  accountFlagsStore.reset()
  superStore.reset()
  localStorage.removeItem('activeManagedUser')
}
