<template>
  <div class="text-center fix-width-m-auto" id="cfs-retirement-calculator">
    <div id="ret-calc-ud-container" v-if="!displaySummary" class="p-8 bg-white">
      <retirement-calculator-user-data @click="validateAndSave" />
    </div>
    <retirement-calculator-summary v-else @click="validateAndSave" />
  </div>
</template>
<script>
import RetirementCalculatorUserData from '@/components/CFS/RetirementCalculatorComponents/RetirementCalculatorUserData.vue'
import RetirementCalculatorSummary from '@/components/CFS/RetirementCalculatorComponents/RetirementCalculatorSummary.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CFSRetirementCalculator',
  components: { RetirementCalculatorSummary, RetirementCalculatorUserData },
  computed: {
    ...mapGetters('RetirementCalculator', ['displaySummary', 'disclaimer'])
  },
  created() {
    this.$store.dispatch('fetchSuperData').then(() => {
      // Calling the refresh in order to set the default super contribution and remove possible data from previous calculations
      this.$store.dispatch('RetirementCalculator/refreshRetCalc')
    })
    this.createLocalStorage()
    this.fetchPreservationAgesForRetCalc()
  },
  methods: {
    ...mapActions('RetirementCalculator', [
      'fetchPreservationAgesForRetCalc',
      'getRetirementCalculatorData',
      'createLocalStorage'
    ]),
    validateAndSave() {
      this.getRetirementCalculatorData()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/themes/cfs/calculator.scss';
</style>
