import { userRole } from '@/lib/AuthenticatorPlugin.ts'
import { inject } from 'vue'

export interface ICheckUser {
  isAdmin: () => boolean
  isUser: () => boolean
}

const adminUserRoles = ['superadmin', 'admin', 'callcentre', 'backoffice']
const isAdmin = () => {
  return adminUserRoles.includes(userRole.value)
}

const roles = ['member', 'standard']
const isUser = () => {
  return roles.includes(userRole.value)
}

export const checkUserSymbol = Symbol('checkUser')
export const provideObject = {
  isAdmin,
  isUser,
}

export const useCheckUser = () => {
  return inject(checkUserSymbol, provideObject)
}
