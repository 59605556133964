import moment from 'moment'
import _ from 'lodash'
import ProfileService from '@/services/ProfileService'
import { updateDependentObjectBeforePosting } from '@/lib/ProfileHelper'
import { calculateAge, capitalize } from '@/lib/helpers'
import { updateSuperObjectBeforePosting } from '@/lib/RetirementPlanHelper'
import {
  dateOfBirthValidation,
  validateEmailAddress,
  validatePhoneNumber
} from '@/lib/ValidationHelper'
import globalConstants from '@/GlobalConstants/globalConstants'

const gc = globalConstants()

const state = () => ({
  doesUserHaveAPartner: false,
  partner_info: {
    firstName: '',
    lastName: '',
    forceTriggerPartnerModal: false, // trigger partner supp form
    isPartnerSuppFormTriggeredAndFilled: false,
    work_status: 'employed',
    gross_annual_salary: null,
    monthly_income: null,
    net_annual_salary: null,
    email: '',
    dob: '',
    partner_mobile: null,
    partnerErrors: {
      firstName: '',
      lastName: '',
      email: '',
      partnerMobile: '',
      dob: '',
      contributionRate: '',
      retirementAge: ''
    }
  }
})

const getters = {
  partnerInfo(state) {
    return state.partner_info
  },

  getPartnerAge(state) {
    return state.partner_info.dob ? calculateAge(new Date(state.partner_info.dob)) : 0
  },

  doesUserHaveAPartner(state, rootGetters) {
    if (!state.doesUserHaveAPartner) {
      return (
        rootGetters.doesHaveAPartnerInvestment ||
        rootGetters.doesHaveAPartnerSavings ||
        rootGetters.doesHaveAPartnerInsurance ||
        rootGetters.doesHaveAPartnerLiability ||
        rootGetters.doesHaveARetirementPartner
      )
    }
    return state.doesUserHaveAPartner
  },

  partnerFirstName(state) {
    let name =
      state.partner_info.firstName && state.partner_info.firstName
        ? state.partner_info.firstName
        : 'Partner'
    name = name.length > 10 ? `${name.slice(0, 10)}...` : name
    return capitalize(name)
  },

  isPartnerRetired(state) {
    return state.partner_info.work_status === 'retired'
  },

  doesPartnerSuppFormFilled(state, rootGetters) {
    return state.doesUserHaveAPartner
      ? state.partner_info.isPartnerSuppFormTriggeredAndFilled
      : true
  },

  isPartnerSuppFormTriggeredAndFilled(state) {
    return state.partner_info.isPartnerSuppFormTriggeredAndFilled
  },

  showPartnerElement(state, rootGetters) {
    return (
      rootGetters.doesUserHaveAPartner && state.partner_info.isPartnerSuppFormTriggeredAndFilled
    )
  },

  showPartnerMobileNumber(state, rootGetters) {
    if (state.partner_info.email && rootGetters.profile.email) {
      return state.partner_info.email.toLowerCase() === rootGetters.profile.email.toLowerCase()
    }

    return false
  }
}

const mutations = {
  SET_PARTNER_INFO(state, payload) {
    let partnerInfoFilled = true

    if (payload.firstName) {
      // user have partner
      state.doesUserHaveAPartner = true
      state.partner_info.firstName = payload.firstName
    } else {
      partnerInfoFilled = false
    }

    if (payload.lastName) {
      state.partner_info.lastName = payload.lastName
    }

    if (payload.partner_gross_annual_salary) {
      state.partner_info.gross_annual_salary = payload.partner_gross_annual_salary
    }

    if (payload.partner_monthly_income) {
      state.partner_info.monthly_income = payload.partner_monthly_income
    }

    if (payload.partner_net_annual_salary) {
      state.partner_info.net_annual_salary = payload.partner_net_annual_salary
    }

    if (payload.partner_work_status) {
      state.partner_info.work_status = payload.partner_work_status
    } else {
      partnerInfoFilled = false
    }

    if (payload.partner_mobile !== null) {
      state.partner_info.partner_mobile = payload.partner_mobile
    }

    if (payload.dob !== null) {
      state.partner_info.dob = payload.dob
    } else {
      partnerInfoFilled = false
    }

    if (payload.email !== null) {
      state.partner_info.email = payload.email
    } else {
      partnerInfoFilled = false
    }

    state.partner_info.isPartnerSuppFormTriggeredAndFilled = partnerInfoFilled
  },

  REMOVE_PARTNER(state) {
    state.doesUserHaveAPartner = false
    state.partner_info.firstName = null
    state.partner_info.lastName = null
    state.partner_info.gross_annual_salary = null
    state.partner_info.monthly_income = null
    state.partner_info.net_annual_salary = null
    state.partner_info.work_status = null
    state.partner_info.partner_mobile = null
    state.partner_info.dob = null
    state.partner_info.email = null
    state.partner_info.isPartnerSuppFormTriggeredAndFilled = false
    state.partner_info.forceTriggerPartnerModal = false
  },

  SET_DOES_USER_HAVE_PARTNER(state, value) {
    state.doesUserHaveAPartner = value
  },

  SET_IS_PARTNER_SUPP_TRIGGERED_AND_FILLED(state, value) {
    state.partner_info.isPartnerSuppFormTriggeredAndFilled = value
  },

  FORCE_TRIGGER_PARTNER_SUPPLEMENTARY_MODAL(state, payload) {
    state.partner_info.forceTriggerPartnerModal = payload
  },

  SET_PARTNER_EMPLOYMENT_SITUATION(state, value) {
    state.partner_info.work_status = value
  },

  SET_PARTNER_DOB(state, value) {
    state.partner_info.dob = value
  },

  SET_PARTNER_EMAIL(state, value) {
    state.partner_info.email = value
  },

  SET_PARTNER_GROSS_ANNUAL_SALARY(state, value) {
    state.partner_info.gross_annual_salary = value
  },

  SET_PARTNER_MONTHLY_INCOME(state, value) {
    state.partner_info.monthly_income = value
  },

  SET_PARTNER_NET_ANNUAL_SALARY(state, value) {
    state.partner_info.net_annual_salary = value
  },

  SET_PARTNER_INFO_ERRORS(state, { property, error }) {
    state.partner_info.error[property] = error
  },

  SET_PARTNER_FIRST_NAME(state, value) {
    state.partner_info.firstName = value
  },

  SET_PARTNER_LAST_NAME(state, value) {
    state.partner_info.lastName = value
  },

  UPDATE_PARTNER_MOBILE(state, value) {
    state.partner_info.partner_mobile = value
  },
  RESET_PARTNER_ERRORS(state) {
    state.partner_info.partnerErrors = {
      firstName: '',
      lastName: '',
      email: '',
      partnerMobile: '',
      dob: '',
      contributionRate: '',
      retirementAge: ''
    }
  }
}

const actions = {
  setPartnerEmploymentSituation({ commit, dispatch }, value) {
    if (value === 'retired') {
      dispatch('setPartnerGrossAnnualSalary', 0)
      dispatch('setPartnerMonthlyIncome', 0)
    }
    commit('SET_PARTNER_EMPLOYMENT_SITUATION', value)
  },

  setPartnerGrossAnnualSalary({ commit, rootGetters, dispatch }, value) {
    commit('SET_PARTNER_GROSS_ANNUAL_SALARY', value)
  },

  setPartnerMonthlyIncome({ commit }, value) {
    commit('SET_PARTNER_MONTHLY_INCOME', value)
  },

  setPartnerNetAnnualSalary({ commit }, value) {
    commit('SET_PARTNER_NET_ANNUAL_SALARY', value)
  },

  async setPartnerDOB({ commit, dispatch, rootGetters }, value) {
    const partnerPreservationAge = await dispatch('RetirementAges/getPreservationAgeForBirthDate', {
      birthDate: rootGetters.profile.dob,
      isUser: false
    })
    const partnerPensionAge = await dispatch('RetirementAges/getPensionAgeForBirthDate', {
      birthDate: rootGetters.partnerInfo.dob,
      isUser: false
    })
    commit('SET_PARTNER_AGE_PENSION', partnerPreservationAge)
    commit('SET_PARTNER_RETIREMENT_AGE', partnerPensionAge)
    commit('SET_PARTNER_DOB', value)
  },

  setPartnerEmail({ commit }, value) {
    commit('SET_PARTNER_EMAIL', value)
  },

  putPartnerSupplementaryInfo({ getters, rootGetters }, { partnerSuper }) {
    const info = getters.partnerInfo
    const retirementAge = rootGetters.getPartnerRetirementAge
    const contributionRate = rootGetters.getPartnerContributionRate
    const payload = {}
    payload.partner_first_name = info.firstName
    payload.partner_last_name = info.lastName
    payload.partner_work_status = info.work_status
    payload.partner_gross_annual_salary = info.gross_annual_salary
    payload.partner_mobile = info.partner_mobile
    payload.partner_dob = moment(info.dob).format('YYYY-MM-DD')
    payload.partner_email = info.email
    payload.partner_contribution_rate = contributionRate
    if (retirementAge) payload.partner_desired_retirement_age = retirementAge
    if (partnerSuper) {
      payload.super = updateSuperObjectBeforePosting(partnerSuper.dataObject)
    }
    if (
      rootGetters.getIndexOfFirstMortgage !== -1 &&
      rootGetters.liabilities[rootGetters.getIndexOfFirstMortgage].id
    ) {
      payload.liability_id = rootGetters.liabilities[rootGetters.getIndexOfFirstMortgage].id
      payload.liability_owner = rootGetters.liabilities[rootGetters.getIndexOfFirstMortgage].owner
    }

    // post dependents
    const dependents = rootGetters.profile.supplementary_info.dependents.filter(
      (el) => el.name !== ''
    )
    if (dependents.length > 0)
      ProfileService.putDependents(dependents.map(updateDependentObjectBeforePosting))
    return ProfileService.putPartnerInfo(payload)
  },

  async removePartnerDataRefresh({ commit, dispatch }) {
    await dispatch('emptyAllLiabilities')
    await dispatch('emptyAllInvestmentsPlans')
    await dispatch('emptyAllInsurancePlans')
    await dispatch('emptyAllSavingsPlans')
    await dispatch('fetchUser')
    await commit('REMOVE_PARTNER')
    await commit('SET_DOES_USER_HAVE_PARTNER', false)
  },

  setPartnerInfo({ commit, rootGetters }, payload) {
    commit('SET_PARTNER_INFO', payload)
  },

  setPartnerInfoErrors({ commit, rootGetters }, payload) {
    commit('SET_PARTNER_INFO_ERRORS', payload)
  },

  setPartnerFirstName({ commit }, value) {
    commit('SET_PARTNER_FIRST_NAME', value)
  },

  setPartnerLastName({ commit }, value) {
    commit('SET_PARTNER_LAST_NAME', value)
  },

  calculatePartnerMonthlySalary({ getters, rootGetters, dispatch }) {
    return ProfileService.getMonthlySalaryFromGrossAnnualSalary({
      grossAnnualSalary: getters.partnerInfo.gross_annual_salary,
      superRate: rootGetters.retirement.partnerContributionRate,
      additionalIncome: rootGetters.retirement.partnerAdditionalContribution
    }).then((res) => {
      dispatch('setPartnerMonthlyIncome', res.data.netSalaryPerMonth)
    })
  },

  updatePartnerMobile({ commit }, value) {
    commit('UPDATE_PARTNER_MOBILE', value)
  },

  resetPartnerErrors({ commit }) {
    commit('RESET_PARTNER_ERRORS')
  },

  // partnerSuper ({ dispatch, getters }) {
  //   const owner = this.globalConstants.OWNERS.PARTNER.VALUE
  //   let index = _.findIndex(getters.supers, { owner: owner })
  //
  //   if (index === -1) {
  //     dispatch('addSuper', { owner: owner })
  //     index = _.findIndex(getters.supers, { owner: owner })
  //   }
  //
  //   return {
  //     index: index,
  //     dataObject: getters.supers[index]
  //   }
  // },

  validatePartnerInfo({ dispatch, getters, rootGetters }, partnerQuestionCount) {
    let validated = true
    dispatch('resetPartnerErrors')
    const partnerInfo = getters.partnerInfo
    if (partnerInfo.firstName === '') {
      validated = false
      partnerInfo.partnerErrors.firstName = 'Please provide valid first name'
    }

    if (partnerInfo.lastName === '') {
      validated = false
      partnerInfo.partnerErrors.lastName = 'Please provide valid last name'
    }

    if (partnerQuestionCount >= 2) {
      if (!validateEmailAddress(partnerInfo.email)) {
        validated = false
        partnerInfo.partnerErrors.email = 'Please provide valid email'
      }
      if (getters.showPartnerMobileNumber && !validatePhoneNumber(partnerInfo.partner_mobile)) {
        validated = false
        partnerInfo.partnerErrors.partnerMobile = 'Please provide valid phone number'
      }
    }

    if (partnerQuestionCount >= 3) {
      const bDateValidation = dateOfBirthValidation(partnerInfo.dob)

      if (!bDateValidation.validated) {
        validated = false
        partnerInfo.partnerErrors.dob = bDateValidation.message
      }
    }

    if (partnerQuestionCount >= 5) {
      if (partnerInfo.work_status === 'employed') {
        if (partnerInfo.gross_annual_salary <= 0 || partnerInfo.gross_annual_salary === null) {
          validated = false
          partnerInfo.partnerErrors.grossAnnualSalary =
            'If $0, please change your employment situation'
        }
      }

      if (partnerInfo.gross_annual_salary === null && partnerInfo.work_status !== 'employed') {
        dispatch('setPartnerGrossAnnualSalary', 0)
      }
    }

    if (partnerQuestionCount >= 6) {
      const owner = gc.OWNERS.PARTNER.VALUE
      let index = _.findIndex(getters.supers, { owner: owner })

      if (index === -1) {
        dispatch('addSuper', { owner: owner })
        index = _.findIndex(getters.supers, { owner: owner })
      }

      const partnerSuper = {
        index: index,
        dataObject: getters.supers[index]
      }

      if (partnerSuper.dataObject.balance === null) {
        dispatch('setSuperBalance', { index: partnerSuper.index, value: 0 })
      }
    }
    // if (!rootGetters.getPartnerContributionRate && partnerQuestionCount >= 7) {
    //   partnerInfo.partnerErrors.contributionRate = 'Please confirm, you can modify the number after confirming'
    //   validated = false
    // }
    return validated
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
