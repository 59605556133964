<template>
  <div class="inline-flex items-center gap-[20px]">
    <div v-if="textFalse" class="inline-block paragraph-1" @click="handleFalse">
      {{ textFalse }}
    </div>

    <!-- focus ring-->
    <div
      class="relative inline-flex items-center cursor-pointer gap-[20px] ring-2 ring-transparent rounded-circle focus-within:ring-[#e8e5fb]"
      :class="{
        'hover:ring-[#e8e5fb]': !disabled
      }">
      <label
        id="toggle-label"
        class="border-[2px] rounded-circle"
        :class="[
          checked ? 'border-otivo-blue' : 'border-grey-4',
          disabled ? 'cursor-not-allowed border-grey-1' : 'cursor-pointer',
          'hover:bg-blue-1 hover:border-blue-1 focus-within:border-blue-1 group'
        ]"
        :for="name">
        <input
          :id="name"
          :checked="checked"
          class="sr-only peer paragraph-1"
          :class="[checked ? 'border-otivo-blue' : 'border-grey-3']"
          type="checkbox"
          @input="handleChange"
          :disabled="disabled" />
        <div
          :class="[
            checked
              ? 'bg-otivo-blue peer-focus:text-blue-1 peer-focus:bg-blue-1 group-hover:bg-blue-1 '
              : 'bg-grey-2',
            { 'group-hover:text-blue-1': !disabled },
            'w-[55px] h-[30px] bg-grey-2 peer rounded-circle border-0 peer-checked:bg-otivo-blue group-hover:peer-checked:bg-blue-1',
            'ring-2 ring-transparent peer-checked:ring-transparent peer-disabled:bg-grey-4 peer-disabled:text-grey-4',
            'peer-disabled:pointer-events-none peer-disabled:cursor-not-allowed peer-disabled:ring-grey-4 peer-disabled:bg-grey-4'
          ]">
          <span
            :class="[
              checked ? 'translate-x-[26px] ring-otivo-blue ' : 'translate-x-0 ring-grey-2',
              'pointer-events-none relative inline-flex items-center mt-0.5 mx-[1px] h-[27px] w-[27px] transform rounded-circle',
              'bg-white shadow transition duration-200 ease-in-out peer-checked:group-hover:bg-blue-1'
            ]">
            <span
              :class="[
                checked
                  ? 'opacity-0 duration-100 ease-out'
                  : 'opacity-100 duration-200 ease-in text-grey-2',
                'absolute inset-0 p-1 flex h-full w-full items-center justify-center transition-opacity'
              ]"
              aria-hidden="true">
              <svg
                v-if="$props.icons"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                class="w-full h-full">
                <path
                  d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" />
              </svg>
            </span>
            <span
              :class="[
                checked
                  ? 'opacity-100 duration-200 ease-in text-otivo-blue group-hover:text-blue-1'
                  : 'opacity-0 duration-100 ease-out',
                'absolute p-1 flex h-full w-full items-center justify-center transition-opacity'
              ]"
              aria-hidden="true">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                v-if="$props.icons"
                viewBox="0 0 16 16"
                fill="currentColor"
                class="w-full h-full">
                <path
                  fill-rule="evenodd"
                  d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                  clip-rule="evenodd" />
              </svg>
            </span>
          </span>
        </div>
      </label>
    </div>

    <div v-if="textTrue" class="inline-block paragraph-1" @click="handleTrue">
      {{ textTrue }}
    </div>
  </div>
</template>

<script lang="ts" setup>
type Props = {
  name: string
  icons: boolean
  checked: boolean
  textTrue?: string
  textFalse?: string
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  textTrue: '',
  textFalse: '',
  disabled: false,
  icons: true
})
const emit = defineEmits<{
  (e: 'update:checked', value: boolean): void
}>()

const handleChange = (event: Event) => {
  emit('update:checked', (event.target as HTMLInputElement).checked)
}

const handleTrue = () => {
  emit('update:checked', true)
}
const handleFalse = () => {
  emit('update:checked', false)
}

const classes = {
  toggle: [props.checked ? 'bg-otivo-blue' : 'bg-grey-2'],
  icon: [props.checked ? 'ease-in text-otivo-blue' : 'opacity-0 duration-100 ease-out']
}
</script>
