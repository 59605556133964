<template>
  <div>
    <div v-if="state.graphLoaded" class="pb-[20px] m-auto mt-[40px]">
      <Graph
        id="super-widget-graph"
        :loading="state.loading"
        :items="superWidgetGraphData?.options || []"
        :key="componentKey" />
    </div>
    <Loader v-if="!state.graphLoaded && state.isEligibleForRecommendations" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import Graph from '@/components/SuperWidget/Graph.vue'
import Loader from '@/components/Loader/Loader.vue'
import { state } from '@/components/CFS/IntrafundInvestment/superInvestmentsState.ts'
import { useIntrafundAdviceStore } from '@/store/pinia/IntrafundAdviceStore.ts'

const superWidgetGraphData = computed(() => useIntrafundAdviceStore().getSuperWidgetGraphData)
const componentKey = ref(0)

/**
 * This is a temporary solution to force the graph to re-render when the data changes.
 * TODO: We should come back and review this to see if we can do it in a better way.
 */
watch(
  () => superWidgetGraphData.value?.options,
  (newVal, oldVal) => {
    if (JSON.stringify(newVal) == JSON.stringify(oldVal)) return
    componentKey.value += 1
  },
  {
    deep: true,
  },
)
</script>

<style scoped></style>
