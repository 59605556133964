import type { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js'
import { Auth0Client, createAuth0Client, RedirectLoginResult } from '@auth0/auth0-spa-js'
import auth0, { Auth0DecodedHash, Auth0ParseHashError } from 'auth0-js'

const RESPONSE_TYPE = 'token id_token'

interface AuthenticatorOptions {
  domain: string
  clientId: string
  audience: string
  scope: string
  useRefreshTokens: boolean
  useTokenFallback: boolean
}

export type LoginObject = {
  email: string
  password: string
  connection?: string
  realm?: string
  redirectUri: string
}

export interface ISignupObject {
  firstName: string
  lastName: string
  email: string
  password: string
  connection: string
  redirectUri: string
}
export default class Authenticator {
  public auth0Client!: Auth0Client
  private readonly domain: string
  private readonly clientId: string
  private readonly audience: string
  private readonly scope: string
  private readonly useRefreshTokens: boolean
  private readonly useTokenFallback: boolean
  private readonly getTokenSilentlyOptions: GetTokenSilentlyOptions

  constructor(options: AuthenticatorOptions) {
    this.domain = options.domain
    this.clientId = options.clientId
    this.audience = options.audience
    this.scope = options.scope
    this.useRefreshTokens = options.useRefreshTokens
    this.useTokenFallback = options.useTokenFallback
    this.getTokenSilentlyOptions = {
      authorizationParams: {
        audience: options.audience,
        scope: options.scope,
      },
    }
  }

  public async createClient(): Promise<void> {
    const client = await createAuth0Client({
      domain: this.domain,
      clientId: this.clientId,
      useRefreshTokens: this.useRefreshTokens,
      useRefreshTokensFallback: this.useTokenFallback,
    }).catch((error) => {
      console.warn('error creating auth0 client', error)
    })

    this.auth0Client = client as Auth0Client
  }

  public createAccount(authorizationParams?: GetTokenSilentlyOptions) {
    this.auth0Client.loginWithRedirect(authorizationParams).catch((error) => {
      console.warn('error creating account', error)
    })
  }

  public async login(authorizationParams?: GetTokenSilentlyOptions) {
    await this.auth0Client.loginWithRedirect(authorizationParams).catch((error) => {
      console.warn('error getting token', error)
    })
  }

  public logout(logoutParams: { returnTo: string }) {
    this.auth0Client
      .logout({
        logoutParams: logoutParams,
      })
      .catch((error) => {
        console.error(error)
      })
  }

  public checkSession = async () => {
    await this.auth0Client.checkSession(this.getTokenSilentlyOptions)
  }

  public async handleRedirectCallback(url?: string): Promise<RedirectLoginResult> {
    return await this.auth0Client.handleRedirectCallback(url)
  }

  public async checkIfAuthenticated() {
    return await this.auth0Client.isAuthenticated()
  }

  public getUser = async () => {
    return await this.auth0Client.getUser()
  }

  public getAccessToken = async (
    options: GetTokenSilentlyOptions = this.getTokenSilentlyOptions,
  ): Promise<null | string> => {
    return (await this.auth0Client.getTokenSilently(options)) as string
  }

  public getIdTokenClaims = async () => {
    return await this.auth0Client.getIdTokenClaims()
  }

  public getTokenWithPopup = async (
    options: GetTokenSilentlyOptions = this.getTokenSilentlyOptions,
  ): Promise<null | string> => {
    return (await this.auth0Client.getTokenWithPopup(options)) as string
  }

  public signUpWithSocial = (payload: object) => {
    const webInstance = this.getAuth0WebInstance()
    return webInstance.authorize(payload)
  }

  public signUpWithEmail = async (signUpObject: ISignupObject): Promise<boolean> => {
    const webInstance = this.getAuth0WebInstance(signUpObject.redirectUri)

    return new Promise((resolve, reject) => {
      webInstance.signup(signUpObject, async (err: Error) => {
        if (err) {
          reject(err)
        } else {
          webInstance.login({
            username: signUpObject.email,
            password: signUpObject.password,
          })
        }
      })
    })
  }

  public loginWithEmail = async (loginObject: LoginObject) => {
    const webInstance = this.getAuth0WebInstance()
    return new Promise((resolve, reject) => {
      webInstance.login(loginObject, (err: Error) => {
        if (err) {
          reject(err)
        } else {
          resolve(true)
        }
      })
    })
  }

  public parse = async (redirectUri?: string) => {
    const webInstance = this.getAuth0WebInstance(redirectUri)
    return new Promise((resolve, reject) => {
      webInstance.parseHash((err: Auth0ParseHashError, authResult: Auth0DecodedHash) => {
        if (err) {
          reject(err)
        } else {
          resolve(authResult)
        }
      })
    })
  }

  public getAuth0WebInstance = (redirectUri = '/signUp/step3') => {
    return new auth0.WebAuth({
      domain: this.domain,
      clientID: this.clientId,
      redirectUri: window.location.origin + redirectUri,
      audience: this.audience,
      responseType: RESPONSE_TYPE,
      scope: this.scope,
      rememberLastLogin: false,
    })
  }
}
