<template>
  <div class="md:text-left pt-12 pb-5">
    <p class="font-bold text-gray-900 mb-4 bold h5">Welcome back for another year!</p>
    <p class="text-blue-1 text-lg mb-8 paragraph-1">
      Thanks for choosing us as your advice partner for another 12 months. To make sure our advice
      is up to date, please update your
      <router-link
        :to="{ name: 'clientFinancialSummary' }"
        class="text-otivo-blue font-bold underline"
        @click="closeModal">
        financial summary
      </router-link>
      or
      <router-link
        :to="{ path: '/dashboard/details' }"
        class="text-otivo-blue font-bold underline"
        @click="closeModal">
        personal info
      </router-link>
      if you've had any big changes in your life like a pay rise (nice work!) or a new house or car.
    </p>

    <!-- Action button -->
    <OtivoButton @click="closeModal" class="button-1 h-[50px]">
      My info hasn't changed
    </OtivoButton>
  </div>
</template>

<script setup lang="ts">
import { useModalStore } from '@/store/pinia/ModalStore'
import OtivoButton from '../OtivoButton.vue'

const modalStore = useModalStore()

const closeModal = () => {
  modalStore.closeModal()
}
</script>
