<template>
  <div>
    <fitness-test-question-label
      :question-number="questionNumber"
      question="I can comfortably pay off my credit card and other short-term debts each month" />

    <number-rating :selected="canPayCreditCard" @click="setCanPayCreditCard" />
  </div>
</template>

<script>
import NumberRating from '../NumberRating/NumberRating.vue'

import { mapState } from 'vuex'
import FitnessTestQuestionLabel from '../QuestionsLabel/FitnessTestQuestionLabel.vue'

export default {
  name: 'CanPayCreditCard',
  components: { FitnessTestQuestionLabel, NumberRating },
  computed: {
    ...mapState({
      canPayCreditCard: (state) => state.fitnessTest.questions.canPayCreditCard,
    }),
  },

  props: {
    questionNumber: {
      type: String,
      default: '',
    },
  },

  methods: {
    setCanPayCreditCard(value) {
      this.$store.dispatch('setFitnessTestCanPayCreditCard', value)
    },
  },
}
</script>

<style scoped></style>
