<template>
  <OtivoButton v-if="isItSuncorpstaff" colour="white" @click="openStaffBenefitsInNewTab()">
    Staff benefits
  </OtivoButton>
</template>

<script>
import OtivoButton from '@/components/OtivoButton.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'SunCorpStaffBenefitsButton',
  components: { OtivoButton },
  emits: ['click'],
  methods: {
    openStaffBenefitsInNewTab() {
      window.open(
        'https://suncorprecognition.rewardgateway.com/Authentication/Login?r=login&page=login&it=%2FSmartPage%2Fgroup_benefits',
        '_blank'
      )
      this.$emit('click')
    }
  },

  computed: {
    ...mapGetters(['isItSuncorpstaff'])
  }
}
</script>

<style scoped></style>
