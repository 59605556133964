<template>
  <div>
    <retirement-calculator-investment-charts
      id="cfs-calculator-charts"
      class="py-6 px-2 md:p-6 bg-white shadow-lg rounded" />

    <div class="my-12 shadow-lg rounded">
      <input-error
        class="md:hidden pb-6"
        v-if="errorLocations.length > 0 && !errorLocations.includes(selectedTab)"
        error="Select other options" />
      <input-select-box
        class="block md:hidden mb-6 mx-auto min-h-[40px]"
        style="max-width: calc(100% - 40px)"
        :styles="{
          button: 'border-cfs-blue min-h-[70px]',
          arrow: 'text-cfs-blue',
        }"
        name=""
        :options="tabNameArray"
        :item-errors="errorLocations"
        @update-select-box="changeTabFromDropdown" />
      <tab-buttons
        id="calculator-tab-buttons"
        class="hidden md:flex"
        @selected="changeTab"
        :classes="{
          active: 'bg-white border-[#e5ecf9] text-cfs-blue',
          inactive: 'bg-[#e5ecf9] border-[#e5ecf9] hover:bg-white text-cfs-blue',
        }"
        :selected="selectedTab"
        :buttons="formTabs"
        :error-list="errorLocations" />
      <div id="tabs-container" class="bg-white p-8">
        <component
          :is="formTabs[selectedTab].page"
          :loading="loading"
          @loaded="() => (loading = false)"
          @click="this.$emit('click')" />
        <input-error
          v-if="errorLocations.length > 0 && !errorLocations.includes(selectedTab)"
          error="Check your inputs in the other options" />
      </div>
    </div>
    <div class="flex gap-4 justify-center">
      <custom-button
        id="download-pdf-button"
        colour="cfs-button"
        class="border rounded-circle p-5 w-44 button-2"
        :disabled="downloadingPDF"
        :loading="downloadingPDF"
        @click="downloadRetirementCalcPDF">
        Download
      </custom-button>
      <custom-button
        id="start-calculator-again-button"
        colour="white"
        custom-style="color: #164A9A"
        class="border rounded-circle p-5 w-44 button-2"
        @click="refreshRetCalc">
        Start again
      </custom-button>
    </div>
  </div>
</template>

<script>
import RetirementCalculatorInvestmentCharts from '@/components/CFS/RetirementCalculatorComponents/RetirementCalculatorInvestmentCharts.vue'
import TabButtons from '@/components/BasicInputs/TabButtons.vue'
import { defineAsyncComponent, markRaw } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import CustomButton from '@/components/CustomButton/CustomButton.vue'
import InputError from '@/components/BasicInputs/InputError.vue'
import InputSelectBox from '@/components/BasicInputs/InputSelectBox.vue'
import { downloadSOA } from '@/lib/WhiteLabelHelpers'
import Highcharts from 'highcharts'

export default {
  name: 'RetirementCalculatorSummary',
  components: {
    InputSelectBox,
    InputError,
    CustomButton,
    TabButtons,
    RetirementCalculatorInvestmentCharts,
  },
  computed: {
    ...mapGetters('RetirementCalculator', ['disclaimer']),
    ...mapGetters('RetirementCalculatorErrors', ['errorLocations']),
    tabNameArray() {
      return this.formTabs.map((tab) => {
        return {
          value: tab.value,
          label: tab.name,
        }
      })
    },
  },
  emits: ['click'],
  data() {
    return {
      selectedTab: 0,
      loading: false,
      downloadingPDF: false,
      formTabs: [
        {
          name: 'Super details',
          value: 0,
          page: markRaw(
            defineAsyncComponent(
              () =>
                import(
                  '@/components/CFS/RetirementCalculatorComponents/RetirementCalculatorUserData.vue'
                ),
            ),
          ),
        },
        {
          name: 'Other income + assets',
          value: 1,
          page: markRaw(
            defineAsyncComponent(
              () =>
                import(
                  '@/components/CFS/RetirementCalculatorComponents/RetirementCalculatorOtherIncome.vue'
                ),
            ),
          ),
        },
        {
          name: 'Additional contributions',
          value: 2,
          page: markRaw(
            defineAsyncComponent(
              () =>
                import(
                  '@/components/CFS/RetirementCalculatorComponents/RetirementCalculatorContributions.vue'
                ),
            ),
          ),
        },
        {
          name: 'Career break + part time',
          value: 3,
          page: markRaw(
            defineAsyncComponent(
              () =>
                import(
                  '@/components/CFS/RetirementCalculatorComponents/RetirementCalculatorCareerBreak.vue'
                ),
            ),
          ),
        },
        {
          name: 'Retirement milestones',
          value: 4,
          page: markRaw(
            defineAsyncComponent(
              () =>
                import(
                  '@/components/CFS/RetirementCalculatorComponents/RetirementCalculatorMilestones.vue'
                ),
            ),
          ),
        },
      ],
    }
  },

  methods: {
    ...mapActions('RetirementCalculator', [
      'getRetirementCalculatorData',
      'refreshRetCalc',
      'getDisclaimer',
      'downloadPDF',
    ]),
    changeTab(val) {
      this.loading = this.selectedTab !== val
      this.selectedTab = val
    },
    changeTabFromDropdown(val) {
      const tab = this.formTabs.findIndex((tab) => tab.value === val)
      this.changeTab(tab)
    },
    validateAndSave() {
      this.$emit('click')
    },

    async getChartPngs() {
      // filter all the undefined elements from the highcharts array because highcharts
      const charts = Highcharts.charts.filter((chart) => chart !== undefined)
      // map valid ones
      const promises = charts.map((chart) => {
        const chartOptions = {
          yAxis: {
            gridLineWidth: 0,
          },
          legend: {
            useHTML: true,
            height: 600,
            y: -20,
            itemStyle: {
              border: '0px',
              backgroundColor: '#fff',
              borderRadius: '0px',
              boxShadow: '0',
            },
            labelFormatter: function () {
              return `<div style="margin-left: 4px; color:${this.color}">${this.name}</div>`
            },
          },
          chart: {
            height: 400,
            style: {
              fontFamily: 'Arial',
            },
          },
        }
        const svg = chart.getSVG(chartOptions)
        return this.createImgFromSvg(svg, 'png')
      })
      return await Promise.all(promises)
    },

    async createImgFromSvg(svg, imageType) {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = () => {
          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')
          canvas.width = image.width
          canvas.height = image.height
          context.drawImage(image, 0, 0)
          resolve(canvas.toDataURL(`image/${imageType}`))
        }
        image.onerror = (err) => reject(err)
        image.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg)
      })
    },

    async downloadRetirementCalcPDF() {
      this.downloadingPDF = true
      const valid = await this.getRetirementCalculatorData()
      if (valid) {
        this.getChartPngs()
          .then((res) => {
            this.downloadPDF(res)
              .then((res) => {
                if (res) {
                  // rename to download PDF
                  downloadSOA(res.data, 'retirement_calc.pdf')
                  this.downloadingPDF = false
                }
              })
              .catch((err) => {
                this.downloadingPDF = false
                console.error(err)
              })
              .finally(() => {
                this.downloadingPDF = false
              })
          })
          .catch((err) => console.error(err))
      } else {
        this.downloadingPDF = false
      }
    },
  },
}
</script>

<style scoped></style>
