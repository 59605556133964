<template>
  <div class="relative">
    <input
      :id="name"
      :class="[
        { 'bg-grey-4 cursor-not-allowed': disabled },
        errorMessage
          ? 'border-red-dark enabled:hover:otivo-outline-error enabled:focus:otivo-outline-error enabled:active:otivo-outline-error'
          : 'border-grey-3 enabled:hover:otivo-outline enabled:focus:otivo-outline enabled:active:otivo-outline',
      ]"
      :disabled="disabled"
      :max="max"
      :min="min"
      :name="name"
      :value="value"
      class="rounded-[5px] px-[15px] py-[10px] border-[1px] w-100% border-grey-field"
      type="date"
      @input="(event: Event) => $emit('update:value', (event.target as HTMLInputElement).value)" />
    <p v-if="errorMessage" class="otivo-error-message absolute right-0">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { lightFormat } from 'date-fns'

type Props = {
  value: string // Format YYYY-MM-DD
  name: string
  min?: string // Format YYYY-MM-DD
  max?: string // Format YYYY-MM-DD
  errorMessage?: string
  disabled?: boolean
}

withDefaults(defineProps<Props>(), {
  min: undefined,
  max: lightFormat(new Date(), 'yyyy-MM-dd'),
  errorMessage: undefined,
})
defineEmits<{
  (e: 'update:value', val: string): void
}>() // Format YYYY-MM-DD
</script>
