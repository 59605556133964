<template>
  <InfoCircle
    v-if="error && error !== ''"
    v-tooltip="{ content: error }"
    icon="exclamation-circle" />
</template>

<script>
import InfoCircle from '@/components/InfoCircle/InfoCircle.vue'

export default {
  name: 'InputErrorIcon',
  components: { InfoCircle },
  props: {
    error: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped></style>
