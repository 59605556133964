<template>
  <div id="personal-better-off">
    <RegularCurve fill="otivo-blue" />
    <div
      class="bg-otivo-blue text-white py-16 md:pb-32 px-[20px] md:px-0"
      @mouseover="regularHover(true)"
      @mouseleave="regularHover(false)">
      <div class="relative md:w-[1000px] m-auto">
        <h3 class="text-center" v-html="personalBetterOffContent.heading"></h3>
        <div class="grid md:grid-cols-2 justify-center items-center mt-[40px] ml-0 mr-0">
          <div class="flex h-full pl-[35%] -mr-[35%] object-contain">
            <BetterOffPhone
              alt="BetterOffPhone"
              class="z-0 hidden md:block"
              :hand-hover-active="handHoverActive"
              @mouseover="handHover()"
              @mouseleave="handHover(false)" />
          </div>
          <div class="transition-all duration-300" :class="[hoverActive ? 'md:ml-8' : 'md:-ml-22']">
            <div
              :key="index"
              v-for="(item, index) in personalBetterOffContent.iterableItems"
              class="block">
              <div class="flex md:w-[446px] min-h-25 gap-8 mt-10">
                <div class="p-4 min-w-18 h-18 rounded-circle bg-blue-1">
                  <component
                    :is="icons[index]"
                    fill="white"
                    fill1="white"
                    fill2="white"
                    class="h-10 w-10" />
                </div>
                <div>
                  <h5 class="font-bold">
                    {{ item.subHeading }}
                  </h5>
                  <p class="paragraph-1 mt-2.5">
                    {{ item.body }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          :src="PointerHand"
          @mouseover="handHover()"
          @mouseleave="handHover(false)"
          alt="PointerHand"
          class="hidden md:block absolute z-10 transition-all duration-300 -left-[20%]"
          :class="[
              handHoverActive ? '-bottom-[32%]' : '-bottom-[45%]',
            ]"
          id="pointer-hand" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import RegularCurve from '@/components/Shapes/RegularCurve.vue'
import HandMoneyTile from '@/components/OtivoIcons/MegaMenu/HandMoneyTile.vue'
import BetterOffPhone from '@/components/BrandComponents/Otivo/Personal/BetterOffPhone.vue'
import PointerHand from '@/components/SVGS/PointerHand.svg?url'
import { nextTick, ref } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import MoneyBagIcon from '@/components/icons/MoneyBagIcon.vue'
import DebtPigIcon from '@/components/icons/DebtPigIcon.vue'
import SunIcon from '@/components/icons/SunIcon.vue'
import BombIcon from '@/components/icons/BombIcon.vue'
import UmbrellaWhite from '@/components/SVGS/BrandIcons/UmbrellaWhite.svg'

type PersonalBetterOffContentType = {
  heading: string
  iterableItems: Array<{ body: string; subHeading: string }>
}

interface Props {
  personalBetterOffContent: PersonalBetterOffContentType
}

defineProps<Props>()

const icons = [
  MoneyBagIcon,
  HandMoneyTile,
  BombIcon,
  BombIcon,
  DebtPigIcon,
  SunIcon,
  UmbrellaWhite,
]

const hoverActive = ref(false)
const handHoverActive = ref(false)

const regularHover = useDebounceFn((value) => {
  nextTick(async () => {
    hoverActive.value = value
  })
}, 10)

const handHover = useDebounceFn((value = true) => {
  nextTick(async () => {
    handHoverActive.value = value
  })
}, 10)
</script>
<style lang="scss" scoped></style>
