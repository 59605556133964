<template>
  <div class="sm:flex flex-row md:max-h-[820px] bg-white">
    <div
      class="w-100% md:w-67% flex flex-col py-10 px-5 md:px-12 pb-2 overflow-scroll no-scrollbar relative">
      <h6 class="text-center text-otivo-blue pb-5 rounded-t">Welcome to Otivo</h6>
      <h5 class="font-bold text-center md:mt-5">
        New features to help you <br class="sm:hidden" />
        be better off
      </h5>
      <p class="paragraph-2 mt-5 sm:mt-10">
        The team at Otivo has been hard at work creating a brand-new version that's even easier and
        safer to use, helping you stay on top of your money. And don't worry, all your previous info
        is just as you left it.
      </p>
      <div class="border-1px border-blue-4.5 otivo-shadow rounded-lg mt-5 sm:mt-10">
        <div class="p-5 flex flex-row gap-4 border-b-1px border-blue-4.5">
          <StopwatchIcon class="min-h-[26px] min-w-[26px]" />
          <div class="flex flex-col">
            <p class="button-1 text-blue-1">Access Otivo anywhere, anytime</p>
            <p class="paragraph-2 pt-2">
              You can now log into Otivo whenever you want within your subscription, 24/7 on any
              device.
            </p>
          </div>
        </div>
        <div class="p-5 flex flex-row gap-3 border-b-1px border-blue-4.5">
          <SunIcon fill2="otivo-red" class="min-h-[26px] min-w-[26px]" />
          <div class="flex flex-col">
            <p class="button-1 text-blue-1">Super investments optimiser</p>
            <p class="paragraph-2 pt-2">
              We've made it even easier to optimise your super investments to fit where you're at in
              life.
            </p>
          </div>
        </div>
        <div class="p-5 flex flex-row gap-3 border-b-1px border-blue-4.5">
          <SubProductIcon class="min-h-[26px] min-w-[26px]" />
          <div class="flex flex-col">
            <p class="button-1 text-blue-1">Contributions advice</p>
            <p class="paragraph-2 pt-2">
              Get help with your super contributions, including tax considerations and salary
              sacrifice.
            </p>
          </div>
        </div>
        <div class="p-5 flex flex-row gap-3 border-b-1px border-blue-4.5">
          <InsuranceColouredIcon class="min-h-[26px] min-w-[26px]" />
          <div class="flex flex-col">
            <p class="button-1 text-blue-1">Personal insurance advice</p>
            <p class="paragraph-2 pt-2">
              It’s now simpler than ever to get the cover you need for peace of mind.
            </p>
          </div>
        </div>
        <div class="p-5 flex flex-row gap-3">
          <ShieldIcon class="min-h-[26px] min-w-[26px]" />
          <div class="flex flex-col">
            <p class="button-1 text-blue-1">Extra security</p>
            <p class="paragraph-2 pt-2">
              We've beefed up our security to make Otivo even safer to use.
            </p>
          </div>
        </div>
      </div>
      <h5 class="font-bold md:text-center mt-10">Getting started is easy...</h5>
      <div class="border-1px border-blue-4.5 otivo-shadow rounded-lg mt-10">
        <div class="p-5 md:flex flex-row gap-3 border-b-1px border-blue-4.5">
          <p class="button-1 text-blue-1 flex-shrink-0">Step 1</p>
          <p class="paragraph-2 pt-3 md:pt-0">
            Log in to Otivo using your existing email and password.
          </p>
        </div>
        <div class="p-5 md:flex flex-row gap-3 border-b-1px border-blue-4.5">
          <p class="button-1 text-blue-1 flex-shrink-0">Step 2</p>
          <p class="paragraph-2 pt-3 md:pt-0">
            Type in your mobile number so we can verify it's you.
          </p>
        </div>
        <div class="p-5 md:flex flex-row gap-3">
          <p class="button-1 text-blue-1 flex-shrink-0">Step 3</p>
          <p class="paragraph-2 pt-3 md:pt-0">
            Enter the verification code sent to your phone, then you're good to go.
          </p>
        </div>
      </div>
      <div class="mt-10 flex justify-center">
        <OtivoButton colour="red" class="mt-10 w-100% md:w-fit" @click="login()">
          Let's go
        </OtivoButton>
      </div>

      <div class="py-10 flex gap-2 justify-center">
        <BaseCheckbox name="dont-show-again" @update:checked="dontShowAgain" />
        <p class="paragraph-2">Don't show this again</p>
      </div>
    </div>
    <div class="hidden sm:flex sm:w-33% bg-otivo-blue overflow-none painter">
      <!--      <img src="@/assets/img/painter.webp" class="w-100%" alt="otivo-logo" />-->
    </div>
  </div>
</template>

<script setup lang="ts">
import StopwatchIcon from '@/components/icons/StopwatchIcon.vue'
import SunIcon from '@/components/icons/SunIcon.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import { login } from '@/lib/AuthenticatorPlugin.ts'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import ShieldIcon from '@/components/icons/ShieldIcon.vue'
import SubProductIcon from '@/components/icons/SubProductIcon.vue'
import InsuranceColouredIcon from '@/components/icons/InsuranceColouredIcon.vue'

const modalStore = useModalStore()
const dontShowAgain = () => {
  localStorage.setItem('dontShowNewFeatures', 'true')
  modalStore.closeModal()
}
</script>

<style lang="scss" scoped>
.painter {
  background-image: url('@/assets/img/painter.webp');
}
</style>
