<template>
  <div id="confirmation-modal">
    <h6 v-if="heading" class="pt-[20px]">{{ heading }}</h6>
    <p v-if="body" class="grey-2 paragraph-1 pt-[20px]">{{ body }}</p>
    <div class="flex gap-[20px] justify-end mt-[40px]">
      <OtivoButton colour="white" @click="confirmFunction" data-test="yesBtn">Yes</OtivoButton>
      <OtivoButton colour="blue" @click="modalStore.closeModal" data-test="noBtn">No</OtivoButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import OtivoButton from '@/components/OtivoButton.vue'
import { useModalStore } from '@/store/pinia/ModalStore'

type Props = {
  heading?: string
  body?: string
  confirmFunction: () => void | unknown
}

withDefaults(defineProps<Props>(), {
  heading: '',
  body: ''
})

const modalStore = useModalStore()
</script>
