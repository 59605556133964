<template>
  <div class="flex flex-col items-center justify-center md:max-w-[375px] mx-auto px-6">
    <h5 class="bold">Are you sure you want to deactivate your Otivo account?</h5>
    <p class="mt-6 text-blue-1">
      Please know that once deactivated, your Otivo account can not be automatically renewed, and we
      won’t be able to offer a refund.
      <br />
      <br />
      If you have any questions, please feel free to contact us at
      <a href="mailto:info@otivo.com" class="hover:text-blue-1">info@otivo.com</a>.
      <br />
      <br />
      Do you wish to continue?
    </p>
    <OtivoButton class="mt-[40px] w-full" @click="next">Yes, please continue</OtivoButton>
    <div
      class="mt-[40px] button-1 underline text-otivo-blue hover:text-blue-1 cursor-pointer"
      @click="goBack()">
      No, please keep my account active
    </div>
  </div>
</template>

<script setup lang="ts">
import OtivoButton from '@/components/OtivoButton.vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const emit = defineEmits<{
  (e: 'next', data: string): void
}>()

const next = () => {
  emit('next', 'deactivate account')
}

const goBack = () => {
  router.push({ name: 'clientDashboard' })
}
</script>

<style scoped></style>
