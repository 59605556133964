<template>
  <svg viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.873 19.98a17.436 17.436 0 0 0-6.28-5.67c2.5-2.534 3.938-6.445 3.938-11.393A2.625 2.625 0 0 0 26.906.292H7.979a2.625 2.625 0 0 0-2.625 2.625c0 4.955 1.398 8.866 3.892 11.4A17.436 17.436 0 0 0-.001 29.691 18.755 18.755 0 0 0 3.99 41.34a2.624 2.624 0 0 0 2.022.952h14.437a2.625 2.625 0 0 0 0-5.25H7.343a14.254 14.254 0 0 1-2.094-7.35 12.193 12.193 0 0 1 12.036-12.167h.308a12.193 12.193 0 0 1 9.93 5.362 2.625 2.625 0 0 0 4.35-2.907ZM10.768 5.542h13.31c-.73 5.348-3.761 6.668-6.432 6.733h-.466c-2.664-.072-5.683-1.398-6.412-6.733Z"
      :class="`fill-${fill1}`" />
    <path
      d="M39.375 33.105h-10.5a2.625 2.625 0 0 1 0-5.25h10.5a2.625 2.625 0 0 1 0 5.25ZM39.375 42.292h-10.5a2.625 2.625 0 0 1 0-5.25h10.5a2.625 2.625 0 0 1 0 5.25Z"
      :class="`fill-${fill2}`" />
  </svg>
</template>

<script lang="ts" setup>
type Props = {
  fill1?: string
  fill2?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red',
})
</script>
