<template>
  <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path
      stroke="currentColor"
      id="Vector"
      d="M1 1.00003L17 17M1 17L17 1"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script lang="ts" setup></script>

<style scoped></style>
