<template>
  <form @submit.prevent="emits('search', searchValue)" class="min-w-[375px]">
    <div class="w-100% px-[40px]">
      <div class="flex flex-col col-span-1 gap-2 mt-5">
        <label for="email" class="w-fit paragraph-1 text-grey-2">
          <span class="flex flex-row"> Email </span>
        </label>
        <BaseInput
          type="text"
          v-model:value="searchValue.email"
          name="email"
          placeholder="user@mail.com"
          class="w-full" />
      </div>
      <div class="flex flex-col col-span-1 gap-2 mt-5">
        <label for="first-name" class="w-fit paragraph-1 text-grey-2">
          <span class="flex flex-row"> First name </span>
        </label>
        <BaseInput
          type="text"
          v-model:value="searchValue.first_name"
          name="first-name"
          placeholder="First name"
          class="w-full" />
      </div>

      <div class="flex flex-col col-span-1 gap-2 mt-5">
        <label for="first-name" class="w-fit paragraph-1 text-grey-2">
          <span class="flex flex-row"> Last name </span>
        </label>
        <BaseInput
          type="text"
          v-model:value="searchValue.last_name"
          name="last-name"
          placeholder="Last name"
          class="w-full" />
      </div>
    </div>
    <OtivoButton
      type="submit"
      colour="blue"
      size="large"
      class="mt-[40px] m-auto hidden"
      data-test="submitBtn"
      :loading="loading">
      Search
    </OtivoButton>
  </form>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import OtivoButton from '@/components/OtivoButton.vue'
import { SearchParams } from '@/types/CallcentrePortalTypes.ts'
import { useCallCentrePortalStore } from '@/store/pinia/adminPortal/CallCentrePortalStore.ts'
import BaseInput from '@/components/Inputs/BaseInput.vue'

const searchValue = ref<SearchParams>({
  first_name: '',
  last_name: '',
  email: '',
})

defineExpose({
  searchValue,
})

const emits = defineEmits({
  search: (value: SearchParams) => value,
})

const callCentrePortalStore = useCallCentrePortalStore()

const loading = ref<boolean>(false)

const updateSearchField = (field: keyof SearchParams, value: string) => {
  searchValue.value[field] = value
  // upateManagedUserKey is used for capturing a users details before they've been created to prepopulate the form
  callCentrePortalStore.updateManagedUserKey(field, value)
}
</script>

<style scoped></style>
