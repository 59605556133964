import { ref } from 'vue'
import { defineStore } from 'pinia'
import { PageContentType } from '@/types/GlobalTypes.ts'
import ContentService from '@/services/ContentService.ts'

export const usePageContentStore = defineStore('pageContentStore', () => {
  const pageContent = ref({} as PageContentType)

  const fetchContent = async (pageRef: string) => {
    try {
      const res = await ContentService.getPageContent(pageRef)
      pageContent.value = res.data
    } catch (err) {
      console.error('Error fetching page content', err)
    }
  }
  return {
    pageContent,
    fetchContent,
  }
})
