<template>
  <div class="md:min-w-[140px]">
    <div class="text-[#A0E6FA] pb-5 font-bold">Follow us</div>
    <div class="flex gap-5 md:gap-8">
      <div v-for="{ name, url, component } in socialIconArray" :key="`${name}-icon`">
        <a :href="url" :alt="`${name} Icon`" target="_blank">
          <component
            :is="component"
            class="md:w-6 md:h-6 w-12 h-12"
            fill1="otivo-blue" />
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineAsyncComponent, markRaw } from 'vue'
import { isAuthenticated } from '@/lib/AuthenticatorPlugin.ts'

const socialIconArray = [
  {
    name: 'linkedin',
    url: 'https://www.linkedin.com/company/otivo/',
    component: markRaw(
      defineAsyncComponent(() => import('@/components/icons/Socials/LinkedInIcon')),
    ),
  },
  {
    name: 'twitter',
    url: 'https://twitter.com/OtivoFinancial',
    component: markRaw(
      defineAsyncComponent(() => import('@/components/icons/Socials/TwitterIcon')),
    ),
  },
  {
    name: 'facebook',
    url: 'https://www.facebook.com/OtivoFinancial',
    component: markRaw(defineAsyncComponent(() => import('@/components/icons/Socials/FBIcon'))),
  },
]
</script>

<style scoped></style>
