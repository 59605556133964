import MonthlyExpensesService from '@/services/MonthlyExpensesService'
const state = () => ({
  expenses: [
    { type: 'food', user: 0, partner: 0 },
    { type: 'recreation', user: 0, partner: 0 },
    { type: 'utilities', user: 0, partner: 0 },
    { type: 'transport', user: 0, partner: 0 },
    { type: 'health', user: 0, partner: 0 },
    { type: 'kids', user: 0, partner: 0 },
    { type: 'rent', user: 0, partner: 0 },
    { type: 'mortgage', user: 0, partner: 0 }
  ],
  // expense calculator is only reference for the user
  totals: {
    // totals of expense calculator
    me: 0,
    partner: 0
  },
  // actual totals are stored separately in the db
  meTotalMonthlyExpense: 0,
  partnerTotalMonthlyExpense: 0,

  // note:: right now we are using only this one
  totalMonthlyExpense: null
})

const getters = {
  monthlyExpenses(state) {
    return state.expenses
  },

  getThreeMonthsExpenses(state) {
    return state.totalMonthlyExpense * 3
  },

  totalMonthlyExpense(state) {
    return state.totalMonthlyExpense
  },

  partnerTotalMonthlyExpenses(state) {
    return state.partnerTotalMonthlyExpense
  },

  totalMonthlyExpenses(state, getters) {
    let value = 0
    getters.investmentYearlyIncomesAndExpenses.map((el) => {
      value += el.expenses
    })
    value += getters.totalMonthlyExpense
    value += getters.monthlyCashFlow.debt
    value += getters.monthlyCashFlow.savingsCommitments
    return value
  }
}

const mutations = {
  SET_MONTHLY_EXPENSES(state, expenses) {
    state.expenses = expenses
  },

  SET_ME_PARTNER_MONTHLY_EXPENSES_TOTAL(state, { me, partner }) {
    state.totals.me = me
    state.totals.partner = partner
  },

  CALCULATE_TOTAL_MONTHLY_EXPENSE(state) {
    state.meTotalMonthlyExpense = state.totals.me
    state.partnerTotalMonthlyExpense = state.totals.partner
    state.totalMonthlyExpense = state.totals.me + state.totals.partner
  },

  SET_TOTAL_MONTHLY_EXPENSE(state, value) {
    state.totalMonthlyExpense = value
  },

  SET_PARTNER_MONTHLY_EXPENSES_TOTAL(state, value) {
    state.partnerTotalMonthlyExpense = value
  },

  SET_SINGLE_MONTHLY_EXPENSE(state, { type, who, value }) {
    state.expenses = state.expenses.map((el) => {
      if (el.type === type) {
        el[who] = value
      }
      el.shared = el.user + el.partner
      return el
    })
  }
}

const actions = {
  putAllMonthlyExpenses({ commit, dispatch, getters }) {
    const expenses = getters.monthlyExpenses.map((expense) => {
      expense.both = expense.user + expense.partner
      expense.shared = expense.user + expense.partner
      return expense
    })

    return MonthlyExpensesService.putMonthlyExpenses({ data: expenses }).then((res) => {})
  },
  // todo :: update post requests
  postTotalMonthlyExpenses({ getters }) {
    return MonthlyExpensesService.postTotalMonthlyExpenses(getters.totalMonthlyExpense)
  },

  postUserTotalMonthlyExpenses({ commit, dispatch, getters }) {
    return MonthlyExpensesService.postUserTotalMonthlyExpenses(getters.totalMonthlyExpense)
  },

  postPartnerTotalMonthlyExpenses({ commit, dispatch, getters }) {
    return MonthlyExpensesService.postPartnerTotalMonthlyExpenses(
      getters.partnerTotalMonthlyExpenses
    )
  },

  // todo :: total should be set differently
  prepareAndSetAllMonthlyExpenses({ commit, dispatch }, expensesArray) {
    if (expensesArray.length > 0) {
      const tempExpenses = []
      const expenses = []
      let total = 0
      expensesArray.forEach((el) => {
        const expense = { type: el.type, ...tempExpenses[el.type] }
        total += el.value
        expense[el.owner] = el.value
        tempExpenses[el.type] = expense
      })

      // associative array to normal array
      for (const expense in tempExpenses) {
        expenses.push(tempExpenses[expense])
      }

      dispatch('setMonthlyExpenses', expenses)
      dispatch('setTotalMonthlyExpense', total)
    }
  },

  setMonthlyExpenses({ commit, dispatch }, expenses) {
    commit('SET_MONTHLY_EXPENSES', expenses)
    const userTotal = expenses.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.user
    }, 0)

    const partnerTotal = expenses.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.partner
    }, 0)

    // todo :: get sepearate total of me partner and total
    dispatch('setMePartnerTotalMonthlyExpenses', { me: userTotal, partner: partnerTotal })
  },

  setMePartnerTotalMonthlyExpenses({ commit }, payload) {
    commit('SET_ME_PARTNER_MONTHLY_EXPENSES_TOTAL', payload)
  },

  setPartnerTotalMonthlyExpenses({ commit }, value) {
    commit('SET_PARTNER_MONTHLY_EXPENSES_TOTAL', value)
  },

  calculateTotalMonthlyExpense({ commit }) {
    commit('CALCULATE_TOTAL_MONTHLY_EXPENSE')
  },

  setTotalMonthlyExpense({ commit }, value) {
    commit('SET_TOTAL_MONTHLY_EXPENSE', value)
  },

  setSingleMonthlyExpense({ commit }, payload) {
    commit('SET_SINGLE_MONTHLY_EXPENSE', payload)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
