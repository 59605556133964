<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0019 1.78654e-07C8.02392 -0.000373623 6.09026 0.585847 4.44548 1.68452C2.8007 2.78319 1.51868 4.34495 0.761571 6.17229C0.00446015 7.99963 -0.193732 10.0104 0.19206 11.9504C0.577851 13.8904 1.5303 15.6724 2.92894 17.0711C4.32757 18.4697 6.10958 19.4221 8.04956 19.8079C9.98955 20.1937 12.0004 19.9955 13.8277 19.2384C15.655 18.4813 17.2168 17.1993 18.3155 15.5545C19.4142 13.9097 20.0004 11.9761 20 9.9981C19.9995 7.34659 18.946 4.80383 17.0711 2.92893C15.1962 1.05403 12.6534 0.000501267 10.0019 1.78654e-07ZM7.74523 14.364H5.80798V8.13645H7.74523V14.364ZM6.77377 7.28785C6.55222 7.28561 6.33629 7.21786 6.15317 7.09315C5.97005 6.96843 5.82792 6.79234 5.74469 6.58701C5.66145 6.38168 5.64083 6.15632 5.68542 5.93929C5.73001 5.72227 5.83782 5.5233 5.99527 5.36742C6.15272 5.21155 6.35277 5.10574 6.57023 5.06333C6.78769 5.02093 7.01283 5.04381 7.21731 5.12911C7.42179 5.2144 7.59646 5.3583 7.71933 5.54266C7.84219 5.72703 7.90776 5.94363 7.90777 6.16518C7.90803 6.31364 7.87879 6.46067 7.82176 6.59774C7.76472 6.7348 7.68102 6.85918 7.57551 6.96362C7.47001 7.06807 7.34481 7.15053 7.20717 7.20618C7.06954 7.26184 6.92222 7.2896 6.77377 7.28785ZM14.9877 14.3583H13.0561V11.3343C13.0561 10.6124 13.0429 9.68626 12.0507 9.68626C11.0584 9.68626 10.8902 10.4706 10.8902 11.2833V14.3583H8.95861V8.13645H10.8127V8.98696H10.8392C11.025 8.66958 11.2935 8.40859 11.6161 8.23184C11.9386 8.0551 12.3031 7.96922 12.6706 7.98337C14.6267 7.98337 14.9877 9.27045 14.9877 10.945V14.3583Z"
      :class="`fill-${fill1}`" />
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'grey-3'
})
</script>
