const logoBaseUrl = 'https://store.otivo.com/client-logos'
export default [
  {
    alt: 'Suncorp',
    src: `${logoBaseUrl}/suncorp.svg`,
  },
  {
    alt: 'Rio Tinto',
    src: `${logoBaseUrl}/rio-tinto.svg`,
  },
  {
    alt: 'Latitude',
    src: `${logoBaseUrl}/latitude.svg`,
  },
  // {
  //   alt: 'News Perform',
  //   src: `${logoBaseUrl}/news_perform.svg`
  // },
  {
    alt: 'EY',
    src: `${logoBaseUrl}/EY.svg`,
  },
  // {
  //   alt: 'UNSW',
  //   src: `${logoBaseUrl}/UNSW.svg`
  // },
  // {
  //   alt: 'Australian Retirement Trust',
  //   src: `${logoBaseUrl}/art_super.svg`
  // },
  {
    alt: 'Pacific Blue',
    src: `${logoBaseUrl}/pacific_blue.svg`,
  },
  // {
  //   alt: 'A Home for Mum or Dad',
  //   src: `${logoBaseUrl}/AHFMOD.svg`
  // },
  {
    alt: 'Assure',
    src: `${logoBaseUrl}/assure.svg`,
  },
  // {
  //   alt: 'Base 51',
  //   src: `${logoBaseUrl}/base_51.svg`
  // },
  {
    alt: 'Diverger',
    src: `${logoBaseUrl}/diverger.svg`,
  },
  // {
  //   alt: 'DJA Capital',
  //   src: `${logoBaseUrl}/dja_capital.svg`
  // },
  // {
  //   alt: 'Financial Choice',
  //   src: `${logoBaseUrl}/financial_choice.svg`
  // },
  {
    alt: 'Ford Scott',
    src: `${logoBaseUrl}/ford_scott.svg`,
  },
  {
    alt: 'Fortnum',
    src: `${logoBaseUrl}/fortnum.svg`,
  },
  {
    alt: 'Lifebroker',
    src: `${logoBaseUrl}/lifebroker.svg`,
  },
  {
    alt: 'Phil Hoffman Travel',
    src: `${logoBaseUrl}/phil_hoffman_travel.svg`,
  },
  {
    alt: 'Stanford Brown',
    src: `${logoBaseUrl}/stanford_brown.svg`,
  },
  {
    alt: 'Surf Coast Shire',
    src: `${logoBaseUrl}/surf_coast_shire.svg`,
  },
  {
    alt: 'Surmount',
    src: `${logoBaseUrl}/surmount.svg`,
  },
  {
    alt: 'Unblock your wealth',
    src: `${logoBaseUrl}/unblock_your_wealth.svg`,
  },
]
