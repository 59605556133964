<template>
  <div class="h-full w-full flex" id="custom-loader">
    <svg
      class="lds-spinner self-center m-auto max-h-48 max-w-48 min-h-10 min-w-10 h-50% w-50%"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      style="background: none">
      <g transform="rotate(0 50 50)">
        <rect
          x="47"
          y="24"
          rx="9.4"
          ry="4.8"
          width="6"
          height="12"
          :class="white ? 'fill-white' : 'fill-otivo-blue'">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.9166666666666666s"
            repeatCount="indefinite" />
        </rect>
      </g>
      <g transform="rotate(30 50 50)">
        <rect
          x="47"
          y="24"
          rx="9.4"
          ry="4.8"
          width="6"
          height="12"
          :class="white ? 'fill-white' : 'fill-otivo-blue'">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.8333333333333334s"
            repeatCount="indefinite" />
        </rect>
      </g>
      <g transform="rotate(60 50 50)">
        <rect
          x="47"
          y="24"
          rx="9.4"
          ry="4.8"
          width="6"
          height="12"
          :class="white ? 'fill-white' : 'fill-otivo-blue'">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.75s"
            repeatCount="indefinite" />
        </rect>
      </g>
      <g transform="rotate(90 50 50)">
        <rect
          x="47"
          y="24"
          rx="9.4"
          ry="4.8"
          width="6"
          height="12"
          :class="white ? 'fill-white' : 'fill-otivo-blue'">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.6666666666666666s"
            repeatCount="indefinite" />
        </rect>
      </g>
      <g transform="rotate(120 50 50)">
        <rect
          x="47"
          y="24"
          rx="9.4"
          ry="4.8"
          width="6"
          height="12"
          :class="white ? 'fill-white' : 'fill-otivo-blue'">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.5833333333333334s"
            repeatCount="indefinite" />
        </rect>
      </g>
      <g transform="rotate(150 50 50)">
        <rect
          x="47"
          y="24"
          rx="9.4"
          ry="4.8"
          width="6"
          height="12"
          :class="white ? 'fill-white' : 'fill-otivo-blue'">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.5s"
            repeatCount="indefinite" />
        </rect>
      </g>
      <g transform="rotate(180 50 50)">
        <rect
          x="47"
          y="24"
          rx="9.4"
          ry="4.8"
          width="6"
          height="12"
          :class="white ? 'fill-white' : 'fill-otivo-blue'">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.4166666666666667s"
            repeatCount="indefinite" />
        </rect>
      </g>
      <g transform="rotate(210 50 50)">
        <rect
          x="47"
          y="24"
          rx="9.4"
          ry="4.8"
          width="6"
          height="12"
          :class="white ? 'fill-white' : 'fill-otivo-blue'">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.3333333333333333s"
            repeatCount="indefinite" />
        </rect>
      </g>
      <g transform="rotate(240 50 50)">
        <rect
          x="47"
          y="24"
          rx="9.4"
          ry="4.8"
          width="6"
          height="12"
          :class="white ? 'fill-white' : 'fill-otivo-blue'">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.25s"
            repeatCount="indefinite" />
        </rect>
      </g>
      <g transform="rotate(270 50 50)">
        <rect
          x="47"
          y="24"
          rx="9.4"
          ry="4.8"
          width="6"
          height="12"
          :class="white ? 'fill-white' : 'fill-otivo-blue'">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.16666666666666666s"
            repeatCount="indefinite" />
        </rect>
      </g>
      <g transform="rotate(300 50 50)">
        <rect
          x="47"
          y="24"
          rx="9.4"
          ry="4.8"
          width="6"
          height="12"
          :class="white ? 'fill-white' : 'fill-otivo-blue'">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.08333333333333333s"
            repeatCount="indefinite" />
        </rect>
      </g>
      <g transform="rotate(330 50 50)">
        <rect
          x="47"
          y="24"
          rx="9.4"
          ry="4.8"
          width="6"
          height="12"
          :class="white ? 'fill-white' : 'fill-otivo-blue'">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="0s"
            repeatCount="indefinite" />
        </rect>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Loader',

  props: {
    classList: {
      type: String,
      default:
        'dynamic-fill-color lds-spinner self-center m-auto max-h-48 max-w-48 min-h-10 min-w-10 h-50% w-50%'
    },
    white: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style scoped></style>
