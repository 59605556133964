<template>
  <div class="w-full">
    <!--  Margin top is because the tooltip is absolutely positioned  -->
    <div :class="{ 'mt-[50px]': showTooltip }" class="flex items-center gap-[4px]">
      <div class="relative w-full">
        <div
          v-if="isTooltipVisible"
          :class="`bg-${tooltipColour} text-${tooltipTextColour}`"
          :style="`left: calc(${newTooltipValue}% + (${16 - newTooltipValue * 0.32}px))`"
          class="range-tooltip z-30">
          {{ value }}
        </div>
        <div
          v-if="recommendedValue !== undefined"
          ref="recommendationLabel"
          :style="`left: clamp(15px, calc(${newRecommendedValue}% - (${recommendationLabelWidth}px)), calc(100% - 107px))`"
          class="absolute top-[-30px] button-4 text-white bg-otivo-red px-[4px] py-[2px] rounded-[2px] w-fit flex">
          We recommend
        </div>
        <div
          v-if="recommendedValue !== undefined"
          :style="`left: calc(${newRecommendedValue}% + (${15 - newRecommendedValue * 0.32}px))`"
          class="absolute top-[-16px] w-0 h-[90px] border-l border-r border-otivo-red border-dashed" />
        <div
          v-if="recommendedValue !== undefined"
          ref="dollarLabel"
          data-test="recommendedValue"
          :style="`left: clamp(
          0px,
          calc(${newRecommendedValue}% - (${dollarLabelWidth}px)),
          100% - 51px)`"
          class="absolute bottom-[-62px] button-1 text-otivo-red flex">
          ${{
            recommendedValue.toLocaleString('en-AU', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })
          }}
        </div>
        <div class="h-[6px] w-100% absolute top-50% bg-[#e2e2e2] secret-bar" />

        <div class="relative flex flex-row z-0">
          <div
            :class="`bg-${ballBackgroundColour}`"
            class="absolute z-10 left-0 w-[30px] h-[30px] flex flex-col justify-center items-center">
            <div :class="`bg-${ballColour}`" class="flex w-[12px] h-[12px] rounded-[9999px]" />
          </div>
          <input
            :max="max"
            :min="min"
            :step="step"
            :value="value"
            :disabled="disabled"
            class="w-full focus:outline-2 focus:outline-otivo-blue my-[13px] z-[11]"
            type="range"
            @input="(event: Event) => handleInput((event.target as HTMLInputElement).value)"
            @mousedown="toggleTooltip"
            @mouseup="toggleTooltip"
            @touchend="toggleTooltip"
            @touchstart="toggleTooltip" />
          <div
            :class="`bg-${ballBackgroundColour}`"
            class="absolute right-0 w-[30px] h-[30px] flex flex-col justify-center items-center">
            <div :class="`bg-${ballColour}`" class="flex w-[12px] h-[12px] rounded-[9999px]" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between mt-[10px]">
      <slot name="minLabel" />
      <slot name="maxLabel" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

type Props = {
  value: number
  min: number
  max: number
  step?: number
  ballColour?: string
  ballBackgroundColour?: string
  tooltipColour?: string
  tooltipTextColour?: string
  showTooltip?: boolean
  recommendedValue?: number
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  step: 1,
  ballColour: 'blue-2',
  ballBackgroundColour: 'white',
  tooltipColour: 'midnight',
  tooltipTextColour: 'white',
  showTooltip: true,
  recommendedValue: undefined
})
const emits = defineEmits<{
  (e: 'update:value', val: number): void
}>()

const inputValue = ref(props.value)
const newTooltipValue = computed(() =>
  Number(((inputValue.value - props.min) * 100) / (props.max - props.min))
)
const newRecommendedValue = computed(() => {
  if (props.recommendedValue) {
    return Number(((props.recommendedValue - props.min) * 100) / (props.max - props.min))
  }
  return 0
})
const isTooltipVisible = ref(false)
const toggleTooltip = () => {
  if (props.showTooltip) isTooltipVisible.value = !isTooltipVisible.value
}

const recommendationLabel = ref<HTMLElement>()
const recommendationLabelWidth = computed(() => {
  const label = recommendationLabel.value ?? null
  if (!label) return
  return label.offsetWidth / 2
})
const dollarLabel = ref<HTMLElement>()
const dollarLabelWidth = computed(() => {
  const label = dollarLabel.value ?? null
  if (!label) return
  return label.offsetWidth / 2
})

const handleInput = (event: string) => {
  const eventAsNumber = Number(event)

  inputValue.value = eventAsNumber
  emits('update:value', eventAsNumber)
}
</script>

<style lang="scss" scoped>
.secret-bar {
  transform: translateY(calc(50% - 7px));
}

input[type='range'] {
  -webkit-appearance: none;
  background-color: transparent;
  height: 6px;
  cursor: pointer;
}

input[type='range']:active {
  cursor: grabbing;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: var(--otivo-blue);
  border: 7px solid white;
  box-shadow: var(--otivo-popup-shadow);
  height: 30px;
  width: 30px;
  border-radius: 100%;
}

/* All the same stuff for Firefox */
input[type='range']::-moz-range-thumb {
  background-color: var(--otivo-blue);
  border: 7px solid white;
  box-shadow: var(--otivo-popup-shadow);
  height: 16px;
  width: 16px;
  border-radius: 100%;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type='range']::-ms-thumb {
  background-color: var(--otivo-blue);
  border: 7px solid white;
  box-shadow: var(--otivo-popup-shadow);
  height: 30px;
  width: 30px;
  border-radius: 100%;
  cursor: pointer;
}

.range-tooltip {
  position: absolute;
  padding: 10px;
  border-radius: 4px;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
}

.range-tooltip::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: inherit;
  bottom: -6px;
  left: 47%;
  transform: rotate(45deg) translateX(-50%);
}
</style>
