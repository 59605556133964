<template>
  <div class="w-full bg-blue-1">
    <div class="fix-width-m-auto sm:text-center py-10 px-6">
      <p class="mh6 md:intro-2 text-white">
        We’re always on the lookout for fresh new ideas to help Aussie be better off.
      </p>
      <div class="relative mx-auto sm:w-64">
        <img :src="ComeAndJoinUs" class="-left-24 -top-12 hidden sm:block absolute" />
        <img :src="ComeAndJoinUsMobile" class="-top-32 left-20% absolute sm:hidden" />
        <OtivoButton
          colour="red"
          class="w-full sm:mx-auto mt-20 mb-10"
          @click="router.push({ path: '/contact' })"
          >Join our team</OtivoButton
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ComeAndJoinUs from '@/assets/img/come-join-us.svg?url'
import ComeAndJoinUsMobile from '@/assets/img/come-join-us-mobile.svg?url'
import OtivoButton from '@/components/OtivoButton.vue'
import { useRouter } from 'vue-router'

const router = useRouter()
</script>

<style scoped></style>
