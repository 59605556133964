<template>
  <div
    class="items-center pl-6 xl:px-20 h-full"
    :class="isAuthenticated ? 'flex justify-between ' : 'grid grid-cols-2 xl:grid-cols-3'">
    <div class="h-full xl:w-auto flex items-center">
      <RouterLink
        :to="home"
        @click="closeBurgerMenu"
        data-test="home-link"
        class="h-full flex items-center">
        <img
          v-if="!whitelabel.joint_logo"
          src="@/assets/img/otivo-logo-white-text.png"
          class="flex max-w-[130px]"
          alt="otivo-logo" />
        <logo v-else />
      </RouterLink>
    </div>
    <div class="m-auto xl:w-[360px] hidden xl:block">
      <TopMenuBar v-if="!isAuthenticated && whitelabel.identifier !== 'COLONIAL_FIRST_STATE'" />
    </div>
    <RightTopNavBar />
  </div>
</template>

<script setup lang="ts">
import RightTopNavBar from '@/components/NavBar/TopNavBar/RightTopNavBar.vue'
import TopMenuBar from '@/components/TopMenuBar/TopMenuBar.vue'
import { isAuthenticated } from '@/lib/AuthenticatorPlugin'
import { computed } from 'vue'
import Logo from '@/components/Logo/Logo.vue'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import { useStore } from 'vuex'

const sessionStore = useSessionStore()
const whitelabel = computed(() => sessionStore.getWhitelabelData)
const store = useStore()

const home = computed(() => {
  if (isAuthenticated.value) return { name: 'clientDashboard' }
  return '/'
})

const closeBurgerMenu = () => {
  store.dispatch('closeSideBar')
}
</script>

<style scoped></style>
