import { GlobalConstantsInterface } from '@/types/GlobalConstantsInterface'

export default function (): GlobalConstantsInterface {
  return {
    // Savings plans
    PROPERTY_SAVINGS_PLAN: 'property',
    RAINY_DAY_PLAN: 'rainy_day',
    HOLIDAY_DAY_SAVINGS_PLAN: 'holiday',
    RENOVATION_DAY_SAVINGS_PLAN: 'renovation',
    KIDS_EDUCATION_DAY_SAVINGS_PLAN: 'kids_education',
    CAR_SAVINGS_PLAN: 'car',
    WEDDING_SAVINGS_PLAN: 'wedding',
    OTHER_SAVING_PLAN: 'other',

    // Debt plans
    CREDIT_CARD_PLAN: 'credit_card',
    CAR_LOAN_PLAN: 'car_loan',
    PERSONAL_LOAN_PLAN: 'personal_loan',
    MORTGAGE_PLAN: 'mortgage',
    HOME_MORTGAGE_PLAN: 'home_mortgage',

    // Insurance plans
    LIFE_INSURANCE_PLAN: 'life',
    INCOME_INSURANCE_PLAN: 'income',
    TRAUMA_INSURANCE_PLAN: 'trauma',
    TPD_INSURANCE_PLAN: 'tpd',

    // Investment plans
    CASH_AND_FIXED_INVESTMENT_PLAN: 'cashAndFixed',
    MANAGED_FUNDS_AND_UNLISTED_INVESTMENT_PLAN: 'managedFunds',
    PROPERTY_INVESTMENT_PLAN: 'property',
    HOME_INVESTMENT_PLAN: 'home',
    SHARES_INVESTMENT_PLAN: 'shares',
    ALTERNATIVE_INVESTMENT_PLAN: 'alternative',
    SHARESIGHT_PORTFOLIO: 'sharesight',

    // plan Owners
    OWNERS: {
      SINGLE: {
        NAME: 'Me',
        VALUE: 'single',
      },

      PARTNER: {
        NAME: 'Partner',
        VALUE: 'partner',
      },

      SHARED: {
        NAME: 'Shared',
        VALUE: 'shared',
      },
    },

    PAYMENT_FREQUENCY: {
      MONTHLY: 'monthly',
      WEEKLY: 'weekly',
      FORT_NIGHTLY: 'fortnightly',
    },

    TIME_PERIOD: {
      YEAR: 365,
      MONTH: 31,
      WEEK: 7,
    },

    LOAN_TYPES: {
      PRINCIPAL_AND_INTEREST: {
        NAME: 'Principal + interest',
        VALUE: 'principalAndInterest',
      },
      INTEREST_ONLY: {
        NAME: 'Interest Only',
        VALUE: 'interest',
      },
    },

    INVESTMENT_RETURN_PERCENTAGES: {
      cash: 2.7,
      conservative: 3.8,
      moderate: 4.4,
      balanced: 4.8,
      default: 7.5,
      growth: 5,
      highGrowth: 5.3,
    },

    INVESTMENT_TAX_ON_EARNINGS: {
      cash: 15,
      conservative: 10.6,
      moderate: 8.3,
      balanced: 6.5,
      default: 7,
      growth: 5.8,
      highGrowth: 4.1,
    },
  }
}
