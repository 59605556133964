<template>
  <div class="flex flex-col">
    <div class="text-center">
      <h3 class="h3 text-otivo-red my-5">$319k?</h3>
      <p class="intro-1">Here's how it's done...</p>
    </div>
    <div v-for="(tile, index) in tiles" :key="index">
      <Collapse
        class="text-grey-1 my-4 otivo-shadow"
        border-class="border border-[#A0E6FA]"
        :default-open="false">
        <template #heading>
          <div class="flex w-full">
            <div class="w-8 pt-1">
              <OtivoIconBase
                :icon-name="tile.icon.name"
                width="16px"
                height="16px"
                :view-box="tile.icon.viewbox">
                <component :is="tile.icon.component" class="justify-center h-8 w-8" />
              </OtivoIconBase>
            </div>
            <div class="font-bold w-full" v-html="tile.header" />
          </div>
        </template>
        <template #body>
          <div class="mp1 lg:paragraph-2 py-3">
            {{ tile.body }}
          </div>
        </template>
      </Collapse>
      <AdditionIcon v-if="index !== tiles.length - 1" class="m-auto" fill="#0064FF" />
      <EqualsIcon v-else class="m-auto" fill1="otivo-blue" />
    </div>
    <div class="relative text-center py-10">
      <img :src="Tada" alt="Tada" class="absolute md:ml-[24%] -top-4 lg:top-6 -left-2 lg:-left-8 md:left-4" />
      <span class="mh2 lg:headline-4 text-otivo-blue">$319,001</span>
    </div>
    <div class="flex pb-6">
      <img :src="UmbrellaWoman" alt="Umbrella Woman" class="w-30% h-auto" />
      <div class="mp1 w-70% pl-4 self-center">
        Plus, we help you find the insurance you need within your super. This not only protects your
        way of life, it protects your bank balance too.
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineAsyncComponent, markRaw, ref } from 'vue'

import Collapse from '@/components/SaveAndCollapse/Collapse.vue'
import OtivoIconBase from '@/components/OtivoIcons/OtivoIconBase.vue'
import EqualsIcon from '@/components/icons/EqualsIcon.vue'
import Tada from '@/components/SVGS/Tada.svg?url'
import UmbrellaWoman from '@/components/SVGS/UmbrellaWoman.svg?url'
import AdditionIcon from '@/components/icons/AdditionIcon.vue'
import LightbulbIcon from '@/components/icons/LightbulbIcon.vue'

defineEmits(['close'])

const tiles = ref([
  // {
  //   header: '<span class="text-otivo-red">$114,763</span> → lowering your interest',
  //   body: 'By helping them find a lower interest rate, our customers are on track to save an average of $113,897 by the time their mortgage is paid off and $866 until their credit card is paid off.',
  //   icon: {
  //     name: 'Debt',
  //     component: markRaw(
  //       defineAsyncComponent(() => import('@/components/OtivoIcons/IconDebt.vue')),
  //     ),
  //     viewbox: '0 0 16 18',
  //   },
  // },
  // {
  //   // language=HTML
  //   header: '<span class="text-otivo-red">$52,030</span> → FASTER repayments',
  //   body: 'By showing people how much they should pay each month, customers on average are $1,200 better off with their credit cards and $50,830 better off with their mortgages throughout the life of their loans.',
  //   icon: {
  //     name: 'Debt',
  //     component: markRaw(
  //       defineAsyncComponent(() => import('@/components/OtivoIcons/IconDebt.vue')),
  //     ),
  //     viewbox: '0 0 16 18',
  //   },
  // },
  {
    header: '<div class="w-full lg:flex space-between lg:pr-4">' +
      '<div class="text-[16px]">SMARTER investments</div>' +
        '<div class="lg:ml-auto">' +
          '<span class="text-[10px]">BE BETTER OFF BY</span>' +
          '<span class="text-[16px] px-2"> → </span>' +
          '<span class="text-[16px] text-otivo-red">$138,645</span>' +
        '</div>' +
      '</div>',
    body: 'By recommending smarter investment options within their super fund, our customer’s super is boosted on average by $138,645 by retirement.',
    icon: {
      name: 'Retire',
      component: markRaw(
        defineAsyncComponent(() => import('@/components/icons/LightbulbIcon.vue')),
      ),
      viewbox: '0 0 18 18',
    },
  },
  {
    header: '<div class="w-full lg:flex space-between lg:pr-4">' +
      '<div class="text-[16px]">SUPER-CHARGED super</div>' +
        '<div class="lg:ml-auto">' +
          '<span class="text-[10px]">BE BETTER OFF BY</span>' +
          '<span class="text-[16px] px-2"> → </span>' +
          '<span class="text-[16px] text-otivo-red">$180,356</span>' +
        '</div>' +
      '</div>',
    body: "By showing people how much to contribute, their super is increased on average by $180,356 (in today's dollars) by retirement.",
    icon: {
      name: 'Retire',
      component: markRaw(
        defineAsyncComponent(() => import('@/components/icons/IncomeAndExpensesIcon.vue')),
      ),
      viewbox: '0 0 18 18',
    },
  },
])
</script>

<style scoped></style>
