<template>
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 4.5C10.2348 4.5 9.98043 4.39464 9.79289 4.20711C9.60536 4.01957 9.5 3.76522 9.5 3.5V2H6.5V3.5C6.5 3.76522 6.39464 4.01957 6.20711 4.20711C6.01957 4.39464 5.76522 4.5 5.5 4.5C5.23478 4.5 4.98043 4.39464 4.79289 4.20711C4.60536 4.01957 4.5 3.76522 4.5 3.5V1C4.5 0.734784 4.60536 0.48043 4.79289 0.292893C4.98043 0.105357 5.23478 0 5.5 0H10.5C10.7652 0 11.0196 0.105357 11.2071 0.292893C11.3946 0.48043 11.5 0.734784 11.5 1V3.5C11.5 3.76522 11.3946 4.01957 11.2071 4.20711C11.0196 4.39464 10.7652 4.5 10.5 4.5Z"
      :fill="selected ? '#FF0000' : '#CACED3'" />
    <path
      d="M15 3H1C0.734784 3 0.48043 3.10536 0.292893 3.29289C0.105357 3.48043 0 3.73478 0 4V14C0 14.2652 0.105357 14.5196 0.292893 14.7071C0.48043 14.8946 0.734784 15 1 15H15C15.2652 15 15.5196 14.8946 15.7071 14.7071C15.8946 14.5196 16 14.2652 16 14V4C16 3.73478 15.8946 3.48043 15.7071 3.29289C15.5196 3.10536 15.2652 3 15 3ZM14 5V7L2 7.0375V5H14ZM2 13V9.04L7 9.025V9.8725C7 10.1377 7.10536 10.3921 7.29289 10.5796C7.48043 10.7671 7.73478 10.8725 8 10.8725C8.26522 10.8725 8.51957 10.7671 8.70711 10.5796C8.89464 10.3921 9 10.1377 9 9.8725V9.0175L14 9V13H2Z"
      :fill="selected ? '#0064FF' : '#CACED3'" />
  </svg>
</template>

<script>
export default {
  name: 'SuitcaseIcon',
  props: {
    selected: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped></style>
