<template>
  <div @keyup.enter="$emit('click', !checked)">
    <input
      :id="name"
      :checked="checked"
      :disabled="disabled"
      :name="name"
      :tabindex="0"
      :type="type"
      class="focusHere"
      :value="checked"
      @click="$emit('click', !checked)" />
    <label :for="name" />
  </div>
</template>

<script>
export default {
  name: 'CustomCheckBox',
  props: {
    name: {
      type: String,
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'checkbox'
    }
  },
  emits: ['click']
}
</script>

<style scoped>
input[type='checkbox'] + label {
  display: block;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

input:disabled + label {
  cursor: not-allowed;
}

input[type='checkbox'] {
  display: none;
}
input[type='checkbox'] + label:before {
  @apply border-otivo-blue;
}
input[type='checkbox'] + label:before {
  content: '';
  background-image: url('https://store.otivo.com/OtherImages/tick.png');
  background-repeat: no-repeat;
  background-position: center;
  border: 0.1em solid #0064ff;
  background-size: 10px;
  border-radius: 0.4em;
  display: table-cell;
  width: 16px;
  height: 16px;
  margin-right: 0.2em;
  vertical-align: middle;
  color: transparent;
  transition: 0.2s;
}

input[type='checkbox'] + label:active:before {
  transform: scale(0);
}

input[type='checkbox']:checked + label:before {
  /* Note :: change bg color in the components */
  @apply bg-otivo-blue border-otivo-blue;
  color: #fff;
  width: 16px;
  height: 16px;
}
</style>
