<template>
  <div v-if="isItEy">
    <OtivoButton colour="white" class="w-full" @click="goToEyStaffBenefits">
      Go to EY Staff Benefits
    </OtivoButton>
  </div>
</template>

<script>
import OtivoButton from '@/components/OtivoButton.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'EyStaffBenefits',
  components: { OtivoButton },
  computed: {
    ...mapGetters(['isItEy'])
  },
  methods: {
    goToEyStaffBenefits() {
      window.open(
        'https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c/oauth2/authorize?response_type=code&client_id=b4ec9c9c-ed6d-407c-8929-437a5a4cca39&scope=openid&nonce=46dddcb0-6eeb-4fd7-ae43-8434d0f05240&redirect_uri=https%3a%2f%2fshare.ey.net%2f&state=AppProxyState%3a%7b%22InvalidTokenRetry%22%3anull%2c%22IsMsofba%22%3afalse%2c%22OriginalRawUrl%22%3a%22https%3a%5c%2f%5c%2fshare.ey.net%5c%2fsites%5c%2fOceaniaWellbeing%5c%2fSitePages%5c%2fCorporate%2520Discounts.aspx%22%2c%22RequestProfileId%22%3anull%7d%23EndOfStateParam%23&client-request-id=10733d10-84f9-48a9-8327-0f98d22468b8',
        '_blank'
      )
    }
  }
}
</script>

<style scoped></style>
