<template>
  <div>
    <!-- compare with -->
    <div class="md:flex justify-center lg:max-w-80vw text-grey-1">
      <div class="self-center md:flex">
        <div class="text-center p-4 cursor-pointer flex justify-center" @click="changeCompareWith">
          <small-check-box
            :checked="compareWithAverageAussie"
            name="Average Aussie"
            label=""
            :disabled="true" />
          <span class="px-4 py-3 text-grey-2 button-1">All working Aussies</span>
          <div @click="resetFilters" class="text-otivo-blue self-center cursor-pointer md:hidden">
            Reset
          </div>
        </div>
      </div>

      <div class="flex self-center justify-center gap-4 m-2">
        <input-select-box
          class="min-w-20 md:min-w-32"
          :select-input="age"
          @update-select-box="setAge"
          name="user-age"
          :options="getAges"
          :reset-button="true" />

        <input-select-box
          class="min-w-20 md:min-w-32"
          :select-input="gender"
          @update-select-box="setGender"
          name="user-gender"
          :options="getGenders"
          :reset-button="true" />

        <input-select-box
          class="min-w-20 md:min-w-32"
          :select-input="state"
          @update-select-box="setState"
          name="user-state"
          :options="getStates"
          :reset-button="true" />

        <div
          @click="resetFilters"
          class="text-otivo-blue self-center cursor-pointer hidden md:block">
          Reset
        </div>
      </div>
    </div>
    <div class="text-grey-1 md:flex md:min-h-100">
      <div class="flex justify-center">
        <total-score-graph
          class="z-0"
          :user-score="resultResponse.scorePercent"
          :average-score="averageAussieData.scorePercent" />
        <info-circle
          message="*The higher the score the better off you are! Otivo commissioned Newgate Research to undertake market research on the financial health of Australian workers. The online survey was completed by 1,573 Australian workers aged 18 years and over. All respondents were employed (either full-time, part-time or casual) at the time of the survey. To ensure a representative sample of Australian workers, quotas were set for age, gender and location."
          class="mt-[15px]"
          size="20" />
      </div>
      <div class="w-full md:w-50%">
        <plan-rating-graphs
          class="relative"
          :result-response="resultResponse"
          :average-scores="getAverageScores" />
      </div>
    </div>
    <div class="bg-blue-1 text-white rounded-b p-10">
      <h5 class="h5 text-center">Flex your finances with Otivo and be better off!</h5>
      <div class="text-center pt-5 relative">
        <OtivoButton
          class="w-full md:w-33% mx-auto"
          @click="createAccount"
          colour="white"
          size="large"
          >Get started now</OtivoButton
        >
      </div>
      <div class="underline pt-5 text-blue-3 text-center cursor-pointer" @click="emitClose">
        No thanks, take me back to the homepage
      </div>
    </div>
  </div>
</template>

<script>
import TotalScoreGraph from './Graphs/TotalScoreGraph'
import PlanRatingGraphs from './Graphs/PlanRatingGraphs'
import CreateAcccountAfterFitnessTest from './CreateAcccountAfterFitnessTest'
import { mapGetters } from 'vuex'
import InputSelectBox from '@/components/BasicInputs/InputSelectBox.vue'
import SmallCheckBox from '@/components/BasicInputs/CustomCheckBox/SmallCheckbox.vue'
import StartHere from '@/components/FitnessTest/ShowResult/StartHere.vue'
import InfoCircle from '@/components/InfoCircle/InfoCircle.vue'
import getApiInstance from '@/services/Api.ts'
import { useToast } from '@/composables/useToast.ts'
import OtivoButton from '@/components/OtivoButton.vue'
import { createAccount } from '@/lib/AuthenticatorPlugin.ts'

const { errorToast } = useToast()
const api = getApiInstance()

export default {
  name: 'ShowResult',

  components: {
    OtivoButton,
    InfoCircle,
    StartHere,
    SmallCheckBox,
    InputSelectBox,
    CreateAcccountAfterFitnessTest,
    PlanRatingGraphs,
    TotalScoreGraph,
  },

  props: {
    resultResponse: {
      type: Object,
      default: () => {
        return {
          debtScore: 0,
          financialStressScore: 0,
          insuranceScore: 0,
          retireScore: 0,
          savingsScore: 0,
          score: 0,
          scorePercent: 0,
        }
      },
    },
  },
  emits: ['close'],

  data() {
    return {
      compareWithAverageAussie: true,
      gender: { label: 'Gender', value: 'gender', disabled: true, selected: true },
      state: { label: 'State', value: 'state', disabled: true, selected: true },
      age: { label: 'Age', value: 'age', disabled: true, selected: true },
      loading: false,
      averageAussieData: {
        debtScore: 0,
        savingsScore: 0,
        insuranceScore: 0,
        retireScore: 0,
        financialStressScore: 0,
        score: 0,
        scorePercent: 0,
      },
    }
  },

  computed: {
    ...mapGetters(['authenticated', 'isItBenestar', 'getCurrentWhitelabelObject']),

    getAverageScores() {
      // order is important
      return [
        'debtScore',
        'savingsScore',
        'retireScore',
        'insuranceScore',
        'financialStressScore',
      ].map((el) => {
        let score = Math.ceil(this.averageAussieData[el])
        if (score > 100) {
          score = 100
        } else if (score < 0) {
          score = 0
        } else if (score < 1) {
          score = 1
        }
        return score
      })
    },

    getStates() {
      return [
        { label: 'State', value: 'state', disabled: true, selected: this.state.value === 'state' },
        { id: 1, label: 'ACT', value: 'ACT', selected: this.state.value === 'ACT' },
        { id: 2, label: 'NSW', value: 'NSW', selected: this.state.value === 'NSW' },
        { id: 3, label: 'NT', value: 'NT', selected: this.state.value === 'NT' },
        { id: 4, label: 'QLD', value: 'QLD', selected: this.state.value === 'QLD' },
        { id: 5, label: 'SA', value: 'SA', selected: this.state.value === 'SA' },
        { id: 6, label: 'TAS', value: 'TAS', selected: this.state.value === 'TAS' },
        { id: 7, label: 'VIC', value: 'VIC', selected: this.state.value === 'VIC' },
        { id: 8, label: 'WA', value: 'WA', selected: this.state.value === 'WA' },
      ]
    },

    getAges() {
      return [
        { value: 'age', label: 'Age', disabled: true, selected: this.age.value === 'age' },
        { value: '24', label: '16 - 24', selected: this.age.value === '24' },
        { value: '34', label: '25 - 34', selected: this.age.value === '34' },
        { value: '44', label: '35 - 44', selected: this.age.value === '44' },
        { value: '54', label: '45 - 54', selected: this.age.value === '54' },
        { value: '64', label: '55 - 64', selected: this.age.value === '64' },
        { value: '74', label: '65 - 74', selected: this.age.value === '74' },
        { value: '75', label: '75 above', selected: this.age.value === '75' },
      ]
    },

    getGenders() {
      return [
        {
          value: 'gender',
          label: 'Gender',
          disabled: true,
          selected: this.gender.value === 'gender',
        },
        { value: 'male', label: 'Male', selected: this.gender.value === 'male' },
        { value: 'female', label: 'Female', selected: this.gender.value === 'female' },
      ]
    },
  },

  watch: {
    compareWithAverageAussie: {
      handler: function () {
        if (this.compareWithAverageAussie) {
          this.gender = { label: 'Gender', value: 'gender', disabled: true, selected: true }
          this.state = { label: 'State', value: 'state', disabled: true, selected: true }
          this.age = { label: 'Age', value: 'age', disabled: true, selected: true }
          this.getDataForAverageAussie()
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    createAccount,
    changeCompareWith() {
      // todo :: check
      this.compareWithAverageAussie = true
    },

    setAge(value) {
      this.compareWithAverageAussie = false
      this.age = this.getAges.find((ag) => {
        return ag.value === value
      })
      this.getDataForAverageAussie()
    },

    setState(value) {
      this.compareWithAverageAussie = false
      this.state = this.getStates.find((sta) => {
        return sta.value === value
      })
      this.getDataForAverageAussie()
    },

    setGender(value) {
      this.compareWithAverageAussie = false
      this.gender = this.getGenders.find((gen) => {
        return gen.value === value
      })
      this.getDataForAverageAussie()
    },

    getDataForAverageAussie() {
      const payload = {}
      if (!this.compareWithAverageAussie) {
        if (this.age.value !== 'age') payload.age = this.age.value
        if (this.state.value !== 'state') payload.state = this.state.value
        if (this.gender.value !== 'gender') payload.gender = this.gender.value
      }
      api
        .Post(`/financial_fitness_test_data`, payload)
        .then((res) => {
          // then show result
          this.averageAussieData = {
            debtScore: res.data.debt_score,
            financialStressScore: res.data.financial_stress_score,
            insuranceScore: res.data.insurance_score,
            retireScore: res.data.retire_score,
            savingsScore: res.data.savings_score,
            score: res.data.score,
            scorePercent: res.data.score_percent,
          }
        })
        .catch((err) => {
          errorToast('Error occurred, please try again')
          throw err
        })
    },
    resetFilters() {
      this.compareWithAverageAussie = true
      this.gender = { label: 'Gender', value: 'gender', disabled: true, selected: true }
      this.state = { label: 'State', value: 'state', disabled: true, selected: true }
      this.age = { label: 'Age', value: 'age', disabled: true, selected: true }
      this.getDataForAverageAussie()
    },
    freeTrial() {
      return this.getCurrentWhitelabelObject.freeDays === 31
        ? 'month'
        : this.getCurrentWhitelabelObject.freeDays + ' day'
    },
    buttonText() {
      if (!this.getCurrentWhitelabelObject.subscriptionRequired) {
        return 'Setup your FREE Otivo account '
      } else if (this.getCurrentWhitelabelObject.gracePeriod > 0) {
        return 'Get started with ' + this.getCurrentWhitelabelObject.gracePeriod + ' FREE months'
      } else {
        return 'Start your FREE ' + this.freeTrial() + ' trial'
      }
    },
    emitClose() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.custom-select :deep(select) {
  @apply rounded border-none text-center pl-0 pr-6 text-black font-medium;
}

.custom-select :deep(select option) {
  @apply text-center;
}

.modal-mask {
  @apply fixed;
  @apply w-full;
  @apply h-full;
  @apply z-60;
  @apply table;
  @apply top-0;
  @apply left-0;
  @apply pt-12;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-container {
  @apply shadow-md;
  @apply rounded;
  @apply z-70;
  transition: all 0.3s ease;
}
</style>
