<template>
  <BaseView>
    <div class="flex flex-col bg-blue-6 h-full w-full items-center py-[60px]">
      <div class="headline-1 text-center w-full max-w-5xl mx-[20px]" v-html="content.heading" />
      <p class="intro-1 mt-[40px] max-w-5xl text-center mx-[20px]" v-html="content.subHeading" />
      <br />

      <div class="flex flex-col justify-center items-center gap-10 mt-[40px]">
        <img
          :src="isUserOnMobile() ? OptimizeSuperChartMobile : OptimizeSuperChart"
          alt="Optimize super chart"
          class="w-full md:w-[830px] md:h-[336px]" />
        <div
          class="w-full grid space-y-4 md:space-y-0 md:flex flex-row-reverse justify-center items-center md:gap-2">
          <div
            class="button-4 bg-otivo-red text-white w-[300px] h-[22px] grid items-center text-center border border-grey-4 rounded-sm">
            With Advice your super can grow to $130,122
          </div>
          <div
            class="button-4 bg-blue-1 text-white w-[300px] h-[22px] grid items-center text-center border border-grey-4 rounded-sm">
            Without advice your super might stay at $70,000
          </div>
        </div>
      </div>

      <OtivoButton
        class="mt-[40px]"
        size="large"
        @click="createAccountWithModuleStream('personalInsurance')"
        >Help me optimise my super
      </OtivoButton>
      <div class="mt-10 fix-width-m-auto p-2 text-xs leading-4">
        The figures presented are based on averages from our user base, utilising data provided by
        users and projected outcomes based on our assumptions, assuming the recommendations are
        followed until the objective is achieved. The results of this calculator are not guaranteed
        nor suitable for defined benefit account holders and based on existing legislation. The
        amounts are estimates only and have been prepared without considering your individual
        objectives, eligibility or suitability to contribute, financial situation, or needs. Log in
        to fine tune your super future.
      </div>
    </div>
  </BaseView>
</template>

<script setup>
import OtivoButton from '@/components/OtivoButton.vue'
import BaseView from '@/views/BaseView.vue'
import OptimizeSuperChart from '@/assets/img/optimze-super-chart.png'
import OptimizeSuperChartMobile from '@/assets/img/optimze-super-chart-mobile.png'
import createAccountWithModuleStream from '@/composables/createAccountWithModuleStream.ts'
import { isUserOnMobile } from '@/utilities.ts'

const content = {
  heading: `Are my super investments <span class="text-otivo-red"> optimised for me</span>?`,
  subHeading: `Did you know 95% of Otivo users receive advice about optimising their investment inside super? Thanks to our advice, people increase their retirement balance on average by<span class="text-blue-1 intro-2"> $138,645</span>.`,
}
</script>
