<template>
  <div>
    <span class="inline-flex gap-[20px]">
      <input
        :id="name"
        :checked="checked"
        :class="[
          errorMessage
            ? '!border-red-dark text-red-dark enabled:hover:otivo-outline-error enabled:focus:otivo-outline-error enabled:active:otivo-outline-error'
            : 'border-grey-field enabled:hover:otivo-outline enabled:focus:otivo-outline enabled:active:otivo-outline'
        ]"
        :disabled="disabled"
        type="checkbox"
        @input="handleChange" />
    </span>
    <p v-if="errorMessage" class="otivo-error-message">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script lang="ts" setup>
type Props = {
  checked?: boolean
  name?: string
  errorMessage?: string
  disabled?: boolean
}

withDefaults(defineProps<Props>(), {
  checked: false,
  name: '',
  errorMessage: '',
  disabled: false
})

const emit = defineEmits<{
  (e: 'update:checked', value: boolean): void
}>()

const handleChange = (event: Event) => {
  emit('update:checked', (event.target as HTMLInputElement).checked)
}
</script>

<style scoped>
input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  border: 1px solid var(--grey-3);
  border-radius: 2px;
  display: grid;
  place-content: center;
  cursor: pointer;
}

input[type='checkbox'] {
  width: 16px;
  height: 16px;
}

input[type='checkbox']:checked {
  background-color: var(--otivo-blue);
}

input[type='checkbox']::before {
  content: '';
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #fff;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  width: 16px;
  height: 16px;
}

input[type='checkbox']:checked::before {
  transform: scale(0.5) rotate(15deg);
}

input[type='checkbox']:disabled {
  background-color: var(--grey-4);
  cursor: not-allowed;
}

input[type='checkbox']:disabled::before {
  box-shadow: inset 1em 1em var(--grey-3);
}
</style>
