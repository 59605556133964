<template>
  <div>
    <fitness-test-question-label :question-number="questionNumber" question="Your division" />
    <division-answer @click="setSunCorpDivision" :selected="sunCorpDivision" />
  </div>
</template>

<script>
import FitnessTestQuestionLabel from '../QuestionsLabel/FitnessTestQuestionLabel.vue'

import { mapState } from 'vuex'
import DivisionAnswer from './DivisionAnswer'
export default {
  name: 'SunCorpDivision',
  components: { DivisionAnswer, FitnessTestQuestionLabel },
  computed: {
    ...mapState({
      sunCorpDivision: (state) => state.fitnessTest.questions.sunCorpDivision,
    }),
  },

  props: {
    questionNumber: {
      type: String,
      default: '',
    },
  },

  methods: {
    setSunCorpDivision(value) {
      this.$store.dispatch('setFitnessTestSunCorpDivision', value)
    },
  },
}
</script>

<style scoped></style>
