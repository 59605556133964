<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
      fill="#FD8900" />
    <path
      d="M7.44207 9.63995C7.28341 9.63995 7.20407 9.56062 7.20407 9.40195L7.03607 3.43795C7.03607 3.27928 7.11074 3.19995 7.26007 3.19995H8.75807C8.90741 3.19995 8.98207 3.27928 8.98207 3.43795L8.80007 9.40195C8.80007 9.56062 8.71607 9.63995 8.54807 9.63995H7.44207ZM7.97407 12.986C7.61007 12.986 7.30674 12.8693 7.06407 12.636C6.82141 12.3933 6.70007 12.104 6.70007 11.768C6.70007 11.4413 6.82607 11.1613 7.07807 10.928C7.33941 10.6853 7.63807 10.564 7.97407 10.564C8.31007 10.564 8.60874 10.6853 8.87007 10.928C9.13141 11.1613 9.26207 11.4413 9.26207 11.768C9.26207 12.104 9.13607 12.3933 8.88407 12.636C8.64141 12.8693 8.33807 12.986 7.97407 12.986Z"
      fill="white" />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
