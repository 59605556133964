<template>
  <div>
    <fitness-test-question-label
      :question-number="questionNumber"
      question="Do you have life insurance?" />

    <input-box-buttons
      @click="setHaveLifeInsurance"
      :buttons="[
        {
          name: 'Yes',
          value: 'yes',
        },
        {
          name: 'No',
          value: 'no',
        },
        {
          name: 'Self insured',
          value: 'self_insured',
        },
      ]"
      :selected="haveLifeInsurance"
      name="living-situation"
      label="" />
  </div>
</template>

<script>
import LifeInsuranceAnswer from './LifeInsuranceAnswer'
import { mapState } from 'vuex'
import FitnessTestQuestionLabel from '../QuestionsLabel/FitnessTestQuestionLabel.vue'

import InputBoxButtons from '@/components/BasicInputs/InputBoxButtons.vue'
export default {
  name: 'HaveLifeInsurance',
  components: { InputBoxButtons, FitnessTestQuestionLabel, LifeInsuranceAnswer },
  computed: {
    ...mapState({
      haveLifeInsurance: (state) => state.fitnessTest.questions.haveLifeInsurance,
    }),
  },

  props: {
    questionNumber: {
      type: String,
      default: '',
    },
  },

  methods: {
    setHaveLifeInsurance(value) {
      this.$store.dispatch('setFitnessTestHaveLifeInsurance', value)
    },
  },
}
</script>

<style scoped></style>
