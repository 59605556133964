import { NavigationGuardNext } from 'vue-router'
import { getRootUrl } from '@/composables/getRootUrl.ts'

export default (
  userRole: string,
  authenticated: boolean,
  next: NavigationGuardNext,
  root?: string,
) => {
  if (authenticated) {
    const url = getRootUrl(userRole) as string
    next(url)
    return
  } else next(root)
}
