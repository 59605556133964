<template>
  <basic-input class="gap-3" :class="{ 'md:flex-col': stackLabel }">
    <template #input-label>
      <div v-if="label !== ''" class="flex set-w-28 md:set-w-48 paragraph-2" :class="labelClasses">
        <label :for="name" :class="{ required: required }">
          {{ this.uppercase(label) }}
        </label>
        <info-circle :message="title" />
      </div>
    </template>
    <template #input-box>
      <input
        class="appearance-none paragraph-2 block w-full p-4 border rounded ring-2"
        :class="applyClasses"
        :id="name"
        :type="type"
        :placeholder="placeHolder"
        :value="value"
        @blur="emitBlur($event)"
        :disabled="disabled"
        :autofocus="autofocus"
        @input="updateValue($event)"
        autocomplete="new-password" />
    </template>

    <template #input-errors>
      <input-error :error="error" />
      <input-info :info="info" />
    </template>
  </basic-input>
</template>

<script>
import InputError from './InputError'
import InputInfo from './InputInfo'
import { capitalize } from '@/lib/helpers'
import BasicInput from '@/components/BasicInputs/BasicInput.vue'
import InfoCircle from '@/components/InfoCircle/InfoCircle.vue'
export default {
  name: 'InputText',
  components: { InfoCircle, BasicInput, InputInfo, InputError },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    placeHolder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    info: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    allowDigits: {
      type: Boolean,
      default: false,
    },
    inputClasses: {
      type: String,
      default: 'paragraph-2',
    },
    labelClasses: {
      type: String,
      default: 'pt-4',
    },
    colour: {
      type: String,
      default: 'blue',
    },
    stackLabel: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['blur', 'input'],
  methods: {
    emitBlur(event) {
      this.$emit('blur', event.data)
    },

    updateValue(event) {
      let value = event.target.value
      if (!this.allowDigits) {
        value = value.replace(/[0-9]/g, '')
        event.target.value = value
      }
      this.$emit('input', value)
    },

    uppercase(value) {
      return capitalize(value)
    },
  },

  computed: {
    applyClasses() {
      if ((this.value === '' && this.colour === 'red') || this.error !== '') {
        return this.inputClasses + ' border-red-1 ring-red-light'
      } else {
        return (
          this.inputClasses +
          ' border-grey-2 ring-transparent focus:border-otivo-blue focus:ring-blue-4'
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/themes/cfs/guidanceInputStyles.scss';

input:disabled {
  @apply border-grey-light rounded bg-grey-light ring-grey-light text-grey-2 border-0;
  -webkit-text-fill-color: var(--grey-2);
}

.inline-input-box input:disabled {
  @apply px-0 mt-0;
}
</style>
