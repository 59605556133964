<template>
  <svg width="18" height="18" viewBox="0 0 55 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.8982 46.8135C16.0971 46.8135 15.3289 46.5052 14.7625 45.9565C14.1961 45.4078 13.8779 44.6636 13.8779 43.8876C13.8779 43.1117 14.1961 42.3674 14.7625 41.8187C15.3289 41.27 16.0971 40.9618 16.8982 40.9618C20.9453 40.9618 21.1416 37.5166 21.1416 36.8217V21.2855C21.1416 20.5095 21.4598 19.7653 22.0262 19.2166C22.5926 18.6679 23.3608 18.3596 24.1618 18.3596C24.9628 18.3596 25.731 18.6679 26.2974 19.2166C26.8638 19.7653 27.182 20.5095 27.182 21.2855V36.8217C27.182 40.8374 24.4336 46.8135 16.8982 46.8135Z"
      :fill="active ? '#FF0000' : '#0064FF'" />
    <path
      d="M48.3237 23.4068H0L0.0906075 20.3932C0.445484 8.96041 11.0163 0 24.1618 0C37.3074 0 47.8782 8.9604 48.233 20.4005L48.3237 23.4068Z"
      fill="#0064FF" />
  </svg>
</template>

<script>
export default {
  name: 'IconInsurance',
  props: {
    active: { type: Boolean, default: true },
  },
}
</script>

<style scoped></style>
