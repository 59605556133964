<template>
  <path d="M55 79.9999H45V52.331H35V79.9999H25V42.2708H55V79.9999Z" :fill="fill" />
  <path
    d="M80 80H55V70.0022H70V41.5835L40 12.0775L10 41.5835V69.9397H25V79.9375H0V40.4837C0.00046879 39.4399 0.216035 38.4074 0.633256 37.4506C1.05048 36.4938 1.66041 35.6332 2.425 34.9224L34.925 2.00469C36.3008 0.716673 38.1151 0 40 0C41.8848 0 43.6992 0.716673 45.075 2.00469L77.575 34.9099C78.3411 35.6221 78.952 36.4847 79.3692 37.4438C79.7865 38.4029 80.0012 39.4378 80 40.4837V80Z"
    :fill="fill" />
</template>

<script>
export default {
  name: 'HomeLoanTile',
  props: {
    fill: { type: String, default: '#D4E7FE' },
    fill1: { type: String, default: '#D4E7FE' },
    fill2: { type: String, default: '#D4E7FE' }
  }
}
</script>

<style scoped></style>
