<template>
  <div v-if="isAdmin() && clientLoggedIn">
    <OtivoButton colour="red" @click="showModal = !showModal" class="w-fit">
      <div class="flex gap-1 items-center justify-center w-full">
        <RefreshIcon fill1="#FFF" class="h-4 w-4" />
        End {{ buttonText }}
      </div>
    </OtivoButton>
    <ConfirmAlertBox
      v-if="showModal"
      :msg="`Are you sure you're ready to end the ${buttonText}`"
      :heading="`End ${buttonText}`"
      @close="logoutClientAccount" />
  </div>
</template>

<script lang="ts" setup>
import RefreshIcon from '@/components/icons/RefreshIcon.vue'
import ConfirmAlertBox from '@/components/Dialogs/ConfirmAlertBox.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useCallCentrePortalStore } from '@/store/pinia/adminPortal/CallCentrePortalStore.ts'
import { useAccountFlagsStore } from '@/store/pinia/AccountFlagsStore.ts'
import { useCheckUser } from '@/composables/users/checkUser.ts'
import { useSuperStore } from '@/store/pinia/SuperStore.ts'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import clearManagedUser from '@/composables/users/clearManagedUser.ts'

const showModal = ref<boolean>(false)
const { isAdmin } = useCheckUser()
const callcentreStore = useCallCentrePortalStore()
const router = useRouter()
const accountFlagsStore = useAccountFlagsStore()
const superStore = useSuperStore()

const isCallcentrePortal = computed(() => useSessionStore().getWhitelabelData.callcentre_portal)
const buttonText = computed(() => (isCallcentrePortal.value ? 'call' : 'session'))
const clientLoggedIn = computed(() => {
  const activeManagedUser = localStorage.getItem('activeManagedUser')
  const routeIsActiveUser = router.currentRoute.value.name === 'manageUser'
  return activeManagedUser || routeIsActiveUser
})
const logoutClientAccount = (value: 'yes' | 'no') => {
  showModal.value = false
  if (value === 'yes') {
    clearManagedUser(callcentreStore, accountFlagsStore, superStore)
    const route = isCallcentrePortal.value ? 'guidanceSearchUsers' : 'SearchForm'

    router.push({ name: route })
  }
}
</script>

<style scoped></style>
