import getApiInstance from '@/services/Api.ts'
import { AxiosResponse } from 'axios'

const ModuleContentService = () => {
  const api = getApiInstance()
  const baseUrl = '/v3/module/content?'

  type ModuleContentResponse = {
    [key: string]: string | { [key: string]: string }
  }

  const getTags = async (tags: string[]): Promise<AxiosResponse<ModuleContentResponse>> => {
    return api.Get<ModuleContentResponse>(baseUrl, {
      params: {
        tags: tags,
      },
    })
  }

  const getBlockTags = async (
    blockTags: string[],
  ): Promise<AxiosResponse<ModuleContentResponse>> => {
    return api.Get<ModuleContentResponse>(baseUrl, {
      params: {
        block_tags: blockTags,
      },
    })
  }

  const getPageTags = async (pageTags: string[]): Promise<AxiosResponse<ModuleContentResponse>> => {
    return api.Get<ModuleContentResponse>(baseUrl, {
      params: {
        page_tag: pageTags,
      },
    })
  }

  /**
   * URL formed as : `.../content?content?tags[]=greetings&block_tags[]=greetings&page_tag=home_page
   * @param tags
   * @param blockTags
   * @param pageTags
   */
  const getAllTagTypes = async (
    tags: string[],
    blockTags: string[],
    pageTags: string[],
  ): Promise<AxiosResponse<ModuleContentResponse[]>> => {
    return api.Get<ModuleContentResponse[]>(baseUrl, {
      params: {
        tags: tags,
        block_tags: blockTags,
        page_tags: pageTags,
      },
    })
  }

  return {
    getTags,
    getBlockTags,
    getPageTags,
    getAllTagTypes,
  }
}

export default ModuleContentService
