// export default (phoneNumber: string | number): string => {
//   if (typeof phoneNumber === 'number') {
//     phoneNumber = phoneNumber.toString()
//   }
//   const removeSpaces: string = phoneNumber.replace(/ /g, '')
//   const firstChar: string = removeSpaces.charAt(0)
//
//   let formattedPhoneNumber = ''
//   switch (firstChar) {
//     case '+':
//       // +61 XXX XXX XXX
//       for (let i = 0; i < removeSpaces.length; i++) {
//         if (i === 3) {
//           formattedPhoneNumber += ' '
//         }
//         if (i === 6) {
//           formattedPhoneNumber += ' '
//         }
//         if (i === 9) {
//           formattedPhoneNumber += ' '
//         }
//
//         formattedPhoneNumber += removeSpaces.charAt(i)
//       }
//       break
//     case '6':
//       // 61 XXX XXX XXX
//       for (let i = 0; i < removeSpaces.length; i++) {
//         if (i === 2) {
//           formattedPhoneNumber += ' '
//         }
//         if (i === 5) {
//           formattedPhoneNumber += ' '
//         }
//         if (i === 8) {
//           formattedPhoneNumber += ' '
//         }
//
//         formattedPhoneNumber += removeSpaces.charAt(i)
//       }
//       break
//     default:
//       // 04XX XXX XXX
//       for (let i = 0; i < removeSpaces.length; i++) {
//         if (i === 4) {
//           formattedPhoneNumber += ' '
//         }
//         if (i === 7) {
//           formattedPhoneNumber += ' '
//         }
//
//         formattedPhoneNumber += removeSpaces.charAt(i)
//       }
//   }
//
//   return formattedPhoneNumber
// }

export default (phoneNumber: string | number): string => {
  // Ensure phoneNumber is treated as a string
  const phoneNumberStr = phoneNumber.toString()

  // Remove spaces to start with a clean slate
  const cleanNumber = phoneNumberStr.replace(/ /g, '')

  // Initialize formattedPhoneNumber outside the switch-case to avoid redundancy
  let formattedPhoneNumber: string

  // Determine the formatting based on the first character
  switch (cleanNumber.charAt(0)) {
    case '+':
      // Format: +61 XXX XXX XXX
      formattedPhoneNumber = cleanNumber.replace(/(\+\d{2})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4')
      break
    case '6':
      // Format: 61 XXX XXX XXX
      formattedPhoneNumber = cleanNumber.replace(/(6\d)(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4')
      break
    default:
      // Default format: 04XX XXX XXX
      formattedPhoneNumber = cleanNumber.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3')
  }

  return formattedPhoneNumber
}
