<template>
  <teleport to="#modals">
    <!-- Animation is in the tailwind.scss -->
    <transition name="modal">
      <div>
        <div
          class="fixed top-0 left-0 table h-full w-full pt-12 transition-opacity duration-300 ease-in-out modal-mask z-100"
          @click="emitClose">
          <!-- @click- to close modal if user clicked outside the modal -->
          <div class="h-screen modal-wrapper" v-if="modalSticks">
            <!-- @click.stop - to stop propagating click event -->
            <div
              :id="id"
              v-if="loading"
              class="flex flex-col rounded p-4 shadow-md transition-all duration-300 ease-in-out modal-container md:mx-auto lg:max-w-900px"
              :class="`bg-${backgroundColour} ${containerStyles}`"
              @click.stop>
              <loader />
            </div>

            <div
              v-else
              :id="id"
              :class="`bg-${backgroundColour} ${containerStyles}`"
              class="modal-container relative overflow-auto transition-all duration-300 ease-in-out max-h-85% md:mx-auto lg:max-w-900px"
              @click.stop>
              <div v-if="canDismiss" data-test="closeModal" @click="emitClose">
                <img
                  class="cursor-pointer w-[16px] absolute top-[20px] right-[20px]"
                  src="../../assets/img/closeX.png"
                  alt="close modal icon" />
              </div>
              <div class="modal-header p-2" @click.stop>
                <slot name="header" />
              </div>

              <div class="modal-body p-2" @click.stop>
                <slot name="body" />
              </div>

              <div class="modal-footer" @click.stop>
                <slot name="footer" />
              </div>
            </div>
          </div>
        </div>
        <!-- If we want the modal to scroll normally with the screen and be skinny-->
        <div class="absolute top-32 right-0 left-0 h-screen modal-wrapper" v-if="!modalSticks">
          <!-- @click.stop - to stop propagating click event -->
          <div
            :id="id"
            v-if="loading"
            class="relative flex flex-col p-4 transition-all duration-300 ease-in-out modal-container md:mx-auto lg:max-w-440px"
            :class="`bg-${backgroundColour} ${containerStyles}`"
            @click.stop>
            <loader />
          </div>

          <div
            v-else
            :id="id"
            :class="`bg-${backgroundColour} ${containerStyles}`"
            class="relative p-6 transition-all duration-300 ease-in-out modal-container mb-10% md:mx-auto lg:max-w-440px"
            @click.stop>
            <div v-if="canDismiss" data-test="closeModal" @click="emitClose">
              <img
                class="cursor-pointer w-[16px] absolute top-[20px] right-[20px]"
                src="../../../src/assets/img/closeX.png"
                alt="close modal icon" />
            </div>
            <div class="p-2 modal-header" @click.stop>
              <slot name="header" />
            </div>

            <div class="p-2 modal-body" @click.stop>
              <slot name="body" />
            </div>

            <div class="p-2 modal-footer" @click.stop>
              <slot name="footer" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts" setup>
import { onBeforeMount, onBeforeUnmount } from 'vue'
import Loader from '@/components/Loader/Loader.vue'

type Props = {
  loading?: boolean
  canDismiss?: boolean
  id?: string
  backgroundColour?: string
  modalSticks?: boolean
  containerStyles?: string
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  canDismiss: true,
  id: 'modal-id',
  backgroundColour: 'white',
  modalSticks: true,
  containerStyles: '',
})

const emit = defineEmits<{ (e: 'close'): void; (e: 'click'): void }>()
let bodyScrollTop = 0

onBeforeMount(() => {
  if (props.canDismiss) {
    // vue does not control body tag so need to add event listener for the modal
    document.body.addEventListener('keyup', (e) => {
      if (e.key === 'esc') {
        emit('close')
      }
    })
  }

  bodyScrollTop = window.scrollY || document.documentElement.scrollTop
  document.body.style.position = 'fixed'
  document.body.style.top = `-${bodyScrollTop}px`
})

onBeforeUnmount(() => {
  document.body.style.position = ''
  document.body.style.top = ''
  window.scrollTo(0, bodyScrollTop)
})

const emitClose = () => {
  if (props.canDismiss) {
    emit('close')
  }
}
</script>

<style scoped>
.modal-mask {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-container {
  border-radius: 10px;
}
</style>
