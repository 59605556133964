<template>
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.3075 1.36323C17.6871 0.532712 18.5164 0 19.4296 0C21.5336 0 23.5515 0.835828 25.0393 2.32361C26.5271 3.81139 27.3629 5.82926 27.3629 7.9333V13.0666H35.5831C36.4575 13.0588 37.3233 13.2401 38.1212 13.5982C38.9235 13.9583 39.6379 14.4885 40.215 15.1522C40.7921 15.8158 41.218 16.597 41.4632 17.4415C41.7084 18.2861 41.767 19.1739 41.635 20.0434L41.6345 20.0469L39.0591 36.843C39.059 36.8437 39.0592 36.8423 39.0591 36.843C38.8393 38.2887 38.1044 39.6086 36.9901 40.5558C35.879 41.5002 34.4649 42.0127 33.007 41.9998H11.963C10.6743 41.9998 9.62964 40.9551 9.62964 39.6664V18.6667C9.62964 18.3319 9.70168 18.001 9.84086 17.6966L17.3075 1.36323ZM20.7924 4.9645L14.2963 19.1747V37.3331H33.0452C33.3828 37.3369 33.7105 37.2187 33.9677 37.0001C34.225 36.7814 34.3946 36.4771 34.4452 36.1433L37.0212 19.3431C37.0211 19.3437 37.0213 19.3425 37.0212 19.3431C37.0513 19.143 37.038 18.937 36.9816 18.7427C36.925 18.5478 36.8267 18.3675 36.6935 18.2144C36.5604 18.0612 36.3955 17.9389 36.2103 17.8558C36.0252 17.7726 35.8242 17.7308 35.6212 17.7331L35.5948 17.7334L25.0296 17.7332C23.7409 17.7332 22.6962 16.6886 22.6962 15.3999V7.9333C22.6962 7.06693 22.3521 6.23605 21.7394 5.62344C21.4627 5.34668 21.1414 5.1247 20.7924 4.9645Z"
      :class="`fill-${fill1}`" />
    <path
      d="M6.36303 21.4665C5.99173 21.4665 5.63564 21.614 5.3731 21.8765C5.11055 22.1391 4.96305 22.4952 4.96305 22.8664V35.933C4.96305 36.3043 5.11055 36.6604 5.3731 36.923C5.63564 37.1855 5.99174 37.333 6.36303 37.333H9.62966V21.4665H6.36303ZM2.07327 18.5767C3.21098 17.439 4.75406 16.7998 6.36303 16.7998H11.963C13.2517 16.7998 14.2963 17.8445 14.2963 19.1331V39.6663C14.2963 40.955 13.2517 41.9997 11.963 41.9997H6.36303C4.75405 41.9997 3.21098 41.3605 2.07327 40.2228C0.935549 39.0851 0.296387 37.542 0.296387 35.933V22.8664C0.296387 21.2575 0.935549 19.7144 2.07327 18.5767Z"
      :class="`fill-${fill2}`" />
  </svg>
</template>

<script setup lang="ts">
type Props = {
  fill1?: string
  fill2?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red',
})
</script>

<style scoped></style>
