<template>
  <div id="graph-container" class="px-[20px] md:px-0">
    <div class="paragraph-1 text-[10px] md:text-14px text-blue-1 pb-[20px] relative" v-if="balance">
      Comparing investment options can be tricky. So, to help you make informed decisions, we’ve
      mapped out how our recommended and alternative investment options would have performed over
      the last 5 years if your super balance started at
      <span class="font-bold">{{ balance }}. </span>
      <ScaleLoader class="absolute right-[30px]" v-if="props.loading" color="var(--otivo-blue)" />
    </div>
    <div class="w-100% h-[250px] relative">
      <canvas id="chart-1" />
    </div>
    <div id="legend-container" class="mt-[20px]" />
    <div class="flex flex-wrap md:justify-between mt-[20px]">
      <div class="pt-2 self-center text-14px font-bold" v-if="notEnoughDataForCurrentInvestment">
        Your current investments don’t have enough history to appear on the graph.
      </div>
      <div class="pt-2 self-center text-14px font-medium">
        Please note, we're only looking at investment options that have a five-year history. Past
        performance is not a guarantee of future performance.
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Chart, ChartConfiguration } from 'chart.js/auto'
import { computed, onMounted, ref, watch } from 'vue'
import { formatCurrency } from '@/lib/helpers'
import { useDebounceFn } from '@vueuse/core'
import { InvestmentOption } from '@/components/SuperWidget/SuperWidgetAdviceType.ts'
import { useSuperStore } from '@/store/pinia/SuperStore.ts'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import {
  ExclusionRules,
  useWhitelabelExclusionRules,
} from '@/composables/useWhitelabelExclusionRules.ts'

/**
 * TODO:
 * Refactor this to a reusable line chart component - current state it's 100% a CFS hardcode job
 */
type Props = {
  items: InvestmentOption[]
  loading?: boolean
}
const props = defineProps<Props>()
const chart = ref<Chart>()
const tooltips = ref<string[]>()

onMounted(() => {
  drawGraph()
})

const xAxisLabels = computed(() => {
  const found = props.items.find((item) => item.graph?.length && item.graph.length >= 5)
  return found?.graph?.map((item) => item.year) || []
})

const drawGraph = useDebounceFn(() => {
  const { dataSets, max } = mapDatasets(props.items)
  const ctx = document.getElementById('chart-1') as HTMLCanvasElement
  const config: ChartConfiguration = {
    type: 'line',
    data: {
      labels: xAxisLabels.value,
      datasets: dataSets,
    },
    options: {
      maintainAspectRatio: false,
      layout: {
        padding: 0,
      },
      interaction: {
        intersect: false,
        mode: 'nearest',
        axis: 'xy',
      },
      // animation,
      scales: {
        x: {
          border: {
            display: false,
          },
          ticks: {
            maxTicksLimit: 10,
            padding: 20,
          },
          grid: {
            display: false,
          },
        },
        y: {
          maximum: max,
          border: {
            display: false,
          },
          ticks: {
            padding: 20,
            maxTicksLimit: 4,
            callback: function (value: number) {
              if (value === 0) return '$0'
              return formatCurrency(value)
            },
          },
          grid: {
            color: '#70D6FF',
          },
        },
      },
      plugins: {
        tooltip: {
          position: 'nearest',
          callbacks: {
            title: (val, index) => {
              // Val === TooltipItem[] not a single TooltipItem
              // this should really be coming from the api in a way thats usable.
              // currently tooltips returns html and we cant use that here
              const historicalPointsOfInterest = {
                '2018': 'U.S - China Trade War',
                '2019': 'Brexit Uncertainty',
                '2020': 'COVID-19 Pandemic',
                '2021': 'Global Vaccine Rollout',
                '2022': 'Continued Inflation Concerns',
                '2023': 'AI goes mainstream',
                '2024': 'Global Political Polarisation',
              } as const

              return `${val[0].label} - ${historicalPointsOfInterest[val[0].label]}`
            },
            label: (val) => {
              return ` ${formatCurrency(val.raw)}`
            },
            afterLabel: (val) => {
              if (val.dataIndex > 0) return `  ${val.dataset.returns[val.dataIndex]}%`
            },
          },
        },
        htmlLegend: {
          containerID: 'legend-container',
        },
        legend: {
          display: false,
          position: 'bottom',
          align: 'center',
        },
      },
    },
    plugins: [htmlLegendPlugin],
  } as unknown as ChartConfiguration
  chart.value = new Chart(ctx, config)
}, 500)

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id)
  let listContainer = legendContainer.querySelector('div')

  if (!listContainer) {
    listContainer = document.createElement('div')
    listContainer.style.display = 'flex'
    listContainer.style.flexDirection = 'row'
    listContainer.style.flexWrap = 'wrap'
    listContainer.style.justifyContent = 'left'
    listContainer.style.gap = '5px'
    listContainer.style.margin = '0'
    listContainer.style.padding = '0'

    legendContainer.appendChild(listContainer)
  }

  return listContainer
}

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const div = getOrCreateLegendList(chart, options.containerID)

    // Remove old legend items
    while (div.firstChild) {
      div.firstChild.remove()
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart)

    items.forEach((item, index) => {
      const legendItem = document.createElement('div')
      legendItem.style.alignItems = 'center'
      legendItem.style.cursor = 'pointer'
      legendItem.style.display = 'flex'
      legendItem.style.flexDirection = 'row'
      legendItem.style.flexWrap = 'wrap'
      legendItem.style.padding = '5px'
      legendItem.style.borderRadius = '4px'
      legendItem.style.border = '1px solid #EEEBEB'

      if (item.hidden) {
        // disabled style
        legendItem.style.background = 'white'
        legendItem.style.borderLeft = `3px solid ${item.strokeStyle}`
      } else {
        // enabled style
        legendItem.style.background = item.strokeStyle
        legendItem.style.borderLeft = `3px solid ${item.strokeStyle}`
      }

      legendItem.onclick = () => {
        const { type } = chart.config
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index)
        } else {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex))
        }
        chart.update()
      }

      // Text
      const textContainer = document.createElement('p')
      textContainer.style.color = item.hidden ? '#888' : '#fff'
      textContainer.style.fontSize = '10px'
      textContainer.style.textTransform = 'uppercase'
      textContainer.style.fontFamily = 'Raleway'
      textContainer.style.fontWeight = 'bold'
      textContainer.style.margin = '0'
      textContainer.style.padding = '0'
      // textContainer.style.textDecoration = item.hidden ? 'line-through' : ''

      const text = document.createTextNode(item.text)
      textContainer.appendChild(text)
      legendItem.appendChild(textContainer)
      div.appendChild(legendItem)
    })
  },
}

const notEnoughDataForCurrentInvestment = ref(false)
const mapDatasets = (items: Array<InvestmentOption>) => {
  notEnoughDataForCurrentInvestment.value = false
  const valueRecommended = items[1]?.tag === 'recommended'
  const OtivoColors = {
    0: '#0064FF',
    1: valueRecommended ? '#FF0000' : '#888888',
    2: '#888888',
    3: '#888888',
    4: '#888888',
    5: '#888888',
  }
  let length = 0
  let highestDatasetValue = 0

  if (items[0].graph && items[0].graph.length < 5) notEnoughDataForCurrentInvestment.value = true
  const validItems = items.filter((item: InvestmentOption) => item.graph && item.graph.length >= 5)
  const dataSets = validItems.map((set: InvestmentOption, idx) => {
    if (notEnoughDataForCurrentInvestment.value) idx += 1
    const mappedBalance: number[] = []
    tooltips.value = []
    const percentageReturns: number[] = []

    Object.keys(set.graph).forEach((key) => {
      const annualReturn = set.graph[key]
      tooltips.value.push(annualReturn.toolTip)
      const rounded = Math.round(annualReturn.balance)
      mappedBalance.push(rounded)
      percentageReturns.push(annualReturn.percentage_return)
    })

    length = mappedBalance.length
    const highestValue = Math.max(...mappedBalance)
    if (highestValue > highestDatasetValue) highestDatasetValue = highestValue
    return {
      order: idx,
      data: mappedBalance,
      label: `${set.investment.name} (${set.tag})`,
      returns: percentageReturns,
      hidden: idx >= (valueRecommended ? 2 : 1),
      borderColor: OtivoColors[idx],
      borderWidth: 3,
      pointRadius: 0,
      borderCapStyle: 'round',
    }
  })

  let max = Math.floor(Math.log10(highestDatasetValue)) // find number of zeros
  max = Math.pow(10, max) // create power of 10
  max = Math.ceil(highestDatasetValue / max) * max // round to nearest thousand/million etc
  return { dataSets, length, max }
}

const { hasExclusionRule } = useWhitelabelExclusionRules()

const activeSuper = computed(() => useSuperStore().getActiveSuperObject)
const balance = computed(() => {
  let balance = activeSuper.value?.balance || 0
  let info = ''
  if (hasExclusionRule(ExclusionRules.SOME_IN_CASH_OR_TERM_DEPOSITS)) {
    const termDeposits = activeSuper.value?.investments.filter((val) => val.is_term_deposit)
    if (termDeposits.length) {
      const percentage = termDeposits.reduce((total, item) => total + item.percent_allocation, 0.0)
      balance = balance - (percentage / 100) * balance
      info = ' (excluding term deposits)'
    }
  }
  // no space between ${formatCurrency}${info} is intentional. it produces an extra space between
  // the balance and period if included in the return if there is no term deposit
  return balance ? `${formatCurrency(balance)}${info}` : 0
})

const updateData = (newData: InvestmentOption[]) => {
  const mapped = mapDatasets(newData)
  const chart = Object.values(Chart.instances)
    .filter((c) => c.canvas.id == 'chart-1')
    .pop() as Chart

  chart.data.labels = xAxisLabels.value
  chart.data.datasets.forEach((dataset, index) => {
    dataset.label = mapped.dataSets[index].label
    dataset.data = mapped.dataSets[index].data
  })

  chart.update()
}

watch(props, () => {
  if (chart.value && props.items) {
    updateData(props.items)
  }
})
</script>
