<template>
  <svg width="100" height="100" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5784_152597)">
      <path
        d="M10.5185 16.5796H1.00176C0.736079 16.5796 0.481277 16.4742 0.29341 16.2867C0.105543 16.0991 0 15.8448 0 15.5796V12.5796C0 12.3143 0.105543 12.06 0.29341 11.8724C0.481277 11.6849 0.736079 11.5796 1.00176 11.5796H8.51498C8.78067 11.5796 9.03547 11.6849 9.22334 11.8724C9.4112 12.06 9.51674 12.3143 9.51674 12.5796C9.51674 12.8448 9.4112 13.0991 9.22334 13.2867C9.03547 13.4742 8.78067 13.5796 8.51498 13.5796H2.00353V14.5796H10.1554L14.3853 11.0621C14.5904 10.9037 14.8491 10.8309 15.1069 10.8589C15.3647 10.887 15.6015 11.0138 15.7676 11.2126C15.9337 11.4114 16.0161 11.6668 15.9974 11.925C15.9787 12.1832 15.8605 12.4241 15.6676 12.5971L11.1596 16.3471C10.9798 16.497 10.7529 16.5793 10.5185 16.5796Z"
        :fill="fill" />
      <path
        d="M10.9042 5.67959C10.8624 5.60698 10.8128 5.53909 10.7564 5.47709C10.0877 4.77209 9.09098 4.57959 8.21194 4.38709C7.85882 4.31459 7.10499 4.32959 7.10499 3.91209C7.10499 3.49459 7.99906 3.52459 8.32714 3.56459C8.58362 3.5736 8.82821 3.67481 9.01585 3.84959C9.17792 4.05977 9.41699 4.19708 9.68047 4.23131C9.94396 4.26553 10.2103 4.19387 10.4208 4.03209C10.6314 3.87031 10.7689 3.63166 10.8032 3.36864C10.8375 3.10562 10.7657 2.83977 10.6036 2.62959C10.2128 2.15114 9.67356 1.81606 9.07095 1.67709V1.57959C9.07095 1.31437 8.96541 1.06002 8.77754 0.872483C8.58967 0.684947 8.33487 0.57959 8.06919 0.57959C7.8035 0.57959 7.5487 0.684947 7.36083 0.872483C7.17297 1.06002 7.06742 1.31437 7.06742 1.57959V1.66209C6.66164 1.75042 6.27977 1.92525 5.94795 2.17459C5.61457 2.45204 5.36414 2.81575 5.2241 3.22586C5.08407 3.63597 5.05983 4.07664 5.15406 4.49959C5.23223 4.8694 5.41216 5.21012 5.67362 5.48349C5.93507 5.75685 6.26771 5.95203 6.63416 6.04709C7.01714 6.17436 7.40885 6.27379 7.80622 6.34459C8.3071 6.44959 9.30887 6.55459 9.30887 7.07209C9.30887 7.58959 6.96224 7.53959 6.51144 6.96709C6.34539 6.75989 6.10369 6.62702 5.8395 6.59772C5.57531 6.56842 5.31028 6.64508 5.10272 6.81084C4.89515 6.9766 4.76205 7.21788 4.7327 7.4816C4.70334 7.74533 4.78014 8.00989 4.94619 8.21709C5.48063 8.83422 6.20907 9.252 7.01233 9.40209V9.57959C7.01233 9.84481 7.11787 10.0992 7.30574 10.2867C7.4936 10.4742 7.7484 10.5796 8.01409 10.5796C8.27977 10.5796 8.53457 10.4742 8.72244 10.2867C8.91031 10.0992 9.01585 9.84481 9.01585 9.57959V9.43459C9.46931 9.35232 9.90232 9.18248 10.2906 8.93459C11.1421 8.36959 11.7532 7.21209 10.9042 5.67959Z"
        :fill="fill" />
    </g>
    <defs>
      <clipPath id="clip0_5784_152597">
        <rect width="100" height="100" fill="white" transform="translate(0 0.57959)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill?: string
}

withDefaults(defineProps<Props>(), {
  fill: 'white'
})
</script>

<style scoped></style>
