import ContentService from '@/services/ContentService'
import {
  getMinSuperContributionRate,
  getSuperContributionThreshHoldMinSalary,
} from '@/lib/RetirementPlanHelper'
import _ from 'lodash'
import { setRootColor } from '@/lib/helpers'
import whitelabelGetters from '@/lib/WhitlabelGettersHelper'
import RetirementService from '@/services/RetirementService'

const state = () => ({
  defaultValuesLoading: false,
  whitelabelDataObject: null,
  maxSalaryPerAnnumForSuperGuarantee: null,
  minSalaryPerAnnumForSuperGuarantee: getSuperContributionThreshHoldMinSalary(),
  maxContributionPerAnnumForSuperGuarantee: null,
  minSuperGuarantee: null,
  maxSuperContributions: null,
})

const getters = {
  ...whitelabelGetters,

  getCurrentWhitelabelObject(state) {
    if (!_.isEmpty(state.whitelabelDataObject)) {
      return {
        id: state.whitelabelDataObject.id,
        name: state.whitelabelDataObject.name,
        logoUrl: state.whitelabelDataObject.logo,
        singleSignOn: state.whitelabelDataObject.single_sign_on,
        showMMPLogo: state.whitelabelDataObject.render_mmp_logo,
        renderWhitelabelLogo: state.whitelabelDataObject.render_whitelabel_logo,
        whitelabelCTAText: state.whitelabelDataObject.cta_text,
        whitelabelCTALink: state.whitelabelDataObject.cta_link,
        displayHelpButton: state.whitelabelDataObject.display_help,
        whitelabelPrivacyPolicy: state.whitelabelDataObject.privacy_url,
        whitelabelTermsAndConditions: state.whitelabelDataObject.terms_and_conditions_url,
        whitelabelFSG: state.whitelabelDataObject.fsg_url,
        whitelabelCopyright: state.whitelabelDataObject.copyright_url,
        subscriptionRequired: state.whitelabelDataObject.subscription_required,
        freeDays: state.whitelabelDataObject.free_days,
        gracePeriod: state.whitelabelDataObject.initial_grace_period,
        jointLogo: state.whitelabelDataObject.joint_logo,
        callcentre_portal: state.whitelabelDataObject.callcentre_portal,
        adviser_consent_box: state.whitelabelDataObject.adviser_consent_box,
        adviser_contact_email: state.whitelabelDataObject.adviser_contact_email,
      }
    }
    return {
      name: 'Otivo',
      logoUrl: '',
      singleSignOn: 'email,gmail',
      showMMPLogo: 1,
      renderWhitelabelLogo: 1,
      whitelabelCTAText: '',
      whitelabelCTALink: '',
      displayHelpButton: 0,
      whitelabelPrivacyPolicy: '/content/privacy',
      whitelabelTermsAndConditions: '/content/security',
      whitelabelFSG: '/fsg',
      subscription_required: true,
      freeDays: 7,
      gracePeriod: 0,
      id: 1,
      jointLogo: false,
      callcentre_portal: 0,
      adviser_consent_box: false,
      adviser_contact_email: null,
    }
  },

  getAFSLDetailsForWhiteLabel(state) {
    return {
      provider: state.whitelabelDataObject.provider,
      logo: state.whitelabelDataObject.logo,
      abn: state.whitelabelDataObject.abn,
      afsl: state.whitelabelDataObject.afsl,
      address: state.whitelabelDataObject.address,
      from_email: state.whitelabelDataObject.from_email,
      terms_and_conditions_url: state.whitelabelDataObject.terms_and_conditions_url,
      privacy_url: state.whitelabelDataObject.privacy_url,
      fsg_url: state.whitelabelDataObject.fsg_url,
    }
  },
  filteredProducts(state) {
    // Filtering hidden products
    return state.whitelabelDataObject.products
      ?.filter((prod) => prod.product.hidden === 0)
      .sort((a, b) => {
        if (a.product.order < b.product.order) {
          return -1
        } else if (a.product.order > b.product.order) {
          return 1
        }
        return 0
      })
  },

  isSubscriptionRequired(state) {
    return state.whitelabelDataObject.subscription_required
  },

  getWhitelabelName(state) {
    return state.whitelabelDataObject.name
  },

  getSignInOptions(state) {
    return state.whitelabelDataObject.singleSignOn.split(',')
  },

  getMaxSalaryPerAnnumForSuperGuarantee(state) {
    return state.maxSalaryPerAnnumForSuperGuarantee
  },

  getMinSalaryPerAnnumForSuperGuarantee(state) {
    return state.minSalaryPerAnnumForSuperGuarantee
  },

  getWhitelabelDataObjectProducts(state) {
    return state.whitelabelDataObject.products
  },

  getAvgSuperContributionRate(state, rootGetters) {
    return (salary) => {
      let rate =
        salary !== null && salary < getSuperContributionThreshHoldMinSalary()
          ? 0
          : getMinSuperContributionRate()
      if (rate > 0 && rootGetters.isItSunsuperemployees) {
        rate = 14
      }
      return rate
    }
  },
  jointLogo(state) {
    return state.whitelabelDataObject.joint_logo
  },
}

const mutations = {
  SET_ALL_DEFAULT_VALUES(state, payload) {
    state.whitelabelDataObject = payload
  },

  SET_WHITELABEL_DEFAULT_VALUES_LOADING(state, value) {
    state.defaultValuesLoading = value
  },

  SET_MIN_SUPER_RATE(state, value) {
    state.minSuperGuarantee = value
  },

  SET_MAX_CONTRIBUTIONS(state, value) {
    state.maxSuperContributions = value
  },

  SET_MAX_SALARY_BASE(state, value) {
    state.maxSalaryPerAnnumForSuperGuarantee = value
  },

  SET_MAX_CONTRIBUTION_PA_BASE(state, value) {
    state.maxContributionPerAnnumForSuperGuarantee = value
  },
}

const actions = {
  // temporary to keep everything else working until we fully refactor whitelabels
  setWhitelabelValues({ commit, dispatch }, whitelabelObject) {
    commit('SET_WHITELABEL_DEFAULT_VALUES_LOADING', true)
    dispatch('updateWhiteLabelValues', whitelabelObject)
    commit('SET_WHITELABEL_DEFAULT_VALUES_LOADING', false)
  },

  updateWhiteLabelValues({ commit, dispatch }, data) {
    commit('SET_ALL_DEFAULT_VALUES', data)
    dispatch('updateCSSColorVariables', data)
  },

  updateCSSColorVariables({ commit }, payload) {
    if (payload.whitelabel_theme) {
      _.map(payload.whitelabel_theme, (value, variable) => {
        setRootColor(variable, value)
        return value
      })
    }
    if (payload.whitelabel_color) {
      setRootColor('whitelabel-color', payload.whitelabel_color)
    }
  },
  fetchSuperData({ commit }) {
    return RetirementService.getSuperGuarantee().then((res) => {
      commit('SET_MIN_SUPER_RATE', res.data.sg.rate)
      commit('SET_MAX_CONTRIBUTIONS', res.data.max_contribution.amount)
      commit('SET_MAX_SALARY_BASE', res.data.contribution_base.max_salary_pa_for_sg)
      commit('SET_MAX_CONTRIBUTION_PA_BASE', res.data.contribution_base.max_contribution_pa_for_sg)
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
