import getApiInstance from '@/services/Api'
const api = getApiInstance()

export default {
  postRetirementCalculatorData(payload) {
    return api.Post('/retirement_calc', payload)
  },
  getNetIncome(payload) {
    return api.Post('/net_income', payload)
  },
  getPreservationAgesForRetCalc() {
    return api.Get('/retirement_calc/preservationAges')
  },
  downloadPDF(payload) {
    return api.Post('/retirement_calc/download', payload)
  }
}
