<template>
  <div id="testimonials">
    <div class="px-[20px] h3 text-center mt-10 md:mt-[80px]" v-html="testimonialPageData.heading" />
    <p
      class="px-[20px] md:px-0 md:text-center text-mp1 md:text-intro md:max-w-[880px] m-auto text-grey-2 mt-[20px]">
      {{ testimonialPageData.subHeading }}
    </p>
    <TestimonialSlider />
  </div>
</template>

<script lang="ts" setup>
import TestimonialSlider  from '@/components/TestimonialSlider.vue'
type TestimonialPageDataType = {
  heading: string
  subHeading: string
}

const testimonialPageData: TestimonialPageDataType = {
  heading:
    'Otivo is helping thousands of Aussies take <br class="hidden md:block"> back <span class="svg-underline-wide"> control of their money</span>',
  subHeading:
    'When we say we’ll help you get your life back, we’re not kidding around. In fact, over a third of our customers say they feel less worried about their finances after using us for just six months.\n\n',
}
</script>
