<template>
  <svg width="32" height="33" viewBox="0 0 32 33" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5527 5.84863L14.6152 6.78613C14.5215 6.87988 14.4746 7.00488 14.4746 7.16113C14.4746 7.31738 14.5215 7.45801 14.6152 7.58301L21.8809 14.8486H5.89648C5.74023 14.8486 5.59961 14.9111 5.47461 15.0361C5.38086 15.1299 5.33398 15.2549 5.33398 15.4111V16.7236C5.33398 16.8799 5.38086 17.0205 5.47461 17.1455C5.59961 17.2393 5.74023 17.2861 5.89648 17.2861H21.8809L14.6152 24.5518C14.5215 24.6768 14.4746 24.8174 14.4746 24.9736C14.4746 25.1299 14.5215 25.2549 14.6152 25.3486L15.5527 26.2861C15.6777 26.3799 15.8184 26.4268 15.9746 26.4268C16.1309 26.4268 16.2559 26.3799 16.3496 26.2861L26.1465 16.4424C26.2715 16.3486 26.334 16.2236 26.334 16.0674C26.334 15.9111 26.2715 15.7861 26.1465 15.6924L16.3496 5.84863C16.2559 5.75488 16.1309 5.70801 15.9746 5.70801C15.8184 5.70801 15.6777 5.75488 15.5527 5.84863Z"
      fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'ArrowRightIcon'
}
</script>
