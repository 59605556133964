<template>
  <svg
    :class="{ 'white-color': white }"
    class="debtIcon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 96"
    @click="$emit('click')">
    <g fill="none" fill-rule="evenodd" stroke="none">
      <path fill-rule="nonzero" d="M0 0h96v96H0z" />
      <path
        class="icon"
        fill="#8A55CC"
        d="M36.566 34a14.511 14.511 0 0 1-2.73-8.5c0-8.008 6.44-14.5 14.386-14.5 7.946 0 14.387 6.492 14.387 14.5 0 3.175-1.013 6.112-2.73 8.5h11.512a3 3 0 0 1 2.953 2.473L83 85H13l8.656-48.527A3 3 0 0 1 24.609 34h11.957zm11.656-1c4.11 0 7.442-3.358 7.442-7.5 0-4.142-3.332-7.5-7.442-7.5s-7.441 3.358-7.441 7.5c0 4.142 3.332 7.5 7.441 7.5zm-1.107 39.164h1.788c.212 0 .38-.063.505-.189s.188-.296.188-.509v-2.035c2.038-.213 3.658-.843 4.86-1.89 1.202-1.046 1.803-2.412 1.803-4.098 0-1.144-.24-2.084-.722-2.82-.48-.737-1.225-1.347-2.235-1.832-1.01-.484-2.37-.92-4.081-1.308-1.231-.31-2.178-.6-2.841-.872-.664-.271-1.145-.581-1.443-.93-.298-.35-.447-.785-.447-1.308 0-.776.303-1.367.909-1.774.606-.407 1.437-.61 2.495-.61.961 0 1.764.208 2.408.625.645.416 1.024.935 1.14 1.555.153.349.432.523.836.523h2.74a.594.594 0 0 0 .606-.61c-.038-.717-.293-1.454-.764-2.21-.472-.755-1.154-1.42-2.048-1.99-.894-.573-1.966-.946-3.216-1.12v-2.064c0-.213-.063-.383-.188-.509S49.115 46 48.903 46h-1.788a.691.691 0 0 0-.49.19.663.663 0 0 0-.202.508v2.006c-1.885.232-3.37.872-4.456 1.918-1.087 1.047-1.63 2.326-1.63 3.838 0 1.666.529 2.946 1.586 3.837 1.058.892 2.721 1.609 4.99 2.151 1.365.369 2.399.684 3.1.945.703.262 1.227.567 1.573.916.346.349.519.785.519 1.308 0 .795-.341 1.42-1.024 1.875-.683.456-1.687.683-3.014.683-1.192 0-2.13-.223-2.812-.668-.683-.446-1.13-.998-1.342-1.657a1.34 1.34 0 0 0-.36-.422c-.125-.087-.303-.13-.534-.13h-2.596a.608.608 0 0 0-.447.188.586.586 0 0 0-.187.422 4.94 4.94 0 0 0 .822 2.514c.51.785 1.26 1.45 2.25 1.992s2.177.882 3.562 1.017v2.035c0 .213.067.383.202.509a.691.691 0 0 0 .49.189z" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DebtIcon',
  props: {
    white: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.icon {
  @apply fill-otivo-blue stroke-otivo-blue;
}
.white-color .icon {
  @apply fill-white;
}
</style>
