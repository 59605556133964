import {
  format,
  sub,
  startOfWeek,
  subYears,
  getYear,
  startOfYear,
  endOfYear,
  parse,
} from 'date-fns'

const now = new Date()
const formatString = 'dd MMM yyyy'
const backendFormat = 'dd/MM/yyyy'
const todayFormatted = format(now, formatString)
const today = () => {
  return {
    start: todayFormatted,
    end: todayFormatted,
    formatted: todayFormatted,
  }
}

const yesterday = () => {
  const yesterday = sub(now, { days: 1 })
  const formatted = format(yesterday, formatString)
  return {
    start: formatted,
    end: formatted,
    formatted: formatted,
  }
}

const thisWeek = () => {
  const startOfWeekDate = startOfWeek(now)
  return {
    start: format(startOfWeekDate, formatString),
    end: todayFormatted,
    formatted: `${format(startOfWeekDate, formatString)} - ${todayFormatted}`,
  }
}

const lastWeek = () => {
  const startOfWeekDate = startOfWeek(now)
  return {
    start: format(startOfWeekDate, formatString),
    end: todayFormatted,
    formatted: `${format(startOfWeekDate, formatString)} - ${todayFormatted}`,
  }
}

const last7Days = () => {
  const lastWeek = sub(now, { days: 7 })
  return {
    start: format(lastWeek, formatString),
    end: todayFormatted,
    formatted: `${format(lastWeek, formatString)} - ${todayFormatted}`,
  }
}

const last28Days = () => {
  const lastMonth = sub(now, { days: 28 })
  return {
    start: format(lastMonth, formatString),
    end: todayFormatted,
    formatted: `${format(lastMonth, formatString)} - ${todayFormatted}`,
  }
}

const last30Days = () => {
  const lastMonth = sub(now, { days: 30 })
  return {
    start: format(lastMonth, formatString),
    end: todayFormatted,
    formatted: `${format(lastMonth, formatString)} - ${todayFormatted}`,
  }
}

const last90Days = () => {
  const lastMonth = sub(now, { days: 90 })
  return {
    start: format(lastMonth, formatString),
    end: todayFormatted,
    formatted: `${format(lastMonth, formatString)} - ${todayFormatted}`,
  }
}

const last12Months = () => {
  const lastYear = subYears(now, 1)
  return {
    start: format(lastYear, formatString),
    end: todayFormatted,
    formatted: `${format(lastYear, formatString)} - ${todayFormatted}`,
  }
}

const lastCalendarYear = () => {
  const lastYear = getYear(now) - 1
  const dateObj = new Date(lastYear, 1, 1)
  const start = format(startOfYear(dateObj), formatString)
  const end = format(endOfYear(dateObj), formatString)

  return {
    start: start,
    end: end,
    formatted: `${start} - ${end}`,
  }
}

const thisYear = () => {
  const start = format(startOfYear(now), formatString)
  const end = format(now, formatString)
  return {
    start: start,
    end: end,
    formatted: `${start} - ${end}`,
  }
}

const parseDateForBackend = (dateString: string, defaultFormat = formatString) => {
  const date = parse(dateString, defaultFormat, new Date())
  return format(date, backendFormat)
}

export default {
  today,
  yesterday,
  thisWeek,
  lastWeek,
  last7Days,
  last28Days,
  last30Days,
  last90Days,
  last12Months,
  lastCalendarYear,
  thisYear,
  parseDateForBackend,
}
