<template>
  <svg width="80" height="80" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.67549 16C7.96516 15.9987 6.32523 15.3238 5.11561 14.1234C3.90599 12.9231 3.22551 11.2954 3.22351 9.59751C3.22351 9.33229 3.32964 9.07794 3.51855 8.8904C3.70746 8.70286 3.96368 8.59751 4.23085 8.59751H8.66815V4.19251C8.66815 3.92729 8.77428 3.67293 8.96319 3.4854C9.15211 3.29786 9.40833 3.1925 9.67549 3.1925C11.3643 3.22563 12.9726 3.91487 14.1551 5.1122C15.3376 6.30953 16 7.91946 16 9.59626C16 11.2731 15.3376 12.883 14.1551 14.0803C12.9726 15.2776 11.3643 15.9669 9.67549 16ZM5.35402 10.5975C5.53365 11.3625 5.91606 12.0659 6.46163 12.6348C7.0072 13.2037 7.69609 13.6174 8.45691 13.8331C9.21774 14.0488 10.0228 14.0587 10.7888 13.8616C11.5547 13.6645 12.2536 13.2677 12.8131 12.7123C13.3726 12.1569 13.7723 11.463 13.9709 10.7027C14.1694 9.94232 14.1595 9.14309 13.9422 8.3878C13.7249 7.63252 13.3081 6.94865 12.735 6.40705C12.162 5.86545 11.4534 5.48582 10.6828 5.30751V9.59751C10.6828 9.86272 10.5767 10.1171 10.3878 10.3046C10.1989 10.4922 9.94265 10.5975 9.67549 10.5975H5.35402Z"
      :fill="fill1" />
    <path
      d="M6.45704 7.4025H1.01492C0.871584 7.40357 0.729664 7.37426 0.598647 7.31653C0.46763 7.2588 0.350534 7.17397 0.255183 7.06772C0.159832 6.96147 0.088422 6.83624 0.0457248 6.70039C0.00302761 6.56455 -0.00997338 6.42123 0.00759036 6.28C0.00500978 6.23837 0.00500978 6.19663 0.00759036 6.155C0.00759036 2.7625 2.90368 0 6.45704 0C6.72421 0 6.98043 0.105357 7.16934 0.292893C7.35825 0.48043 7.46438 0.734784 7.46438 1V6.4025C7.46438 6.66772 7.35825 6.92207 7.16934 7.10961C6.98043 7.29714 6.72421 7.4025 6.45704 7.4025ZM2.09529 5.4025H5.44971V2.1075C4.62861 2.27392 3.87412 2.67415 3.27842 3.2593C2.68271 3.84446 2.27159 4.58918 2.09529 5.4025Z"
      :fill="fill2" />
  </svg>
</template>

<script>
export default {
  name: 'SuperTile',
  props: {
    fill1: { type: String, default: '#70D6FF' },
    fill2: { type: String, default: '#70D6FF' }
  }
}
</script>

<style scoped></style>
