<template>
  <div>
    <fitness-test-question-label
      :question-number="questionNumber"
      question=" Do you have home insurance (including building or contents)?" />

    <input-box-buttons
      @click="setHaveHomeInsurance"
      :buttons="[
        {
          name: 'Yes',
          value: 'yes',
        },
        {
          name: 'No',
          value: 'no',
        },
        {
          name: 'Self insured',
          value: 'self_insured',
        },
      ]"
      :selected="haveHomeInsurance"
      name="living-situation"
      label="" />
  </div>
</template>

<script>
import HomeInsuranceAnswer from './HomeInsuranceAnswer'
import { mapState } from 'vuex'
import FitnessTestQuestionLabel from '../QuestionsLabel/FitnessTestQuestionLabel.vue'

import InputBoxButtons from '@/components/BasicInputs/InputBoxButtons.vue'
export default {
  name: 'HaveHomeInsurance',
  components: { InputBoxButtons, FitnessTestQuestionLabel, HomeInsuranceAnswer },
  computed: {
    ...mapState({
      haveHomeInsurance: (state) => state.fitnessTest.questions.haveHomeInsurance,
    }),
  },

  props: {
    questionNumber: {
      type: String,
      default: '',
    },
  },

  methods: {
    setHaveHomeInsurance(value) {
      this.$store.dispatch('setFitnessTestHaveHomeInsurance', value)
    },
  },
}
</script>

<style scoped></style>
