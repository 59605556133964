<template>
  <div class="bg-retirement-notification rounded">
    <div
      class="text-14px text-retirement-darkest font-medium p-3 flex justify-between cursor-pointer"
      @click="showMoreAssumptions = !showMoreAssumptions">
      <div class="self-center">{{ showMoreAssumptions ? 'Hide' : 'Show' }} more assumptions</div>
      <div class="text-18pt self-center">
        <DropdownArrow :class="{ 'transform rotate-180': showMoreAssumptions }" />
      </div>
    </div>

    <graph-assumptions-body class="px-3 pb-3" v-if="showMoreAssumptions" :plan="plan" />
  </div>
</template>

<script>
import GraphAssumptionsBody from '../../GraphAssumptions/GraphAssumptionsBody'
import DropdownArrow from '@/components/icons/DropdownArrow.vue'

export default {
  name: 'MoreRetirementAssumptions',
  components: { DropdownArrow, GraphAssumptionsBody },
  props: {
    plan: {
      type: String,
      default: 'retirement',
    },
  },
  data() {
    return {
      showMoreAssumptions: false,
    }
  },
}
</script>

<style scoped></style>
