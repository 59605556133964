<template>
  <svg>
    <circle cx="9" cy="9" r="5" fill="#0064FF" />
    <path
      d="M9 2V1"
      :stroke="active ? '#FF0000' : '#0064FF'"
      stroke-width="2"
      stroke-linecap="round" />
    <path
      d="M9 17V16"
      :stroke="active ? '#FF0000' : '#0064FF'"
      stroke-width="2"
      stroke-linecap="round" />
    <path
      d="M2 9L1 9"
      :stroke="active ? '#FF0000' : '#0064FF'"
      stroke-width="2"
      stroke-linecap="round" />
    <path
      d="M17 9L16 9"
      :stroke="active ? '#FF0000' : '#0064FF'"
      stroke-width="2"
      stroke-linecap="round" />
    <path
      d="M4.05025 4.05025L3.34314 3.34314"
      :stroke="active ? '#FF0000' : '#0064FF'"
      stroke-width="2"
      stroke-linecap="round" />
    <path
      d="M14.6569 14.6569L13.9498 13.9498"
      :stroke="active ? '#FF0000' : '#0064FF'"
      stroke-width="2"
      stroke-linecap="round" />
    <path
      d="M13.9497 4.05025L14.6568 3.34314"
      :stroke="active ? '#FF0000' : '#0064FF'"
      stroke-width="2"
      stroke-linecap="round" />
    <path
      d="M3.34314 14.6568L4.05025 13.9497"
      :stroke="active ? '#FF0000' : '#0064FF'"
      stroke-width="2"
      stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  name: 'IconRetire',
  props: {
    active: { type: Boolean, default: true }
  }
}
</script>

<style scoped></style>
