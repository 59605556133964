<template>
  <div class="flex flex-col justify-center p-10">
    <div class="flex justify-center py-8">
      <img class="h-20" src="@/assets/img/otivo-small.png" :alt="`otivo-logo`" />
    </div>
    <div class="flex flex-col self-center md:max-w-33% w-fit">
      <h1 class="pb-8">We'll be back soon!</h1>
      <div class="text-16px text-left">
        <p>
          Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment. If
          you need to you can always <a href="mailto:info@otivo.com">contact us</a>, otherwise
          we&rsquo;ll be back online shortly!
        </p>
        <p class="mt-5">&mdash; The Otivo Team</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
article {
  display: block;
  text-align: left;
  width: 650px;
  margin: 0 auto;
}
</style>
