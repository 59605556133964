import OptimizeAdviceService from '@/services/OptimizeAdviceService.ts'
import { SuperFundAllocationNameType } from '@/types/CallcentrePortalTypes.ts'
import { InputItem } from '@/types/InputItem.ts'

export default async (productId: number | null): Promise<SuperFundAllocationNameType[]> => {
  try {
    const res = await OptimizeAdviceService.getSuperFundOptions({ id: productId, search: '' })
    const values: Array<{ id: number; name: string; isTermDeposit: boolean }> = Object.values(
      res.data,
    )
    // MAKE PRETTY
    return values
      .map((item): InputItem<number> => {
        return {
          value: item.id,
          label: item.name,
        }
      })
      .sort((a: { value: number; label: string }, b: { value: number; label: string }) => {
        return a.label.localeCompare(b.label)
      })
  } catch (error) {
    console.error('Error fetching super fund options', error)
    return []
  }
}
