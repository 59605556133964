<template>
  <section class="px-[20px]">
    <div class="text-center">
      <h5 class="text-otivo-blue bold">Getting started is easy</h5>
      <div class="mh3 sm:h1 pt-2">
        Simply choose your path, and we’ll <br class="hidden md:inline" />help you
        <span class="svg-underline-wide whitespace-nowrap">be better off</span>
      </div>
    </div>
    <div
      class="max-w-[750px] lg:max-w-[1300px] grid sm:grid-cols-2 lg:grid-cols-4 gap-[40px] lg:gap-[20px] mt-[40px] py-10 mx-auto">
      <div
        class="justify-self-center lg:justify-self-auto"
        v-for="(tile, index) in tilesData"
        :key="index">
        <Tile :tile="tile" class="max-w-[340px]" />
      </div>
    </div>
    <p class="paragraph-4 mt-10 max-w-[900px] text-center mx-auto">
      *The figures presented are based on averages from our user base, utilising data provided by
      users and projected outcomes based on our assumptions, assuming the recommendations are
      followed until the objective is achieved. These amounts are estimates only and have been
      prepared without considering your individual objectives, financial situation, or needs.
      Therefore, you should assess the appropriateness of this information in light of your own
      objectives, financial situation, and needs before acting on it.
    </p>
  </section>
</template>

<script setup lang="ts">
import OptimizeInvestments from '@/assets/img/optimise-investments.png?url'
import OptimizeInvestmentsAnimated from '@/assets/animated/optimise-investments-640.webm?url'
import BoostSuper from '@/assets/img/boost-super.png?url'
import BoostSuperAnimated from '@/assets/animated/boost-super-640.webm?url'
import BusDriving from '@/assets/img/bus-driving.png?url'
import BusDrivingAnimated from '@/assets/animated/bus-driving-640.webm?url'
import Insurance from '@/assets/img/insurance.png?url'
import InsuranceAnimated from '@/assets/animated/insurance-protection-640.webm?url'
import Tile, { TileType } from '@/components/Dashboard/Tile.vue'
import SunIcon from '../icons/SunIcon.vue'
import InsuranceColouredIcon from '../icons/InsuranceColouredIcon.vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const goToRoute = (routeName: string) => {
  router.push({ name: routeName })
}

const tilesData: TileType[] = [
  {
    image: OptimizeInvestments,
    animatedImage: OptimizeInvestmentsAnimated,
    type: 'super',
    icon: SunIcon,
    title: 'Are my super investments optimised to me?',
    description:
      "Thanks to our investment advice, the average person's retirement balance is increased by <strong>$138,645*</strong>.",
    action: () => goToRoute('intro-optimize-super'),
  },
  {
    image: BoostSuper,
    animatedImage: BoostSuperAnimated,
    icon: SunIcon,
    type: 'super',
    title: 'How can I boost my super with salary sacrifice?',
    description:
      "By following our advice to add more from their salary into super, the average person's retirement balance is boosted by <strong>$180,356*</strong>.",
    action: () => goToRoute('intro-salary-sacrifice'),
  },
  {
    image: BusDriving,
    animatedImage: BusDrivingAnimated,
    icon: SunIcon,
    type: 'super',
    title: 'How much can I add to super from my savings?',
    description:
      'Making tax-deductible contributions from your savings can boost your super, and provide some sweet tax breaks along the way.',
    action: () => goToRoute('intro-boost-super'),
  },
  {
    image: Insurance,
    animatedImage: InsuranceAnimated,
    icon: InsuranceColouredIcon,
    type: 'personal insurance',
    title: 'What personal insurance do I need?',
    description:
      "We’ll help you find the right level of cover by looking at what you've already got, and then figuring out how much you should have inside super.",
    action: () => goToRoute('intro-insurance'),
  },
  //Debt data, should it be removed completely or leave it as placeholder?
  // {
  //   image: PayingOffLoans,
  //   animatedImage: PayingOffLoansAnimated,
  //   icon: BombIcon,
  //   type: 'debt',
  //   disabled: true,
  //   title: 'How can I pay off my loans faster?',
  //   description:
  //     'Thanks to our credit card advice, people save an average of <strong>$2,066</strong> over the life of their loan.',
  // },
  // {
  //   image: ManagingMortgage,
  //   animatedImage: ManagingMortgageAnimated,
  //   icon: BombIcon,
  //   type: 'debt',
  //   disabled: true,
  //   title: 'How can I better manage my mortgage?',
  //   description:
  //     'With a little help from us, the average person saves <strong>$164,727</strong> by the time they pay off their mortgage.',
  // },
]
</script>

<style scoped lang="scss">
h1 {
  font-size: 40px;
  line-height: 44px;
  font-weight: 700;
  font-family: 'Fraunces', serif;
}

.hide-scroll {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
