<template>
  <div class="w-full max-w-[1240px] mx-auto">
    <form
      class="bg-white rounded p-[40px] pt-[30px] border border-blue-4.5"
      @submit.prevent="submitForm()">
      <div class="flex justify-between">
        <h6 class="self-center text-blue-1 flex gap-3 items-center">
          <SuperSun fill2="otivo-red" />
          Personal info
        </h6>
        <div class="flex flex-col">
          <div
            v-if="showEditButton"
            @click="editable = !editable"
            class="underline text-otivo-blue button-2 mt-[10px] cursor-pointer hover:text-blue-1 text-right">
            {{ editable ? 'Lock Account' : 'Edit Account' }}
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 w-full mt-[40px]">
        <!--        <div class="flex flex-col col-span-1 gap-2 mt-2" v-if="isCallcentre">-->
        <!--          <label for="CFS_details_id" class="w-fit button-2">Member ID</label>-->
        <!--          <BaseInput-->
        <!--            type="text"-->
        <!--            :disabled="!canEdit || !editable"-->
        <!--            :error-message="formErrors.member_id"-->
        <!--            :value="form.member_id"-->
        <!--            class="w-full cfs-guidance-member-input"-->
        <!--            name="CFS_details_id"-->
        <!--            placeholder="06112345"-->
        <!--            @update:value="(val: string) => (form.member_id = val)" />-->
        <!--        </div>-->

        <div class="flex flex-col col-span-1 gap-2">
          <label for="CFS_details_first_name" class="w-fit button-2"> First name </label>
          <BaseInput
            type="text"
            validation="alpha"
            :max-length="50"
            :disabled="!canEdit || !editable"
            :error-message="formErrors.first_name"
            :value="form.first_name"
            class="w-full cfs-guidance-member-input"
            name="CFS_details_first_name"
            placeholder="First name"
            @update:value="(val: string) => (form.first_name = capitalize(val))" />
        </div>

        <div class="flex flex-col col-span-1 gap-2">
          <label for="CFS_details_middle_name" class="w-fit button-2">
            <span class="flex gap-1">
              Middle name
              <InfoCircle
                class="flex"
                message="Leave a space between extra middle names eg. Sage Remy" />
            </span>
          </label>
          <BaseInput
            type="text"
            validation="alpha"
            :disabled="!canEdit || !editable"
            :value="form.middle_name"
            class="w-full cfs-guidance-member-input"
            name="CFS_details_middle_name"
            :placeholder="userRole === 'member' ? '' : 'Middle name'"
            @update:value="(val: string) => (form.middle_name = capitalize(val))" />
        </div>

        <div class="flex flex-col col-span-1 gap-2">
          <label for="CFS_details_last_name" class="w-fit button-2"> Last name </label>
          <BaseInput
            :disabled="!canEdit || !editable"
            :error-message="formErrors.first_name"
            :max-length="50"
            :placeholder="'Last name'"
            :value="form.last_name"
            class="w-full cfs-guidance-member-input"
            name="CFS_details_last_name"
            type="text"
            @update:value="(val: string) => (form.last_name = capitalize(val))" />
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <label for="CFS_details_email" class="w-fit button-2"> Email </label>
          <BaseInput
            :disabled="!canEdit || !editable"
            :error-message="formErrors.email"
            :value="form.email"
            class="w-full cfs-guidance-member-input"
            name="CFS_details_email"
            placeholder="email@address.com"
            type="email"
            @update:value="(val: string) => (form.email = val)" />
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <label for="CFS_details_date_of_birth" class="w-fit button-2"> Date of birth </label>
          <BaseDateInput
            :disabled="!canEdit || !editable"
            :error-message="formErrors.dob"
            :max="getMaxDateForDobString()"
            :min="getMinDateForDobString()"
            :value="form.dob"
            class="w-full cfs-guidance-member-input"
            name="CFS_details_date_of_birth"
            @update:value="(val: string) => (form.dob = val)" />
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <label for="cfs_details_mobile_number" class="w-fit button-2">
            <span class="flex gap-1">
              Mobile number
              <InfoCircle class="flex" message="International or mobile code (+61 or 04)" />
            </span>
          </label>
          <BaseInput
            :disabled="!canEdit || !editable"
            :error-message="formErrors.mobile"
            :value="form.mobile"
            class="w-full cfs-guidance-member-input"
            name="cfs_details_mobile_number"
            placeholder="0412 345 678"
            type="phone"
            @update:value="(val: string) => (form.mobile = val)" />
        </div>
      </div>
      <div v-if="userRole !== 'member'" class="flex w-full gap-4 md:gap-8 mt-[60px]">
        <!-- MT -> Split it into 3 buttons as it was getting confusing-->
        <div v-if="clientLoggedIn" class="flex gap-4 w-50%">
          <OtivoButton
            v-if="isAdmin()"
            type="button"
            size="large"
            colour="blue"
            data-test="nextBtn"
            class="w-fit border"
            @click="router.push({ name: 'AccountStatus' })"
            :disabled="loading || forceDisabled">
            Next
          </OtivoButton>
          <OtivoButton
            type="submit"
            :colour="'white'"
            size="large"
            class="w-fit border"
            data-test="saveBtn"
            :loading="loading"
            :disabled="loading || !editable || forceDisabled">
            Save
          </OtivoButton>
        </div>
        <OtivoButton
          v-else
          size="large"
          type="submit"
          colour="blue"
          class="border fit"
          :disabled="forceDisabled"
          data-test="createAccountBtn"
          :loading="loading">
          Create
        </OtivoButton>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { capitalize } from '@/lib/helpers'
import { userRole } from '@/lib/AuthenticatorPlugin.ts'
import { getMaxDateForDobString, getMinDateForDobString } from '@/composables/getDateRangeForDob.ts'
import SuperSun from '@/components/SVGS/SuperSun.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import BaseDateInput from '@/components/Inputs/BaseDateInput.vue'
import InfoCircle from '@/components/InfoCircle/InfoCircle.vue'
import { computed, reactive, ref, watchEffect } from 'vue'
import { useUserStore } from '@/store/pinia/UserStore.ts'
import { useRouter } from 'vue-router'
import { useFormValidator, commonValidators } from '@/composables/useFormValidator.ts'
import { useCheckUser } from '@/composables/users/checkUser.ts'

const userStore = useUserStore()
const user = computed(() => userStore.getUser)
const { isAdmin } = useCheckUser()

const showEditButton = false
const editable = ref(false)
const canEdit = true // wtf
const forceDisabled = false
const loading = false
const clientLoggedIn = true

const router = useRouter()

const mapUserToForm = (user) => {
  return {
    first_name: user?.first_name ?? '',
    middle_name: user?.middle_name ?? '',
    last_name: user?.last_name ?? '',
    email: user?.email ?? '',
    dob: user?.dob ?? '',
    mobile: user?.mobile ?? '',
  }
}

const form = reactive(mapUserToForm(user.value))

watchEffect(() => {
  if (user.value) {
    Object.assign(form, mapUserToForm(user.value))
  }
})

const { formErrors, validateForm } = useFormValidator(
  {
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
    dob: '',
    mobile: '',
  },
  {
    first_name: {
      required: true,
      validate: commonValidators.required,
      message: 'First name is required',
    },
    last_name: {
      required: true,
      validate: commonValidators.required,
      message: 'Last name is required',
    },
    email: {
      required: true,
      validate: commonValidators.email,
      message: 'Please enter a valid email address',
    },
    dob: {
      required: true,
      validate: commonValidators.date,
      message: 'Date of birth is required',
    },
    mobile: {
      required: true,
      validate: commonValidators.mobile,
      message: 'Please enter a valid mobile number',
    },
  },
)

const submitForm = () => {
  const { isValid } = validateForm(form)
  if (isValid) {
    console.log('Form is valid')
  }
}
</script>

<style scoped></style>
