<template>
  <div v-if="gettingStarted" id="getting-started" class="text-white">
    <h3 class="md:text-center pt-21 px-[20px]" v-html="gettingStarted.heading" />
    <div
      class="flex flex-col md:flex-row text-left gap-6 md:max-w-[1100px] md:py-20 mx-auto relative">
      <div class="px-[20px] mt-[30px]">
        <div class="intro-2" v-html="gettingStarted.subHeading" />
        <div class="flex flex-col basis-1/3 flex-wrap gap-y-2 mt-[30px]">
          <div class="w-full h-1px bg-blue-1 line-gradient" />
          <div
            v-for="(bullet, idx) in gettingStarted.iterableItems"
            :key="idx"
            class="button-1 self-start md:w-[700px]">
            <BulletPoint :text="bullet.body" class="text-left pt-[4px]" fill="blue-2" />
            <div class="w-full h-1px bg-blue-1 line-gradient mt-[12px]" />
          </div>
        </div>
        <div class="mt-[30px] paragraph-1">
          And as their life changes, our advice changes with them. Our plans also include free
          access to our care team if they have any questions.
        </div>
        <div class="mt-[30px] paragraph-1">Ask how we can help your business</div>
        <OtivoButton
          class="mt-[20px] mb-[10px] md:mb-0"
          colour="white"
          @click="$emit('button-clicked')">
          Be better off
        </OtivoButton>
      </div>
      <BetterOffPhone
        alt="BetterOffPhone"
        class="z-0 hidden xl:block w-full"
        :hand-hover-active="handHoverActive"
        @mouseover="handHover()"
        @mouseleave="handHover(false)" />

      <img
        :src="PointerHand"
        @mouseover="handHover()"
        @mouseleave="handHover(false)"
        alt="PointerHand"
        style="transform: scaleX(-1)"
        class="hidden xl:block absolute z-10 transition-all duration-300"
        :class="[
          handHoverActive ? '-bottom-[200px] -right-[380px]' : '-bottom-[260px] -right-[450px]',
        ]"
        id="pointer-hand" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import BulletPoint from '@/components/BulletPoint.vue'
import PointerHand from '@/components/SVGS/PointerHand.svg?url'
import OtivoButton from '@/components/OtivoButton.vue'
import BetterOffPhone from '@/components/BrandComponents/Otivo/Personal/BetterOffPhone.vue'
import { useDebounceFn } from '@vueuse/core'
import { nextTick, ref } from 'vue'

type GettingStartedType = {
  heading: string
  subHeading: string
  footer?: string
  buttonText: string
  iterableItems: Array<{ body: string }>
}

defineEmits(['button-clicked'])

const handHoverActive = ref(false)

const handHover = useDebounceFn((value = true) => {
  nextTick(async () => {
    handHoverActive.value = value
  })
}, 10)

const gettingStarted: GettingStartedType = {
  heading:
    "Otivo's plug-and-play platform makes <span class='svg-underline-wide'>getting started easy</span>",
  subHeading:
    "To help you with the roll-out, we'll provide you with some easy-to-follow guides. We'll also be on hand to help out if you hit any snags.<br><br>Once you're ready to launch, your employees can log into any device and in just 10 minutes, they can…",
  footer:
    'And as their life changes, our advice changes with them. Our plans also come with free access to our care team if they have any questions.',
  buttonText: ' Ask how we can help your business',
  iterableItems: [
    { body: 'Discover how much salary to sacrifice to boost their super' },
    { body: 'Learn how to optimise investments inside super' },
    { body: 'Find out how to add more to super from savings without exceeding caps' },
    { body: 'Strategies to pump up their super for a stylish retirement' },
    { body: 'Demystify personal insurance by getting the cover they need' },
  ],
}
</script>

<style scoped>
.line-gradient {
  background: linear-gradient(90deg, #0037a1, #0037a1, #0064ff);
}
</style>
