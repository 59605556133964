<template>
  <div class="flex w-full max-w-[1920px]">
    <div class="w-full flex flex-col flex-grow">
      <h4 class="mt-5 text-blue-1">Admin table</h4>
      <h4 class="text-blue-1 font-bold">{{ useSessionStore().getWhitelabelData.name }}</h4>
      <div class="w-full mt-5">
        <BasicCard>
          <div class="flex items-center">
            <portalSearchInput
              @input="searchByField"
              name="Search Input"
              place-holder="First, last or email"
              class="pr-8 w-full grow" />
            <OtivoButton
              @click="triggerCreateModal"
              colour="blue"
              class="px-8 whitespace-nowrap"
              size="large">
              Create staff account
            </OtivoButton>
          </div>
          <div class="w-full mt-10 mx-auto overflow-x-auto">
            <LumpSumSkeleton v-if="!showPortalStaff" />
            <table class="border-0 bg-blue-1 w-full" v-else>
              <thead>
                <tr>
                  <th v-for="(field, index) in fields" :key="index">
                    <div
                      class="p-4 cursor-pointer w-full flex gap-4 text-white whitespace-nowrap"
                      v-if="field.value">
                      {{ field.label }}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="border border-grey-5 bg-white">
                  <td colspan="8" v-if="loading">
                    <BeatLoader class="my-[40px] text-center" color="var(--otivo-blue)" />
                  </td>
                </tr>
              </tbody>
              <SuperUserAdminTableRows
                v-if="portalStaff"
                :table-data="portalSearchableStaff ? portalSearchableStaff : portalStaff"
                @edit="openEditStaffModal"
                @delete="deleteStaffMember"
                @restore="restoreStaffMember" />
            </table>
          </div>
        </BasicCard>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import CFSCreateStaffModal from '@/components/CFS/CFSCreateStaffModal.vue'
import CFSEditStaffModal from '@/components/CFS/CFSEditStaffModal.vue'
import portalSearchInput from '@/components/CFS/CFSSearchInput.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import SuperUserAdminTableRows from '@/components/CFS/SuperUserAdminTableRows.vue'
import LumpSumSkeleton from '@/components/CFS/Skeletons/LumpSumSkeleton.vue'
import { useTemporaryPortalStore } from '@/store/pinia/TemporaryPortalStore.ts'
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import BasicCard from '@/app/components/BasicCard.vue'
import { useSessionStore } from '../../../store/pinia/SessionStore.ts'

const portalStore = useTemporaryPortalStore()
const modalStore = useModalStore()

const loading = ref(false)
const fields = [
  {
    label: 'First name',
    value: 'first_name',
    type: 'string',
  },
  {
    label: 'Last name',
    value: 'last_name',
    type: 'string',
  },
  {
    label: 'Email',
    value: 'email',
    type: 'string',
  },
  {
    label: 'Contact center',
    value: 'contact_centre',
    type: 'boolean',
  },
  {
    label: 'Super user',
    value: 'super_user',
    type: 'boolean',
  },
  {
    label: 'Back office',
    value: 'back_office',
    type: 'boolean',
  },
  {
    label: 'Blocked',
    value: 'blocked',
    type: 'boolean',
  },
]

const portalStaff = computed(() => portalStore.portalStaff)
const portalSearchableStaff = ref([])

watch(portalStaff, (newStaff) => {
  if (newStaff.length > 0) portalSearchableStaff.value = newStaff
})

onMounted(() => {
  portalStore.getAllPortalStaff()
})

const showPortalStaff = computed(() => portalStaff.value.length > 0)

const searchByField = (val) => {
  portalSearchableStaff.value = portalStaff.value.filter((staff) => {
    const name = (staff.first_name + ' ' + staff.last_name).toLowerCase()
    const email = staff.email.toLowerCase()
    return name.includes(val.toLowerCase()) || email.includes(val.toLowerCase())
  })
}

const triggerCreateModal = () => {
  modalStore.openModal(CFSCreateStaffModal)
}

const restoreStaffMember = (index: number) => {
  portalStore.restoreStaffMember(portalStaff.value[index].id)
}

const deleteStaffMember = (index: number) => {
  portalStore.deleteStaffMember(portalStaff.value[index].id)
}

const editStaffMember = async (obj) => {
  loading.value = true
  await portalStore.editStaffMember(obj)
  loading.value = false
}

const openEditStaffModal = (index) => {
  modalStore.openModal(CFSEditStaffModal, {
    staffObject: portalStaff.value[index],
  })
}

const mapAccount = (member) => ({
  id: member.id,
  firstName: member.first_name,
  lastName: member.last_name,
  email: member.email,
  mobile: member.mobile,
  role: member.contact_centre ? 'callcentre' : 'backoffice',
  superUser: member.super_user,
})
</script>
