<template>
  <svg width="50" height="66" viewBox="0 0 50 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 24.6216C0 11.0451 11.0409 0 24.6118 0C38.1827 0 49.2236 11.0451 49.2236 24.6216C49.2236 44.8498 27.0611 64.6007 26.118 65.4316C25.6876 65.8107 25.1498 66.0002 24.6118 66.0002C24.0738 66.0002 23.5362 65.8105 23.1056 65.4316C22.162 64.6005 0 44.8496 0 24.6216ZM26.7643 24.125H32.655C33.569 24.125 34.1784 25.1914 33.6706 25.9531L24.7331 41.3906C24.53 41.7969 24.1237 42 23.6667 42C22.905 42 22.3464 41.2891 22.4987 40.5273L24.8347 30.625H18.8425C18.0807 30.625 17.5222 30.0156 17.6237 29.2539L19.2487 17.0664C19.2995 16.457 19.8581 16 20.4675 16H27.78C28.5417 16 29.1511 16.7617 28.9479 17.5742L26.7643 24.125Z"
      fill="inherit"
      style="box-shadow: black" />
  </svg>
</template>

<script>
export default {
  name: 'ThunderPinIcon'
}
</script>

<style scoped></style>
