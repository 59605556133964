import getApiInstance from '@/services/Api'
const api = getApiInstance()

export default {
  getMonthlyExpenses() {
    return api.Get('/expense')
  },

  putMonthlyExpenses(payload) {
    return api.Post('/expense', payload)
  },

  postTotalMonthlyExpenses(balance) {
    return api.Post('/expense/user/total', { value: balance })
  },

  // todo :: update totals
  postUserTotalMonthlyExpenses(balance) {
    return api.Post('/expense/user/total', { value: balance })
  },

  postPartnerTotalMonthlyExpenses(balance) {
    return api.Post('/expense/partner/total', { value: balance })
  }
}
