const routes = {
  reporting: [
    {
      heading: 'Reporting',
      content: [
        {
          label: 'Overview',
          routerName: 'portalReportingOverview'
        },
        {
          label: 'Engagement',
          routerName: 'portalReportingEngagement'
        }
      ]
    }
  ]
}

export default (key: string) => {
  return routes[key]
}
