<template>
  <div id="otivo-wrapper">
    <LoadingPage v-if="loading" />
    <Maintenance v-else-if="forceMaintenanceMode" />
    <RouterView v-else />
    <BaseModal />
  </div>
</template>

<script setup lang="ts">
import Maintenance from '@/views/Otivo/Maintenance.vue'
import LoadingPage from '@/views/LoadingPage.vue'
import BaseModal from '@/components/Modals/BaseModal.vue'
import { inject, onBeforeMount, onUnmounted, ref } from 'vue'
import { store } from '@/store'
import { useRoute } from 'vue-router'
import { validSurmountCompany } from '@/lib/ValidationHelper.js'
import { Auth0Symbol, IAuth0Plugin } from '@/lib/AuthenticatorPlugin.ts'
import useInactivityTimer from '@/composables/inactivityTimeout.ts'
import { FeatureList, useLaunchDarkly } from '@/composables/useLaunchDarkly.ts'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'

const { isFeatureEnabled } = useLaunchDarkly()
const forceMaintenanceMode = isFeatureEnabled(FeatureList.MAINTENANCE_MODE)
const auth0 = inject(Auth0Symbol) as IAuth0Plugin
const route = useRoute()
const loading = ref(false)
const inactivityTimer = useInactivityTimer(useSessionStore())

const init = async () => {
  if (auth0.isAuthenticated.value) {
    inactivityTimer.start(60)
  }
  if (route.query.companyName && validSurmountCompany(route.query.companyName)) {
    store.commit('SET_FITNESS_TEST_COMPANY_NAME', route.query.companyName)
    store.dispatch('setSignUpCompanyName', route.query.companyName)
  } else {
    store.commit('SET_FITNESS_TEST_COMPANY_NAME', '')
    store.dispatch('setSignUpCompanyName', null)
  }
  await store.dispatch('fetchLabelContent')
  loading.value = false
}

onBeforeMount(() => {
  init()
})

onUnmounted(() => {
  inactivityTimer.stop()
})
</script>
<style lang="scss">
@import './assets/css/themes/OtivoMain/theme.scss';
</style>
