import getApiInstance from '@/services/Api.ts'
import { AxiosResponse } from 'axios'
const api = getApiInstance()
export default async function () {
  try {
    const res = (await api.Get('/v3/system/status').catch((error) => {
      // null check
      return false
    })) as AxiosResponse<any>
    return res.data.data
  } catch (e) {
    return false
  }
}
