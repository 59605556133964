import { DashboardService } from '@/services/DashboardService'
import { DashboardInterface } from '@/types/DashboardInterface'
import { DashboardStateInterface } from '@/types/DashboardStateInterface'
import { DashboardRecommendationInterface } from '@/types/DashboardRecommendationInterface'
import { DashboardCashflowInterface } from '@/types/DashboardCashflowInterface'

const state = (): DashboardStateInterface => ({
  dashboard: {
    name: '',
    intro: '',
    recommendations: {
      debt: {
        headline: '',
        text: '',
        cta: ''
      },
      retire: {
        headline: '',
        text: '',
        cta: ''
      },
      insurance: {
        headline: '',
        text: '',
        cta: ''
      },
      saving: {
        headline: '',
        text: '',
        cta: ''
      }
    },
    planProgress: 1,
    moneySummary: {
      cashflow: {
        remainingMonthlyIncome: 0,
        userMonthlyIncome: 0,
        income: 0,
        expenses: 0,
        debt: 0,
        savingsCommitments: 0,
        investmentCommitments: 0,
        investmentIncome: 0,
        remaining: 0,
        offsetBalance: 0,
        savingsBalance: 0,
        totalSavings: 0,
        unallocatedSavings: 0,
        rdfTarget: 0
      }
    }
  }
})

const getters = {
  getName(state): string {
    return state.dashboard.name
  },
  getIntro(state): string {
    return state.dashboard.intro
  },
  getRecommendations(state) {
    return state.dashboard.recommendations
  },
  getDebt(state): DashboardRecommendationInterface {
    return state.dashboard.recommendations.debt
  },
  getRetire(state): DashboardRecommendationInterface {
    return state.dashboard.recommendations.retire
  },
  getInsurance(state): DashboardRecommendationInterface {
    return state.dashboard.recommendations.insurance
  },
  getSavings(state): DashboardRecommendationInterface {
    return state.dashboard.recommendations.saving
  },
  getPlanProgress(state): number {
    return state.dashboard.planProgress
  },
  getCashflow(state): DashboardCashflowInterface {
    return state.dashboard.moneySummary.cashflow
  }
}

const mutations = {
  SET_DASHBOARD_STATE(state, dashboard: DashboardInterface): void {
    state.dashboard = dashboard
  }
}

const actions = {
  async setDashboardState({ commit }): Promise<void> {
    const data: DashboardInterface = await DashboardService.getDashboard()
    commit('SET_DASHBOARD_STATE', data)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
