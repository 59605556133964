<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 496 496"
    style="enable-background: new 0 0 496 496"
    xml:space="preserve">
    <g>
      <g>
        <path
          d="M112,400c-17.648,0-32,14.352-32,32s14.352,32,32,32s32-14.352,32-32S129.648,400,112,400z M112,448
          c-8.824,0-16-7.176-16-16c0-8.824,7.176-16,16-16c8.824,0,16,7.176,16,16C128,440.824,120.824,448,112,448z" />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M384,400c-17.648,0-32,14.352-32,32s14.352,32,32,32s32-14.352,32-32S401.648,400,384,400z M384,448
          c-8.824,0-16-7.176-16-16c0-8.824,7.176-16,16-16c8.824,0,16,7.176,16,16C400,440.824,392.824,448,384,448z" />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M280,96c-8.824,0-16-7.176-16-16s7.176-16,16-16c8.824,0,16,7.176,16,16h16c0-14.864-10.232-27.288-24-30.864V32h-16
          v17.136c-13.768,3.576-24,16-24,30.864c0,17.648,14.352,32,32,32c8.824,0,16,7.176,16,16s-7.176,16-16,16c-8.824,0-16-7.176-16-16
          h-16c0,14.864,10.232,27.288,24,30.864V176h16v-17.136c13.768-3.576,24-16,24-30.864C312,110.352,297.648,96,280,96z" />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M462.568,299.656l-18.728-3.12c-23.168-47.528-64.632-84.08-114.056-101.368c3.56-1.96,7.064-4.064,10.416-6.456
          l-9.28-13.032c-15,10.672-32.6,16.32-50.92,16.32c-48.52,0-88-39.48-88-88s39.48-88,88-88s88,39.48,88,88
          c0,18.312-5.648,35.92-16.328,50.928l13.032,9.28C377.328,146.48,384,125.656,384,104C384,46.656,337.344,0,280,0
          S176,46.656,176,104c0,35.088,17.512,66.112,44.208,84.952c-52.68,11.832-98.96,44.808-127.272,91.472l-46.952,8.536
          C27.8,292.272,13.2,304.208,5.648,320h-1.16l-1.496,6.104c-0.056,0.24-0.072,0.488-0.128,0.728C1.088,332.296,0,338.056,0,344.056
          v6.952V376v80h52.712c9.52,23.424,32.488,40,59.288,40s49.768-16.576,59.288-40h153.424c9.52,23.424,32.488,40,59.288,40
          s49.768-16.576,59.288-40H496V339.112C496,319.48,481.944,302.888,462.568,299.656z M16,351.008
          c0-5.024,0.432-10.048,1.272-15.008H24c4.416,0,8,3.592,8,8v8c0,4.408-3.584,8-8,8h-8V351.008z M48,432
          c0,2.712,0.224,5.376,0.552,8H16v-32h36.712C49.696,415.416,48,423.512,48,432z M112,480c-26.472,0-48-21.528-48-48
          s21.528-48,48-48s48,21.528,48,48S138.472,480,112,480z M384,480c-26.472,0-48-21.528-48-48s21.528-48,48-48s48,21.528,48,48
          S410.472,480,384,480z M480,440h-32.552c0.328-2.624,0.552-5.288,0.552-8c0-8.488-1.696-16.584-4.712-24H480V440z M480,392h-46.12
          c-11.744-14.608-29.72-24-49.88-24c-20.16,0-38.144,9.392-49.88,24H296v16h28.712c-3.016,7.416-4.712,15.512-4.712,24
          c0,2.712,0.224,5.376,0.552,8H175.448c0.328-2.624,0.552-5.288,0.552-8c0-8.488-1.696-16.584-4.712-24H280v-16H161.88
          c-11.744-14.608-29.72-24-49.88-24c-20.16,0-38.144,9.392-49.88,24H16v-16h8c13.232,0,24-10.768,24-24v-8
          c0-13.176-10.672-23.888-23.824-23.984c5.88-7.776,14.48-13.456,24.672-15.312l47.696-8.672l5.656,0.264l2.44-4.208
          c29.216-50.56,81.072-84.384,138.912-90.808C254.912,205.552,267.16,208,280,208c8.688,0,17.224-1.12,25.496-3.224
          c55.096,12.72,102.024,50.888,125.744,102.488l2.032,4.424l4.856,0.232l21.816,3.512C471.56,317.368,480,327.328,480,339.112V392z
          " />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M398.512,284.408C368.016,241.576,318.432,216,265.872,216c-55.368,0-106.432,27.752-136.584,74.232L128,292.224V328h272
          v-41.512L398.512,284.408z M176,312h-32v-15.008c0.232-0.336,0.48-0.656,0.712-0.992H168c4.416,0,8,3.592,8,8V312z M288,312h-96
          v-8c0-13.232-10.768-24-24-24h-10.624c27.576-30.368,66.656-48,108.496-48c7.464,0,14.848,0.632,22.128,1.752V312z M384,312h-80
          v-74.88c31.712,8.56,60.112,27.584,80,54.52V312z" />
      </g>
    </g>
    <g>
      <g>
        <rect x="208" y="96" width="16" height="16" />
      </g>
    </g>
    <g>
      <g>
        <rect x="336" y="96" width="16" height="16" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CarLoanIcon'
}
</script>

<style scoped></style>
