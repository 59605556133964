import _ from 'lodash'
import OptimizeAdviceService from '@/services/OptimizeAdviceService'
const getEmptyChantWestInvestments = (productId) => {
  return {
    productId: productId,
    loading: true,
    investments: []
  }
}
const state = () => ({
  // all supers
  chant_west_products: [],
  chant_west_products_loading: false,
  // all super investments
  // empty investment object is added for each super and populate investments when it is needed
  chant_west_investments: [
    // { loading: false, productId: productId investments: []}
  ]
})

const getters = {
  getChantWestSupersData: (state) => state
}

const mutations = {
  SET_ALL_CHANT_WEST_PRODUCTS(state, products) {
    state.chant_west_products = products
  },

  SET_ALL_CHANT_WEST_PRODUCTS_LOADING(state, value) {
    state.chant_west_products_loading = value
  },

  SET_ALL_CHANT_WEST_INVESTMENTS(state, { productId, investments }) {
    const index = _.findIndex(state.chant_west_investments, { productId: productId })
    let tempInvestments = _.cloneDeep(state.chant_west_investments)
    if (index === -1) {
      tempInvestments.push({
        ...getEmptyChantWestInvestments(productId),
        investments: investments
      })
    } else {
      tempInvestments = tempInvestments.map((el, key) => {
        if (key === index) {
          return {
            ...el,
            investments: investments
          }
        }
        return el
      })
    }

    state.chant_west_investments = tempInvestments
  },

  ADD_CHANT_WEST_INVESTMENTS_OBJECT_FOR_PRODUCT_ID(state, { productId, investments }) {
    const index = _.findIndex(state.chant_west_investments, { productId: productId })
    const tempInvestments = _.cloneDeep(state.chant_west_investments)
    tempInvestments.map((el, key) => {
      if (key === index) {
        el.investments = _.map(investments, (investment) => {
          let name = ''
          if (investment.name) {
            name = investment.name
          } else if (investment.investment) {
            name = investment.investment.name
          }
          return { value: investment.id, name: name }
        })
      }
      return el
    })

    state.chant_west_investments = tempInvestments
  },

  ADD_CHANT_WEST_EMPTY_INVESTMENTS_OBJECT_FOR_PRODUCT_ID(state, productId) {
    const index = _.findIndex(state.chant_west_investments, { productId: productId })

    if (index === -1) {
      const tempInvestments = _.cloneDeep(state.chant_west_investments)
      tempInvestments.push(getEmptyChantWestInvestments(productId))
      state.chant_west_investments = tempInvestments
    }
  },

  SET_ALL_CHANT_WEST_INVESTMENTS_LOADING(state, { productId, value }) {
    const index = _.findIndex(state.chant_west_investments, { productId: productId })
    state.chant_west_investments[index].loading = value
  }
}

const actions = {
  getAllChantWestProducts({ dispatch, commit }) {
    commit('SET_ALL_CHANT_WEST_PRODUCTS_LOADING', true)
    OptimizeAdviceService.getSuperFundNames()
      .then((res) => {
        commit('SET_ALL_CHANT_WEST_PRODUCTS_LOADING', false)
      })
      .catch((err) => {
        commit('SET_ALL_CHANT_WEST_PRODUCTS_LOADING', false)
        throw err
      })
  },

  setChantWestProducts({ commit }, products) {
    const items = _.map(products, (el) => {
      commit('ADD_CHANT_WEST_EMPTY_INVESTMENTS_OBJECT_FOR_PRODUCT_ID', el.id)
      return { value: el.id, name: el.longName }
    })

    items.push({ value: 0, name: 'Unknown' })

    commit('SET_ALL_CHANT_WEST_PRODUCTS', items)
  },

  getAllChantWestInvestmentByProductId({ state, commit }, productId) {
    let index = _.findIndex(state.chant_west_investments, { productId: productId })
    if (index === -1) {
      commit('ADD_CHANT_WEST_EMPTY_INVESTMENTS_OBJECT_FOR_PRODUCT_ID', productId)
      index = _.findIndex(state.chant_west_investments, { productId: productId })
    }

    if (state.chant_west_investments[index].investments.length === 0) {
      commit('SET_ALL_CHANT_WEST_INVESTMENTS_LOADING', { productId: productId, value: true })
      OptimizeAdviceService.getSuperFundOptions({ id: productId, search: '' })
        .then((res) => {
          commit('ADD_CHANT_WEST_INVESTMENTS_OBJECT_FOR_PRODUCT_ID', {
            productId,
            investments: res.data
          })
          commit('SET_ALL_CHANT_WEST_INVESTMENTS_LOADING', { productId: productId, value: false })
        })
        .catch((err) => {
          commit('SET_ALL_CHANT_WEST_INVESTMENTS_LOADING', { productId: productId, value: false })
          throw err
        })
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
