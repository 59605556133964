<template>
  <div id="media-articles" class="bg-white relative">
    <h5 class="font-bold text-center ">
      {{ mediaArticlesContent.heading }}
    </h5>
    <h3 class="text-center text-grey-1">
      {{ mediaArticlesContent.subHeading }}
    </h3>
    <ul class="justify-center mt-[40px] md:px-0 md:w-50% m-auto gap-8 border-t-1px border-blue-4.5">
      <li
        v-for="(article, index) in newsArticles"
        :key="index"
        class="flex gap-[10px] md:gap-[20px] py-[10px] border-b-1px border-blue-4.5 items-center">
        <component :is="iconTypeArray[article.type]" class="w-[23px]" />
        <div class="truncate w-full">
          <a :href="article.link" target="_blank" class="intro-1 text-grey-1">{{
            article.title
          }}</a>
          <p class="paragraph-2 truncate">
            <span class="text-otivo-blue">{{ article.source }}</span> |
            {{ formatDate(article.date) }}
          </p>
        </div>
        <div class="w-[136px] flex gap-[10px] items-center">
          <div class="w-[114px] paragraph-2 text-grey-2 h-[18px] text-right">
            {{ article.type == 3 ? 'Listen to podcast' : 'Read more' }}
          </div>
          <ArrowSide class="w-[12px] h-[18px]" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import MiscellaneousService from '@/services/MiscellaneousService'
import moment from 'moment'
import { defineAsyncComponent, markRaw, onMounted, ref } from 'vue'
import ArrowSide from '@/components/icons/ArrowSide.vue'

interface Props {
  mediaArticlesContent: {
    heading: string
  }
}

const newsArticles = ref([])
const iconTypeArray = [
  markRaw(defineAsyncComponent(() => import('@/components/icons/NewspaperIcon.vue'))),
  markRaw(defineAsyncComponent(() => import('@/components/icons/NewspaperIcon.vue'))),
  markRaw(defineAsyncComponent(() => import('@/components/icons/BroadcastIcon.vue'))),
  markRaw(defineAsyncComponent(() => import('@/components/icons/PodcastIcon.vue'))),
]

defineProps<Props>()
onMounted(() => {
  MiscellaneousService.getNewsArticles().then((res) => {
    newsArticles.value = res.data.data
  })
})

const formatDate = (value) => {
  return moment(value).format('MMMM D')
}
</script>
