export const toggleModalMixin = {
  data() {
    return { showModal: false }
  },
  methods: {
    toggleShowModal() {
      this.showModal = !this.showModal
    }
  }
}
