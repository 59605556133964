<template>
  <div>
    <fitness-test-question-label
      :question-number="questionNumber"
      question="If you lost your job, how long would you be able to cover your usual expenses without increasing your debt?" />
    <input-box-buttons
      @click="setCanCoverExpenses"
      :buttons="[
        {
          name: 'No time at all',
          value: '1',
        },
        {
          name: 'Less than 3 months',
          value: '2',
        },
        {
          name: 'At least 3 months',
          value: '3',
        },
        {
          name: 'At least 6 months',
          value: '4',
        },
        {
          name: '1 year or more',
          value: '5',
        },
      ]"
      :selected="canCoverExpenses"
      name="living-situation"
      label=""
      :multi-line="true" />
  </div>
</template>

<script>
import CoverExpensesAnswer from './CoverExpensesAnswer'
import { mapState } from 'vuex'
import FitnessTestQuestionLabel from '../QuestionsLabel/FitnessTestQuestionLabel.vue'

import InputBoxButtons from '@/components/BasicInputs/InputBoxButtons.vue'
export default {
  name: 'CanCoverExpenses',
  components: { InputBoxButtons, FitnessTestQuestionLabel, CoverExpensesAnswer },
  computed: {
    ...mapState({
      canCoverExpenses: (state) => state.fitnessTest.questions.canCoverExpenses,
    }),
  },

  props: {
    questionNumber: {
      type: String,
      default: '',
    },
  },

  methods: {
    setCanCoverExpenses(value) {
      this.$store.dispatch('setFitnessTestCanCoverExpenses', value)
    },
  },
}
</script>

<style scoped></style>
