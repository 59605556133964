<template>
  <section class="flex flex-col gap-y-[20px] max-w-[570px] user-form">
    <h5 class="font-bold pt-12">Welcome to Otivo</h5>
    <div class="flex justify-center">
      <p class="text-blue-1 paragraph-1">
        You’re so close to setting yourself up to be better off, just a few more steps and you're
        finished! We hate paperwork, too.
      </p>
    </div>
    <form @submit.prevent="handleNext" class="flex flex-col gap-y-[10px] mb-[80px]">
      <p class="button-2">First name</p>
      <BaseInput
        v-model:value="form.firstName"
        :error-message="errors.firstName"
        type="text"
        placeholder="First name"
        name="first-name" />

      <p class="button-2">Last name</p>
      <BaseInput
        v-model:value="form.lastName"
        :error-message="errors.lastName"
        v-model="form.lastName"
        type="text"
        placeholder="Last name"
        name="last-name" />

      <p class="button-2">Date of birth</p>
      <BaseDateInput
        :value="form.dob"
        @update:value="(val) => (form.dob = val)"
        :error-message="errors.dob"
        name="dob" />

      <!--      <p class="button-2">Mobile number</p>-->
      <!--      <BaseInput-->
      <!--        v-model:value="form.mobileNumber"-->
      <!--        :error-message="errors.mobileNumber"-->
      <!--        v-model="form.mobileNumber"-->
      <!--        type="phone"-->
      <!--        placeholder="Mobile number"-->
      <!--        name="mobile-number" />-->

      <div
        class="flex flex-col gap-y-[10px]"
        v-if="whitelabel.identifier === 'CERTE_FINANCIAL_WELLBEING'">
        <p class="button-2">Company name</p>
        <Autocomplete
          type="certe-company-name"
          :get-items="
            async (val: string) => {
              const companyNames = [
                { value: 'maddocks', name: 'Maddocks' },
                { value: 'liberty_mutual_insurance', name: 'Liberty Mutual Insurance Group' },
              ]
              return companyNames.filter((item) =>
                item.name.toLowerCase().includes(val.toLowerCase()),
              )
            }
          "
          @selected="certeCompanyName = $event"
          :existing-item="certeCompanyName"
          placeholder="Search for your company"
          id="certe-autocomplete" />
      </div>

      <OtivoButton type="submit" class="mt-[60px] w-full" :loading="loading">Next</OtivoButton>
    </form>
  </section>
</template>
<script setup lang="ts">
import { coerce, date, maxValue, minLength, minValue, object, Output, regex, string } from 'valibot'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import BaseDateInput from '@/components/Inputs/BaseDateInput.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import { useValibotForm } from '@/composables/useValibotForm.ts'
import getApiInstance from '@/services/Api.ts'
import { onMounted, shallowRef } from 'vue'
import { useAuth0, userAuth0Id } from '@/lib/AuthenticatorPlugin.ts'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import { format, subYears } from 'date-fns'
import { useAccountCreationStore } from '@/store/pinia/AccountCreationStore.ts'
import { useGuidanceSuperStore } from '@/store/pinia/GuidanceSuperStore'
import Autocomplete from '@/components/BasicInputs/Autocomplete.vue'

const UserDetailsSchema = object({
  firstName: string('Your first name must be a string.', [
    minLength(2, 'Your first name must be at least 2 characters long.'),
    regex(/^[a-zA-Z\s]*$/, 'Your first name must only contain letters.'),
  ]),
  lastName: string('Your last name must be a string.', [
    minLength(2, 'Your last name must be at least 2 characters long.'),
    regex(/^[a-zA-Z\s]*$/, 'Your last name must only contain letters.'),
  ]),
  dob: coerce(
    date('Your date of birth must be a date.', [
      minValue(subYears(new Date(), 91), 'Sorry, the maximum age allowed is 91 years.'),
      maxValue(
        subYears(new Date(), 16),
        'You need to be at least 16 years old to create an account.',
      ),
    ]),
    (value) => new Date(value as string),
  ),
  // mobileNumber: string('Your mobile number is invalid.', [
  //   minLength(10, 'Your mobile number is invalid.'),
  // ]),
})
export type UserForm = Output<typeof UserDetailsSchema>

const { form, errors, validate } = useValibotForm<UserForm>(
  {
    firstName: '',
    lastName: '',
    dob: null,
    // mobileNumber: '',
  },
  UserDetailsSchema,
)

onMounted(() => {
  if (auth0.user.value) {
    form.firstName = auth0.user.value.given_name ?? ''
    form.lastName = auth0.user.value.family_name ?? ''
    form.dob = auth0.user.value.dob ?? ''
  }
})

const emit = defineEmits<{
  (e: 'next', status: number): void
}>()

const auth0 = useAuth0()
const sessionStore = useSessionStore()
const whitelabel = sessionStore.getWhitelabelData
const certeCompanyName = shallowRef({ value: '', label: 'Select from dropdown' })

const guidanceSuperStore = useGuidanceSuperStore()

const loading = shallowRef(false)

const capitalize = (str: string) => {
  return str[0].toUpperCase() + str.slice(1)
}
const handleNext = async () => {
  loading.value = true
  const output = validate()
  if (output) {
    try {
      const api = getApiInstance()
      const request = api.getRequestType(auth0.user.value.legacy_user ? 'put' : 'post')
      const body = {
        first_name: capitalize(output.firstName),
        last_name: capitalize(output.lastName),
        dob: format(output.dob, 'yyyy-MM-dd'),
        auth0id: userAuth0Id.value,
        // mobile: output.mobileNumber,
        whitelabel_id: sessionStore.getWhitelabelData.id,
        user_has_password: true,
      }

      if (whitelabel.identifier === 'CERTE_FINANCIAL_WELLBEING') {
        body['company_name'] = certeCompanyName.value.value
      }

      // add WBG details if they have them
      if (sessionStore.getWhitelabelData.identifier === 'WBG') {
        const details: { company_code: string; global_user_id: number } = JSON.parse(
          localStorage.getItem('wbg_details'),
        )
        try {
          body['global_user_id'] = details.global_user_id.toString()
          body['company_code'] = details.company_code
          localStorage.removeItem('wbg_details')
        } catch (error) {
          console.error(error)
        }
      }

      const res = await request('/v3/account', body).catch(() => {
        loading.value = false
      })
      const accountCreationStore = useAccountCreationStore()
      accountCreationStore.setUserAccountNumber(res.data.id)
      guidanceSuperStore.setSupersArray(res.data.supers)
      emit('next', res.status)
    } catch (error) {
      console.error(error)
      loading.value = false
    }
  }
  loading.value = false
}
</script>

<style>
.user-form .otivo-error-message {
  position: relative !important;
}
</style>
