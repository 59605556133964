<template>
  <OtivoTable :number-of-columns="selectedCurrentOption ? 1 : 2" :table-data="tableData" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { TableDataType } from '@/types/CallcentrePortalTypes.ts'
import OtivoTable from '@/components/OtivoTable.vue'
import { userOnMobile } from '@/composables/userOnMobile.ts'
import InvestmentOption from '@/types/api/super/InvestmentOption.ts'

type Props = {
  investments: Array<InvestmentOption>
  requestedAllocation: Array<InvestmentOption>
  selectedCurrentOption: boolean
}
const props = defineProps<Props>()

const termDepositAllocations = computed(() =>
  props.investments.filter((value) => value.is_term_deposit),
)
const nonTermDepositAllocations = computed(() =>
  props.investments.filter((value) => !value.is_term_deposit),
)

const tableData = computed<Array<TableDataType>>(() => {
  const payload = [
    {
      component: props.selectedCurrentOption
        ? 'Selected investment'
        : "You've elected to change from",
      fieldType: 'heading',
    },
  ]
  const allAllocations = termDepositAllocations.value.concat(nonTermDepositAllocations.value)

  if (!userOnMobile() && !props.selectedCurrentOption) {
    payload.push({
      component: 'To',
      fieldType: 'heading',
    })
    allAllocations.forEach((allocation, index) => {
      payload.push({
        component:
          `<div data-test="fromName-${index}">` +
          allocation.chant_west_investment_name +
          `<span class="text-otivo-blue"  data-test="fromAllocation-${index}"> ` +
          allocation.percent_allocation +
          '%</span>' +
          '</div>',
        fieldType: 'html',
      })
      if (props.requestedAllocation[index]) {
        payload.push({
          component:
            `<div data-test="toName-${index}">` +
            props.requestedAllocation[index].chant_west_investment_name +
            `<span class="text-otivo-blue" data-test="toAllocation-${index}"> ` +
            props.requestedAllocation[index].percent_allocation +
            '%</span>' +
            '</div>',
          fieldType: 'html',
        })
      } else {
        payload.push({
          component: '',
          fieldType: 'blank',
        })
      }
    })
  } else {
    allAllocations.forEach((allocation, index) => {
      payload.push({
        component:
          `<div data-test="selectedName-${index}">` +
          allocation.chant_west_investment_name +
          `<span class="text-otivo-blue" data-test="selectedAllocation-${index}"> ` +
          allocation.percent_allocation +
          '%</span>' +
          '</div>',
        fieldType: 'html',
      })
    })
    if (!props.selectedCurrentOption) {
      payload.push({
        component: 'To',
        fieldType: 'heading',
      })
      props.requestedAllocation.forEach((allocation) => {
        payload.push({
          component:
            allocation.chant_west_investment_name +
            '<span class="text-otivo-blue" data-test="`toAlocation-${index}`">\n' +
            allocation.percent_allocation +
            '%</span>',
          fieldType: 'html',
        })
      })
    }
  }

  return payload
})
</script>

<style scoped></style>
