<template>
  <div>
    <input-box-buttons
      @click="onSubmitClick"
      :buttons="[
        {
          name: 'Strongly disagree',
          value: 1,
        },
        {
          name: 'Disagree',
          value: 2,
        },
        {
          name: 'Neutral',
          value: 3,
        },
        {
          name: 'Agree',
          value: 4,
        },
        {
          name: 'Strongly agree',
          value: 5,
        },
      ]"
      :selected="selected"
      name="living-situation"
      label="" />
  </div>
</template>
<script>
import InputBoxButtons from '@/components/BasicInputs/InputBoxButtons.vue'
export default {
  name: 'NumberRating',
  components: { InputBoxButtons },
  props: {
    selected: {
      type: [Number, String],
      default: '',
    },
  },
  emits: ['click'],
  methods: {
    onSubmitClick(value) {
      this.$emit('click', value)
    },
  },
}
</script>

<style scoped></style>
