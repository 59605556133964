<template>
  <path
    d="M31.4664 92.9032C29.9801 92.9032 28.5547 92.2915 27.5037 91.2026C26.4528 90.1136 25.8624 88.6367 25.8624 87.0968C25.8624 85.5568 26.4528 84.0799 27.5037 82.991C28.5547 81.9021 29.9801 81.2903 31.4664 81.2903C39.0177 81.2903 39.396 74.4532 39.396 73.0742V42.2419C39.396 40.702 39.9864 39.2251 41.0374 38.1362C42.0883 37.0472 43.5137 36.4355 45 36.4355C46.4862 36.4355 47.9116 37.0472 48.9626 38.1362C50.0135 39.2251 50.604 40.702 50.604 42.2419V73.0742C50.604 81.0435 45.5043 92.9032 31.4664 92.9032Z"
    :fill="fill" />
  <path
    d="M90 46.4516H0L0.168121 40.4855C0.826589 17.7823 20.5246 0 45 0C69.4754 0 89.1734 17.7823 89.8319 40.4855L90 46.4516ZM12.2447 34.8387H77.7553C74.2668 21.6 60.7752 11.6129 45 11.6129C29.2248 11.6129 15.7332 21.6 12.2447 34.8387Z"
    :fill="fill" />
</template>

<script>
export default {
  name: 'InsuranceTile',
  props: {
    fill: { type: String, default: '#D4E7FE' },
    fill1: { type: String, default: '#D4E7FE' },
    fill2: { type: String, default: '#D4E7FE' }
  }
}
</script>

<style scoped></style>
