import getApiInstance from '@/services/Api.ts'
import { AxiosResponse } from 'axios'
import SuperObject from '@/types/api/super/SuperObject.ts'

const api = getApiInstance()

export default {
  getAllSupers() {
    return api.Get<AxiosResponse<SuperObject[]>>(`/v3/account/supers`)
  },

  createNewSuper(superObject: SuperObject) {
    return api.Post<AxiosResponse<SuperObject>>(`/v3/account/supers`, superObject)
  },

  getSuper(superId: string) {
    return api.Get<AxiosResponse<SuperObject>>(`/v3/account/supers/${superId}`)
  },

  updateSuper(superId: string, payload: Record<string, unknown>) {
    return api.Put<AxiosResponse<SuperObject>>(`/v3/account/supers/${superId}`, payload)
  },

  deleteSuper(superId: string) {
    return api.Delete<AxiosResponse<void>>(`/v3/account/supers/${superId}`)
  },
  hasInSuperInsurances(superId: string) {
    return api.Get<AxiosResponse<SuperObject>>(`/v3/supers/${superId}/has-insurances`)
  },
}
