import CfsService from '@/services/CfsService.ts'
import { AxiosError } from 'axios'

/**
 * CFS REPORTING: propose moving this to something under /Client/CFS or something
 */
export const fetchCFSBackendReports = async () => {
  const reportsResponse = await CfsService.fetchBackendReports().catch((err: AxiosError) => {
    console.warn(err)
  })
  return reportsResponse.data.data
}
export const downloadCFSBackendReport = (link: string) => {
  return CfsService.downloadCFSBackendReport(link)
}
