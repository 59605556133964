<template>
  <div>
    <custom-button
      class="bg-otivo-blue text-white p-3 px-8 text-center font-medium w-72 text-18pt"
      :loading="loading"
      @click="triggerOnBoardingProcess()">
      {{ buttonText }}
    </custom-button>
  </div>
</template>

<script>
import CustomButton from '../../CustomButton/CustomButton'
import { mapGetters } from 'vuex'
import { toggleModalMixin } from '../../../mixins/toggleModalMixin'
import SignUpService from '../../../services/SignUpService'
import { useToast } from '@/composables/useToast.ts'

const { errorToast } = useToast()

export default {
  name: 'CreateAcccountAfterFitnessTest',
  components: { CustomButton },
  mixins: [toggleModalMixin],
  props: {
    buttonText: {
      type: String,
      default: 'See your plan now!',
    },
  },
  data() {
    return {
      loading: false,
      message: 'Please check your inbox and junk mail for a password reset request email.',
    }
  },
  computed: {
    ...mapGetters(['email', 'authenticated']),
  },
  methods: {
    triggerOnBoardingProcess() {
      if (this.authenticated) {
        this.$router.push({ name: 'home' })
        return
      }

      this.loading = true
      SignUpService.doesUserExists(this.email)
        .then((res) => {
          this.loading = true
          if (res.data.result) {
            // account exists
            this.$store.commit('TOGGLE_TRIGGER_SIGN_IN')
            this.loading = true
          } else {
            this.$store
              .dispatch('postUserSignUpData')
              .then(() => {
                this.$store.dispatch('setSignUpInProgress', true)
                this.$router.push({ name: 'signUpStep2' })
                this.loading = false
              })
              .catch((err) => {
                this.loading = false
                errorToast('Error occurred, please try again')
                throw err
              })
          }
        })
        .catch((err) => {
          this.loading = true
          throw err
        })
    },
  },
}
</script>

<style scoped></style>
