<template>
  <BaseView>
    <div class="flex flex-col h-full w-full items-center py-[60px] bg-white lg:bg-blue-6">
      <h3 class="lg:h1 text-center w-full max-w-5xl px-6" v-html="content.heading" />
      <p class="intro-1 mt-[40px] px-6" v-html="content.subHeading" />

      <div class="hidden md:grid grid-cols-2 gap-80 lg:gap-6 py-[40px] px-5 bg-blue-6">
        <!-- Left Video and Image -->
        <div class="relative order-2 md:order-1">
          <video muted loop autoplay class="rounded-circle border-1 border-grey-4 w-full">
            <source :src="InsuranceWithoutAdviceAnimated" type="video/mp4" />
          </video>
          <div
            class="text-white text-center absolute -top-34 lg:top-0 bottom-0 right-0 lg:-left-[100px] w-[200px] h-[200px] bg-blue-1 rounded-circle flex flex-col items-center justify-center z-10">
            <div class="menu-1 mt-[30px]">WITHOUT ADVICE</div>
            <div class="button-1 w-[160px] mt-[10px] mb-[20px]">
              {{ content.withoutAdviceText }}
            </div>
          </div>
        </div>

        <!-- Right Video and Image -->
        <div class="relative order-1 md:order-2">
          <video muted loop autoplay class="rounded-circle border-1 border-blue-4.5 w-full">
            <source :src="InsuranceWithAdviceAnimated" type="video/mp4" />
          </video>
          <div
            class="text-white text-center absolute -bottom-40 lg:top-0 lg:-right-28 w-[200px] h-[200px] bg-otivo-red rounded-circle flex flex-col items-center justify-center z-10">
            <OtivoIcon fill-1="white" fill-2="white" class="w-[32px] h-[32px]" />
            <div class="menu-1 mt-[10px]">WITH ADVICE</div>
            <div class="button-1 w-[160px] mt-[10px] mb-[20px]">
              {{ content.withAdviceText }}
            </div>
          </div>
        </div>
      </div>
      <RegularCurve fill="blue-6" class="md:hidden pt-15" />

      <div class="md:hidden flex w-full justify-center gap-5 bg-blue-6 pt-10">
        <div
          class="text-white text-center bg-blue-1 w-[160px] flex flex-col items-center overflow-clip">
          <div class="p-[10px] h-[180px]">
            <div class="menu-1 mt-[20px]">WITHOUT ADVICE</div>
            <div class="headline-6 mt-[10px]">
              {{ content.withoutAdviceText }}
            </div>
          </div>
          <img
            v-if="isIOS()"
            :src="InsuranceWithoutAdviceImage"
            class="w-full border-1 border-grey-4 h-[260px]" />
          <div v-else class="w-full overflow-clip border-1 border-grey-4 flex justify-center">
            <video muted loop autoplay class="min-w-[260px]">
              <source :src="InsuranceWithoutAdviceAnimated" type="video/mp4" />
            </video>
          </div>
        </div>
        <div
          class="text-white text-center bg-otivo-red w-[160px] flex flex-col items-center overflow-clip">
          <div class="p-[10px] h-[180px] flex flex-col items-center">
            <OtivoIcon fill-1="white" fill-2="white" class="w-[30px] h-[30px]" />
            <div class="menu-1 mt-[10px]">WITH ADVICE</div>
            <div class="headline-6">
              {{ content.withAdviceText }}
            </div>
          </div>
          <img
            v-if="isIOS()"
            :src="InsuranceWithAdviceImage"
            class="w-full border-1 border-blue-4.5 h-[260px]" />
          <div v-else class="w-full overflow-clip border-1 border-blue-4.5 flex justify-center">
            <video muted loop autoplay class="min-w-[260px]">
              <source :src="InsuranceWithAdviceAnimated" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>

      <OtivoButton
        class="mt-[40px]"
        size="large"
        @click="createAccountWithModuleStream('personalInsurance')"
        >Help me find peace of mind</OtivoButton
      >
    </div>
  </BaseView>
</template>

<script setup>
import OtivoButton from '@/components/OtivoButton.vue'
import BaseView from '@/views/BaseView.vue'
import InsuranceWithoutAdviceAnimated from '@/assets/img/insurance-without-advice-animated.mp4'
import InsuranceWithAdviceAnimated from '@/assets/img/insurance-with-advice-animated.mp4'
import InsuranceWithoutAdviceImage from '@/assets/img/insurance-without-advice.webp'
import InsuranceWithAdviceImage from '@/assets/img/insurance-with-advice.webp'
import createAccountWithModuleStream from '@/composables/createAccountWithModuleStream.ts'
import OtivoIcon from '@/components/icons/OtivoIcon.vue'
import RegularCurve from '@/components/Shapes/RegularCurve.vue'

const content = {
  heading: `What personal insurance do I need?`,
  subHeading: `We’ll let you know how much cover you need, including what’s available inside your super.`,
  withAdviceText: "Enjoy peace of mind knowing you've got the cover you need.",
  withoutAdviceText: 'You might find yourself without cover when you need it most.',
}
const isIOS = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  return /iphone|ipad|ipod/.test(userAgent) && !/android/.test(userAgent)
}
</script>
