import { AxiosError } from 'axios'
import { computed, ref, watch } from 'vue'
import { defineStore } from 'pinia'
import CfsService from '@/services/CfsService.ts'
import { useSuperStore } from '@/store/pinia/SuperStore.ts'
import { useUserStore } from '@/store/pinia/UserStore.ts'
import { CFSUserCreationObject } from '@/types/GlobalTypes.ts'
import getApiInstance from '@/services/Api.ts'
import UserService from '@/services/UserService.ts'
import User from '@/types/api/account/User.ts'
import setupUser from '@/composables/users/setupUser.ts'

/**
 * Manages all the mutations and creations for managed clients by a call centre team member
 */
export const useCallCentrePortalStore = defineStore('CallCentrePortalStore', () => {
  const userStore = useUserStore()
  const superStore = useSuperStore()
  const api = getApiInstance()

  const admin = computed(() => userStore.getAdmin)
  watch(admin, async (newAdmin) => {
    const managedUser = getManagedUserFromLocalStorage()
    if (newAdmin && managedUser) {
      await getManagedUserData(managedUser.auth0Id)
    }
  })

  // TODO: bind up consent
  const clientConsentGiven = ref<boolean>(false)
  const getClientConsentGiven = computed(() => clientConsentGiven.value)

  const createManagedUser = async (userCreationObject: CFSUserCreationObject) => {
    try {
      const newUser = await UserService.createManagedUser(userCreationObject)
      await setupManagedUser(newUser.data, userCreationObject.account_number, newUser.data.super_id)
    } catch (e) {
      console.error(e)
    }
  }

  const setupManagedUser = async (managedUser: User, accountNumber: string, superId: number) => {
    // set managed user to localStorage
    try {
      setManagedUserToLocalStorage(managedUser.auth0id)
    } catch (e) {
      console.error('Critical error trying to save user', e)
      return
    }

    await superStore.setupSuper(accountNumber, superId?.toString())
    // user setup
    userStore.setManagedUserAsUser(managedUser)

    // TODO: Determine how we can refactor this also if it can be done by the backend after the super update
    CfsService.postIntrafundAdviceRequest(
      {
        member_id: managedUser.member_id,
        advice_stage: 1,
      },
      superId,
    ).catch((error: AxiosError) => console.error(error))
  }

  // used for capturing keys for creating new users (e.g email, member number etc)
  const updateManagedUserKey = (key: string, value: string): void => {
    const user = userStore.getUser
    user[key] = value
  }

  const getManagedUserData = async (auth0Id: string) => {
    if (!auth0Id) auth0Id = getManagedUserFromLocalStorage()
    else setManagedUserToLocalStorage(auth0Id)
    try {
      const managedUser = await UserService.getUserByAuth0Id(auth0Id)
      await setupUser()
      // if managed user view need module status, get it here
      userStore.setManagedUserAsUser(managedUser.data.data)
    } catch (e) {
      console.error(e)
    }
  }

  const setManagedUserToLocalStorage = (auth0Id: string) => {
    api.setClientHeader(auth0Id)
    window.localStorage.setItem(
      'activeManagedUser',
      JSON.stringify({ auth0Id: auth0Id, timestamp: new Date().getTime() }),
    )
  }

  const getManagedUserFromLocalStorage = () => {
    const managedUser = window.localStorage.getItem('activeManagedUser')
    if (managedUser) {
      return JSON.parse(managedUser)
    }
    return null
  }

  const reset = () => {
    clientConsentGiven.value = false
    localStorage.removeItem('activeManagedUser')
    userStore.clearUser()
  }

  return {
    getClientConsentGiven,
    createManagedUser,
    updateManagedUserKey,
    setupManagedUser,
    getManagedUserData,
    reset,
  }
})
