import NotificationsService from '@/services/NotificationsService'
import moment from 'moment'

const state = () => ({
  notifications: []
})

const getters = {
  notifications(state) {
    return state.notifications
  }
}

const mutations = {
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications
  },
  REMOVE_NOTIFICATION(state, index) {
    state.notifications.map((el, i) => {
      if (i === index) {
        el.deleted_at = moment().format('YYYY-MM-DD HH:MM:SS')
      }
      return el
    })
  }
}

const actions = {
  async fetchNotifications({ dispatch }) {
    return NotificationsService.getNotifications().then((response) => {
      dispatch('setNotifications', response.data.data)
    })
  },

  removeNotification({ commit, getters }, index) {
    const id = getters.notifications[index].id
    commit('REMOVE_NOTIFICATION', index)
    return NotificationsService.deleteNotification(id)
  },

  setNotifications({ commit }, notifications) {
    if (notifications && notifications.length > 0) commit('SET_NOTIFICATIONS', notifications)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
