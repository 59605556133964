<template>
  <nav class="breadCrumbNav w-1/4">
    <router-link to="/" class="font-medium no-underline hover:underline">
      <div v-if="this.authenticated">Dashboard</div>
      <div v-else>Home</div>
    </router-link>
    <a class="ml-2 mr-2">></a>
    <router-link :to="currentRoute.path" class="font-medium no-underline hover:underline">
      {{ articleTitle }}
    </router-link>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ContentBreadcrumb',
  computed: {
    ...mapGetters(['authenticated']),
    currentRoute() {
      return this.$route
    },
    articleTitle() {
      let title = this.currentRoute.path.replace(/_/g, ' ').replace(/\/content\//, '')
      title = title[0].toUpperCase() + title.substr(1)
      return title
    }
  }
}
</script>

<style scoped></style>
