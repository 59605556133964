import type { AxiosResponse } from 'axios'
import GetApiInstance from '@/services/Api'
import {
  ArticleCategoryType,
  ArticleFilters,
  ArticleType,
  FAQType,
  FullArticleType,
} from '@/types/Article'
import { LaravelPagination } from '@/types/LaravelPagination'
import { LaravelResourceCollection, LaravelResponse } from '@/types/LaravelResponse'

const api = GetApiInstance()

export const fetchArticles = async (
  filter: ArticleFilters,
): Promise<AxiosResponse<LaravelPagination<ArticleType>>> => {
  return await api.Get('/articles', {
    params: filter,
  })
}

export const fetchArticleBySlug = async (
  slug: string | number,
): Promise<AxiosResponse<LaravelResponse<FullArticleType>>> => {
  return await api.Get(`/articles/${slug}`)
}

export const fetchArticleCategories = async (): Promise<
  AxiosResponse<LaravelResourceCollection<ArticleCategoryType>>
> => {
  return await api.Get('/articles/categories')
}

export const fetchRelatedArticles = async (
  id: number,
): Promise<AxiosResponse<LaravelResourceCollection<ArticleType>>> => {
  return await api.Get(`/article/${id}/related-readings`)
}

export const fetchAllFAQs = async (
  type: string,
): Promise<AxiosResponse<LaravelResourceCollection<FAQType>>> => {
  const urlParam = new URLSearchParams({ type: type }).toString()
  return await api.Get(`/faqs/?${urlParam}`)
}
